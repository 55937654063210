import { postRecommendationClearSelection, postRecommendationSelectOption } from '@apis/Recommendations';
import { Recommendation, RecommendationOption } from '@apis/Recommendations/model';
import styled from '@emotion/styled';
import { Box, Divider, Group, Overlay, Space, Text, useMantineTheme } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';
import { IComparsonConfig, IMetricInfo } from '@root/Components/Recommendations/Options/Models';
import { useMetricComparison } from '@root/Components/Recommendations/Options/RecommendationOptionComparison';
import { RecommendationOptionsPanel } from '@root/Components/Recommendations/Options/RecommendationOptions';
import { IFieldConfig, RecommendationGrid } from '@root/Components/Recommendations/RecommendationGrid';
import { PageContent, PaneledPage, PagePanel, PanelHeader, PanelSubHeader } from '@root/Design/Layout';
import { useResizeNeeded } from '@root/Design/Primitives';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

interface IBaseRightsizingPageProps {
    comparisonConfig: IComparsonConfig;
    fieldConfig: IFieldConfig;
    resourceType: string;
    title: string;
}

export function BaseRightsizingPage({ comparisonConfig, fieldConfig, resourceType, title }: IBaseRightsizingPageProps) {
    const theme = useMantineTheme();
    const [refreshHandler, setRefreshHandler] = useState<{ refresh: (item?: Recommendation) => void }>();
    const handleRefreshHandler = useCallback((handler: (item?: Recommendation) => void) => {
        setRefreshHandler({ refresh: handler });
    }, []);
    const [selectedRecommendation, setSelectedRecommendation] = useState<Recommendation>();
    const resizeNeeded = useResizeNeeded();

    useEffect(() => {
        resizeNeeded();
    }, [!!selectedRecommendation]);
    const menuItems = useMemo(() => {
        return (
            [
                { endpoint: 'rightsizing-ec2', icon: <></>, text: 'EC2', type: 'link', atid: 'RightsizingEC2' },
                { endpoint: 'rightsizing-rds', icon: <></>, text: 'RDS', type: 'link', atid: 'RightsizingRDS' },
            ] as NavBarItem[]
        ).map((r) => ({ ...r }));
    }, []);

    const onOptionSelected = useCallback(
        async (option?: RecommendationOption | 'no change') => {
            option = option === 'no change' ? { ChangeDetails: { SizeChange: { RecommendedSize: 'No Change' } } } : option;
            const result = !option
                ? await postRecommendationClearSelection([selectedRecommendation!.Id!])
                : await postRecommendationSelectOption(option as unknown as RecommendationOption, { recommendationId: selectedRecommendation!.Id! });
            const updatedRecommendation = !option
                ? Object.assign(selectedRecommendation, { OptionSelected: null })
                : Object.assign(selectedRecommendation, result);
            refreshHandler?.refresh(updatedRecommendation);
        },
        [selectedRecommendation, refreshHandler]
    );
    const onSelectClick = useCallback(
        (item: Recommendation) => {
            setSelectedRecommendation(item);
        },
        [onOptionSelected]
    );
    const handlePanelClose = useCallback(() => {
        setSelectedRecommendation(undefined);
    }, []);
    const { width } = useViewportSize();
    const shouldSidePanelOverlap = width < 1850;
    const sidePanelOverlapStyles: CSSProperties = shouldSidePanelOverlap ? { background: theme.colors.gray[1], position: 'absolute', right: 0 } : {};

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flex: 0, height: '100%' }}>
                <PageNavBar items={menuItems} />
            </Box>
            <Divider orientation="vertical" />
            <PageContent style={{ position: 'relative' }}>
                <PaneledPage>
                    <PagePanel size="fill" padded style={{ position: 'relative' }}>
                        {shouldSidePanelOverlap && selectedRecommendation ? <Overlay sx={{ zIndex: 1 }} onClick={handlePanelClose} /> : null}
                        <Box>
                            <PanelHeader style={{ padding: 0 }}>
                                <Text data-atid="SiteSecondaryHeader" size={20}>
                                    {title} Rightsizing
                                </Text>
                            </PanelHeader>
                            <PanelSubHeader style={{ padding: 0 }}>Select resources and apply new sizes</PanelSubHeader>
                            <Space h="md" />
                        </Box>
                        <Box sx={{ height: '100%', minHeight: 0 }}>
                            <RecommendationGrid
                                persistenceKey={`${resourceType} Rightsizing`}
                                resourceType={resourceType}
                                onClickSelect={onSelectClick}
                                fieldConfig={fieldConfig}
                                recommendationType="Size"
                                refreshHandler={handleRefreshHandler}
                                selected={selectedRecommendation}
                            />
                        </Box>
                    </PagePanel>
                    {!selectedRecommendation ? null : (
                        <SidePanelContainer style={sidePanelOverlapStyles}>
                            <Divider orientation="vertical" />
                            <RecommendationOptionsPanel
                                key={selectedRecommendation.Id}
                                comparison={comparisonConfig}
                                onClose={handlePanelClose}
                                recommendation={selectedRecommendation}
                                onSelectionChange={onOptionSelected}
                            />
                        </SidePanelContainer>
                    )}
                </PaneledPage>
            </PageContent>
        </Box>
    );
}

const SidePanelContainer = styled.div`
    height: 100%;
    width: 826px;
    flex: 0 0 826px;
    display: flex;
    z-index: 1;
`;

function SpecText({ before, after, fmt, label }: { before: number; after: number; fmt: (v: number) => string; label: string }) {
    return (
        <Group noWrap spacing={0}>
            <Text size="xs" color="dimmed">
                {label}:
            </Text>
            <Space w={4} />
            <Text size="xs" color="dimmed">
                {fmt(after)}
            </Text>
        </Group>
    );
}

export function SelectionSpecCompare({ recommendation, specMetrics }: { recommendation: Recommendation; specMetrics: IMetricInfo[] }) {
    const results = useMetricComparison(recommendation.OptionSelected, recommendation, specMetrics);
    const details = results.map((r, i) => `${r.label}: ${r.fmt(r.after)}`);

    return (
        <Text size="xs" color="dimmed">
            {details.join(', ')}
        </Text>
    );
}
