import { CompanyRelationshipBundle } from '@apis/Customers/model';
import { FillerSwitch } from '@root/Design/Filler';
import { DataMarketplaceApiService, IDataDefinitionDigest } from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useState } from 'react';
import { ChipMfgMarketshare } from './CustomViews/ChipMfgMarketshare';

export function RelationshipDataPage() {
    const { loading, digest, relationships, definition } = useParamsDataDef();
    const digestPres = digest?.Presentation;

    return (
        <FillerSwitch loading={loading} noData={!loading && !digestPres}>
            {() => (
                <>
                    {digestPres?.CustomView === 'chip-mfg-marketshare' ? (
                        <ChipMfgMarketshare digest={digest!} relationships={relationships!} definition={definition!} />
                    ) : (
                        ''
                    )}
                </>
            )}
        </FillerSwitch>
    );
}

function RelationshipDataBreadcrumb() {
    const { loading, digest } = useParamsDataDef();
    return <>{loading ? 'Loading...' : digest?.Name ?? 'Unknown'}</>;
}

endpoint('relationship-data', RelationshipDataPage, RelationshipDataBreadcrumb);

function useParamsDataDef() {
    const marketplaceSvc = useDi(DataMarketplaceApiService);
    const { id: rawDefId } = useNav().getData('id');
    const defId = parseInt(rawDefId ?? '0');
    const [relationships, setRelationships] = useState<CompanyRelationshipBundle>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        marketplaceSvc
            .getCompanyRelationships()
            .then((data) => {
                setRelationships(data);
            })
            .finally(() => setLoading(false));
    }, [defId]);
    const definition = relationships?.Definitions?.find((d) => d.Id === defId);
    const digest = definition?.Digest as unknown as IDataDefinitionDigest | undefined;

    return { loading, digest, relationships, definition };
}
