import { Tag } from '@apis/Resources/model';
import { Card, Group, Space } from '@mantine/core';
import { isValidValue } from '@root/Components/Resources/TagValidation';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { useEffect, useState } from 'react';
import { CirclePlus } from 'tabler-icons-react';
import { ResourceTagService } from '../Services/ResourceTagService';
import { BulkTagProps } from './BulkTagProps';
import { TagEditor } from './TagEditor';
import { TagField } from './TagField';

export const AddTags = (props: BulkTagProps) => {
    const [tagKeyList, setTagKeyList] = useState<string[]>([]);
    const [tagValueList, setTagValueList] = useState<string[]>([]);
    const [hybridValueList, setHybridValueList] = useState<string[]>([]);
    const resourceSvc = useDi(ResourceService);
    const tagSvc = useDi(ResourceTagService);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [canRemove, setCanRemove] = useState<boolean>(false);
    const [tags, setTags] = useState<Tag[]>([{ Key: '', Value: '' }]);
    const [overwriteConflicts, setOverwriteConflicts] = useState<boolean>(true);

    const getAllKnownValues = async () => {
        let allKeys = (await resourceSvc.getTags()).sort();
        setHybridValueList([...allKeys]);
        return allKeys;
    };

    useEffect(() => {
        (async () => {
            setTagKeyList((await getAllKnownValues()).sort());
            setTagValueList((await resourceSvc.getAllTagValues()).sort());
        })();

        if (props.tagKeyGettingValue) {
            setTagKey(0, props.tagKeyGettingValue);
        }
    }, []);

    useEffect(() => {
        const validForm = formIsValid();
        setDisableSubmit(!validForm);
        setCanRemove(tags.length > 1);
    }, [JSON.stringify(tags)]);

    const formIsValid = () => {
        return tags.filter((t) => t.Key === '' || t.Value === '' || isValidValue(t.Key ?? '') != 0 || isValidValue(t.Value ?? '') != 0).length === 0;
    };

    const addRowHandler = () => {
        setTags((prevState) => {
            let newState = [...prevState];
            newState.push({ Key: '', Value: '' });
            return newState;
        });
    };

    const removeRowHandler = (index: number) => {
        if (tags.length > 1) {
            setTags((prevState) => {
                let newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const setTagKey = (index: number, key: string) => {
        setTags((prevState) => {
            let newState = [...prevState];
            let tag = newState[index];
            tag.Key = key;
            newState.splice(index, 1, tag);
            return newState;
        });
    };

    const setTagValue = (index: number, value: string) => {
        setTags((prevState) => {
            let newState = [...prevState];
            let tag = newState[index];
            tag.Value = value;
            newState.splice(index, 1, tag);
            return newState;
        });
    };

    const handleValueOnFocus = async (index: number = 0) => {
        if (tags[index]?.Key && tagKeyList.includes(tags[index]?.Key!)) {
            setHybridValueList((await resourceSvc.getTagValues(tags[index].Key!)).sort());
        } else {
            setHybridValueList([...tagValueList]);
        }
    };

    const tagJob = tagSvc.createTagJob(props.resourceIds, { AddTags: tags, OverwriteConflicts: overwriteConflicts });

    return (
        <TagEditor
            onButtonClick={props.onButtonClick}
            companyId={props.companyId}
            resourceIds={props.resourceIds}
            pageTitle={props.pageTitle}
            instructions={props.instructions}
            canSubmit={!disableSubmit}
            setDisableSubmit={setDisableSubmit}
            overwriteConflicts={!overwriteConflicts}
            setOverwriteConflicts={setOverwriteConflicts}
            operationType="add"
            tagJob={tagJob}
            onTagJobStarting={props.onTagJobStarting}
            onTagJobStarted={props.onTagJobStarted}
        >
            {tags.map((tag, index) => (
                <>
                    <Card withBorder radius="md" p="sm">
                        <Group noWrap grow key={`group${index}`} spacing={0} mb="xs">
                            <TagField
                                children={
                                    <TagField
                                        atid={'TagKeyInput:' + index.toString()}
                                        key={`AddTagKey${index}`}
                                        fieldValue={tag.Key ?? ''}
                                        index={index}
                                        count={tags.length}
                                        valuePickList={tagKeyList}
                                        label="Key"
                                        setSelectedValue={setTagKey}
                                        canAdd={false}
                                        canRemove={false}
                                        receiveInitalFocus={true}
                                    />
                                }
                                atid={'TagValueInput:' + index}
                                key={`AddTagValue${index}`}
                                fieldValue={tag.Value ?? ''}
                                index={index}
                                count={tags.length}
                                valuePickList={hybridValueList}
                                label="Value"
                                setSelectedValue={setTagValue}
                                handleAddRow={addRowHandler}
                                handleRemoveRow={removeRowHandler}
                                canAdd={true}
                                canRemove={canRemove}
                                onFocus={handleValueOnFocus}
                            />
                        </Group>
                    </Card>
                    <Space h="sm" />
                </>
            ))}
            <Card withBorder radius="md" p="sm">
                <AnchorButton atid="AddTagsAddAnchor" text="Add" onClick={addRowHandler} icon={<CirclePlus size={20} />} />
            </Card>
        </TagEditor>
    );
};
