import { postResourcesCheckAwsResources, postResourcesCheckAzureResources, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource, CheckAwsResourcesJob } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Anchor, Box, Text, Drawer, Group, Stack, Tabs, Title, Tooltip, useMantineTheme, Divider, ActionIcon } from '@mantine/core';
import { keys } from '@mantine/utils';
import { theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useNav } from '@root/Services/NavigationService';
import { Platform } from '@root/Services/PlatformService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { add, addHours } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Refresh } from 'tabler-icons-react';
import { useCompany } from '../Router/CompanyContent';
import { AzureResourceDetails } from './Details/AzureResourceDetails';
import { GetResourceAvailabilityZones } from './ResourceAvailabilityZones';
import { GetResourceBundles } from './ResourceBundles';
import { GetResourceDetails } from './ResourceDetails';
import { GetResourceHistory } from './ResourceHistory';
import { GetResourceInstances } from './ResourceInstances';
import { GetResourceIopsUtilization } from './ResourceIopsUtilization';
import { GetResourceIpAddresses } from './ResourceIpAddresses';
import { GetResourceLaunchConfigurations } from './ResourceLaunchConfigurations';
import { GetResourceRedshiftNodes } from './ResourceRedshiftNodes';
import { GetResourceResources } from './ResourceResources';
import { GetResourceRoute53Records } from './ResourceRoute53Records';
import { GetResourceSnapshots } from './ResourceSnapshots';
import { GetResourceStorage } from './ResourceStorage';
import { GetResourceTags } from './ResourceTags';
import { GetResourceUsage } from './ResourceUsage';

export function GetResourceDetailsTabs(props: { resourceType: string; resourceId: string; onModelLoaded?: (resource: BaseResource) => void }) {
    const [currProperty, setCurrProperty] = useState('');
    function resourcePropertyChange(e: any) {
        setCurrProperty(e);
    }
    const [resource, setStateResource] = useState<BaseResource & { Name: string }>();
    const setResource = (resource: BaseResource & { Name: string }) => {
        setStateResource(resource);
    };

    var resourceContainer;
    switch (props.resourceType) {
        case 'Autoscale':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Instances" value="Instances">
                            Instances
                        </Tabs.Tab>
                        <Tabs.Tab key="Launch Configurations" value="Launch Configurations">
                            Launch Configurations
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Instances">
                        <GetResourceInstances key="Instances" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Launch Configurations">
                        <GetResourceLaunchConfigurations
                            key="Launch Configurations"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EBS':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Volume Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Ec2Details" value="Ec2Details">
                            EC2 Details
                        </Tabs.Tab>
                        <Tabs.Tab key="IopsUtilization" value="IopsUtilization">
                            IOPS Utilization
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Ec2Details">
                        {resource && (
                            <GetResourceDetails
                                onModelLoaded={() => {}}
                                key="Ec2Details"
                                resourceId={`${resource.AccountID}.${(resource as any).Attachments?.[0]?.InstanceId}`}
                                resourceType={'EC2 On Demand'}
                            />
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="IopsUtilization">
                        <GetResourceIopsUtilization key="IopsUtilization" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EC2 On Demand':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Compute" className="resourcesTabs">
                    <Tabs.List>
                        <Tabs.Tab key="Compute" value="Compute">
                            Compute
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Usage" value="Usage">
                            Usage
                        </Tabs.Tab>
                        <Tabs.Tab key="Storage" value="Storage">
                            Storage
                        </Tabs.Tab>
                        <Tabs.Tab key="IpAddresses" value="IpAddresses">
                            IP Addresses
                        </Tabs.Tab>
                        <Tabs.Tab key="Snapshots" value="Snapshots">
                            Snapshots
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Compute">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Compute"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Usage">
                        <GetResourceUsage key="Usage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Storage">
                        <GetResourceStorage key="Storage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="IpAddresses">
                        <GetResourceIpAddresses key="IpAddresses" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Snapshots">
                        <GetResourceSnapshots key="Snapshots" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'ElastiCache Cluster':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Bundle" value="Bundle">
                            Bundle
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Bundle">
                        <GetResourceBundles key="Bundle" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'ELBV1':
        case 'ELBV2':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Usage" value="Usage">
                            Usage
                        </Tabs.Tab>
                        <Tabs.Tab key="AvailabilityZones" value="AvailabilityZones">
                            Availability Zones
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="AvailabilityZones">
                        <GetResourceAvailabilityZones key="AvailabilityZones" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Usage">
                        <GetResourceUsage key="Usage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EMR':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Usage" value="Usage">
                            Usage
                        </Tabs.Tab>
                        <Tabs.Tab key="Resources" value="Resources">
                            Resources
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Resources">
                        <GetResourceResources key="Resources" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Usage">
                        <GetResourceUsage key="Usage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'RDS':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Usage" value="Usage">
                            Usage
                        </Tabs.Tab>
                        <Tabs.Tab key="Snapshots" value="Snapshots">
                            Snapshots
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Usage">
                        <GetResourceUsage key="Usage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Snapshots">
                        <GetResourceSnapshots key="Snapshots" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Redshift':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Nodes" value="Nodes">
                            Nodes
                        </Tabs.Tab>
                        <Tabs.Tab key="Usage" value="Usage">
                            Usage
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Nodes">
                        <GetResourceRedshiftNodes key="Nodes" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Usage">
                        <GetResourceUsage key="Usage" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Route 53':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Records" value="Records">
                            Records
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Records">
                        <GetResourceRoute53Records key="Records" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Workspace':
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Bundle" value="Bundle">
                            Bundle
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Bundle">
                        <GetResourceBundles key="Bundle" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        default:
            resourceContainer = (
                <Tabs onTabChange={resourcePropertyChange} defaultValue="Details">
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <GetResourceDetails
                            onModelLoaded={setResource}
                            key="Details"
                            resourceId={props.resourceId}
                            resourceType={props.resourceType}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags">
                        <GetResourceTags key="Tags" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <GetResourceHistory key="History" resourceId={props.resourceId} resourceType={props.resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
    }

    return resourceContainer;
}

export function ResourceDetails(props: {
    resourceType: string;
    resourceId: string;
    platform: Platform;
    onModelLoaded: (resource: BaseResource) => void;
}) {
    return (
        <ResourceDetailsContainer className="resourcesContainer">
            {props.platform === 'Azure' ? (
                <AzureResourceDetails resourceId={props.resourceId} onModelLoaded={props.onModelLoaded} />
            ) : (
                <Box ml="lg" className="awsResourceDetailsContainer">
                    <GetResourceDetailsTabs resourceType={props.resourceType} resourceId={props.resourceId} onModelLoaded={props.onModelLoaded} />
                </Box>
            )}
        </ResourceDetailsContainer>
    );
}

const ResourceDetailsContainer = styled.div`
    .awsResourceDetailsContainer {
        height: 100%;
        .mantine-Tabs-root {
            height: 100%;
            .mantine-Tabs-panel {
                height: calc(100% - 40px);
                overflow: auto;
            }
        }
    }
`;

export function ResourceDetailsOpener({
    resourceId,
    resourceType,
    platform,
    onClose,
    refreshDataOnly,
    ...props
}: {
    resourceType: string;
    resourceId: string | null | undefined;
    platform: Platform;
    onClose: () => void;
    resource?: BaseResource;
    refreshDataOnly?: () => void;
}) {
    const resourceService = useDi(ResourceService);
    const [resource, setResource] = useState(props.resource);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        if (resourceId && resourceType) {
            resourceService.getResource(resourceId, resourceType).then(setResource);
        }
    }, [resourceId, resourceType, refreshKey]);

    const handleRefresh = useCallback(() => {
        if (resourceId && resourceType) {
            refreshDataOnly?.();
            setRefreshKey((prev) => prev + 1);
        }
    }, [resourceId, resourceType, refreshDataOnly]);

    return (
        <Drawer
            onClose={onClose}
            opened={!!resourceId}
            title={
                <ResourceDrawerTitle
                    key={refreshKey}
                    resource={resource!}
                    resourceName={resource?.Name ?? ''}
                    resourceCsTagsName={platform !== 'Aws' ? '' : resource?.CsTags?.Name ?? ''}
                    resourceId={resource?.Id ?? ''}
                    type={resource?.ResourceType ?? ''}
                    refreshDataOnly={handleRefresh}
                ></ResourceDrawerTitle>
            }
            position="right"
            withinPortal
            size="1000px"
            className="resourceDetailsDrawer"
            style={{ width: '100%' }}
        >
            <ResourceDetails
                key={refreshKey}
                platform={platform}
                resourceType={resourceType}
                onModelLoaded={setResource}
                resourceId={resourceId ?? ''}
            />
        </Drawer>
    );
}

export function ResourceDrawerTitle(props: {
    resource: BaseResource;
    resourceName: string;
    resourceCsTagsName: string;
    resourceId: string;
    type?: string;
    refreshDataOnly?: () => void;
}) {
    const dispResourceName = props.resourceCsTagsName != '' ? props.resourceCsTagsName : props.resourceName;
    let dispResourceId = props.resourceId;
    const dotPos = dispResourceId.indexOf('.');
    dispResourceId = dispResourceId.substring(dotPos + 1);
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const jobSvc = useDi(JobService);
    const pollingSvc = useDi(PollingService);
    const [lastSyncDate, setLastSyncDate] = useState<Date>(new Date(props.resource?.LastSyncDate ?? '12/31/9999'));
    const [nextSyncDate, setNextSyncDate] = useState<Date>(new Date('12/31/9999'));
    const hoursBetweenRefresh = 1;
    const [sync, setSync] = useState<boolean>(false);
    const [syncNeeded, setSyncNeeded] = useState(false);
    function canSync() {
        return !sync && new Date(Date.now()) > nextSyncDate;
    }

    useEffect(() => {
        if (props.resource?.LastSyncDate) {
            setLastSyncDate(new Date(props.resource.LastSyncDate));
            setSync(false);
        }
    }, [props.resource?.LastSyncDate]);

    const updateResource = () => {
        if (props.refreshDataOnly) {
            props.refreshDataOnly();
        }

        setNextSyncDate(addHours(lastSyncDate, hoursBetweenRefresh));
        setSyncNeeded(false);
    };

    const resync = async () => {
        if (canSync() || syncNeeded) {
            if ((!sync || syncNeeded) && props.resource) {
                setSync(true);
                if (props.resource.CloudPlatform === 'Aws') {
                    const job = await postResourcesCheckAwsResources({
                        CompanyId: props.resource.CompanyID!,
                        AccountId: props.resource.AccountID!,
                        Region: props.resource.Region as string,
                        ResourceType: props.resource.ResourceType!,
                        ResourceIds: [props.resource.Id!],
                    });
                    pollCheckAwsResourcesJob(job);
                }
            }
        }
    };

    const pollCheckAwsResourcesJob = async (job: CheckAwsResourcesJob) => {
        const poller = pollingSvc.pollUntil(
            async () => {
                const progressLookup = await jobSvc.getHierarchyProgressLookup([job.HierarchyId!]);
                const progress = [...progressLookup.values()].reduce(
                    (result, item) => {
                        const statusInfo = jobSvc.getStatusInfo(item);
                        result.progress += statusInfo?.progress ?? 0;
                        result.inProgress = result.inProgress || (statusInfo?.inProgress ?? false);
                        return result;
                    },
                    { progress: 0, inProgress: false }
                );
                return progress;
            },
            (result) => !result?.inProgress,
            3000
        );

        await poller;
        updateResource();
    };

    useEffect(() => {
        setNextSyncDate(addHours(lastSyncDate, hoursBetweenRefresh));
    }, [lastSyncDate]);
    const { getDescendUrl } = useNav();

    const title =
        dispResourceId == dispResourceName ? (
            <>
                <Title order={4} m={0} p={0}>
                    {dispResourceName}
                </Title>
            </>
        ) : (
            <>
                <Title order={4} m={0} p={0}>
                    {dispResourceName}
                </Title>
                <Title order={5} m={0} p={0}>
                    {props.type ?? dispResourceId}
                </Title>
            </>
        );

    const resourceSupportsResync = (resource?: BaseResource) => {
        return resource?.CloudPlatform === 'Aws';
    };

    const syncButton = (
        <Box>
            <Text size="sm">Last Sync Date</Text>
            <Group
                spacing={0}
                align="stretch"
                sx={{ background: theme.white, borderRadius: theme.radius.sm, border: `solid 1px ${theme.colors.gray[4]}` }}
            >
                <Stack px="xs" justify="center" sx={{ background: theme.colors.gray[2] }}>
                    {sync ? (
                        <Text size="sm">Updating Resource</Text>
                    ) : (
                        <Tooltip label="Date last synced">
                            <Text size="sm">
                                {lastSyncDate.getFullYear() == 9999
                                    ? 'Loading last sync...'
                                    : lastSyncDate.getFullYear() == 2000
                                    ? 'No results found'
                                    : fmtSvc.formatDatetimeNoSecs(lastSyncDate).toString().replace(',', '')}
                            </Text>
                        </Tooltip>
                    )}
                </Stack>
                <Divider orientation="vertical" />
                <Tooltip
                    label={sync ? 'Resync in progress' : canSync() ? 'Resync resource' : 'Resync available every ' + hoursBetweenRefresh + ' hour'}
                >
                    <ActionIcon disabled={sync || !canSync()} onClick={resync}>
                        <Refresh
                            style={{
                                color: sync ? theme.black : canSync() ? theme.colors.primary[6] : theme.colors.warning[6],
                                height: '16px',
                                width: '16px',
                                cursor: !sync && canSync() ? 'pointer' : 'default',
                            }}
                            className={sync ? 'ti-spin' : ''}
                        />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Anchor href={getDescendUrl('connection-health')} size="xs">
                Check connection health
            </Anchor>
        </Box>
    );
    return (
        <Group position="apart" style={{ width: '900px' }}>
            <div>{title}</div>
            {resourceSupportsResync(props.resource) ? <div>{syncButton}</div> : null}
        </Group>
    );
}
