import { Company } from '@apis/Customers/model';
import { postExportExport } from '@apis/Export';
import { ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { ChangeDetails, Recommendation, RecommendationOption, RecommendationType } from '@apis/Recommendations/model';
import { getMetadataIntegrationGetSchema, QueryResult } from '@apis/Resources';
import { Query, SchemaType } from '@apis/Resources/model';
import { Button, Space, Text, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { generateColor } from '@root/Design/Primitives';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { FieldInfo, SchemaService, SchemaValueProvider, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { RecommendationApiService } from '@root/Services/Recommendations/RecommendationApiService';
import { RecommendationSchemaService } from '@root/Services/Recommendations/RecommendationSchemaService';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FileSpreadsheet } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { FieldInfoColumnAdapter } from '../DataGrid/FieldInfoColumnAdapter';
import { ColumnConfig, DataGridState, DataSourceConfig, IDataSource } from '../DataGrid/Models';
import { QueryExprGroupHelper } from '../DataGrid/QueryExprGroupHelper';
import { IValueProviderFactory } from '../Filter/Filters';
import { ChangeSelector, SpecColumn, TagCell } from './GridComponents';
import { IMetricInfo } from './Options/Models';

interface IRecommendationGridProps {
    resourceType: string;
    persistenceKey: string;
    recommendationType?: RecommendationType;
    fieldConfig: IFieldConfig;
    onClickSelect: (item: Recommendation) => void;
    selected?: Recommendation;
    refreshHandler: (handler: (item?: Recommendation) => void) => void;
}

export interface IFieldConfig {
    currentStateField: string;
    currentStateFieldLabel: string;
    currentStateAccessor: (item: Recommendation) => string;
    selectorRenderer: (item: Recommendation) => React.ReactNode;
    specInfo: IMetricInfo[];
    defaultColumns: string[];
    factorFields: string[];
    columnConfig: Record<string, { format: string; header: string; helpText: string; renderer?: (item: Recommendation) => React.ReactNode }>;
}

export function RecommendationGrid({ resourceType, persistenceKey, recommendationType = 'Size', fieldConfig, ...props }: IRecommendationGridProps) {
    const theme = useMantineTheme();
    const model = useDiMemo(RecommendationGridModel);
    useEffect(() => {
        model.init(resourceType, recommendationType, fieldConfig);
    }, [model, resourceType, recommendationType, JSON.stringify(fieldConfig)]);
    useEffect(() => model.setSelectClickHandler(props.onClickSelect), [model, props.onClickSelect]);
    useEffect(() => props.refreshHandler(model.refresh), [model, props.refreshHandler]);

    const columnGroups = useMemo(
        () => ({
            Selection: { color: theme.colors.success[3] },
            Recommendation: { color: theme.colors.warning[3] },
            Tags: { color: theme.colors.primary[3] },
            'Current Resource': { color: generateColor('Resource') },
            ...model.integrationGroups,
        }),
        [model.integrationGroups]
    );
    const loading = useEventValue(model.loading);
    const persistenceKeyConfig = useMemo(() => ({ key: persistenceKey }), []);

    return (
        <FillerSwitch loading={loading}>
            {() => (
                <DataGrid
                    columns={model.columns}
                    dataSource={model.datasource}
                    requestSize={50}
                    backtrackSize={5}
                    filterValueProvider={model.filterValueProvider}
                    state={model.defaultState}
                    childAccessor={model.childAccessor}
                    onModelLoaded={model.attachGrid}
                    allowGroupBy
                    showHeaderGroups
                    allowPinning
                    allowSavedViews
                    hideGlobalSearch
                    indentLeafNodes
                    showRefresh
                    groupConfig={columnGroups}
                    itemHeight={50}
                    statePersistence={persistenceKeyConfig}
                    highlightColor={theme.colors.primary[2]}
                    selectionMode="single"
                    selection={props.selected as unknown as RecommendationGridRow}
                    onRowClick={(item) => props.onClickSelect(item)}
                    rightToolPlaceHolder={<ExportButton model={model} />}
                    renderGroupBy={(node) => <GroupByRenderer item={node.item} />}
                />
            )}
        </FillerSwitch>
    );
}

function GroupByRenderer({ item }: { item: RecommendationGridRow }) {
    return (
        <Text size="md" sx={{ display: 'flex', alignItems: 'center' }}>
            {item.value !== ValuesGroupOtherText ? item.value : item.groupId === 'Factors.ClusterName' ? 'No cluster' : 'Other'} ({item.count})
        </Text>
    );
}

function ExportButton({ model }: { model: RecommendationGridModel }) {
    const [exporting, setExporting] = useState(false);
    const handleExport = useCallback(async () => {
        setExporting(true);
        try {
            await model.export();
        } finally {
            setExporting(false);
        }
    }, [model]);
    return (
        <>
            <Button
                size="xs"
                radius="lg"
                leftIcon={<FileSpreadsheet size={16} />}
                onClick={handleExport}
                disabled={exporting || !model.canExport()}
                data-atid="ExportButton"
            >
                Export
            </Button>
            <Space w={10} />
        </>
    );
}

type RecommendationGridRow = {
    parent?: RecommendationGridRow;
    count?: number;
    value?: string;
    depth: number;
    groupId: string;
} & Recommendation;

@injectable()
export class RecommendationGridModel {
    public schema?: SchemaService;
    public readonly loading = new EventEmitter(true);
    public columns: ColumnConfig<RecommendationGridRow>[] = [];
    public datasource: DataSourceConfig<RecommendationGridRow> = [];
    public filterValueProvider?: IValueProviderFactory;
    public defaultState?: DataGridState;
    public integrationGroups = {} as Record<string, { color: string }>;

    private gridModel?: DataGridModel;
    private groupQueryHelper?: QueryExprGroupHelper<RecommendationGridRow>;
    private resourceType: string = '';
    private type: RecommendationType = RecommendationType.Size;
    private selectClickHandler: (item: Recommendation) => void = () => {};

    public constructor(
        @inject(RecommendationSchemaService) private readonly schemaSvc: RecommendationSchemaService,
        @inject(RecommendationApiService) private readonly recommendationApi: RecommendationApiService,
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<RecommendationGridRow>,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(JobService) private readonly jobService: JobService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async init(resourceType: string, type: RecommendationType, fieldConfig: IFieldConfig) {
        this.loading.emit(true);
        try {
            this.resourceType = resourceType;
            if (type) {
                this.type = type;
            }
            const metadataIntegrations = await getMetadataIntegrationGetSchema();
            const schemaTypes = await this.schemaSvc.getSchema();
            this.schema = new SchemaService(schemaTypes);
            this.schema.resolveChildren();
            this.datasource = this.createDataSource();
            this.groupQueryHelper = this.createGroupQueryHelper();
            this.filterValueProvider = new SchemaValueProvider(this.schema, (q) => {
                return this.recommendationApi.queryByResourceType(this.resourceType, q);
            });
            this.createIntegrationGroups(metadataIntegrations);
            this.columns = this.createColumns(this.schema, fieldConfig, metadataIntegrations);
            this.defaultState = this.createDefaultState(fieldConfig.defaultColumns, this.columns);
        } finally {
            this.loading.emit(false);
        }
    }

    public setSelectClickHandler(handler: (item: Recommendation) => void): void {
        this.selectClickHandler = handler;
    }

    public refresh = (item?: Recommendation) => {
        if (item) {
            this.gridModel?.treeModel?.invalidateItem(item);
        } else {
            this.gridModel?.refresh();
        }
    };

    public canExport = () => !!this.gridModel;
    public export = async () => {
        try {
            const config = this.gridModel?.getDashboardConfig();
            if (config) {
                const resourceTypeExpr = { Operation: 'eq', Operands: [{ Field: 'ResourceIdentifier.ResourceType' }, { Value: this.resourceType }] };
                const filters = [resourceTypeExpr, ...(config.filters ?? [])];
                const exportConfig = {
                    Layout: config.layout,
                    Name: config.name,
                    Filters: filters,
                };
                exportConfig.Layout[0].data.DashboardItemName = this.type === 'Size' ? `${this.resourceType} Rightsizing` : ``;
                const requestBody = {
                    CompanyId: this.company.Id,
                    Target: ExportRequestTarget.RecommendationQuery,
                    Type: ExportRequestType.Excel,
                    DashboardConfig: exportConfig,
                };
                await postExportExport(requestBody);
                this.notificationSvc.notify(
                    'Export Rightsizing Data',
                    `Export Requested. Please check the activity log for download.`,
                    'primary',
                    <ThemeIcon variant="light" size="xl" radius="xl">
                        <FileSpreadsheet />
                    </ThemeIcon>
                );
                this.jobService.notifyNewJob();
            }
        } catch {
            this.notificationSvc.notify('Export Failed', `Error: Export failed. Please check the activity log for status details.`, 'error', null);
        }
    };

    private handleSelectClick = (item: Recommendation) => {
        this.selectClickHandler(item);
    };

    public attachGrid = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        this.groupQueryHelper?.attach(gridModel);
    };

    public childAccessor = {
        hasChildren: (row: RecommendationGridRow) => row.depth < (this.gridModel?.gridState.groupBy?.length ?? 0),
    };

    private getChangeFieldAccessor = () =>
        this.type === 'Size'
            ? (item?: ChangeDetails) => {
                  return item?.SizeChange?.RecommendedSize;
              }
            : () => '';
    private getChangeFieldPath = () => (this.type === 'Size' ? 'SizeChange.RecommendedSize' : '');

    private createIntegrationGroups(metadataIntegrations: SchemaType[]) {
        this.integrationGroups = metadataIntegrations.reduce(
            (result, item) => Object.assign(result, { [item.Name ?? '']: { color: generateColor(item.Name ?? '') } }),
            {} as Record<string, { color: string }>
        );
    }

    private createColumns(schema: SchemaService, fieldConfig: IFieldConfig, metadataIntegrations: SchemaType[]) {
        const changeFieldAccessor = this.getChangeFieldAccessor();
        const changeFieldPath = this.getChangeFieldPath();
        const integrationNames = new Set(metadataIntegrations.map((t) => t.Name));
        const result = [] as ColumnConfig<RecommendationGridRow>[];
        const factorFields = new Set(fieldConfig.factorFields);
        const excludedFields = new Set([
            'ResourceMetadata.Id',
            'ResourceMetadata.CompanyID',
            'ResourceMetadata.CloudPlatform',
            'ResourceMetadata.LastSyncDate',
            'ResourceMetadata.AccountID',
            'ResourceMetadata.ResourceType',
            'ResourceMetadata.ParentCreateDate',
        ]);
        const isSelected = (o: Recommendation) => this.gridModel?.isSelected(o) ?? false;
        result.push(
            {
                accessor: (o) => o.HasOptions,
                id: 'HasOptions',
                defaultHidden: true,
                allowGrouping: false,
                defaultWidth: 130,
                header: 'Has Options',
                sortField: 'HasOptions',
                cellRenderer: (item: RecommendationGridRow) => (
                    <>
                        {item.Options?.length ? (
                            'Yes'
                        ) : (
                            <Tooltip
                                withinPortal
                                opened
                                label={
                                    <>
                                        {item.IneligibleReasons?.length
                                            ? item.IneligibleReasons.map((r, i) => (
                                                  <Fragment key={i}>
                                                      {r}
                                                      <br />
                                                  </Fragment>
                                              ))
                                            : 'No valid resize options'}
                                    </>
                                }
                            >
                                <span>No</span>
                            </Tooltip>
                        )}
                    </>
                ),
                align: 'center',
                filter: {
                    filterType: 'boolean',
                    name: 'Has Options',
                    filterField: 'HasOptions',
                },
                type: 'boolean',
                noSort: true,
            },
            {
                accessor: (o) => changeFieldAccessor(o.OptionSelected?.ChangeDetails),
                defaultWidth: 140,
                id: `OptionSelector`,
                header: 'New ' + this.type,
                defaultFixed: true,
                allowGrouping: false,
                noRemove: true,
                noReorder: true,
                type: 'string',
                sortField: `OptionSelected.ChangeDetails.${changeFieldPath}`,
                filter: {
                    filterType: 'string',
                    name: 'New ' + this.type,
                    filterField: `OptionSelected.ChangeDetails.${changeFieldPath}`,
                    options: this.filterValueProvider,
                },
                cellRenderer: (item: RecommendationGridRow) => (
                    <ChangeSelector isSelected={isSelected} item={item} changeFieldAccessor={changeFieldAccessor}>
                        {fieldConfig.selectorRenderer(item)}
                    </ChangeSelector>
                ),
            },
            {
                accessor: (o) => o.OptionSelected?.SelectedAt,
                id: `OptionSelected.SelectedAt`,
                header: 'Selected At',
                defaultWidth: 120,
                type: 'date',
                groupName: 'Selection',
                sortField: `OptionSelected.SelectedAt`,
                formatter: (_, value) => this.formatSvc.formatDateWithTodaysTime(value),
                filter: {
                    filterType: 'date',
                    name: 'Selected At',
                    filterField: `OptionSelected.SelectedAt`,
                },
            },
            {
                accessor: 'LastEvaluatedAt',
                id: 'LastEvaluatedAt',
                header: 'Last Evaluated At',
                defaultWidth: 180,
                type: 'date',
                sortField: 'LastEvaluatedAt',
                formatter: (_, value) => this.formatSvc.formatDateWithTodaysTime(value),
                filter: {
                    filterType: 'date',
                    name: 'Last Evaluated At',
                    filterField: 'LastEvaluatedAt',
                },
            },
            ...this.createOptionColumns('OptionSelected', 'Selection', fieldConfig)
        );
        result.push(...this.createOptionColumns('LowestRiskHighestSavings', 'Recommendation', fieldConfig));

        schema.visitFields((field) => {
            if (excludedFields.has(field.path)) {
                return;
            } else if (field.path.indexOf('.CsTags.') > -1) {
                if (field.isPrimitive) {
                    const [, ...tagParts] = field.path.split('.CsTags.');
                    const tag = tagParts.join('.CsTags.');
                    result.push({
                        ...this.adaptColumn(field, 'Tags'),
                        cellRenderer: (item: RecommendationGridRow) => <TagCell item={item} tag={tag} />,
                    });
                }
            } else if (field.path.startsWith('ResourceMetadata.') || (field.path.startsWith('Factors.') && factorFields.has(field.path))) {
                if (field.fieldName === 'FromCloudFormation') {
                    result.push({
                        ...this.adaptColumn(field, 'Current Resource'),
                        header: 'From CloudFormation',
                    });
                } else if (field.fieldName == 'PricePerUnit') {
                    result.push({
                        ...this.adaptColumn(field, 'Current Resource'),
                        header: field.name,
                        defaultWidth: 150,
                        formatter: (item, value) => this.formatSvc.formatMoney4Decimals(value ?? 0),
                    });
                } else if (
                    field.fieldName === 'ExtendedAnnualizedCost' ||
                    field.fieldName === 'AnnualizedCost' ||
                    field.fieldName === 'Last30DaysCost'
                ) {
                    result.push({
                        ...this.adaptColumn(field, 'Current Resource'),
                        header: field.fieldName === 'Last30DaysCost' ? 'Last 30 Days Cost' : field.name,
                        defaultWidth: field.fieldName == 'ExtendedAnnualizedCost' ? 210 : 150,
                        formatter: (_, value) => this.formatSvc.formatMoneyNonZeroTwoDecimals(value ?? 0),
                    });
                } else if (integrationNames.has(field.getPath()[1])) {
                    result.push(this.adaptColumn(field, field.getPath()[1]));
                } else if (field.path === fieldConfig.currentStateField) {
                    result.push({
                        ...this.adaptColumn(field, 'Current Resource'),
                        header: fieldConfig.currentStateFieldLabel,
                        defaultWidth: 200,
                        cellRenderer: (item: RecommendationGridRow) => (
                            <SpecColumn
                                specMetrics={fieldConfig.specInfo}
                                metrics={item.HistoricalUtilizationMetrics ?? {}}
                                state={fieldConfig.currentStateAccessor(item)}
                            />
                        ),
                    });
                } else if (field.path in fieldConfig.columnConfig) {
                    const config = fieldConfig.columnConfig[field.path];
                    const options = this.adaptColumn(field, 'Current Resource', config.helpText, config.format);
                    if (config.renderer) {
                        options.cellRenderer = config.renderer;
                    }
                    result.push({
                        ...options,
                        header: config.header,
                    });
                } else if (field.isPrimitive) {
                    result.push(this.adaptColumn(field, 'Current Resource'));
                }
            }
        });

        result.sort((a, b) => (a.header ?? '').localeCompare(b.header ?? '', undefined, { sensitivity: 'base' }));
        result.forEach((r) => {
            r.allowGrouping = r.allowGrouping === undefined ? r.type === 'string' : r.allowGrouping;
        });

        return result;
    }

    private adaptColumn(field: FieldInfo, group?: string, helpText?: string, format?: string) {
        return this.columnAdapter.adapt(field, { idAsPath: true, group, helpText, format });
    }

    private createOptionColumns(path: keyof Recommendation, group: string, fieldConfig: IFieldConfig) {
        const changeFieldAccessor = this.getChangeFieldAccessor();
        const changeFieldPath = this.getChangeFieldPath();
        const accessor = (o: Recommendation) => o[path] as RecommendationOption | undefined;
        return [
            ...fieldConfig.specInfo.map((spec) => ({
                accessor: (o: Recommendation) => spec.value(accessor(o)?.ExpectedUtilizationMetrics ?? {}),
                defaultWidth: 100,
                id: `${path}.ExpectedUtilizationMetrics.${spec.path}`,
                header: spec.label,
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.ExpectedUtilizationMetrics.${spec.path}`,
                formatter: (_: Recommendation, value: number) => (typeof value === 'number' ? spec.format(value) : ''),
                filter: {
                    filterType: 'number',
                    name: spec.label,
                    filterField: `${path}.ExpectedUtilizationMetrics.${spec.path}`,
                },
            })),
            {
                accessor: (o) => accessor(o)?.ExpectedCost,
                defaultWidth: 140,
                id: `${path}.ExpectedCost`,
                header: 'Unit Price (Expected)',
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.ExpectedCost`,
                formatter: (item, value) => (typeof value === 'number' ? this.formatSvc.formatMoney4Decimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Unit Price (Expected)',
                    filterField: `${path}.ExpectedCost`,
                },
            },
            {
                accessor: (o) => accessor(o)?.ExpectedAnnualCost,
                defaultWidth: 150,
                id: `${path}.ExpectedAnnualCost`,
                header: 'Annual Cost (Expected)',
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.ExpectedAnnualCost`,
                formatter: (item, value) => (typeof value === 'number' ? this.formatSvc.formatMoneyNonZeroTwoDecimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Annual Cost (Expected)',
                    filterField: `${path}.ExpectedAnnualCost`,
                },
            },
            {
                accessor: (o) => changeFieldAccessor(accessor(o)?.ChangeDetails),
                defaultWidth: 200,
                id: `${path}.ChangeDetails.${changeFieldPath}`,
                header: fieldConfig.currentStateFieldLabel,
                type: 'string',
                groupName: group,
                sortField: `${path}.ChangeDetails.${changeFieldPath}`,
                filter: {
                    filterType: 'string',
                    name: this.type,
                    filterField: `${path}.ChangeDetails.${changeFieldPath}`,
                    options: this.filterValueProvider,
                },
                cellRenderer: (item: RecommendationGridRow) =>
                    !accessor(item) ? (
                        <>None</>
                    ) : (
                        <SpecColumn
                            specMetrics={fieldConfig.specInfo}
                            metrics={accessor(item)?.ExpectedUtilizationMetrics ?? {}}
                            state={changeFieldAccessor(accessor(item)?.ChangeDetails) ?? ''}
                        />
                    ),
            },
            {
                accessor: (o) => accessor(o)?.ExpectedSavings,
                defaultWidth: 150,
                id: `${path}.ExpectedSavings`,
                header: 'Unit Price Savings (Expected)',
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.ExpectedSavings`,
                formatter: (item, value) => (typeof value === 'number' ? this.formatSvc.formatMoney4Decimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Unit Price Savings (Expected)',
                    filterField: `${path}.ExpectedSavings`,
                },
            },
            {
                accessor: (o) => accessor(o)?.ExpectedAnnualSavings,
                defaultWidth: 150,
                id: `${path}.ExpectedAnnualSavings`,
                header: 'Annual Savings (Expected)',
                helpText: 'Annualized unit price adjusted by hourly uptime over the last 30 days',
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.ExpectedAnnualSavings`,
                formatter: (item, value) => (typeof value === 'number' ? this.formatSvc.formatMoneyNonZeroTwoDecimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Annual Savings (Expected)',
                    filterField: `${path}.ExpectedAnnualSavings`,
                },
            },
            {
                accessor: (o) => accessor(o)?.RiskRating,
                defaultWidth: 120,
                id: `${path}.RiskRating`,
                header: 'Risk Rating',
                type: 'number',
                align: 'right',
                groupName: group,
                sortField: `${path}.RiskRating`,
                formatter: (item, value) => (typeof value === 'number' ? this.formatSvc.formatPercent(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Risk Rating',
                    filterField: `${path}.RiskRating`,
                },
            },
        ] as ColumnConfig<RecommendationGridRow>[];
    }

    private createGroupQueryHelper() {
        return new QueryExprGroupHelper<RecommendationGridRow>(
            (q) => this.getFlatResults(q),
            (q, depth) => this.getGroupByResults(q, depth)
        );
    }

    private createDataSource() {
        return {
            getPage: async (start, end, state, parent) => {
                return this.groupQueryHelper?.getPage(start, end, state, parent);
            },
        } as IDataSource<RecommendationGridRow>;
    }

    private createDefaultState(defaultColumns: string[], columns: ColumnConfig<RecommendationGridRow>[]) {
        const columnWidths = new Map(columns.map((c) => [c.id, c.defaultWidth]));
        return {
            columns: [
                { id: 'OptionSelector', width: 250, fixed: true },
                ...defaultColumns.map((c) => ({ id: c, width: columnWidths.get(c) })).filter((c) => c.width),
            ],
            filters: [{ Operation: 'eq', Operands: [{ Field: 'HasOptions' }, { Value: true }] }],
            sort: [{ Direction: 'Desc', Expr: { Field: 'LowestRiskHighestSavings.ExpectedAnnualSavings' } }],
        } as DataGridState;
    }

    private async getGroupByResults(query: Query, depth: number) {
        const result = await this.recommendationApi.queryByResourceType(this.resourceType!, query);

        return {
            Count: result.Count ?? 0,
            Results: (result.Results ?? []).map((r) => {
                Object.assign(r, { depth });
                return r as RecommendationGridRow;
            }),
        };
    }

    private async getFlatResults(query: Query) {
        const result = await this.recommendationApi.queryByResourceType(this.resourceType!, query);

        return result as unknown as QueryResult<RecommendationGridRow>;
    }
}
