import { QueryConstant, QueryExpr, QueryField, QueryOperation } from '@apis/Resources';
import { IQueryExpr } from '@apis/Resources/model';
import { FormatService } from '@root/Services/FormatService';
import { PrimitiveType, SchemaService, traverseExpr } from '@root/Services/QueryExpr';
import { ReactNode } from 'react';
import { container } from 'tsyringe';

export interface IQueryToken {
    type: 'operation' | 'field' | 'constant' | 'none';
    name: ReactNode;
    text: string;
    expr: IQueryExpr;
}
export interface IValueNameProvider {
    getName(value: any): string | string[];
}
export interface IFieldNameProvider {
    getName(value: QueryField): string;
}
export interface IExprTypeProvider {
    getType(value: QueryExpr): PrimitiveType;
}
export interface IFieldInfoProvider extends IExprTypeProvider, IFieldNameProvider {}

export class SchemaFieldNameProvider implements IFieldNameProvider, IExprTypeProvider {
    public constructor(private schemaSvc: SchemaService) {
        schemaSvc.resolveChildren();
    }
    public getType(value: QueryExpr): PrimitiveType {
        if (!('Field' in value)) {
            throw new Error(`SchemaFieldNameProvider cannot provide type for non-field expression, ${JSON.stringify(value)}`);
        }
        const field = this.getFieldInfo(value);
        return (field?.field.IsPrimitive ? field?.field.TypeName ?? 'unknown' : 'unknown') as PrimitiveType;
    }
    public getName(value: QueryField) {
        const field = this.getFieldInfo(value);
        return field?.name ?? value.Field;
    }

    private getFieldInfo(expr: QueryField) {
        return this.schemaSvc.getField(expr.Field, expr.Type) ?? this.schemaSvc.getField(expr.Field);
    }
}

export class QueryDescriptorService {
    private formatSvc: FormatService = container.resolve(FormatService);
    private valueNameProvider?: IValueNameProvider;
    public constructor(public fieldInfoProvider: IFieldInfoProvider, private exprTypeProvider: IExprTypeProvider) {}

    public static create(schemaSvc: SchemaService) {
        schemaSvc.resolveChildren();
        const fieldInfoProvider = new SchemaFieldNameProvider(schemaSvc);
        return new QueryDescriptorService(fieldInfoProvider, new ExprTypeProvider(fieldInfoProvider));
    }

    public getDefaultOperation(field: QueryField): QueryOperation {
        const type = this.exprTypeProvider.getType(field);
        switch (type) {
            case 'unknown':
                return { Operation: 'isNull', Operands: [field] };
            case 'string':
                return { Operation: 'eq', Operands: [field, { value: '' }] };
            case 'date':
                return { Operation: 'eq', Operands: [field, { value: new Date() }] };
            case 'boolean':
                return { Operation: 'eq', Operands: [field, { value: true }] };
            case 'number':
                return { Operation: 'eq', Operands: [field, { value: 0 }] };
        }
    }

    public search<T>(expr: QueryExpr, evaluator: (item: QueryExpr) => T | undefined): T | undefined {
        return traverseExpr(expr, evaluator);
    }

    public getExprType(query: QueryExpr) {
        return this.exprTypeProvider.getType(query);
    }

    public getOperationInfo(operation: QueryOperation | string) {
        const opName = (typeof operation === 'string' ? operation : operation.Operation?.toLowerCase()) ?? '';
        return operationLookup.get(opName);
    }

    public getOperationOptions(type: PrimitiveType) {
        return Object.keys(defaultTypeComparitors.get(type) ?? {});
    }

    public getTokensWithValueProvider(expr: Partial<QueryExpr>, valueNameProvider?: IValueNameProvider, type?: string): IQueryToken[] {
        const originalNameProvider = this.valueNameProvider;
        try {
            this.valueNameProvider = valueNameProvider;
            return this.getTokens(expr, type);
        } finally {
            this.valueNameProvider = originalNameProvider;
        }
    }

    public getTokens(expr: Partial<QueryExpr>, type?: string): IQueryToken[] {
        if (expr) {
            if ('Operation' in expr) {
                return this.getOperationTokens(expr as QueryOperation);
            } else if ('Field' in expr) {
                const name = this.fieldInfoProvider.getName(expr as QueryField);
                return [{ expr, name, text: name, type: 'field' }];
            } else if ('Value' in expr) {
                const value = this.formatValue(expr.Value, type);
                return [{ expr, name: value, text: value, type: 'constant' }];
            }
        }
        return [];
    }

    public getName(expr: QueryExpr) {
        return this.getTokens(expr)
            .map((t) => t.text)
            .join(' ');
    }

    protected getOperationTokens(op: QueryOperation) {
        switch (op.Operation) {
            case 'eq':
            case 'ne':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
            case 'startsWith':
            case 'endsWith':
            case 'contains':
            case 'notcontains':
                return this.getComparitorTokens(op);
            case 'isNull':
                return this.getNullCheckTokens(op, true);
            case 'isNotNull':
                return this.getNullCheckTokens(op, false);
            default:
                return this.getGenericOperationTokens(op);
        }
    }

    public getOperationName(op: string, type: PrimitiveType) {
        return operationLookup.get(op.toLowerCase())?.name ?? this.getComparitorByType(op, type);
    }

    public getGenericOperationTokens(op: QueryOperation) {
        const operation = operationLookup.get(op.Operation?.toLowerCase() ?? '');
        if (operation) {
            const result = [{ expr: op, name: operation.name, text: operation.name, type: 'operation' }] as IQueryToken[];
            op.Operands?.forEach((item) => {
                result.push(...this.getTokens(item as QueryExpr));
            });
            return result;
        } else {
            return this.getComparitorTokens(op);
        }
    }

    protected getComparitorTokens(op: QueryOperation): IQueryToken[] {
        const ops = (op.Operands as QueryExpr[]) ?? [];
        const bestType = this.getBestOperandType(op);
        const comparitorName = this.getComparitorByType(op.Operation, bestType ?? 'unknown');
        switch (op.Operation) {
            default:
                return [
                    ...this.getTokens(ops[0], bestType),
                    { expr: op, text: comparitorName, name: comparitorName, type: 'operation' },
                    ...this.getTokens(ops[1], bestType),
                ];
        }
    }

    public getBestOperandType(op: Partial<QueryOperation>) {
        const ops = (op.Operands as QueryExpr[]) ?? [];
        const types = ops.map((o) => this.exprTypeProvider.getType(o));
        const bestType = types.find((t) => t !== 'unknown');
        return bestType ?? 'unknown';
    }

    protected getComparitorByType(op: string, type: PrimitiveType) {
        return defaultTypeComparitors.get(type)?.[op] ?? op;
    }

    protected getNullCheckTokens(op: QueryOperation, isNull: boolean): IQueryToken[] {
        const name = isNull ? 'is blank' : 'is not blank';
        return [...this.getTokens(op.Operands?.[0] as QueryExpr), { expr: op, text: name, name, type: 'operation' }];
    }

    protected formatValue(value: any, type?: string) {
        switch (type) {
            case 'date':
                return value instanceof Date ? this.formatSvc.formatDate(value) : this.formatSvc.formatDate(this.formatSvc.toLocalDate(value));
            default:
                const defaultValue = this.valueNameProvider?.getName(value);
                if (defaultValue) {
                    return defaultValue;
                } else if (value instanceof Array) {
                    const items = value.slice();
                    const last = items.splice(-1, 1);
                    return items.length > 0
                        ? `${items.map((m) => `"${m}"`).join(', ')}${items.length > 1 ? ',' : ''} or "${last}"`
                        : `"${last?.toString()}"` ?? '';
                } else {
                    return this.valueNameProvider?.getName(value) ?? value?.toString() ?? '';
                }
        }
    }
}

export class ExprTypeProvider implements IExprTypeProvider {
    public constructor(private fieldTypeProvider: IExprTypeProvider) {}
    public getType(value: QueryExpr): PrimitiveType {
        if ('Operation' in value) {
            return this.getOperationType(value as QueryOperation);
        } else if ('Field' in value) {
            return this.fieldTypeProvider.getType(value);
        } else if ('Value' in value) {
            return this.getValueType(value);
        }
        return 'unknown';
    }

    private getOperationType(value: QueryOperation): PrimitiveType {
        const operation = operationLookup.get(value.Operation?.toLowerCase() ?? '');
        return operation?.type ?? 'boolean';
    }

    private getValueType(value: QueryConstant) {
        return typeof value === 'string'
            ? 'string'
            : typeof value === 'number'
            ? 'number'
            : typeof value === 'boolean'
            ? 'boolean'
            : value instanceof Date
            ? 'date'
            : 'unknown';
    }
}

interface IOperationInfo {
    name?: string;
    operation: string;
    type: PrimitiveType;
    params: IParameterInfo[];
    aggregate?: boolean;
}
interface IParameterInfo {
    name?: string;
    type?: PrimitiveType;
}
export const operationLookup = new Map<string, IOperationInfo>([
    [
        'count',
        {
            operation: 'count',
            name: 'Count',
            params: [],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'countvalues',
        {
            operation: 'countvalues',
            name: 'Count of',
            params: [{}],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'countuniquevalues',
        {
            operation: 'countuniquevalues',
            name: 'Unique Count of',
            params: [{}],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'sum',
        {
            operation: 'sum',
            name: 'Sum',
            params: [{ type: 'number' }],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'avg',
        {
            operation: 'avg',
            name: 'Average',
            params: [{ type: 'number' }],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'min',
        {
            operation: 'min',
            name: 'Min',
            params: [{ type: 'number' }],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'max',
        {
            operation: 'max',
            name: 'Max',
            params: [{ type: 'number' }],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'percent',
        {
            operation: 'percent',
            name: 'Percent',
            params: [{ type: 'boolean' }],
            type: 'number',
            aggregate: true,
        },
    ],
    [
        'truncdate',
        {
            operation: 'truncdate',
            name: 'Date Histogram',
            params: [{ type: 'date' }, { type: 'string' }, { type: 'string' }, { type: 'date' }, { type: 'date' }],
            type: 'date',
            aggregate: true,
        },
    ],
]);

const defaultTypeComparitors = new Map<PrimitiveType, { [operation: string]: string }>([
    [
        'boolean',
        {
            eq: 'is',
            ne: 'is not',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'date',
        {
            eq: 'is',
            ne: 'is not',
            gt: 'is after',
            gte: 'is on or after',
            lt: 'is before',
            lte: 'is on or before',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'number',
        {
            eq: '=',
            ne: '≠',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<=',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'string',
        {
            eq: 'is',
            ne: 'is not',
            startsWith: 'starts with',
            endsWith: 'ends with',
            contains: 'contains',
            isNull: 'is blank',
            isNotNull: 'is not blank',
            notcontains: 'does not contain',
        },
    ],
    [
        'unknown',
        {
            eq: 'is',
            ne: 'is not',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<=',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
]);
