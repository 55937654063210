import { usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { NotFound } from './NotFound';

export function GetResourceResources(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <NotFound description="No resources"></NotFound>;
        } else {
            var resourceValues = [];
            try {
                const resource = data?.Results[0] as { Instances: { Ec2InstanceId: string; Market: { Value: string }; InstanceType: string }[] };
                for (var j = 0; j < resource.Instances.length; j++) {
                    resourceValues.push({
                        id: resource.Instances[j].Ec2InstanceId,
                        market: resource.Instances[j].Market.Value,
                        type: resource.Instances[j].InstanceType,
                    });
                }
            } catch (Exception) {}

            if (resourceValues.length > 0) {
                let valuesArray = [];
                for (var i = 0; i < resourceValues.length; i++) {
                    valuesArray.push(
                        <tr key={'rr' + i}>
                            <td key={'i' + i} className="label">
                                {resourceValues[i].id}
                            </td>
                            <td key={'m' + i} className="value">
                                {resourceValues[i].market}
                            </td>
                            <td key={'t' + i} className="value">
                                {resourceValues[i].type}
                            </td>
                        </tr>
                    );
                }
                retValue = (
                    <Table>
                        <thead>
                            <tr>
                                <td>Resource Id</td>
                                <td>Market</td>
                                <td className="left">Instance Type</td>
                            </tr>
                        </thead>
                        <tbody>{valuesArray}</tbody>
                    </Table>
                );
            } else {
                retValue = <NotFound description="No resources"></NotFound>;
            }
        }
    }

    return (
        <div className="resourcesTableContainer">
            <ResourceInfoTable>{retValue}</ResourceInfoTable>
        </div>
    );
}

export function ResourceResources() {
    return <>Resources</>;
}
endpoint('resource', ResourceResources, 'Resource Resources');
