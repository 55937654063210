import { useMemo } from 'react';

export function useTickSpacing(lineDist: number = 3, textDist: number = 16) {
    return useMemo(
        () => ({
            lastTickX: {
                text: Infinity,
                line: Infinity,
            },
            reset() {
                this.lastTickX = {
                    text: Infinity,
                    line: Infinity,
                };
            },
            next(value: number) {
                const result = { text: false, line: false };
                if (value < this.lastTickX.text || value - this.lastTickX.text > textDist) {
                    result.text = true;
                    this.lastTickX.text = value;
                }
                if (value < this.lastTickX.line || value - this.lastTickX.line > lineDist) {
                    result.line = true;
                    this.lastTickX.line = value;
                }
                return result;
            },
        }),
        []
    );
}

export interface ChartMargin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}
export type ChartTypes = 'bar' | 'pie' | 'gauge' | 'line' | 'kpi' | 'grid' | 'map' | 'list';
export const chartColors = [
    '#009FE1',
    '#00A79D',
    '#2C607B',
    '#5C4B8C',
    '#CA4127',
    '#E06600',
    '#F4A21E',
    '#67BFE5',
    '#5BC4B7',
    '#5496AF',
    '#847EC1',
    '#DD7D76',
    '#ED863A',
    '#FFBE5F',
    '#0076C1',
    '#00686B',
    '#154054',
    '#3F3E6B',
    '#AF2B19',
    '#AA4900',
    '#D38718',
];
