import { QueryExpr } from '@apis/Resources';
import { Accordion, Group, NumberInput, SegmentedControl } from '@mantine/core';
import { BarChartSettings } from '@root/Components/Charts/BarChart';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { AggregateOperation, ChartMargins, DropdownSettings, EditorSelectExpr, FilterSettings, NumberSettings } from './Components';
import { SettingsLabel, SettingsDivider } from './Design';
import { ChartEditor } from './Models';

export const BarSettings = observer(function BarSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<BarChartSettings>(), []);
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel>Bars</SettingsLabel>
                        <EditorSelectExpr editor={editor} expr={editor.getGroup(0)} onChange={(x) => editor.setGroup(0, x)} types={['string']} />
                    </Group>
                    <Group position="apart">
                        <SettingsLabel>Stack by</SettingsLabel>
                        <EditorSelectExpr
                            editor={editor}
                            expr={editor.getGroup(1)}
                            onChange={(x) => editor.setGroup(1, x)}
                            types={['string']}
                            onRemove={() => editor.removeGroup(1)}
                        />
                    </Group>
                    <Group position="apart">
                        <SettingsLabel>Value</SettingsLabel>
                        <AggregateOperation
                            options={['count', 'countvalues', 'countuniquevalues', 'sum', 'avg', 'min', 'max']}
                            editor={editor}
                            expr={editor.getValue(0).Expr as QueryExpr}
                            onChange={(x) => editor.setValue(0, { Alias: 'Value', Expr: x })}
                            types={['number']}
                        />
                    </Group>
                    <DropdownSettings
                        options={[
                            { label: 'Currency', value: 'money' },
                            { label: 'Currency (whole)', value: 'money-whole' },
                            { label: 'Percent', value: 'percent' },
                        ]}
                        label="Format"
                        onChange={(value) => (settings.format = value as any)}
                        value={settings.format}
                    />
                    <DropdownSettings
                        options={[
                            { label: 'Value Ascending', value: 'value-ascending' },
                            { label: 'Value Descending', value: 'value-descending' },
                            { label: 'Group Ascending', value: 'group-ascending' },
                            { label: 'Group Descending', value: 'group-descending' },
                        ]}
                        label="Sort by"
                        onChange={(value) => {
                            const [sortBy, sort] = (value as string).split('-');
                            settings.sortBy = sortBy as 'group' | 'value';
                            settings.sort = sort as 'ascending' | 'descending';
                        }}
                        value={`${settings.sortBy}-${settings.sort}`}
                    />
                    <NumberSettings label="Top N" onChange={(value) => (settings.topN = value ?? undefined)} value={settings.topN} min={0} />
                    <SettingsDivider />
                    <FilterSettings editor={editor} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="Options">
                <Accordion.Control value="Options">Options</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel icon="ti ti-chart-bar">Chart Type</SettingsLabel>
                        <SegmentedControl
                            value={settings.orientation}
                            data={[
                                { value: 'Vertical', label: 'Column' },
                                { value: 'Horizontal', label: 'Bar' },
                            ]}
                            onChange={(value: 'Vertical' | 'Horizontal') => (settings.orientation = value)}
                        />
                    </Group>
                    <SettingsDivider />
                    <Group position="apart">
                        <SettingsLabel icon="ti ti-angle">Label angle (degrees)</SettingsLabel>
                        <NumberInput
                            value={settings.labelAngle}
                            onChange={(value) => (settings.labelAngle = value)}
                            sx={{ width: '6rem' }}
                            step={15}
                        />
                    </Group>
                    <ChartMargins settings={settings} />
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
});
