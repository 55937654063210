import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, Ebs, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceRedshiftNodes(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();

    var retValue;

    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var rows = [];
    var row;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        const redshift = data?.Results?.[0] as { ClusterNodes?: { NodeRole: string; PrivateIPAddress: string; PublicIPAddress: string }[] };
        if (!redshift || !redshift.ClusterNodes) {
            rows.push({
                name: 'No nodes found',
            });
        } else {
            var nodes = redshift.ClusterNodes;

            for (var i = 0; i < nodes.length; i++) {
                rows.push({
                    role: nodes[i].NodeRole,
                    privateIp: nodes[i].PrivateIPAddress,
                    publicIp: nodes[i].PublicIPAddress,
                });
            }
        }
        retValue = (
            <Table>
                <thead>
                    <tr>
                        <td>Node Role</td>
                        <td>Private IP Address</td>
                        <td className="left">Public IP Address</td>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.role}</td>
                                <td>{val.privateIp}</td>
                                <td>{val.publicIp}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    return (
        <div className="resourcesTableContainer">
            <ResourceInfoTable>{retValue}</ResourceInfoTable>
        </div>
    );
}

export function ResourceRedshiftNodes() {
    return <>Resource Redshift Nodes</>;
}
endpoint('resource', ResourceRedshiftNodes, 'Resource Redshift Nodes');
