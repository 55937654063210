import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { ActionIcon, Box, Button, Divider, Popover, Space, Text, Tooltip } from '@mantine/core';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnConfig, DataColumnConfig } from './Models';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Picker } from '../Picker/Picker';
import { FieldInfo, SchemaService, TypeInfo } from '@root/Services/QueryExpr';
import { FieldPicker } from '../Picker/FieldPicker';
import { BaseResource } from '../Resources/ResourcesGrid';

export interface IColumnSelectorOption {
    column: DataColumnConfig<any>;
    locked?: boolean;
}

export interface IColumnSelectorGroup {
    name: string;
    options: IColumnSelectorOption[];
}

export type ColumnSelectorOption = IColumnSelectorOption | IColumnSelectorGroup;

class ColumnSelectorModel {
    public availableColumns: ColumnSelectorOption[] = [];
    private columnLookup = new Map<string, IColumnSelectorOption>();
    public unpinnedColumns: string[] = [];
    public pinnedColumns: string[] = [];
    public customColumns: ColumnConfig<BaseResource>[] | undefined;
    public selectionChanged = EventEmitter.empty();
    public pendingSelections?: string[] = [];
    public externallyChanged = EventEmitter.empty();
    public onApplying = EventEmitter.empty();

    private aggFieldInfo = new WeakMap<FieldInfo, null | (FieldInfo & { agg: string })[]>();

    public constructor(public readonly allowPinning: boolean) {}

    public init(columns: ColumnSelectorOption[], selected: ColumnSelectorOption[]) {
        this.availableColumns = columns;
        this.columnLookup.clear();
        const cols = columns.slice();
        for (const col of cols) {
            if ('name' in col) {
                cols.push(...col.options);
            } else {
                this.columnLookup.set(col.column.id, col);
            }
        }
        this.unpinnedColumns = [];
        this.pinnedColumns = [];
        for (const item of selected) {
            if ('column' in item) {
                if (item.locked) {
                    this.pinnedColumns.push(item.column.id);
                } else {
                    this.unpinnedColumns.push(item.column.id);
                }
            }
        }
    }

    public getSelectedOptions(preserveInstance?: boolean) {
        const result: IColumnSelectorOption[] = [];
        const getSelections = (source: string[], locked: boolean) => {
            for (const selected of source) {
                const col = this.getColumnById(selected);
                if (col) {
                    if (preserveInstance) {
                        result.push(col);
                    } else {
                        result.push({ column: col.column, locked });
                    }
                }
            }
        };
        getSelections(this.pinnedColumns, true);
        getSelections(this.unpinnedColumns, false);
        return result;
    }

    private createAggId(id: string, agg?: string) {
        return agg ? `${agg}(${id})` : id;
    }

    public getColumnById(id: string) {
        // The second option is for the custom default columns.
        return this.columnLookup.get(id) ?? this.columnLookup.get(id.split(/[.]+/).pop() ?? '');
    }

    public setPinned(id: string, pinned: boolean) {
        const move = (source: string[], target: string[], index: number) => {
            source.splice(source.indexOf(id), 1);
            target.splice(index, 0, id);
        };
        if (pinned) {
            move(this.unpinnedColumns, this.pinnedColumns, Infinity);
        } else {
            move(this.pinnedColumns, this.unpinnedColumns, 0);
        }
        this.selectionChanged.emit();
    }

    public remove(id: string) {
        const removeSelection = (source: string[]) => {
            if (source.length > 1) {
                const idx = source.indexOf(id);
                if (idx >= 0) {
                    source.splice(idx, 1);
                    return true;
                }
            }
            return false;
        };
        if (removeSelection(this.pinnedColumns) || removeSelection(this.unpinnedColumns)) {
            this.selectionChanged.emit();
        }
    }

    public move(id: string, over: string) {
        const moveSelection = (source: string[]) => {
            const activeIdx = source.indexOf(id);
            if (activeIdx >= 0) {
                const overIdx = source.indexOf(over);
                return arrayMove(source, activeIdx, overIdx);
            }
            return source;
        };
        this.pinnedColumns = moveSelection(this.pinnedColumns);
        this.unpinnedColumns = moveSelection(this.unpinnedColumns);
        this.selectionChanged.emit();
    }

    public preparePendingSelections = () => {
        this.pendingSelections = [...this.pinnedColumns, ...this.unpinnedColumns];
        this.addCustomColumns();
    };

    public updatePendingSelections = (items: ColumnSelectorOption[]) => {
        this.pendingSelections = items.map((c) => ('column' in c ? c.column.id : ''));
        this.addCustomColumns();
    };

    public updateFromSchema = (items: FieldInfo[]) => {
        this.pendingSelections = items.map((f) => this.createAggId(f.pathWithRoot, (f as any).agg));
        this.addCustomColumns();
    };

    private addCustomColumns() {
        if (this.customColumns) {
            this.pendingSelections = [...(this.pendingSelections ?? []), ...this.customColumns.map((m) => m.id)];
        }
    }

    public getSchemaSelections = (schema: SchemaService) => {
        const result: FieldInfo[] = [];
        for (const selected of [...this.pinnedColumns, ...this.unpinnedColumns]) {
            const selectedCol = this.getColumnById(selected);
            const field =
                this.getSchemaField(schema, selected) ??
                (selectedCol ? this.getAggSchemaField(schema, selectedCol?.column) : undefined) ??
                (selectedCol ? this.getSchemaByGroupName(schema, selectedCol?.column) : undefined);
            if (field) {
                if (selectedCol?.column.aggregator) {
                    const aggField = this.getAggFieldInfo(field)?.find((f) => f.agg === selectedCol?.column.aggregator);
                    if (aggField) {
                        result.push(aggField);
                    }
                } else {
                    result.push(field);
                }
            }
        }
        return result;
    };

    private getSchemaByGroupName(schema: SchemaService, column: DataColumnConfig<any>) {
        let root = schema.rootTypeInfo.find((f) => f.type.Name == column.groupName && f.type.IsRoot);
        return root?.fields.find((f) => f.field.Name == column.header);
    }

    private getAggSchemaField(schema: SchemaService, column: DataColumnConfig<any>) {
        if (column.aggregator) {
            const fieldId = column.id.substring(column.aggregator.length + 1, column.id.length - 1);
            return this.getSchemaField(schema, fieldId);
        }
        return undefined;
    }

    private getSchemaField(schema: SchemaService, id: string) {
        const [type, ...fieldParts] = id.split('.');
        return schema.getField(fieldParts.join('.'), type);
    }

    public getAggFieldInfo(field: FieldInfo) {
        let result = this.aggFieldInfo.get(field);
        if (result !== null && !result && !('agg' in field)) {
            const column = this.getColumnById(field.pathWithRoot);
            if (column?.column?.aggregations) {
                result = column.column.aggregations.map((agg) =>
                    Object.assign(Object.create(FieldInfo.prototype), field, { agg, name: `${agg} ${field.name}` })
                );
                this.aggFieldInfo.set(field, result);
            } else {
                this.aggFieldInfo.set(field, null);
            }
        }
        return result ?? undefined;
    }

    public applyPendingSelections = () => {
        if (this.pendingSelections) {
            const nextSelections = this.pendingSelections.reduce((result, item) => result.add(item), new Set<string>());
            const pinnedLookup = this.pinnedColumns.reduce((result, item) => result.add(item), new Set<string>());
            this.pinnedColumns = this.pinnedColumns.filter((c) => nextSelections.has(c));
            this.unpinnedColumns = this.pendingSelections.filter((c) => !pinnedLookup.has(c));
            this.pendingSelections = undefined;
            this.selectionChanged.emit();
        }
    };

    public clearPendingSelections = () => {
        this.pendingSelections = undefined;
    };
}

const ColumnSelectorPanel = styled.div`
    height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
`;

const ColumnSelectorToolbar = styled.div`
    flex: 0 0 auto;
    text-align: right;
    padding: ${(p) => p.theme.spacing.lg}px;
`;

const SelectionContainer = styled.div`
    height: 100%;
    overflow: auto;
    flex: 1 1 auto;
    padding: 0 ${(p) => p.theme.spacing.lg}px;
`;
const Selection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.125rem;
    .col-pin {
        display: none;
        text-align: center;
    }
    :hover .col-pin,
    .col-pinned {
        display: block;
    }
`;
const SelectionName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const SelectionText = styled.div`
    flex: 1 1 auto;
    text-align: left;
    overflow: hidden;
`;

const SelectionFooter = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px solid;
    border-color: ${(p) => p.theme.colors.gray[4]};
    padding: ${(p) => p.theme.spacing.sm}px;
`;

function SelectedColumn({
    columnId,
    model,
    setHasChanges,
    pinned,
    columnText,
}: {
    model: ColumnSelectorModel;
    columnId: string;
    setHasChanges: (value: boolean) => void;
    pinned?: boolean;
    columnText?: string | null | undefined;
}) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: columnId });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const column = model.getColumnById(columnId);
    const canPin = model.allowPinning;
    const canRemove = column?.column.noRemove !== true;
    const onRemove = useCallback(() => {
        model.remove(columnId);
        setHasChanges(true);
    }, [model, columnId]);
    const togglePin = useCallback(() => {
        model.setPinned(columnId, !pinned);
        setHasChanges(true);
    }, [model, columnId]);

    return (
        <Selection ref={setNodeRef} style={style}>
            <SelectionText>
                <Text
                    component={SelectionName}
                    {...attributes}
                    {...listeners}
                    sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                    data-atid={(pinned ? 'PinnedColumn:' : 'UnpinnedColumn:') + column?.column?.header}
                >
                    <i className="ti ti-grip-vertical"></i>
                    {column?.column.headerRenderer ? column.column.headerRenderer(column?.column) : column?.column.header}
                </Text>
            </SelectionText>
            {canPin ? (
                <Tooltip label={pinned ? 'Unpin' : 'Pin'} position="bottom">
                    <ActionIcon
                        onClick={togglePin}
                        className={`col-pin ${pinned ? 'col-pinned' : ''}`}
                        data-atid={(pinned ? 'PinnedColumnPin:' : 'UnpinnedColumnPin:') + column?.column?.header}
                    >
                        <i className="ti ti-pin"></i>
                    </ActionIcon>
                </Tooltip>
            ) : null}
            {canRemove ? (
                <ActionIcon onClick={onRemove} data-atid={'ColumnTrashCan:' + column?.column?.header}>
                    <i className="ti ti-trash"></i>
                </ActionIcon>
            ) : null}
        </Selection>
    );
}

export interface IColumnSelectorRenderOptions {
    renderAdd?: (defaultRender: ReactNode) => ReactNode;
    renderItemList?: (defaultRender: ReactNode, model: ColumnSelectorModel) => ReactNode;
    renderFooter?: (defaultRender: ReactNode, model: ColumnSelectorModel) => ReactNode;
    renderColumnGroup?: (defaultRender: ReactNode, group?: string) => ReactNode;
}

interface IColumnSelectorProps {
    allowPinning?: boolean;
    columns: ColumnSelectorOption[];
    selections: IColumnSelectorOption[];
    defaultGroup?: string;
    onBlockClose: (block: boolean) => void;
    onApply: (selections: IColumnSelectorOption[]) => void;
    onCancel: () => void;
    schemaFilter?: (item: TypeInfo | FieldInfo) => boolean;
    schema?: SchemaService;
    onRender?: IColumnSelectorRenderOptions;
}

export function ColumnSelector({ allowPinning, columns, schema, selections, onApply, onCancel, onBlockClose, ...props }: IColumnSelectorProps) {
    const [pickerOpen, setPickerOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const handleChanges = (items: FieldInfo[]) => {
        model.updateFromSchema(items);
        setHasChanges(true);
    };
    const handlePickerChanges = (items: ColumnSelectorOption[], customColumns?: Set<string>) => {
        model.updatePendingSelections(items);
        setHasChanges(true);
    };
    const togglePicker = useCallback(() => {
        setPickerOpen(!pickerOpen);
        if (!pickerOpen) {
            model.preparePendingSelections();
        }
    }, [pickerOpen, setPickerOpen]);
    const cancelPicker = useCallback(() => {
        model.clearPendingSelections();
        setPickerOpen(false);
    }, [setPickerOpen]);
    const applyPicker = useCallback(() => {
        model.applyPendingSelections();
        setPickerOpen(false);
    }, [setPickerOpen]);
    const model = useMemo(() => {
        const result = new ColumnSelectorModel(!!allowPinning);
        result.init(columns, selections);
        return result;
    }, []);
    const setDirty = useCallback(() => setHasChanges(true), [setHasChanges]);
    useEvent(model.externallyChanged, setDirty);
    useEvent(model.selectionChanged);
    useEffect(() => onBlockClose(pickerOpen), [pickerOpen]);
    const onDragEnd = useCallback(
        (e: DragEndEvent) => {
            model.move(e.active.id as string, e.over!.id as string);
            setHasChanges(true);
        },
        [model]
    );
    const apply = useCallback(() => {
        model.onApplying.emit();
        onApply(model.getSelectedOptions());
    }, [onApply, model]);
    const renderField = useCallback(
        (field: FieldInfo) => {
            const column = model.getColumnById(field.pathWithRoot);
            return column?.column.headerRenderer?.(column.column) ?? field.name;
        },
        [model]
    );
    const getFieldChildren = useCallback((item: FieldInfo | TypeInfo) => {
        if (item instanceof TypeInfo) {
            return item.children;
        }
        return model.getAggFieldInfo(item) ?? item.children;
    }, []);

    const renderer = (defaultRender: ReactNode, renderOverride?: (defaultRender: ReactNode, ...params: any[]) => ReactNode, ...params: any[]) => {
        return renderOverride ? renderOverride(defaultRender, ...params) : defaultRender;
    };

    let currGroup: string | undefined = undefined;

    return (
        <ColumnSelectorPanel>
            <SelectionContainer>
                <Popover withinPortal opened={pickerOpen} onClose={applyPicker} shadow="md" withArrow position="bottom">
                    <Popover.Target>
                        {renderer(
                            <Button onClick={togglePicker} variant="subtle" leftIcon={<i className="ti ti-plus"></i>} data-atid="AddColumnsButton">
                                Add Columns
                            </Button>,
                            props.onRender?.renderAdd
                        )}
                    </Popover.Target>
                    <Popover.Dropdown p={0}>
                        <Box sx={{ height: 400 }}>
                            {schema ? (
                                <FieldPicker
                                    mode="multiple"
                                    onChange={handleChanges}
                                    selections={model.getSchemaSelections(schema)}
                                    schema={schema}
                                    schemaFilter={props.schemaFilter}
                                    getChildren={getFieldChildren}
                                    renderItem={(o) => (!('field' in o) ? o.name : renderField(o))}
                                    isDisabled={(o) => o instanceof FieldInfo && model.getColumnById(o.pathWithRoot)?.column.noRemove === true}
                                />
                            ) : (
                                <Picker
                                    items={model.availableColumns}
                                    selections={model.getSelectedOptions(true)}
                                    onChange={handlePickerChanges}
                                    nameAccessor={(c) => ('name' in c ? c.name : c.column.id)}
                                    childAccessor={(c) => ('options' in c ? c.options : undefined)}
                                    isSelectable={(c) => 'column' in c}
                                    renderItem={(c) => ('column' in c ? c.column.header : c.name)}
                                    width={350}
                                />
                            )}
                        </Box>
                        <SelectionFooter>
                            <Button disabled={!hasChanges} onClick={applyPicker} data-atid="ColumnPickerAddButton">
                                Add
                            </Button>
                            <Space w="sm" />
                            <Button onClick={cancelPicker} variant="outline" color="gray" data-atid="ColumnPickerCancelButton">
                                Cancel
                            </Button>
                        </SelectionFooter>
                    </Popover.Dropdown>
                </Popover>
                <Space h="sm" />
                {renderer(
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext strategy={verticalListSortingStrategy} items={model.pinnedColumns}>
                            {model.pinnedColumns.map((c) => {
                                const colGroup = model.getColumnById(c)?.column.groupName ?? props.defaultGroup;
                                const groupText = currGroup === colGroup ? null : (currGroup = colGroup);
                                return (
                                    <Fragment key={c}>
                                        {renderer(
                                            <>
                                                <Text size="xs" color="gray">
                                                    {groupText}
                                                </Text>
                                                <SelectedColumn key={c} pinned columnId={c} model={model} setHasChanges={setHasChanges} />
                                            </>,
                                            props.onRender?.renderColumnGroup,
                                            colGroup
                                        )}
                                    </Fragment>
                                );
                            })}
                        </SortableContext>
                        <SortableContext strategy={verticalListSortingStrategy} items={model.unpinnedColumns}>
                            {model.unpinnedColumns.map((c) => {
                                const colGroup = model.getColumnById(c)?.column.groupName ?? props.defaultGroup;
                                const groupText = currGroup === colGroup ? null : (currGroup = colGroup);
                                return (
                                    <Fragment key={c}>
                                        {renderer(
                                            <>
                                                <Text size="xs" color="gray">
                                                    {groupText}
                                                </Text>
                                                <SelectedColumn
                                                    key={c}
                                                    columnId={c}
                                                    model={model}
                                                    setHasChanges={setHasChanges}
                                                    columnText={model?.getColumnById(c)?.column?.header}
                                                />
                                            </>,
                                            props.onRender?.renderColumnGroup,
                                            colGroup
                                        )}
                                    </Fragment>
                                );
                            })}
                        </SortableContext>
                    </DndContext>,
                    props.onRender?.renderItemList
                )}
            </SelectionContainer>
            <Divider />
            {renderer(
                <ColumnSelectorToolbar>
                    <Button disabled={!hasChanges} onClick={apply} data-atid="ColumnPickerSaveButton">
                        Save
                    </Button>
                    <Space w="xs" sx={{ display: 'inline-block' }} />
                    <Button variant="outline" color="gray" onClick={onCancel} data-atid="ColumnPickerCancelButton">
                        Cancel
                    </Button>
                </ColumnSelectorToolbar>,
                props.onRender?.renderFooter,
                model
            )}
        </ColumnSelectorPanel>
    );
}
