import { Center, Loader } from '@mantine/core';
import { ContentRoot } from '@root/Design/Layout';
import { DiContext, useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader, IChildRouteReady } from '@root/Services/Router/BasicRouteLoader';
import { Fragment, useEffect, useLayoutEffect, useMemo } from 'react';
import { DependencyContainer } from 'tsyringe';

export function ContentRouter(props: { startDepth: number; defaultPage: string }) {
    const loader = useDi(BasicRouteLoader);
    const ready = useEventValue(loader.ready);

    return !ready ? null : <ContentRouterReady {...props} />;
}
function ContentRouterReady({ startDepth = 0, defaultPage }: { startDepth: number; defaultPage: string }) {
    const loader = useDi(BasicRouteLoader);
    const routeMeta = useEventValue(loader.routeMeta);
    const route = useMemo(
        () =>
            routeMeta?.slice(startDepth).map((r) => ({
                Component: r.endpointInfo?.type,
                container: r.container,
                key: r.routeMetaKey,
                params: r.params,
            })) ?? [],
        [routeMeta]
    );

    const tail = route[route.length - 1];
    const nav = useNav();
    if (!route.some((r) => r.Component)) {
        nav.goto(defaultPage);
    }
    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
    }, [routeMeta]);

    return <RouteRenderer key={route[0]?.key ?? 'none'} items={route} index={0} tail={tail} />;
}

interface RouteRendererItem {
    Component: React.ComponentType<{}> | undefined;
    container: DependencyContainer;
    key: string;
    params: Record<string, string>;
}
function RouteRenderer({ items, index, tail }: { items: RouteRendererItem[]; index: number; tail: RouteRendererItem }) {
    const item = items[index];
    const childReady = item?.container.resolve(IChildRouteReady) as EventEmitter<boolean>;
    const ready = useEventValue(childReady);

    if (!item) {
        return <></>;
    }
    const { key, container, params, Component } = item;
    return (
        <>
            <DiContext.Provider value={container}>
                <ContentRoot style={{ display: tail.key === key ? undefined : 'none' }}>
                    {Component ? <Component {...({ ...params } as any)} /> : null}
                </ContentRoot>
            </DiContext.Provider>
            {ready ? (
                <RouteRenderer key={items[index + 1]?.key ?? 'none'} items={items} index={index + 1} tail={tail} />
            ) : (
                <Center sx={{ height: 400 }}>
                    <Loader />
                </Center>
            )}
        </>
    );
}
