import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { IdleResourceGrid, IdleRunningStatusCell, IdleUsedByOtherResourceCell } from '../Components/IdleResourcesGrid';
import { IdleResourcesPage } from '../Components/IdleResourcesPage';
import { IdleResourcesService } from '../Services/IdleResourcesService';
import { useDi } from '@root/Services/DI';
import { ColumnConfig, GridColumnState } from '@root/Components/DataGrid/Models';
import { SubscriptionCheck } from '@root/Components/CompanyInfo/SubscriptionCheck';
import { useMemo, useState } from 'react';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { BaseResource, QueryBasedResourceSelection } from '@root/Components/Resources/ResourcesGrid';
import { useEvent } from '@root/Services/EventEmitter';
import { IdleResourcesActionCard } from '../Components/IdleResourcesActionCard';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { postIdleActionStartIdleResources, postIdleActionStopIdleResources, postResourcesQuery } from '@apis/Resources';
import { SelectedIdleResourceRequest } from '@apis/Resources/model';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { ThemeIcon, useMantineTheme } from '@mantine/core';
import { ListCheck } from 'tabler-icons-react';
import { CustomColors } from '@root/Design/Themes';
import { MspService } from '@root/Services/MspService';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function IdleRDSContent() {
    const company = useCompany();
    const idleResourceService = useDi(IdleResourcesService);
    const notificationSvc = useDi(NotificationService);
    const mspSvc = useDi(MspService);
    const theme = useMantineTheme();
    const resourceColumns: GridColumnState[] = [
        { id: 'RunningStatusRDS', width: 125 },
        { id: 'HasRDSClusterParent', width: 145 },
        { id: 'Metrics.IdleResources.DatabaseConnections', width: 190 },
        { id: 'Metrics.IdleResources.ReadIOPS', width: 110 },
        { id: 'Metrics.IdleResources.WriteIOPS', width: 110 },
    ];
    const [idleResourceGrid, setIdleResourceGrid] = useState<DataGridModel>();
    const [selectedResources, setSelectedResources] = useState<BaseResource[]>();
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>();
    const [selectedResourcesState, setSelectedResourcesState] = useState<'running' | 'stopped' | 'terminated' | ''>('');
    const [showSidePanel, setShowSidePanel] = useState<boolean>();

    const customDefaultColumns = useMemo(() => {
        return [
            {
                accessor: 'ActivityStreamStatus.Value',
                id: 'RunningStatusRDS',
                defaultWidth: 125,
                header: 'Running Status',
                type: 'string',
                filter: {
                    filterField: 'ActivityStreamStatus.Value',
                    filterType: 'string',
                    name: 'Running Status',
                },
                groupName: 'RDS',
                cellRenderer: (item) => <IdleRunningStatusCell item={item} resourceType="RDS" />,
            },
            {
                accessor: (r) => r.ParentResourceId?.ResourceType === 'RDS Cluster',
                id: 'HasRDSClusterParent',
                defaultWidth: 145,
                header: 'Used in RDS Cluster',
                type: 'boolean',
                groupName: 'RDS',
                sortField: 'HasRDSClusterParent',
                filter: {
                    filterField: 'HasRDSClusterParent',
                    filterType: 'boolean',
                    name: 'Used in RDS Cluster',
                },
                cellRenderer: (item) => <IdleUsedByOtherResourceCell used={item.ParentResourceId?.ResourceType === 'RDS Cluster'} />,
            },
        ] as ColumnConfig<BaseResource>[];
    }, []);

    useEvent(idleResourceGrid?.selectionChanged, async () => {
        const selections = idleResourceGrid?.selections as QueryBasedResourceSelection;
        if (selections.count() == 0) {
            setSelectedResources([]);
            setSelectedResourceIds([]);
            setShowSidePanel(false);
            return;
        }

        const selectedResourcesQuery = selections.createIdCriteria();
        const gridCriteria = selections.getResourceQuery();
        const queryToUse = selectedResourcesQuery ? selectedResourcesQuery : gridCriteria;

        const results = await postResourcesQuery(
            {
                IncludeSchema: false,
                IncludeCount: true,
                Where: queryToUse,
                Take: idleResourceGrid?.selections.count(),
            },
            { companyId: company?.Id }
        );

        if (results.Results == undefined || results.Results == null || results.Results.length == 0) {
            clearSelections();
            return;
        }

        const selectedResources = results.Results as BaseResource[];
        if (selectedResources.length == 0) {
            setShowSidePanel(false);
            return;
        }

        if (selectedResources[0].Engine) {
            switch (selectedResources[0].Engine) {
                case 'mysql':
                    setSelectedResourcesState(selectedResources[0].ActivityStreamStatus.Value as 'running' | 'stopped' | 'terminated' | '');
                    break;
                default:
                    setSelectedResourcesState('');
            }
        } else {
            setSelectedResourcesState('');
        }

        setSelectedResources(selectedResources);
        setSelectedResourceIds(selectedResources.map((x) => x.Id!));
        setShowSidePanel(true);
    });

    const clearSelections = () => {
        setSelectedResources([]);
        setSelectedResourceIds([]);
        setShowSidePanel(false);
        idleResourceGrid?.selections.setSelectAll(false);
    };

    const onStart = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });
            await postIdleActionStartIdleResources(resources, { resourceType: 'RDS', companyId: company?.Id });

            notificationSvc.notify(
                'Starting Stopped Resources',
                `The selected resources will be started. There may be a brief delay before their status updates.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };
    const onStop = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });
            await postIdleActionStopIdleResources(resources, { resourceType: 'RDS', companyId: company?.Id });

            notificationSvc.notify(
                'Stopping Idle Resources',
                `The selected resources will be stopped. AWS may automatically restart RDS instances 7 days after they've been stopped. If this happens, ${mspSvc.mspSupportAndLogos.CompanyName} will notify you.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };

    const onCancel = () => {
        clearSelections();
    };

    return (
        <IdleResourcesPage title="RDS" types={['RDS']}>
            <div style={{ height: '100%', width: showSidePanel ? '70%' : '100%', float: showSidePanel ? 'left' : 'unset' }}>
                <IdleResourceGrid
                    refreshNeeded={idleResourceService.refreshNeeded}
                    title="RDS"
                    resourceType="RDS"
                    extraColumns={resourceColumns}
                    setIdleResourcesGrid={setIdleResourceGrid}
                    customDefaultColumns={customDefaultColumns}
                    includeMemory={false}
                />
            </div>

            {showSidePanel && selectedResourceIds != undefined && selectedResourceIds?.length! > 0 && (
                <IdleResourcesActionCard
                    selectedResourceIds={selectedResourceIds}
                    selectedResourceState={selectedResourcesState}
                    onStart={onStart}
                    onStop={onStop}
                    onCancel={onCancel}
                    warning={`The selected resources will be stopped. AWS may automatically restart RDS instances 7 days after they've been stopped. If this happens, ${mspSvc.mspSupportAndLogos.CompanyName} will notify you.`}
                />
            )}
        </IdleResourcesPage>
    );
}

function IdleRDS() {
    return (
        <SubscriptionCheck app="Optimization" featureName={AppFeatureNames.IdleResources}>
            {() => <ConnectionCheck>{() => <IdleRDSContent />}</ConnectionCheck>}
        </SubscriptionCheck>
    );
}
endpoint('idle-rds', IdleRDS, 'Idle Resources');
