import { postResourcesQuery, postTagPolicyGetPolicies } from '@apis/Resources';
import { AwsEffectiveTagPolicy, BaseResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { ActionIcon, Alert, Anchor, Box, Divider, Drawer, Grid, Group, Progress, Space, Stack, Tabs, Text, Title } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, ColumnGroupConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { awsTagPolicyComplianceCounts } from '@root/Components/TagManager/TagPolicyFunctions';
import { PageContent, PanelBody, PanelContent, PanelHeader } from '@root/Design/Layout';
import { generateColor } from '@root/Design/Primitives';
import { theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useEffect, useMemo, useState } from 'react';
import { AlertTriangle, X } from 'tabler-icons-react';
import { OpenSidebar } from '../../Components/OpenSidebar';
import { OperationState } from '../../Components/TagConsolidationSidePanel';
import { TagIntelligencePage } from '../../TagIntelligence/TagIntelligencePage';
import { CoverageNumber, CoverageText, PolicySection } from './Design';

type EffectiveTagPolicyDataItem = {
    key: string;
    capitalizationEnforced: boolean;
    allowedValues: string[];
    enforcedServices: string[];
    numAllowedValues: number;
    numEnforcedServices: number;
    tagRequired: boolean;
    numCompliant: number;
    numNoncompliant: number;
};

type ComplianceCount = {
    count: number;
    compliant: number;
};

export function AwsTagPolicyDetails() {
    const company = useCompany()!;
    let { id } = useNav().getData('id');
    let { accountId } = useNav().getData('accountId');
    const [numInCompliance, setNumInCompliance] = useState(0);
    const [numNotInCompliance, setNumNotInCompliance] = useState(0);
    const [percentInCompliance, setPercentInCompliance] = useState(0);
    const [policyStatements, setPolicyStatements] = useState<EffectiveTagPolicyDataItem[]>();
    const fmtSvc = useDi(FormatService);
    const [selectedPolicyStatement, setSelectedPolicyStatement] = useState<EffectiveTagPolicyDataItem>();
    const [selectedPolicyInitialView, setSelectedPolicyInitialView] = useState('');
    const [effectivePolicy, setEffectivePolicy] = useState<AwsEffectiveTagPolicy>();
    const [effPolicies, setEffPolicies] = useState<AwsEffectiveTagPolicy[]>();
    const [numResourcesInCompliance, setNumResourcesInCompliance] = useState(0);
    const [numResourcesNotInCompliance, setNumResourcesNotInCompliance] = useState(0);
    const [numResourcesTotal, setNumResourcesTotal] = useState(0);

    const routeLoader = useDi(BasicRouteLoader);
    const { getDescendUrl, goto } = useNav(routeLoader.getTopRouteMeta());

    useEffect(() => {
        (async () => {
            const effectivePolicies = await postTagPolicyGetPolicies();
            const effectivePolicy = effectivePolicies.find((f) => f.AccountId == id);
            setEffectivePolicy(effectivePolicy);
            setEffPolicies(effectivePolicies);
            let statementCounts = new Map<string, number>();
            let total = 0;

            if (effectivePolicies != null) {
                const complianceChecks = effectivePolicy?.Statements?.map((s) => s.ComplianceRule);

                const compQuery = queryBuilder<BaseResource>()
                    .where((b) => b.model.AccountID!.eq(parseInt(id ?? '0')))
                    .select((b) => ({
                        count: b.count(),
                    }))
                    .build();
                compQuery.Select!.push({
                    Alias: 'compliant',
                    Expr: { operation: 'countif', operands: [{ operation: 'and', operands: [...complianceChecks!] }] },
                });

                var complianceResources = await postResourcesQuery(compQuery, { companyId: company?.Id });
                const accountCr = (complianceResources.Results as ComplianceCount[])?.[0];
                total = accountCr?.count ?? 0;
                const compliant = accountCr?.compliant ?? 0;
                const nonCompliant = total - compliant;
                setNumResourcesTotal(total);
                setNumResourcesInCompliance(compliant);
                setNumResourcesNotInCompliance(nonCompliant);

                const query = queryBuilder<BaseResource>()
                    .where((b) => b.model.AccountID!.eq(parseInt(id ?? '0')))
                    .build();
                query.Select = [];

                for (const statement of effectivePolicy?.Statements ?? []) {
                    if (statement.Tag) {
                        query.Select.push({ Alias: statement.Tag, Expr: { operation: 'countif', operands: [statement.ComplianceRule] } });
                    }
                }

                const rawStmtCountResults = ((await postResourcesQuery(query, { companyId: company?.Id }))?.Results?.[0] ?? {}) as Record<
                    string,
                    number
                >;
                statementCounts = Object.keys(rawStmtCountResults).reduce(
                    (acc, key) => acc.set(key, rawStmtCountResults[key]),
                    new Map<string, number>()
                );
            }

            const policyStatements =
                effectivePolicy?.Statements?.map((m) => ({
                    key: m.Tag ?? '',
                    capitalizationEnforced: m.Tag != m.Tag?.toLowerCase(),
                    allowedValues: m.AllowedValues ?? [],
                    enforcedServices: m.AwsEnforcedFor ?? [],
                    numAllowedValues: m.AllowedValues != null ? m.AllowedValues.length : 0,
                    numEnforcedServices: m.AwsEnforcedFor != null ? m.AwsEnforcedFor.length : 0,
                    tagRequired: true,
                    numCompliant: statementCounts.get(m.Tag ?? '') ?? 0,
                    numNoncompliant: total - (statementCounts.get(m.Tag ?? '') ?? 0),
                })) ?? [];
            setPolicyStatements(policyStatements);

            const numCompliant = policyStatements.reduce((a, v) => (a = a + v.numCompliant), 0);
            const numNoncompliant = policyStatements.reduce((a, v) => (a = a + v.numNoncompliant), 0);
            setNumInCompliance(numCompliant);
            setNumNotInCompliance(numNoncompliant);
            setPercentInCompliance(numCompliant + numNoncompliant > 0 ? Math.round((numCompliant / (numCompliant + numNoncompliant)) * 100) : 0);
        })();
    }, []);

    const cols = useMemo(() => {
        return [
            {
                header: 'Statement Key',
                headerRenderer: () => (
                    <HeaderCell>
                        Statement
                        <br />
                        Key
                    </HeaderCell>
                ),
                accessor: 'key',
                defaultWidth: 250,
                id: 'Tag',
                type: 'string',
                align: 'left',
                sortField: 'key',
                filter: {
                    filterField: 'key',
                    filterType: 'string',
                    name: 'Statement Key',
                },
                helpText: 'This is the name of the tag.',
                noRemove: true,
            },
            {
                header: 'Capitalization Enforced',
                headerRenderer: () => (
                    <HeaderCell>
                        Capitalization
                        <br />
                        Enforced
                    </HeaderCell>
                ),
                accessor: (item) => item.capitalizationEnforced,
                cellRenderer: (item) => {
                    return item.capitalizationEnforced ? <i style={{ fontWeight: 'bold' }} color="success" className="ti ti-check"></i> : <></>;
                },
                defaultWidth: 150,
                id: 'Capitalization',
                type: 'string',
                align: 'center',
                sortField: 'capitalizationEnforced',
                filter: {
                    filterField: 'capitalizationEnforced',
                    filterType: 'boolean',
                    name: 'Capitalization Enforced',
                },
                groupName: 'AWS Effective Policy',
                helpText: 'If the first letter in the tag key is capitalized, then all other capitalization must be followed.',
                noRemove: true,
            },
            {
                header: 'Allowed Values',
                headerRenderer: () => (
                    <HeaderCell>
                        Allowed
                        <br />
                        Values
                    </HeaderCell>
                ),
                accessor: (item) => item.numAllowedValues,
                cellRenderer: (item) => {
                    return <PolicyStatementLink num={item.numAllowedValues} show="allowedValues" policy={item}></PolicyStatementLink>;
                },
                defaultWidth: 100,
                id: 'AllowedValues',
                type: 'number',
                align: 'center',
                sortField: 'numAllowedValues',
                filter: {
                    filterField: 'numAllowedValues',
                    filterType: 'number',
                    name: 'Allowed Values',
                },
                groupName: 'AWS Effective Policy',
                helpText: 'Only specified values are allowed for the tag key, including the specified capitalization.',
                noRemove: true,
            },
            {
                header: 'Enforced Services',
                headerRenderer: () => (
                    <HeaderCell>
                        Enforced
                        <br />
                        Services
                    </HeaderCell>
                ),
                accessor: (item) => item.numEnforcedServices,
                cellRenderer: (item) => {
                    return <PolicyStatementLink num={item.numEnforcedServices} show="enforcedServices" policy={item}></PolicyStatementLink>;
                },
                defaultWidth: 130,
                id: 'EnforcedServices',
                type: 'number',
                align: 'center',
                sortField: 'numEnforcedServices',
                filter: {
                    filterField: 'numEnforcedServices',
                    filterType: 'number',
                    name: 'Enforced Services',
                },
                groupName: 'AWS Effective Policy',
                helpText: 'Specifies the resource types (i.e. ec2:instance) for which the policy applies.',
                noRemove: true,
            },
            {
                header: 'Tag Required',
                headerRenderer: () => (
                    <HeaderCell>
                        Tag
                        <br />
                        Required
                    </HeaderCell>
                ),
                accessor: (item) => item.tagRequired,
                cellRenderer: (item) => {
                    return <i style={{ fontWeight: 'bold' }} color={theme.colors!.success![8] ?? '#F00'} className="ti ti-check"></i>;
                },
                defaultWidth: 125,
                id: 'TagRequired',
                type: 'boolean',
                align: 'center',
                sortField: 'tagRequired',
                filter: {
                    filterField: 'tagRequired',
                    filterType: 'boolean',
                    name: 'Tag Required',
                },
                groupName: 'Virtual Policy',
                helpText: 'This tag will be required to create additional resources.',
                noRemove: true,
            },
            {
                header: 'Compliant',
                accessor: (item) => item.numCompliant,
                cellRenderer: (item) => {
                    return <ResourceLink num={item.numCompliant} compliance="In" policy={item}></ResourceLink>;
                },
                defaultWidth: 125,
                id: 'numCompliant',
                type: 'number',
                align: 'center',
                sortField: 'numCompliant',
                filter: {
                    filterField: 'numCompliant',
                    filterType: 'number',
                    name: 'Compliant',
                },
                groupName: 'Tag Keys',
                noRemove: true,
            },
            {
                header: 'Non-Compliant',
                headerRenderer: () => (
                    <HeaderCell>
                        Non-
                        <br />
                        Compliant
                    </HeaderCell>
                ),
                accessor: (item) => item.numNoncompliant,
                cellRenderer: (item) => {
                    return <ResourceLink num={item.numNoncompliant} compliance="Out" policy={item}></ResourceLink>;
                },
                defaultWidth: 125,
                id: 'numNoncompliant',
                type: 'number',
                align: 'center',
                sortField: 'numNoncompliant',
                filter: {
                    filterField: 'numNoncompliant',
                    filterType: 'number',
                    name: 'Non Compliant',
                },
                groupName: 'Tag Keys',
                noRemove: true,
            },
            {
                header: 'Total',
                headerRenderer: () => (
                    <HeaderCell>
                        <br />
                        Total
                    </HeaderCell>
                ),
                accessor: (item) => item.numCompliant + item.numNoncompliant,
                cellRenderer: (item) => {
                    return <ResourceLink num={item.numCompliant + item.numNoncompliant} compliance="Total" policy={item}></ResourceLink>;
                },
                defaultWidth: 140,
                id: 'Total',
                align: 'center',
                type: 'number',
                noRemove: true,
            },
            {
                header: 'Compliance',
                headerRenderer: () => (
                    <HeaderCell>
                        <br />
                        Compliance
                    </HeaderCell>
                ),
                accessor: (item) =>
                    item.numCompliant + item.numNoncompliant > 0
                        ? Math.round((item.numCompliant / (item.numCompliant + item.numNoncompliant)) * 100)
                        : 0,
                sortField: 'Compliance',
                filter: true,
                cellRenderer: (item) => {
                    return (
                        <Group>
                            <div style={{ marginTop: '2px', width: '100px' }}>
                                <Progress
                                    radius="md"
                                    size="md"
                                    value={
                                        item.numCompliant + item.numNoncompliant > 0
                                            ? (item.numCompliant / (item.numCompliant + item.numNoncompliant)) * 100
                                            : 0
                                    }
                                />
                            </div>
                            <div style={{ textAlign: 'right', width: '40px' }}>
                                {Math.round(
                                    item.numCompliant + item.numNoncompliant > 0
                                        ? (item.numCompliant / (item.numCompliant + item.numNoncompliant)) * 100
                                        : 0
                                )}
                                %
                            </div>
                        </Group>
                    );
                },
                exportOptions: { header: 'Compliance %' },
                defaultWidth: 200,
                id: 'Compliance',
                align: 'left',
                type: 'number',
                noRemove: true,
            },
        ] as ColumnConfig<EffectiveTagPolicyDataItem>[];
    }, [policyStatements]);

    var groupConfig: { [groupName: string]: ColumnGroupConfig } = {};
    groupConfig = {};
    groupConfig['AWS Effective Policy'] = { color: '#B0E8FF' };
    groupConfig['Virtual Policy'] = { color: generateColor('Virtual Policy') };
    groupConfig['Tag Keys'] = { color: generateColor('Tag Keys') };

    function PolicyStatementLink({ num, show, policy }: { num: number; show: string; policy: EffectiveTagPolicyDataItem }) {
        return <Anchor onClick={() => openSlider({ show, policy })}>{fmtSvc.formatInt(num)}</Anchor>;
    }
    function ResourceLink({ num, compliance, policy }: { num: number; compliance: string; policy: EffectiveTagPolicyDataItem }) {
        return <Anchor onClick={() => showResources({ compliance, policy })}>{fmtSvc.formatInt(num)}</Anchor>;
    }

    function showResources({ compliance, policy }: { compliance: string; policy: EffectiveTagPolicyDataItem }) {
        const statement = effectivePolicy?.Statements?.find((f) => f.Tag == policy.key);

        const inCompliance = {
            description: `Compliant with statement ${statement?.Tag}`,
            Operation: 'And',
            Operands: [statement?.Filter, statement?.ComplianceRule],
        };
        const notInCompliance = {
            description: `Not compliant with statement ${statement?.Tag}`,
            Operation: 'And',
            Operands: [
                { Operation: 'And', Operands: [statement?.Filter] },
                { Operation: 'Not', Operands: [statement?.ComplianceRule] },
            ],
        };
        const totalCompliance = {
            description: `Statement ${statement?.Tag}`,
            Operation: 'And',
            Operands: [statement?.Filter],
        };

        const filter = compliance == 'In' ? inCompliance : compliance == 'Out' ? notInCompliance : totalCompliance;
        const state: DataGridState = {
            columns: compliance == 'Out' ? [{ id: `Tags.CsTags.${policy.key}`, width: 150 }] : [],
            filters: [filter],
            sort: [],
        };
        const operation: OperationState = {
            operation: OpenSidebar.addTags,
            tagKeyGettingValue: policy.key,
        };
        const stateData = JSON.stringify(state);
        const operationData = JSON.stringify(operation);

        const tagExplorerUrl =
            compliance == 'Out'
                ? getDescendUrl('tag-explorer', {
                      state: stateData,
                      operation: operationData,
                  })
                : getDescendUrl('tag-explorer', {
                      state: stateData,
                  });
        goto(tagExplorerUrl);
    }

    function openSlider({ show, policy }: { show: string; policy: EffectiveTagPolicyDataItem }) {
        setSelectedPolicyInitialView(show);
        setSelectedPolicyStatement(policy);
    }

    function closeSlider() {
        setSelectedPolicyStatement(undefined);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <PageContent>
                <PanelContent>
                    <PanelHeader>
                        <Text size={20}>Effective Tag Policy for Account {accountId}</Text>
                    </PanelHeader>
                    <Divider color="gray.3" />
                    <PanelBody>
                        <Stack>
                            <PolicySection>
                                <Grid px="xs" gutter="xl" columns={13} align="flex-end">
                                    <Grid.Col span={3}>
                                        <div>
                                            <Header>&nbsp;</Header>
                                            <Space h={15} />
                                            <Header>Overall Account Compliance</Header>
                                            <Space h={8} />
                                            <Group>
                                                <div style={{ marginTop: '2px', width: '130px' }}>
                                                    <Progress radius="md" size="md" value={percentInCompliance} />
                                                </div>
                                                <div style={{ textAlign: 'right', width: '40px' }}>{percentInCompliance}%</div>
                                            </Group>
                                        </div>
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <div style={{ textAlign: 'center', width: '100%' }}>
                                            <Header>Tag Keys</Header>
                                            <Divider my="xs" />
                                            <div>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageText>Compliant</CoverageText>
                                                    <CoverageText>Non-Compliant</CoverageText>
                                                    <CoverageText>Total</CoverageText>
                                                </Group>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageNumber>{fmtSvc.formatInt(numInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numNotInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numInCompliance + numNotInCompliance)}</CoverageNumber>
                                                </Group>
                                            </div>
                                        </div>
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <div style={{ textAlign: 'center', width: '100%' }}>
                                            <Header>Taggable Resources</Header>
                                            <Divider my="xs" />
                                            <div>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageText>Compliant</CoverageText>
                                                    <CoverageText>Non-Compliant</CoverageText>
                                                    <CoverageText>Total</CoverageText>
                                                </Group>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesNotInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesTotal)}</CoverageNumber>
                                                </Group>
                                            </div>
                                        </div>
                                    </Grid.Col>
                                </Grid>
                            </PolicySection>
                            <PolicySection>
                                {!policyStatements ? null : (
                                    <div style={{ height: 500 }}>
                                        <DataGrid
                                            dataSource={policyStatements}
                                            columns={cols}
                                            showHeaderGroups={true}
                                            groupConfig={groupConfig}
                                            headerHeight={50}
                                            hideColumnSelector
                                            hideMenu={false}
                                            hideHeader
                                        />
                                    </div>
                                )}
                            </PolicySection>
                        </Stack>
                    </PanelBody>

                    {!selectedPolicyStatement ? null : (
                        <Drawer
                            onClose={closeSlider}
                            opened={!!selectedPolicyStatement}
                            position="right"
                            withinPortal
                            size={500}
                            withCloseButton={false}
                        >
                            <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                                <Group position="apart" px="lg" sx={{ height: 80 }}>
                                    <Title order={4}>{selectedPolicyStatement.key}</Title>
                                    <ActionIcon onClick={closeSlider}>
                                        <X />
                                    </ActionIcon>
                                </Group>
                                <Divider />

                                <div style={{ padding: '16px' }}>
                                    <Tabs defaultValue={selectedPolicyInitialView}>
                                        <Tabs.List>
                                            <Tabs.Tab value="allowedValues">Allowed Values</Tabs.Tab>
                                            <Tabs.Tab value="enforcedServices">Enforced Services</Tabs.Tab>
                                        </Tabs.List>

                                        <Tabs.Panel value="allowedValues" pt="xs">
                                            <Space h={16} />
                                            {selectedPolicyStatement.allowedValues.map((m) => (
                                                <DetailsItem>{m}</DetailsItem>
                                            ))}
                                        </Tabs.Panel>

                                        <Tabs.Panel value="enforcedServices" pt="xs">
                                            <Space h={16} />
                                            <Alert
                                                icon={<AlertTriangle />}
                                                color="warning"
                                                title="Enforcement options do not count towards compliance scores"
                                            >
                                                Because AWS enforcement options are limited and can cause complications, we recommend using them with
                                                caution and interpreting them as a means of <strong>enforcement</strong>, not resource type
                                                applicability.
                                            </Alert>
                                            <Space h={16} />
                                            {selectedPolicyStatement.enforcedServices.length ? null : (
                                                <DetailsItem>
                                                    <Text align="center" italic color="dimmed">
                                                        No Enforced Services
                                                    </Text>
                                                </DetailsItem>
                                            )}
                                            {selectedPolicyStatement.enforcedServices.map((m) => (
                                                <DetailsItem>{m}</DetailsItem>
                                            ))}
                                        </Tabs.Panel>
                                    </Tabs>
                                </div>
                            </Box>
                        </Drawer>
                    )}
                </PanelContent>
            </PageContent>
        </Box>
    );
}

const DetailsItem = styled.div`
    border: 1px solid ${theme.colors!.gray![3]};
    padding: 16px;
`;

const Header = styled.div``;

const HeaderCell = styled.div`
    line-height: 25px;
`;
