import styled from '@emotion/styled';
import { Anchor, Box, Button, Card, Divider, Group, LoadingOverlay, Space, Tabs, Text, TextInput, Title, Tooltip } from '@mantine/core';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { CustomColors, theme } from '@root/Design/Themes';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useMemo, useState } from 'react';
import { SettingsPage } from './SettingsPage';
import { useModals } from '@mantine/modals';
import { useDi, useDiComponent, useDiContainer } from '@root/Services/DI';
import { AdminInviteModal } from './AdminInviteModal';
import { getConnectionTryAssumeRole, postConnectionGetPendingConnection, getConnectionGetCliConnectionInfo } from '@apis/Resources';
import { CliConnectionInfo, PendingCliConnection } from '@apis/Resources/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import {
    LoadingState,
    WizardFormStep,
    ScriptTextArea,
    LoadingValidation,
    WizardFormFooter,
    WizardDownload,
} from '@root/Components/Wizard/WizardForm';
import { ConfigService } from '@root/Services/ConfigService';
import { useNav } from '@root/Services/NavigationService';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { AccountIdValidator, ConnectionWizardFooterText } from './Common';
import { IacConnector } from '@root/Components/Connections/IacConnector';
import { useEvent } from '@root/Services/EventEmitter';
import { useInputState } from '@mantine/hooks';
import { ToolTip } from '@root/Design/Primitives';
import { Link } from '@root/Services/Router/Links';

interface IProps {
    isSelected: boolean;
}

export const PlatformButtonWizard = styled.div<IProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${(p) => p.theme.spacing.md}px;
    line-height: 40px;
    border: 1px solid ${(p) => p.theme.colors?.gray?.[3] as CustomColors};
    border-radius: 10px;
    width: 140px;
    height: 96px;
    color: ${(p) => p.theme.black};
    text-decoration: none;
    box-shadow: ${(p) => p.theme.shadows.sm};
    background: ${(props) => (props.isSelected ? (p) => p.theme.colors?.primary?.[6] as CustomColors : (p) => p.theme.white as CustomColors)};
    &:hover {
        border-color: ${(p) => p.theme.colors.primary[4]};
        box-shadow: 0 0 2px 2px ${(p) => p.theme.colors?.primary?.[2]};
        cursor: pointer;
    }
`;

export const ConnectionButtonWizard = styled.div<IProps>`
    display: block;
    padding: ${(p) => p.theme.spacing.sm}px ${(p) => p.theme.spacing.xl}px;
    border: 1px solid ${(p) => (!p.isSelected ? p.theme.colors?.gray?.[3] : p.theme.colors.primary[6])};
    border-radius: 10px;
    color: ${(props) => (!props.isSelected ? (p) => p.theme.colors?.primary?.[6] as CustomColors : (p) => p.theme.white as CustomColors)};
    text-decoration: none;
    background: ${(props) => (props.isSelected ? (p) => p.theme.colors?.primary?.[6] as CustomColors : (p) => p.theme.white as CustomColors)};
    &:hover {
        border-color: ${(p) => (!p.isSelected ? p.theme.colors.primary[4] : '#0000')};
        cursor: pointer;
    }
    box-shadow: ${(p) => p.theme.shadows.sm};
    position: relative;
    z-index: 2;
`;

export const BottomArrowWizard = styled.div<IProps>`
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        margin-top: -1px;
        visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
        border-top: 25px solid
            ${(props) => (props.isSelected ? (p) => p.theme.colors?.primary?.[6] as CustomColors : (p) => p.theme.colors?.gray[0] as CustomColors)};
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
    }
`;

export const HelpButtonWizard = styled.div`
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(p) => p.theme.colors?.warning?.[4] as CustomColors};
    color: ${(p) => p.theme.white as CustomColors};
    font-size: 16px;
    border-radius: 15px;
    cursor: pointer;
`;

export const ConnectionMethodInstructions = styled.div`
    background-color: ${(p) => p.theme.white as CustomColors};
    border-top: 3px solid ${(p) => p.theme.colors?.primary?.[6] as CustomColors};
    width: 100%;
    margin-top: -3px;
    padding: 16px;
`;

export const H3 = styled.div`
    border-bottom: 1px solid ${(p) => p.theme.colors?.gray?.[4] as CustomColors};
    color: ${(p) => p.theme.colors?.gray?.[6] as CustomColors};
    font-size: 20px;
`;

export function PlatformBoxWizard({ platform, selected }: { platform: string; selected: boolean }) {
    return (
        <Box p="md">
            {platform === CloudService.AWS ? (
                <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ height: 54, filter: selected ? 'brightness(0) invert(1)' : undefined }} />
            ) : (
                <img src="/assets/azure_logo.svg" style={{ width: 61, filter: selected ? 'grayscale() brightness(2)' : undefined }} />
            )}
        </Box>
    );
}

export function ConnectionBox({ method, selected }: { method: string; selected: boolean }) {
    return (
        <Box p="md">
            {method === 'CLI' ? (
                <img src="/assets/cli.svg" style={{ filter: selected ? 'invert(1)' : 'invert(1) brightness(0.5)', height: '100px' }} />
            ) : method === 'CloudFormation' ? (
                <img src="/assets/aws-cloudformation.svg" style={{ filter: selected ? 'brightness(2) grayscale(1)' : undefined, height: '100px' }} />
            ) : method === 'TerraForm' ? (
                <img src="/assets/terraformio-icon.svg" style={{ filter: selected ? 'brightness(0) invert(1)' : undefined, height: '100px' }} />
            ) : (
                <img src="/assets/azure_arm.png" style={{ height: '100px' }} />
            )}
        </Box>
    );
}

export function WhatYoullNeed({ text, info }: { text: string; info: string }) {
    return (
        <Group spacing={0}>
            <Text color="success" size="xl">
                <i className="ti ti-check"></i>
            </Text>
            <Space w="xs" />
            <Text>{text}</Text>
            <ToolTip infoText={info} infoSize="small"></ToolTip>
        </Group>
    );
}

export function MethodHelp({ href, icon, text }: { href: string; icon: string; text: string }) {
    return (
        <Text>
            <a href={href} target="_blank" style={{ textDecoration: 'none', color: theme.colors?.primary?.[6] }}>
                <Box style={{ width: '40px', display: 'inline-block', textAlign: 'center' }}>
                    <i className={icon}></i>
                </Box>
                {text}
            </a>
        </Text>
    );
}

enum CloudService {
    AWS = 'AWS',
    Azure = 'Azure',
}

enum ConnectionMethod {
    CLI = 'CLI',
    TerraForm = 'TerraForm',
    CloudFormation = 'CloudFormation',
    ResourceManager = 'ResourceManager',
}

export function ConnectionWizard() {
    const [helpOpen, setHelpOpen] = useState(true);
    const [cloudService, setCloudService] = useState<string>('');
    const [connectionMethod, setConnectionMethod] = useState<string>('');

    const emailToInvite = '';
    const fiveMinutes = 'Average time to complete: 5 minutes';
    const tenMinutes = 'Average time to complete: 10 minutes';
    const sidebarTitle = 'Guide - Connection Wizard';

    const modals = useModals();
    const DiContainer = useDiComponent();
    const openAdminInviteModal = (type: string) => {
        const id = modals.openModal({
            title: 'Invite ' + cloudService + ' Administrator',
            zIndex: 1000,
            children: (
                <DiContainer>
                    <AdminInviteModal onClose={() => modals.closeModal(id)} type={type}></AdminInviteModal>
                </DiContainer>
            ),
        });
    };

    function choosePlatform(platform: string) {
        setCloudService(platform);
        setConnectionMethod('');
    }

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <Box style={{ textAlign: 'center' }}>
                            <Title order={1}>Let's Get Connected</Title>
                            <Space h="md" />
                            <Title order={5}>Select a cloud service to begin.</Title>
                            <Space h="md" />
                            <Group position="center">
                                <Tooltip label={CloudService.AWS + ' cloud service'}>
                                    <PlatformButtonWizard
                                        isSelected={cloudService == CloudService.AWS}
                                        onClick={() => choosePlatform(CloudService.AWS)}
                                        data-atid="AWSButton"
                                    >
                                        <PlatformBoxWizard platform={CloudService.AWS} selected={cloudService == CloudService.AWS} />
                                    </PlatformButtonWizard>
                                </Tooltip>
                                <Tooltip label={'Microsoft ' + CloudService.Azure + ' cloud service'}>
                                    <PlatformButtonWizard
                                        isSelected={cloudService == CloudService.Azure}
                                        onClick={() => choosePlatform(CloudService.Azure)}
                                        data-atid="AzureButton"
                                    >
                                        <PlatformBoxWizard platform={CloudService.Azure} selected={cloudService == CloudService.Azure} />
                                    </PlatformButtonWizard>
                                </Tooltip>
                            </Group>
                        </Box>

                        <Space h={50} />
                        {cloudService == CloudService.AWS ? (
                            <>
                                <Box style={{ textAlign: 'center' }}>
                                    Select a method to connect an account.
                                    <Space h="lg" />
                                    <Group position="center">
                                        <div style={{ position: 'relative' }}>
                                            <BottomArrowWizard isSelected={connectionMethod == ConnectionMethod.CLI}>
                                                <ConnectionButtonWizard
                                                    isSelected={connectionMethod == ConnectionMethod.CLI}
                                                    onClick={() => setConnectionMethod(ConnectionMethod.CLI)}
                                                    data-atid="CLIButton"
                                                >
                                                    <ConnectionBox
                                                        method={ConnectionMethod.CLI}
                                                        selected={connectionMethod == ConnectionMethod.CLI}
                                                    />
                                                    {ConnectionMethod.CLI}
                                                </ConnectionButtonWizard>
                                            </BottomArrowWizard>
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <BottomArrowWizard isSelected={connectionMethod == ConnectionMethod.CloudFormation}>
                                                <ConnectionButtonWizard
                                                    isSelected={connectionMethod == ConnectionMethod.CloudFormation}
                                                    onClick={() => setConnectionMethod(ConnectionMethod.CloudFormation)}
                                                    data-atid="CloudFormationButton"
                                                >
                                                    <ConnectionBox
                                                        method={ConnectionMethod.CloudFormation}
                                                        selected={connectionMethod == ConnectionMethod.CloudFormation}
                                                    />
                                                    {ConnectionMethod.CloudFormation}
                                                </ConnectionButtonWizard>
                                            </BottomArrowWizard>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <BottomArrowWizard isSelected={connectionMethod == ConnectionMethod.TerraForm}>
                                                <ConnectionButtonWizard
                                                    isSelected={connectionMethod == ConnectionMethod.TerraForm}
                                                    onClick={() => setConnectionMethod(ConnectionMethod.TerraForm)}
                                                    data-atid="TerraFormButton"
                                                >
                                                    <ConnectionBox
                                                        method={ConnectionMethod.TerraForm}
                                                        selected={connectionMethod == ConnectionMethod.TerraForm}
                                                    />
                                                    {ConnectionMethod.TerraForm}
                                                </ConnectionButtonWizard>
                                            </BottomArrowWizard>
                                        </div>
                                    </Group>
                                </Box>
                                <Space h={26} />

                                {connectionMethod == ConnectionMethod.CLI ? (
                                    <ConnectionMethodInstructions>
                                        <Box style={{ textAlign: 'center' }}>{fiveMinutes}</Box>
                                        <ConnectCliContentWizard platform="aws" />
                                    </ConnectionMethodInstructions>
                                ) : null}
                                {connectionMethod == ConnectionMethod.CloudFormation ? (
                                    <ConnectionMethodInstructions>
                                        <Box style={{ textAlign: 'center' }}>{tenMinutes}</Box>
                                        <ConnectCfContentWizard />
                                    </ConnectionMethodInstructions>
                                ) : null}
                                {connectionMethod == ConnectionMethod.TerraForm ? (
                                    <ConnectionMethodInstructions>
                                        <Box style={{ textAlign: 'center' }}>{tenMinutes}</Box>
                                        <ConnectTfContentWizard />
                                    </ConnectionMethodInstructions>
                                ) : null}
                            </>
                        ) : null}
                        {cloudService == CloudService.Azure ? (
                            <>
                                <Box style={{ textAlign: 'center' }}>
                                    Select a method to connect a subscription.
                                    <Space h="lg" />
                                    <Group position="center">
                                        <div style={{ position: 'relative' }}>
                                            <LoadingOverlay
                                                visible
                                                overlayColor={theme.colors?.gray?.[3]}
                                                loader={
                                                    <Card withBorder shadow="lg" p="xl" radius="lg">
                                                        Coming soon
                                                    </Card>
                                                }
                                            />
                                            <BottomArrowWizard isSelected={connectionMethod == ConnectionMethod.ResourceManager}>
                                                <ConnectionButtonWizard
                                                    isSelected={connectionMethod == ConnectionMethod.ResourceManager}
                                                    onClick={() => setConnectionMethod(ConnectionMethod.ResourceManager)}
                                                >
                                                    <ConnectionBox
                                                        method={ConnectionMethod.ResourceManager}
                                                        selected={connectionMethod == ConnectionMethod.ResourceManager}
                                                    />
                                                    {ConnectionMethod.ResourceManager}
                                                </ConnectionButtonWizard>
                                            </BottomArrowWizard>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <BottomArrowWizard isSelected={connectionMethod == ConnectionMethod.CLI}>
                                                <ConnectionButtonWizard
                                                    isSelected={connectionMethod == ConnectionMethod.CLI}
                                                    onClick={() => setConnectionMethod(ConnectionMethod.CLI)}
                                                >
                                                    <ConnectionBox
                                                        method={ConnectionMethod.CLI}
                                                        selected={connectionMethod == ConnectionMethod.CLI}
                                                    />
                                                    CLI
                                                </ConnectionButtonWizard>
                                            </BottomArrowWizard>
                                        </div>
                                    </Group>
                                </Box>
                                <Space h={26} />

                                {connectionMethod == ConnectionMethod.ResourceManager ? (
                                    <ConnectionMethodInstructions>
                                        <Box style={{ textAlign: 'center' }}>{tenMinutes}</Box>
                                        *** ResourceManager connection goes here ***
                                    </ConnectionMethodInstructions>
                                ) : null}
                                {connectionMethod == ConnectionMethod.CLI ? (
                                    <ConnectionMethodInstructions>
                                        <Box style={{ textAlign: 'center' }}>{fiveMinutes}</Box>
                                        <ConnectCliContentWizard platform="azure" />
                                    </ConnectionMethodInstructions>
                                ) : null}
                            </>
                        ) : null}
                    </PanelBody>
                    <Divider />
                </PagePanel>
                <Divider orientation="vertical" />
                {helpOpen ? (
                    <PagePanel size={440}>
                        <Group position="apart" style={{ borderBottom: '1px solid ' + theme.colors?.gray?.[4] }}>
                            <Title p="lg" order={3}>
                                {sidebarTitle}
                            </Title>
                            <Box style={{ marginRight: '16px' }}>
                                <HelpButtonWizard onClick={() => setHelpOpen(false)}>
                                    <ChevronLeft size={20} />
                                </HelpButtonWizard>
                            </Box>
                        </Group>
                        <Box p="md">
                            <Space h="lg" />
                            <H3>{cloudService == '' ? 'Overview' : cloudService}</H3>
                            <Space h="md" />
                            <Text weight={700}>{cloudService == '' ? 'Select a cloud service' : "What You'll Need"}</Text>
                            <Space h="md" />
                            {cloudService == '' ? (
                                <Text style={{ fontSize: '14px' }}>
                                    CloudSaver supports {CloudService.AWS} and {CloudService.Azure}. Select one of the cloud services to connect your
                                    account.
                                </Text>
                            ) : (
                                <>
                                    <Text size="sm">
                                        {cloudService == CloudService.AWS ? (
                                            <>
                                                <WhatYoullNeed
                                                    text={'Your ' + cloudService + ' Management Account ID'}
                                                    info="Also known as master account or master payor, we'll need to connect to this account for resource information."
                                                />
                                                <Space h="xs" />
                                                <WhatYoullNeed
                                                    text="Management account admin login credentials"
                                                    info="You'll need access to the management account to deploy the connection"
                                                />
                                                <Space h="xs" />
                                                <WhatYoullNeed
                                                    text="Permission to create IAM roles and policies"
                                                    info="We'll use roles you create to make the connection"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <WhatYoullNeed
                                                    text={cloudService + ' CLI installed and configured'}
                                                    info={'CloudSaver will use CLI to connect to your ' + cloudService + ' subscription.'}
                                                />
                                                <Space h="xs" />
                                                <WhatYoullNeed
                                                    text={'App Administrator ' + cloudService + ' AD or equivalent role'}
                                                    info={
                                                        "You'll need to be the Application Administrator " +
                                                        cloudService +
                                                        ' AD or have a role that allows application registration.'
                                                    }
                                                />
                                                <Space h="xs" />
                                                <WhatYoullNeed
                                                    text="Permission to create custom roles"
                                                    info="You'll need to have permissions that allow creation of custom role/permissions on the subscription level/scope."
                                                />
                                            </>
                                        )}

                                        <Space h="lg" />

                                        <Box style={{ backgroundColor: theme.colors?.gray?.[3], borderRadius: '10px', padding: '20px' }}>
                                            <Text size="md" weight={700}>
                                                Don't Have One of These?
                                            </Text>
                                            <Space h="sm" />
                                            <Text size="sm">Invite your {cloudService} administrator and team members to CloudSaver</Text>
                                            <Space h="sm" />
                                            <Button
                                                data-atid="UsersInviteButton"
                                                sx={{ width: 100 }}
                                                type="submit"
                                                value={emailToInvite}
                                                onClick={() => openAdminInviteModal('bob')}
                                            >
                                                Invite
                                            </Button>
                                        </Box>
                                    </Text>

                                    {connectionMethod == ConnectionMethod.CLI && cloudService == CloudService.AWS ? (
                                        <>
                                            <Space h="lg" />
                                            <H3>CLI</H3>
                                            <Space h="sm" />
                                            <MethodHelp
                                                href={'/assets/connection/' + cloudService + 'CLI.pdf'}
                                                icon="ti ti-file-type-pdf"
                                                text={'Download ' + ConnectionMethod.CLI + ' Instructions'}
                                            />
                                            <Space h="sm" />
                                            <MethodHelp
                                                href="https://knowledge.cloudsaver.com/s/article/Platform-Connection-Wizard-CLI"
                                                icon="ti ti-browser"
                                                text="Knowledge Base Article Link"
                                            />
                                        </>
                                    ) : null}
                                    {connectionMethod == ConnectionMethod.CloudFormation ? (
                                        <>
                                            <Space h="lg" />
                                            <H3>CloudFormation</H3>
                                            <Space h="sm" />
                                            <MethodHelp
                                                href="/assets/connection/CloudFormation.pdf"
                                                icon="ti ti-file-type-pdf"
                                                text={'Download ' + ConnectionMethod.CloudFormation + ' Instructions'}
                                            />
                                            <Space h="sm" />
                                            <MethodHelp
                                                href="https://knowledge.cloudsaver.com/s/article/Connecting-Accounts-with-AWS-CloudFormation"
                                                icon="ti ti-browser"
                                                text="Knowledge Base Article Link"
                                            />
                                        </>
                                    ) : null}
                                    {connectionMethod == ConnectionMethod.TerraForm ? (
                                        <>
                                            <Space h="lg" />
                                            <H3>TerraForm</H3>
                                            <Space h="sm" />
                                            <MethodHelp
                                                href="/assets/connection/TerraForm.pdf"
                                                icon="ti ti-file-type-pdf"
                                                text={'Download ' + ConnectionMethod.TerraForm + ' Instructions'}
                                            />
                                            <Space h="sm" />
                                            <MethodHelp
                                                href="https://knowledge.cloudsaver.com/s/article/Platform-Connection-Wizard-Terraform"
                                                icon="ti ti-browser"
                                                text="Knowledge Base Article Link"
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Box>
                    </PagePanel>
                ) : (
                    <PagePanel size={50}>
                        <Box style={{ marginLeft: '8px', marginTop: '23px' }}>
                            <HelpButtonWizard onClick={() => setHelpOpen(true)}>
                                <ChevronRight size={20} />
                            </HelpButtonWizard>
                        </Box>

                        <Text
                            style={{
                                transform: 'rotate(270deg)',
                                height: '25px',
                                width: '225px',
                                marginTop: '100px',
                                marginLeft: '-90px',
                            }}
                        >
                            {sidebarTitle}
                        </Text>
                    </PagePanel>
                )}
            </PaneledPage>
        </PageContent>
    );
}
endpoint('connections-wizard', ConnectionWizard, 'Connection Wizard');

export function ConnectCliContentWizard({
    hideFooter,
    onReadyChanged,
    ...props
}: {
    hideFooter?: boolean;
    platform: string;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const platform = props.platform ?? useNav().getData('platform')?.platform;
    const { getAscendUrl } = useNav();
    const configSvc = useDi(ConfigService);
    const company = useCompany();

    const [connState, setConnState] = useState<LoadingState>(LoadingState.loading);
    const [connMsg, setConnMsg] = useState<string>('Awaiting connection');
    const [cliScriptWin, setCliScriptWin] = useState<string>('');
    const [cliScriptWinReadonly, setCliScriptWinReadonly] = useState<string>('');
    // const [cliScriptMac, setCliScriptMac] = useState<string>('');
    const [cliScriptLinux, setCliScriptLinux] = useState<string>('');
    const connChecker = useMemo(
        () => ({
            connInfo: null as CliConnectionInfo | null,
            pendingConn: null as PendingCliConnection | null,
            shouldPoll: true,
            poll() {
                setTimeout(async () => {
                    if (!this.shouldPoll) {
                        return;
                    }
                    try {
                        if (this.connInfo) {
                            if (this.pendingConn && this.pendingConn.Complete) {
                                setConnState(LoadingState.check);
                                setConnMsg('Connection successful');
                                this.shouldPoll = false;
                            } else if (this.pendingConn && this.pendingConn.MasterAccount && this.pendingConn.ExternalId) {
                                const assumeResult = await getConnectionTryAssumeRole({
                                    awsAccountId: this.pendingConn.MasterAccount,
                                    externalId: this.pendingConn.ExternalId,
                                });
                                if (assumeResult?.Success) {
                                    setConnState(LoadingState.check);
                                    setConnMsg('Connection successful');
                                    this.shouldPoll = false;
                                }
                            } else {
                                this.pendingConn = await postConnectionGetPendingConnection({ token: this.connInfo.Token! });
                            }
                        }
                    } finally {
                        this.poll();
                    }
                }, 3000);
            },
            stopPolling() {
                this.shouldPoll = false;
            },
        }),
        []
    );

    useEffect(() => {
        (async () => {
            const baseUrl = configSvc.config.apis['Resources'].baseUrl;
            const connInfo = await getConnectionGetCliConnectionInfo();
            connChecker.connInfo = connInfo;
            const [, creds] = (connInfo.Creds ?? '').split(' ');
            const [name, value] = creds.replace(/"/g, '').split('=');
            const domain = new URL(baseUrl).hostname;
            const windowsCli = `$session=New-Object Microsoft.PowerShell.Commands.WebRequestSession; $cookie=New-Object System.Net.Cookie; $cookie.Name='${name}'; $cookie.Domain='${domain}'; $cookie.Value='${value}'; $session.Cookies.Add($cookie); iwr ${baseUrl}/connection/scripts/${
                connInfo.Token
            }/${platform?.toLowerCase()}-cs-connector.ps1 -WebSession $session -OutFile ${platform?.toLowerCase()}-cs-connector.ps1;  .\\${platform?.toLowerCase()}-cs-connector.ps1`;
            setCliScriptWin(windowsCli);
            setCliScriptWinReadonly(windowsCli + ' -ReadOnly');
            const linux = `bash <(curl ${connInfo.Creds} -sL ${baseUrl}/connection/scripts/${
                connInfo.Token
            }/${platform?.toLowerCase()}-cs-connector.sh)`;
            setCliScriptLinux(linux);
            // setCliScriptMac(linux);
        })();
    }, []);

    useEffect(() => {
        connChecker.poll();
        return () => connChecker.stopPolling();
    }, []);

    useEffect(() => {
        onReadyChanged?.(connState === LoadingState.check, connChecker.pendingConn?.MasterAccount ?? '');
    }, [connState === LoadingState.check]);

    return (
        <>
            <WizardFormStep title="1. Copy and paste the script into your preferred CLI" infoText="Bash or Powershell" infoSize="small">
                <Space h="md" />
                <Tabs defaultValue="windows">
                    <Tabs.List>
                        <Tabs.Tab value="windows">Windows</Tabs.Tab>
                        {platform !== 'azure' ? null : <Tabs.Tab value="windows-readonly">Windows - Read Only</Tabs.Tab>}
                        {/* <Tabs.Tab value="macos">MacOS</Tabs.Tab> */}
                        <Tooltip label="Temporarily unavailable" disabled={platform !== 'azure'}>
                            <Tabs.Tab
                                sx={{
                                    cursor: platform === 'azure' ? 'not-allowed' : undefined,
                                }}
                                value="linux"
                                disabled={platform === 'azure'}
                            >
                                Linux
                            </Tabs.Tab>
                        </Tooltip>
                        {platform !== 'azure' ? null : (
                            <Tooltip label="Temporarily unavailable" disabled={platform !== 'azure'}>
                                <Tabs.Tab
                                    sx={{
                                        cursor: platform === 'azure' ? 'not-allowed' : undefined,
                                    }}
                                    value="linux-readonly"
                                    disabled={platform === 'azure'}
                                >
                                    Linux - Read Only
                                </Tabs.Tab>
                            </Tooltip>
                        )}
                    </Tabs.List>
                    <Tabs.Panel value="windows">
                        <ScriptTextArea value={cliScriptWin} label="script area" minRows={3} maxRows={4} />
                    </Tabs.Panel>
                    <Tabs.Panel value="windows-readonly">
                        <ScriptTextArea value={cliScriptWinReadonly} label="script area" minRows={3} maxRows={4} />
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="macos">
            <ScriptTextArea value={cliScriptMac} label="script area" minRows={3} maxRows={4} />
        </Tabs.Panel> */}
                    <Tabs.Panel value="linux">
                        <ScriptTextArea value={cliScriptLinux} label="script area" minRows={3} maxRows={4} />
                    </Tabs.Panel>
                </Tabs>
            </WizardFormStep>
            <WizardFormStep
                title="2. CloudSaver will detect when the script is finished"
                subTitle="We'll check for the script to run and for accounts to be connected."
            >
                <Box ml="lg">
                    <LoadingValidation loadingState={connState} text={connMsg}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {hideFooter ? null : (
                <>
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                component={Link}
                                href={getAscendUrl()}
                                disabled={connState !== LoadingState.check}
                                style={{ pointerEvents: connState !== LoadingState.check ? 'none' : undefined }}
                            >
                                View Connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}

export function ConnectCfContentWizard({
    hideFooter,
    onReadyChanged,
}: {
    hideFooter?: boolean;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const company = useCompany();
    const { move } = useNav();

    useEvent(iacConn.status);
    const [accountId, setAccountId] = useInputState('');
    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);
    function stackSets() {
        window.open('https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/what-is-cfnstacksets.html');
    }
    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);
    useEffect(() => {
        onReadyChanged?.(iacConn.isConnected(), accountId);
    }, [iacConn.isConnected()]);
    return (
        <>
            <WizardFormStep
                title="1. Enter your 12-digit Management Account ID"
                infoText="Also known as master account or master payor, we'll need to connect to this account for resource information."
                infoSize="small"
                subTitle="We use this to find and connect to your AWS Management Account."
            >
                <Group spacing="xs" ml="xl">
                    <TextInput
                        value={accountId}
                        onChange={setAccountId}
                        size="sm"
                        type="number"
                        maxLength={12}
                        placeholder="012345678910"
                        sx={{ width: 200 }}
                        data-atid="AccountIdInput"
                    />
                    <AccountIdValidator condition={accountId.length == 12} />
                </Group>
            </WizardFormStep>
            <WizardFormStep
                title="2. Upload the .yaml file to the Management Account."
                subTitle={
                    <>
                        Use <Anchor onClick={stackSets}>stack sets</Anchor> to apply to all accounts you want to connect to CloudSaver.
                    </>
                }
            >
                <Box ml="xl">
                    <WizardDownload
                        imgLocation="/assets/aws-cloudformation.svg"
                        displayTitle="CloudFormation"
                        displayText="Download YAML"
                        fileLocation={iacConn.getDownloadUrl('cs-cloudformation.yml')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                        atid="CloudFormationDownload"
                    ></WizardDownload>
                </Box>
            </WizardFormStep>
            <WizardFormStep
                title="3. CloudSaver will check for connectivity to the Management Account."
                subTitle="We will use your entered Management Account ID to attempt connection every 3 seconds."
            >
                <Box ml="xl">
                    <LoadingValidation loadingState={iacConn.getLoadingState() as LoadingState} text={iacConn.getStatusMessage()}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {hideFooter ? null : (
                <>
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                disabled={!iacConn.isConnected()}
                                component={Link}
                                onClick={() => move('connections')}
                                style={{ pointerEvents: !iacConn.isConnected() ? 'none' : undefined }}
                            >
                                View Connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}

export function ConnectTfContentWizard({
    hideFooter,
    onReadyChanged,
}: {
    hideFooter?: boolean;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const { getMoveUrl } = useNav();
    const company = useCompany();

    useEvent(iacConn.status);
    const [accountId, setAccountId] = useInputState('');
    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);

    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);

    useEffect(() => {
        onReadyChanged?.(iacConn.isConnected(), accountId);
    }, [iacConn.isConnected()]);

    return (
        <>
            <WizardFormStep
                title="1. Enter your 12-digit Management Account ID"
                infoText="Also known as master account or master payor, we'll need to connect to this account for resource information."
                infoSize="small"
                subTitle="We use this to find and connect CloudSaver to your AWS Management Account."
            >
                <Group spacing="xs" ml="xl">
                    <TextInput value={accountId} onChange={setAccountId} size="sm" type="number" placeholder="012345678910" sx={{ width: 200 }} />
                    <AccountIdValidator condition={accountId.length == 12} />
                </Group>
            </WizardFormStep>
            <WizardFormStep
                title="2. Deploy the .tf file to your accounts."
                subTitle="Start with the Management Account, then deploy to all other accounts you want to connect to CloudSaver. A Management Account is required."
            >
                <Box ml="xl">
                    <WizardDownload
                        imgLocation="/assets/terraformio-icon.svg"
                        displayTitle="Terraform"
                        displayText="Download .tf"
                        fileLocation={iacConn.getDownloadUrl('cs-terraform.tf')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                    ></WizardDownload>
                </Box>
            </WizardFormStep>
            <WizardFormStep
                title="3. CloudSaver will check for connectivity to the Management Account."
                subTitle="Using the Management Account ID you entered, we will attempt to connect every 3 seconds until success."
            >
                <Box ml="xl">
                    <LoadingValidation loadingState={iacConn.getLoadingState() as LoadingState} text={iacConn.getStatusMessage()}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {!hideFooter && (
                <>
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                disabled={!iacConn.isConnected()}
                                component={Link}
                                href={getMoveUrl('connections')}
                                style={{ pointerEvents: !iacConn.isConnected() ? 'none' : undefined }}
                            >
                                View connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}
