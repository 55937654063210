import { CompanyRelationshipBundle, DataAccessDataDefinition } from '@apis/Customers/model';
import { QueryResult } from '@apis/Resources';
import { IQueryExpr, Query } from '@apis/Resources/model';
import { Box, Button, Card, Group, Space, Stack, Sx, Text, ThemeIcon, Title, Tooltip, useMantineTheme } from '@mantine/core';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { DataGrid } from '@root/Components/DataGrid';
import { BaseChartKeySelectionStrategy } from '@root/Components/DataGrid/BaseChartKeySelectionStrategy';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { DateRangeFilter } from '@root/Components/Filter/DateRangeFilter';
import { IncreaseIndicator } from '@root/Design/Data';
import { FillerSwitch } from '@root/Design/Filler';
import { PageBody, PageContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import {
    DataMarketplaceApiService,
    IDataDefinitionDigest,
    ScopedRelationshipBundleService,
} from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { useDi, useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { useGridResizer } from '@root/Site/Invoices/Components/GridResizer';
import { addDays } from 'date-fns';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FileSpreadsheet, Rotate2 } from 'tabler-icons-react';
import { useCompany } from '@root/Components/Router/CompanyContent';

interface IChipMfgMarketshareProps {
    digest: IDataDefinitionDigest;
    definition: DataAccessDataDefinition;
    relationships: CompanyRelationshipBundle;
}
export function ChipMfgMarketshare({ digest, definition, relationships }: IChipMfgMarketshareProps) {
    const mtkApiSvc = useDi(DataMarketplaceApiService);
    const company = useCompany();
    const relSvc = useMemo(
        () => new ScopedRelationshipBundleService(definition.Id!, company!, relationships, mtkApiSvc),
        [definition.Id, relationships, company]
    );
    const chipSvc = useMemo(() => new ChipMfgMarketshareModel(relSvc).init(), [relSvc]);
    const dateRange = useEventValue(chipSvc.dateRange);
    const cspFilter = useEventValue(chipSvc.cspFilter) ?? '';
    const selectedDateRange = useEventValue(chipSvc.selectedDateRange);
    const gridMode = useEventValue(chipSvc.gridMode);
    const deltaLookback = useEventValue(chipSvc.deltaLookback);

    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();

    return (
        <PageBody>
            <PageContent ref={setScrollContainer}>
                <PaneledPage>
                    <Box p="lg" sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', minHeight: 850 }}>
                        <PanelHeader style={{ flex: 0, padding: 0 }}>
                            <Title order={3}>{digest.Name}</Title>
                            <Group sx={{ rowGap: 10 }} position="right">
                                <GridModePicker value={gridMode!} onSelect={chipSvc.updateGridMode} />
                                <CspFilter selected={cspFilter} onSelect={chipSvc.cspFilter.emit} />
                                <DeltaLookbackPicker value={deltaLookback!} onSelect={chipSvc.deltaLookback.emit} />
                                <Box>
                                    <DateRangePicker
                                        constraint={dateRange}
                                        loading={!dateRange}
                                        onSelect={chipSvc.selectedDateRange.emit}
                                        selected={selectedDateRange}
                                    />
                                </Box>
                            </Group>
                        </PanelHeader>
                        <Card withBorder sx={{ flexGrow: 1, minHeight: 250 }} radius="md">
                            <ChipMfgChart chipSvc={chipSvc} />
                        </Card>
                        <Box sx={{ height: '100%', overflow: 'hidden', ...containerStyle }}>
                            <ChipMfgGrid chipSvc={chipSvc} resizer={() => <Resizer />} />
                        </Box>
                    </Box>
                </PaneledPage>
            </PageContent>
        </PageBody>
    );
}

type DeltaLookback = 30 | 90 | 365;

type GridMode = 'company' | 'instance-type';

function CspFilter({ selected, onSelect }: { selected: string; onSelect: (value: string) => void }) {
    return (
        <Button.Group>
            <Button sx={{ width: 80 }} variant={!selected ? 'filled' : 'default'} onClick={() => onSelect('')}>
                All
            </Button>
            <Button sx={{ width: 80 }} variant={selected === 'AWS' ? 'filled' : 'default'} onClick={() => onSelect('AWS')}>
                AWS
            </Button>
            <Button sx={{ width: 80 }} variant={selected === 'Azure' ? 'filled' : 'default'} onClick={() => onSelect('Azure')}>
                Azure
            </Button>
        </Button.Group>
    );
}

function stackedLabelSx(width?: number) {
    return { sx: { width, ['.mantine-Button-label']: { display: 'block', textAlign: 'center', lineHeight: 0.5 } } as Sx };
}

function GridModePicker({ value, onSelect }: { value: GridMode; onSelect: (value: GridMode) => void }) {
    return (
        <Button.Group>
            <Button {...stackedLabelSx()} variant={value === 'instance-type' ? 'filled' : 'default'} onClick={() => onSelect('instance-type')}>
                <Text size="xs" sx={{ lineHeight: 1.5 }}>
                    Instance
                </Text>
                <Text size="xs" sx={{ lineHeight: 1 }}>
                    Type
                </Text>
            </Button>
            <Button variant={value === 'company' ? 'filled' : 'default'} onClick={() => onSelect('company')}>
                Company
            </Button>
        </Button.Group>
    );
}

function DeltaLookbackPicker({ value, onSelect }: { value: DeltaLookback; onSelect: (value: DeltaLookback) => void }) {
    return (
        <Button.Group>
            <Button {...stackedLabelSx(90)} variant={value === 30 ? 'filled' : 'default'} onClick={() => onSelect(30)}>
                <Text size="xs">Last</Text> Month
            </Button>
            <Button {...stackedLabelSx(90)} variant={value === 90 ? 'filled' : 'default'} onClick={() => onSelect(90)}>
                <Text size="xs">Last</Text> Quarter
            </Button>
            <Button {...stackedLabelSx(90)} variant={value === 365 ? 'filled' : 'default'} onClick={() => onSelect(365)}>
                <Text size="xs">Last</Text> Year
            </Button>
        </Button.Group>
    );
}

function DateRangePicker({
    selected,
    constraint,
    onSelect,
    loading,
}: {
    selected?: { from?: Date; to?: Date };
    constraint?: { min?: Date; max?: Date };
    loading: boolean;
    onSelect: (value: { from?: Date; to?: Date }) => void;
}) {
    const defaultSelection = useMemo(() => ({ from: constraint?.min, to: constraint?.max }), [constraint]);

    return loading || !constraint ? null : (
        <>
            <DateRangeFilter
                height={36}
                emptyLabel="All dates"
                corners={3}
                constraint={constraint}
                value={selected?.from && selected?.to ? selected : defaultSelection ?? {}}
                onChange={onSelect}
            />
        </>
    );
}

const mfgChipNames = [
    { mfg: 'AMD', chip: 'AMD', universal: true, dark: '#AD1717', light: '#EBC7C7' },
    { mfg: 'Intel', chip: 'Intel', universal: true, dark: '#157CAD', light: '#C7E0EB' },
    { mfg: 'AWS', chip: 'Graviton', dark: '#AD9117', light: '#EBE5C7' },
    { mfg: 'Azure', chip: 'Ampere', dark: '#14BDD4', light: '#9FDBEB' },
];

interface ChipMfgChartProps {
    chipSvc: ChipMfgMarketshareModel;
}
function ChipMfgChart({ chipSvc }: ChipMfgChartProps) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const csp = useEventValue(chipSvc.cspFilter);
    const dateRange = useEventValue(chipSvc.selectedDateRange);
    const [lineData, setLineData] = useState<Record<string, number | Date | string>[]>([]);
    const [totals, setTotals] = useState<((typeof mfgChipNames)[number] & { value: number; pct: number })[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalLoading, setTotalLoading] = useState(false);
    const queryKey = useMemo(() => ({ value: '', totalValue: '' }), []);
    const selections = useEventValue(chipSvc.rowsSelected);
    const gridMode = useEventValue(chipSvc.gridMode);

    const chartProps = useMemo(
        () => ({
            groups: ['date', 'mfg'],
            values: ['hours'],
            settings: {
                chartColors:
                    gridMode === 'instance-type' && selections?.length
                        ? selections.map((s) => s.color)
                        : ['#157CAD', '#AD1717', '#AD9117', '#14BDD4'],
                hidePoints: true,
                noWrapper: true,
                enableArea: false,
                hideXAxis: true,
                format: 'int',
                interval: 'day',
                margin: { top: 10, right: 10, bottom: 10, left: 60 },
            } as LineChartSettings,
        }),
        [JSON.stringify(selections), gridMode]
    );

    useEffect(() => {
        (async () => {
            let isCurrent = false;
            try {
                const currentKey = JSON.stringify({ csp, dateRange, selections });
                setLoading(true);
                queryKey.value = currentKey;

                const expr = exprBuilder<ChipMfgMarketshareDigest>().createFluentExpr;
                const criteria = [
                    csp ? expr((b) => b.model.CloudProvider.eq(csp)) : null!,
                    dateRange?.from ? expr((b) => b.model.Date.onOrAfter(dateRange.from!)) : null!,
                    dateRange?.to ? expr((b) => b.model.Date.onOrBefore(dateRange.to!)) : null!,
                ].filter((c) => !!c);

                if (selections?.length && gridMode === 'company') {
                    const selectedCompanies = selections.map((s) => s.item.CompanyId);
                    criteria.push(expr((b) => b.model.CustomerId.eq(selectedCompanies)));
                }

                const where =
                    criteria.length > 1
                        ? expr((b) => b.and(...criteria))
                        : criteria.length > 0
                        ? criteria[0]!
                        : expr((b) => b.model.Date.isNotNull());

                let lineData: Record<string, string | number | Date>[] = [];

                if (selections?.length && gridMode === 'instance-type') {
                    const lineDataResponse = (await queryBuilder<ChipMfgMarketshareDigest>()
                        .where(() => where)
                        .select((b) => ({
                            date: b.model.Date,
                            ...selections.reduce(
                                (res, item) => ({
                                    ...res,
                                    [item.item.Instance + ' ' + item.item.Region]: b.aggIf(
                                        b.and(b.model.Instance.eq(item.item.Instance), b.model.Region.eq(item.item.Region)),
                                        b.sum(b.model.vCPUs)
                                    ),
                                }),
                                {}
                            ),
                        }))
                        .execute((q) => chipSvc.query(q))) as unknown as QueryResult<{ date: Date } & { [key: string]: number }>;

                    lineData = (lineDataResponse.Results ?? []).flatMap((r) =>
                        selections.map((s) => ({
                            date: r.date,
                            mfg: s.item.Instance + ' ' + s.item.Region,
                            hours: Math.round((r[s.item.Instance + ' ' + s.item.Region] ?? 0) * 24),
                        }))
                    );
                } else {
                    const lineDataResponse = await queryBuilder<ChipMfgMarketshareDigest>()
                        .where(() => where)
                        .select((b) => {
                            let result = {
                                date: b.model.Date,
                                AMD: b.aggIf(b.model.ProcessorFamily.eq('AMD'), b.sum(b.model.vCPUs)),
                                Intel: b.aggIf(b.model.ProcessorFamily.eq('Intel'), b.sum(b.model.vCPUs)),
                                AWS: b.aggIf(b.model.ProcessorFamily.eq('Graviton'), b.sum(b.model.vCPUs)),
                                Azure: b.aggIf(b.model.ProcessorFamily.eq('Ampere'), b.sum(b.model.vCPUs)),
                            };
                            return result;
                        })
                        .execute((q) => chipSvc.query(q));

                    lineData = (lineDataResponse.Results ?? []).flatMap((r) =>
                        [
                            { date: r.date, mfg: 'Intel', hours: Math.round(r.Intel * 24) },
                            { date: r.date, mfg: 'AMD', hours: Math.round(r.AMD * 24) },
                            { date: r.date, mfg: 'AWS', hours: Math.round(r.AWS * 24) },
                            { date: r.date, mfg: 'Azure', hours: Math.round(r.Azure * 24) },
                        ].filter((d) => !csp || mfgChipNames.find((n) => n.mfg === d.mfg)?.universal || d.mfg === csp)
                    );
                }

                if (queryKey.value === currentKey) {
                    isCurrent = true;
                    setLineData(lineData);
                }
            } finally {
                if (isCurrent) {
                    setLoading(false);
                }
            }
        })();
    }, [csp, gridMode, dateRange?.from, dateRange?.to, JSON.stringify(selections)]);

    useEffect(() => {
        (async () => {
            let isCurrent = false;
            try {
                const currentKey = JSON.stringify({ csp, dateRange });
                setTotalLoading(true);
                queryKey.totalValue = currentKey;

                const expr = exprBuilder<ChipMfgMarketshareDigest>().createFluentExpr;
                const criteria = [
                    csp ? expr((b) => b.model.CloudProvider.eq(csp)) : null!,
                    dateRange?.from ? expr((b) => b.model.Date.onOrAfter(dateRange.from!)) : null!,
                    dateRange?.to ? expr((b) => b.model.Date.onOrBefore(dateRange.to!)) : null!,
                ].filter((c) => !!c);
                const where =
                    criteria.length > 1
                        ? expr((b) => b.and(...criteria))
                        : criteria.length > 0
                        ? criteria[0]!
                        : expr((b) => b.model.Date.isNotNull());

                const totalsResponse = await queryBuilder<ChipMfgMarketshareDigest>()
                    .where(() => where)
                    .select((b) => ({
                        total: b.sum(b.model.vCPUs),
                        AMD: b.aggIf(b.model.ProcessorFamily.eq('AMD'), b.sum(b.model.vCPUs)),
                        Intel: b.aggIf(b.model.ProcessorFamily.eq('Intel'), b.sum(b.model.vCPUs)),
                        AWS: b.aggIf(b.model.ProcessorFamily.eq('Graviton'), b.sum(b.model.vCPUs)),
                        Azure: b.aggIf(b.model.ProcessorFamily.eq('Ampere'), b.sum(b.model.vCPUs)),
                    }))
                    .execute((q) => chipSvc.query(q));

                const cpuTotals = (totalsResponse.Results?.[0] ?? { total: 0, AMD: 0, Intel: 0, AWS: 0, Azure: 0 }) as Record<string, number>;
                const total = cpuTotals.total ?? 0;
                const totals = mfgChipNames
                    .filter((n) => !csp || n.universal || n.mfg === csp)
                    .map((n) => ({ ...n, value: Math.round((cpuTotals[n.mfg] ?? 0) * 24), pct: total ? (cpuTotals[n.mfg] ?? 0) / total : 0 }));
                totals.sort((a, b) => b.value - a.value);
                setTotals(totals);

                if (queryKey.totalValue === currentKey) {
                    isCurrent = true;
                }
            } finally {
                if (isCurrent) {
                    setTotalLoading(false);
                }
            }
        })();
    }, [csp, dateRange?.from, dateRange?.to]);

    const totalHeaderStyle = { borderBottom: `solid 1px ${theme.colors.gray[2]}` };

    return (
        <Box sx={{ height: '100%', display: 'flex' }}>
            <Box sx={{ height: '100%', flexBasis: `100%`, minWidth: 0 }}>
                <FillerSwitch loading={loading}>{() => <LineChart data={lineData} {...chartProps} />}</FillerSwitch>
            </Box>
            <Space w="md" />
            <Card p={0} sx={{ borderRadius: 5, border: `solid 1px ${theme.colors.gray[2]}`, flex: `0 0 400px`, height: '100%' }}>
                <FillerSwitch loading={totalLoading}>
                    {() => (
                        <Box
                            sx={{
                                display: 'grid',
                                height: '100%',
                                gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                        >
                            <Box sx={totalHeaderStyle}></Box>
                            <Stack justify="end" sx={totalHeaderStyle}>
                                <Text px="lg" size="sm" align="right">
                                    vCPU Hours
                                </Text>
                            </Stack>
                            <Stack justify="end" sx={totalHeaderStyle}>
                                <Text px="lg" size="sm" align="right">
                                    Marketshare
                                </Text>
                            </Stack>
                            {totals.map((t, i) => (
                                <Fragment key={i}>
                                    <Stack sx={{ background: t.light }} justify="center">
                                        <Text align="center" size="sm">
                                            {t.mfg}
                                        </Text>
                                    </Stack>
                                    <Stack sx={{ background: i % 2 === 1 ? theme.colors.gray[2] : undefined }} justify="center">
                                        <Text px="lg" size="sm" align="right">
                                            {fmtSvc.formatInt0Dec(t.value)}
                                        </Text>
                                    </Stack>
                                    <Stack sx={{ background: i % 2 === 1 ? theme.colors.gray[2] : undefined }} justify="center">
                                        <Text px="lg" size="sm" align="right">
                                            {fmtSvc.formatPercent(t.pct)}
                                        </Text>
                                    </Stack>
                                </Fragment>
                            ))}
                        </Box>
                    )}
                </FillerSwitch>
            </Card>
        </Box>
    );
}

function RevertButton({ visible, onClick }: { visible: EventEmitter<boolean>; onClick: () => void }) {
    const canRevert = useEventValue(visible);

    return !canRevert ? null : (
        <Tooltip label="Reset Table">
            <ThemeIcon sx={{ cursor: 'pointer' }} variant="light" onClick={onClick} data-atid="grid-revert-icon">
                <Rotate2 />
            </ThemeIcon>
        </Tooltip>
    );
}

interface IChipMfgGridProps {
    chipSvc: ChipMfgMarketshareModel;
    resizer: () => ReactNode;
}
function ChipMfgGrid({ chipSvc, resizer }: IChipMfgGridProps) {
    const csp = useEventValue(chipSvc.cspFilter);
    const mode = useEventValue(chipSvc.gridMode);
    const deltaLookback = useEventValue(chipSvc.deltaLookback);
    const fmtSvc = useDiMemo(FormatService);
    const { model, selectionStrategy } = useMemo(() => {
        return {
            model: new ChipMfgGridService(chipSvc, fmtSvc).init(),
            selectionStrategy: new ChipMfgChartKeySelectionStrategy(mode === 'company' ? 1 : 10),
        };
    }, [chipSvc, csp, mode, deltaLookback]);
    const datasource = useEventValue(model.datasource);
    const rowSelector = useRowSelector({ gridSelectionStrategy: selectionStrategy, tooltip: 'Chart this data' });

    const handleSelectionChanged = useCallback(
        async ({ getItems }: { getItems: () => Promise<IChipMfgGridRow[]> }) => {
            const selections = await getItems();
            chipSvc.rowsSelected.emit(selections.map((r) => ({ item: r, color: selectionStrategy.getSelectionColor(r)! })));
        },
        [chipSvc, selectionStrategy]
    );

    return (
        <Box sx={{ height: '100%' }}>
            <DataGrid
                key={'' + csp + mode + deltaLookback}
                statePersistence={{ key: '' + csp + mode + 'ChipMfgMarketshare' }}
                columns={model.columns}
                dataSource={datasource!}
                groupConfig={model.groupConfig}
                showHeaderGroups
                onModelLoaded={model.attach}
                state={model.defaultState}
                onStateLoaded={model.handleStateChanged}
                onStateChanged={model.handleStateChanged}
                selectionStrategy={selectionStrategy}
                renderRowSelector={rowSelector}
                onSelectedChanged={handleSelectionChanged}
                selectionMode="multiple"
                onRowClick="select"
                hideHeader
                rightToolPlaceHolder={
                    <>
                        <RevertButton visible={model.revertEvent} onClick={model.revert} />
                        <Space w="md" />
                        {resizer()}
                        <Space w="md" />
                        <Button hidden leftIcon={<FileSpreadsheet size={20} />} sx={{ height: 30 }} variant="filled">
                            Export
                        </Button>
                    </>
                }
            />
        </Box>
    );
}
interface IChipMfgGridRow {
    Company: string;
    CompanyId: number;
    Msp: string;
    MspId: number;
    Region: string;
    vCPUs: number;
    vCPUHours: number;
    ProcessorFamily: string;
    PhysicalProcessor: string;
    Instance: string;
    CloudProvider: string;
    AverageRate: number;
    TotalCost: number;
    MfgStats: {
        [key: string]: { hours: number; share: number; last30DaysDeltaPct: null | number };
    };
}
class ChipMfgGridService {
    public columns: ColumnConfig<IChipMfgGridRow>[] = [];
    public datasource = new EventEmitter<IChipMfgGridRow[]>([]);
    public defaultState?: DataGridState;
    public groupConfig = mfgChipNames.reduce((result, item) => ({ ...result, [item.mfg]: { color: item.light } }), {});
    public revertEvent = new EventEmitter<boolean>(false);

    private csp = '';
    private mode: GridMode = 'company';
    private dataGridModel!: DataGridModel;
    private dateRange?: { from?: Date; to?: Date };
    private queryKey = '';
    private deltaLookback: DeltaLookback = 30;

    public constructor(private readonly chipSvc: ChipMfgMarketshareModel, private readonly fmtSvc: FormatService) {
        this.mode = chipSvc.gridMode.value;
        this.csp = chipSvc.cspFilter.value;
        this.deltaLookback = chipSvc.deltaLookback.value;
        this.chipSvc.selectedDateRange.listen(this.setDateRange);
    }
    public init() {
        this.columns = this.createColumns();
        this.defaultState = this.createDefaultState();
        return this;
    }

    public attach = (model: DataGridModel) => {
        this.dataGridModel = model;
        model.gridStateChanged.listen(this.handleStateChanged);
        model.stateSaved.listen(this.handleStateChanged);
        this.reload();
    };

    public revert = () => {
        this.revertEvent.emit(false);
        return this.dataGridModel?.revert();
    };

    public handleStateChanged = () => {
        this.revertEvent.emit(!!this.dataGridModel?.canRevert().length);
        this.reload();
    };

    private createQueryKey(state: DataGridState) {
        const columns = state.columns
            .map((c) => c.id)
            .sort()
            .join(',');
        const dates = JSON.stringify(this.chipSvc.selectedDateRange.value ?? {});
        return `${columns}[${dates}]`;
    }

    private setDateRange = (dateRange: { from?: Date; to?: Date }) => {
        this.dateRange = dateRange;
        this.reload();
    };

    public async reload() {
        if (this.dataGridModel) {
            const columns = new Set(this.dataGridModel.gridState.columns.map((c) => c.id));
            const currentState = this.createQueryKey(this.dataGridModel.gridState);

            if (!columns.size || this.queryKey === currentState) {
                return;
            }
            this.queryKey = currentState;

            const mfgGroups: { mfg: string; chip: string }[] = this.mode === 'instance-type' ? [{ mfg: 'any', chip: 'any' }] : mfgChipNames;
            const query = this.createQuery(columns, mfgGroups);
            const response = await this.chipSvc.query<Record<string, number>>(query);

            if (currentState === this.queryKey) {
                const results = (response.Results ?? []).map(
                    (r) =>
                        ({
                            ...r,
                            vCPUHours: r.vCPUs * 24,
                            MfgStats: mfgGroups.reduce((acc, { mfg }) => {
                                const vCPUs = r[`${mfg}-vCPUs`] ?? 0;
                                const hours = vCPUs * 24;
                                const share = !r.vCPUs ? 0 : hours / (r.vCPUs * 24);
                                const current = r[`${mfg}-vCPUCurrent`] ?? 0;
                                const prior = r[`${mfg}-vCPUPrior`] ?? 0;
                                const last30DaysDeltaPct = prior === 0 ? null : (current - prior) / prior;
                                acc[mfg] = { hours, share, last30DaysDeltaPct };
                                return acc;
                            }, {} as IChipMfgGridRow['MfgStats']),
                        } as IChipMfgGridRow)
                );

                this.queryKey = currentState;
                this.datasource.emit(results);
            }
        }
    }

    private createQuery(columns: Set<string>, mfgGroups: { mfg: string; chip: string }[]) {
        const builder = exprBuilder<ChipMfgMarketshareDigest>();
        const createExpr = builder.createExpr.bind(builder);

        const dateConstraint = this.chipSvc.dateRange.value ?? { min: undefined, max: undefined };
        const maxDate = this.dateRange?.to ?? dateConstraint.max ?? new Date();
        const minDate = this.dateRange?.from ?? dateConstraint.min ?? new Date();
        const statMaxEndDate = this.dateRange?.to ?? dateConstraint.max ?? new Date();
        const yday = addDays(new Date(), -1);
        const statEndDate = statMaxEndDate > yday ? yday : statMaxEndDate;
        const idealStartDate = addDays(statEndDate, -this.deltaLookback);
        const statStartDate = minDate > idealStartDate ? minDate : idealStartDate;

        const aggregations = mfgGroups.flatMap(({ mfg, chip }) => {
            return [
                {
                    Alias: `${mfg}-vCPUs`,
                    Expr: createExpr((b) =>
                        b.aggIf(mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip), b.sum(b.model.vCPUs))
                    ),
                },
                {
                    Alias: `${mfg}-vCPUPrior`,
                    Expr: createExpr((b) =>
                        b.aggIf(
                            b.and(
                                mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip),
                                b.model.Date.eq(statStartDate)
                            ),
                            b.sum(b.model.vCPUs)
                        )
                    ),
                },
                {
                    Alias: `${mfg}-vCPUCurrent`,
                    Expr: createExpr((b) =>
                        b.aggIf(
                            b.and(
                                mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip),
                                b.model.Date.eq(statEndDate)
                            ),
                            b.sum(b.model.vCPUs)
                        )
                    ),
                },
            ];
        });
        aggregations.push({
            Alias: 'TotalCost',
            Expr: createExpr((b) => b.sum(b.model.TotalCost)),
        });
        if (this.mode === 'instance-type') {
            aggregations.push({
                Alias: 'AverageRate',
                Expr: createExpr((b) => b.avg(b.model.AverageRate)),
            });
        }
        const possibleGroups: [keyof IChipMfgGridRow, keyof ChipMfgMarketshareDigest][] = [
            ['CloudProvider', 'CloudProvider'],
            ['Instance', 'Instance'],
            ['PhysicalProcessor', 'PhysicalProcessor'],
            ['ProcessorFamily', 'ProcessorFamily'],
            ['Region', 'Region'],
            ['Company', 'CustomerName'],
            ['CompanyId', 'CustomerId'],
            ['Msp', 'MspName'],
            ['MspId', 'MspId'],
        ];
        const selectedGroups = possibleGroups
            .filter((g) => columns.has(g[0]))
            .map(([alias, field]) => ({ Alias: alias, Expr: createExpr((b) => b.model[field]) }));
        if (this.mode === 'company') {
            selectedGroups.push({ Alias: 'CompanyId', Expr: createExpr((b) => b.model.CustomerId) });
        }
        const filters: IQueryExpr[] = [];
        if (this.csp) {
            filters.push(createExpr((b) => b.model.CloudProvider.eq(this.csp)));
        }
        if (this.dateRange?.from) {
            filters.push(createExpr((b) => b.model.Date.onOrAfter(this.dateRange!.from!)));
        }
        if (this.dateRange?.to) {
            filters.push(createExpr((b) => b.model.Date.onOrBefore(this.dateRange!.to!)));
        }

        const query = {
            Select: [...selectedGroups, ...aggregations, { Alias: 'vCPUs', Expr: createExpr((b) => b.sum(b.model.vCPUs)) }],
            Where: !filters.length ? null : filters.length === 1 ? filters[0] : { Operation: 'and', Operands: filters },
        } as Query;

        return query;
    }

    private createDefaultState() {
        return {
            columns:
                this.mode === 'company'
                    ? [
                          { id: 'Company', width: 300 },
                          { id: 'TotalCost', width: 120 },
                          ...mfgChipNames.flatMap(({ mfg }) => [
                              { id: `${mfg}-hours`, width: 120 },
                              { id: `${mfg}-share`, width: 120 },
                              { id: `${mfg}-delta`, width: 120 },
                          ]),
                      ]
                    : [
                          { id: 'Instance', width: 300 },
                          { id: 'PhysicalProcessor', width: 200 },
                          { id: 'ProcessorFamily', width: 130 },
                          { id: 'vCPUs', width: 120 },
                          { id: 'vCPUHours', width: 120 },
                          { id: `any-delta`, width: 120 },
                          { id: `CloudProvider`, width: 120 },
                          { id: 'Region', width: 160 },
                          { id: 'AverageRate', width: 120 },
                          { id: 'TotalCost', width: 120 },
                      ],
            filters: [],
            sort: [],
        } as DataGridState;
    }

    private createColumns() {
        const result = [] as ColumnConfig<IChipMfgGridRow>[];

        result.push(
            {
                id: 'Region',
                accessor: 'Region',
                header: 'Region',
                defaultWidth: 160,
                type: 'string',
                sortField: 'Region',
                filter: true,
                align: 'right',
            },
            {
                id: 'vCPUs',
                accessor: 'vCPUs',
                header: 'vCPUs',
                defaultWidth: 120,
                type: 'number',
                sortField: 'vCPUs',
                filter: true,
                align: 'right',
                formatter: (r) => this.fmtSvc.formatInt0Dec(r.vCPUs ?? 0),
            },
            {
                id: 'vCPUHours',
                accessor: 'vCPUHours',
                header: 'vCPU Hours',
                defaultWidth: 120,
                type: 'number',
                sortField: 'vCPUHours',
                filter: true,
                align: 'right',
                formatter: (r) => this.fmtSvc.formatInt0Dec(r.vCPUHours ?? 0),
            },
            {
                id: 'TotalCost',
                accessor: 'TotalCost',
                header: 'Spend',
                defaultWidth: 120,
                type: 'number',
                sortField: 'TotalCost',
                filter: true,
                align: 'right',
                formatter: (r) => this.fmtSvc.formatMoneyNoDecimals(r.TotalCost ?? 0),
            }
        );

        if (this.mode === 'company') {
            result.push(
                {
                    id: 'Company',
                    defaultWidth: 200,
                    accessor: 'Company',
                    header: 'Company',
                    type: 'string',
                    sortField: 'Company',
                    filter: true,
                },
                {
                    id: 'Msp',
                    accessor: 'Msp',
                    header: 'MSP',
                    defaultWidth: 160,
                    type: 'string',
                    sortField: 'Msp',
                    filter: true,
                }
            );

            mfgChipNames
                .filter((n) => !this.csp || n.universal || n.mfg === this.csp)
                .forEach(({ mfg }) => {
                    result.push(
                        {
                            id: `${mfg}-hours`,
                            accessor: (r) => r.MfgStats[mfg].hours ?? 0,
                            header: `vCPU Hours`,
                            defaultWidth: 100,
                            type: 'number',
                            sortField: `${mfg}-hours`,
                            filter: {
                                filterType: 'number',
                                name: `${mfg} vCPU Hours`,
                                filterField: `${mfg}-hours`,
                            },
                            groupName: mfg,
                            align: 'right',
                            formatter: (r) => this.fmtSvc.formatInt0Dec(r.MfgStats[mfg].hours ?? 0),
                        },
                        {
                            id: `${mfg}-share`,
                            accessor: (r) => r.MfgStats[mfg].share ?? 0,
                            header: `Marketshare`,
                            defaultWidth: 100,
                            type: 'number',
                            sortField: `${mfg}-share`,
                            filter: {
                                filterType: 'number',
                                name: `${mfg} Marketshare`,
                                filterField: `${mfg}-share`,
                            },
                            groupName: mfg,
                            align: 'right',
                            formatter: (r) => this.fmtSvc.formatPercent(r.MfgStats[mfg].share ?? 0, 10000),
                        },
                        {
                            id: `${mfg}-delta`,
                            accessor: (r) => r.MfgStats[mfg].last30DaysDeltaPct ?? 0,
                            header: `% Change`,
                            helpText: `% Change in vCPU Hours over the last ${this.deltaLookback} days`,
                            defaultWidth: 100,
                            type: 'number',
                            sortField: `${mfg}-delta`,
                            filter: {
                                filterType: 'number',
                                name: `${mfg} % Change`,
                                filterField: `${mfg}-delta`,
                            },
                            align: 'center',
                            groupName: mfg,
                            cellRenderer: (r) =>
                                r.MfgStats[mfg].last30DaysDeltaPct === null ? (
                                    'N/A'
                                ) : (
                                    <>
                                        {this.fmtSvc.formatPercent(r.MfgStats[mfg].last30DaysDeltaPct ?? 0, 10000)}
                                        <IncreaseIndicator size="sm" value={r.MfgStats[mfg].last30DaysDeltaPct ?? 0} />
                                    </>
                                ),
                        }
                    );
                });
        }

        if (this.mode === 'instance-type') {
            result.push(
                {
                    id: 'PhysicalProcessor',
                    accessor: 'PhysicalProcessor',
                    header: 'Processor',
                    defaultWidth: 160,
                    type: 'string',
                    filter: true,
                    align: 'right',
                },
                {
                    id: 'ProcessorFamily',
                    accessor: 'ProcessorFamily',
                    header: 'Vendor',
                    defaultWidth: 160,
                    type: 'string',
                    filter: true,
                    align: 'right',
                },
                {
                    id: 'AverageRate',
                    accessor: 'AverageRate',
                    header: 'Rate ($/hr)',
                    defaultWidth: 160,
                    type: 'number',
                    sortField: 'AverageRate',
                    filter: true,
                    align: 'right',
                    formatter: (r) => (typeof r.AverageRate !== 'number' ? 'N/A' : this.fmtSvc.formatMoney4Decimals(r.AverageRate ?? 0)),
                },
                {
                    id: 'Instance',
                    accessor: 'Instance',
                    header: 'Instance Type',
                    defaultWidth: 200,
                    type: 'string',
                    filter: true,
                },
                {
                    id: `any-delta`,
                    accessor: (r) => r.MfgStats['any']?.last30DaysDeltaPct ?? 0,
                    header: `% Change`,
                    helpText: `% Change in vCPU Hours over the last ${this.deltaLookback} days`,
                    defaultWidth: 100,
                    type: 'number',
                    sortField: `any-delta`,
                    filter: {
                        filterType: 'number',
                        name: `% Change`,
                        filterField: `any-delta`,
                    },
                    align: 'center',
                    cellRenderer: (r) =>
                        typeof r.MfgStats['any']?.last30DaysDeltaPct !== 'number' ? (
                            'N/A'
                        ) : (
                            <>
                                {this.fmtSvc.formatPercent(r.MfgStats['any'].last30DaysDeltaPct ?? 0, 10000)}
                                <IncreaseIndicator size="sm" value={r.MfgStats['any'].last30DaysDeltaPct ?? 0} />
                            </>
                        ),
                }
            );
        }

        if (!this.csp) {
            result.push({
                id: 'CloudProvider',
                accessor: 'CloudProvider',
                header: 'CSP',
                defaultWidth: 100,
                type: 'string',
                filter: true,
                align: 'right',
            });
        }

        for (const column of result) {
            column.noRemove = true;
        }

        return result;
    }
}

interface ChipMfgMarketshareDigest {
    Date: Date;
    PhysicalProcessor: string;
    Region: string;
    Instance: string;
    ProcessorFamily: string;
    vCPUs: number;
    CloudProvider: string;
    MspName: string;
    MspId: number;
    CustomerName: string;
    CustomerId: number;
    AverageRate: number;
    TotalCost: number;
}

class ChipMfgMarketshareModel {
    public dateRange = new EventEmitter<{ min?: Date; max?: Date } | undefined>({});
    public cspFilter = new EventEmitter<string>('');
    public selectedDateRange = new EventEmitter<{ from?: Date; to?: Date }>({});
    public gridMode = new EventEmitter<GridMode>('instance-type');
    public deltaLookback = new EventEmitter<DeltaLookback>(30);
    public rowsSelected = new EventEmitter<{ item: IChipMfgGridRow; color: string }[]>([]);

    public constructor(private readonly relSvc: ScopedRelationshipBundleService) {}

    public init() {
        this.loadDateRange();
        return this;
    }

    public updateGridMode = (mode: GridMode) => {
        this.rowsSelected.emit([]);
        this.gridMode.emit(mode);
    };

    public query<TResult>(query: Query) {
        return this.relSvc.query<TResult>(query);
    }

    public async loadDateRange() {
        this.dateRange.emit(undefined);

        const dateRangeResponse = await queryBuilder<ChipMfgMarketshareDigest>()
            .select((b) => ({
                start: b.min(b.model.Date),
                end: b.max(b.model.Date),
            }))
            .execute((q) => this.relSvc.query(q));
        const result = dateRangeResponse.Results?.[0] ?? { start: null, end: null };

        this.dateRange.emit({
            min: result.start ? new Date(result.start) : undefined,
            max: result.end ? new Date(result.end) : undefined,
        });
    }
}

class ChipMfgChartKeySelectionStrategy extends BaseChartKeySelectionStrategy<IChipMfgGridRow> {}

function useRowSelector({
    gridSelectionStrategy,
    color,
    tooltip,
}: {
    gridSelectionStrategy: ChipMfgChartKeySelectionStrategy;
    color?: string;
    tooltip?: string;
}) {
    return useCallback(
        (item: IChipMfgGridRow | null, { toggle }: { toggle: (selected: boolean) => void }) => (
            <ChartKeyRowSelector selectionStrategy={gridSelectionStrategy} toggle={toggle} item={item} color={color} tooltip={tooltip} />
        ),
        [gridSelectionStrategy, color, tooltip]
    );
}

export function ChartKeyRowSelector({
    selectionStrategy,
    item,
    toggle,
    color,
    tooltip,
}: {
    selectionStrategy: BaseChartKeySelectionStrategy<any>;
    item: any | null;
    toggle: (selected: boolean) => void;
    color?: string;
    tooltip?: string;
}) {
    const theme = useMantineTheme();
    useEvent(selectionStrategy.selectionChanged);
    const isSelected = item ? selectionStrategy.isSelected(item) : false;
    const itemColor = item ? selectionStrategy.getSelectionColor(item) : undefined;
    const allSelected = selectionStrategy.isAllSelected();
    const selectAll = useCallback(() => {
        toggle(true);
    }, [selectionStrategy, toggle]);
    const iconColor = allSelected ? theme.colors.primary[6] : isSelected ? color ?? itemColor ?? theme.colors.primary[6] : theme.colors.gray[4];

    return (
        <Tooltip withinPortal label={tooltip} position="right">
            {item ? (
                <i className="ti ti-chart-histogram" style={{ color: iconColor }} />
            ) : (
                <i className="ti ti-chart-histogram" style={{ color: theme.colors.primary[6] }} onClick={selectAll} />
            )}
        </Tooltip>
    );
}
