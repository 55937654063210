import { FillerSwitch } from '@root/Design/Filler';
import { AppTypes, ICompanyFeatureLookup, useCompanyFeatureSvc } from '@root/Services/Customers/CompanyFeatureService';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';
import { IUserFeatureAccessLookup, UserFeatureAccessService } from '@root/Services/Customers/UserFeatureAccessService';
import { useDi } from '@root/Services/DI';
import { useState, useEffect, createContext, useContext } from 'react';
import { useCompany } from '../Router/CompanyContent';

export function withAppFeatureCheck(Component: React.ComponentType<any>, app: AppTypes, feature?: string) {
    return function AppFeatureCheck(props: any) {
        const companyFeatureSvc = useDi(CompanyFeatureService);
        const company = useCompany();
        const [allow, setAllow] = useState<boolean>();
        useEffect(() => {
            (async () => {
                const allowed = await companyFeatureSvc.checkAccess(company ?? {}, app, feature);
                setAllow(allowed);
            })();
        }, []);
        return <>{allow ? <Component {...props} /> : null}</>;
    };
}

const CompanyFeaturesContext = createContext<ICompanyFeatureLookup | undefined>(undefined);
export function CompanyFeaturesProvider({ children }: { children: () => React.ReactNode }) {
    const companyFeatureSvc = useCompanyFeatureSvc();
    const [companyFeatures, setCompanyFeatures] = useState<ICompanyFeatureLookup>();
    useEffect(() => {
        setCompanyFeatures(undefined);
        companyFeatureSvc.getFeatures().then(setCompanyFeatures);
    }, [companyFeatureSvc]);
    return (
        <FillerSwitch loading={!companyFeatures}>
            {() => <CompanyFeaturesContext.Provider value={companyFeatures}>{children()}</CompanyFeaturesContext.Provider>}
        </FillerSwitch>
    );
}
export function useCompanyFeaturesParentContext() {
    const context = useContext(CompanyFeaturesContext);
    if (context === undefined) {
        throw new Error('useCompanyFeaturesParentContext must be used within a CompanyFeaturesProvider');
    }
    return context;
}

const UserAccessContext = createContext<IUserFeatureAccessLookup | undefined>(undefined);
export function UserAccessContextProvider({ children }: { children: () => React.ReactNode }) {
    const userAccessSvc = useDi(UserFeatureAccessService);
    const company = useCompany();
    const [lookup, setLookup] = useState<IUserFeatureAccessLookup>();
    useEffect(() => {
        setLookup(undefined);
        if (company) {
            userAccessSvc.getAccessLookup(company).then(setLookup);
        }
    }, [company]);
    return (
        <FillerSwitch loading={!lookup}>{() => <UserAccessContext.Provider value={lookup!}>{children()}</UserAccessContext.Provider>}</FillerSwitch>
    );
}
export function useUserAccessContext() {
    const context = useContext(UserAccessContext);
    if (context === undefined) {
        throw new Error('useCompanyFeaturesParentContext must be used within a CompanyFeaturesProvider');
    }
    return context;
}
