import { IQueryExpr } from '@apis/Invoices/model';
import { postResourcesQuery, postTagPolicyGetAzureTagPolicies } from '@apis/Resources';
import { BaseAzureResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Anchor, Box, Divider, Drawer, Grid, Group, Progress, Space, Stack, Tabs, Text, Tooltip } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, ColumnGroupConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { azureTagPolicyComplianceCounts } from '@root/Components/TagManager/TagPolicyFunctions';
import { PageContent, PanelBody, PanelContent, PanelHeader } from '@root/Design/Layout';
import { generateColor } from '@root/Design/Primitives';
import { theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useEffect, useMemo, useState } from 'react';
import { AlertTriangle } from 'tabler-icons-react';
import { SidePanelContainer } from '@root/Design/SidePanel';
import { CoverageNumber, CoverageText, PolicySection } from './Design';
import { getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { CompanyCloudProvider } from '@apis/Customers/model';

type TagPolicyAggregateData = {
    subscriptionId: string;
    id: string;
    displayName: string;
    validationError?: string;
    policyType: string;
    policyRuleJson: string;
    assignmentParameterJson: string;
    definitionParameterJson: string;
    parameters: number;
    effect: string;
    compliant: number;
    nonCompliant: number;
    filter: IQueryExpr;
    policyRule: IQueryExpr;
    logicSupported: boolean;
};

type complianceResource = {
    subscriptionId: string;
    count: number;
    complianceCount: number;
    noncomplianceCount: number;
};

export function AzureTagPolicyDetails() {
    const company = useCompany()!;
    let { subscriptionId } = useNav().getData('subscriptionId');
    const [numInCompliance, setNumInCompliance] = useState<number>(0);
    const [subscription, setSubscription] = useState<CompanyCloudProvider>();
    const [numNotInCompliance, setNumNotInCompliance] = useState<number>(0);
    const [percentInCompliance, setPercentInCompliance] = useState<number>(0);
    const fmtSvc = useDi(FormatService);
    const [selectedPolicyInitialView, setSelectedPolicyInitialView] = useState<string>('ruleDefinition');
    const [numResourcesInCompliance, setNumResourcesInCompliance] = useState<number>(0);
    const [numResourcesNotInCompliance, setNumResourcesNotInCompliance] = useState<number>(0);
    const [numResourcesTotal, setNumResourcesTotal] = useState<number>(0);
    const [policySummary, setPolicySummary] = useState<TagPolicyAggregateData[]>([]);
    const [selectedPolicy, setSelectedPolicy] = useState<TagPolicyAggregateData>();

    const routeLoader = useDi(BasicRouteLoader);
    const { getDescendUrl, goto } = useNav(routeLoader.getTopRouteMeta());

    useEffect(() => {
        (async () => {
            setPolicySummary([]);
            const [azureTagPolicies, cloudProviders] = await Promise.all([postTagPolicyGetAzureTagPolicies(), getCompanyGetCompanyCloudProviders()]);
            setSubscription(cloudProviders.find((c) => c.ExternalId === subscriptionId));
            let policiesForSub = azureTagPolicies.filter((p) => p.SubscriptionId === subscriptionId);
            const policyCountData = await Promise.all(
                policiesForSub.map(async (policy) => {
                    const complianceCounts = await azureTagPolicyComplianceCounts(policy.Filter!, policy.PolicyRule!, company.Id!);
                    return {
                        subscriptionId: subscriptionId!,
                        id: policy.Id,
                        displayName: policy.DisplayName,
                        validationError: policy.ValidationError ?? '',
                        policyType: policy.PolicyType,
                        policyRuleJson: policy.PolicyRuleJson,
                        assignmentParameterJson: policy.AssignmentParametersJson,
                        definitionParameterJson: policy.DefinitionParametersJson,
                        parameters: policy.ParameterCount,
                        effect: policy.Effect,
                        compliant: complianceCounts.compliance.length,
                        nonCompliant: complianceCounts.noncompliance.length,
                        total: complianceCounts.compliance.length + complianceCounts.noncompliance.length,
                        filter: policy.Filter,
                        policyRule: policy.PolicyRule,
                        logicSupported: policy.LogicSupported,
                    } as TagPolicyAggregateData;
                })
            );

            setPolicySummary(policyCountData);
            let totalCompliant = SumProperty(policyCountData, 'compliant');
            let totalNonCompliant = SumProperty(policyCountData, 'nonCompliant');
            let totalCompliantPercentage = (totalCompliant / (totalCompliant + totalNonCompliant)) * 100;
            setNumInCompliance(totalCompliant);
            setNumNotInCompliance(totalNonCompliant);
            setPercentInCompliance(totalCompliantPercentage);

            const notInComp = azureTagPolicies?.map((s) => ({
                operation: 'and',
                operands: [
                    { operation: 'and', operands: [s.Filter] },
                    { operation: 'and', operands: [s.PolicyRule] },
                ],
            }));

            const compQuery = queryBuilder<BaseAzureResource>()
                .select((b) => ({
                    subscriptionId: b.model.SubscriptionId,
                    count: b.count(),
                }))
                .build();

            compQuery.Select!.push({
                Alias: 'noncomplianceCount',
                Expr: { operation: 'count', operands: [{ operation: 'or', operands: [...notInComp!] }] },
            });

            var complianceResources = await postResourcesQuery(compQuery, { companyId: company?.Id });
            const cr = complianceResources.Results as complianceResource[];
            const accountCr = cr.filter((a) => a.subscriptionId == subscriptionId);
            accountCr.map((m) => {
                setNumResourcesTotal(m.count);
                setNumResourcesInCompliance(m.count - m.noncomplianceCount);
                setNumResourcesNotInCompliance(m.noncomplianceCount);
            });
        })();
    }, []);

    const SumProperty = (aggData: TagPolicyAggregateData[], propertyName: keyof TagPolicyAggregateData) => {
        return aggData.reduce(function (a, b) {
            return a + (b[propertyName] as number);
        }, 0);
    };

    const cols = useMemo(() => {
        return [
            {
                header: 'Name',
                accessor: 'displayName',
                cellRenderer: (item) => {
                    return <PolicyStatementLink show="ruleDefinition" policy={item} />;
                },
                defaultWidth: 350,
                id: 'displayName',
                type: 'string',
                align: 'left',
                filter: {
                    filterField: 'displayName',
                    filterType: 'string',
                    name: 'DisplayName',
                },
                helpText: 'This is the Display Name of the policy.',
            },
            {
                header: 'Type',
                accessor: 'policyType',
                defaultWidth: 100,
                id: 'policyType',
                type: 'string',
                align: 'left',
                filter: {
                    filterField: 'policyType',
                    filterType: 'string',
                    name: 'policyType',
                },
                helpText: 'The type of policy -- Builtin, Custom or Static (Regulatory Compliance).',
            },
            {
                header: 'Effect',
                accessor: 'effect',
                defaultWidth: 100,
                id: 'effect',
                type: 'string',
                align: 'left',
                filter: {
                    filterField: 'effect',
                    filterType: 'string',
                    name: 'effect',
                },
                helpText: 'The action taken for non-compliance.',
            },
            {
                header: 'Compliant',
                accessor: (item) => {
                    return <ResourceLink num={item.compliant} compliance="In" policy={item}></ResourceLink>;
                },
                defaultWidth: 125,
                id: 'numCompliant',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'numCompliant',
                    filterType: 'number',
                    name: 'numCompliant',
                },
                groupName: 'Taggable Resources',
            },
            {
                header: 'Non-<br />Compliant',
                accessor: (item) => {
                    return <ResourceLink num={item.nonCompliant} compliance="Out" policy={item}></ResourceLink>;
                },
                defaultWidth: 125,
                id: 'numNoncompliant',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'numNoncompliant',
                    filterType: 'number',
                    name: 'numNoncompliant',
                },
                groupName: 'Taggable Resources',
            },
            {
                header: 'Total',
                accessor: (item) => item.compliant + item.nonCompliant,
                cellRenderer: (item) => {
                    return <ResourceLink num={item.compliant + item.nonCompliant} compliance="Total" policy={item}></ResourceLink>;
                },
                defaultWidth: 140,
                id: 'Total',
                align: 'center',
                type: 'number',
            },
            {
                header: 'Compliance',
                accessor: (item) => {
                    return (
                        <Group>
                            <div style={{ marginTop: '2px', width: '100px' }}>
                                <Progress
                                    radius="md"
                                    size="md"
                                    value={item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0}
                                />
                            </div>
                            <div style={{ textAlign: 'right', width: '40px' }}>
                                {Math.round(
                                    item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0
                                )}
                                %
                            </div>
                        </Group>
                    );
                },
                defaultWidth: 200,
                id: 'Compliance',
                align: 'left',
                type: 'number',
            },
        ] as ColumnConfig<TagPolicyAggregateData>[];
    }, [policySummary]);

    var groupConfig: { [groupName: string]: ColumnGroupConfig } = {};
    groupConfig = {};
    groupConfig['Taggable Resources'] = { color: generateColor('Taggable Resources') };

    function PolicyStatementLink({ show, policy }: { show: string; policy: TagPolicyAggregateData }) {
        return (
            <Tooltip
                disabled={policy.logicSupported}
                label={`Some logic for this policy is not supported. Message: ${policy.validationError || 'None'}`}
                position="right"
                withinPortal
            >
                <Anchor onClick={() => openSlider({ show, policy })}>
                    {policy.logicSupported ? null : <AlertTriangle size={16} />}
                    {policy.displayName}
                </Anchor>
            </Tooltip>
        );
    }
    function ResourceLink({ num, compliance, policy }: { num: number; compliance: string; policy: TagPolicyAggregateData }) {
        return <Anchor onClick={() => showResources({ compliance, policy })}>{fmtSvc.formatInt(num)}</Anchor>;
    }

    function showResources({ compliance, policy }: { compliance: string; policy: TagPolicyAggregateData }) {
        const inCompliance = {
            description: `Compliant with policy ${policy?.displayName}`,
            Operation: 'And',
            Operands: [
                { Operation: 'And', Operands: [policy?.filter] },
                { Operation: 'Not', Operands: [policy?.policyRule] },
            ],
        };

        const notInCompliance = {
            description: `Not compliant with policy ${policy?.displayName}`,
            Operation: 'And',
            Operands: [policy?.filter, policy?.policyRule],
        };

        const totalCompliance = {
            description: `Policy ${policy?.displayName}`,
            Operation: 'And',
            Operands: [policy?.filter],
        };

        const filter = compliance == 'In' ? inCompliance : compliance == 'Out' ? notInCompliance : totalCompliance;
        const state: DataGridState = {
            columns: compliance == 'Out' ? [{ id: `Tags.CsTags.${policy.id}`, width: 150 }] : [],
            filters: [filter],
            sort: [],
        };

        const stateData = JSON.stringify(state);

        const tagExplorerUrl = getDescendUrl('tag-explorer', {
            state: stateData,
        });
        goto(tagExplorerUrl);
    }

    function openSlider({ show, policy }: { show: string; policy: TagPolicyAggregateData }) {
        setSelectedPolicyInitialView(show);
        setSelectedPolicy(policy);
    }

    function closeSlider() {
        setSelectedPolicy(undefined);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <PageContent>
                <PanelContent>
                    <PanelHeader>
                        <Text size={20}>Tag Policies {!subscription?.Name ? 'Loading...' : `for ${subscription?.Name ?? ''}`}</Text>
                    </PanelHeader>
                    <PanelBody>
                        <Stack>
                            <PolicySection>
                                <Grid px="xs" gutter="xl" columns={13} align="flex-end">
                                    <Grid.Col span={3}>
                                        <div>
                                            <Header>&nbsp;</Header>
                                            <Space h={15} />
                                            <Header>Overall Subscription Compliance</Header>
                                            <Space h={8} />
                                            <Group>
                                                <div style={{ marginTop: '2px', width: '130px' }}>
                                                    <Progress radius="md" size="md" value={percentInCompliance} />
                                                </div>
                                                <div style={{ textAlign: 'right', width: '40px' }}>{Math.round(percentInCompliance)}%</div>
                                            </Group>
                                        </div>
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <div style={{ textAlign: 'center', width: '100%' }}>
                                            <Header>Tag Keys</Header>
                                            <Divider my="xs" />
                                            <div>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageText>Compliant</CoverageText>
                                                    <CoverageText>Non-Compliant</CoverageText>
                                                    <CoverageText>Total</CoverageText>
                                                </Group>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageNumber>{fmtSvc.formatInt(numInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numNotInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numInCompliance + numNotInCompliance)}</CoverageNumber>
                                                </Group>
                                            </div>
                                        </div>
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <div style={{ textAlign: 'center', width: '100%' }}>
                                            <Header>Taggable Resources</Header>
                                            <Divider my="xs" />
                                            <div>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageText>Compliant</CoverageText>
                                                    <CoverageText>Non-Compliant</CoverageText>
                                                    <CoverageText>Total</CoverageText>
                                                </Group>
                                                <Group position="apart" align="center" spacing={0}>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesNotInCompliance)}</CoverageNumber>
                                                    <CoverageNumber>{fmtSvc.formatInt(numResourcesTotal)}</CoverageNumber>
                                                </Group>
                                            </div>
                                        </div>
                                    </Grid.Col>
                                </Grid>
                            </PolicySection>
                            <PolicySection style={{ height: '500px' }}>
                                {!policySummary ? null : (
                                    <DataGrid
                                        dataSource={policySummary}
                                        columns={cols}
                                        hideHeader
                                        hideFilter={true}
                                        showHeaderGroups={true}
                                        groupConfig={groupConfig}
                                        headerHeight={50}
                                    />
                                )}
                            </PolicySection>
                        </Stack>
                    </PanelBody>

                    {!selectedPolicy ? null : (
                        <Drawer onClose={closeSlider} opened={!!selectedPolicy} position="right" withinPortal size={600} withCloseButton={false}>
                            <SidePanelContainer title={selectedPolicy?.displayName} onClose={closeSlider}>
                                <Tabs
                                    sx={{
                                        '[role=tabpanel]': { flex: 1, overflow: 'hidden' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1,
                                        height: '100%',
                                        overflow: 'hidden',
                                    }}
                                    defaultValue={selectedPolicyInitialView}
                                >
                                    <Tabs.List>
                                        <Tabs.Tab value="ruleDefinition">Rule Definition</Tabs.Tab>
                                        <Tabs.Tab value="assignmentParameters">Assignment Parameters</Tabs.Tab>
                                        <Tabs.Tab value="definitionParameters">Parameter Definition</Tabs.Tab>
                                    </Tabs.List>

                                    <Tabs.Panel value="ruleDefinition" pt="xs">
                                        <DetailsItem>
                                            <pre>{JSON.stringify(JSON.parse(selectedPolicy.policyRuleJson), null, 2)}</pre>
                                        </DetailsItem>
                                    </Tabs.Panel>

                                    <Tabs.Panel value="assignmentParameters" pt="xs">
                                        <DetailsItem>
                                            <pre>{JSON.stringify(JSON.parse(selectedPolicy.assignmentParameterJson), null, 2)}</pre>
                                        </DetailsItem>
                                    </Tabs.Panel>

                                    <Tabs.Panel value="definitionParameters" pt="xs">
                                        <DetailsItem>
                                            <pre>{JSON.stringify(JSON.parse(selectedPolicy.definitionParameterJson), null, 2)}</pre>
                                        </DetailsItem>
                                    </Tabs.Panel>
                                </Tabs>
                            </SidePanelContainer>
                        </Drawer>
                    )}
                </PanelContent>
            </PageContent>
        </Box>
    );
}

const DetailsItem = styled.div`
    border: 1px solid ${theme.colors!.gray![3]};
    background: ${theme.colors!.gray![2]};
    padding: 16px;
    overflow: auto;
    height: 100%;
`;
const Header = styled.div``;
