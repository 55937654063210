/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Export.Host.Api
 * OpenAPI spec version: 1.0
 */

export type ExportRequestTarget = typeof ExportRequestTarget[keyof typeof ExportRequestTarget];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportRequestTarget = {
  ResourcesQuery: 'ResourcesQuery',
  RecommendationQuery: 'RecommendationQuery',
  DailyInvoiceQuery: 'DailyInvoiceQuery',
  MonthlyInvoiceQuery: 'MonthlyInvoiceQuery',
  CostForecastQuery: 'CostForecastQuery',
  InvoiceComparisonQuery: 'InvoiceComparisonQuery',
  TrendAnalysisQuery: 'TrendAnalysisQuery',
} as const;
