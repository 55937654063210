import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { IdleResourceGrid } from '../Components/IdleResourcesGrid';
import { useDi } from '@root/Services/DI';
import { IdleResourcesService } from '../Services/IdleResourcesService';
import { IdleResourcesPage } from '../Components/IdleResourcesPage';
import { GridColumnState } from '@root/Components/DataGrid/Models';
import { SubscriptionCheck } from '@root/Components/CompanyInfo/SubscriptionCheck';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { IdleResourcesTooltip } from '../Components/IdleResourcesTooltip';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function IdleEMRContent() {
    const idleResourceService = useDi(IdleResourcesService);

    const resourceColumns: GridColumnState[] = [
        { id: 'Base.CreateDate', width: 120 },
        { id: 'Metrics.IdleResources.IsIdle', width: 90 },
        { id: 'NumberOfInstances', width: 175 },
    ];

    return (
        <IdleResourcesPage title="EMR" IdleResourcesTooltip={<IdleResourcesTooltip resourceType="EMR" />}>
            <IdleResourceGrid
                refreshNeeded={idleResourceService.refreshNeeded}
                title="EMR"
                resourceType="EMR"
                includeMemory={false}
                extraColumns={resourceColumns}
            />
        </IdleResourcesPage>
    );
}

function IdleEMR() {
    return (
        <SubscriptionCheck app="Optimization" featureName={AppFeatureNames.IdleResources}>
            {() => <ConnectionCheck>{() => <IdleEMRContent />}</ConnectionCheck>}
        </SubscriptionCheck>
    );
}
endpoint('idle-emr', IdleEMR, 'Idle Resources');
