/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Account,
  GetAccountGetAccountsByOUParams,
  GetAccountGetAccountsByAwsOUIdParams,
  GetAccountGetAccountParams,
  GetAccountGetAccountByAWSIdParams,
  PostAccountConnectMasterAccountParams,
  PostAccountConnectSecondaryAccountParams,
  PostAccountUpdateAccountParams,
  GetAccountDisconnectMasterAccountParams,
  GetAccountDisconnectSecondaryAccountParams,
  DeleteAccountDeleteMasterAccountParams,
  DeleteAccountDeleteSecondaryAccountParams,
  AccountConnectedStatus,
  GetAccountGetAccountConnectionStatusParams,
  GetAccountGetAccountConnectionStatusInternalParams,
  GetAccountCheckIfIsMasterAccountParams,
  PostAccountGetOrCreateMasterParams,
  PostAccountSyncMasterParams,
  PostAccountGetOrCreateLinkedParams,
  PostAccountSyncLinkedParams,
  AppFeatures,
  CustomerSubsidyRequest,
  PostCloudIntelligenceReviewCompanyCandidateParams,
  Company,
  GetCloudIntelligenceGetCustomerCompanyParams,
  GetCloudIntelligenceGetCustomersByUserParams,
  CustomerSubsidyViewModel,
  GetCloudIntelligenceGetCustomerSubsidyRequestByIdParams,
  PostCloudIntelligenceDeleteCompanyCandidateParams,
  PostCloudIntelligenceInviteAccountRepParams,
  CompanyApp,
  GetCompanyGetCompanyAppsParams,
  CompanyApps,
  PutCompanyToggleCompanyAppLockedParams,
  PutCompanySaveCompanyAppsParams,
  CompanyFeature,
  GetCompanyGetCompanyParams,
  GetCompanyGetCompanyInternalParams,
  PutCompanyCreateCompanyParams,
  PutCompanyRegisterCompanyParams,
  CompanyConfiguration,
  PutCompanyAdminRegisterCompanyParams,
  DeleteCompanyDeleteCompanyParams,
  CompanyAccount,
  GetCompanyGetAccountCompanyParams,
  PostCompanyAddUsersParams,
  CompanyUser,
  PostCompanyAddUserParams,
  PostCompanyRemoveUsersParams,
  GetCompanyGetCompanyBySystemNameParams,
  PostCompanySaveCompanyStandardTagsBodyOne,
  PostCompanySaveCompanyStandardTagsBodyTwo,
  PostCompanySaveCompanyStandardTagsBodyThree,
  PostCompanySaveCompanyStandardTagsParams,
  CompanyStandardTag,
  GetCompanyGetCompanyStandardTagsParams,
  StandardTag,
  CompanyCloudProvider,
  GetCompanyGetCompanyCloudProvidersInternalParams,
  AzureConnectivityInfo,
  GetCompanyGetAzureConnectionInfoParams,
  GetCompanyGetAzureConnectionInfoInternalParams,
  GetCompanyGetCloudConnectionsParams,
  NewCompanyCloudProvider,
  PostCompanyAddAzureConnectivityParams,
  PostCompanyAddAzureTenantConnectivityParams,
  CompanyCloudProviderRequest,
  PostCompanyAddAzureTenantCloudProviderParams,
  AzureSubscriptionInfo,
  CloudProviderConnectionStatus,
  GetCompanyCheckCloudProviderConnectivityParams,
  SingleSignOnConfig,
  SubscriptionTypeApp,
  PostCompanySaveCompanySubscriptionTypeParams,
  CompanyTypeRequest,
  CompanyDailyCreditSnapshot,
  GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotParams,
  CompanyRelationshipBundle,
  DataAccessDefinitionBundle,
  GetCompanyRelationshipGetDataAccessDefinitionsParams,
  GetCompanyRelationshipGetDataAccessBeneficiariesParams,
  CompanyStat,
  CompanyStatQueryResult,
  CompanyStatsQuery,
  CreditSummary,
  GetCreditsGetCreditSummaryParams,
  PostCreditsUpdateCreditOverrideParams,
  CreditTransaction,
  GetCreditsGetCreditTransactionsForPeriodParams,
  CompanyCreditUsage,
  GetCreditsGetCreditsSpentForPeriodParams,
  GetCreditsAddCreditsParams,
  PostCreditsAddCreditsBySupportUserParams,
  GetCreditsCheckCreditCountParams,
  GetCreditsIsSpendLimitedParams,
  GetCreditsTryClaimCreditsParams,
  PostCreditsUpdateSettingsPageParams,
  AzureMarketplaceSubscription,
  GetCspMktGetStagedAzureSubscriptionParams,
  PostCspMktActivateAzureSubscriptionParams,
  PendingPaymentMethodInfo,
  PostCspMktGetPendingPaymentMethodParams,
  PostCspMktActivateAzureSubscriptionBySecretParams,
  AwsMarketplaceSubscription,
  AwsMarketplaceOffer,
  CreateAwsMarketplacePrivateOfferRequest,
  DeleteCspMktStagePrivateAwsSubscriptionParams,
  GetCspMktGetAwsSubscriptionParams,
  GetCspMktGetAwsSubscriptionByIdParams,
  PostCspMktActivateAwsSubscriptionParams,
  PostCspMktActivateAwsPaymentParams,
  CustomerFeedbackRequest,
  CWMetricsConfigData,
  GetCWMetricsConfigGetCWMetricsConfigParams,
  GetCWMetricsConfigGetCWMetricsConfigDataParams,
  AwsCWMetricsConfig,
  GetCWMetricsConfigGetCWMetricsConfigFiltersParams,
  GetCWMetricsConfigGetCwMetricFiltersOrDefault200One,
  GetCWMetricsConfigGetCwMetricFiltersOrDefault200Two,
  GetCWMetricsConfigGetCwMetricFiltersOrDefault200Three,
  GetCWMetricsConfigGetCwMetricFiltersOrDefaultParams,
  PostCWMetricsConfigSaveCWMetricsConfigParams,
  PostCWMetricsConfigSaveCWMetricsConfigFiltersParams,
  DashboardConfig,
  PostDashboardShareWithUserParams,
  PostDashboardRemoveSharedAccessParams,
  GetDashboardGetUsersByDashboardIdParams,
  GetDashboardParams,
  DeleteDashboardParams,
  GetDashboardGetByKeyParams,
  DashboardUserSettings,
  FrontEndError,
  Integration,
  GetIntegrationGetIntegrationsParams,
  PostIntegrationUpdateStatusParams,
  PostIntegrationSignOffIntegrationParams,
  InvoiceDetail,
  GetInvoiceDownloadInvoiceParams,
  EstimateRequest,
  Msp,
  GetMspGetMspByCompanyIdParams,
  MspSubscriptionType,
  GetMspGetMspSubscriptionTypeParams,
  CompanySubscriptionType,
  GetMspGetMspChildCompanySubscriptionParams,
  MspCreditPackage,
  PostMspSaveMspUserCompaniesParams,
  GetMspGetMspUserCompaniesParams,
  GetMspGetMspsForCompanyParams,
  MspCompensation,
  GetMspCompensationGetMspCompensationParams,
  MspCompensationCategory,
  GetMspCompensationCategoryGetMspCompensationCategoryParams,
  MspCompensationLineItem,
  GetMspCompensationLineItemGetMspCompensationLineItemParams,
  GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryParams,
  MspConfig,
  GetMspConfigGetMspConfigByIdParams,
  GetMspConfigGetMspConfigByCompanyParams,
  GetMspConfigGetMspConfigByHostParams,
  GetMspConfigGetMspConfigForCompanyParams,
  GetMspConfigGetMspConfigByMspCompanyIdParams,
  Organization,
  GetOrganizationGetOrganizationsParams,
  GetOrganizationGetChildOrganizationsParams,
  GetOrganizationGetOrganizationParams,
  GetOrganizationGetOrganizationByAWSIdParams,
  PostOrganizationUpdateOrganizationParams,
  DeleteOrganizationDeleteOrganizationParams,
  PaymentSource,
  HostedPage,
  Card,
  PostPaymentAddPaymentSourceParams,
  PostPaymentDeletePaymentSourceParams,
  PostPaymentUpdatePrimaryPaymentParams,
  ItemPrice,
  GetPaymentGetItemPriceParams,
  BuyCreditsResult,
  PostPaymentBuyCreditsParams,
  ChargeBeeBillingInfo,
  AzureMarketplaceOffer,
  PendingPaymentMethodSecret,
  GetPaymentGetPendingPaymentMethodParams,
  PostPaymentUsePendingPaymentMethodParams,
  BulkUserRoleChange,
  RolePermission,
  Role,
  DeleteRbacDeleteRoleParams,
  CompanyUserRole,
  GetRbacGetUserRolesByUserIdParams,
  GetRbacGetRolesByUserIdParams,
  GetRbacGetRolePermissionsParams,
  IQueryExpr,
  GetRbacGetUserAccessRulesParams,
  GetRbacGetCompanyRolePermissionsParams,
  Permission,
  GetRbacGetRolesByCompanyParams,
  GetRbacGetRoleParams,
  CompanyRoleListItem,
  BulkAssignMspUsers,
  BulkAssignSupportUsers,
  BulkRemoveMspUsers,
  ServiceAction,
  PostServiceActionSaveServiceActionParams,
  PostStagedCompanyConvertParams,
  StagedCompanyConnectionStatus,
  GetStagedCompanyGetConnectionStatusParams,
  StagedCompany,
  GetStagedCompanyGetByIdParams,
  SubscriptionType,
  PromotionCode,
  GetSubscriptionGetPromotionByCodeParams,
  PostSubscriptionAddSubscriptionParams,
  PostSubscriptionAddSubscriptionByPromotionParams,
  PostSubscriptionAddSubscriptionBySupportUserParams,
  SubscriptionDetail,
  SubscriptionLite,
  GetSubscriptionGetSubscriptionByIdParams,
  SubscriptionOptions,
  GetSubscriptionGetSubscriptionOptionsParams,
  CreditRates,
  GetSubscriptionGetCreditRatesParams,
  GetSubscriptionGetSubscriptionRateParams,
  ContractTerms,
  GetSubscriptionGetContractTermsParams,
  PostSubscriptionUpdateSubscriptionParams,
  PostSubscriptionCancelSubscriptionAtContractEndParams,
  PostSubscriptionCancelSubscriptionTodayParams,
  PostSubscriptionReverseSubscriptionCancellationParams,
  CompanyInfo,
  PostSubscriptionUpdateMspCompanyInfoParams,
  PostSubscriptionHandleActivateCompanyParams,
  GetSubscriptionActiveSubscriptionForApplicationParams,
  GetSubscriptionGetAppAccessByCompanyParams,
  PostSubscriptionHandleBulkActivateCompaniesParams,
  GetSubscriptionGetSubscriptionsByCompanyIdParams,
  GetSubscriptionGetAppAccessByMspCompanyParams,
  GetSupportGetSupportedCompaniesParams,
  Int32StringKeyValuePair,
  BulkAssignSupportCompanies,
  User,
  GetUserGetUserByExternalIdParams,
  PostUserCreateUserByEmailAddressParams,
  GetUserGetUserCompaniesParams,
  GetUserGetSupportUserCompaniesParams,
  EffectivePermissions,
  GetUserLoginParams,
  GetUserAzLoginParams,
  EmailVerified,
  GetUserBearerLoginParams,
  GetUserLoginCallbackParams,
  UserActivityQueryResult,
  GetUserQueryUserActivityParams,
  UserListItem,
  SupportUserListItem,
  PostUserSaveUserStatusParams,
  PostUserSaveUserIncludeFutureCompaniesParams,
  DeleteUserDeleteUserParams,
  GetUserGetUserByIdParams,
  TOSAccepted,
  GetUserHasAcceptedTOSParams,
  PostUserSaveTermsOfSvcAgreementParams,
  GetUserGetCompanyTypesByUser200OneItem,
  GetUserGetCompanyTypesByUser200TwoItem,
  GetUserGetCompanyTypesByUser200ThreeItem,
  GetUserGetCompanyTypesByUserParams,
  UserFacingPermissionInfo,
  RoleUserFacingPermission,
  PostUserFacingPermissionSaveRoleUserFacingPermissionsParams,
  PostUserFacingPermissionApplyRoleUfPermissionsParams
} from './Customers/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAccountGetAccounts = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account[]>(
      {url: `/Account/GetAccounts`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountsQueryKey = () => [`/Account/GetAccounts`];

    
export type GetAccountGetAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccounts>>>
export type GetAccountGetAccountsQueryError = unknown

export const useGetAccountGetAccounts = <TData = Awaited<ReturnType<typeof getAccountGetAccounts>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccounts>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccounts>>> = ({ signal }) => getAccountGetAccounts(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccounts>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetAccountsByOU = (
    params?: GetAccountGetAccountsByOUParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account[]>(
      {url: `/Account/GetAccountsByOU`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountsByOUQueryKey = (params?: GetAccountGetAccountsByOUParams,) => [`/Account/GetAccountsByOU`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountsByOUQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountsByOU>>>
export type GetAccountGetAccountsByOUQueryError = unknown

export const useGetAccountGetAccountsByOU = <TData = Awaited<ReturnType<typeof getAccountGetAccountsByOU>>, TError = unknown>(
 params?: GetAccountGetAccountsByOUParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountsByOU>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountsByOUQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountsByOU>>> = ({ signal }) => getAccountGetAccountsByOU(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccountsByOU>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetAccountsByAwsOUId = (
    params?: GetAccountGetAccountsByAwsOUIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account[]>(
      {url: `/Account/GetAccountsByAwsOUId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountsByAwsOUIdQueryKey = (params?: GetAccountGetAccountsByAwsOUIdParams,) => [`/Account/GetAccountsByAwsOUId`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountsByAwsOUIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountsByAwsOUId>>>
export type GetAccountGetAccountsByAwsOUIdQueryError = unknown

export const useGetAccountGetAccountsByAwsOUId = <TData = Awaited<ReturnType<typeof getAccountGetAccountsByAwsOUId>>, TError = unknown>(
 params?: GetAccountGetAccountsByAwsOUIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountsByAwsOUId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountsByAwsOUIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountsByAwsOUId>>> = ({ signal }) => getAccountGetAccountsByAwsOUId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccountsByAwsOUId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetAccount = (
    params?: GetAccountGetAccountParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account>(
      {url: `/Account/GetAccount`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountQueryKey = (params?: GetAccountGetAccountParams,) => [`/Account/GetAccount`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccount>>>
export type GetAccountGetAccountQueryError = unknown

export const useGetAccountGetAccount = <TData = Awaited<ReturnType<typeof getAccountGetAccount>>, TError = unknown>(
 params?: GetAccountGetAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccount>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccount>>> = ({ signal }) => getAccountGetAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccount>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetCompanyAccounts = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account[]>(
      {url: `/Account/GetCompanyAccounts`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetCompanyAccountsQueryKey = () => [`/Account/GetCompanyAccounts`];

    
export type GetAccountGetCompanyAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetCompanyAccounts>>>
export type GetAccountGetCompanyAccountsQueryError = unknown

export const useGetAccountGetCompanyAccounts = <TData = Awaited<ReturnType<typeof getAccountGetCompanyAccounts>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetCompanyAccounts>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetCompanyAccountsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetCompanyAccounts>>> = ({ signal }) => getAccountGetCompanyAccounts(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetCompanyAccounts>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetAccountByAWSId = (
    params?: GetAccountGetAccountByAWSIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account>(
      {url: `/Account/GetAccountByAWSId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountByAWSIdQueryKey = (params?: GetAccountGetAccountByAWSIdParams,) => [`/Account/GetAccountByAWSId`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountByAWSIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountByAWSId>>>
export type GetAccountGetAccountByAWSIdQueryError = unknown

export const useGetAccountGetAccountByAWSId = <TData = Awaited<ReturnType<typeof getAccountGetAccountByAWSId>>, TError = unknown>(
 params?: GetAccountGetAccountByAWSIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountByAWSId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountByAWSIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountByAWSId>>> = ({ signal }) => getAccountGetAccountByAWSId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccountByAWSId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postAccountConnectMasterAccount = (
    account: Account,
    params?: PostAccountConnectMasterAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Account/ConnectMasterAccount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: account,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountConnectMasterAccountMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountConnectMasterAccount>>>
    export type PostAccountConnectMasterAccountMutationBody = Account
    export type PostAccountConnectMasterAccountMutationError = unknown

    export const usePostAccountConnectMasterAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountConnectMasterAccount>>, TError,{data: Account;params?: PostAccountConnectMasterAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountConnectMasterAccount>>, {data: Account;params?: PostAccountConnectMasterAccountParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postAccountConnectMasterAccount(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountConnectMasterAccount>>, TError, {data: Account;params?: PostAccountConnectMasterAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccountConnectSecondaryAccount = (
    account: Account,
    params?: PostAccountConnectSecondaryAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Account/ConnectSecondaryAccount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: account,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountConnectSecondaryAccountMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountConnectSecondaryAccount>>>
    export type PostAccountConnectSecondaryAccountMutationBody = Account
    export type PostAccountConnectSecondaryAccountMutationError = unknown

    export const usePostAccountConnectSecondaryAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountConnectSecondaryAccount>>, TError,{data: Account;params?: PostAccountConnectSecondaryAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountConnectSecondaryAccount>>, {data: Account;params?: PostAccountConnectSecondaryAccountParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postAccountConnectSecondaryAccount(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountConnectSecondaryAccount>>, TError, {data: Account;params?: PostAccountConnectSecondaryAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccountUpdateAccount = (
    account: Account,
    params?: PostAccountUpdateAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Account/UpdateAccount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: account,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountUpdateAccountMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountUpdateAccount>>>
    export type PostAccountUpdateAccountMutationBody = Account
    export type PostAccountUpdateAccountMutationError = unknown

    export const usePostAccountUpdateAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountUpdateAccount>>, TError,{data: Account;params?: PostAccountUpdateAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountUpdateAccount>>, {data: Account;params?: PostAccountUpdateAccountParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postAccountUpdateAccount(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountUpdateAccount>>, TError, {data: Account;params?: PostAccountUpdateAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAccountDisconnectMasterAccount = (
    params?: GetAccountDisconnectMasterAccountParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Account/DisconnectMasterAccount`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountDisconnectMasterAccountQueryKey = (params?: GetAccountDisconnectMasterAccountParams,) => [`/Account/DisconnectMasterAccount`, ...(params ? [params]: [])];

    
export type GetAccountDisconnectMasterAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountDisconnectMasterAccount>>>
export type GetAccountDisconnectMasterAccountQueryError = unknown

export const useGetAccountDisconnectMasterAccount = <TData = Awaited<ReturnType<typeof getAccountDisconnectMasterAccount>>, TError = unknown>(
 params?: GetAccountDisconnectMasterAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountDisconnectMasterAccount>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountDisconnectMasterAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountDisconnectMasterAccount>>> = ({ signal }) => getAccountDisconnectMasterAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountDisconnectMasterAccount>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountDisconnectSecondaryAccount = (
    params?: GetAccountDisconnectSecondaryAccountParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Account/DisconnectSecondaryAccount`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountDisconnectSecondaryAccountQueryKey = (params?: GetAccountDisconnectSecondaryAccountParams,) => [`/Account/DisconnectSecondaryAccount`, ...(params ? [params]: [])];

    
export type GetAccountDisconnectSecondaryAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountDisconnectSecondaryAccount>>>
export type GetAccountDisconnectSecondaryAccountQueryError = unknown

export const useGetAccountDisconnectSecondaryAccount = <TData = Awaited<ReturnType<typeof getAccountDisconnectSecondaryAccount>>, TError = unknown>(
 params?: GetAccountDisconnectSecondaryAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountDisconnectSecondaryAccount>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountDisconnectSecondaryAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountDisconnectSecondaryAccount>>> = ({ signal }) => getAccountDisconnectSecondaryAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountDisconnectSecondaryAccount>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const deleteAccountDeleteMasterAccount = (
    params?: DeleteAccountDeleteMasterAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Account/DeleteMasterAccount`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteAccountDeleteMasterAccountMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountDeleteMasterAccount>>>
    
    export type DeleteAccountDeleteMasterAccountMutationError = unknown

    export const useDeleteAccountDeleteMasterAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountDeleteMasterAccount>>, TError,{params?: DeleteAccountDeleteMasterAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAccountDeleteMasterAccount>>, {params?: DeleteAccountDeleteMasterAccountParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAccountDeleteMasterAccount(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteAccountDeleteMasterAccount>>, TError, {params?: DeleteAccountDeleteMasterAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteAccountDeleteSecondaryAccount = (
    params?: DeleteAccountDeleteSecondaryAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Account/DeleteSecondaryAccount`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteAccountDeleteSecondaryAccountMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountDeleteSecondaryAccount>>>
    
    export type DeleteAccountDeleteSecondaryAccountMutationError = unknown

    export const useDeleteAccountDeleteSecondaryAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountDeleteSecondaryAccount>>, TError,{params?: DeleteAccountDeleteSecondaryAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAccountDeleteSecondaryAccount>>, {params?: DeleteAccountDeleteSecondaryAccountParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAccountDeleteSecondaryAccount(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteAccountDeleteSecondaryAccount>>, TError, {params?: DeleteAccountDeleteSecondaryAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAccountGetAccountConnectionStatus = (
    params?: GetAccountGetAccountConnectionStatusParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AccountConnectedStatus[]>(
      {url: `/Account/GetAccountConnectionStatus`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountConnectionStatusQueryKey = (params?: GetAccountGetAccountConnectionStatusParams,) => [`/Account/GetAccountConnectionStatus`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountConnectionStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatus>>>
export type GetAccountGetAccountConnectionStatusQueryError = unknown

export const useGetAccountGetAccountConnectionStatus = <TData = Awaited<ReturnType<typeof getAccountGetAccountConnectionStatus>>, TError = unknown>(
 params?: GetAccountGetAccountConnectionStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatus>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountConnectionStatusQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatus>>> = ({ signal }) => getAccountGetAccountConnectionStatus(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatus>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountGetAccountConnectionStatusInternal = (
    params?: GetAccountGetAccountConnectionStatusInternalParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AccountConnectedStatus[]>(
      {url: `/Account/GetAccountConnectionStatusInternal`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetAccountConnectionStatusInternalQueryKey = (params?: GetAccountGetAccountConnectionStatusInternalParams,) => [`/Account/GetAccountConnectionStatusInternal`, ...(params ? [params]: [])];

    
export type GetAccountGetAccountConnectionStatusInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatusInternal>>>
export type GetAccountGetAccountConnectionStatusInternalQueryError = unknown

export const useGetAccountGetAccountConnectionStatusInternal = <TData = Awaited<ReturnType<typeof getAccountGetAccountConnectionStatusInternal>>, TError = unknown>(
 params?: GetAccountGetAccountConnectionStatusInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatusInternal>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetAccountConnectionStatusInternalQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatusInternal>>> = ({ signal }) => getAccountGetAccountConnectionStatusInternal(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetAccountConnectionStatusInternal>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAccountCheckIfIsMasterAccount = (
    params?: GetAccountCheckIfIsMasterAccountParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Account/CheckIfIsMasterAccount`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountCheckIfIsMasterAccountQueryKey = (params?: GetAccountCheckIfIsMasterAccountParams,) => [`/Account/CheckIfIsMasterAccount`, ...(params ? [params]: [])];

    
export type GetAccountCheckIfIsMasterAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountCheckIfIsMasterAccount>>>
export type GetAccountCheckIfIsMasterAccountQueryError = unknown

export const useGetAccountCheckIfIsMasterAccount = <TData = Awaited<ReturnType<typeof getAccountCheckIfIsMasterAccount>>, TError = unknown>(
 params?: GetAccountCheckIfIsMasterAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountCheckIfIsMasterAccount>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountCheckIfIsMasterAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountCheckIfIsMasterAccount>>> = ({ signal }) => getAccountCheckIfIsMasterAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountCheckIfIsMasterAccount>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postAccountGetOrCreateMaster = (
    params?: PostAccountGetOrCreateMasterParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Account>(
      {url: `/Account/GetOrCreateMaster`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountGetOrCreateMasterMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountGetOrCreateMaster>>>
    
    export type PostAccountGetOrCreateMasterMutationError = unknown

    export const usePostAccountGetOrCreateMaster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountGetOrCreateMaster>>, TError,{params?: PostAccountGetOrCreateMasterParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountGetOrCreateMaster>>, {params?: PostAccountGetOrCreateMasterParams}> = (props) => {
          const {params} = props ?? {};

          return  postAccountGetOrCreateMaster(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountGetOrCreateMaster>>, TError, {params?: PostAccountGetOrCreateMasterParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccountSyncMaster = (
    params?: PostAccountSyncMasterParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Account>(
      {url: `/Account/SyncMaster`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountSyncMasterMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountSyncMaster>>>
    
    export type PostAccountSyncMasterMutationError = unknown

    export const usePostAccountSyncMaster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountSyncMaster>>, TError,{params?: PostAccountSyncMasterParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountSyncMaster>>, {params?: PostAccountSyncMasterParams}> = (props) => {
          const {params} = props ?? {};

          return  postAccountSyncMaster(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountSyncMaster>>, TError, {params?: PostAccountSyncMasterParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccountGetOrCreateLinked = (
    params?: PostAccountGetOrCreateLinkedParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Account>(
      {url: `/Account/GetOrCreateLinked`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountGetOrCreateLinkedMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountGetOrCreateLinked>>>
    
    export type PostAccountGetOrCreateLinkedMutationError = unknown

    export const usePostAccountGetOrCreateLinked = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountGetOrCreateLinked>>, TError,{params?: PostAccountGetOrCreateLinkedParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountGetOrCreateLinked>>, {params?: PostAccountGetOrCreateLinkedParams}> = (props) => {
          const {params} = props ?? {};

          return  postAccountGetOrCreateLinked(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountGetOrCreateLinked>>, TError, {params?: PostAccountGetOrCreateLinkedParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccountSyncLinked = (
    params?: PostAccountSyncLinkedParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Account>(
      {url: `/Account/SyncLinked`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostAccountSyncLinkedMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountSyncLinked>>>
    
    export type PostAccountSyncLinkedMutationError = unknown

    export const usePostAccountSyncLinked = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountSyncLinked>>, TError,{params?: PostAccountSyncLinkedParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountSyncLinked>>, {params?: PostAccountSyncLinkedParams}> = (props) => {
          const {params} = props ?? {};

          return  postAccountSyncLinked(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccountSyncLinked>>, TError, {params?: PostAccountSyncLinkedParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAccountGetRelatedAccounts = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Account[][]>(
      {url: `/Account/GetRelatedAccounts`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAccountGetRelatedAccountsQueryKey = () => [`/Account/GetRelatedAccounts`];

    
export type GetAccountGetRelatedAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetRelatedAccounts>>>
export type GetAccountGetRelatedAccountsQueryError = unknown

export const useGetAccountGetRelatedAccounts = <TData = Awaited<ReturnType<typeof getAccountGetRelatedAccounts>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountGetRelatedAccounts>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountGetRelatedAccountsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetRelatedAccounts>>> = ({ signal }) => getAccountGetRelatedAccounts(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountGetRelatedAccounts>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getAppGetAppsAndFeatures = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AppFeatures[]>(
      {url: `/App/GetAppsAndFeatures`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetAppGetAppsAndFeaturesQueryKey = () => [`/App/GetAppsAndFeatures`];

    
export type GetAppGetAppsAndFeaturesQueryResult = NonNullable<Awaited<ReturnType<typeof getAppGetAppsAndFeatures>>>
export type GetAppGetAppsAndFeaturesQueryError = unknown

export const useGetAppGetAppsAndFeatures = <TData = Awaited<ReturnType<typeof getAppGetAppsAndFeatures>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppGetAppsAndFeatures>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppGetAppsAndFeaturesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppGetAppsAndFeatures>>> = ({ signal }) => getAppGetAppsAndFeatures(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAppGetAppsAndFeatures>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCloudIntelligenceSubmitCompanyCandidate = (
    customerSubsidyRequest: CustomerSubsidyRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CloudIntelligence/SubmitCompanyCandidate`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: customerSubsidyRequest
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCloudIntelligenceSubmitCompanyCandidateMutationResult = NonNullable<Awaited<ReturnType<typeof postCloudIntelligenceSubmitCompanyCandidate>>>
    export type PostCloudIntelligenceSubmitCompanyCandidateMutationBody = CustomerSubsidyRequest
    export type PostCloudIntelligenceSubmitCompanyCandidateMutationError = unknown

    export const usePostCloudIntelligenceSubmitCompanyCandidate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCloudIntelligenceSubmitCompanyCandidate>>, TError,{data: CustomerSubsidyRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCloudIntelligenceSubmitCompanyCandidate>>, {data: CustomerSubsidyRequest}> = (props) => {
          const {data} = props ?? {};

          return  postCloudIntelligenceSubmitCompanyCandidate(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCloudIntelligenceSubmitCompanyCandidate>>, TError, {data: CustomerSubsidyRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCloudIntelligenceReviewCompanyCandidate = (
    params?: PostCloudIntelligenceReviewCompanyCandidateParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CloudIntelligence/ReviewCompanyCandidate`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCloudIntelligenceReviewCompanyCandidateMutationResult = NonNullable<Awaited<ReturnType<typeof postCloudIntelligenceReviewCompanyCandidate>>>
    
    export type PostCloudIntelligenceReviewCompanyCandidateMutationError = unknown

    export const usePostCloudIntelligenceReviewCompanyCandidate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCloudIntelligenceReviewCompanyCandidate>>, TError,{params?: PostCloudIntelligenceReviewCompanyCandidateParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCloudIntelligenceReviewCompanyCandidate>>, {params?: PostCloudIntelligenceReviewCompanyCandidateParams}> = (props) => {
          const {params} = props ?? {};

          return  postCloudIntelligenceReviewCompanyCandidate(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCloudIntelligenceReviewCompanyCandidate>>, TError, {params?: PostCloudIntelligenceReviewCompanyCandidateParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCloudIntelligenceGetCustomerCompany = (
    params?: GetCloudIntelligenceGetCustomerCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/CloudIntelligence/GetCustomerCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCloudIntelligenceGetCustomerCompanyQueryKey = (params?: GetCloudIntelligenceGetCustomerCompanyParams,) => [`/CloudIntelligence/GetCustomerCompany`, ...(params ? [params]: [])];

    
export type GetCloudIntelligenceGetCustomerCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerCompany>>>
export type GetCloudIntelligenceGetCustomerCompanyQueryError = unknown

export const useGetCloudIntelligenceGetCustomerCompany = <TData = Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerCompany>>, TError = unknown>(
 params?: GetCloudIntelligenceGetCustomerCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCloudIntelligenceGetCustomerCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerCompany>>> = ({ signal }) => getCloudIntelligenceGetCustomerCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCloudIntelligenceGetCustomersByUser = (
    params?: GetCloudIntelligenceGetCustomersByUserParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number[]>(
      {url: `/CloudIntelligence/GetCustomersByUser`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCloudIntelligenceGetCustomersByUserQueryKey = (params?: GetCloudIntelligenceGetCustomersByUserParams,) => [`/CloudIntelligence/GetCustomersByUser`, ...(params ? [params]: [])];

    
export type GetCloudIntelligenceGetCustomersByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomersByUser>>>
export type GetCloudIntelligenceGetCustomersByUserQueryError = unknown

export const useGetCloudIntelligenceGetCustomersByUser = <TData = Awaited<ReturnType<typeof getCloudIntelligenceGetCustomersByUser>>, TError = unknown>(
 params?: GetCloudIntelligenceGetCustomersByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomersByUser>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCloudIntelligenceGetCustomersByUserQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomersByUser>>> = ({ signal }) => getCloudIntelligenceGetCustomersByUser(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomersByUser>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCloudIntelligenceGetCustomerSubsidyRequests = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CustomerSubsidyViewModel[]>(
      {url: `/CloudIntelligence/GetCustomerSubsidyRequests`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCloudIntelligenceGetCustomerSubsidyRequestsQueryKey = () => [`/CloudIntelligence/GetCustomerSubsidyRequests`];

    
export type GetCloudIntelligenceGetCustomerSubsidyRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequests>>>
export type GetCloudIntelligenceGetCustomerSubsidyRequestsQueryError = unknown

export const useGetCloudIntelligenceGetCustomerSubsidyRequests = <TData = Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequests>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequests>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCloudIntelligenceGetCustomerSubsidyRequestsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequests>>> = ({ signal }) => getCloudIntelligenceGetCustomerSubsidyRequests(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequests>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCloudIntelligenceGetCustomerSubsidyRequestById = (
    params?: GetCloudIntelligenceGetCustomerSubsidyRequestByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CustomerSubsidyViewModel>(
      {url: `/CloudIntelligence/GetCustomerSubsidyRequestById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCloudIntelligenceGetCustomerSubsidyRequestByIdQueryKey = (params?: GetCloudIntelligenceGetCustomerSubsidyRequestByIdParams,) => [`/CloudIntelligence/GetCustomerSubsidyRequestById`, ...(params ? [params]: [])];

    
export type GetCloudIntelligenceGetCustomerSubsidyRequestByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequestById>>>
export type GetCloudIntelligenceGetCustomerSubsidyRequestByIdQueryError = unknown

export const useGetCloudIntelligenceGetCustomerSubsidyRequestById = <TData = Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequestById>>, TError = unknown>(
 params?: GetCloudIntelligenceGetCustomerSubsidyRequestByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequestById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCloudIntelligenceGetCustomerSubsidyRequestByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequestById>>> = ({ signal }) => getCloudIntelligenceGetCustomerSubsidyRequestById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCloudIntelligenceGetCustomerSubsidyRequestById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCloudIntelligenceDeleteCompanyCandidate = (
    params?: PostCloudIntelligenceDeleteCompanyCandidateParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CloudIntelligence/DeleteCompanyCandidate`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCloudIntelligenceDeleteCompanyCandidateMutationResult = NonNullable<Awaited<ReturnType<typeof postCloudIntelligenceDeleteCompanyCandidate>>>
    
    export type PostCloudIntelligenceDeleteCompanyCandidateMutationError = unknown

    export const usePostCloudIntelligenceDeleteCompanyCandidate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCloudIntelligenceDeleteCompanyCandidate>>, TError,{params?: PostCloudIntelligenceDeleteCompanyCandidateParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCloudIntelligenceDeleteCompanyCandidate>>, {params?: PostCloudIntelligenceDeleteCompanyCandidateParams}> = (props) => {
          const {params} = props ?? {};

          return  postCloudIntelligenceDeleteCompanyCandidate(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCloudIntelligenceDeleteCompanyCandidate>>, TError, {params?: PostCloudIntelligenceDeleteCompanyCandidateParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCloudIntelligenceInviteAccountRep = (
    params?: PostCloudIntelligenceInviteAccountRepParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CloudIntelligence/InviteAccountRep`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCloudIntelligenceInviteAccountRepMutationResult = NonNullable<Awaited<ReturnType<typeof postCloudIntelligenceInviteAccountRep>>>
    
    export type PostCloudIntelligenceInviteAccountRepMutationError = unknown

    export const usePostCloudIntelligenceInviteAccountRep = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCloudIntelligenceInviteAccountRep>>, TError,{params?: PostCloudIntelligenceInviteAccountRepParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCloudIntelligenceInviteAccountRep>>, {params?: PostCloudIntelligenceInviteAccountRepParams}> = (props) => {
          const {params} = props ?? {};

          return  postCloudIntelligenceInviteAccountRep(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCloudIntelligenceInviteAccountRep>>, TError, {params?: PostCloudIntelligenceInviteAccountRepParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCompanyApps = (
    params?: GetCompanyGetCompanyAppsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyApp[]>(
      {url: `/Company/GetCompanyApps`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyAppsQueryKey = (params?: GetCompanyGetCompanyAppsParams,) => [`/Company/GetCompanyApps`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyAppsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyApps>>>
export type GetCompanyGetCompanyAppsQueryError = unknown

export const useGetCompanyGetCompanyApps = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyApps>>, TError = unknown>(
 params?: GetCompanyGetCompanyAppsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyApps>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyAppsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyApps>>> = ({ signal }) => getCompanyGetCompanyApps(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyApps>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompanyAppInfo = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyApps>(
      {url: `/Company/GetCompanyAppInfo`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyAppInfoQueryKey = () => [`/Company/GetCompanyAppInfo`];

    
export type GetCompanyGetCompanyAppInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyAppInfo>>>
export type GetCompanyGetCompanyAppInfoQueryError = unknown

export const useGetCompanyGetCompanyAppInfo = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyAppInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyAppInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyAppInfoQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyAppInfo>>> = ({ signal }) => getCompanyGetCompanyAppInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyAppInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const putCompanyToggleCompanyAppLocked = (
    params?: PutCompanyToggleCompanyAppLockedParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/ToggleCompanyAppLocked`, method: 'put',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanyToggleCompanyAppLockedMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyToggleCompanyAppLocked>>>
    
    export type PutCompanyToggleCompanyAppLockedMutationError = unknown

    export const usePutCompanyToggleCompanyAppLocked = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyToggleCompanyAppLocked>>, TError,{params?: PutCompanyToggleCompanyAppLockedParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyToggleCompanyAppLocked>>, {params?: PutCompanyToggleCompanyAppLockedParams}> = (props) => {
          const {params} = props ?? {};

          return  putCompanyToggleCompanyAppLocked(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanyToggleCompanyAppLocked>>, TError, {params?: PutCompanyToggleCompanyAppLockedParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const putCompanySaveCompanyApps = (
    putCompanySaveCompanyAppsBody: number[],
    params?: PutCompanySaveCompanyAppsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/SaveCompanyApps`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: putCompanySaveCompanyAppsBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanySaveCompanyAppsMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanySaveCompanyApps>>>
    export type PutCompanySaveCompanyAppsMutationBody = number[]
    export type PutCompanySaveCompanyAppsMutationError = unknown

    export const usePutCompanySaveCompanyApps = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanySaveCompanyApps>>, TError,{data: number[];params?: PutCompanySaveCompanyAppsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanySaveCompanyApps>>, {data: number[];params?: PutCompanySaveCompanyAppsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putCompanySaveCompanyApps(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanySaveCompanyApps>>, TError, {data: number[];params?: PutCompanySaveCompanyAppsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCompanyFeatures = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyFeature[]>(
      {url: `/Company/GetCompanyFeatures`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyFeaturesQueryKey = () => [`/Company/GetCompanyFeatures`];

    
export type GetCompanyGetCompanyFeaturesQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyFeatures>>>
export type GetCompanyGetCompanyFeaturesQueryError = unknown

export const useGetCompanyGetCompanyFeatures = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyFeatures>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyFeatures>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyFeaturesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyFeatures>>> = ({ signal }) => getCompanyGetCompanyFeatures(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyFeatures>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const putCompanySaveCompanyFeatures = (
    putCompanySaveCompanyFeaturesBody: string[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/SaveCompanyFeatures`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: putCompanySaveCompanyFeaturesBody
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanySaveCompanyFeaturesMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanySaveCompanyFeatures>>>
    export type PutCompanySaveCompanyFeaturesMutationBody = string[]
    export type PutCompanySaveCompanyFeaturesMutationError = unknown

    export const usePutCompanySaveCompanyFeatures = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanySaveCompanyFeatures>>, TError,{data: string[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanySaveCompanyFeatures>>, {data: string[]}> = (props) => {
          const {data} = props ?? {};

          return  putCompanySaveCompanyFeatures(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanySaveCompanyFeatures>>, TError, {data: string[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCurrentCompany = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/Company/GetCurrentCompany`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCurrentCompanyQueryKey = () => [`/Company/GetCurrentCompany`];

    
export type GetCompanyGetCurrentCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCurrentCompany>>>
export type GetCompanyGetCurrentCompanyQueryError = unknown

export const useGetCompanyGetCurrentCompany = <TData = Awaited<ReturnType<typeof getCompanyGetCurrentCompany>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCurrentCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCurrentCompanyQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCurrentCompany>>> = ({ signal }) => getCompanyGetCurrentCompany(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCurrentCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompany = (
    params?: GetCompanyGetCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/Company/GetCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyQueryKey = (params?: GetCompanyGetCompanyParams,) => [`/Company/GetCompany`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompany>>>
export type GetCompanyGetCompanyQueryError = unknown

export const useGetCompanyGetCompany = <TData = Awaited<ReturnType<typeof getCompanyGetCompany>>, TError = unknown>(
 params?: GetCompanyGetCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompany>>> = ({ signal }) => getCompanyGetCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompanyInternal = (
    params?: GetCompanyGetCompanyInternalParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/Company/GetCompanyInternal`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyInternalQueryKey = (params?: GetCompanyGetCompanyInternalParams,) => [`/Company/GetCompanyInternal`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyInternal>>>
export type GetCompanyGetCompanyInternalQueryError = unknown

export const useGetCompanyGetCompanyInternal = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyInternal>>, TError = unknown>(
 params?: GetCompanyGetCompanyInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyInternal>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyInternalQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyInternal>>> = ({ signal }) => getCompanyGetCompanyInternal(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyInternal>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompanies = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/Company/GetCompanies`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompaniesQueryKey = () => [`/Company/GetCompanies`];

    
export type GetCompanyGetCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanies>>>
export type GetCompanyGetCompaniesQueryError = unknown

export const useGetCompanyGetCompanies = <TData = Awaited<ReturnType<typeof getCompanyGetCompanies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompaniesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanies>>> = ({ signal }) => getCompanyGetCompanies(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanySaveCompany = (
    company: Company,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/SaveCompany`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: company
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanySaveCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanySaveCompany>>>
    export type PostCompanySaveCompanyMutationBody = Company
    export type PostCompanySaveCompanyMutationError = unknown

    export const usePostCompanySaveCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanySaveCompany>>, TError,{data: Company}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanySaveCompany>>, {data: Company}> = (props) => {
          const {data} = props ?? {};

          return  postCompanySaveCompany(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanySaveCompany>>, TError, {data: Company}, TContext>(mutationFn, mutationOptions)
    }
    
export const putCompanyCreateCompany = (
    params?: PutCompanyCreateCompanyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Company>(
      {url: `/Company/CreateCompany`, method: 'put',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanyCreateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyCreateCompany>>>
    
    export type PutCompanyCreateCompanyMutationError = unknown

    export const usePutCompanyCreateCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyCreateCompany>>, TError,{params?: PutCompanyCreateCompanyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyCreateCompany>>, {params?: PutCompanyCreateCompanyParams}> = (props) => {
          const {params} = props ?? {};

          return  putCompanyCreateCompany(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanyCreateCompany>>, TError, {params?: PutCompanyCreateCompanyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const putCompanyRegisterCompany = (
    params?: PutCompanyRegisterCompanyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Company>(
      {url: `/Company/RegisterCompany`, method: 'put',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanyRegisterCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyRegisterCompany>>>
    
    export type PutCompanyRegisterCompanyMutationError = unknown

    export const usePutCompanyRegisterCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyRegisterCompany>>, TError,{params?: PutCompanyRegisterCompanyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyRegisterCompany>>, {params?: PutCompanyRegisterCompanyParams}> = (props) => {
          const {params} = props ?? {};

          return  putCompanyRegisterCompany(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanyRegisterCompany>>, TError, {params?: PutCompanyRegisterCompanyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCompanyConfig = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyConfiguration[]>(
      {url: `/Company/GetCompanyConfig`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyConfigQueryKey = () => [`/Company/GetCompanyConfig`];

    
export type GetCompanyGetCompanyConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyConfig>>>
export type GetCompanyGetCompanyConfigQueryError = unknown

export const useGetCompanyGetCompanyConfig = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyConfig>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyConfig>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyConfigQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyConfig>>> = ({ signal }) => getCompanyGetCompanyConfig(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyConfig>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const putCompanyAdminRegisterCompany = (
    params?: PutCompanyAdminRegisterCompanyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Company>(
      {url: `/Company/AdminRegisterCompany`, method: 'put',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanyAdminRegisterCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyAdminRegisterCompany>>>
    
    export type PutCompanyAdminRegisterCompanyMutationError = unknown

    export const usePutCompanyAdminRegisterCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyAdminRegisterCompany>>, TError,{params?: PutCompanyAdminRegisterCompanyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyAdminRegisterCompany>>, {params?: PutCompanyAdminRegisterCompanyParams}> = (props) => {
          const {params} = props ?? {};

          return  putCompanyAdminRegisterCompany(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanyAdminRegisterCompany>>, TError, {params?: PutCompanyAdminRegisterCompanyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteCompanyDeleteCompany = (
    params?: DeleteCompanyDeleteCompanyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/DeleteCompany`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteCompanyDeleteCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyDeleteCompany>>>
    
    export type DeleteCompanyDeleteCompanyMutationError = unknown

    export const useDeleteCompanyDeleteCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyDeleteCompany>>, TError,{params?: DeleteCompanyDeleteCompanyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyDeleteCompany>>, {params?: DeleteCompanyDeleteCompanyParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteCompanyDeleteCompany(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCompanyDeleteCompany>>, TError, {params?: DeleteCompanyDeleteCompanyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetAccountCompany = (
    params?: GetCompanyGetAccountCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyAccount[]>(
      {url: `/Company/GetAccountCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetAccountCompanyQueryKey = (params?: GetCompanyGetAccountCompanyParams,) => [`/Company/GetAccountCompany`, ...(params ? [params]: [])];

    
export type GetCompanyGetAccountCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetAccountCompany>>>
export type GetCompanyGetAccountCompanyQueryError = unknown

export const useGetCompanyGetAccountCompany = <TData = Awaited<ReturnType<typeof getCompanyGetAccountCompany>>, TError = unknown>(
 params?: GetCompanyGetAccountCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetAccountCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetAccountCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetAccountCompany>>> = ({ signal }) => getCompanyGetAccountCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetAccountCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanyAddUsers = (
    postCompanyAddUsersBody: number[],
    params?: PostCompanyAddUsersParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/AddUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postCompanyAddUsersBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddUsers>>>
    export type PostCompanyAddUsersMutationBody = number[]
    export type PostCompanyAddUsersMutationError = unknown

    export const usePostCompanyAddUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddUsers>>, TError,{data: number[];params?: PostCompanyAddUsersParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddUsers>>, {data: number[];params?: PostCompanyAddUsersParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanyAddUsers(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddUsers>>, TError, {data: number[];params?: PostCompanyAddUsersParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyAddUser = (
    params?: PostCompanyAddUserParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyUser>(
      {url: `/Company/AddUser`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddUserMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddUser>>>
    
    export type PostCompanyAddUserMutationError = unknown

    export const usePostCompanyAddUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddUser>>, TError,{params?: PostCompanyAddUserParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddUser>>, {params?: PostCompanyAddUserParams}> = (props) => {
          const {params} = props ?? {};

          return  postCompanyAddUser(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddUser>>, TError, {params?: PostCompanyAddUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyRemoveUsers = (
    postCompanyRemoveUsersBody: number[],
    params?: PostCompanyRemoveUsersParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/RemoveUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postCompanyRemoveUsersBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyRemoveUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyRemoveUsers>>>
    export type PostCompanyRemoveUsersMutationBody = number[]
    export type PostCompanyRemoveUsersMutationError = unknown

    export const usePostCompanyRemoveUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyRemoveUsers>>, TError,{data: number[];params?: PostCompanyRemoveUsersParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyRemoveUsers>>, {data: number[];params?: PostCompanyRemoveUsersParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanyRemoveUsers(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyRemoveUsers>>, TError, {data: number[];params?: PostCompanyRemoveUsersParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCompanyBySystemName = (
    params?: GetCompanyGetCompanyBySystemNameParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/Company/GetCompanyBySystemName`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyBySystemNameQueryKey = (params?: GetCompanyGetCompanyBySystemNameParams,) => [`/Company/GetCompanyBySystemName`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyBySystemNameQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyBySystemName>>>
export type GetCompanyGetCompanyBySystemNameQueryError = unknown

export const useGetCompanyGetCompanyBySystemName = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyBySystemName>>, TError = unknown>(
 params?: GetCompanyGetCompanyBySystemNameParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyBySystemName>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyBySystemNameQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyBySystemName>>> = ({ signal }) => getCompanyGetCompanyBySystemName(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyBySystemName>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanySaveCompanyStandardTags = (
    postCompanySaveCompanyStandardTagsBody: PostCompanySaveCompanyStandardTagsBodyOne | PostCompanySaveCompanyStandardTagsBodyTwo | PostCompanySaveCompanyStandardTagsBodyThree,
    params?: PostCompanySaveCompanyStandardTagsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/SaveCompanyStandardTags`, method: 'post',
      data: postCompanySaveCompanyStandardTagsBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanySaveCompanyStandardTagsMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanySaveCompanyStandardTags>>>
    export type PostCompanySaveCompanyStandardTagsMutationBody = PostCompanySaveCompanyStandardTagsBodyOne | PostCompanySaveCompanyStandardTagsBodyTwo | PostCompanySaveCompanyStandardTagsBodyThree
    export type PostCompanySaveCompanyStandardTagsMutationError = unknown

    export const usePostCompanySaveCompanyStandardTags = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanySaveCompanyStandardTags>>, TError,{data: PostCompanySaveCompanyStandardTagsBodyOne | PostCompanySaveCompanyStandardTagsBodyTwo | PostCompanySaveCompanyStandardTagsBodyThree;params?: PostCompanySaveCompanyStandardTagsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanySaveCompanyStandardTags>>, {data: PostCompanySaveCompanyStandardTagsBodyOne | PostCompanySaveCompanyStandardTagsBodyTwo | PostCompanySaveCompanyStandardTagsBodyThree;params?: PostCompanySaveCompanyStandardTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanySaveCompanyStandardTags(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanySaveCompanyStandardTags>>, TError, {data: PostCompanySaveCompanyStandardTagsBodyOne | PostCompanySaveCompanyStandardTagsBodyTwo | PostCompanySaveCompanyStandardTagsBodyThree;params?: PostCompanySaveCompanyStandardTagsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetCompanyStandardTags = (
    params?: GetCompanyGetCompanyStandardTagsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyStandardTag[]>(
      {url: `/Company/GetCompanyStandardTags`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyStandardTagsQueryKey = (params?: GetCompanyGetCompanyStandardTagsParams,) => [`/Company/GetCompanyStandardTags`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyStandardTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyStandardTags>>>
export type GetCompanyGetCompanyStandardTagsQueryError = unknown

export const useGetCompanyGetCompanyStandardTags = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyStandardTags>>, TError = unknown>(
 params?: GetCompanyGetCompanyStandardTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyStandardTags>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyStandardTagsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyStandardTags>>> = ({ signal }) => getCompanyGetCompanyStandardTags(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyStandardTags>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetStandardTags = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<StandardTag[]>(
      {url: `/Company/GetStandardTags`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetStandardTagsQueryKey = () => [`/Company/GetStandardTags`];

    
export type GetCompanyGetStandardTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetStandardTags>>>
export type GetCompanyGetStandardTagsQueryError = unknown

export const useGetCompanyGetStandardTags = <TData = Awaited<ReturnType<typeof getCompanyGetStandardTags>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetStandardTags>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetStandardTagsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetStandardTags>>> = ({ signal }) => getCompanyGetStandardTags(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetStandardTags>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompanyCloudProviders = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyCloudProvider[]>(
      {url: `/Company/GetCompanyCloudProviders`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyCloudProvidersQueryKey = () => [`/Company/GetCompanyCloudProviders`];

    
export type GetCompanyGetCompanyCloudProvidersQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProviders>>>
export type GetCompanyGetCompanyCloudProvidersQueryError = unknown

export const useGetCompanyGetCompanyCloudProviders = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyCloudProviders>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProviders>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyCloudProvidersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProviders>>> = ({ signal }) => getCompanyGetCompanyCloudProviders(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProviders>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCompanyCloudProvidersInternal = (
    params?: GetCompanyGetCompanyCloudProvidersInternalParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyCloudProvider[]>(
      {url: `/Company/GetCompanyCloudProvidersInternal`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCompanyCloudProvidersInternalQueryKey = (params?: GetCompanyGetCompanyCloudProvidersInternalParams,) => [`/Company/GetCompanyCloudProvidersInternal`, ...(params ? [params]: [])];

    
export type GetCompanyGetCompanyCloudProvidersInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProvidersInternal>>>
export type GetCompanyGetCompanyCloudProvidersInternalQueryError = unknown

export const useGetCompanyGetCompanyCloudProvidersInternal = <TData = Awaited<ReturnType<typeof getCompanyGetCompanyCloudProvidersInternal>>, TError = unknown>(
 params?: GetCompanyGetCompanyCloudProvidersInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProvidersInternal>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCompanyCloudProvidersInternalQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProvidersInternal>>> = ({ signal }) => getCompanyGetCompanyCloudProvidersInternal(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCompanyCloudProvidersInternal>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetAzureConnectionInfo = (
    params?: GetCompanyGetAzureConnectionInfoParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AzureConnectivityInfo[]>(
      {url: `/Company/GetAzureConnectionInfo`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetAzureConnectionInfoQueryKey = (params?: GetCompanyGetAzureConnectionInfoParams,) => [`/Company/GetAzureConnectionInfo`, ...(params ? [params]: [])];

    
export type GetCompanyGetAzureConnectionInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfo>>>
export type GetCompanyGetAzureConnectionInfoQueryError = unknown

export const useGetCompanyGetAzureConnectionInfo = <TData = Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfo>>, TError = unknown>(
 params?: GetCompanyGetAzureConnectionInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetAzureConnectionInfoQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfo>>> = ({ signal }) => getCompanyGetAzureConnectionInfo(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetAzureConnectionInfoInternal = (
    params?: GetCompanyGetAzureConnectionInfoInternalParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AzureConnectivityInfo[]>(
      {url: `/Company/GetAzureConnectionInfoInternal`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetAzureConnectionInfoInternalQueryKey = (params?: GetCompanyGetAzureConnectionInfoInternalParams,) => [`/Company/GetAzureConnectionInfoInternal`, ...(params ? [params]: [])];

    
export type GetCompanyGetAzureConnectionInfoInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfoInternal>>>
export type GetCompanyGetAzureConnectionInfoInternalQueryError = unknown

export const useGetCompanyGetAzureConnectionInfoInternal = <TData = Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfoInternal>>, TError = unknown>(
 params?: GetCompanyGetAzureConnectionInfoInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfoInternal>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetAzureConnectionInfoInternalQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfoInternal>>> = ({ signal }) => getCompanyGetAzureConnectionInfoInternal(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetAzureConnectionInfoInternal>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyGetCloudConnections = (
    params?: GetCompanyGetCloudConnectionsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyCloudProvider[]>(
      {url: `/Company/GetCloudConnections`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetCloudConnectionsQueryKey = (params?: GetCompanyGetCloudConnectionsParams,) => [`/Company/GetCloudConnections`, ...(params ? [params]: [])];

    
export type GetCompanyGetCloudConnectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetCloudConnections>>>
export type GetCompanyGetCloudConnectionsQueryError = unknown

export const useGetCompanyGetCloudConnections = <TData = Awaited<ReturnType<typeof getCompanyGetCloudConnections>>, TError = unknown>(
 params?: GetCompanyGetCloudConnectionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetCloudConnections>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetCloudConnectionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetCloudConnections>>> = ({ signal }) => getCompanyGetCloudConnections(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetCloudConnections>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanyAddCloudConnection = (
    newCompanyCloudProvider: NewCompanyCloudProvider,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyCloudProvider>(
      {url: `/Company/AddCloudConnection`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: newCompanyCloudProvider
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddCloudConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddCloudConnection>>>
    export type PostCompanyAddCloudConnectionMutationBody = NewCompanyCloudProvider
    export type PostCompanyAddCloudConnectionMutationError = unknown

    export const usePostCompanyAddCloudConnection = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddCloudConnection>>, TError,{data: NewCompanyCloudProvider}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddCloudConnection>>, {data: NewCompanyCloudProvider}> = (props) => {
          const {data} = props ?? {};

          return  postCompanyAddCloudConnection(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddCloudConnection>>, TError, {data: NewCompanyCloudProvider}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyAddAzureConnectivity = (
    azureConnectivityInfo: AzureConnectivityInfo[],
    params?: PostCompanyAddAzureConnectivityParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AzureConnectivityInfo[]>(
      {url: `/Company/AddAzureConnectivity`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: azureConnectivityInfo,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddAzureConnectivityMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddAzureConnectivity>>>
    export type PostCompanyAddAzureConnectivityMutationBody = AzureConnectivityInfo[]
    export type PostCompanyAddAzureConnectivityMutationError = unknown

    export const usePostCompanyAddAzureConnectivity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddAzureConnectivity>>, TError,{data: AzureConnectivityInfo[];params?: PostCompanyAddAzureConnectivityParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddAzureConnectivity>>, {data: AzureConnectivityInfo[];params?: PostCompanyAddAzureConnectivityParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanyAddAzureConnectivity(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddAzureConnectivity>>, TError, {data: AzureConnectivityInfo[];params?: PostCompanyAddAzureConnectivityParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyAddAzureTenantConnectivity = (
    azureConnectivityInfo: AzureConnectivityInfo[],
    params?: PostCompanyAddAzureTenantConnectivityParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AzureConnectivityInfo[]>(
      {url: `/Company/AddAzureTenantConnectivity`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: azureConnectivityInfo,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddAzureTenantConnectivityMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddAzureTenantConnectivity>>>
    export type PostCompanyAddAzureTenantConnectivityMutationBody = AzureConnectivityInfo[]
    export type PostCompanyAddAzureTenantConnectivityMutationError = unknown

    export const usePostCompanyAddAzureTenantConnectivity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddAzureTenantConnectivity>>, TError,{data: AzureConnectivityInfo[];params?: PostCompanyAddAzureTenantConnectivityParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddAzureTenantConnectivity>>, {data: AzureConnectivityInfo[];params?: PostCompanyAddAzureTenantConnectivityParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanyAddAzureTenantConnectivity(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddAzureTenantConnectivity>>, TError, {data: AzureConnectivityInfo[];params?: PostCompanyAddAzureTenantConnectivityParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyAddAzureTenantCloudProvider = (
    companyCloudProviderRequest: CompanyCloudProviderRequest[],
    params?: PostCompanyAddAzureTenantCloudProviderParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyCloudProvider[]>(
      {url: `/Company/AddAzureTenantCloudProvider`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyCloudProviderRequest,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyAddAzureTenantCloudProviderMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyAddAzureTenantCloudProvider>>>
    export type PostCompanyAddAzureTenantCloudProviderMutationBody = CompanyCloudProviderRequest[]
    export type PostCompanyAddAzureTenantCloudProviderMutationError = unknown

    export const usePostCompanyAddAzureTenantCloudProvider = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyAddAzureTenantCloudProvider>>, TError,{data: CompanyCloudProviderRequest[];params?: PostCompanyAddAzureTenantCloudProviderParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyAddAzureTenantCloudProvider>>, {data: CompanyCloudProviderRequest[];params?: PostCompanyAddAzureTenantCloudProviderParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanyAddAzureTenantCloudProvider(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyAddAzureTenantCloudProvider>>, TError, {data: CompanyCloudProviderRequest[];params?: PostCompanyAddAzureTenantCloudProviderParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetAzureSubscriptions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AzureSubscriptionInfo[]>(
      {url: `/Company/GetAzureSubscriptions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetAzureSubscriptionsQueryKey = () => [`/Company/GetAzureSubscriptions`];

    
export type GetCompanyGetAzureSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetAzureSubscriptions>>>
export type GetCompanyGetAzureSubscriptionsQueryError = unknown

export const useGetCompanyGetAzureSubscriptions = <TData = Awaited<ReturnType<typeof getCompanyGetAzureSubscriptions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetAzureSubscriptions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetAzureSubscriptionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetAzureSubscriptions>>> = ({ signal }) => getCompanyGetAzureSubscriptions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetAzureSubscriptions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyCheckCloudProviderConnectivity = (
    params?: GetCompanyCheckCloudProviderConnectivityParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CloudProviderConnectionStatus[]>(
      {url: `/Company/CheckCloudProviderConnectivity`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyCheckCloudProviderConnectivityQueryKey = (params?: GetCompanyCheckCloudProviderConnectivityParams,) => [`/Company/CheckCloudProviderConnectivity`, ...(params ? [params]: [])];

    
export type GetCompanyCheckCloudProviderConnectivityQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyCheckCloudProviderConnectivity>>>
export type GetCompanyCheckCloudProviderConnectivityQueryError = unknown

export const useGetCompanyCheckCloudProviderConnectivity = <TData = Awaited<ReturnType<typeof getCompanyCheckCloudProviderConnectivity>>, TError = unknown>(
 params?: GetCompanyCheckCloudProviderConnectivityParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyCheckCloudProviderConnectivity>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyCheckCloudProviderConnectivityQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCheckCloudProviderConnectivity>>> = ({ signal }) => getCompanyCheckCloudProviderConnectivity(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyCheckCloudProviderConnectivity>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanySetupSingleSignOn = (
    singleSignOnConfig: SingleSignOnConfig,
 options?: SecondParameter<typeof request>,
) => {
      return request<SingleSignOnConfig>(
      {url: `/Company/SetupSingleSignOn`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: singleSignOnConfig
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanySetupSingleSignOnMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanySetupSingleSignOn>>>
    export type PostCompanySetupSingleSignOnMutationBody = SingleSignOnConfig
    export type PostCompanySetupSingleSignOnMutationError = unknown

    export const usePostCompanySetupSingleSignOn = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanySetupSingleSignOn>>, TError,{data: SingleSignOnConfig}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanySetupSingleSignOn>>, {data: SingleSignOnConfig}> = (props) => {
          const {data} = props ?? {};

          return  postCompanySetupSingleSignOn(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanySetupSingleSignOn>>, TError, {data: SingleSignOnConfig}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyGetSingleSignOn = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SingleSignOnConfig>(
      {url: `/Company/GetSingleSignOn`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyGetSingleSignOnQueryKey = () => [`/Company/GetSingleSignOn`];

    
export type GetCompanyGetSingleSignOnQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGetSingleSignOn>>>
export type GetCompanyGetSingleSignOnQueryError = unknown

export const useGetCompanyGetSingleSignOn = <TData = Awaited<ReturnType<typeof getCompanyGetSingleSignOn>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyGetSingleSignOn>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyGetSingleSignOnQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyGetSingleSignOn>>> = ({ signal }) => getCompanyGetSingleSignOn(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyGetSingleSignOn>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const deleteCompanyRemoveSingleSignOn = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<SingleSignOnConfig>(
      {url: `/Company/RemoveSingleSignOn`, method: 'delete'
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteCompanyRemoveSingleSignOnMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyRemoveSingleSignOn>>>
    
    export type DeleteCompanyRemoveSingleSignOnMutationError = unknown

    export const useDeleteCompanyRemoveSingleSignOn = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyRemoveSingleSignOn>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyRemoveSingleSignOn>>, TVariables> = () => {
          ;

          return  deleteCompanyRemoveSingleSignOn(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCompanyRemoveSingleSignOn>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanySaveCompanySubscriptionType = (
    subscriptionTypeApp: SubscriptionTypeApp[],
    params?: PostCompanySaveCompanySubscriptionTypeParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/SaveCompanySubscriptionType`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: subscriptionTypeApp,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanySaveCompanySubscriptionTypeMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanySaveCompanySubscriptionType>>>
    export type PostCompanySaveCompanySubscriptionTypeMutationBody = SubscriptionTypeApp[]
    export type PostCompanySaveCompanySubscriptionTypeMutationError = unknown

    export const usePostCompanySaveCompanySubscriptionType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanySaveCompanySubscriptionType>>, TError,{data: SubscriptionTypeApp[];params?: PostCompanySaveCompanySubscriptionTypeParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanySaveCompanySubscriptionType>>, {data: SubscriptionTypeApp[];params?: PostCompanySaveCompanySubscriptionTypeParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCompanySaveCompanySubscriptionType(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanySaveCompanySubscriptionType>>, TError, {data: SubscriptionTypeApp[];params?: PostCompanySaveCompanySubscriptionTypeParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteCompanyBulkDeleteCompanies = (
    deleteCompanyBulkDeleteCompaniesBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/BulkDeleteCompanies`, method: 'delete',
      headers: {'Content-Type': 'application/json'},
      data: deleteCompanyBulkDeleteCompaniesBody
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteCompanyBulkDeleteCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyBulkDeleteCompanies>>>
    export type DeleteCompanyBulkDeleteCompaniesMutationBody = number[]
    export type DeleteCompanyBulkDeleteCompaniesMutationError = unknown

    export const useDeleteCompanyBulkDeleteCompanies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyBulkDeleteCompanies>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyBulkDeleteCompanies>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  deleteCompanyBulkDeleteCompanies(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCompanyBulkDeleteCompanies>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const putCompanyUpdateCompanyTypeById = (
    companyTypeRequest: CompanyTypeRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Company/UpdateCompanyTypeById`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: companyTypeRequest
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutCompanyUpdateCompanyTypeByIdMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyUpdateCompanyTypeById>>>
    export type PutCompanyUpdateCompanyTypeByIdMutationBody = CompanyTypeRequest
    export type PutCompanyUpdateCompanyTypeByIdMutationError = unknown

    export const usePutCompanyUpdateCompanyTypeById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyUpdateCompanyTypeById>>, TError,{data: CompanyTypeRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyUpdateCompanyTypeById>>, {data: CompanyTypeRequest}> = (props) => {
          const {data} = props ?? {};

          return  putCompanyUpdateCompanyTypeById(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putCompanyUpdateCompanyTypeById>>, TError, {data: CompanyTypeRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot = (
    params?: GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyDailyCreditSnapshot>(
      {url: `/CompanyDailyCreditSnapshot/GetCompanyDailyCreditSnapshot`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotQueryKey = (params?: GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotParams,) => [`/CompanyDailyCreditSnapshot/GetCompanyDailyCreditSnapshot`, ...(params ? [params]: [])];

    
export type GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot>>>
export type GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotQueryError = unknown

export const useGetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot = <TData = Awaited<ReturnType<typeof getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot>>, TError = unknown>(
 params?: GetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshotQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot>>> = ({ signal }) => getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyDailyCreditSnapshotGetCompanyDailyCreditSnapshot>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot = (
    companyDailyCreditSnapshot: CompanyDailyCreditSnapshot,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyDailyCreditSnapshot>(
      {url: `/CompanyDailyCreditSnapshot/SaveCompanyDailyCreditSnapshot`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyDailyCreditSnapshot
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshotMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot>>>
    export type PostCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshotMutationBody = CompanyDailyCreditSnapshot
    export type PostCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshotMutationError = unknown

    export const usePostCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot>>, TError,{data: CompanyDailyCreditSnapshot}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot>>, {data: CompanyDailyCreditSnapshot}> = (props) => {
          const {data} = props ?? {};

          return  postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyDailyCreditSnapshotSaveCompanyDailyCreditSnapshot>>, TError, {data: CompanyDailyCreditSnapshot}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCompanyRelationshipGetCompanyRelationships = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyRelationshipBundle>(
      {url: `/CompanyRelationship/GetCompanyRelationships`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyRelationshipGetCompanyRelationshipsQueryKey = () => [`/CompanyRelationship/GetCompanyRelationships`];

    
export type GetCompanyRelationshipGetCompanyRelationshipsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyRelationshipGetCompanyRelationships>>>
export type GetCompanyRelationshipGetCompanyRelationshipsQueryError = unknown

export const useGetCompanyRelationshipGetCompanyRelationships = <TData = Awaited<ReturnType<typeof getCompanyRelationshipGetCompanyRelationships>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyRelationshipGetCompanyRelationships>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyRelationshipGetCompanyRelationshipsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyRelationshipGetCompanyRelationships>>> = ({ signal }) => getCompanyRelationshipGetCompanyRelationships(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyRelationshipGetCompanyRelationships>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyRelationshipGetDataAccessDefinitions = (
    params?: GetCompanyRelationshipGetDataAccessDefinitionsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<DataAccessDefinitionBundle[]>(
      {url: `/CompanyRelationship/GetDataAccessDefinitions`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyRelationshipGetDataAccessDefinitionsQueryKey = (params?: GetCompanyRelationshipGetDataAccessDefinitionsParams,) => [`/CompanyRelationship/GetDataAccessDefinitions`, ...(params ? [params]: [])];

    
export type GetCompanyRelationshipGetDataAccessDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessDefinitions>>>
export type GetCompanyRelationshipGetDataAccessDefinitionsQueryError = unknown

export const useGetCompanyRelationshipGetDataAccessDefinitions = <TData = Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessDefinitions>>, TError = unknown>(
 params?: GetCompanyRelationshipGetDataAccessDefinitionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessDefinitions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyRelationshipGetDataAccessDefinitionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessDefinitions>>> = ({ signal }) => getCompanyRelationshipGetDataAccessDefinitions(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessDefinitions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCompanyRelationshipGetDataAccessBeneficiaries = (
    params?: GetCompanyRelationshipGetDataAccessBeneficiariesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number[]>(
      {url: `/CompanyRelationship/GetDataAccessBeneficiaries`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCompanyRelationshipGetDataAccessBeneficiariesQueryKey = (params?: GetCompanyRelationshipGetDataAccessBeneficiariesParams,) => [`/CompanyRelationship/GetDataAccessBeneficiaries`, ...(params ? [params]: [])];

    
export type GetCompanyRelationshipGetDataAccessBeneficiariesQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessBeneficiaries>>>
export type GetCompanyRelationshipGetDataAccessBeneficiariesQueryError = unknown

export const useGetCompanyRelationshipGetDataAccessBeneficiaries = <TData = Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessBeneficiaries>>, TError = unknown>(
 params?: GetCompanyRelationshipGetDataAccessBeneficiariesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessBeneficiaries>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCompanyRelationshipGetDataAccessBeneficiariesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessBeneficiaries>>> = ({ signal }) => getCompanyRelationshipGetDataAccessBeneficiaries(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompanyRelationshipGetDataAccessBeneficiaries>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCompanyStatsUpsertCompanyStats = (
    companyStat: CompanyStat[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CompanyStats/UpsertCompanyStats`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyStat
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyStatsUpsertCompanyStatsMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyStatsUpsertCompanyStats>>>
    export type PostCompanyStatsUpsertCompanyStatsMutationBody = CompanyStat[]
    export type PostCompanyStatsUpsertCompanyStatsMutationError = unknown

    export const usePostCompanyStatsUpsertCompanyStats = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyStatsUpsertCompanyStats>>, TError,{data: CompanyStat[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyStatsUpsertCompanyStats>>, {data: CompanyStat[]}> = (props) => {
          const {data} = props ?? {};

          return  postCompanyStatsUpsertCompanyStats(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyStatsUpsertCompanyStats>>, TError, {data: CompanyStat[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyStatsDeleteCompanyStats = (
    postCompanyStatsDeleteCompanyStatsBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CompanyStats/DeleteCompanyStats`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postCompanyStatsDeleteCompanyStatsBody
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyStatsDeleteCompanyStatsMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyStatsDeleteCompanyStats>>>
    export type PostCompanyStatsDeleteCompanyStatsMutationBody = number[]
    export type PostCompanyStatsDeleteCompanyStatsMutationError = unknown

    export const usePostCompanyStatsDeleteCompanyStats = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyStatsDeleteCompanyStats>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyStatsDeleteCompanyStats>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  postCompanyStatsDeleteCompanyStats(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyStatsDeleteCompanyStats>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCompanyStatsQueryCompanyStats = (
    companyStatsQuery: CompanyStatsQuery,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyStatQueryResult>(
      {url: `/CompanyStats/QueryCompanyStats`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyStatsQuery
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCompanyStatsQueryCompanyStatsMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanyStatsQueryCompanyStats>>>
    export type PostCompanyStatsQueryCompanyStatsMutationBody = CompanyStatsQuery
    export type PostCompanyStatsQueryCompanyStatsMutationError = unknown

    export const usePostCompanyStatsQueryCompanyStats = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanyStatsQueryCompanyStats>>, TError,{data: CompanyStatsQuery}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanyStatsQueryCompanyStats>>, {data: CompanyStatsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postCompanyStatsQueryCompanyStats(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCompanyStatsQueryCompanyStats>>, TError, {data: CompanyStatsQuery}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCreditsGetCreditSummary = (
    params?: GetCreditsGetCreditSummaryParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CreditSummary>(
      {url: `/Credits/GetCreditSummary`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsGetCreditSummaryQueryKey = (params?: GetCreditsGetCreditSummaryParams,) => [`/Credits/GetCreditSummary`, ...(params ? [params]: [])];

    
export type GetCreditsGetCreditSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsGetCreditSummary>>>
export type GetCreditsGetCreditSummaryQueryError = unknown

export const useGetCreditsGetCreditSummary = <TData = Awaited<ReturnType<typeof getCreditsGetCreditSummary>>, TError = unknown>(
 params?: GetCreditsGetCreditSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsGetCreditSummary>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsGetCreditSummaryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsGetCreditSummary>>> = ({ signal }) => getCreditsGetCreditSummary(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsGetCreditSummary>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCreditsUpdateCreditOverride = (
    params?: PostCreditsUpdateCreditOverrideParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<CreditSummary>(
      {url: `/Credits/UpdateCreditOverride`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCreditsUpdateCreditOverrideMutationResult = NonNullable<Awaited<ReturnType<typeof postCreditsUpdateCreditOverride>>>
    
    export type PostCreditsUpdateCreditOverrideMutationError = unknown

    export const usePostCreditsUpdateCreditOverride = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCreditsUpdateCreditOverride>>, TError,{params?: PostCreditsUpdateCreditOverrideParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCreditsUpdateCreditOverride>>, {params?: PostCreditsUpdateCreditOverrideParams}> = (props) => {
          const {params} = props ?? {};

          return  postCreditsUpdateCreditOverride(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCreditsUpdateCreditOverride>>, TError, {params?: PostCreditsUpdateCreditOverrideParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCreditsGetCreditTransactions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CreditTransaction[]>(
      {url: `/Credits/GetCreditTransactions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsGetCreditTransactionsQueryKey = () => [`/Credits/GetCreditTransactions`];

    
export type GetCreditsGetCreditTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsGetCreditTransactions>>>
export type GetCreditsGetCreditTransactionsQueryError = unknown

export const useGetCreditsGetCreditTransactions = <TData = Awaited<ReturnType<typeof getCreditsGetCreditTransactions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsGetCreditTransactions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsGetCreditTransactionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsGetCreditTransactions>>> = ({ signal }) => getCreditsGetCreditTransactions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsGetCreditTransactions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCreditsGetCreditTransactionsForPeriod = (
    params?: GetCreditsGetCreditTransactionsForPeriodParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CreditTransaction[]>(
      {url: `/Credits/GetCreditTransactionsForPeriod`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsGetCreditTransactionsForPeriodQueryKey = (params?: GetCreditsGetCreditTransactionsForPeriodParams,) => [`/Credits/GetCreditTransactionsForPeriod`, ...(params ? [params]: [])];

    
export type GetCreditsGetCreditTransactionsForPeriodQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsGetCreditTransactionsForPeriod>>>
export type GetCreditsGetCreditTransactionsForPeriodQueryError = unknown

export const useGetCreditsGetCreditTransactionsForPeriod = <TData = Awaited<ReturnType<typeof getCreditsGetCreditTransactionsForPeriod>>, TError = unknown>(
 params?: GetCreditsGetCreditTransactionsForPeriodParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsGetCreditTransactionsForPeriod>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsGetCreditTransactionsForPeriodQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsGetCreditTransactionsForPeriod>>> = ({ signal }) => getCreditsGetCreditTransactionsForPeriod(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsGetCreditTransactionsForPeriod>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCreditsGetCreditsSpentForPeriod = (
    params?: GetCreditsGetCreditsSpentForPeriodParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyCreditUsage[]>(
      {url: `/Credits/GetCreditsSpentForPeriod`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsGetCreditsSpentForPeriodQueryKey = (params?: GetCreditsGetCreditsSpentForPeriodParams,) => [`/Credits/GetCreditsSpentForPeriod`, ...(params ? [params]: [])];

    
export type GetCreditsGetCreditsSpentForPeriodQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsGetCreditsSpentForPeriod>>>
export type GetCreditsGetCreditsSpentForPeriodQueryError = unknown

export const useGetCreditsGetCreditsSpentForPeriod = <TData = Awaited<ReturnType<typeof getCreditsGetCreditsSpentForPeriod>>, TError = unknown>(
 params?: GetCreditsGetCreditsSpentForPeriodParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsGetCreditsSpentForPeriod>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsGetCreditsSpentForPeriodQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsGetCreditsSpentForPeriod>>> = ({ signal }) => getCreditsGetCreditsSpentForPeriod(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsGetCreditsSpentForPeriod>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCreditsAddCredits = (
    params?: GetCreditsAddCreditsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Credits/AddCredits`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsAddCreditsQueryKey = (params?: GetCreditsAddCreditsParams,) => [`/Credits/AddCredits`, ...(params ? [params]: [])];

    
export type GetCreditsAddCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsAddCredits>>>
export type GetCreditsAddCreditsQueryError = unknown

export const useGetCreditsAddCredits = <TData = Awaited<ReturnType<typeof getCreditsAddCredits>>, TError = unknown>(
 params?: GetCreditsAddCreditsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsAddCredits>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsAddCreditsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsAddCredits>>> = ({ signal }) => getCreditsAddCredits(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsAddCredits>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCreditsAddCreditsBySupportUser = (
    params?: PostCreditsAddCreditsBySupportUserParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Credits/AddCreditsBySupportUser`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCreditsAddCreditsBySupportUserMutationResult = NonNullable<Awaited<ReturnType<typeof postCreditsAddCreditsBySupportUser>>>
    
    export type PostCreditsAddCreditsBySupportUserMutationError = unknown

    export const usePostCreditsAddCreditsBySupportUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCreditsAddCreditsBySupportUser>>, TError,{params?: PostCreditsAddCreditsBySupportUserParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCreditsAddCreditsBySupportUser>>, {params?: PostCreditsAddCreditsBySupportUserParams}> = (props) => {
          const {params} = props ?? {};

          return  postCreditsAddCreditsBySupportUser(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCreditsAddCreditsBySupportUser>>, TError, {params?: PostCreditsAddCreditsBySupportUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCreditsCheckCreditCount = (
    params?: GetCreditsCheckCreditCountParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number>(
      {url: `/Credits/CheckCreditCount`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsCheckCreditCountQueryKey = (params?: GetCreditsCheckCreditCountParams,) => [`/Credits/CheckCreditCount`, ...(params ? [params]: [])];

    
export type GetCreditsCheckCreditCountQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsCheckCreditCount>>>
export type GetCreditsCheckCreditCountQueryError = unknown

export const useGetCreditsCheckCreditCount = <TData = Awaited<ReturnType<typeof getCreditsCheckCreditCount>>, TError = unknown>(
 params?: GetCreditsCheckCreditCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsCheckCreditCount>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsCheckCreditCountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsCheckCreditCount>>> = ({ signal }) => getCreditsCheckCreditCount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsCheckCreditCount>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCreditsIsSpendLimited = (
    params?: GetCreditsIsSpendLimitedParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Credits/IsSpendLimited`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsIsSpendLimitedQueryKey = (params?: GetCreditsIsSpendLimitedParams,) => [`/Credits/IsSpendLimited`, ...(params ? [params]: [])];

    
export type GetCreditsIsSpendLimitedQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsIsSpendLimited>>>
export type GetCreditsIsSpendLimitedQueryError = unknown

export const useGetCreditsIsSpendLimited = <TData = Awaited<ReturnType<typeof getCreditsIsSpendLimited>>, TError = unknown>(
 params?: GetCreditsIsSpendLimitedParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsIsSpendLimited>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsIsSpendLimitedQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsIsSpendLimited>>> = ({ signal }) => getCreditsIsSpendLimited(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsIsSpendLimited>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCreditsTryClaimCredits = (
    params?: GetCreditsTryClaimCreditsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Credits/TryClaimCredits`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCreditsTryClaimCreditsQueryKey = (params?: GetCreditsTryClaimCreditsParams,) => [`/Credits/TryClaimCredits`, ...(params ? [params]: [])];

    
export type GetCreditsTryClaimCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditsTryClaimCredits>>>
export type GetCreditsTryClaimCreditsQueryError = unknown

export const useGetCreditsTryClaimCredits = <TData = Awaited<ReturnType<typeof getCreditsTryClaimCredits>>, TError = unknown>(
 params?: GetCreditsTryClaimCreditsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditsTryClaimCredits>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCreditsTryClaimCreditsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditsTryClaimCredits>>> = ({ signal }) => getCreditsTryClaimCredits(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCreditsTryClaimCredits>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCreditsUpdateSettingsPage = (
    params?: PostCreditsUpdateSettingsPageParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Credits/UpdateSettingsPage`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCreditsUpdateSettingsPageMutationResult = NonNullable<Awaited<ReturnType<typeof postCreditsUpdateSettingsPage>>>
    
    export type PostCreditsUpdateSettingsPageMutationError = unknown

    export const usePostCreditsUpdateSettingsPage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCreditsUpdateSettingsPage>>, TError,{params?: PostCreditsUpdateSettingsPageParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCreditsUpdateSettingsPage>>, {params?: PostCreditsUpdateSettingsPageParams}> = (props) => {
          const {params} = props ?? {};

          return  postCreditsUpdateSettingsPage(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCreditsUpdateSettingsPage>>, TError, {params?: PostCreditsUpdateSettingsPageParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspMktAzHook = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/AzHook`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktAzHookMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktAzHook>>>
    
    export type PostCspMktAzHookMutationError = unknown

    export const usePostCspMktAzHook = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktAzHook>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktAzHook>>, TVariables> = () => {
          ;

          return  postCspMktAzHook(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktAzHook>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getCspMktGetStagedAzureSubscription = (
    params?: GetCspMktGetStagedAzureSubscriptionParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AzureMarketplaceSubscription>(
      {url: `/CspMkt/GetStagedAzureSubscription`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCspMktGetStagedAzureSubscriptionQueryKey = (params?: GetCspMktGetStagedAzureSubscriptionParams,) => [`/CspMkt/GetStagedAzureSubscription`, ...(params ? [params]: [])];

    
export type GetCspMktGetStagedAzureSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getCspMktGetStagedAzureSubscription>>>
export type GetCspMktGetStagedAzureSubscriptionQueryError = unknown

export const useGetCspMktGetStagedAzureSubscription = <TData = Awaited<ReturnType<typeof getCspMktGetStagedAzureSubscription>>, TError = unknown>(
 params?: GetCspMktGetStagedAzureSubscriptionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCspMktGetStagedAzureSubscription>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCspMktGetStagedAzureSubscriptionQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCspMktGetStagedAzureSubscription>>> = ({ signal }) => getCspMktGetStagedAzureSubscription(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCspMktGetStagedAzureSubscription>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCspMktActivateAzureSubscription = (
    params?: PostCspMktActivateAzureSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/ActivateAzureSubscription`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktActivateAzureSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktActivateAzureSubscription>>>
    
    export type PostCspMktActivateAzureSubscriptionMutationError = unknown

    export const usePostCspMktActivateAzureSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktActivateAzureSubscription>>, TError,{params?: PostCspMktActivateAzureSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktActivateAzureSubscription>>, {params?: PostCspMktActivateAzureSubscriptionParams}> = (props) => {
          const {params} = props ?? {};

          return  postCspMktActivateAzureSubscription(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktActivateAzureSubscription>>, TError, {params?: PostCspMktActivateAzureSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspMktGetPendingPaymentMethod = (
    params?: PostCspMktGetPendingPaymentMethodParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<PendingPaymentMethodInfo>(
      {url: `/CspMkt/GetPendingPaymentMethod`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktGetPendingPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktGetPendingPaymentMethod>>>
    
    export type PostCspMktGetPendingPaymentMethodMutationError = unknown

    export const usePostCspMktGetPendingPaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktGetPendingPaymentMethod>>, TError,{params?: PostCspMktGetPendingPaymentMethodParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktGetPendingPaymentMethod>>, {params?: PostCspMktGetPendingPaymentMethodParams}> = (props) => {
          const {params} = props ?? {};

          return  postCspMktGetPendingPaymentMethod(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktGetPendingPaymentMethod>>, TError, {params?: PostCspMktGetPendingPaymentMethodParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspMktActivateAzureSubscriptionBySecret = (
    params?: PostCspMktActivateAzureSubscriptionBySecretParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/ActivateAzureSubscriptionBySecret`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktActivateAzureSubscriptionBySecretMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktActivateAzureSubscriptionBySecret>>>
    
    export type PostCspMktActivateAzureSubscriptionBySecretMutationError = unknown

    export const usePostCspMktActivateAzureSubscriptionBySecret = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktActivateAzureSubscriptionBySecret>>, TError,{params?: PostCspMktActivateAzureSubscriptionBySecretParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktActivateAzureSubscriptionBySecret>>, {params?: PostCspMktActivateAzureSubscriptionBySecretParams}> = (props) => {
          const {params} = props ?? {};

          return  postCspMktActivateAzureSubscriptionBySecret(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktActivateAzureSubscriptionBySecret>>, TError, {params?: PostCspMktActivateAzureSubscriptionBySecretParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspMktAwsMarketplaceOffer = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/AwsMarketplaceOffer`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktAwsMarketplaceOfferMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktAwsMarketplaceOffer>>>
    
    export type PostCspMktAwsMarketplaceOfferMutationError = unknown

    export const usePostCspMktAwsMarketplaceOffer = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktAwsMarketplaceOffer>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktAwsMarketplaceOffer>>, TVariables> = () => {
          ;

          return  postCspMktAwsMarketplaceOffer(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktAwsMarketplaceOffer>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getCspMktGetAwsSubscriptions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsMarketplaceSubscription[]>(
      {url: `/CspMkt/GetAwsSubscriptions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCspMktGetAwsSubscriptionsQueryKey = () => [`/CspMkt/GetAwsSubscriptions`];

    
export type GetCspMktGetAwsSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptions>>>
export type GetCspMktGetAwsSubscriptionsQueryError = unknown

export const useGetCspMktGetAwsSubscriptions = <TData = Awaited<ReturnType<typeof getCspMktGetAwsSubscriptions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCspMktGetAwsSubscriptionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptions>>> = ({ signal }) => getCspMktGetAwsSubscriptions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCspMktGetAwsMarketplaceOffers = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsMarketplaceOffer[]>(
      {url: `/CspMkt/GetAwsMarketplaceOffers`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCspMktGetAwsMarketplaceOffersQueryKey = () => [`/CspMkt/GetAwsMarketplaceOffers`];

    
export type GetCspMktGetAwsMarketplaceOffersQueryResult = NonNullable<Awaited<ReturnType<typeof getCspMktGetAwsMarketplaceOffers>>>
export type GetCspMktGetAwsMarketplaceOffersQueryError = unknown

export const useGetCspMktGetAwsMarketplaceOffers = <TData = Awaited<ReturnType<typeof getCspMktGetAwsMarketplaceOffers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCspMktGetAwsMarketplaceOffers>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCspMktGetAwsMarketplaceOffersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCspMktGetAwsMarketplaceOffers>>> = ({ signal }) => getCspMktGetAwsMarketplaceOffers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCspMktGetAwsMarketplaceOffers>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCspMktStagePrivateAwsSubscription = (
    createAwsMarketplacePrivateOfferRequest: CreateAwsMarketplacePrivateOfferRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<AwsMarketplaceSubscription>(
      {url: `/CspMkt/StagePrivateAwsSubscription`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: createAwsMarketplacePrivateOfferRequest
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktStagePrivateAwsSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktStagePrivateAwsSubscription>>>
    export type PostCspMktStagePrivateAwsSubscriptionMutationBody = CreateAwsMarketplacePrivateOfferRequest
    export type PostCspMktStagePrivateAwsSubscriptionMutationError = unknown

    export const usePostCspMktStagePrivateAwsSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktStagePrivateAwsSubscription>>, TError,{data: CreateAwsMarketplacePrivateOfferRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktStagePrivateAwsSubscription>>, {data: CreateAwsMarketplacePrivateOfferRequest}> = (props) => {
          const {data} = props ?? {};

          return  postCspMktStagePrivateAwsSubscription(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktStagePrivateAwsSubscription>>, TError, {data: CreateAwsMarketplacePrivateOfferRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteCspMktStagePrivateAwsSubscription = (
    params?: DeleteCspMktStagePrivateAwsSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/StagePrivateAwsSubscription`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteCspMktStagePrivateAwsSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCspMktStagePrivateAwsSubscription>>>
    
    export type DeleteCspMktStagePrivateAwsSubscriptionMutationError = unknown

    export const useDeleteCspMktStagePrivateAwsSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCspMktStagePrivateAwsSubscription>>, TError,{params?: DeleteCspMktStagePrivateAwsSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCspMktStagePrivateAwsSubscription>>, {params?: DeleteCspMktStagePrivateAwsSubscriptionParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteCspMktStagePrivateAwsSubscription(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCspMktStagePrivateAwsSubscription>>, TError, {params?: DeleteCspMktStagePrivateAwsSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCspMktGetAwsSubscription = (
    params?: GetCspMktGetAwsSubscriptionParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsMarketplaceSubscription>(
      {url: `/CspMkt/GetAwsSubscription`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCspMktGetAwsSubscriptionQueryKey = (params?: GetCspMktGetAwsSubscriptionParams,) => [`/CspMkt/GetAwsSubscription`, ...(params ? [params]: [])];

    
export type GetCspMktGetAwsSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getCspMktGetAwsSubscription>>>
export type GetCspMktGetAwsSubscriptionQueryError = unknown

export const useGetCspMktGetAwsSubscription = <TData = Awaited<ReturnType<typeof getCspMktGetAwsSubscription>>, TError = unknown>(
 params?: GetCspMktGetAwsSubscriptionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCspMktGetAwsSubscription>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCspMktGetAwsSubscriptionQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCspMktGetAwsSubscription>>> = ({ signal }) => getCspMktGetAwsSubscription(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCspMktGetAwsSubscription>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCspMktGetAwsSubscriptionById = (
    params?: GetCspMktGetAwsSubscriptionByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsMarketplaceSubscription>(
      {url: `/CspMkt/GetAwsSubscriptionById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCspMktGetAwsSubscriptionByIdQueryKey = (params?: GetCspMktGetAwsSubscriptionByIdParams,) => [`/CspMkt/GetAwsSubscriptionById`, ...(params ? [params]: [])];

    
export type GetCspMktGetAwsSubscriptionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptionById>>>
export type GetCspMktGetAwsSubscriptionByIdQueryError = unknown

export const useGetCspMktGetAwsSubscriptionById = <TData = Awaited<ReturnType<typeof getCspMktGetAwsSubscriptionById>>, TError = unknown>(
 params?: GetCspMktGetAwsSubscriptionByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptionById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCspMktGetAwsSubscriptionByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptionById>>> = ({ signal }) => getCspMktGetAwsSubscriptionById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCspMktGetAwsSubscriptionById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCspMktActivateAwsSubscription = (
    params?: PostCspMktActivateAwsSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/ActivateAwsSubscription`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktActivateAwsSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktActivateAwsSubscription>>>
    
    export type PostCspMktActivateAwsSubscriptionMutationError = unknown

    export const usePostCspMktActivateAwsSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktActivateAwsSubscription>>, TError,{params?: PostCspMktActivateAwsSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktActivateAwsSubscription>>, {params?: PostCspMktActivateAwsSubscriptionParams}> = (props) => {
          const {params} = props ?? {};

          return  postCspMktActivateAwsSubscription(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktActivateAwsSubscription>>, TError, {params?: PostCspMktActivateAwsSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspMktActivateAwsPayment = (
    params?: PostCspMktActivateAwsPaymentParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspMkt/ActivateAwsPayment`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCspMktActivateAwsPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof postCspMktActivateAwsPayment>>>
    
    export type PostCspMktActivateAwsPaymentMutationError = unknown

    export const usePostCspMktActivateAwsPayment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspMktActivateAwsPayment>>, TError,{params?: PostCspMktActivateAwsPaymentParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspMktActivateAwsPayment>>, {params?: PostCspMktActivateAwsPaymentParams}> = (props) => {
          const {params} = props ?? {};

          return  postCspMktActivateAwsPayment(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspMktActivateAwsPayment>>, TError, {params?: PostCspMktActivateAwsPaymentParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCustomerFeedbackSendLead = (
    customerFeedbackRequest: CustomerFeedbackRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CustomerFeedback/SendLead`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: customerFeedbackRequest
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCustomerFeedbackSendLeadMutationResult = NonNullable<Awaited<ReturnType<typeof postCustomerFeedbackSendLead>>>
    export type PostCustomerFeedbackSendLeadMutationBody = CustomerFeedbackRequest
    export type PostCustomerFeedbackSendLeadMutationError = unknown

    export const usePostCustomerFeedbackSendLead = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCustomerFeedbackSendLead>>, TError,{data: CustomerFeedbackRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCustomerFeedbackSendLead>>, {data: CustomerFeedbackRequest}> = (props) => {
          const {data} = props ?? {};

          return  postCustomerFeedbackSendLead(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCustomerFeedbackSendLead>>, TError, {data: CustomerFeedbackRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCWMetricsConfigGetCWMetricsConfig = (
    params?: GetCWMetricsConfigGetCWMetricsConfigParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CWMetricsConfigData>(
      {url: `/CWMetricsConfig/GetCWMetricsConfig`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCWMetricsConfigGetCWMetricsConfigQueryKey = (params?: GetCWMetricsConfigGetCWMetricsConfigParams,) => [`/CWMetricsConfig/GetCWMetricsConfig`, ...(params ? [params]: [])];

    
export type GetCWMetricsConfigGetCWMetricsConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfig>>>
export type GetCWMetricsConfigGetCWMetricsConfigQueryError = unknown

export const useGetCWMetricsConfigGetCWMetricsConfig = <TData = Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfig>>, TError = unknown>(
 params?: GetCWMetricsConfigGetCWMetricsConfigParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfig>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCWMetricsConfigGetCWMetricsConfigQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfig>>> = ({ signal }) => getCWMetricsConfigGetCWMetricsConfig(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfig>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCWMetricsConfigGetCWMetricsConfigData = (
    params?: GetCWMetricsConfigGetCWMetricsConfigDataParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CWMetricsConfigData[]>(
      {url: `/CWMetricsConfig/GetCWMetricsConfigData`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCWMetricsConfigGetCWMetricsConfigDataQueryKey = (params?: GetCWMetricsConfigGetCWMetricsConfigDataParams,) => [`/CWMetricsConfig/GetCWMetricsConfigData`, ...(params ? [params]: [])];

    
export type GetCWMetricsConfigGetCWMetricsConfigDataQueryResult = NonNullable<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigData>>>
export type GetCWMetricsConfigGetCWMetricsConfigDataQueryError = unknown

export const useGetCWMetricsConfigGetCWMetricsConfigData = <TData = Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigData>>, TError = unknown>(
 params?: GetCWMetricsConfigGetCWMetricsConfigDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigData>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCWMetricsConfigGetCWMetricsConfigDataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigData>>> = ({ signal }) => getCWMetricsConfigGetCWMetricsConfigData(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigData>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCWMetricsConfigGetCWMetricsConfigFilters = (
    params?: GetCWMetricsConfigGetCWMetricsConfigFiltersParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsCWMetricsConfig[]>(
      {url: `/CWMetricsConfig/GetCWMetricsConfigFilters`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCWMetricsConfigGetCWMetricsConfigFiltersQueryKey = (params?: GetCWMetricsConfigGetCWMetricsConfigFiltersParams,) => [`/CWMetricsConfig/GetCWMetricsConfigFilters`, ...(params ? [params]: [])];

    
export type GetCWMetricsConfigGetCWMetricsConfigFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigFilters>>>
export type GetCWMetricsConfigGetCWMetricsConfigFiltersQueryError = unknown

export const useGetCWMetricsConfigGetCWMetricsConfigFilters = <TData = Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigFilters>>, TError = unknown>(
 params?: GetCWMetricsConfigGetCWMetricsConfigFiltersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigFilters>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCWMetricsConfigGetCWMetricsConfigFiltersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigFilters>>> = ({ signal }) => getCWMetricsConfigGetCWMetricsConfigFilters(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCWMetricsConfigGetCWMetricsConfigFilters>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getCWMetricsConfigGetCwMetricFiltersOrDefault = (
    params?: GetCWMetricsConfigGetCwMetricFiltersOrDefaultParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<GetCWMetricsConfigGetCwMetricFiltersOrDefault200One | GetCWMetricsConfigGetCwMetricFiltersOrDefault200Two | GetCWMetricsConfigGetCwMetricFiltersOrDefault200Three>(
      {url: `/CWMetricsConfig/GetCwMetricFiltersOrDefault`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetCWMetricsConfigGetCwMetricFiltersOrDefaultQueryKey = (params?: GetCWMetricsConfigGetCwMetricFiltersOrDefaultParams,) => [`/CWMetricsConfig/GetCwMetricFiltersOrDefault`, ...(params ? [params]: [])];

    
export type GetCWMetricsConfigGetCwMetricFiltersOrDefaultQueryResult = NonNullable<Awaited<ReturnType<typeof getCWMetricsConfigGetCwMetricFiltersOrDefault>>>
export type GetCWMetricsConfigGetCwMetricFiltersOrDefaultQueryError = unknown

export const useGetCWMetricsConfigGetCwMetricFiltersOrDefault = <TData = Awaited<ReturnType<typeof getCWMetricsConfigGetCwMetricFiltersOrDefault>>, TError = unknown>(
 params?: GetCWMetricsConfigGetCwMetricFiltersOrDefaultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCWMetricsConfigGetCwMetricFiltersOrDefault>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCWMetricsConfigGetCwMetricFiltersOrDefaultQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCWMetricsConfigGetCwMetricFiltersOrDefault>>> = ({ signal }) => getCWMetricsConfigGetCwMetricFiltersOrDefault(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCWMetricsConfigGetCwMetricFiltersOrDefault>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postCWMetricsConfigSaveCWMetricsConfig = (
    params?: PostCWMetricsConfigSaveCWMetricsConfigParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/CWMetricsConfig/SaveCWMetricsConfig`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCWMetricsConfigSaveCWMetricsConfigMutationResult = NonNullable<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfig>>>
    
    export type PostCWMetricsConfigSaveCWMetricsConfigMutationError = unknown

    export const usePostCWMetricsConfigSaveCWMetricsConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfig>>, TError,{params?: PostCWMetricsConfigSaveCWMetricsConfigParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfig>>, {params?: PostCWMetricsConfigSaveCWMetricsConfigParams}> = (props) => {
          const {params} = props ?? {};

          return  postCWMetricsConfigSaveCWMetricsConfig(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfig>>, TError, {params?: PostCWMetricsConfigSaveCWMetricsConfigParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCWMetricsConfigSaveCWMetricsConfigFilters = (
    params?: PostCWMetricsConfigSaveCWMetricsConfigFiltersParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/CWMetricsConfig/SaveCWMetricsConfigFilters`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostCWMetricsConfigSaveCWMetricsConfigFiltersMutationResult = NonNullable<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfigFilters>>>
    
    export type PostCWMetricsConfigSaveCWMetricsConfigFiltersMutationError = unknown

    export const usePostCWMetricsConfigSaveCWMetricsConfigFilters = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfigFilters>>, TError,{params?: PostCWMetricsConfigSaveCWMetricsConfigFiltersParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfigFilters>>, {params?: PostCWMetricsConfigSaveCWMetricsConfigFiltersParams}> = (props) => {
          const {params} = props ?? {};

          return  postCWMetricsConfigSaveCWMetricsConfigFilters(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCWMetricsConfigSaveCWMetricsConfigFilters>>, TError, {params?: PostCWMetricsConfigSaveCWMetricsConfigFiltersParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDashboardSave = (
    dashboardConfig: DashboardConfig,
 options?: SecondParameter<typeof request>,
) => {
      return request<DashboardConfig>(
      {url: `/Dashboard/Save`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: dashboardConfig
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostDashboardSaveMutationResult = NonNullable<Awaited<ReturnType<typeof postDashboardSave>>>
    export type PostDashboardSaveMutationBody = DashboardConfig
    export type PostDashboardSaveMutationError = unknown

    export const usePostDashboardSave = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDashboardSave>>, TError,{data: DashboardConfig}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDashboardSave>>, {data: DashboardConfig}> = (props) => {
          const {data} = props ?? {};

          return  postDashboardSave(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDashboardSave>>, TError, {data: DashboardConfig}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDashboardShare = (
    dashboardConfig: DashboardConfig,
 options?: SecondParameter<typeof request>,
) => {
      return request<DashboardConfig>(
      {url: `/Dashboard/Share`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: dashboardConfig
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostDashboardShareMutationResult = NonNullable<Awaited<ReturnType<typeof postDashboardShare>>>
    export type PostDashboardShareMutationBody = DashboardConfig
    export type PostDashboardShareMutationError = unknown

    export const usePostDashboardShare = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDashboardShare>>, TError,{data: DashboardConfig}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDashboardShare>>, {data: DashboardConfig}> = (props) => {
          const {data} = props ?? {};

          return  postDashboardShare(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDashboardShare>>, TError, {data: DashboardConfig}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDashboardShareWithUser = (
    postDashboardShareWithUserBody: number[],
    params?: PostDashboardShareWithUserParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Dashboard/ShareWithUser`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postDashboardShareWithUserBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostDashboardShareWithUserMutationResult = NonNullable<Awaited<ReturnType<typeof postDashboardShareWithUser>>>
    export type PostDashboardShareWithUserMutationBody = number[]
    export type PostDashboardShareWithUserMutationError = unknown

    export const usePostDashboardShareWithUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDashboardShareWithUser>>, TError,{data: number[];params?: PostDashboardShareWithUserParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDashboardShareWithUser>>, {data: number[];params?: PostDashboardShareWithUserParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postDashboardShareWithUser(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDashboardShareWithUser>>, TError, {data: number[];params?: PostDashboardShareWithUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDashboardRemoveSharedAccess = (
    params?: PostDashboardRemoveSharedAccessParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Dashboard/RemoveSharedAccess`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostDashboardRemoveSharedAccessMutationResult = NonNullable<Awaited<ReturnType<typeof postDashboardRemoveSharedAccess>>>
    
    export type PostDashboardRemoveSharedAccessMutationError = unknown

    export const usePostDashboardRemoveSharedAccess = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDashboardRemoveSharedAccess>>, TError,{params?: PostDashboardRemoveSharedAccessParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDashboardRemoveSharedAccess>>, {params?: PostDashboardRemoveSharedAccessParams}> = (props) => {
          const {params} = props ?? {};

          return  postDashboardRemoveSharedAccess(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDashboardRemoveSharedAccess>>, TError, {params?: PostDashboardRemoveSharedAccessParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getDashboardGetUsersByDashboardId = (
    params?: GetDashboardGetUsersByDashboardIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number[]>(
      {url: `/Dashboard/GetUsersByDashboardId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetDashboardGetUsersByDashboardIdQueryKey = (params?: GetDashboardGetUsersByDashboardIdParams,) => [`/Dashboard/GetUsersByDashboardId`, ...(params ? [params]: [])];

    
export type GetDashboardGetUsersByDashboardIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardGetUsersByDashboardId>>>
export type GetDashboardGetUsersByDashboardIdQueryError = unknown

export const useGetDashboardGetUsersByDashboardId = <TData = Awaited<ReturnType<typeof getDashboardGetUsersByDashboardId>>, TError = unknown>(
 params?: GetDashboardGetUsersByDashboardIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardGetUsersByDashboardId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDashboardGetUsersByDashboardIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardGetUsersByDashboardId>>> = ({ signal }) => getDashboardGetUsersByDashboardId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDashboardGetUsersByDashboardId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getDashboard = (
    params?: GetDashboardParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<DashboardConfig>(
      {url: `/Dashboard`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetDashboardQueryKey = (params?: GetDashboardParams,) => [`/Dashboard`, ...(params ? [params]: [])];

    
export type GetDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboard>>>
export type GetDashboardQueryError = unknown

export const useGetDashboard = <TData = Awaited<ReturnType<typeof getDashboard>>, TError = unknown>(
 params?: GetDashboardParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboard>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDashboardQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboard>>> = ({ signal }) => getDashboard(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDashboard>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const deleteDashboard = (
    params?: DeleteDashboardParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Dashboard`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteDashboardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDashboard>>>
    
    export type DeleteDashboardMutationError = unknown

    export const useDeleteDashboard = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDashboard>>, TError,{params?: DeleteDashboardParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDashboard>>, {params?: DeleteDashboardParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteDashboard(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDashboard>>, TError, {params?: DeleteDashboardParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getDashboardGetByKey = (
    params?: GetDashboardGetByKeyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<DashboardConfig[]>(
      {url: `/Dashboard/GetByKey`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetDashboardGetByKeyQueryKey = (params?: GetDashboardGetByKeyParams,) => [`/Dashboard/GetByKey`, ...(params ? [params]: [])];

    
export type GetDashboardGetByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardGetByKey>>>
export type GetDashboardGetByKeyQueryError = unknown

export const useGetDashboardGetByKey = <TData = Awaited<ReturnType<typeof getDashboardGetByKey>>, TError = unknown>(
 params?: GetDashboardGetByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardGetByKey>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDashboardGetByKeyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardGetByKey>>> = ({ signal }) => getDashboardGetByKey(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDashboardGetByKey>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getDashboardGetOrCreateDashboardUserSettings = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<DashboardUserSettings>(
      {url: `/Dashboard/GetOrCreateDashboardUserSettings`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetDashboardGetOrCreateDashboardUserSettingsQueryKey = () => [`/Dashboard/GetOrCreateDashboardUserSettings`];

    
export type GetDashboardGetOrCreateDashboardUserSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardGetOrCreateDashboardUserSettings>>>
export type GetDashboardGetOrCreateDashboardUserSettingsQueryError = unknown

export const useGetDashboardGetOrCreateDashboardUserSettings = <TData = Awaited<ReturnType<typeof getDashboardGetOrCreateDashboardUserSettings>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardGetOrCreateDashboardUserSettings>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDashboardGetOrCreateDashboardUserSettingsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardGetOrCreateDashboardUserSettings>>> = ({ signal }) => getDashboardGetOrCreateDashboardUserSettings(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDashboardGetOrCreateDashboardUserSettings>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postDashboardSaveDashboardUserSettings = (
    dashboardUserSettings: DashboardUserSettings,
 options?: SecondParameter<typeof request>,
) => {
      return request<DashboardUserSettings>(
      {url: `/Dashboard/SaveDashboardUserSettings`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: dashboardUserSettings
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostDashboardSaveDashboardUserSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof postDashboardSaveDashboardUserSettings>>>
    export type PostDashboardSaveDashboardUserSettingsMutationBody = DashboardUserSettings
    export type PostDashboardSaveDashboardUserSettingsMutationError = unknown

    export const usePostDashboardSaveDashboardUserSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDashboardSaveDashboardUserSettings>>, TError,{data: DashboardUserSettings}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDashboardSaveDashboardUserSettings>>, {data: DashboardUserSettings}> = (props) => {
          const {data} = props ?? {};

          return  postDashboardSaveDashboardUserSettings(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDashboardSaveDashboardUserSettings>>, TError, {data: DashboardUserSettings}, TContext>(mutationFn, mutationOptions)
    }
    
export const postFrontEndErrorSaveError = (
    frontEndError: FrontEndError,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/FrontEndError/SaveError`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: frontEndError
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostFrontEndErrorSaveErrorMutationResult = NonNullable<Awaited<ReturnType<typeof postFrontEndErrorSaveError>>>
    export type PostFrontEndErrorSaveErrorMutationBody = FrontEndError
    export type PostFrontEndErrorSaveErrorMutationError = unknown

    export const usePostFrontEndErrorSaveError = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFrontEndErrorSaveError>>, TError,{data: FrontEndError}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFrontEndErrorSaveError>>, {data: FrontEndError}> = (props) => {
          const {data} = props ?? {};

          return  postFrontEndErrorSaveError(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postFrontEndErrorSaveError>>, TError, {data: FrontEndError}, TContext>(mutationFn, mutationOptions)
    }
    
export const getIntegrationGetIntegrations = (
    params?: GetIntegrationGetIntegrationsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Integration[]>(
      {url: `/Integration/GetIntegrations`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetIntegrationGetIntegrationsQueryKey = (params?: GetIntegrationGetIntegrationsParams,) => [`/Integration/GetIntegrations`, ...(params ? [params]: [])];

    
export type GetIntegrationGetIntegrationsQueryResult = NonNullable<Awaited<ReturnType<typeof getIntegrationGetIntegrations>>>
export type GetIntegrationGetIntegrationsQueryError = unknown

export const useGetIntegrationGetIntegrations = <TData = Awaited<ReturnType<typeof getIntegrationGetIntegrations>>, TError = unknown>(
 params?: GetIntegrationGetIntegrationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIntegrationGetIntegrations>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetIntegrationGetIntegrationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntegrationGetIntegrations>>> = ({ signal }) => getIntegrationGetIntegrations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getIntegrationGetIntegrations>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postIntegrationSaveIntegration = (
    integration: Integration,
 options?: SecondParameter<typeof request>,
) => {
      return request<Integration>(
      {url: `/Integration/SaveIntegration`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: integration
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostIntegrationSaveIntegrationMutationResult = NonNullable<Awaited<ReturnType<typeof postIntegrationSaveIntegration>>>
    export type PostIntegrationSaveIntegrationMutationBody = Integration
    export type PostIntegrationSaveIntegrationMutationError = unknown

    export const usePostIntegrationSaveIntegration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postIntegrationSaveIntegration>>, TError,{data: Integration}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postIntegrationSaveIntegration>>, {data: Integration}> = (props) => {
          const {data} = props ?? {};

          return  postIntegrationSaveIntegration(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postIntegrationSaveIntegration>>, TError, {data: Integration}, TContext>(mutationFn, mutationOptions)
    }
    
export const postIntegrationUpdateStatus = (
    params?: PostIntegrationUpdateStatusParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Integration>(
      {url: `/Integration/UpdateStatus`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostIntegrationUpdateStatusMutationResult = NonNullable<Awaited<ReturnType<typeof postIntegrationUpdateStatus>>>
    
    export type PostIntegrationUpdateStatusMutationError = unknown

    export const usePostIntegrationUpdateStatus = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postIntegrationUpdateStatus>>, TError,{params?: PostIntegrationUpdateStatusParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postIntegrationUpdateStatus>>, {params?: PostIntegrationUpdateStatusParams}> = (props) => {
          const {params} = props ?? {};

          return  postIntegrationUpdateStatus(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postIntegrationUpdateStatus>>, TError, {params?: PostIntegrationUpdateStatusParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postIntegrationSignOffIntegration = (
    params?: PostIntegrationSignOffIntegrationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Integration>(
      {url: `/Integration/SignOffIntegration`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostIntegrationSignOffIntegrationMutationResult = NonNullable<Awaited<ReturnType<typeof postIntegrationSignOffIntegration>>>
    
    export type PostIntegrationSignOffIntegrationMutationError = unknown

    export const usePostIntegrationSignOffIntegration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postIntegrationSignOffIntegration>>, TError,{params?: PostIntegrationSignOffIntegrationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postIntegrationSignOffIntegration>>, {params?: PostIntegrationSignOffIntegrationParams}> = (props) => {
          const {params} = props ?? {};

          return  postIntegrationSignOffIntegration(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postIntegrationSignOffIntegration>>, TError, {params?: PostIntegrationSignOffIntegrationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getInvoiceGetInvoices = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<InvoiceDetail[]>(
      {url: `/Invoice/GetInvoices`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetInvoiceGetInvoicesQueryKey = () => [`/Invoice/GetInvoices`];

    
export type GetInvoiceGetInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceGetInvoices>>>
export type GetInvoiceGetInvoicesQueryError = unknown

export const useGetInvoiceGetInvoices = <TData = Awaited<ReturnType<typeof getInvoiceGetInvoices>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceGetInvoices>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceGetInvoicesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceGetInvoices>>> = ({ signal }) => getInvoiceGetInvoices(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceGetInvoices>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getInvoiceDownloadInvoice = (
    params?: GetInvoiceDownloadInvoiceParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string>(
      {url: `/Invoice/DownloadInvoice`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetInvoiceDownloadInvoiceQueryKey = (params?: GetInvoiceDownloadInvoiceParams,) => [`/Invoice/DownloadInvoice`, ...(params ? [params]: [])];

    
export type GetInvoiceDownloadInvoiceQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceDownloadInvoice>>>
export type GetInvoiceDownloadInvoiceQueryError = unknown

export const useGetInvoiceDownloadInvoice = <TData = Awaited<ReturnType<typeof getInvoiceDownloadInvoice>>, TError = unknown>(
 params?: GetInvoiceDownloadInvoiceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceDownloadInvoice>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceDownloadInvoiceQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceDownloadInvoice>>> = ({ signal }) => getInvoiceDownloadInvoice(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceDownloadInvoice>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postInvoiceCreateEstimate = (
    estimateRequest: EstimateRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<InvoiceDetail>(
      {url: `/Invoice/CreateEstimate`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: estimateRequest
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostInvoiceCreateEstimateMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceCreateEstimate>>>
    export type PostInvoiceCreateEstimateMutationBody = EstimateRequest
    export type PostInvoiceCreateEstimateMutationError = unknown

    export const usePostInvoiceCreateEstimate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceCreateEstimate>>, TError,{data: EstimateRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceCreateEstimate>>, {data: EstimateRequest}> = (props) => {
          const {data} = props ?? {};

          return  postInvoiceCreateEstimate(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceCreateEstimate>>, TError, {data: EstimateRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspGetMspByCompanyId = (
    params?: GetMspGetMspByCompanyIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Msp>(
      {url: `/Msp/GetMspByCompanyId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspByCompanyIdQueryKey = (params?: GetMspGetMspByCompanyIdParams,) => [`/Msp/GetMspByCompanyId`, ...(params ? [params]: [])];

    
export type GetMspGetMspByCompanyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspByCompanyId>>>
export type GetMspGetMspByCompanyIdQueryError = unknown

export const useGetMspGetMspByCompanyId = <TData = Awaited<ReturnType<typeof getMspGetMspByCompanyId>>, TError = unknown>(
 params?: GetMspGetMspByCompanyIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspByCompanyId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspByCompanyIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspByCompanyId>>> = ({ signal }) => getMspGetMspByCompanyId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspByCompanyId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetCompaniesForMsp = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/Msp/GetCompaniesForMsp`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetCompaniesForMspQueryKey = () => [`/Msp/GetCompaniesForMsp`];

    
export type GetMspGetCompaniesForMspQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetCompaniesForMsp>>>
export type GetMspGetCompaniesForMspQueryError = unknown

export const useGetMspGetCompaniesForMsp = <TData = Awaited<ReturnType<typeof getMspGetCompaniesForMsp>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetCompaniesForMsp>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetCompaniesForMspQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetCompaniesForMsp>>> = ({ signal }) => getMspGetCompaniesForMsp(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetCompaniesForMsp>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetMspSubscriptionType = (
    params?: GetMspGetMspSubscriptionTypeParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspSubscriptionType>(
      {url: `/Msp/GetMspSubscriptionType`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspSubscriptionTypeQueryKey = (params?: GetMspGetMspSubscriptionTypeParams,) => [`/Msp/GetMspSubscriptionType`, ...(params ? [params]: [])];

    
export type GetMspGetMspSubscriptionTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspSubscriptionType>>>
export type GetMspGetMspSubscriptionTypeQueryError = unknown

export const useGetMspGetMspSubscriptionType = <TData = Awaited<ReturnType<typeof getMspGetMspSubscriptionType>>, TError = unknown>(
 params?: GetMspGetMspSubscriptionTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspSubscriptionType>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspSubscriptionTypeQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspSubscriptionType>>> = ({ signal }) => getMspGetMspSubscriptionType(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspSubscriptionType>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetMspChildCompanySubscription = (
    params?: GetMspGetMspChildCompanySubscriptionParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanySubscriptionType>(
      {url: `/Msp/GetMspChildCompanySubscription`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspChildCompanySubscriptionQueryKey = (params?: GetMspGetMspChildCompanySubscriptionParams,) => [`/Msp/GetMspChildCompanySubscription`, ...(params ? [params]: [])];

    
export type GetMspGetMspChildCompanySubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspChildCompanySubscription>>>
export type GetMspGetMspChildCompanySubscriptionQueryError = unknown

export const useGetMspGetMspChildCompanySubscription = <TData = Awaited<ReturnType<typeof getMspGetMspChildCompanySubscription>>, TError = unknown>(
 params?: GetMspGetMspChildCompanySubscriptionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspChildCompanySubscription>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspChildCompanySubscriptionQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspChildCompanySubscription>>> = ({ signal }) => getMspGetMspChildCompanySubscription(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspChildCompanySubscription>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetMspCreditPackagesByCompany = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCreditPackage[]>(
      {url: `/Msp/GetMspCreditPackagesByCompany`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspCreditPackagesByCompanyQueryKey = () => [`/Msp/GetMspCreditPackagesByCompany`];

    
export type GetMspGetMspCreditPackagesByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspCreditPackagesByCompany>>>
export type GetMspGetMspCreditPackagesByCompanyQueryError = unknown

export const useGetMspGetMspCreditPackagesByCompany = <TData = Awaited<ReturnType<typeof getMspGetMspCreditPackagesByCompany>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspCreditPackagesByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspCreditPackagesByCompanyQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspCreditPackagesByCompany>>> = ({ signal }) => getMspGetMspCreditPackagesByCompany(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspCreditPackagesByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetMyMsp = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company>(
      {url: `/Msp/GetMyMsp`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMyMspQueryKey = () => [`/Msp/GetMyMsp`];

    
export type GetMspGetMyMspQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMyMsp>>>
export type GetMspGetMyMspQueryError = unknown

export const useGetMspGetMyMsp = <TData = Awaited<ReturnType<typeof getMspGetMyMsp>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMyMsp>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMyMspQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMyMsp>>> = ({ signal }) => getMspGetMyMsp(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMyMsp>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMspSaveMspUserCompanies = (
    postMspSaveMspUserCompaniesBody: number[],
    params?: PostMspSaveMspUserCompaniesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Msp/SaveMspUserCompanies`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postMspSaveMspUserCompaniesBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostMspSaveMspUserCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof postMspSaveMspUserCompanies>>>
    export type PostMspSaveMspUserCompaniesMutationBody = number[]
    export type PostMspSaveMspUserCompaniesMutationError = unknown

    export const usePostMspSaveMspUserCompanies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMspSaveMspUserCompanies>>, TError,{data: number[];params?: PostMspSaveMspUserCompaniesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMspSaveMspUserCompanies>>, {data: number[];params?: PostMspSaveMspUserCompaniesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMspSaveMspUserCompanies(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMspSaveMspUserCompanies>>, TError, {data: number[];params?: PostMspSaveMspUserCompaniesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspGetMspUserCompanies = (
    params?: GetMspGetMspUserCompaniesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number[]>(
      {url: `/Msp/GetMspUserCompanies`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspUserCompaniesQueryKey = (params?: GetMspGetMspUserCompaniesParams,) => [`/Msp/GetMspUserCompanies`, ...(params ? [params]: [])];

    
export type GetMspGetMspUserCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspUserCompanies>>>
export type GetMspGetMspUserCompaniesQueryError = unknown

export const useGetMspGetMspUserCompanies = <TData = Awaited<ReturnType<typeof getMspGetMspUserCompanies>>, TError = unknown>(
 params?: GetMspGetMspUserCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspUserCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspUserCompaniesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspUserCompanies>>> = ({ signal }) => getMspGetMspUserCompanies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspUserCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspGetMspsForCompany = (
    params?: GetMspGetMspsForCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/Msp/GetMspsForCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspGetMspsForCompanyQueryKey = (params?: GetMspGetMspsForCompanyParams,) => [`/Msp/GetMspsForCompany`, ...(params ? [params]: [])];

    
export type GetMspGetMspsForCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getMspGetMspsForCompany>>>
export type GetMspGetMspsForCompanyQueryError = unknown

export const useGetMspGetMspsForCompany = <TData = Awaited<ReturnType<typeof getMspGetMspsForCompany>>, TError = unknown>(
 params?: GetMspGetMspsForCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspGetMspsForCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspGetMspsForCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspGetMspsForCompany>>> = ({ signal }) => getMspGetMspsForCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspGetMspsForCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspCompensationGetMspCompensation = (
    params?: GetMspCompensationGetMspCompensationParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCompensation>(
      {url: `/MspCompensation/GetMspCompensation`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspCompensationGetMspCompensationQueryKey = (params?: GetMspCompensationGetMspCompensationParams,) => [`/MspCompensation/GetMspCompensation`, ...(params ? [params]: [])];

    
export type GetMspCompensationGetMspCompensationQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCompensationGetMspCompensation>>>
export type GetMspCompensationGetMspCompensationQueryError = unknown

export const useGetMspCompensationGetMspCompensation = <TData = Awaited<ReturnType<typeof getMspCompensationGetMspCompensation>>, TError = unknown>(
 params?: GetMspCompensationGetMspCompensationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCompensationGetMspCompensation>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspCompensationGetMspCompensationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCompensationGetMspCompensation>>> = ({ signal }) => getMspCompensationGetMspCompensation(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspCompensationGetMspCompensation>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMspCompensationSaveMspCompensation = (
    mspCompensation: MspCompensation,
 options?: SecondParameter<typeof request>,
) => {
      return request<MspCompensation>(
      {url: `/MspCompensation/SaveMspCompensation`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mspCompensation
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostMspCompensationSaveMspCompensationMutationResult = NonNullable<Awaited<ReturnType<typeof postMspCompensationSaveMspCompensation>>>
    export type PostMspCompensationSaveMspCompensationMutationBody = MspCompensation
    export type PostMspCompensationSaveMspCompensationMutationError = unknown

    export const usePostMspCompensationSaveMspCompensation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMspCompensationSaveMspCompensation>>, TError,{data: MspCompensation}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMspCompensationSaveMspCompensation>>, {data: MspCompensation}> = (props) => {
          const {data} = props ?? {};

          return  postMspCompensationSaveMspCompensation(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMspCompensationSaveMspCompensation>>, TError, {data: MspCompensation}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspCompensationCategoryGetMspCompensationCategory = (
    params?: GetMspCompensationCategoryGetMspCompensationCategoryParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCompensationCategory>(
      {url: `/MspCompensationCategory/GetMspCompensationCategory`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspCompensationCategoryGetMspCompensationCategoryQueryKey = (params?: GetMspCompensationCategoryGetMspCompensationCategoryParams,) => [`/MspCompensationCategory/GetMspCompensationCategory`, ...(params ? [params]: [])];

    
export type GetMspCompensationCategoryGetMspCompensationCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategory>>>
export type GetMspCompensationCategoryGetMspCompensationCategoryQueryError = unknown

export const useGetMspCompensationCategoryGetMspCompensationCategory = <TData = Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategory>>, TError = unknown>(
 params?: GetMspCompensationCategoryGetMspCompensationCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategory>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspCompensationCategoryGetMspCompensationCategoryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategory>>> = ({ signal }) => getMspCompensationCategoryGetMspCompensationCategory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategory>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspCompensationCategoryGetMspCompensationCategories = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCompensationCategory[]>(
      {url: `/MspCompensationCategory/GetMspCompensationCategories`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspCompensationCategoryGetMspCompensationCategoriesQueryKey = () => [`/MspCompensationCategory/GetMspCompensationCategories`];

    
export type GetMspCompensationCategoryGetMspCompensationCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategories>>>
export type GetMspCompensationCategoryGetMspCompensationCategoriesQueryError = unknown

export const useGetMspCompensationCategoryGetMspCompensationCategories = <TData = Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategories>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategories>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspCompensationCategoryGetMspCompensationCategoriesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategories>>> = ({ signal }) => getMspCompensationCategoryGetMspCompensationCategories(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspCompensationCategoryGetMspCompensationCategories>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMspCompensationCategorySaveMspCompensationCategory = (
    mspCompensationCategory: MspCompensationCategory,
 options?: SecondParameter<typeof request>,
) => {
      return request<MspCompensationCategory>(
      {url: `/MspCompensationCategory/SaveMspCompensationCategory`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mspCompensationCategory
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostMspCompensationCategorySaveMspCompensationCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof postMspCompensationCategorySaveMspCompensationCategory>>>
    export type PostMspCompensationCategorySaveMspCompensationCategoryMutationBody = MspCompensationCategory
    export type PostMspCompensationCategorySaveMspCompensationCategoryMutationError = unknown

    export const usePostMspCompensationCategorySaveMspCompensationCategory = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMspCompensationCategorySaveMspCompensationCategory>>, TError,{data: MspCompensationCategory}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMspCompensationCategorySaveMspCompensationCategory>>, {data: MspCompensationCategory}> = (props) => {
          const {data} = props ?? {};

          return  postMspCompensationCategorySaveMspCompensationCategory(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMspCompensationCategorySaveMspCompensationCategory>>, TError, {data: MspCompensationCategory}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspCompensationLineItemGetMspCompensationLineItem = (
    params?: GetMspCompensationLineItemGetMspCompensationLineItemParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCompensationLineItem>(
      {url: `/MspCompensationLineItem/GetMspCompensationLineItem`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspCompensationLineItemGetMspCompensationLineItemQueryKey = (params?: GetMspCompensationLineItemGetMspCompensationLineItemParams,) => [`/MspCompensationLineItem/GetMspCompensationLineItem`, ...(params ? [params]: [])];

    
export type GetMspCompensationLineItemGetMspCompensationLineItemQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItem>>>
export type GetMspCompensationLineItemGetMspCompensationLineItemQueryError = unknown

export const useGetMspCompensationLineItemGetMspCompensationLineItem = <TData = Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItem>>, TError = unknown>(
 params?: GetMspCompensationLineItemGetMspCompensationLineItemParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItem>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspCompensationLineItemGetMspCompensationLineItemQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItem>>> = ({ signal }) => getMspCompensationLineItemGetMspCompensationLineItem(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItem>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspCompensationLineItemGetMspCompensationLineItemsByCategory = (
    params?: GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspCompensationLineItem[]>(
      {url: `/MspCompensationLineItem/GetMspCompensationLineItemsByCategory`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspCompensationLineItemGetMspCompensationLineItemsByCategoryQueryKey = (params?: GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryParams,) => [`/MspCompensationLineItem/GetMspCompensationLineItemsByCategory`, ...(params ? [params]: [])];

    
export type GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItemsByCategory>>>
export type GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryQueryError = unknown

export const useGetMspCompensationLineItemGetMspCompensationLineItemsByCategory = <TData = Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItemsByCategory>>, TError = unknown>(
 params?: GetMspCompensationLineItemGetMspCompensationLineItemsByCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItemsByCategory>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspCompensationLineItemGetMspCompensationLineItemsByCategoryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItemsByCategory>>> = ({ signal }) => getMspCompensationLineItemGetMspCompensationLineItemsByCategory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspCompensationLineItemGetMspCompensationLineItemsByCategory>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMspCompensationLineItemSaveMspCompensationLineItem = (
    mspCompensationLineItem: MspCompensationLineItem,
 options?: SecondParameter<typeof request>,
) => {
      return request<MspCompensationLineItem>(
      {url: `/MspCompensationLineItem/SaveMspCompensationLineItem`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mspCompensationLineItem
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostMspCompensationLineItemSaveMspCompensationLineItemMutationResult = NonNullable<Awaited<ReturnType<typeof postMspCompensationLineItemSaveMspCompensationLineItem>>>
    export type PostMspCompensationLineItemSaveMspCompensationLineItemMutationBody = MspCompensationLineItem
    export type PostMspCompensationLineItemSaveMspCompensationLineItemMutationError = unknown

    export const usePostMspCompensationLineItemSaveMspCompensationLineItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMspCompensationLineItemSaveMspCompensationLineItem>>, TError,{data: MspCompensationLineItem}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMspCompensationLineItemSaveMspCompensationLineItem>>, {data: MspCompensationLineItem}> = (props) => {
          const {data} = props ?? {};

          return  postMspCompensationLineItemSaveMspCompensationLineItem(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMspCompensationLineItemSaveMspCompensationLineItem>>, TError, {data: MspCompensationLineItem}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspConfigGetMspConfigById = (
    params?: GetMspConfigGetMspConfigByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspConfig>(
      {url: `/MspConfig/GetMspConfigById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspConfigGetMspConfigByIdQueryKey = (params?: GetMspConfigGetMspConfigByIdParams,) => [`/MspConfig/GetMspConfigById`, ...(params ? [params]: [])];

    
export type GetMspConfigGetMspConfigByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfigGetMspConfigById>>>
export type GetMspConfigGetMspConfigByIdQueryError = unknown

export const useGetMspConfigGetMspConfigById = <TData = Awaited<ReturnType<typeof getMspConfigGetMspConfigById>>, TError = unknown>(
 params?: GetMspConfigGetMspConfigByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfigGetMspConfigById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspConfigGetMspConfigByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfigGetMspConfigById>>> = ({ signal }) => getMspConfigGetMspConfigById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspConfigGetMspConfigById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspConfigGetMspConfigByCompany = (
    params?: GetMspConfigGetMspConfigByCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspConfig>(
      {url: `/MspConfig/GetMspConfigByCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspConfigGetMspConfigByCompanyQueryKey = (params?: GetMspConfigGetMspConfigByCompanyParams,) => [`/MspConfig/GetMspConfigByCompany`, ...(params ? [params]: [])];

    
export type GetMspConfigGetMspConfigByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfigGetMspConfigByCompany>>>
export type GetMspConfigGetMspConfigByCompanyQueryError = unknown

export const useGetMspConfigGetMspConfigByCompany = <TData = Awaited<ReturnType<typeof getMspConfigGetMspConfigByCompany>>, TError = unknown>(
 params?: GetMspConfigGetMspConfigByCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfigGetMspConfigByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspConfigGetMspConfigByCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfigGetMspConfigByCompany>>> = ({ signal }) => getMspConfigGetMspConfigByCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspConfigGetMspConfigByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspConfigGetMspConfigByHost = (
    params?: GetMspConfigGetMspConfigByHostParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspConfig>(
      {url: `/MspConfig/GetMspConfigByHost`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspConfigGetMspConfigByHostQueryKey = (params?: GetMspConfigGetMspConfigByHostParams,) => [`/MspConfig/GetMspConfigByHost`, ...(params ? [params]: [])];

    
export type GetMspConfigGetMspConfigByHostQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfigGetMspConfigByHost>>>
export type GetMspConfigGetMspConfigByHostQueryError = unknown

export const useGetMspConfigGetMspConfigByHost = <TData = Awaited<ReturnType<typeof getMspConfigGetMspConfigByHost>>, TError = unknown>(
 params?: GetMspConfigGetMspConfigByHostParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfigGetMspConfigByHost>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspConfigGetMspConfigByHostQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfigGetMspConfigByHost>>> = ({ signal }) => getMspConfigGetMspConfigByHost(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspConfigGetMspConfigByHost>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMspConfigGetMspConfigForCompany = (
    params?: GetMspConfigGetMspConfigForCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspConfig>(
      {url: `/MspConfig/GetMspConfigForCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspConfigGetMspConfigForCompanyQueryKey = (params?: GetMspConfigGetMspConfigForCompanyParams,) => [`/MspConfig/GetMspConfigForCompany`, ...(params ? [params]: [])];

    
export type GetMspConfigGetMspConfigForCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfigGetMspConfigForCompany>>>
export type GetMspConfigGetMspConfigForCompanyQueryError = unknown

export const useGetMspConfigGetMspConfigForCompany = <TData = Awaited<ReturnType<typeof getMspConfigGetMspConfigForCompany>>, TError = unknown>(
 params?: GetMspConfigGetMspConfigForCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfigGetMspConfigForCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspConfigGetMspConfigForCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfigGetMspConfigForCompany>>> = ({ signal }) => getMspConfigGetMspConfigForCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspConfigGetMspConfigForCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMspConfigSaveMspConfig = (
    mspConfig: MspConfig,
 options?: SecondParameter<typeof request>,
) => {
      return request<MspConfig>(
      {url: `/MspConfig/SaveMspConfig`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mspConfig
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostMspConfigSaveMspConfigMutationResult = NonNullable<Awaited<ReturnType<typeof postMspConfigSaveMspConfig>>>
    export type PostMspConfigSaveMspConfigMutationBody = MspConfig
    export type PostMspConfigSaveMspConfigMutationError = unknown

    export const usePostMspConfigSaveMspConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMspConfigSaveMspConfig>>, TError,{data: MspConfig}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMspConfigSaveMspConfig>>, {data: MspConfig}> = (props) => {
          const {data} = props ?? {};

          return  postMspConfigSaveMspConfig(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMspConfigSaveMspConfig>>, TError, {data: MspConfig}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMspConfigGetMspConfigByMspCompanyId = (
    params?: GetMspConfigGetMspConfigByMspCompanyIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MspConfig>(
      {url: `/MspConfig/GetMspConfigByMspCompanyId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetMspConfigGetMspConfigByMspCompanyIdQueryKey = (params?: GetMspConfigGetMspConfigByMspCompanyIdParams,) => [`/MspConfig/GetMspConfigByMspCompanyId`, ...(params ? [params]: [])];

    
export type GetMspConfigGetMspConfigByMspCompanyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfigGetMspConfigByMspCompanyId>>>
export type GetMspConfigGetMspConfigByMspCompanyIdQueryError = unknown

export const useGetMspConfigGetMspConfigByMspCompanyId = <TData = Awaited<ReturnType<typeof getMspConfigGetMspConfigByMspCompanyId>>, TError = unknown>(
 params?: GetMspConfigGetMspConfigByMspCompanyIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfigGetMspConfigByMspCompanyId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMspConfigGetMspConfigByMspCompanyIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfigGetMspConfigByMspCompanyId>>> = ({ signal }) => getMspConfigGetMspConfigByMspCompanyId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMspConfigGetMspConfigByMspCompanyId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOrganizationGetOrganizations = (
    params?: GetOrganizationGetOrganizationsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Organization[]>(
      {url: `/Organization/GetOrganizations`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetOrganizationGetOrganizationsQueryKey = (params?: GetOrganizationGetOrganizationsParams,) => [`/Organization/GetOrganizations`, ...(params ? [params]: [])];

    
export type GetOrganizationGetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationGetOrganizations>>>
export type GetOrganizationGetOrganizationsQueryError = unknown

export const useGetOrganizationGetOrganizations = <TData = Awaited<ReturnType<typeof getOrganizationGetOrganizations>>, TError = unknown>(
 params?: GetOrganizationGetOrganizationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationGetOrganizations>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationGetOrganizationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationGetOrganizations>>> = ({ signal }) => getOrganizationGetOrganizations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrganizationGetOrganizations>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOrganizationGetChildOrganizations = (
    params?: GetOrganizationGetChildOrganizationsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Organization[]>(
      {url: `/Organization/GetChildOrganizations`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetOrganizationGetChildOrganizationsQueryKey = (params?: GetOrganizationGetChildOrganizationsParams,) => [`/Organization/GetChildOrganizations`, ...(params ? [params]: [])];

    
export type GetOrganizationGetChildOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationGetChildOrganizations>>>
export type GetOrganizationGetChildOrganizationsQueryError = unknown

export const useGetOrganizationGetChildOrganizations = <TData = Awaited<ReturnType<typeof getOrganizationGetChildOrganizations>>, TError = unknown>(
 params?: GetOrganizationGetChildOrganizationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationGetChildOrganizations>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationGetChildOrganizationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationGetChildOrganizations>>> = ({ signal }) => getOrganizationGetChildOrganizations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrganizationGetChildOrganizations>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOrganizationGetOrganization = (
    params?: GetOrganizationGetOrganizationParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Organization>(
      {url: `/Organization/GetOrganization`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetOrganizationGetOrganizationQueryKey = (params?: GetOrganizationGetOrganizationParams,) => [`/Organization/GetOrganization`, ...(params ? [params]: [])];

    
export type GetOrganizationGetOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationGetOrganization>>>
export type GetOrganizationGetOrganizationQueryError = unknown

export const useGetOrganizationGetOrganization = <TData = Awaited<ReturnType<typeof getOrganizationGetOrganization>>, TError = unknown>(
 params?: GetOrganizationGetOrganizationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationGetOrganization>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationGetOrganizationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationGetOrganization>>> = ({ signal }) => getOrganizationGetOrganization(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrganizationGetOrganization>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOrganizationGetOrganizationByAWSId = (
    params?: GetOrganizationGetOrganizationByAWSIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Organization>(
      {url: `/Organization/GetOrganizationByAWSId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetOrganizationGetOrganizationByAWSIdQueryKey = (params?: GetOrganizationGetOrganizationByAWSIdParams,) => [`/Organization/GetOrganizationByAWSId`, ...(params ? [params]: [])];

    
export type GetOrganizationGetOrganizationByAWSIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationGetOrganizationByAWSId>>>
export type GetOrganizationGetOrganizationByAWSIdQueryError = unknown

export const useGetOrganizationGetOrganizationByAWSId = <TData = Awaited<ReturnType<typeof getOrganizationGetOrganizationByAWSId>>, TError = unknown>(
 params?: GetOrganizationGetOrganizationByAWSIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationGetOrganizationByAWSId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationGetOrganizationByAWSIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationGetOrganizationByAWSId>>> = ({ signal }) => getOrganizationGetOrganizationByAWSId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrganizationGetOrganizationByAWSId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postOrganizationUpdateOrganization = (
    params?: PostOrganizationUpdateOrganizationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Organization/UpdateOrganization`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostOrganizationUpdateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof postOrganizationUpdateOrganization>>>
    
    export type PostOrganizationUpdateOrganizationMutationError = unknown

    export const usePostOrganizationUpdateOrganization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrganizationUpdateOrganization>>, TError,{params?: PostOrganizationUpdateOrganizationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrganizationUpdateOrganization>>, {params?: PostOrganizationUpdateOrganizationParams}> = (props) => {
          const {params} = props ?? {};

          return  postOrganizationUpdateOrganization(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postOrganizationUpdateOrganization>>, TError, {params?: PostOrganizationUpdateOrganizationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteOrganizationDeleteOrganization = (
    params?: DeleteOrganizationDeleteOrganizationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Organization/DeleteOrganization`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteOrganizationDeleteOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganizationDeleteOrganization>>>
    
    export type DeleteOrganizationDeleteOrganizationMutationError = unknown

    export const useDeleteOrganizationDeleteOrganization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationDeleteOrganization>>, TError,{params?: DeleteOrganizationDeleteOrganizationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganizationDeleteOrganization>>, {params?: DeleteOrganizationDeleteOrganizationParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteOrganizationDeleteOrganization(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteOrganizationDeleteOrganization>>, TError, {params?: DeleteOrganizationDeleteOrganizationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getPaymentGetPaymentSources = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PaymentSource[]>(
      {url: `/Payment/GetPaymentSources`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetPaymentSourcesQueryKey = () => [`/Payment/GetPaymentSources`];

    
export type GetPaymentGetPaymentSourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetPaymentSources>>>
export type GetPaymentGetPaymentSourcesQueryError = unknown

export const useGetPaymentGetPaymentSources = <TData = Awaited<ReturnType<typeof getPaymentGetPaymentSources>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetPaymentSources>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetPaymentSourcesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetPaymentSources>>> = ({ signal }) => getPaymentGetPaymentSources(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetPaymentSources>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getPaymentGetHostedPage = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<HostedPage>(
      {url: `/Payment/GetHostedPage`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetHostedPageQueryKey = () => [`/Payment/GetHostedPage`];

    
export type GetPaymentGetHostedPageQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetHostedPage>>>
export type GetPaymentGetHostedPageQueryError = unknown

export const useGetPaymentGetHostedPage = <TData = Awaited<ReturnType<typeof getPaymentGetHostedPage>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetHostedPage>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetHostedPageQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetHostedPage>>> = ({ signal }) => getPaymentGetHostedPage(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetHostedPage>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getPaymentGetPrimaryPayment = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PaymentSource>(
      {url: `/Payment/GetPrimaryPayment`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetPrimaryPaymentQueryKey = () => [`/Payment/GetPrimaryPayment`];

    
export type GetPaymentGetPrimaryPaymentQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetPrimaryPayment>>>
export type GetPaymentGetPrimaryPaymentQueryError = unknown

export const useGetPaymentGetPrimaryPayment = <TData = Awaited<ReturnType<typeof getPaymentGetPrimaryPayment>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetPrimaryPayment>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetPrimaryPaymentQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetPrimaryPayment>>> = ({ signal }) => getPaymentGetPrimaryPayment(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetPrimaryPayment>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postPaymentAddPaymentSource = (
    card: Card,
    params?: PostPaymentAddPaymentSourceParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Payment/AddPaymentSource`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: card,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentAddPaymentSourceMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentAddPaymentSource>>>
    export type PostPaymentAddPaymentSourceMutationBody = Card
    export type PostPaymentAddPaymentSourceMutationError = unknown

    export const usePostPaymentAddPaymentSource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentAddPaymentSource>>, TError,{data: Card;params?: PostPaymentAddPaymentSourceParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentAddPaymentSource>>, {data: Card;params?: PostPaymentAddPaymentSourceParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postPaymentAddPaymentSource(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentAddPaymentSource>>, TError, {data: Card;params?: PostPaymentAddPaymentSourceParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postPaymentDeletePaymentSource = (
    params?: PostPaymentDeletePaymentSourceParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Payment/DeletePaymentSource`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentDeletePaymentSourceMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentDeletePaymentSource>>>
    
    export type PostPaymentDeletePaymentSourceMutationError = unknown

    export const usePostPaymentDeletePaymentSource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentDeletePaymentSource>>, TError,{params?: PostPaymentDeletePaymentSourceParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentDeletePaymentSource>>, {params?: PostPaymentDeletePaymentSourceParams}> = (props) => {
          const {params} = props ?? {};

          return  postPaymentDeletePaymentSource(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentDeletePaymentSource>>, TError, {params?: PostPaymentDeletePaymentSourceParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postPaymentUpdatePrimaryPayment = (
    params?: PostPaymentUpdatePrimaryPaymentParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Payment/UpdatePrimaryPayment`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentUpdatePrimaryPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentUpdatePrimaryPayment>>>
    
    export type PostPaymentUpdatePrimaryPaymentMutationError = unknown

    export const usePostPaymentUpdatePrimaryPayment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentUpdatePrimaryPayment>>, TError,{params?: PostPaymentUpdatePrimaryPaymentParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentUpdatePrimaryPayment>>, {params?: PostPaymentUpdatePrimaryPaymentParams}> = (props) => {
          const {params} = props ?? {};

          return  postPaymentUpdatePrimaryPayment(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentUpdatePrimaryPayment>>, TError, {params?: PostPaymentUpdatePrimaryPaymentParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getPaymentGetItemPrice = (
    params?: GetPaymentGetItemPriceParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ItemPrice>(
      {url: `/Payment/GetItemPrice`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetItemPriceQueryKey = (params?: GetPaymentGetItemPriceParams,) => [`/Payment/GetItemPrice`, ...(params ? [params]: [])];

    
export type GetPaymentGetItemPriceQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetItemPrice>>>
export type GetPaymentGetItemPriceQueryError = unknown

export const useGetPaymentGetItemPrice = <TData = Awaited<ReturnType<typeof getPaymentGetItemPrice>>, TError = unknown>(
 params?: GetPaymentGetItemPriceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetItemPrice>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetItemPriceQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetItemPrice>>> = ({ signal }) => getPaymentGetItemPrice(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetItemPrice>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postPaymentBuyCredits = (
    params?: PostPaymentBuyCreditsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<BuyCreditsResult>(
      {url: `/Payment/BuyCredits`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentBuyCreditsMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentBuyCredits>>>
    
    export type PostPaymentBuyCreditsMutationError = unknown

    export const usePostPaymentBuyCredits = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentBuyCredits>>, TError,{params?: PostPaymentBuyCreditsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentBuyCredits>>, {params?: PostPaymentBuyCreditsParams}> = (props) => {
          const {params} = props ?? {};

          return  postPaymentBuyCredits(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentBuyCredits>>, TError, {params?: PostPaymentBuyCreditsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getPaymentGetCreditBuyingList = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ItemPrice[]>(
      {url: `/Payment/GetCreditBuyingList`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetCreditBuyingListQueryKey = () => [`/Payment/GetCreditBuyingList`];

    
export type GetPaymentGetCreditBuyingListQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetCreditBuyingList>>>
export type GetPaymentGetCreditBuyingListQueryError = unknown

export const useGetPaymentGetCreditBuyingList = <TData = Awaited<ReturnType<typeof getPaymentGetCreditBuyingList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetCreditBuyingList>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetCreditBuyingListQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetCreditBuyingList>>> = ({ signal }) => getPaymentGetCreditBuyingList(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetCreditBuyingList>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postPaymentUpdateBillingInfo = (
    chargeBeeBillingInfo: ChargeBeeBillingInfo,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Payment/UpdateBillingInfo`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: chargeBeeBillingInfo
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentUpdateBillingInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentUpdateBillingInfo>>>
    export type PostPaymentUpdateBillingInfoMutationBody = ChargeBeeBillingInfo
    export type PostPaymentUpdateBillingInfoMutationError = unknown

    export const usePostPaymentUpdateBillingInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentUpdateBillingInfo>>, TError,{data: ChargeBeeBillingInfo}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentUpdateBillingInfo>>, {data: ChargeBeeBillingInfo}> = (props) => {
          const {data} = props ?? {};

          return  postPaymentUpdateBillingInfo(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentUpdateBillingInfo>>, TError, {data: ChargeBeeBillingInfo}, TContext>(mutationFn, mutationOptions)
    }
    
export const getPaymentGetChargeBeeCustomer = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ChargeBeeBillingInfo>(
      {url: `/Payment/GetChargeBeeCustomer`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetChargeBeeCustomerQueryKey = () => [`/Payment/GetChargeBeeCustomer`];

    
export type GetPaymentGetChargeBeeCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetChargeBeeCustomer>>>
export type GetPaymentGetChargeBeeCustomerQueryError = unknown

export const useGetPaymentGetChargeBeeCustomer = <TData = Awaited<ReturnType<typeof getPaymentGetChargeBeeCustomer>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetChargeBeeCustomer>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetChargeBeeCustomerQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetChargeBeeCustomer>>> = ({ signal }) => getPaymentGetChargeBeeCustomer(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetChargeBeeCustomer>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getPaymentGetAwsPublicOfferUrl = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsMarketplaceOffer>(
      {url: `/Payment/GetAwsPublicOfferUrl`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetAwsPublicOfferUrlQueryKey = () => [`/Payment/GetAwsPublicOfferUrl`];

    
export type GetPaymentGetAwsPublicOfferUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetAwsPublicOfferUrl>>>
export type GetPaymentGetAwsPublicOfferUrlQueryError = unknown

export const useGetPaymentGetAwsPublicOfferUrl = <TData = Awaited<ReturnType<typeof getPaymentGetAwsPublicOfferUrl>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetAwsPublicOfferUrl>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetAwsPublicOfferUrlQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetAwsPublicOfferUrl>>> = ({ signal }) => getPaymentGetAwsPublicOfferUrl(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetAwsPublicOfferUrl>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getPaymentGetAzurePublicOfferUrl = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AzureMarketplaceOffer>(
      {url: `/Payment/GetAzurePublicOfferUrl`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetAzurePublicOfferUrlQueryKey = () => [`/Payment/GetAzurePublicOfferUrl`];

    
export type GetPaymentGetAzurePublicOfferUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetAzurePublicOfferUrl>>>
export type GetPaymentGetAzurePublicOfferUrlQueryError = unknown

export const useGetPaymentGetAzurePublicOfferUrl = <TData = Awaited<ReturnType<typeof getPaymentGetAzurePublicOfferUrl>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetAzurePublicOfferUrl>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetAzurePublicOfferUrlQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetAzurePublicOfferUrl>>> = ({ signal }) => getPaymentGetAzurePublicOfferUrl(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetAzurePublicOfferUrl>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const putPaymentCreatePendingPaymentMethod = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<PendingPaymentMethodSecret>(
      {url: `/Payment/CreatePendingPaymentMethod`, method: 'put'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PutPaymentCreatePendingPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof putPaymentCreatePendingPaymentMethod>>>
    
    export type PutPaymentCreatePendingPaymentMethodMutationError = unknown

    export const usePutPaymentCreatePendingPaymentMethod = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putPaymentCreatePendingPaymentMethod>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putPaymentCreatePendingPaymentMethod>>, TVariables> = () => {
          ;

          return  putPaymentCreatePendingPaymentMethod(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putPaymentCreatePendingPaymentMethod>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getPaymentGetPendingPaymentMethod = (
    params?: GetPaymentGetPendingPaymentMethodParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PendingPaymentMethodSecret>(
      {url: `/Payment/GetPendingPaymentMethod`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetPaymentGetPendingPaymentMethodQueryKey = (params?: GetPaymentGetPendingPaymentMethodParams,) => [`/Payment/GetPendingPaymentMethod`, ...(params ? [params]: [])];

    
export type GetPaymentGetPendingPaymentMethodQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentGetPendingPaymentMethod>>>
export type GetPaymentGetPendingPaymentMethodQueryError = unknown

export const useGetPaymentGetPendingPaymentMethod = <TData = Awaited<ReturnType<typeof getPaymentGetPendingPaymentMethod>>, TError = unknown>(
 params?: GetPaymentGetPendingPaymentMethodParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentGetPendingPaymentMethod>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentGetPendingPaymentMethodQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentGetPendingPaymentMethod>>> = ({ signal }) => getPaymentGetPendingPaymentMethod(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentGetPendingPaymentMethod>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postPaymentUsePendingPaymentMethod = (
    params?: PostPaymentUsePendingPaymentMethodParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<PendingPaymentMethodSecret>(
      {url: `/Payment/UsePendingPaymentMethod`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostPaymentUsePendingPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof postPaymentUsePendingPaymentMethod>>>
    
    export type PostPaymentUsePendingPaymentMethodMutationError = unknown

    export const usePostPaymentUsePendingPaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPaymentUsePendingPaymentMethod>>, TError,{params?: PostPaymentUsePendingPaymentMethodParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPaymentUsePendingPaymentMethod>>, {params?: PostPaymentUsePendingPaymentMethodParams}> = (props) => {
          const {params} = props ?? {};

          return  postPaymentUsePendingPaymentMethod(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPaymentUsePendingPaymentMethod>>, TError, {params?: PostPaymentUsePendingPaymentMethodParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacSaveUserRoles = (
    bulkUserRoleChange: BulkUserRoleChange,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/SaveUserRoles`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkUserRoleChange
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacSaveUserRolesMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacSaveUserRoles>>>
    export type PostRbacSaveUserRolesMutationBody = BulkUserRoleChange
    export type PostRbacSaveUserRolesMutationError = unknown

    export const usePostRbacSaveUserRoles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacSaveUserRoles>>, TError,{data: BulkUserRoleChange}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacSaveUserRoles>>, {data: BulkUserRoleChange}> = (props) => {
          const {data} = props ?? {};

          return  postRbacSaveUserRoles(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacSaveUserRoles>>, TError, {data: BulkUserRoleChange}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacSaveRolePermissions = (
    rolePermission: RolePermission[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/SaveRolePermissions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: rolePermission
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacSaveRolePermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacSaveRolePermissions>>>
    export type PostRbacSaveRolePermissionsMutationBody = RolePermission[]
    export type PostRbacSaveRolePermissionsMutationError = unknown

    export const usePostRbacSaveRolePermissions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacSaveRolePermissions>>, TError,{data: RolePermission[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacSaveRolePermissions>>, {data: RolePermission[]}> = (props) => {
          const {data} = props ?? {};

          return  postRbacSaveRolePermissions(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacSaveRolePermissions>>, TError, {data: RolePermission[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacSaveRole = (
    role: Role,
 options?: SecondParameter<typeof request>,
) => {
      return request<Role>(
      {url: `/Rbac/SaveRole`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: role
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacSaveRoleMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacSaveRole>>>
    export type PostRbacSaveRoleMutationBody = Role
    export type PostRbacSaveRoleMutationError = unknown

    export const usePostRbacSaveRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacSaveRole>>, TError,{data: Role}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacSaveRole>>, {data: Role}> = (props) => {
          const {data} = props ?? {};

          return  postRbacSaveRole(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacSaveRole>>, TError, {data: Role}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteRbacDeleteRole = (
    params?: DeleteRbacDeleteRoleParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/DeleteRole`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteRbacDeleteRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRbacDeleteRole>>>
    
    export type DeleteRbacDeleteRoleMutationError = unknown

    export const useDeleteRbacDeleteRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRbacDeleteRole>>, TError,{params?: DeleteRbacDeleteRoleParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRbacDeleteRole>>, {params?: DeleteRbacDeleteRoleParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteRbacDeleteRole(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteRbacDeleteRole>>, TError, {params?: DeleteRbacDeleteRoleParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getRbacGetUserRolesByUserId = (
    params?: GetRbacGetUserRolesByUserIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyUserRole[]>(
      {url: `/Rbac/GetUserRolesByUserId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetUserRolesByUserIdQueryKey = (params?: GetRbacGetUserRolesByUserIdParams,) => [`/Rbac/GetUserRolesByUserId`, ...(params ? [params]: [])];

    
export type GetRbacGetUserRolesByUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetUserRolesByUserId>>>
export type GetRbacGetUserRolesByUserIdQueryError = unknown

export const useGetRbacGetUserRolesByUserId = <TData = Awaited<ReturnType<typeof getRbacGetUserRolesByUserId>>, TError = unknown>(
 params?: GetRbacGetUserRolesByUserIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetUserRolesByUserId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetUserRolesByUserIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetUserRolesByUserId>>> = ({ signal }) => getRbacGetUserRolesByUserId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetUserRolesByUserId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetRolesByUserId = (
    params?: GetRbacGetRolesByUserIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Role[]>(
      {url: `/Rbac/GetRolesByUserId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetRolesByUserIdQueryKey = (params?: GetRbacGetRolesByUserIdParams,) => [`/Rbac/GetRolesByUserId`, ...(params ? [params]: [])];

    
export type GetRbacGetRolesByUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetRolesByUserId>>>
export type GetRbacGetRolesByUserIdQueryError = unknown

export const useGetRbacGetRolesByUserId = <TData = Awaited<ReturnType<typeof getRbacGetRolesByUserId>>, TError = unknown>(
 params?: GetRbacGetRolesByUserIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetRolesByUserId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetRolesByUserIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetRolesByUserId>>> = ({ signal }) => getRbacGetRolesByUserId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetRolesByUserId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetRolePermissions = (
    params?: GetRbacGetRolePermissionsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<RolePermission[]>(
      {url: `/Rbac/GetRolePermissions`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetRolePermissionsQueryKey = (params?: GetRbacGetRolePermissionsParams,) => [`/Rbac/GetRolePermissions`, ...(params ? [params]: [])];

    
export type GetRbacGetRolePermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetRolePermissions>>>
export type GetRbacGetRolePermissionsQueryError = unknown

export const useGetRbacGetRolePermissions = <TData = Awaited<ReturnType<typeof getRbacGetRolePermissions>>, TError = unknown>(
 params?: GetRbacGetRolePermissionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetRolePermissions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetRolePermissionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetRolePermissions>>> = ({ signal }) => getRbacGetRolePermissions(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetRolePermissions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetUserAccessRules = (
    params?: GetRbacGetUserAccessRulesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<IQueryExpr[]>(
      {url: `/Rbac/GetUserAccessRules`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetUserAccessRulesQueryKey = (params?: GetRbacGetUserAccessRulesParams,) => [`/Rbac/GetUserAccessRules`, ...(params ? [params]: [])];

    
export type GetRbacGetUserAccessRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetUserAccessRules>>>
export type GetRbacGetUserAccessRulesQueryError = unknown

export const useGetRbacGetUserAccessRules = <TData = Awaited<ReturnType<typeof getRbacGetUserAccessRules>>, TError = unknown>(
 params?: GetRbacGetUserAccessRulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetUserAccessRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetUserAccessRulesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetUserAccessRules>>> = ({ signal }) => getRbacGetUserAccessRules(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetUserAccessRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetCompanyRolePermissions = (
    params?: GetRbacGetCompanyRolePermissionsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<RolePermission[]>(
      {url: `/Rbac/GetCompanyRolePermissions`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetCompanyRolePermissionsQueryKey = (params?: GetRbacGetCompanyRolePermissionsParams,) => [`/Rbac/GetCompanyRolePermissions`, ...(params ? [params]: [])];

    
export type GetRbacGetCompanyRolePermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetCompanyRolePermissions>>>
export type GetRbacGetCompanyRolePermissionsQueryError = unknown

export const useGetRbacGetCompanyRolePermissions = <TData = Awaited<ReturnType<typeof getRbacGetCompanyRolePermissions>>, TError = unknown>(
 params?: GetRbacGetCompanyRolePermissionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetCompanyRolePermissions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetCompanyRolePermissionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetCompanyRolePermissions>>> = ({ signal }) => getRbacGetCompanyRolePermissions(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetCompanyRolePermissions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postRbacGetRolePermissionsBulk = (
    postRbacGetRolePermissionsBulkBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<RolePermission[]>(
      {url: `/Rbac/GetRolePermissionsBulk`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postRbacGetRolePermissionsBulkBody
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacGetRolePermissionsBulkMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacGetRolePermissionsBulk>>>
    export type PostRbacGetRolePermissionsBulkMutationBody = number[]
    export type PostRbacGetRolePermissionsBulkMutationError = unknown

    export const usePostRbacGetRolePermissionsBulk = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacGetRolePermissionsBulk>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacGetRolePermissionsBulk>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  postRbacGetRolePermissionsBulk(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacGetRolePermissionsBulk>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const getRbacGetPermissions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Permission[]>(
      {url: `/Rbac/GetPermissions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetPermissionsQueryKey = () => [`/Rbac/GetPermissions`];

    
export type GetRbacGetPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetPermissions>>>
export type GetRbacGetPermissionsQueryError = unknown

export const useGetRbacGetPermissions = <TData = Awaited<ReturnType<typeof getRbacGetPermissions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetPermissions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetPermissionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetPermissions>>> = ({ signal }) => getRbacGetPermissions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetPermissions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetRoles = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Role[]>(
      {url: `/Rbac/GetRoles`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetRolesQueryKey = () => [`/Rbac/GetRoles`];

    
export type GetRbacGetRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetRoles>>>
export type GetRbacGetRolesQueryError = unknown

export const useGetRbacGetRoles = <TData = Awaited<ReturnType<typeof getRbacGetRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetRoles>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetRolesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetRoles>>> = ({ signal }) => getRbacGetRoles(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetRoles>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetRolesByCompany = (
    params?: GetRbacGetRolesByCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Role[]>(
      {url: `/Rbac/GetRolesByCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetRolesByCompanyQueryKey = (params?: GetRbacGetRolesByCompanyParams,) => [`/Rbac/GetRolesByCompany`, ...(params ? [params]: [])];

    
export type GetRbacGetRolesByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetRolesByCompany>>>
export type GetRbacGetRolesByCompanyQueryError = unknown

export const useGetRbacGetRolesByCompany = <TData = Awaited<ReturnType<typeof getRbacGetRolesByCompany>>, TError = unknown>(
 params?: GetRbacGetRolesByCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetRolesByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetRolesByCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetRolesByCompany>>> = ({ signal }) => getRbacGetRolesByCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetRolesByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetRole = (
    params?: GetRbacGetRoleParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Role>(
      {url: `/Rbac/GetRole`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetRoleQueryKey = (params?: GetRbacGetRoleParams,) => [`/Rbac/GetRole`, ...(params ? [params]: [])];

    
export type GetRbacGetRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetRole>>>
export type GetRbacGetRoleQueryError = unknown

export const useGetRbacGetRole = <TData = Awaited<ReturnType<typeof getRbacGetRole>>, TError = unknown>(
 params?: GetRbacGetRoleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetRole>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetRoleQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetRole>>> = ({ signal }) => getRbacGetRole(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetRole>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRbacGetCompanyRoles = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyRoleListItem[]>(
      {url: `/Rbac/GetCompanyRoles`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetRbacGetCompanyRolesQueryKey = () => [`/Rbac/GetCompanyRoles`];

    
export type GetRbacGetCompanyRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getRbacGetCompanyRoles>>>
export type GetRbacGetCompanyRolesQueryError = unknown

export const useGetRbacGetCompanyRoles = <TData = Awaited<ReturnType<typeof getRbacGetCompanyRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRbacGetCompanyRoles>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRbacGetCompanyRolesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRbacGetCompanyRoles>>> = ({ signal }) => getRbacGetCompanyRoles(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRbacGetCompanyRoles>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postRbacBulkAssignMspUsers = (
    bulkAssignMspUsers: BulkAssignMspUsers,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/BulkAssignMspUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkAssignMspUsers
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacBulkAssignMspUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacBulkAssignMspUsers>>>
    export type PostRbacBulkAssignMspUsersMutationBody = BulkAssignMspUsers
    export type PostRbacBulkAssignMspUsersMutationError = unknown

    export const usePostRbacBulkAssignMspUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacBulkAssignMspUsers>>, TError,{data: BulkAssignMspUsers}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacBulkAssignMspUsers>>, {data: BulkAssignMspUsers}> = (props) => {
          const {data} = props ?? {};

          return  postRbacBulkAssignMspUsers(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacBulkAssignMspUsers>>, TError, {data: BulkAssignMspUsers}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacBulkAssignSupportUsers = (
    bulkAssignSupportUsers: BulkAssignSupportUsers,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/BulkAssignSupportUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkAssignSupportUsers
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacBulkAssignSupportUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacBulkAssignSupportUsers>>>
    export type PostRbacBulkAssignSupportUsersMutationBody = BulkAssignSupportUsers
    export type PostRbacBulkAssignSupportUsersMutationError = unknown

    export const usePostRbacBulkAssignSupportUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacBulkAssignSupportUsers>>, TError,{data: BulkAssignSupportUsers}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacBulkAssignSupportUsers>>, {data: BulkAssignSupportUsers}> = (props) => {
          const {data} = props ?? {};

          return  postRbacBulkAssignSupportUsers(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacBulkAssignSupportUsers>>, TError, {data: BulkAssignSupportUsers}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacBulkRemoveMspUsers = (
    bulkRemoveMspUsers: BulkRemoveMspUsers,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/BulkRemoveMspUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkRemoveMspUsers
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacBulkRemoveMspUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacBulkRemoveMspUsers>>>
    export type PostRbacBulkRemoveMspUsersMutationBody = BulkRemoveMspUsers
    export type PostRbacBulkRemoveMspUsersMutationError = unknown

    export const usePostRbacBulkRemoveMspUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacBulkRemoveMspUsers>>, TError,{data: BulkRemoveMspUsers}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacBulkRemoveMspUsers>>, {data: BulkRemoveMspUsers}> = (props) => {
          const {data} = props ?? {};

          return  postRbacBulkRemoveMspUsers(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacBulkRemoveMspUsers>>, TError, {data: BulkRemoveMspUsers}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRbacBulkRemoveSupportUsers = (
    bulkAssignSupportUsers: BulkAssignSupportUsers,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Rbac/BulkRemoveSupportUsers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkAssignSupportUsers
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostRbacBulkRemoveSupportUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postRbacBulkRemoveSupportUsers>>>
    export type PostRbacBulkRemoveSupportUsersMutationBody = BulkAssignSupportUsers
    export type PostRbacBulkRemoveSupportUsersMutationError = unknown

    export const usePostRbacBulkRemoveSupportUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRbacBulkRemoveSupportUsers>>, TError,{data: BulkAssignSupportUsers}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRbacBulkRemoveSupportUsers>>, {data: BulkAssignSupportUsers}> = (props) => {
          const {data} = props ?? {};

          return  postRbacBulkRemoveSupportUsers(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRbacBulkRemoveSupportUsers>>, TError, {data: BulkAssignSupportUsers}, TContext>(mutationFn, mutationOptions)
    }
    
export const getServiceActionGetServiceActions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ServiceAction[]>(
      {url: `/ServiceAction/GetServiceActions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetServiceActionGetServiceActionsQueryKey = () => [`/ServiceAction/GetServiceActions`];

    
export type GetServiceActionGetServiceActionsQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceActionGetServiceActions>>>
export type GetServiceActionGetServiceActionsQueryError = unknown

export const useGetServiceActionGetServiceActions = <TData = Awaited<ReturnType<typeof getServiceActionGetServiceActions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceActionGetServiceActions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetServiceActionGetServiceActionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceActionGetServiceActions>>> = ({ signal }) => getServiceActionGetServiceActions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getServiceActionGetServiceActions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postServiceActionSaveServiceAction = (
    params?: PostServiceActionSaveServiceActionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ServiceAction>(
      {url: `/ServiceAction/SaveServiceAction`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostServiceActionSaveServiceActionMutationResult = NonNullable<Awaited<ReturnType<typeof postServiceActionSaveServiceAction>>>
    
    export type PostServiceActionSaveServiceActionMutationError = unknown

    export const usePostServiceActionSaveServiceAction = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postServiceActionSaveServiceAction>>, TError,{params?: PostServiceActionSaveServiceActionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postServiceActionSaveServiceAction>>, {params?: PostServiceActionSaveServiceActionParams}> = (props) => {
          const {params} = props ?? {};

          return  postServiceActionSaveServiceAction(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postServiceActionSaveServiceAction>>, TError, {params?: PostServiceActionSaveServiceActionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postStagedCompanyConvert = (
    params?: PostStagedCompanyConvertParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Company>(
      {url: `/StagedCompany/Convert`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostStagedCompanyConvertMutationResult = NonNullable<Awaited<ReturnType<typeof postStagedCompanyConvert>>>
    
    export type PostStagedCompanyConvertMutationError = unknown

    export const usePostStagedCompanyConvert = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStagedCompanyConvert>>, TError,{params?: PostStagedCompanyConvertParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStagedCompanyConvert>>, {params?: PostStagedCompanyConvertParams}> = (props) => {
          const {params} = props ?? {};

          return  postStagedCompanyConvert(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postStagedCompanyConvert>>, TError, {params?: PostStagedCompanyConvertParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getStagedCompanyGetConnectionStatus = (
    params?: GetStagedCompanyGetConnectionStatusParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<StagedCompanyConnectionStatus[]>(
      {url: `/StagedCompany/GetConnectionStatus`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetStagedCompanyGetConnectionStatusQueryKey = (params?: GetStagedCompanyGetConnectionStatusParams,) => [`/StagedCompany/GetConnectionStatus`, ...(params ? [params]: [])];

    
export type GetStagedCompanyGetConnectionStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getStagedCompanyGetConnectionStatus>>>
export type GetStagedCompanyGetConnectionStatusQueryError = unknown

export const useGetStagedCompanyGetConnectionStatus = <TData = Awaited<ReturnType<typeof getStagedCompanyGetConnectionStatus>>, TError = unknown>(
 params?: GetStagedCompanyGetConnectionStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStagedCompanyGetConnectionStatus>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStagedCompanyGetConnectionStatusQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStagedCompanyGetConnectionStatus>>> = ({ signal }) => getStagedCompanyGetConnectionStatus(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getStagedCompanyGetConnectionStatus>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getStagedCompanyGetById = (
    params?: GetStagedCompanyGetByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<StagedCompany>(
      {url: `/StagedCompany/GetById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetStagedCompanyGetByIdQueryKey = (params?: GetStagedCompanyGetByIdParams,) => [`/StagedCompany/GetById`, ...(params ? [params]: [])];

    
export type GetStagedCompanyGetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getStagedCompanyGetById>>>
export type GetStagedCompanyGetByIdQueryError = unknown

export const useGetStagedCompanyGetById = <TData = Awaited<ReturnType<typeof getStagedCompanyGetById>>, TError = unknown>(
 params?: GetStagedCompanyGetByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStagedCompanyGetById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStagedCompanyGetByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStagedCompanyGetById>>> = ({ signal }) => getStagedCompanyGetById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getStagedCompanyGetById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetSubscriptionTypes = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionType[]>(
      {url: `/Subscription/GetSubscriptionTypes`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionTypesQueryKey = () => [`/Subscription/GetSubscriptionTypes`];

    
export type GetSubscriptionGetSubscriptionTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionTypes>>>
export type GetSubscriptionGetSubscriptionTypesQueryError = unknown

export const useGetSubscriptionGetSubscriptionTypes = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionTypes>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionTypesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionTypes>>> = ({ signal }) => getSubscriptionGetSubscriptionTypes(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionTypes>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetPromotionByCode = (
    params?: GetSubscriptionGetPromotionByCodeParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PromotionCode>(
      {url: `/Subscription/GetPromotionByCode`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetPromotionByCodeQueryKey = (params?: GetSubscriptionGetPromotionByCodeParams,) => [`/Subscription/GetPromotionByCode`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetPromotionByCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetPromotionByCode>>>
export type GetSubscriptionGetPromotionByCodeQueryError = unknown

export const useGetSubscriptionGetPromotionByCode = <TData = Awaited<ReturnType<typeof getSubscriptionGetPromotionByCode>>, TError = unknown>(
 params?: GetSubscriptionGetPromotionByCodeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetPromotionByCode>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetPromotionByCodeQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetPromotionByCode>>> = ({ signal }) => getSubscriptionGetPromotionByCode(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetPromotionByCode>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postSubscriptionAddSubscription = (
    params?: PostSubscriptionAddSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<InvoiceDetail>(
      {url: `/Subscription/AddSubscription`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionAddSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionAddSubscription>>>
    
    export type PostSubscriptionAddSubscriptionMutationError = unknown

    export const usePostSubscriptionAddSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionAddSubscription>>, TError,{params?: PostSubscriptionAddSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionAddSubscription>>, {params?: PostSubscriptionAddSubscriptionParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionAddSubscription(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionAddSubscription>>, TError, {params?: PostSubscriptionAddSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionAddSubscriptionByPromotion = (
    params?: PostSubscriptionAddSubscriptionByPromotionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/AddSubscriptionByPromotion`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionAddSubscriptionByPromotionMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionByPromotion>>>
    
    export type PostSubscriptionAddSubscriptionByPromotionMutationError = unknown

    export const usePostSubscriptionAddSubscriptionByPromotion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionByPromotion>>, TError,{params?: PostSubscriptionAddSubscriptionByPromotionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionByPromotion>>, {params?: PostSubscriptionAddSubscriptionByPromotionParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionAddSubscriptionByPromotion(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionByPromotion>>, TError, {params?: PostSubscriptionAddSubscriptionByPromotionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionAddSubscriptionBySupportUser = (
    params?: PostSubscriptionAddSubscriptionBySupportUserParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<InvoiceDetail>(
      {url: `/Subscription/AddSubscriptionBySupportUser`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionAddSubscriptionBySupportUserMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionBySupportUser>>>
    
    export type PostSubscriptionAddSubscriptionBySupportUserMutationError = unknown

    export const usePostSubscriptionAddSubscriptionBySupportUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionBySupportUser>>, TError,{params?: PostSubscriptionAddSubscriptionBySupportUserParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionBySupportUser>>, {params?: PostSubscriptionAddSubscriptionBySupportUserParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionAddSubscriptionBySupportUser(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionAddSubscriptionBySupportUser>>, TError, {params?: PostSubscriptionAddSubscriptionBySupportUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSubscriptionGetSubscriptionsByCompany = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionDetail[]>(
      {url: `/Subscription/GetSubscriptionsByCompany`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionsByCompanyQueryKey = () => [`/Subscription/GetSubscriptionsByCompany`];

    
export type GetSubscriptionGetSubscriptionsByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompany>>>
export type GetSubscriptionGetSubscriptionsByCompanyQueryError = unknown

export const useGetSubscriptionGetSubscriptionsByCompany = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompany>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionsByCompanyQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompany>>> = ({ signal }) => getSubscriptionGetSubscriptionsByCompany(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetAllSubscriptionsByCompany = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionDetail[]>(
      {url: `/Subscription/GetAllSubscriptionsByCompany`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetAllSubscriptionsByCompanyQueryKey = () => [`/Subscription/GetAllSubscriptionsByCompany`];

    
export type GetSubscriptionGetAllSubscriptionsByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetAllSubscriptionsByCompany>>>
export type GetSubscriptionGetAllSubscriptionsByCompanyQueryError = unknown

export const useGetSubscriptionGetAllSubscriptionsByCompany = <TData = Awaited<ReturnType<typeof getSubscriptionGetAllSubscriptionsByCompany>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetAllSubscriptionsByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetAllSubscriptionsByCompanyQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetAllSubscriptionsByCompany>>> = ({ signal }) => getSubscriptionGetAllSubscriptionsByCompany(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetAllSubscriptionsByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetSubscriptionById = (
    params?: GetSubscriptionGetSubscriptionByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionLite>(
      {url: `/Subscription/GetSubscriptionById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionByIdQueryKey = (params?: GetSubscriptionGetSubscriptionByIdParams,) => [`/Subscription/GetSubscriptionById`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetSubscriptionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionById>>>
export type GetSubscriptionGetSubscriptionByIdQueryError = unknown

export const useGetSubscriptionGetSubscriptionById = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionById>>, TError = unknown>(
 params?: GetSubscriptionGetSubscriptionByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionById>>> = ({ signal }) => getSubscriptionGetSubscriptionById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetSubscriptionOptions = (
    params?: GetSubscriptionGetSubscriptionOptionsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionOptions>(
      {url: `/Subscription/GetSubscriptionOptions`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionOptionsQueryKey = (params?: GetSubscriptionGetSubscriptionOptionsParams,) => [`/Subscription/GetSubscriptionOptions`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetSubscriptionOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionOptions>>>
export type GetSubscriptionGetSubscriptionOptionsQueryError = unknown

export const useGetSubscriptionGetSubscriptionOptions = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionOptions>>, TError = unknown>(
 params?: GetSubscriptionGetSubscriptionOptionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionOptions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionOptionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionOptions>>> = ({ signal }) => getSubscriptionGetSubscriptionOptions(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionOptions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetCreditRates = (
    params?: GetSubscriptionGetCreditRatesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CreditRates>(
      {url: `/Subscription/GetCreditRates`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetCreditRatesQueryKey = (params?: GetSubscriptionGetCreditRatesParams,) => [`/Subscription/GetCreditRates`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetCreditRatesQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetCreditRates>>>
export type GetSubscriptionGetCreditRatesQueryError = unknown

export const useGetSubscriptionGetCreditRates = <TData = Awaited<ReturnType<typeof getSubscriptionGetCreditRates>>, TError = unknown>(
 params?: GetSubscriptionGetCreditRatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetCreditRates>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetCreditRatesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetCreditRates>>> = ({ signal }) => getSubscriptionGetCreditRates(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetCreditRates>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetSubscriptionRate = (
    params?: GetSubscriptionGetSubscriptionRateParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number>(
      {url: `/Subscription/GetSubscriptionRate`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionRateQueryKey = (params?: GetSubscriptionGetSubscriptionRateParams,) => [`/Subscription/GetSubscriptionRate`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetSubscriptionRateQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionRate>>>
export type GetSubscriptionGetSubscriptionRateQueryError = unknown

export const useGetSubscriptionGetSubscriptionRate = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionRate>>, TError = unknown>(
 params?: GetSubscriptionGetSubscriptionRateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionRate>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionRateQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionRate>>> = ({ signal }) => getSubscriptionGetSubscriptionRate(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionRate>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetContractTerms = (
    params?: GetSubscriptionGetContractTermsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ContractTerms[]>(
      {url: `/Subscription/GetContractTerms`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetContractTermsQueryKey = (params?: GetSubscriptionGetContractTermsParams,) => [`/Subscription/GetContractTerms`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetContractTermsQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetContractTerms>>>
export type GetSubscriptionGetContractTermsQueryError = unknown

export const useGetSubscriptionGetContractTerms = <TData = Awaited<ReturnType<typeof getSubscriptionGetContractTerms>>, TError = unknown>(
 params?: GetSubscriptionGetContractTermsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetContractTerms>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetContractTermsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetContractTerms>>> = ({ signal }) => getSubscriptionGetContractTerms(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetContractTerms>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postSubscriptionUpdateSubscription = (
    params?: PostSubscriptionUpdateSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<InvoiceDetail>(
      {url: `/Subscription/UpdateSubscription`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionUpdateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionUpdateSubscription>>>
    
    export type PostSubscriptionUpdateSubscriptionMutationError = unknown

    export const usePostSubscriptionUpdateSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionUpdateSubscription>>, TError,{params?: PostSubscriptionUpdateSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionUpdateSubscription>>, {params?: PostSubscriptionUpdateSubscriptionParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionUpdateSubscription(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionUpdateSubscription>>, TError, {params?: PostSubscriptionUpdateSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionCancelSubscriptionAtContractEnd = (
    params?: PostSubscriptionCancelSubscriptionAtContractEndParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/CancelSubscriptionAtContractEnd`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionCancelSubscriptionAtContractEndMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionAtContractEnd>>>
    
    export type PostSubscriptionCancelSubscriptionAtContractEndMutationError = unknown

    export const usePostSubscriptionCancelSubscriptionAtContractEnd = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionAtContractEnd>>, TError,{params?: PostSubscriptionCancelSubscriptionAtContractEndParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionAtContractEnd>>, {params?: PostSubscriptionCancelSubscriptionAtContractEndParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionCancelSubscriptionAtContractEnd(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionAtContractEnd>>, TError, {params?: PostSubscriptionCancelSubscriptionAtContractEndParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionCancelSubscriptionToday = (
    params?: PostSubscriptionCancelSubscriptionTodayParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/CancelSubscriptionToday`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionCancelSubscriptionTodayMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionToday>>>
    
    export type PostSubscriptionCancelSubscriptionTodayMutationError = unknown

    export const usePostSubscriptionCancelSubscriptionToday = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionToday>>, TError,{params?: PostSubscriptionCancelSubscriptionTodayParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionToday>>, {params?: PostSubscriptionCancelSubscriptionTodayParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionCancelSubscriptionToday(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionCancelSubscriptionToday>>, TError, {params?: PostSubscriptionCancelSubscriptionTodayParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionReverseSubscriptionCancellation = (
    params?: PostSubscriptionReverseSubscriptionCancellationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/ReverseSubscriptionCancellation`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionReverseSubscriptionCancellationMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionReverseSubscriptionCancellation>>>
    
    export type PostSubscriptionReverseSubscriptionCancellationMutationError = unknown

    export const usePostSubscriptionReverseSubscriptionCancellation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionReverseSubscriptionCancellation>>, TError,{params?: PostSubscriptionReverseSubscriptionCancellationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionReverseSubscriptionCancellation>>, {params?: PostSubscriptionReverseSubscriptionCancellationParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionReverseSubscriptionCancellation(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionReverseSubscriptionCancellation>>, TError, {params?: PostSubscriptionReverseSubscriptionCancellationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSubscriptionGetCompanyInfo = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyInfo>(
      {url: `/Subscription/GetCompanyInfo`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetCompanyInfoQueryKey = () => [`/Subscription/GetCompanyInfo`];

    
export type GetSubscriptionGetCompanyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetCompanyInfo>>>
export type GetSubscriptionGetCompanyInfoQueryError = unknown

export const useGetSubscriptionGetCompanyInfo = <TData = Awaited<ReturnType<typeof getSubscriptionGetCompanyInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetCompanyInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetCompanyInfoQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetCompanyInfo>>> = ({ signal }) => getSubscriptionGetCompanyInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetCompanyInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetMspCompanyInfo = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyInfo>(
      {url: `/Subscription/GetMspCompanyInfo`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetMspCompanyInfoQueryKey = () => [`/Subscription/GetMspCompanyInfo`];

    
export type GetSubscriptionGetMspCompanyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetMspCompanyInfo>>>
export type GetSubscriptionGetMspCompanyInfoQueryError = unknown

export const useGetSubscriptionGetMspCompanyInfo = <TData = Awaited<ReturnType<typeof getSubscriptionGetMspCompanyInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetMspCompanyInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetMspCompanyInfoQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetMspCompanyInfo>>> = ({ signal }) => getSubscriptionGetMspCompanyInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetMspCompanyInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postSubscriptionUpdateMspCompanyInfo = (
    companyInfo: CompanyInfo,
    params?: PostSubscriptionUpdateMspCompanyInfoParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Subscription/UpdateMspCompanyInfo`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyInfo,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionUpdateMspCompanyInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionUpdateMspCompanyInfo>>>
    export type PostSubscriptionUpdateMspCompanyInfoMutationBody = CompanyInfo
    export type PostSubscriptionUpdateMspCompanyInfoMutationError = unknown

    export const usePostSubscriptionUpdateMspCompanyInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionUpdateMspCompanyInfo>>, TError,{data: CompanyInfo;params?: PostSubscriptionUpdateMspCompanyInfoParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionUpdateMspCompanyInfo>>, {data: CompanyInfo;params?: PostSubscriptionUpdateMspCompanyInfoParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postSubscriptionUpdateMspCompanyInfo(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionUpdateMspCompanyInfo>>, TError, {data: CompanyInfo;params?: PostSubscriptionUpdateMspCompanyInfoParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionUpdateCompanyInfo = (
    companyInfo: CompanyInfo,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/UpdateCompanyInfo`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyInfo
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionUpdateCompanyInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfo>>>
    export type PostSubscriptionUpdateCompanyInfoMutationBody = CompanyInfo
    export type PostSubscriptionUpdateCompanyInfoMutationError = unknown

    export const usePostSubscriptionUpdateCompanyInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfo>>, TError,{data: CompanyInfo}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfo>>, {data: CompanyInfo}> = (props) => {
          const {data} = props ?? {};

          return  postSubscriptionUpdateCompanyInfo(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfo>>, TError, {data: CompanyInfo}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionUpdateCompanyInfoById = (
    companyInfo: CompanyInfo,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/UpdateCompanyInfoById`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: companyInfo
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionUpdateCompanyInfoByIdMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfoById>>>
    export type PostSubscriptionUpdateCompanyInfoByIdMutationBody = CompanyInfo
    export type PostSubscriptionUpdateCompanyInfoByIdMutationError = unknown

    export const usePostSubscriptionUpdateCompanyInfoById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfoById>>, TError,{data: CompanyInfo}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfoById>>, {data: CompanyInfo}> = (props) => {
          const {data} = props ?? {};

          return  postSubscriptionUpdateCompanyInfoById(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionUpdateCompanyInfoById>>, TError, {data: CompanyInfo}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionHandleActivateCompany = (
    params?: PostSubscriptionHandleActivateCompanyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/HandleActivateCompany`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionHandleActivateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionHandleActivateCompany>>>
    
    export type PostSubscriptionHandleActivateCompanyMutationError = unknown

    export const usePostSubscriptionHandleActivateCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionHandleActivateCompany>>, TError,{params?: PostSubscriptionHandleActivateCompanyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionHandleActivateCompany>>, {params?: PostSubscriptionHandleActivateCompanyParams}> = (props) => {
          const {params} = props ?? {};

          return  postSubscriptionHandleActivateCompany(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionHandleActivateCompany>>, TError, {params?: PostSubscriptionHandleActivateCompanyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionUpdatePrimaryContactToCurrentUser = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/UpdatePrimaryContactToCurrentUser`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionUpdatePrimaryContactToCurrentUserMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionUpdatePrimaryContactToCurrentUser>>>
    
    export type PostSubscriptionUpdatePrimaryContactToCurrentUserMutationError = unknown

    export const usePostSubscriptionUpdatePrimaryContactToCurrentUser = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionUpdatePrimaryContactToCurrentUser>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionUpdatePrimaryContactToCurrentUser>>, TVariables> = () => {
          ;

          return  postSubscriptionUpdatePrimaryContactToCurrentUser(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionUpdatePrimaryContactToCurrentUser>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postSubscriptionSubscriptionHealthCheck = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<string[]>(
      {url: `/Subscription/SubscriptionHealthCheck`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionSubscriptionHealthCheckMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionSubscriptionHealthCheck>>>
    
    export type PostSubscriptionSubscriptionHealthCheckMutationError = unknown

    export const usePostSubscriptionSubscriptionHealthCheck = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionSubscriptionHealthCheck>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionSubscriptionHealthCheck>>, TVariables> = () => {
          ;

          return  postSubscriptionSubscriptionHealthCheck(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionSubscriptionHealthCheck>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getSubscriptionActiveSubscriptionForApplication = (
    params?: GetSubscriptionActiveSubscriptionForApplicationParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Subscription/ActiveSubscriptionForApplication`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionActiveSubscriptionForApplicationQueryKey = (params?: GetSubscriptionActiveSubscriptionForApplicationParams,) => [`/Subscription/ActiveSubscriptionForApplication`, ...(params ? [params]: [])];

    
export type GetSubscriptionActiveSubscriptionForApplicationQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionActiveSubscriptionForApplication>>>
export type GetSubscriptionActiveSubscriptionForApplicationQueryError = unknown

export const useGetSubscriptionActiveSubscriptionForApplication = <TData = Awaited<ReturnType<typeof getSubscriptionActiveSubscriptionForApplication>>, TError = unknown>(
 params?: GetSubscriptionActiveSubscriptionForApplicationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionActiveSubscriptionForApplication>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionActiveSubscriptionForApplicationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionActiveSubscriptionForApplication>>> = ({ signal }) => getSubscriptionActiveSubscriptionForApplication(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionActiveSubscriptionForApplication>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetAppAccessByCompany = (
    params?: GetSubscriptionGetAppAccessByCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string[]>(
      {url: `/Subscription/GetAppAccessByCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetAppAccessByCompanyQueryKey = (params?: GetSubscriptionGetAppAccessByCompanyParams,) => [`/Subscription/GetAppAccessByCompany`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetAppAccessByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByCompany>>>
export type GetSubscriptionGetAppAccessByCompanyQueryError = unknown

export const useGetSubscriptionGetAppAccessByCompany = <TData = Awaited<ReturnType<typeof getSubscriptionGetAppAccessByCompany>>, TError = unknown>(
 params?: GetSubscriptionGetAppAccessByCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetAppAccessByCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByCompany>>> = ({ signal }) => getSubscriptionGetAppAccessByCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionMspIsEnabledAndHasActiveSubscription = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<boolean>(
      {url: `/Subscription/MspIsEnabledAndHasActiveSubscription`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionMspIsEnabledAndHasActiveSubscriptionQueryKey = () => [`/Subscription/MspIsEnabledAndHasActiveSubscription`];

    
export type GetSubscriptionMspIsEnabledAndHasActiveSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionMspIsEnabledAndHasActiveSubscription>>>
export type GetSubscriptionMspIsEnabledAndHasActiveSubscriptionQueryError = unknown

export const useGetSubscriptionMspIsEnabledAndHasActiveSubscription = <TData = Awaited<ReturnType<typeof getSubscriptionMspIsEnabledAndHasActiveSubscription>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionMspIsEnabledAndHasActiveSubscription>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionMspIsEnabledAndHasActiveSubscriptionQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionMspIsEnabledAndHasActiveSubscription>>> = ({ signal }) => getSubscriptionMspIsEnabledAndHasActiveSubscription(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionMspIsEnabledAndHasActiveSubscription>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postSubscriptionHandleBulkActivateCompanies = (
    postSubscriptionHandleBulkActivateCompaniesBody: number[],
    params?: PostSubscriptionHandleBulkActivateCompaniesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Subscription/HandleBulkActivateCompanies`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postSubscriptionHandleBulkActivateCompaniesBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSubscriptionHandleBulkActivateCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof postSubscriptionHandleBulkActivateCompanies>>>
    export type PostSubscriptionHandleBulkActivateCompaniesMutationBody = number[]
    export type PostSubscriptionHandleBulkActivateCompaniesMutationError = unknown

    export const usePostSubscriptionHandleBulkActivateCompanies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSubscriptionHandleBulkActivateCompanies>>, TError,{data: number[];params?: PostSubscriptionHandleBulkActivateCompaniesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSubscriptionHandleBulkActivateCompanies>>, {data: number[];params?: PostSubscriptionHandleBulkActivateCompaniesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postSubscriptionHandleBulkActivateCompanies(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSubscriptionHandleBulkActivateCompanies>>, TError, {data: number[];params?: PostSubscriptionHandleBulkActivateCompaniesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSubscriptionGetSubscriptionsByCompanyId = (
    params?: GetSubscriptionGetSubscriptionsByCompanyIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SubscriptionDetail[]>(
      {url: `/Subscription/GetSubscriptionsByCompanyId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetSubscriptionsByCompanyIdQueryKey = (params?: GetSubscriptionGetSubscriptionsByCompanyIdParams,) => [`/Subscription/GetSubscriptionsByCompanyId`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetSubscriptionsByCompanyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompanyId>>>
export type GetSubscriptionGetSubscriptionsByCompanyIdQueryError = unknown

export const useGetSubscriptionGetSubscriptionsByCompanyId = <TData = Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompanyId>>, TError = unknown>(
 params?: GetSubscriptionGetSubscriptionsByCompanyIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompanyId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetSubscriptionsByCompanyIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompanyId>>> = ({ signal }) => getSubscriptionGetSubscriptionsByCompanyId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetSubscriptionsByCompanyId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSubscriptionGetAppAccessByMspCompany = (
    params?: GetSubscriptionGetAppAccessByMspCompanyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanySubscriptionType>(
      {url: `/Subscription/GetAppAccessByMspCompany`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSubscriptionGetAppAccessByMspCompanyQueryKey = (params?: GetSubscriptionGetAppAccessByMspCompanyParams,) => [`/Subscription/GetAppAccessByMspCompany`, ...(params ? [params]: [])];

    
export type GetSubscriptionGetAppAccessByMspCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByMspCompany>>>
export type GetSubscriptionGetAppAccessByMspCompanyQueryError = unknown

export const useGetSubscriptionGetAppAccessByMspCompany = <TData = Awaited<ReturnType<typeof getSubscriptionGetAppAccessByMspCompany>>, TError = unknown>(
 params?: GetSubscriptionGetAppAccessByMspCompanyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByMspCompany>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionGetAppAccessByMspCompanyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByMspCompany>>> = ({ signal }) => getSubscriptionGetAppAccessByMspCompany(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptionGetAppAccessByMspCompany>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSupportGetSupportedCompanies = (
    params?: GetSupportGetSupportedCompaniesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number[]>(
      {url: `/Support/GetSupportedCompanies`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSupportGetSupportedCompaniesQueryKey = (params?: GetSupportGetSupportedCompaniesParams,) => [`/Support/GetSupportedCompanies`, ...(params ? [params]: [])];

    
export type GetSupportGetSupportedCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getSupportGetSupportedCompanies>>>
export type GetSupportGetSupportedCompaniesQueryError = unknown

export const useGetSupportGetSupportedCompanies = <TData = Awaited<ReturnType<typeof getSupportGetSupportedCompanies>>, TError = unknown>(
 params?: GetSupportGetSupportedCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSupportGetSupportedCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSupportGetSupportedCompaniesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSupportGetSupportedCompanies>>> = ({ signal }) => getSupportGetSupportedCompanies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSupportGetSupportedCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSupportGetExternalSupportCompanies = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Int32StringKeyValuePair[]>(
      {url: `/Support/GetExternalSupportCompanies`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetSupportGetExternalSupportCompaniesQueryKey = () => [`/Support/GetExternalSupportCompanies`];

    
export type GetSupportGetExternalSupportCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getSupportGetExternalSupportCompanies>>>
export type GetSupportGetExternalSupportCompaniesQueryError = unknown

export const useGetSupportGetExternalSupportCompanies = <TData = Awaited<ReturnType<typeof getSupportGetExternalSupportCompanies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSupportGetExternalSupportCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSupportGetExternalSupportCompaniesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSupportGetExternalSupportCompanies>>> = ({ signal }) => getSupportGetExternalSupportCompanies(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSupportGetExternalSupportCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postSupportBulkAssignSupportCompanies = (
    bulkAssignSupportCompanies: BulkAssignSupportCompanies,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Support/BulkAssignSupportCompanies`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bulkAssignSupportCompanies
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostSupportBulkAssignSupportCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof postSupportBulkAssignSupportCompanies>>>
    export type PostSupportBulkAssignSupportCompaniesMutationBody = BulkAssignSupportCompanies
    export type PostSupportBulkAssignSupportCompaniesMutationError = unknown

    export const usePostSupportBulkAssignSupportCompanies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSupportBulkAssignSupportCompanies>>, TError,{data: BulkAssignSupportCompanies}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSupportBulkAssignSupportCompanies>>, {data: BulkAssignSupportCompanies}> = (props) => {
          const {data} = props ?? {};

          return  postSupportBulkAssignSupportCompanies(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSupportBulkAssignSupportCompanies>>, TError, {data: BulkAssignSupportCompanies}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTestErrorNonServiceEx = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TestError/NonServiceEx`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostTestErrorNonServiceExMutationResult = NonNullable<Awaited<ReturnType<typeof postTestErrorNonServiceEx>>>
    
    export type PostTestErrorNonServiceExMutationError = unknown

    export const usePostTestErrorNonServiceEx = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTestErrorNonServiceEx>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTestErrorNonServiceEx>>, TVariables> = () => {
          ;

          return  postTestErrorNonServiceEx(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTestErrorNonServiceEx>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTestErrorServiceExInternal = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TestError/ServiceExInternal`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostTestErrorServiceExInternalMutationResult = NonNullable<Awaited<ReturnType<typeof postTestErrorServiceExInternal>>>
    
    export type PostTestErrorServiceExInternalMutationError = unknown

    export const usePostTestErrorServiceExInternal = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTestErrorServiceExInternal>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTestErrorServiceExInternal>>, TVariables> = () => {
          ;

          return  postTestErrorServiceExInternal(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTestErrorServiceExInternal>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTestErrorServiceExUnknown = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TestError/ServiceExUnknown`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostTestErrorServiceExUnknownMutationResult = NonNullable<Awaited<ReturnType<typeof postTestErrorServiceExUnknown>>>
    
    export type PostTestErrorServiceExUnknownMutationError = unknown

    export const usePostTestErrorServiceExUnknown = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTestErrorServiceExUnknown>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTestErrorServiceExUnknown>>, TVariables> = () => {
          ;

          return  postTestErrorServiceExUnknown(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTestErrorServiceExUnknown>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTestErrorServiceExNotFound = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TestError/ServiceExNotFound`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostTestErrorServiceExNotFoundMutationResult = NonNullable<Awaited<ReturnType<typeof postTestErrorServiceExNotFound>>>
    
    export type PostTestErrorServiceExNotFoundMutationError = unknown

    export const usePostTestErrorServiceExNotFound = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTestErrorServiceExNotFound>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTestErrorServiceExNotFound>>, TVariables> = () => {
          ;

          return  postTestErrorServiceExNotFound(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTestErrorServiceExNotFound>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTestErrorServiceExBadRequest = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TestError/ServiceExBadRequest`, method: 'post'
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostTestErrorServiceExBadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postTestErrorServiceExBadRequest>>>
    
    export type PostTestErrorServiceExBadRequestMutationError = unknown

    export const usePostTestErrorServiceExBadRequest = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTestErrorServiceExBadRequest>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTestErrorServiceExBadRequest>>, TVariables> = () => {
          ;

          return  postTestErrorServiceExBadRequest(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTestErrorServiceExBadRequest>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserGetUserByExternalId = (
    params?: GetUserGetUserByExternalIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<User>(
      {url: `/User/GetUserByExternalId`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetUserByExternalIdQueryKey = (params?: GetUserGetUserByExternalIdParams,) => [`/User/GetUserByExternalId`, ...(params ? [params]: [])];

    
export type GetUserGetUserByExternalIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetUserByExternalId>>>
export type GetUserGetUserByExternalIdQueryError = unknown

export const useGetUserGetUserByExternalId = <TData = Awaited<ReturnType<typeof getUserGetUserByExternalId>>, TError = unknown>(
 params?: GetUserGetUserByExternalIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetUserByExternalId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetUserByExternalIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetUserByExternalId>>> = ({ signal }) => getUserGetUserByExternalId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetUserByExternalId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postUserCreateUserByEmailAddress = (
    params?: PostUserCreateUserByEmailAddressParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<User>(
      {url: `/User/CreateUserByEmailAddress`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserCreateUserByEmailAddressMutationResult = NonNullable<Awaited<ReturnType<typeof postUserCreateUserByEmailAddress>>>
    
    export type PostUserCreateUserByEmailAddressMutationError = unknown

    export const usePostUserCreateUserByEmailAddress = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserCreateUserByEmailAddress>>, TError,{params?: PostUserCreateUserByEmailAddressParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserCreateUserByEmailAddress>>, {params?: PostUserCreateUserByEmailAddressParams}> = (props) => {
          const {params} = props ?? {};

          return  postUserCreateUserByEmailAddress(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserCreateUserByEmailAddress>>, TError, {params?: PostUserCreateUserByEmailAddressParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserGetMyCompanies = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/User/GetMyCompanies`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyCompaniesQueryKey = () => [`/User/GetMyCompanies`];

    
export type GetUserGetMyCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyCompanies>>>
export type GetUserGetMyCompaniesQueryError = unknown

export const useGetUserGetMyCompanies = <TData = Awaited<ReturnType<typeof getUserGetMyCompanies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyCompaniesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyCompanies>>> = ({ signal }) => getUserGetMyCompanies(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetUserCompanies = (
    params?: GetUserGetUserCompaniesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/User/GetUserCompanies`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetUserCompaniesQueryKey = (params?: GetUserGetUserCompaniesParams,) => [`/User/GetUserCompanies`, ...(params ? [params]: [])];

    
export type GetUserGetUserCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetUserCompanies>>>
export type GetUserGetUserCompaniesQueryError = unknown

export const useGetUserGetUserCompanies = <TData = Awaited<ReturnType<typeof getUserGetUserCompanies>>, TError = unknown>(
 params?: GetUserGetUserCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetUserCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetUserCompaniesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetUserCompanies>>> = ({ signal }) => getUserGetUserCompanies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetUserCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetSupportUserCompanies = (
    params?: GetUserGetSupportUserCompaniesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/User/GetSupportUserCompanies`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetSupportUserCompaniesQueryKey = (params?: GetUserGetSupportUserCompaniesParams,) => [`/User/GetSupportUserCompanies`, ...(params ? [params]: [])];

    
export type GetUserGetSupportUserCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetSupportUserCompanies>>>
export type GetUserGetSupportUserCompaniesQueryError = unknown

export const useGetUserGetSupportUserCompanies = <TData = Awaited<ReturnType<typeof getUserGetSupportUserCompanies>>, TError = unknown>(
 params?: GetUserGetSupportUserCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetSupportUserCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetSupportUserCompaniesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetSupportUserCompanies>>> = ({ signal }) => getUserGetSupportUserCompanies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetSupportUserCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetMyMspCompanies = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Company[]>(
      {url: `/User/GetMyMspCompanies`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyMspCompaniesQueryKey = () => [`/User/GetMyMspCompanies`];

    
export type GetUserGetMyMspCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyMspCompanies>>>
export type GetUserGetMyMspCompaniesQueryError = unknown

export const useGetUserGetMyMspCompanies = <TData = Awaited<ReturnType<typeof getUserGetMyMspCompanies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyMspCompanies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyMspCompaniesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyMspCompanies>>> = ({ signal }) => getUserGetMyMspCompanies(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyMspCompanies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postUserUpdateMyProfile = (
    user: User,
 options?: SecondParameter<typeof request>,
) => {
      return request<User>(
      {url: `/User/UpdateMyProfile`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: user
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserUpdateMyProfileMutationResult = NonNullable<Awaited<ReturnType<typeof postUserUpdateMyProfile>>>
    export type PostUserUpdateMyProfileMutationBody = User
    export type PostUserUpdateMyProfileMutationError = unknown

    export const usePostUserUpdateMyProfile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserUpdateMyProfile>>, TError,{data: User}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserUpdateMyProfile>>, {data: User}> = (props) => {
          const {data} = props ?? {};

          return  postUserUpdateMyProfile(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserUpdateMyProfile>>, TError, {data: User}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserGetMyProfile = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<User>(
      {url: `/User/GetMyProfile`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyProfileQueryKey = () => [`/User/GetMyProfile`];

    
export type GetUserGetMyProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyProfile>>>
export type GetUserGetMyProfileQueryError = unknown

export const useGetUserGetMyProfile = <TData = Awaited<ReturnType<typeof getUserGetMyProfile>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyProfile>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyProfileQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyProfile>>> = ({ signal }) => getUserGetMyProfile(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyProfile>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetMyPermissions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<EffectivePermissions[]>(
      {url: `/User/GetMyPermissions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyPermissionsQueryKey = () => [`/User/GetMyPermissions`];

    
export type GetUserGetMyPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyPermissions>>>
export type GetUserGetMyPermissionsQueryError = unknown

export const useGetUserGetMyPermissions = <TData = Awaited<ReturnType<typeof getUserGetMyPermissions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyPermissions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyPermissionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyPermissions>>> = ({ signal }) => getUserGetMyPermissions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyPermissions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetMyAccessRules = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<IQueryExpr[]>(
      {url: `/User/GetMyAccessRules`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyAccessRulesQueryKey = () => [`/User/GetMyAccessRules`];

    
export type GetUserGetMyAccessRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyAccessRules>>>
export type GetUserGetMyAccessRulesQueryError = unknown

export const useGetUserGetMyAccessRules = <TData = Awaited<ReturnType<typeof getUserGetMyAccessRules>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyAccessRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyAccessRulesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyAccessRules>>> = ({ signal }) => getUserGetMyAccessRules(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyAccessRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetMyRoles = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CompanyUserRole[]>(
      {url: `/User/GetMyRoles`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMyRolesQueryKey = () => [`/User/GetMyRoles`];

    
export type GetUserGetMyRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMyRoles>>>
export type GetUserGetMyRolesQueryError = unknown

export const useGetUserGetMyRoles = <TData = Awaited<ReturnType<typeof getUserGetMyRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMyRoles>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMyRolesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMyRoles>>> = ({ signal }) => getUserGetMyRoles(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMyRoles>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserLogin = (
    params?: GetUserLoginParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/Login`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserLoginQueryKey = (params?: GetUserLoginParams,) => [`/User/Login`, ...(params ? [params]: [])];

    
export type GetUserLoginQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLogin>>>
export type GetUserLoginQueryError = unknown

export const useGetUserLogin = <TData = Awaited<ReturnType<typeof getUserLogin>>, TError = unknown>(
 params?: GetUserLoginParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLogin>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserLoginQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLogin>>> = ({ signal }) => getUserLogin(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLogin>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserAzLogin = (
    params?: GetUserAzLoginParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/AzLogin`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserAzLoginQueryKey = (params?: GetUserAzLoginParams,) => [`/User/AzLogin`, ...(params ? [params]: [])];

    
export type GetUserAzLoginQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAzLogin>>>
export type GetUserAzLoginQueryError = unknown

export const useGetUserAzLogin = <TData = Awaited<ReturnType<typeof getUserAzLogin>>, TError = unknown>(
 params?: GetUserAzLoginParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserAzLogin>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserAzLoginQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAzLogin>>> = ({ signal }) => getUserAzLogin(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserAzLogin>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserLogout = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/Logout`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserLogoutQueryKey = () => [`/User/Logout`];

    
export type GetUserLogoutQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLogout>>>
export type GetUserLogoutQueryError = unknown

export const useGetUserLogout = <TData = Awaited<ReturnType<typeof getUserLogout>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLogout>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserLogoutQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLogout>>> = ({ signal }) => getUserLogout(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLogout>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserEmailVerified = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<EmailVerified>(
      {url: `/User/EmailVerified`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserEmailVerifiedQueryKey = () => [`/User/EmailVerified`];

    
export type GetUserEmailVerifiedQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEmailVerified>>>
export type GetUserEmailVerifiedQueryError = unknown

export const useGetUserEmailVerified = <TData = Awaited<ReturnType<typeof getUserEmailVerified>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserEmailVerified>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserEmailVerifiedQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEmailVerified>>> = ({ signal }) => getUserEmailVerified(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserEmailVerified>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserAuth0ResendEmailValidation = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string>(
      {url: `/User/Auth0ResendEmailValidation`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserAuth0ResendEmailValidationQueryKey = () => [`/User/Auth0ResendEmailValidation`];

    
export type GetUserAuth0ResendEmailValidationQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAuth0ResendEmailValidation>>>
export type GetUserAuth0ResendEmailValidationQueryError = unknown

export const useGetUserAuth0ResendEmailValidation = <TData = Awaited<ReturnType<typeof getUserAuth0ResendEmailValidation>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserAuth0ResendEmailValidation>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserAuth0ResendEmailValidationQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAuth0ResendEmailValidation>>> = ({ signal }) => getUserAuth0ResendEmailValidation(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserAuth0ResendEmailValidation>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserBearerLogin = (
    params?: GetUserBearerLoginParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/BearerLogin`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserBearerLoginQueryKey = (params?: GetUserBearerLoginParams,) => [`/User/BearerLogin`, ...(params ? [params]: [])];

    
export type GetUserBearerLoginQueryResult = NonNullable<Awaited<ReturnType<typeof getUserBearerLogin>>>
export type GetUserBearerLoginQueryError = unknown

export const useGetUserBearerLogin = <TData = Awaited<ReturnType<typeof getUserBearerLogin>>, TError = unknown>(
 params?: GetUserBearerLoginParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserBearerLogin>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserBearerLoginQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserBearerLogin>>> = ({ signal }) => getUserBearerLogin(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserBearerLogin>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserLoginCallback = (
    params?: GetUserLoginCallbackParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/LoginCallback`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserLoginCallbackQueryKey = (params?: GetUserLoginCallbackParams,) => [`/User/LoginCallback`, ...(params ? [params]: [])];

    
export type GetUserLoginCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLoginCallback>>>
export type GetUserLoginCallbackQueryError = unknown

export const useGetUserLoginCallback = <TData = Awaited<ReturnType<typeof getUserLoginCallback>>, TError = unknown>(
 params?: GetUserLoginCallbackParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLoginCallback>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserLoginCallbackQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLoginCallback>>> = ({ signal }) => getUserLoginCallback(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLoginCallback>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserPingActivity = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/User/PingActivity`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserPingActivityQueryKey = () => [`/User/PingActivity`];

    
export type GetUserPingActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getUserPingActivity>>>
export type GetUserPingActivityQueryError = unknown

export const useGetUserPingActivity = <TData = Awaited<ReturnType<typeof getUserPingActivity>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserPingActivity>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserPingActivityQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserPingActivity>>> = ({ signal }) => getUserPingActivity(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserPingActivity>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserQueryUserActivity = (
    params?: GetUserQueryUserActivityParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<UserActivityQueryResult[]>(
      {url: `/User/QueryUserActivity`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserQueryUserActivityQueryKey = (params?: GetUserQueryUserActivityParams,) => [`/User/QueryUserActivity`, ...(params ? [params]: [])];

    
export type GetUserQueryUserActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getUserQueryUserActivity>>>
export type GetUserQueryUserActivityQueryError = unknown

export const useGetUserQueryUserActivity = <TData = Awaited<ReturnType<typeof getUserQueryUserActivity>>, TError = unknown>(
 params?: GetUserQueryUserActivityParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserQueryUserActivity>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryUserActivityQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserQueryUserActivity>>> = ({ signal }) => getUserQueryUserActivity(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserQueryUserActivity>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetCompanyUsers = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<UserListItem[]>(
      {url: `/User/GetCompanyUsers`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetCompanyUsersQueryKey = () => [`/User/GetCompanyUsers`];

    
export type GetUserGetCompanyUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetCompanyUsers>>>
export type GetUserGetCompanyUsersQueryError = unknown

export const useGetUserGetCompanyUsers = <TData = Awaited<ReturnType<typeof getUserGetCompanyUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetCompanyUsers>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetCompanyUsersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetCompanyUsers>>> = ({ signal }) => getUserGetCompanyUsers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetCompanyUsers>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserGetMspSupportUsers = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SupportUserListItem[]>(
      {url: `/User/GetMspSupportUsers`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetMspSupportUsersQueryKey = () => [`/User/GetMspSupportUsers`];

    
export type GetUserGetMspSupportUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetMspSupportUsers>>>
export type GetUserGetMspSupportUsersQueryError = unknown

export const useGetUserGetMspSupportUsers = <TData = Awaited<ReturnType<typeof getUserGetMspSupportUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetMspSupportUsers>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetMspSupportUsersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetMspSupportUsers>>> = ({ signal }) => getUserGetMspSupportUsers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetMspSupportUsers>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postUserSaveUserStatus = (
    params?: PostUserSaveUserStatusParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<CompanyUser>(
      {url: `/User/SaveUserStatus`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserSaveUserStatusMutationResult = NonNullable<Awaited<ReturnType<typeof postUserSaveUserStatus>>>
    
    export type PostUserSaveUserStatusMutationError = unknown

    export const usePostUserSaveUserStatus = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserSaveUserStatus>>, TError,{params?: PostUserSaveUserStatusParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserSaveUserStatus>>, {params?: PostUserSaveUserStatusParams}> = (props) => {
          const {params} = props ?? {};

          return  postUserSaveUserStatus(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserSaveUserStatus>>, TError, {params?: PostUserSaveUserStatusParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postUserSaveUserIncludeFutureCompanies = (
    params?: PostUserSaveUserIncludeFutureCompaniesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<User>(
      {url: `/User/SaveUserIncludeFutureCompanies`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserSaveUserIncludeFutureCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof postUserSaveUserIncludeFutureCompanies>>>
    
    export type PostUserSaveUserIncludeFutureCompaniesMutationError = unknown

    export const usePostUserSaveUserIncludeFutureCompanies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserSaveUserIncludeFutureCompanies>>, TError,{params?: PostUserSaveUserIncludeFutureCompaniesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserSaveUserIncludeFutureCompanies>>, {params?: PostUserSaveUserIncludeFutureCompaniesParams}> = (props) => {
          const {params} = props ?? {};

          return  postUserSaveUserIncludeFutureCompanies(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserSaveUserIncludeFutureCompanies>>, TError, {params?: PostUserSaveUserIncludeFutureCompaniesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteUserDeleteUser = (
    params?: DeleteUserDeleteUserParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/User/DeleteUser`, method: 'delete',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type DeleteUserDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserDeleteUser>>>
    
    export type DeleteUserDeleteUserMutationError = unknown

    export const useDeleteUserDeleteUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserDeleteUser>>, TError,{params?: DeleteUserDeleteUserParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserDeleteUser>>, {params?: DeleteUserDeleteUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteUserDeleteUser(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUserDeleteUser>>, TError, {params?: DeleteUserDeleteUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserGetUserById = (
    params?: GetUserGetUserByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<User>(
      {url: `/User/GetUserById`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetUserByIdQueryKey = (params?: GetUserGetUserByIdParams,) => [`/User/GetUserById`, ...(params ? [params]: [])];

    
export type GetUserGetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetUserById>>>
export type GetUserGetUserByIdQueryError = unknown

export const useGetUserGetUserById = <TData = Awaited<ReturnType<typeof getUserGetUserById>>, TError = unknown>(
 params?: GetUserGetUserByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetUserById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetUserByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetUserById>>> = ({ signal }) => getUserGetUserById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetUserById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserHasAcceptedTOS = (
    params?: GetUserHasAcceptedTOSParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TOSAccepted>(
      {url: `/User/HasAcceptedTOS`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserHasAcceptedTOSQueryKey = (params?: GetUserHasAcceptedTOSParams,) => [`/User/HasAcceptedTOS`, ...(params ? [params]: [])];

    
export type GetUserHasAcceptedTOSQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHasAcceptedTOS>>>
export type GetUserHasAcceptedTOSQueryError = unknown

export const useGetUserHasAcceptedTOS = <TData = Awaited<ReturnType<typeof getUserHasAcceptedTOS>>, TError = unknown>(
 params?: GetUserHasAcceptedTOSParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserHasAcceptedTOS>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserHasAcceptedTOSQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHasAcceptedTOS>>> = ({ signal }) => getUserHasAcceptedTOS(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserHasAcceptedTOS>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postUserSaveTermsOfSvcAgreement = (
    params?: PostUserSaveTermsOfSvcAgreementParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/User/SaveTermsOfSvcAgreement`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserSaveTermsOfSvcAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof postUserSaveTermsOfSvcAgreement>>>
    
    export type PostUserSaveTermsOfSvcAgreementMutationError = unknown

    export const usePostUserSaveTermsOfSvcAgreement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserSaveTermsOfSvcAgreement>>, TError,{params?: PostUserSaveTermsOfSvcAgreementParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserSaveTermsOfSvcAgreement>>, {params?: PostUserSaveTermsOfSvcAgreementParams}> = (props) => {
          const {params} = props ?? {};

          return  postUserSaveTermsOfSvcAgreement(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserSaveTermsOfSvcAgreement>>, TError, {params?: PostUserSaveTermsOfSvcAgreementParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserGetCompanyTypesByUser = (
    params?: GetUserGetCompanyTypesByUserParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<GetUserGetCompanyTypesByUser200OneItem[] | GetUserGetCompanyTypesByUser200TwoItem[] | GetUserGetCompanyTypesByUser200ThreeItem[]>(
      {url: `/User/GetCompanyTypesByUser`, method: 'get', signal,
        params,
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserGetCompanyTypesByUserQueryKey = (params?: GetUserGetCompanyTypesByUserParams,) => [`/User/GetCompanyTypesByUser`, ...(params ? [params]: [])];

    
export type GetUserGetCompanyTypesByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGetCompanyTypesByUser>>>
export type GetUserGetCompanyTypesByUserQueryError = unknown

export const useGetUserGetCompanyTypesByUser = <TData = Awaited<ReturnType<typeof getUserGetCompanyTypesByUser>>, TError = unknown>(
 params?: GetUserGetCompanyTypesByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserGetCompanyTypesByUser>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserGetCompanyTypesByUserQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGetCompanyTypesByUser>>> = ({ signal }) => getUserGetCompanyTypesByUser(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserGetCompanyTypesByUser>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUserFacingPermissionGetUserFacingPermissions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<UserFacingPermissionInfo[]>(
      {url: `/UserFacingPermission/GetUserFacingPermissions`, method: 'get', signal
    },
      { type: 'Customers',  ...options});
    }
  

export const getGetUserFacingPermissionGetUserFacingPermissionsQueryKey = () => [`/UserFacingPermission/GetUserFacingPermissions`];

    
export type GetUserFacingPermissionGetUserFacingPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserFacingPermissionGetUserFacingPermissions>>>
export type GetUserFacingPermissionGetUserFacingPermissionsQueryError = unknown

export const useGetUserFacingPermissionGetUserFacingPermissions = <TData = Awaited<ReturnType<typeof getUserFacingPermissionGetUserFacingPermissions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserFacingPermissionGetUserFacingPermissions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserFacingPermissionGetUserFacingPermissionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFacingPermissionGetUserFacingPermissions>>> = ({ signal }) => getUserFacingPermissionGetUserFacingPermissions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserFacingPermissionGetUserFacingPermissions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postUserFacingPermissionGetRoleUserFacingPermissions = (
    postUserFacingPermissionGetRoleUserFacingPermissionsBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<RoleUserFacingPermission[]>(
      {url: `/UserFacingPermission/GetRoleUserFacingPermissions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postUserFacingPermissionGetRoleUserFacingPermissionsBody
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserFacingPermissionGetRoleUserFacingPermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof postUserFacingPermissionGetRoleUserFacingPermissions>>>
    export type PostUserFacingPermissionGetRoleUserFacingPermissionsMutationBody = number[]
    export type PostUserFacingPermissionGetRoleUserFacingPermissionsMutationError = unknown

    export const usePostUserFacingPermissionGetRoleUserFacingPermissions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserFacingPermissionGetRoleUserFacingPermissions>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserFacingPermissionGetRoleUserFacingPermissions>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  postUserFacingPermissionGetRoleUserFacingPermissions(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserFacingPermissionGetRoleUserFacingPermissions>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postUserFacingPermissionSaveRoleUserFacingPermissions = (
    postUserFacingPermissionSaveRoleUserFacingPermissionsBody: number[],
    params?: PostUserFacingPermissionSaveRoleUserFacingPermissionsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/UserFacingPermission/SaveRoleUserFacingPermissions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postUserFacingPermissionSaveRoleUserFacingPermissionsBody,
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserFacingPermissionSaveRoleUserFacingPermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof postUserFacingPermissionSaveRoleUserFacingPermissions>>>
    export type PostUserFacingPermissionSaveRoleUserFacingPermissionsMutationBody = number[]
    export type PostUserFacingPermissionSaveRoleUserFacingPermissionsMutationError = unknown

    export const usePostUserFacingPermissionSaveRoleUserFacingPermissions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserFacingPermissionSaveRoleUserFacingPermissions>>, TError,{data: number[];params?: PostUserFacingPermissionSaveRoleUserFacingPermissionsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserFacingPermissionSaveRoleUserFacingPermissions>>, {data: number[];params?: PostUserFacingPermissionSaveRoleUserFacingPermissionsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postUserFacingPermissionSaveRoleUserFacingPermissions(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserFacingPermissionSaveRoleUserFacingPermissions>>, TError, {data: number[];params?: PostUserFacingPermissionSaveRoleUserFacingPermissionsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postUserFacingPermissionApplyRoleUfPermissions = (
    params?: PostUserFacingPermissionApplyRoleUfPermissionsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<RoleUserFacingPermission[]>(
      {url: `/UserFacingPermission/ApplyRoleUfPermissions`, method: 'post',
        params,
    },
      { type: 'Customers',  ...options});
    }
  


    export type PostUserFacingPermissionApplyRoleUfPermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof postUserFacingPermissionApplyRoleUfPermissions>>>
    
    export type PostUserFacingPermissionApplyRoleUfPermissionsMutationError = unknown

    export const usePostUserFacingPermissionApplyRoleUfPermissions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserFacingPermissionApplyRoleUfPermissions>>, TError,{params?: PostUserFacingPermissionApplyRoleUfPermissionsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserFacingPermissionApplyRoleUfPermissions>>, {params?: PostUserFacingPermissionApplyRoleUfPermissionsParams}> = (props) => {
          const {params} = props ?? {};

          return  postUserFacingPermissionApplyRoleUfPermissions(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postUserFacingPermissionApplyRoleUfPermissions>>, TError, {params?: PostUserFacingPermissionApplyRoleUfPermissionsParams}, TContext>(mutationFn, mutationOptions)
    }
    
