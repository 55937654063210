import { inject, singleton } from 'tsyringe';
import { ConfigService } from '../ConfigService';
declare const Chargebee: any;

@singleton()
export class ChargeBeeInit {
    public constructor(@inject(ConfigService) private readonly configSvc: ConfigService) {}
    public init() {
        (Chargebee as any).init(this.configSvc.config.chargebee);
    }
}
