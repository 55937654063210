import { getAccountGetAccounts } from '@apis/Customers';
import { Company, IQueryExpr } from '@apis/Customers/model';
import { postExportExport } from '@apis/Export';
import { ExportRequest, ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { getForecastGetForecastConfig, postForecastCreateCostForecast, postForecastUpsertForecastConfig } from '@apis/Invoices';
import { CardinalityCheckResult, CostForecastRequest, ForecastConfig } from '@apis/Invoices/model';
import { JobOfCostForecast } from '@apis/Resources';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { SavedForecast, CostForecastPersistence } from '@root/Services/Invoices/CostForecastService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IDailyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { JobService } from '@root/Services/Jobs/JobService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { addMinutes, differenceInCalendarDays, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { inject, injectable, singleton } from 'tsyringe';

@singleton()
class SpendForecastCache {
    private value?: Promise<unknown>;
    private companyId?: number;
    public get<T>(companyId: number | undefined, handler: () => Promise<T>): Promise<T> {
        if (!this.value || this.companyId !== companyId) {
            this.companyId = companyId;
            this.value = handler();
        }
        return this.value as Promise<T>;
    }
}

export type TenantForecastStatus = 'NotConfigured' | 'NotReady' | 'Ready';
export type ForecastRangeInfo = ReturnType<CostForecastPersistence['getForecastRange']>;

@injectable()
export class SpendForecastModel {
    public dateRange?: { from?: Date; to?: Date };
    public accountDateRange: { from: Date; to: Date; accountId: string; accountName: string }[] = [];
    public historicalDaysAvailable: number = 0;
    public historicalDaysNeeded = 14;
    public forecastJobs: JobOfCostForecast[] = [];
    public mySavedForecasts: SavedForecast[] = [];
    public othersSavedForecasts: SavedForecast[] = [];
    public initialized = new EventEmitter<boolean>(false);
    public loaderOpened = new EventEmitter<boolean | 'shared'>(false);
    public detailsRequested = new EventEmitter<{ job?: JobOfCostForecast; savedForecast?: SavedForecast } | undefined>(undefined);
    public configRequested = new EventEmitter(false);
    public forecastRenamed = EventEmitter.empty();
    public userId = -1;

    public constructor(
        @inject(InvoiceApiService) public readonly invoiceApi: InvoiceApiService,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(CostForecastPersistence) public readonly forecastPersistence: CostForecastPersistence,
        @inject(JobService) private readonly jobSvc: JobService,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService,
        @inject(SpendForecastCache) private readonly cache: SpendForecastCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {
        this.userId = this.authSvc.user?.Id ?? -1;
        this.init();
    }

    public init() {
        Promise.all([this.loadDateRange(), this.loadForecastJobs(), this.loadSavedForecasts()]).finally(() => {
            this.initialized.emit(true);
        });
    }

    public async reloadForecasts() {
        await Promise.all([this.loadForecastJobs(), this.loadSavedForecasts()]);
    }

    private async loadDateRange() {
        const [accounts, dataRanges] = await this.cache.get(this.company.Id, async () => {
            const dateRangeRequest = queryBuilder<IDailyRollup>()
                .select((b) => ({
                    accountId: b.model['bill/PayerAccountId'],
                    min: b.min(b.model.UsageStartDate),
                    max: b.max(b.model.UsageStartDate),
                }))
                .execute((q) => this.invoiceApi.query(q, {}));
            const accountRequest = getAccountGetAccounts();
            return await Promise.all([accountRequest, dateRangeRequest]);
        });
        const today = new Date();
        this.accountDateRange =
            dataRanges.Results?.map((r) => ({
                from: !r.min ? today : new Date(r.min),
                to: !r.max ? today : new Date(r.max) > today ? today : new Date(r.max),
                accountId: r.accountId,
                accountName: accounts.find((a) => a.AwsAccountId === r.accountId)?.Name ?? r.accountId,
            })) ?? [];
        this.dateRange = {
            from: this.accountDateRange.reduce((a, b) => (a.from < b.from ? a : b)).from,
            to: this.accountDateRange.reduce((a, b) => (a.to > b.to ? a : b)).to,
        };
        this.historicalDaysAvailable =
            !this.dateRange.to || !this.dateRange.from ? 0 : differenceInCalendarDays(this.dateRange.to, this.dateRange.from);
    }

    private async loadForecastJobs() {
        const jobs = await this.jobSvc.queryJobs((q) =>
            q
                .where((b) =>
                    b.and(
                        b.model.Type!.eq('Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested'),
                        b.model.RequestedById!.eq(this.userId),
                        b.model.Status!.ne('Failed')
                    )
                )
                .sortDesc((b) => b.model.QueuedAt!)
        );
        this.forecastJobs = jobs as JobOfCostForecast[];
    }

    private async loadSavedForecasts() {
        const savedForecasts = await this.forecastPersistence.getMyCostForecasts();
        this.mySavedForecasts = [];
        this.othersSavedForecasts = [];
        for (const forecast of savedForecasts) {
            if (forecast.owner === this.userId) {
                this.mySavedForecasts.push(forecast.savedForecast);
            } else {
                this.othersSavedForecasts.push(forecast.savedForecast);
            }
        }
    }

    public getDefaultForecast() {
        const mostRecentAccessForecast = this.mySavedForecasts.sort((a, b) =>
            (a.dateAccessed ?? new Date(0)) > (b.dateAccessed ?? new Date(0)) ? -1 : 1
        )[0];
        const mostRecentJob = this.forecastJobs.slice().sort((a, b) => ((a.QueuedAt ?? new Date(0)) > (b.QueuedAt ?? new Date(0)) ? -1 : 1))[0];

        const bestForecastJobId =
            (mostRecentAccessForecast?.dateAccessed?.getTime() ?? 0) < (this.fmtSvc.toLocalDate(mostRecentJob?.QueuedAt).getTime() ?? 0)
                ? mostRecentJob.Id
                : mostRecentAccessForecast?.forecastJobId;

        return bestForecastJobId;
    }

    public async getForecastInfoByJobId(forecastJobId: string) {
        let mySavedForecast = this.mySavedForecasts.find((f) => f.forecastJobId === forecastJobId);
        let othersSavedForecast = this.othersSavedForecasts.find((f) => f.forecastJobId === forecastJobId);
        let job = this.forecastJobs.find((j) => j.Id === forecastJobId);
        const result = { job, savedForecast: mySavedForecast };

        if (!job && othersSavedForecast) {
            const sharedJob = await this.jobSvc.queryJobs((q) => q.where((b) => b.model.Id!.eq(forecastJobId)));
            if (sharedJob.length) {
                result.job = sharedJob[0] as JobOfCostForecast;
                result.savedForecast = othersSavedForecast;
            }
        }

        return result;
    }

    public async createSavedForecast(job: JobOfCostForecast) {
        if (job.RequestedById === this.userId) {
            await this.forecastPersistence.saveFromJob(job);
            await this.loadSavedForecasts();
            return this.mySavedForecasts.find((f) => f.forecastJobId === job.Id);
        }
        return null;
    }

    public async getJobStatus(hierarchyId: string) {
        const progress = await this.jobSvc.getHierarchyProgress(hierarchyId);
        const status = !progress ? null : this.jobSvc.getStatusInfo(progress);
        return status;
    }

    public async getTenantConfig() {
        return await getForecastGetForecastConfig();
    }

    public async getTenantForecastDetails() {
        const config = await this.getTenantConfig();
        const result = await queryBuilder<{ Date: number; IsHistorical: boolean }>()
            .select((b) => ({
                historicalFrom: b.aggIf(b.model.IsHistorical.eq(true), b.min(b.model.Date)),
                forecastTo: b.aggIf(b.model.IsHistorical.eq(false), b.max(b.model.Date)),
                forecastFrom: b.aggIf(b.model.IsHistorical.eq(false), b.min(b.model.Date)),
            }))
            .execute((q) => this.invoiceApi.queryForecastData(q));

        const ranges = (result.Results ?? [])[0];
        const historicalFrom = !ranges ? '' : this.fmtSvc.toJsonShortDate(this.fmtSvc.parseDateNoTime(ranges.historicalFrom as number));
        const forecastFrom = !ranges?.forecastFrom ? 0 : (ranges.forecastFrom as number);
        const forecastTo = !ranges?.forecastTo ? 0 : (ranges.forecastTo as number);
        const forecastDays = !forecastTo || !forecastFrom ? 0 : differenceInDays(forecastTo, forecastFrom);
        const historicalTo = !ranges ? '' : this.fmtSvc.toJsonShortDate(this.fmtSvc.parseDateNoTime(forecastFrom));

        return {
            historicalFrom,
            historicalTo,
            forecastDays,
            config,
            groups: config?.Dimensions ?? [],
        };
    }

    public async getTenantForecastStatus(): Promise<{ status: TenantForecastStatus; config: ForecastConfig }> {
        const config = await this.getTenantConfig();
        const notProcessedDate = '2000-01-01';
        let status: TenantForecastStatus = 'Ready';
        if (!config?.Status) {
            status = 'NotConfigured';
        } else {
            const modifiedAt = config.UpdatedAt ?? notProcessedDate;
            const { LastForecastFulfillDate } = config.Status;
            if (LastForecastFulfillDate === notProcessedDate || !LastForecastFulfillDate || modifiedAt > LastForecastFulfillDate) {
                status = 'NotReady';
            }
        }
        return { status, config };
    }

    public async saveTenantConfig(dimensions: string[]) {
        const result = await postForecastUpsertForecastConfig({
            Dimensions: dimensions,
        });
        return result;
    }

    public async startForecast(request: CostForecastRequest) {
        const result = await postForecastCreateCostForecast(request);

        await this.loadForecastJobs();

        return result;
    }

    public async rename(jobId: undefined | string, name: string) {
        if (jobId) {
            if (await this.forecastPersistence.rename(jobId, name)) {
                await this.reloadForecasts();
                this.forecastRenamed.emit();
            }
        }
    }

    public getForecastName(job: JobOfCostForecast) {
        return [...this.mySavedForecasts, ...this.othersSavedForecasts].find((f) => f.forecastJobId === job.Id)?.name;
    }

    public openConfig = () => this.configRequested.emit(true);
    public openLoader = () => this.loaderOpened.emit(true);
    public openShared = () => this.loaderOpened.emit('shared');
    public closeLoader = () => this.loaderOpened.emit(false);
    public closeDetails = () => this.detailsRequested.emit(undefined);
    public closeConfig = () => this.configRequested.emit(false);
    public loadDetails = (job: JobOfCostForecast) => {
        const savedForecast = [...this.mySavedForecasts, ...this.othersSavedForecasts].find((f) => f.forecastJobId === job.Id);
        this.detailsRequested.emit({ job, savedForecast });
    };
    public createForecast = () => this.detailsRequested.emit({});

    public async exportData(job: JobOfCostForecast | undefined, rangeInfo: ForecastRangeInfo, filters: IQueryExpr[], showHistorical: boolean) {
        const tenantConfig = job ? null : await this.getTenantConfig();
        const { groupOptions } = this.forecastPersistence.getGroupOptions(job?.Parameters ?? { Groups: tenantConfig?.Dimensions });
        filters = filters.slice();

        const startDate = showHistorical ? rangeInfo?.historicalRange.from : rangeInfo?.forecastRange.from;
        if (startDate) {
            filters.push({ Operation: 'gte', Operands: [{ Field: 'Date' }, { Value: this.fmtSvc.toJsonShortDate(startDate) }] });
        }
        if (job?.ResultDetail?.ForecastRequestJobId) {
            filters.push({ Operation: 'eq', Operands: [{ Field: 'JobId' }, { Value: job.ResultDetail.ForecastRequestJobId ?? '' }] });
        }
        const requestBody: ExportRequest = {
            CompanyId: job?.CompanyId ?? tenantConfig?.CompanyId,
            Target: ExportRequestTarget.CostForecastQuery,
            Type: ExportRequestType.Excel,
            DashboardConfig: {
                Layout: [
                    {
                        Data: {
                            ItemType: 'DataGrid',
                            DashboardItemName: 'Cost Forecast',
                            Filter: filters.length > 1 ? { Operation: 'and', Operands: filters } : filters[0],
                            Groups: groupOptions.map((g) => ({ GroupName: g.label, Field: g.value })),
                        },
                    },
                ],
                Name: 'Cost Forecast',
            },
        };
        await postExportExport(requestBody);
    }
}
