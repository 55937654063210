import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ResourceUsageChart } from '@root/Components/Resources/ResourceUsageChart';
import { Grid, SegmentedControl, Table } from '@mantine/core';
import { useEffect, useState } from 'react';

export function GetResourceUsage(props: { resourceType: string; resourceId: string }) {
    const [historyDaysBack, setHistoryDaysBack] = useState<string>('60');
    const [currDaysBack, setDaysBack] = useState<number>(60);
    const [dataFrequency, setDataFrequency] = useState<string>('60');
    const [currFrequency, setFrequency] = useState<number>(60);

    useEffect(() => {
        setDaysBack(Number(historyDaysBack));
    }, [historyDaysBack]);

    useEffect(() => {
        setFrequency(Number(dataFrequency));
    }, [dataFrequency]);

    var charts;
    if (props.resourceType == 'EC2 On Demand') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="CPU_Utilization"
                        unit="Percent"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Network_In"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Network_Out"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Memory Used"
                        unit="Percent"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={true}
                    />
                </Grid.Col>
            </Grid>
        );
    } else if (props.resourceType == 'RDS') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Read_IOPS"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Write_IOPS"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>

                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Freeable_Memory"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="CPU_Utilization"
                        unit="Percent"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Network_Receive_Throughput"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Network_Transmit_Throughput"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Database_Connections"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Active_Memory"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
            </Grid>
        );
    } else if (props.resourceType == 'EMR') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Is_Idle"
                        unit="Boolean"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
            </Grid>
        );
    } else if (props.resourceType == 'Redshift') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="CPU_Utilization"
                        unit="Percent"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Percentage_Disk_Space_Used"
                        unit="Percent"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Read_IOPS"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Write_IOPS"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Read_Throughput"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Write_Throughput"
                        unit="Bytes"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="Database_Connections"
                        unit="Count"
                        operation="avg"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
            </Grid>
        );
    } else if (props.resourceType == 'ELBV1') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="RequestCount"
                        unit="Count"
                        operation="sum"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
            </Grid>
        );
    } else if (props.resourceType == 'ELBV2') {
        charts = (
            <Grid>
                <Grid.Col sm={6} md={6} lg={6}>
                    <ResourceUsageChart
                        resourceType={props.resourceType}
                        resourceId={props.resourceId}
                        metric="RequestCount"
                        unit="Count"
                        operation="sum"
                        daysBack={currDaysBack}
                        frequency={currFrequency}
                        requiresCwAgent={false}
                    />
                </Grid.Col>
            </Grid>
        );
    }

    return (
        <div className="resourcesTableContainer">
            <Table>
                <thead>
                    <tr>
                        <td>History</td>
                        <td>Data Frequency</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="noPadding">
                            <SegmentedControl
                                key="DaysBack"
                                value={historyDaysBack}
                                onChange={setHistoryDaysBack}
                                defaultValue={historyDaysBack}
                                data={[
                                    { label: '30 Days', value: '30' },
                                    { label: '60 Days', value: '60' },
                                    { label: '180 Days', value: '180' },
                                    { label: '12 Months', value: '365' },
                                ]}
                            />
                        </td>
                        <td className="noPadding textRight">
                            <SegmentedControl
                                key="Frequency"
                                value={dataFrequency}
                                onChange={setDataFrequency}
                                defaultValue={dataFrequency}
                                data={[
                                    { label: '5 Minutes', value: '5' },
                                    { label: '15 Minutes', value: '15' },
                                    { label: '1 Hour', value: '60' },
                                    { label: '1 Day', value: '1440' },
                                ]}
                            />
                        </td>
                    </tr>
                </tbody>
                <br />
            </Table>
            {charts}
        </div>
    );
}

export function ResourceUsage() {
    return <>Resource Usage</>;
}
endpoint('resource', ResourceUsage, 'Resource Usage');
