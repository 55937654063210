import { observer, useObserver } from 'mobx-react';
import { AllocationRuleEditor } from '../Model';
import {
    Box,
    Text,
    useMantineTheme,
    Anchor,
    Group,
    TextInput,
    Popover,
    ActionIcon,
    Stack,
    Space,
    Loader,
    Button,
    Checkbox,
    Card,
} from '@mantine/core';
import { ChevronDown, ChevronUp, Plus } from 'tabler-icons-react';
import { useDi, useDiMemo } from '@root/Services/DI';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InvoiceAllocationRuleRuleType, InvoiceTaggingRule, NamedFilter, NamedFilterSet, ObjectQueryResult } from '@apis/Invoices/model';
import { InvoiceDateRange } from '@root/Components/Invoices/InvoiceDateRange';
import { getIntegrationGetIntegrations } from '@apis/Customers';
import { MetadataIntegrationConfig, Query } from '@apis/Resources/model';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { SchemaValueProvider, queryBuilder, groupExprs, cleanBoolExpr } from '@root/Services/QueryExpr';
import { QueryExpr, QueryResult, postMetadataIntegrationSearch } from '@apis/Resources';
import { Picker } from '@root/Components/Picker/Picker';
import { useDisclosure } from '@mantine/hooks';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { AnchorButton, useReadonlyInputStyles } from '@root/Design/Primitives';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { EditorCard, EditorCardWhiteWrap } from '../Design';
import { FillerSwitch } from '@root/Design/Filler';
import styled from '@emotion/styled';
import { useEvent, useToggle } from '@root/Services/EventEmitter';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { NamedFilterSetModel } from '../FilterSetEditor/NamedFilterSetModel';
import { FormatService } from '@root/Services/FormatService';
import { useCompanyFeatureSvc, AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export const UntaggedCostRuleCard = observer(function UntaggedCostCard({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const theme = useMantineTheme();
    const invoiceApi = useDiMemo(InvoiceApiService);
    const fmtSvc = useDi(FormatService);
    const companyFeatureSvc = useCompanyFeatureSvc();

    //general props
    const [loading, setLoading] = useState<boolean>(false);
    const [hasTagManager, setHasTagManager] = useState<boolean>(false);
    const [rule, setRule] = useState<InvoiceTaggingRule>(ruleEditor.rule as InvoiceTaggingRule);

    //date control props
    const constraint: { min?: Date; max?: Date } = useMemo(() => {
        const min = addMonths(startOfMonth(ruleEditor.month), -1);
        return { min, max: undefined };
    }, []);
    const [selectedDateRange, setSelectedDateRange] = useState<{ from?: Date; to?: Date }>({
        from: startOfMonth(ruleEditor.month),
        to: endOfMonth(ruleEditor.month),
    });

    const schemaSvc = ruleEditor.schemaSvc;

    //filter props
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const { getFilters, setFilters, onFilterChanged } = useMemo(
        () => NamedFilterSetModel.createSimpleSet(rule as InvoiceTaggingRule, 'TargetFilters'),
        [rule]
    );
    const filters = getFilters();
    useEvent(
        filterModel?.filtersChanged,
        useCallback(() => setFilters(filterModel?.getFilters() ?? []), [filterModel])
    );
    useEvent(onFilterChanged);
    const cleanedExpr = cleanBoolExpr({ Operation: 'and', Operands: filters });
    useEffect(() => {
        if (rule.TargetFilters) {
            rule.TargetFilters.InclusionRules = cleanedExpr ? [{ Filter: cleanedExpr }] : [];
        }
        ruleEditor.rulePropsChanged.emit();
    }, [JSON.stringify(cleanedExpr)]);

    const [noOverwrite, setNoOverwrite] = useState(!rule.AllowOverwrite);

    useEffect(() => {
        rule.AllowOverwrite = !noOverwrite;
        ruleEditor.rulePropsChanged.emit();
    }, [noOverwrite, rule]);

    //initial load
    useEffect(() => {
        setLoading(true);

        //set selected month
        if (rule?.SelectedMonth) {
            const selectedMonth = fmtSvc.parseDateNoTime(rule.SelectedMonth);
            const from = startOfMonth(selectedMonth);
            const to = endOfMonth(selectedMonth);
            rule.SelectedMonth = fmtSvc.toJsonShortDate(selectedMonth);
            setSelectedDateRange({ from, to });
        } else {
            rule.SelectedMonth = fmtSvc.toJsonShortDate(ruleEditor.month);
        }

        //set tag key
        if (!rule?.TagKey) {
            rule.TagKey = ruleEditor.allocDim.DimensionName;
        }

        (async () => {
            //init services
            const companyFeatures = await companyFeatureSvc.getFeatures();

            //check tag manager sub
            setHasTagManager(companyFeatures.checkFeature('Compliance', AppFeatureNames.TagManager));
        })().finally(() => setLoading(false));
    }, []);

    const changeDateRange = (range: { from?: Date; to?: Date }) => {
        const rule = ruleEditor.rule as InvoiceTaggingRule;
        rule.SelectedMonth = fmtSvc.toJsonShortDate(range.from ?? ruleEditor.month);
        setSelectedDateRange(range);
    };

    const datasource = useMemo(
        () =>
            function query<T>(query: Query) {
                return invoiceApi.query(query, {}) as Promise<QueryResult<T>>;
            },
        [selectedDateRange]
    );

    const valueProvider = useMemo(() => new SchemaValueProvider(schemaSvc, datasource), [schemaSvc]);
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schemaSvc!), [schemaSvc]);

    const addFilter = useCallback(() => {
        filterModel?.addEmptyFilter(true);
    }, [filterModel]);

    const {
        classes: { readonly },
    } = useReadonlyInputStyles();

    const dimName: string = ruleEditor.showbackSvc.getDimensionName(ruleEditor.allocDim);

    return (
        <>
            <EditorCardWhiteWrap>
                <FillerSwitch loading={loading}>
                    {() => (
                        <Stack spacing={12}>
                            <EditorCard color="gray" title="Fix Tags" wrapped>
                                <Box sx={{ display: 'block' }}>
                                    <Text component={SectionDescriptionText}>
                                        This updates all invoice line items to include the selected allocation dimension tag value.
                                    </Text>
                                    <Text component={SectionDescriptionText}>
                                        <Anchor>Tag Manager</Anchor> updates the resource with the tag value.
                                    </Text>
                                </Box>
                                <Space h="sm" />
                                <Group position="left">
                                    <Box>
                                        <Text sx={{ fontWeight: 'bold', color: theme.colors.gray[6] }}>Allocation Dimension Key</Text>
                                        <TextInput className={readonly} data-atid="DimensionKeyDisabledInput" disabled={true} value={dimName} />
                                    </Box>
                                    <Box>
                                        <Text sx={{ fontWeight: 'bold', color: theme.colors.gray[6] }}>Value</Text>
                                        <Box sx={{ minWidth: 160, flex: '1' }}>
                                            <TagValuePicker ruleEditor={ruleEditor} dateRange={selectedDateRange} />
                                        </Box>
                                    </Box>
                                </Group>
                            </EditorCard>
                            <EditorCard color="gray" title="Apply To" wrapped>
                                <Card p="sm" radius="md">
                                    <Checkbox
                                        onChange={(e) => setNoOverwrite(e.currentTarget.checked)}
                                        checked={noOverwrite}
                                        label={`Only line items that have no ${dimName}`}
                                    />
                                </Card>
                                <Space h="md" />
                                <DataFilters
                                    filters={filters}
                                    onChange={setFilters}
                                    valueProvider={valueProvider}
                                    fieldInfoProvider={fieldInfoProvider!}
                                    renderFieldPicker={(select) => (
                                        <FieldPicker mode="single" selections={[]} schema={schemaSvc!} onChange={([f]) => select(f.path)} />
                                    )}
                                    onModelLoaded={setFilterModel}
                                    dataFiltersAsLineItem
                                />
                                <Button variant="outline" size="xs" radius="xl" leftIcon={<Plus size={16} />} onClick={addFilter}>
                                    Add Filter
                                </Button>
                            </EditorCard>
                        </Stack>
                    )}
                </FillerSwitch>
            </EditorCardWhiteWrap>
        </>
    );
});

function TagValuePicker({
    ruleEditor,
    dateRange,
    setTagValue,
}: {
    ruleEditor: AllocationRuleEditor;
    dateRange: { from?: Date; to?: Date };
    setTagValue?: (value: string) => void;
}) {
    const [loading, setLoading] = useState<boolean>(false);
    const [availableValues, setAvailableValues] = useState<string[]>([]);
    const [opened, { close, toggle, open }] = useToggle(false);
    const arrowRef = useRef<HTMLButtonElement>(null);
    const rule = ruleEditor.rule as InvoiceTaggingRule;
    const [filterText, setFilterText] = useState('');

    const tagValue = useObserver(() => rule?.TagValue ?? '');

    useEffect(() => {
        setLoading(true);
        loadExistingDimensionValues()
            .then(setAvailableValues)
            .finally(() => setLoading(false));
        ruleEditor.setTypeErrors(InvoiceAllocationRuleRuleType.UntaggedCosts, []);
    }, [ruleEditor.allocDim?.DimensionName, ruleEditor.rule]);

    useEffect(() => {
        if (rule.TagValue && rule.TagValue != '') {
            ruleEditor.setTypeErrors(InvoiceAllocationRuleRuleType.UntaggedCosts, []);
        } else {
            ruleEditor.setTypeErrors(InvoiceAllocationRuleRuleType.UntaggedCosts, ['Please select a value for the tag.']);
        }
    }, [rule.TagValue]);

    const handleValuePicklistSelect = useCallback(
        (items: { value: string }[]) => {
            rule.TagValue = items[0]?.value;
            setTagValue?.(items[0]?.value);
            ruleEditor.rulePropsChanged.emit();
            close();
        },
        [close, rule]
    );

    const handleValueInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            rule.TagValue = e.currentTarget.value;
            setTagValue?.(e.currentTarget.value);
            setFilterText(e.currentTarget.value.toLocaleLowerCase());
            ruleEditor.rulePropsChanged.emit();
        },
        [rule, setFilterText]
    );

    const pickerProps = useMemo(
        () => ({
            nameAccessor: ({ value }: { value: string }) => value,
            selections: [],
        }),
        []
    );
    const values = useMemo(() => availableValues.map((x) => ({ lcValue: x.toLocaleLowerCase(), value: x })), [availableValues]);
    const filteredValues = useMemo(() => {
        const lcTagValue = filterText;
        return values.filter((value) => value.lcValue.toLowerCase().includes(lcTagValue));
    }, [filterText, values]);
    const openPicker = useCallback(() => {
        setFilterText('');
        open();
    }, [open, setFilterText]);

    return (
        <Popover opened={opened} onClose={close} position="bottom" offset={0} shadow="md" withinPortal>
            <Popover.Dropdown p={0}>
                <Picker
                    mode="single"
                    height={300}
                    minimizeHeight
                    items={filteredValues}
                    selections={pickerProps.selections}
                    onChange={handleValuePicklistSelect}
                    nameAccessor={pickerProps.nameAccessor}
                    noFilter
                ></Picker>
            </Popover.Dropdown>
            <Popover.Target>
                <TextInput
                    onFocus={openPicker}
                    placeholder="Select or type new"
                    onChange={handleValueInputChange}
                    autoComplete="off"
                    rightSection={
                        loading ? (
                            <Loader size={16} />
                        ) : (
                            <ActionIcon ref={arrowRef} tabIndex={-1} onClick={toggle}>
                                {opened ? <ChevronUp /> : <ChevronDown />}
                            </ActionIcon>
                        )
                    }
                    value={tagValue}
                    data-atid="DimensionValueInput"
                />
            </Popover.Target>
        </Popover>
    );

    async function loadExistingDimensionValues() {
        return await ruleEditor.showbackSvc.getDimensionValueOptions(ruleEditor.allocDim, dateRange);
    }
}

export const SectionDescriptionText = styled.div`
    font-size: 13px;
    color: ${(p) => p.theme.colors.gray[6]};
    align-self: left;
`;
