import { BaseAzureResource, BaseResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Box, Divider, Table, Tabs, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight } from 'tabler-icons-react';

export function AzureResourceDetails({ resourceId }: { resourceId: string; onModelLoaded: (resource: BaseResource) => void }) {
    const resourceSvc = useDi(ResourceService);
    const [resource, setResource] = useState<BaseAzureResource | null>();
    useEffect(() => {
        resourceSvc.getPlatformResource(resourceId, 'Azure').then(setResource);
    }, []);
    const loading = resource === undefined;
    const notFound = resource === null;

    return loading ? <></> : notFound ? <ResourceNotFound /> : <AzureResourceDetailsContent resource={resource} />;
}

export function ResourceNotFound() {
    return <></>;
}

function AzureResourceDetailsContent({ resource }: { resource: BaseAzureResource }) {
    const theme = useMantineTheme();

    return (
        <Tabs
            defaultValue="Details"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                ['[role=tabpanel]']: { overflow: 'auto', flex: 1, minHeight: 0, background: theme.colors.gray[2] },
            }}
        >
            <Tabs.List px="lg">
                <Tabs.Tab key="Details" value="Details">
                    Details
                </Tabs.Tab>
                <Tabs.Tab key="Tags" value="Tags">
                    Tags
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="Details">
                <ResourceDetailOverview resource={resource} />
            </Tabs.Panel>
            <Tabs.Panel value="Tags">
                <ResourceDetailTags resource={resource} />
            </Tabs.Panel>
        </Tabs>
    );
}

class PropertyGridItem {
    public children: null | PropertyGridItem[];
    public type: string;
    public value: unknown;

    public get hasChildren() {
        return this.children !== null;
    }
    public get expandable() {
        return this.children && this.children.length > 0;
    }
    public itemType: 'property' | 'index';

    public constructor(public target: Record<string | number, unknown> | Array<unknown>, public property: string | number) {
        this.value = target instanceof Array ? target[property as number] : target[property];
        this.itemType = typeof property === 'string' ? 'property' : 'index';
        this.children = this.resolveChildren(this.value);
        this.type = typeof this.value;
    }
    private resolveChildren(value: unknown) {
        if (typeof value === 'object') {
            if (value instanceof Array) {
                return value.map((_, index) => new PropertyGridItem(value, index));
            } else if (value === null) {
                return null;
            } else {
                return PropertyGridItem.create(value as Record<string, unknown>);
            }
        }
        return null;
    }

    public static create(target: Record<string, unknown>) {
        return Object.keys(target).map((key) => new PropertyGridItem(target, key));
    }
}

function PropertyGridRow({ item }: { item: PropertyGridItem }) {
    const formatSvc = useDi(FormatService);
    const [opened, { toggle }] = useDisclosure(false);

    return item.itemType === 'index' ? (
        <PropertyGridArrayItem>
            <PropertyGridIndex>
                <Text>{(item.property as number) + 1}.</Text>
            </PropertyGridIndex>
            <PropertyGridArrayItemValue>
                {item.type !== 'object' ? (
                    <PropertyGridValueEl>
                        <ValueRenderer item={item} />
                    </PropertyGridValueEl>
                ) : null}
                {item.children && item.children.map((p, i) => <PropertyGridRow key={i} item={p} />)}
            </PropertyGridArrayItemValue>
        </PropertyGridArrayItem>
    ) : (
        <>
            <PropertyGridRowEl mode={item.expandable ? 'expandable' : 'disabled'} onClick={toggle}>
                <PropertyGridExpander mode={item.expandable ? 'visible' : item.hasChildren ? 'disabled' : 'hidden'}>
                    {opened && item.expandable ? <ChevronDown /> : <ChevronRight />}
                </PropertyGridExpander>
                <PropertyGridText>{formatSvc.userFriendlyCamelCase(item.property as string)}</PropertyGridText>
                <PropertyGridValueEl>
                    <ValueRenderer item={item} />
                </PropertyGridValueEl>
            </PropertyGridRowEl>
            {item.children && opened && item.expandable ? (
                <PropertyGridSection>
                    {item.children.map((p, i) => (
                        <PropertyGridRow key={i} item={p} />
                    ))}
                </PropertyGridSection>
            ) : null}
        </>
    );
}

const PropertyGridArrayItem = styled.div`
    display: flex;
    border-top: solid 1px ${(p) => p.theme.colors.gray[3]};
    flex: 1;
`;
const PropertyGridArrayItemValue = styled.div`
    &:first-of-type {
        border-top: none;
    }
    flex: 1;
`;
const PropertyGridSection = styled.div`
    margin: ${(p) => p.theme.spacing.xs}px 0 ${(p) => p.theme.spacing.md}px ${(p) => p.theme.spacing.md * 2}px;
    border: solid 1px ${(p) => p.theme.colors.gray[3]};
    border-right: none;
    flex: 1;
    background: ${(p) => p.theme.white};
`;
const PropertyGridRowEl = styled.div<{ mode?: 'expandable' | 'disabled' }>`
    display: flex;
    align-items: stretch;
    cursor: ${(p) => (p.mode === 'expandable' ? 'pointer' : undefined)};
    border-top: solid 1px ${(p) => p.theme.colors.gray[3]};
    &:first-of-type {
        border-top: none;
    }
    &:hover {
        background: ${(p) => (p.mode === 'expandable' ? p.theme.colors.primary[2] : p.mode === 'disabled' ? p.theme.colors.gray[1] : undefined)};
    }
`;
const PropertyGridExpander = styled.div<{ mode: 'visible' | 'disabled' | 'hidden' }>`
    width: 30px;
    visibility: ${(p) => (p.mode !== 'hidden' ? 'visible' : 'hidden')};

    display: flex;
    align-items: center;
    justify-content: center;
`;
const PropertyGridText = styled.div`
    padding: ${(p) => p.theme.spacing.xs / 2}px;
    flex: 1;
    font-weight: bold;
`;
const PropertyGridIndex = styled.div`
    flex: 0;
    border-right: solid 1px ${(p) => p.theme.colors.gray[3]};
    padding: ${(p) => p.theme.spacing.xs / 2}px;
`;
const PropertyGridValueEl = styled.div`
    flex: 1;
    min-width: 600px;
    word-break: break-word;
    padding: ${(p) => p.theme.spacing.xs / 2}px;
`;

function ValueRenderer({ item }: { item: PropertyGridItem }) {
    const formatSvc = useDi(FormatService);
    switch (typeof item.value) {
        case 'number':
            return <>{formatSvc.formatInt(item.value)}</>;
        case 'undefined':
        case 'object':
            if (!item.value) {
                return <>Nothing</>;
            } else if (item.value instanceof Array) {
                return <>({item.value.length})</>;
            } else {
                return (
                    <Text color="dimmed" italic>
                        {item.children?.length ? 'More details' : 'Empty'}
                    </Text>
                );
            }
        case 'string':
            return <>{item.value}</>;
        default:
            return <></>;
    }
}

function PropertyGrid({ target }: { target: Record<string, unknown> }) {
    const properties = useMemo(() => PropertyGridItem.create(target), [target]);
    const theme = useMantineTheme();
    return (
        <PropertyGridSection>
            {properties.map((p, i) => (
                <PropertyGridRow key={i} item={p} />
            ))}
        </PropertyGridSection>
    );
}

function ResourceDetailOverview({ resource }: { resource: BaseAzureResource }) {
    const configuration = useMemo(() => {
        const result = { ...(resource[resource.ResourceType?.replace(/\./g, '_') ?? ''] as Record<string, unknown>) };
        if ('tags' in result) {
            delete result.tags;
        }
        return result;
    }, [resource]);
    return <PropertyGrid target={configuration} />;
}

function ResourceDetailTags({ resource }: { resource: BaseAzureResource }) {
    const tags = resource.Tags ?? [];
    return !tags.length ? (
        <Text>No tags</Text>
    ) : (
        <PropertyGridSection>
            <PropertyGridRowEl>
                <PropertyGridText>Key</PropertyGridText>
                <PropertyGridValueEl>
                    <Text weight="bold">Value</Text>
                </PropertyGridValueEl>
            </PropertyGridRowEl>
            {tags.map((t) => (
                <PropertyGridRowEl key={t.Key} mode="disabled">
                    <PropertyGridText>{t.Key}</PropertyGridText>
                    <PropertyGridValueEl>{t.Value}</PropertyGridValueEl>
                </PropertyGridRowEl>
            ))}
        </PropertyGridSection>
    );
}
function ResourceDetailConfiguration({ resource }: { resource: BaseAzureResource }) {
    return <></>;
}
