import { usePostObservabilityQuery } from '@apis/Resources';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { KpiLineChart } from '../Charts/LineChart';
import { ResourceChart, MetricName, MinAvgMaxContainer, MinAvgMax, DivClear, DivCenterMarginTop } from '@root/Design/ResourceContainer';

export function ResourceUsageChart(props: {
    resourceType: string;
    resourceId: string;
    metric: string;
    unit: string;
    operation: string;
    daysBack: number;
    frequency: number;
    requiresCwAgent: boolean;
}) {
    const { data, isLoading, mutate: runQuery } = usePostObservabilityQuery();
    const company = useCompany();
    var min = 0;
    var avg = 0;
    var max = 0;
    var unit = '';

    useEffect(() => {
        var endTime = new Date();
        endTime.setDate(endTime.getDate() + 1); // Make sure that we get all times during the current day
        var startTime = new Date();
        startTime.setDate(startTime.getDate() - props.daysBack);
        const frequency = props.frequency * 60;

        var StartTime = startTime.getFullYear() + '-' + ('00' + (startTime.getMonth() + 1)).slice(-2) + '-' + ('00' + startTime.getDate()).slice(-2);
        var EndTime = endTime.getFullYear() + '-' + ('00' + (endTime.getMonth() + 1)).slice(-2) + '-' + ('00' + endTime.getDate()).slice(-2);
        runQuery({
            data: {
                Requests: [
                    {
                        ResourceId: props.resourceId,
                        ResourceType: props.resourceType,
                        Metric: props.metric.replace(/_/g, ''),
                        Operation: props.operation,
                    },
                ],
                StartTime: StartTime,
                EndTime: EndTime,
                Period: frequency,
            },
            params: { companyId: company?.Id },
        });
    }, [props.frequency, props.daysBack]);

    var lineChart;
    if (isLoading) {
        lineChart = (
            <>
                <DivCenterMarginTop>Loading...</DivCenterMarginTop>
            </>
        );
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            lineChart = (
                <>
                    <DivCenterMarginTop>No data available</DivCenterMarginTop>
                </>
            );
        } else {
            min = 1000000;
            var records: any = [];
            var total = 0;
            for (var j = 0; j < data.Results.length; j++) {
                var yVal = data.Results[j].Values![0];
                if (yVal < min) {
                    min = yVal;
                }
                total += yVal;
                if (yVal > max) {
                    max = yVal;
                }

                var r = { id: props.metric, x: data.Results[j].Time, y: yVal };
                records.push(r);
            }
            if (data.Results.length > 0) {
                avg = total / (data.Results.length - 1);
            }
            min = Math.round(min * 10) / 10;
            avg = Math.round(avg * 10) / 10;
            max = Math.round(max * 10) / 10;

            var tickValues = [];
            if (data.Results.length > 10) {
                var l = data.Results.length - 1;
                tickValues.push(data.Results[0].Time);
                tickValues.push(data.Results[Math.round(l / 4)].Time);
                tickValues.push(data.Results[Math.round(l / 2)].Time);
                tickValues.push(data.Results[Math.round((l / 4) * 3)].Time);
                tickValues.push(data.Results[l].Time);
            }

            if (props.unit == 'Percent') {
                unit = '%';
                for (var j = 0; j < records.length; j++) {
                    records[j].y = Math.round(records[j].y * 10) / 10;
                }
            } else if (props.unit == 'Bytes') {
                unit = 'kB';
                if (max > 999) {
                    min /= 1024;
                    avg /= 1024;
                    max /= 1024;
                    unit = 'kB';
                    if (max > 999) {
                        min /= 1024;
                        avg /= 1024;
                        max /= 1024;
                        unit = 'MB';
                        if (max > 999) {
                            min /= 1024;
                            avg /= 1024;
                            max /= 1024;
                            unit = 'GB';
                        }
                    }
                }

                for (var j = 0; j < records.length; j++) {
                    if (unit == 'kB') {
                        records[j].y /= 1024;
                    }

                    if (unit == 'MB') {
                        records[j].y = records[j].y / 1024 / 1024;
                    }

                    if (unit == 'GB') {
                        records[j].y = records[j].y / 1024 / 1024 / 1024;
                    }
                    records[j].y = Math.round(records[j].y * 10) / 10;
                }

                min = Math.round(min * 10) / 10;
                avg = Math.round(avg * 10) / 10;
                max = Math.round(max * 10) / 10;
            } else {
                for (var j = 0; j < records.length; j++) {
                    records[j].y = Math.round(records[j].y * 10) / 10;
                }
            }

            lineChart = (
                <>
                    <KpiLineChart
                        data={records}
                        groups={['id', 'x']}
                        values={['y']}
                        settings={{ tickValues, yMax: props.unit == 'Percent' ? 100 : 0 }}
                    />
                </>
            );
        }
    }

    return (
        <ResourceChart key={props.metric + '_' + props.frequency + '_' + props.daysBack}>
            <MetricName>
                {props.metric.replace(/_/g, ' ')} {unit != '' ? '(' + unit + ')' : ''}
            </MetricName>
            <MinAvgMaxContainer>
                <MinAvgMax>
                    <div>Min</div>
                    <div>
                        {min}
                        {unit}
                    </div>
                </MinAvgMax>
                <MinAvgMax>
                    <div>Avg</div>
                    <div>
                        {avg}
                        {unit}
                    </div>
                </MinAvgMax>
                <MinAvgMax>
                    <div>Max</div>
                    <div>
                        {max}
                        {unit}
                    </div>
                </MinAvgMax>
            </MinAvgMaxContainer>
            <DivClear></DivClear>
            {lineChart}
        </ResourceChart>
    );
}
