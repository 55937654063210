import { QueryExpr } from '@apis/Resources';
import { Query, QuerySelectExpr } from '@apis/Resources/model';
import { BarChart, BarChartSettings } from '@root/Components/Charts/BarChart';
import { ChartTypes } from '@root/Components/Charts/Common';
import { GaugeChart, GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { GridChart, GridChartSettings, GridChartProps } from '@root/Components/Charts/GridChart';
import { KpiChart, KpiChartSettings } from '@root/Components/Charts/KpiChart';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { StandardChartProps } from '@root/Components/Charts/Models';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ComponentType, useEffect, useMemo } from 'react';
import { container } from 'tsyringe';
import { QueryDatasource } from '../Models';
import { ListChart, ListChartSettings } from '@root/Components/Charts/ListChart';
import { MapChart, MapChartSettings } from '@root/Components/Charts/MapChart';

interface ChartSetup<TSettings, TProps extends StandardChartProps<any, any>> {
    Component: ComponentType<TProps>;
    getDefaults: (dataSource?: QueryDatasource) => TSettings;
    prepareQuery?: (config: ChartConfig, globalFilters: QueryExpr[]) => Query | Query[] | undefined;
    dataKey?: (config: ChartConfig, globalFilters: QueryExpr[]) => string;
    cleanConfig?: (config: ChartConfig, dataSource: QueryDatasource) => void;
    getProps?: (config: ChartConfig, dataSource: QueryDatasource, globalFilters: QueryExpr[], saveLayout: () => void) => {};
}

export const chartInfo = new Map<ChartTypes, ChartSetup<any, any>>([
    [
        'pie',
        {
            Component: PieChart,
            getDefaults: () =>
                ({
                    margin: { bottom: 60, top: 60, left: 120, right: 120 },
                    threshold: 0,
                    valueFormat: undefined,
                    topN: undefined,
                } as PieChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'bar',
        {
            Component: BarChart,
            getDefaults: () =>
                ({
                    labelAngle: -50,
                    margin: { bottom: 80, left: 60, right: 60, top: 10 },
                    orientation: 'Vertical',
                    format: undefined,
                    topN: undefined,
                    sortBy: undefined,
                    sort: undefined,
                } as BarChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'gauge',
        {
            Component: GaugeChart,
            getDefaults: () =>
                ({
                    angle: 'large',
                    margin: { bottom: -50, left: 30, right: 30, top: 60 },
                    danger: undefined,
                    max: undefined,
                    target: undefined,
                    format: undefined,
                    topN: undefined,
                } as GaugeChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'map',
        {
            Component: MapChart,
            getDefaults: () => ({} as MapChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'list',
        {
            Component: ListChart,
            getDefaults: () => ({} as ListChartSettings<any>),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'line',
        {
            Component: LineChart,
            getDefaults: () =>
                ({
                    margin: { bottom: 70, left: 60, right: 20, top: 20 },
                    labelAngle: -50,
                    interval: 'month',
                    format: 'int',
                    stacked: false,
                    topN: undefined,
                } as LineChartSettings),
            dataKey: (config, globalFilters) => {
                const settings = config.settings as LineChartSettings;
                return JSON.stringify([config.groups, config.filters, config.values, settings.interval, globalFilters]);
            },
            prepareQuery: (config: ChartConfig, globalFilters: QueryExpr[]) => {
                let [group1] = config.groups;
                const settings = config.settings as LineChartSettings;
                if (settings.interval) {
                    const fmtSvc = container.resolve(FormatService);
                    group1 = {
                        ...group1,
                        Expr: {
                            operation: 'truncdate',
                            operands: [{ Value: settings.interval }, group1.Expr, { Value: fmtSvc.getTzOffsetHours() }],
                        },
                    };
                }
                const query: Query = {
                    Select: [group1, ...config.groups.slice(1), ...config.values],
                };
                const chartFilters = config.filters ?? [];
                const filters = [...globalFilters, ...chartFilters];

                if (filters.length) {
                    query.Where = { operation: 'and', operands: filters };
                }
                return query;
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultHistogram();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'kpi',
        {
            Component: KpiChart,
            getDefaults: () =>
                ({
                    labels: [''],
                    valueFilters: [[]],
                    format: [undefined],
                } as KpiChartSettings),
            prepareQuery: (config: ChartConfig, globalFilters: QueryExpr[]) => {
                return config.values.map((value, i) => {
                    const settings = config.settings as KpiChartSettings;
                    value = structuredClone(value);
                    const query: Query = {
                        Select: [value],
                    };
                    const chartFilters = config.filters ?? [];
                    const filters = [...globalFilters, ...chartFilters];
                    if (settings?.valueFilters?.[i]?.length) {
                        if (value.Expr && 'Operation' in value.Expr && value.Expr.Operation === 'percent') {
                            value.Expr.Operands = [{ operation: 'and', operands: settings?.valueFilters?.[i] }];
                        } else {
                            filters.push(...settings?.valueFilters[i]);
                        }
                    }
                    if (filters.length) {
                        query.Where = { operation: 'and', operands: filters };
                    }
                    return query;
                });
            },
            dataKey: (config: ChartConfig, globalFilters: QueryExpr[]) => {
                const settings = config.settings as KpiChartSettings;
                return JSON.stringify([config.values, config.filters, settings.valueFilters, globalFilters]);
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds, 1, 1);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'grid',
        {
            Component: GridChart,
            getDefaults: (ds): GridChartSettings => {
                const group = ds?.getDefaultGroup();
                const value = ds?.getDefaultValue();
                return !ds || !group || !value
                    ? {
                          columns: [],
                          state: { columns: [], filters: [], sort: [] },
                      }
                    : {
                          columns: [
                              {
                                  type: 'string',
                                  select: group,
                                  id: group?.Alias ?? 'group',
                              },
                              {
                                  type: 'number',
                                  select: value,
                                  id: value?.Alias ?? 'value',
                              },
                          ],
                          state: {
                              columns: [
                                  {
                                      id: group?.Alias ?? 'group',
                                      width: 160,
                                  },
                                  {
                                      id: value?.Alias ?? 'value',
                                      width: 160,
                                  },
                              ],
                              filters: [],
                              sort: [],
                          },
                      };
            },
            prepareQuery: () => undefined,
            getProps: (config, datasource, globalFilters, saveLayout) => {
                const settings = config.settings as GridChartSettings;
                return {
                    title: config.title,
                    globalFilters,
                    datasource,
                    onStateChange: (state) => {
                        settings.state = state;
                        saveLayout();
                    },
                } as GridChartProps<any>;
            },
        },
    ],
]);

export class ChartRendererModel {
    public loading = new EventEmitter(false);
    public data: unknown[] = [];
    public onDataChanged = EventEmitter.empty();
    public globalFilters: QueryExpr[] = [];
    public _saveLayout?: () => void;
    private datakey: string = '';
    public constructor(public config: ChartConfig, public datasource: QueryDatasource) {}

    public updateSaveHandler(saveLayout?: () => void) {
        this._saveLayout = saveLayout;
    }

    public getGroups() {
        return this.config.groups.map((g) => g.Alias ?? '');
    }
    public getValues() {
        return this.config.values.map((v) => v.Alias ?? '');
    }
    public getComponent() {
        return chartInfo.get(this.config.chartType)?.Component!;
    }
    public getSettings() {
        return this.config.settings ?? chartInfo.get(this.config.chartType)?.getDefaults(this.datasource);
    }
    public tryReloadData() {
        if (this.datakey !== this.getDataKey()) {
            this.load();
        }
    }
    public getDataKey() {
        const type = chartInfo.get(this.config.chartType);
        return type?.dataKey
            ? type.dataKey(this.config, this.globalFilters)
            : JSON.stringify([this.config.groups, this.config.filters, this.config.values, this.globalFilters]);
    }
    public async load() {
        try {
            this.loading.emit(true);
            this.datakey = this.getDataKey();
            const queries = this.prepareQuery();
            if (queries) {
                const data =
                    queries instanceof Array
                        ? await Promise.all(queries!.map((q) => this.datasource.source(q)))
                        : await this.datasource.source(queries);
                this.data =
                    data instanceof Array
                        ? data.reduce((result, item) => {
                              result.push(item.Results ?? []);
                              return result;
                          }, [] as unknown[][])
                        : data.Results ?? [];
                this.onDataChanged.emit();
            } else {
                this.datakey = '';
            }
        } finally {
            this.loading.emit(false);
        }
    }
    public getProps() {
        const type = chartInfo.get(this.config.chartType);
        return type?.getProps?.(this.config, this.datasource, this.globalFilters, this.saveLayout) ?? {};
    }
    private prepareQuery() {
        const type = chartInfo.get(this.config.chartType);
        if (type && type.prepareQuery) {
            return type.prepareQuery(this.config, this.globalFilters);
        } else {
            const query: Query = {
                Select: [...this.config.groups, ...this.config.values],
            };
            const chartFilters = this.config.filters ?? [];
            const filters = [...this.globalFilters, ...chartFilters];
            if (filters.length) {
                query.Where = { operation: 'and', operands: filters };
            }
            return query;
        }
    }
    private saveLayout = () => {
        this._saveLayout?.();
    };
}

export interface ChartConfig {
    type: 'chart';
    chartType: ChartTypes;
    groups: QuerySelectExpr[];
    values: QuerySelectExpr[];
    filters?: QueryExpr[];
    datasourceName: string;
    title: string;
    settings?: {};
}
interface ChartRendererProps {
    config: ChartConfig;
    datasource: QueryDatasource;
    filters: QueryExpr[];
    rerenderNeeded?: EventEmitter<any>;
    saveLayout?: () => void;
    resized?: EventEmitter<any>;
}

export function ChartRenderer({ config, datasource, filters, rerenderNeeded, saveLayout, resized }: ChartRendererProps) {
    const model = useMemo(() => new ChartRendererModel(config, datasource), [config]);
    useEffect(() => {
        model.datasource = datasource;
        model.globalFilters = filters;
        model.tryReloadData();
    }, [datasource, filters]);
    useEffect(() => {
        model.tryReloadData();
    }, [model.getDataKey(), model.config.chartType]);
    useEffect(() => {
        model.updateSaveHandler(saveLayout);
    }, [saveLayout]);

    useEvent(model.onDataChanged);
    useEvent(rerenderNeeded);
    const loading = useEventValue(model.loading);
    const Component = model.getComponent();

    return loading ? null : (
        <Component
            data={model.data}
            groups={model.getGroups()}
            values={model.getValues()}
            resized={resized}
            {...{ settings: model.getSettings(), ...model.getProps() }}
        />
    );
}

function resetSelect(config: ChartConfig, datasource: QueryDatasource, expectedGroups: number, expectedValues: number) {
    config.groups.splice(expectedGroups, Infinity);
    if (config.groups.length < expectedGroups) {
        config.groups.push(datasource.getDefaultGroup());
    }
    config.values.splice(expectedValues, Infinity);
    if (config.values.length < expectedValues) {
        config.values.push(datasource.getDefaultGroup());
    }
}
