import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { IdleResourceGrid, IdleRunningStatusCell, IdleUsedByOtherResourceCell } from '../Components/IdleResourcesGrid';
import { IdleResourcesPage } from '../Components/IdleResourcesPage';
import { IdleResourcesService } from '../Services/IdleResourcesService';
import { useDi } from '@root/Services/DI';
import { ColumnConfig, GridColumnState } from '@root/Components/DataGrid/Models';
import { useMemo, useState } from 'react';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { BaseResource, QueryBasedResourceSelection } from '@root/Components/Resources/ResourcesGrid';
import { useEvent } from '@root/Services/EventEmitter';
import { IdleResourcesActionCard } from '../Components/IdleResourcesActionCard';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { postIdleActionStartIdleResources, postIdleActionStopIdleResources, postResourcesQuery } from '@apis/Resources';
import { SelectedIdleResourceRequest } from '@apis/Resources/model';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { ThemeIcon, useMantineTheme } from '@mantine/core';
import { ListCheck } from 'tabler-icons-react';
import { CustomColors } from '@root/Design/Themes';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';

export function IdleEc2Content() {
    const company = useCompany();
    const idleResourceService = useDi(IdleResourcesService);
    const notificationSvc = useDi(NotificationService);
    const theme = useMantineTheme();
    // should be based on existence of MemoryUtilization field
    const includeMemory = false;
    let resourceColumns: GridColumnState[] = [
        { id: 'Idle.RunningStatusEC2', width: 125 },
        { id: 'Metrics.IdleResources.CpuUtilization', width: 140 },
        { id: 'Metrics.IdleResources.NetworkIn', width: 130 },
        { id: 'Metrics.IdleResources.NetworkOut', width: 130 },
        { id: 'Idle.HasASGParent', width: 145 },
        { id: 'Idle.HasEMRParent', width: 145 },
        { id: 'Idle.HasEKSParent', width: 145 },
        { id: 'Idle.HasELBParent', width: 145 },
    ];
    if (includeMemory) {
        resourceColumns.push({ id: 'Metrics.IdleResources.MemoryUtilization', width: 130 });
    }

    const customDefaultColumns = useMemo(() => {
        return [
            {
                accessor: 'RunningState.Value',
                id: 'Idle.RunningStatusEC2',
                defaultWidth: 125,
                header: 'Status',
                type: 'string',
                groupName: 'EC2 On Demand',
                filter: {
                    filterField: 'RunningState.Value',
                    filterType: 'string',
                    name: 'Running Status',
                    fieldPickerRenderer: () => 'RunningState.Value',
                },
                cellRenderer: (item) => <IdleRunningStatusCell item={item} resourceType="EC2 On Demand" />,
            },
            {
                accessor: 'Idle.HasELBParent',
                id: 'Idle.HasELBParent',
                defaultWidth: 145,
                header: 'Used By Elastic Beanstalk',
                type: 'string',
                sortField: 'Idle.HasELBParent',
                groupName: 'EC2 On Demand',
                cellRenderer: (item) => (
                    <IdleUsedByOtherResourceCell
                        used={item.ParentResourceId?.ResourceType === 'ELBV1' || item.ParentResourceId?.ResourceType === 'ELBV2'}
                    />
                ),
            },
            {
                accessor: 'Idle.HasEKSParent',
                id: 'Idle.HasEKSParent',
                defaultWidth: 145,
                header: 'Used By EKS',
                type: 'string',
                sortField: 'Idle.HasEKSParent',
                groupName: 'EC2 On Demand',
                cellRenderer: (item) => <IdleUsedByOtherResourceCell used={item.ParentResourceId?.ResourceType === 'EKS'} />,
            },
            {
                accessor: 'Idle.HasEMRParent',
                id: 'Idle.HasEMRParent',
                defaultWidth: 145,
                header: 'Used By EMR',
                type: 'string',
                sortField: 'Idle.HasEMRParent',
                groupName: 'EC2 On Demand',
                cellRenderer: (item) => <IdleUsedByOtherResourceCell used={item.ParentResourceId?.ResourceType === 'EMR'} />,
            },
            {
                accessor: 'Idle.HasASGParent',
                id: 'Idle.HasASGParent',
                defaultWidth: 145,
                header: 'Used in Auto Scale Group',
                type: 'string',
                sortField: 'Idle.HasASGParent',
                groupName: 'EC2 On Demand',
                cellRenderer: (item) => <IdleUsedByOtherResourceCell used={item.ParentResourceId?.ResourceType === 'Autoscale'} />,
            },
        ] as ColumnConfig<BaseResource>[];
    }, []);

    const [idleResourceGrid, setIdleResourceGrid] = useState<DataGridModel>();
    const [selectedResources, setSelectedResources] = useState<BaseResource[]>();
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>();
    const [selectedResourcesState, setSelectedResourcesState] = useState<'running' | 'stopped' | 'terminated' | ''>('');
    const [showSidePanel, setShowSidePanel] = useState<boolean>();
    const [warning, setWarning] = useState<string | undefined>();

    const clearSelections = () => {
        setSelectedResources([]);
        setSelectedResourceIds([]);
        setShowSidePanel(false);
        idleResourceGrid?.selections.setSelectAll(false);
    };

    useEvent(idleResourceGrid?.selectionChanged, async () => {
        const selections = idleResourceGrid?.selections as QueryBasedResourceSelection;
        if (selections.count() > 0) {
            const selectedResourcesQuery = selections.createIdCriteria();
            const gridCriteria = selections.getResourceQuery();
            const queryToUse = selectedResourcesQuery ? selectedResourcesQuery : gridCriteria;

            const results = await postResourcesQuery(
                {
                    IncludeSchema: false,
                    IncludeCount: true,
                    Where: queryToUse,
                    Take: idleResourceGrid?.selections.count(),
                },
                { companyId: company?.Id }
            );

            if (results.Results != undefined && results.Results != null && results.Results.length > 0) {
                const selectedResources = results.Results as BaseResource[];
                if (selectedResources.length > 0) {
                    //group by running state
                    var shouldWarn = selectedResources.some((x) => x.InstanceTypeAttributes?.Storage != 'EBS only');
                    if (shouldWarn) {
                        setWarning('Some selected instances may be using instance store volumes. Stopping these instances may result in data loss.');
                    }
                    setSelectedResourcesState(selectedResources[0].RunningState.Value as 'running' | 'stopped' | 'terminated' | '');

                    setSelectedResources(selectedResources);
                    setSelectedResourceIds(selectedResources.map((x) => x.Id!));
                    setShowSidePanel(true);
                } else {
                    setShowSidePanel(false);
                }
            } else {
                clearSelections();
            }
        } else {
            clearSelections();
        }
    });

    const onStart = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });
            await postIdleActionStartIdleResources(resources, { resourceType: 'EC2 On Demand', companyId: company?.Id });

            notificationSvc.notify(
                'Starting Stopped Resources',
                `The selected resources will be started. There may be a brief delay before their status updates.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };
    const onStop = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });
            await postIdleActionStopIdleResources(resources, { resourceType: 'EC2 On Demand', companyId: company?.Id });

            notificationSvc.notify(
                'Stopping Idle Resources',
                `The selected resources will be stopped. There may be a brief delay before their status updates.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };
    const onCancel = () => {
        clearSelections();
    };
    return (
        <IdleResourcesPage title="EC2" types={['EC2 On Demand']}>
            <div style={{ height: '100%', width: showSidePanel ? '70%' : '100%', float: showSidePanel ? 'left' : 'unset' }}>
                <IdleResourceGrid
                    refreshNeeded={idleResourceService.refreshNeeded}
                    title="EC2"
                    resourceType="EC2 On Demand"
                    includeMemory={includeMemory}
                    extraColumns={resourceColumns}
                    setIdleResourcesGrid={setIdleResourceGrid}
                    customDefaultColumns={customDefaultColumns}
                />
            </div>

            {showSidePanel && selectedResourceIds != undefined && selectedResourceIds?.length! > 0 && (
                <IdleResourcesActionCard
                    selectedResourceIds={selectedResourceIds}
                    selectedResourceState={selectedResourcesState}
                    onStart={onStart}
                    onStop={onStop}
                    onCancel={onCancel}
                    warning={warning ?? undefined}
                />
            )}
        </IdleResourcesPage>
    );
}

function IdleEc2() {
    return <ConnectionCheck>{() => <IdleEc2Content />}</ConnectionCheck>;
}
endpoint('idle-ec2', withAppFeatureCheck(IdleEc2, 'Optimization', AppFeatureNames.IdleResources), 'Idle Resources');
