import { Text, Switch, Group, Space, Divider, Button, Box, LoadingOverlay, Tooltip, Radio } from '@mantine/core';
import { INavigatingEvent } from '@root/Services/Router/Router';
import { Company, CompanyInfo } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { deleteCompanyDeleteCompany, postSubscriptionHandleActivateCompany } from '@apis/Customers';
import { confirmAction } from './ConfirmActionDialog';
import { AdminPageTitle, AdminPanel } from './Design';
import { useEffect, useState } from 'react';
import { useEvent } from '@root/Services/EventEmitter';
import { CompanyInfoForm } from '@root/Site/Settings/CompanyInfo/CompanyInfoForm';
import { useDi } from '@root/Services/DI';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';

export interface AdminCompanyInfoFormProps {
    companyInfo: CompanyInfo;
    model: CompanyAdministrationPanelModel;
    parentCompany: Company;
    toggleSidePanel?: (showSidePanel: boolean) => void;
    toggleCompanyDeleted?: (companyDeleted: boolean) => void;
    loadCompany: () => void;
    statJobComplete?: () => void;
}

export const AdminCompanyInfo = (props: AdminCompanyInfoFormProps) => {
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [isActive, setActivate] = useState(false);
    const contextSvc = useDi(CompanyContextService);
    const [mspActivateChange, setMspActivateChange] = useState<boolean | undefined>(undefined);
    const [agreementAccepted, setAgreementAccepted] = useState(props.companyInfo.AgreementAccepted ?? false);
    const [agreementAcceptedValue, setAgreementAcceptedValue] = useState('No');

    useEffect(() => {
        if (props.companyInfo.AgreementAccepted === true) {
            setAgreementAcceptedValue('Yes');
        } else {
            setAgreementAcceptedValue('No');
        }
    }, [props.companyInfo.AgreementAccepted]);

    useEffect(() => {
        if (agreementAcceptedValue === 'Yes') {
            setAgreementAccepted(true);
        } else {
            setAgreementAccepted(false);
        }
    }, [agreementAcceptedValue]);

    useEffect(() => {
        setActivate(props.companyInfo.IsActive!);
        checkBillingAddress();
    }, [props.companyInfo]);

    const checkBillingAddress = () => {
        if (props.companyInfo.BillingAddress) {
            setDisable(false);
        }
    };

    const confirmAbandonChanges = (e: INavigatingEvent) => {
        confirmAction({
            headerText: 'Confirm Navigation',
            promptText: 'You have unsaved changes. Are you sure you want to leave this page?',
            confirmButtonText: 'Leave This Page',
            cancelButtonText: 'Stay On Page',
            confirmAction: () => e.continue(),
        });
    };

    const confirmDeleteCompany = () => {
        confirmAction({
            headerText: 'Delete Company?',
            promptText: `Are you sure you want to permanently delete company "${props.companyInfo.CompanyName}"?`,
            confirmButtonText: 'Yes, delete company',
            cancelButtonText: "No, don't delete",
            confirmAction: () => deleteCompany(),
        });
    };

    const deleteCompany = async () => {
        if (props.companyInfo.CompanyId) {
            await contextSvc.withParentCompany(async () => {
                await deleteCompanyDeleteCompany({ companyId: props.companyInfo.CompanyId });
                props?.toggleSidePanel?.(false);
                props?.toggleCompanyDeleted?.(true);
            });
        }
    };

    const handleActivateChange = (isActive: boolean) => {
        setActivate(isActive);
        setMspActivateChange(isActive);
        props.model.isModifiedChanged.emit(true);
    };

    const handleAgreementChange = () => {
        props.model.isModifiedChanged.emit(true);
    };

    const setValues = () => {
        setActivate(props.companyInfo.IsActive ?? false);
        setAgreementAccepted(props.companyInfo.AgreementAccepted ?? false);
    };

    const handleDiscardChanges = () => {
        setValues();
        props.model.isModifiedChanged.emit(false);
    };

    useEvent(props.model.isAddressGood, (value) => setDisable(!value));
    useEvent(props.model.discardChangesRequested, handleDiscardChanges);

    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <Box px="lg" pt="md">
                <AdminPageTitle>Company Info</AdminPageTitle>
            </Box>
            <CompanyInfoForm
                mspActivateChange={mspActivateChange}
                agreementAccepted={agreementAccepted}
                OnNavigating={confirmAbandonChanges}
                noHeader
                ShowSaveHeader={false}
                model={props.model}
                statJobComplete={props.statJobComplete}
            />
            <Divider orientation="horizontal" sx={{ margin: '0px 16px' }} />
            {props.parentCompany?.Type === 'PlatformSupport' ? (
                <MasterSubscriptionAgreementContainer>
                    <Group>
                        <Text>
                            <b>Accepted Master Subscription Agreement</b>
                        </Text>
                        <Radio.Group value={agreementAcceptedValue} onChange={handleAgreementChange}>
                            <Radio value={'Yes'} label="Yes" onClick={() => setAgreementAcceptedValue('Yes')} />
                            <Radio value={'No'} label="No" onClick={() => setAgreementAcceptedValue('No')} />
                        </Radio.Group>
                    </Group>
                </MasterSubscriptionAgreementContainer>
            ) : null}

            <Divider orientation="horizontal" sx={{ margin: '0px 16px' }} />
            <CompanyActionsContainer>
                <Group>
                    <Text>
                        <b>Company Actions</b>
                    </Text>

                    <Group>
                        <Space w={120} />
                        <Switch
                            disabled={disable}
                            label={
                                <Tooltip
                                    position="right"
                                    withArrow
                                    label={isActive ? 'On save, company will be set to active' : 'On save, company will be set to inactive'}
                                >
                                    <div>{isActive ? 'Active' : 'Inactive'}</div>
                                </Tooltip>
                            }
                            checked={isActive}
                            onChange={(event) => handleActivateChange(event.currentTarget.checked)}
                        />
                    </Group>
                </Group>
            </CompanyActionsContainer>
            <DeleteCompanyContainer>
                <Group position="apart">
                    <div>
                        <Text>
                            <b>Delete Company</b>
                        </Text>
                    </div>
                    <Button onClick={confirmDeleteCompany}>Delete Company</Button>
                </Group>
            </DeleteCompanyContainer>
        </>
    );
};

const MasterSubscriptionAgreementContainer = styled.div`
    margin: 12px 24px 12px 24px;
    border-radius: 10px;
    padding: 12px;
`;

const CompanyActionsContainer = styled.div`
    margin: 12px 24px 12px 24px;
    border-radius: 10px;
    padding: 12px;
`;

const DeleteCompanyContainer = styled.div`
    margin: 12px 24px 12px 24px;
    border: 1px solid #d92d20;
    border-radius: 10px;
    padding: 12px;
    padding: 12px;
    .mantine-Text-root {
        color: #d92d20;
    }
    .mantine-UnstyledButton-root {
        background-color: #d92d20;
        color: white;
    }
    .mantine-UnstyledButton-root:hover {
        background-color: white;
        color: #d92d20;
        border: 1px solid #d92d20;
    }
`;
