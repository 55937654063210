import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, Ebs, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceSnapshots(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();

    var retValue;

    var rows = [];
    if (props.resourceType == 'EC2 On Demand') {
        useEffect(() => {
            (async () => {
                var dotPos = props.resourceId.indexOf('.');
                var instanceId = props.resourceId.substring(dotPos + 1);
                var volumeData = await queryBuilder<BaseAwsResource | Ebs>()
                    .where((r) => r.and(r.model.ResourceType!.eq('EBS'), r.model['Attachments.InstanceId']!.eq(instanceId)))
                    .execute((q) => postResourcesQuery(q, { companyId: company?.Id }));

                var volumeIds: string[] = [];
                if (volumeData.Results?.length) {
                    for (var i = 0; i < volumeData.Results.length; i++) {
                        volumeIds.push(volumeData.Results[i].VolumeId as string);
                    }
                }

                runQuery({
                    data: queryBuilder<BaseAwsResource>()
                        .where((r) => r.and(r.model.ResourceType!.eq('EC2 Snapshot'), r.model.VolumeId!.eq(volumeIds)))
                        .take(1000)
                        .build(),
                    params: { companyId: company?.Id },
                });
            })();
        }, []);

        if (isLoading) {
            retValue = <>Loading...</>;
        } else {
            if (data == null || data.Results == null || data.Results[0] == null) {
                rows.push({
                    name: 'No snapshots found',
                });
            } else {
                for (var i = 0; i < data.Results.length; i++) {
                    const resource = data.Results[i] as {
                        Tags: { Value: string; Key: string }[];
                        Encrypted: boolean;
                        VolumeSize: string;
                        StartTime: string;
                    };
                    var snapshotName = '[No Name]';
                    if (resource.Tags.length > 0) {
                        snapshotName = resource.Tags[0].Value;
                    }
                    rows.push({
                        name: snapshotName,
                        encrypted: resource.Encrypted ? 'Yes' : 'No',
                        size: resource.VolumeSize + ' GB',
                        date: resource.StartTime,
                    });
                }
            }
            retValue = (
                <Table>
                    <thead>
                        <tr>
                            <td>Snapshot Name</td>
                            <td>Encrypted</td>
                            <td>Size</td>
                            <td className="left">Date</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.name}</td>
                                    <td>{val.encrypted}</td>
                                    <td>{val.size}</td>
                                    <td>{val.date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            );
        }
    } else if (props.resourceType == 'RDS') {
        var dotPos = props.resourceId.indexOf('.');
        var instanceId = props.resourceId.substring(dotPos + 1);
        useEffect(() => {
            runQuery({
                data: queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq('RDS Snapshot'), r.model.DbiResourceId!.eq(instanceId)))
                    .take(1000)
                    .build(),
                params: { companyId: company?.Id },
            });
        }, []);

        if (isLoading) {
            retValue = <>Loading...</>;
        } else {
            if (data == null || data.Results == null || data.Results[0] == null) {
                rows.push({
                    name: 'No snapshots found',
                });
            } else {
                var snapshots = data.Results as {
                    DBSnapshotIdentifier: string;
                    SnapshotCreateTime: string;
                    AllocatedStorage: string;
                    SnapshotType: string;
                }[];

                for (var i = 0; i < snapshots.length; i++) {
                    rows.push({
                        name: snapshots[i].DBSnapshotIdentifier,
                        date: snapshots[i].SnapshotCreateTime,
                        size: snapshots[i].AllocatedStorage + ' GB',
                        type: snapshots[i].SnapshotType,
                    });
                }
            }
            retValue = (
                <table>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Date Created</td>
                            <td>Size</td>
                            <td className="left">Type</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.name}</td>
                                    <td>{val.date}</td>
                                    <td>{val.size}</td>
                                    <td>{val.type}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
    }

    return (
        <div className="resourcesTableContainer">
            <ResourceInfoTable>{retValue}</ResourceInfoTable>
        </div>
    );
}

export function ResourceSnapshots() {
    return <>Resource Snapshots</>;
}
endpoint('resource', ResourceSnapshots, 'Resource Snapshots');
