import {
    useGetPaymentGetPaymentSources,
    postPaymentAddPaymentSource,
    postPaymentDeletePaymentSource,
    useGetPaymentGetPrimaryPayment,
    postPaymentUpdatePrimaryPayment,
    getPaymentGetHostedPage,
    getPaymentGetAwsPublicOfferUrl,
    getPaymentGetAzurePublicOfferUrl,
} from '@apis/Customers';
import { Card, HostedPage, PaymentSource } from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Alert,
    Badge,
    Box,
    Button,
    Checkbox,
    CopyButton,
    createStyles,
    Divider,
    Group,
    Loader,
    LoadingOverlay,
    NumberInput,
    Popover,
    Progress,
    Space,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    Tooltip,
    UnstyledButton,
} from '@mantine/core';
import { closeModal, useModals } from '@mantine/modals';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent } from '@root/Design/Layout';
import { colorPalette, CustomColors, mspColorPalette, theme } from '@root/Design/Themes';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { FC, Ref, SetStateAction, useMemo } from 'react';
import { useEffect, useState, useCallback } from 'react';
export interface Tokenizeable {
    tokenize: (cardInfo: AdditionalCardInfo) => Promise<{ token: string }>;
}
type CardComponentType = FC<{
    ref?: Ref<Tokenizeable>;
    className?: string;
    onChange?: (status: 'empty' | 'complete' | 'valid' | 'invalid') => void;
}> &
    Tokenizeable;
// @ts-ignore
import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper';
import { Observer } from 'mobx-react';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useDi, useDiComponent } from '@root/Services/DI';
import { SettingsPage } from '../SettingsPage';
import { CountryPicker } from '@root/Components/CompanyInfo/CompanyAddress';
import { AzurePaymentMethodService } from './Services/AzurePaymentMethodService';
import { Check, Copy } from 'tabler-icons-react';

export interface AdditionalCardInfo {
    firstName?: string;
    lastName?: string;
    billingAddr1?: string | null;
    billingAddr2?: string | null;
    billingCity?: string | null;
    billingState?: string | null;
    billingStateCode?: string | null;
    billingZip?: string | null;
    billingCountry?: string | null;
}

function hasExclusivePaymentSource(paymentSources: PaymentSource[]) {
    return paymentSources?.some((p) => p.PaymentSourceType === 'CSP' && p.CloudPartner?.CloudPlatform === 'Aws');
}

export function PaymentMethods() {
    const com = useCompany();
    const { data: savedPayments, refetch } = useGetPaymentGetPaymentSources();
    const { classes } = useStyles();
    const { data: primaryPayment, refetch: refetchPrimary } = useGetPaymentGetPrimaryPayment();
    const [hostedPage, setHostedPage] = useState<HostedPage>();

    useEffect(() => {
        (async () => {
            setHostedPage(await getPaymentGetHostedPage());
        })();
    }, []);

    const reload = () => {
        refetch();
        refetchPrimary();
    };
    const { openPaymentsModal } = useAddPaymentMethod(reload, hostedPage);

    const nonPrimarySources = savedPayments?.filter((p) => p.Id != primaryPayment?.Id && p.CloudPartner?.Status != 'Deleted');
    const nonPrimaryCards = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'Card');
    const nonPrimaryBankAccounts = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'DirectDebit');
    const nonPrimaryCSPs = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'CSP');
    const canAddPaymentMethod = !hasExclusivePaymentSource(savedPayments ?? []);

    return (
        <SettingsPage>
            <PageContent>
                {!savedPayments ? (
                    <Progress />
                ) : (
                    <>
                        <div
                            style={{
                                paddingBottom: '1rem',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid lightgray',
                            }}
                        >
                            <Group position="apart">
                                <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                                    Payment Methods
                                </Title>
                                <Group hidden={!canAddPaymentMethod} style={{ paddingRight: '26px', paddingTop: '11px' }}>
                                    <Button
                                        data-atid="AddPaymentButton"
                                        onClick={() => {
                                            openPaymentsModal();
                                        }}
                                        leftIcon={<i className="ti ti-plus" />}
                                        variant="outline"
                                    >
                                        <Text weight={600}>Add Payment Method</Text>
                                    </Button>
                                </Group>
                            </Group>
                        </div>

                        <Box px="xl" py="lg" sx={{ maxWidth: 720 }}>
                            {!primaryPayment && !nonPrimarySources?.length ? (
                                <Text size="md" weight={600} color="gray">
                                    No payment methods
                                </Text>
                            ) : null}

                            {primaryPayment ? (
                                <>
                                    <Text size="md" weight={600} color={colorPalette.titleTextColor} data-atid="PrimaryPaymentMethod">
                                        Primary payment method
                                    </Text>
                                    <PaymentList>
                                        <Observer
                                            render={() =>
                                                primaryPayment.PaymentSourceType == 'Card' ? (
                                                    <CardHolder
                                                        item={primaryPayment as PaymentSource}
                                                        onAction={reload}
                                                        key={0}
                                                        isPrimary={true}
                                                    ></CardHolder>
                                                ) : primaryPayment.PaymentSourceType == 'DirectDebit' ? (
                                                    <AccountHolder
                                                        item={primaryPayment as PaymentSource}
                                                        onAction={reload}
                                                        key={0}
                                                        isPrimary={true}
                                                        hostedPage={hostedPage as HostedPage}
                                                    ></AccountHolder>
                                                ) : (
                                                    <>
                                                        <CspHolder
                                                            item={primaryPayment as PaymentSource}
                                                            onAction={reload}
                                                            key={0}
                                                            isPrimary={true}
                                                        ></CspHolder>
                                                    </>
                                                )
                                            }
                                        />
                                    </PaymentList>
                                    <Space h={20}></Space>
                                </>
                            ) : (
                                ''
                            )}

                            {nonPrimarySources?.length ? (
                                <>
                                    <Group>
                                        <Text size="md" weight={600} color={colorPalette.titleTextColor}>
                                            Saved payment methods
                                        </Text>
                                        <NumberCircle>
                                            <Text weight={500} color={colorPalette.subHeaderTextColor}>
                                                {nonPrimarySources.length}
                                            </Text>
                                        </NumberCircle>
                                    </Group>
                                    <PaymentList>
                                        {nonPrimaryCards?.map((item, i) => (
                                            <Observer
                                                render={() => (
                                                    <CardHolder item={item} onAction={reload} key={'card' + i + 1} isPrimary={false}></CardHolder>
                                                )}
                                            />
                                        ))}

                                        {nonPrimaryBankAccounts?.map((item, i) => (
                                            <Observer
                                                render={() => (
                                                    <AccountHolder
                                                        item={item}
                                                        onAction={reload}
                                                        key={'account' + i + 1}
                                                        isPrimary={false}
                                                        hostedPage={hostedPage as HostedPage}
                                                    ></AccountHolder>
                                                )}
                                            />
                                        ))}

                                        {nonPrimaryCSPs?.map((item, i) => (
                                            <Observer
                                                render={() => (
                                                    <CspHolder item={item} onAction={reload} key={'csp' + i + 1} isPrimary={false}></CspHolder>
                                                )}
                                            />
                                        ))}
                                    </PaymentList>
                                </>
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </PageContent>
        </SettingsPage>
    );
}
endpoint('payment-methods', PaymentMethods, 'Payment Methods');

export function useAddPaymentMethod(reload: () => void, hostedPage?: HostedPage) {
    const modals = useModals();
    const DiContainer = useDiComponent();

    const [awsPublicOfferUrl, setAwsPublicOfferUrl] = useState<string | null>();
    const [azurePublicOfferUrl, setAzurePublicOfferUrl] = useState<string | null>();

    useEffect(() => {
        (async () => {
            const awsPublicOffer = await getPaymentGetAwsPublicOfferUrl();
            const azurePublicOffer = await getPaymentGetAzurePublicOfferUrl();
            setAwsPublicOfferUrl(awsPublicOffer?.PublicOfferUrl);
            setAzurePublicOfferUrl(azurePublicOffer?.PublicOfferUrl);
        })();
    }, []);

    const openAddModal = () => {
        const id = modals.openModal({
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Add Payment Method
                </Text>
            ),
            children: (
                <DiContainer>
                    <CardModal onAction={reload} onClose={() => modals.closeModal(id)}></CardModal>,
                </DiContainer>
            ),
            size: '600px',
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };

    const openAwsCspModal = () => {
        const id = modals.openModal({
            title: (
                <Text sx={{ flex: 1 }} align="center" size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Bill My AWS Account
                </Text>
            ),
            children: (
                <DiContainer>
                    <AddAwsMarketplace close={() => closeModal(id)} awsPublicOfferUrl={awsPublicOfferUrl!} />
                </DiContainer>
            ),
            size: 500,
            sx: { padding: '32px' },
            radius: 'lg',
        });
    };

    const openAzCspModal = () => {
        const id = modals.openModal({
            title: (
                <Text sx={{ flex: 1 }} align="center" size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Bill My Azure Subscription
                </Text>
            ),
            children: (
                <DiContainer>
                    <AddAzureMarketplace azurePublicOfferUrl={azurePublicOfferUrl!} close={() => closeModal(id)} />
                </DiContainer>
            ),
            size: 500,
            sx: { padding: '32px' },
            radius: 'lg',
        });
    };

    const openPaymentsModal = () => {
        const id = modals.openModal({
            title: (
                <Text data-atid="ModalHeader" size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Add Payment Method
                </Text>
            ),
            children: (
                <DiContainer>
                    <Box sx={{ textAlign: 'center' }}>
                        {awsPublicOfferUrl ? (
                            <>
                                <Button
                                    data-atid="BillCloudProvider-Aws"
                                    onClick={() => {
                                        closeModal(id);
                                        openAwsCspModal();
                                    }}
                                    leftIcon={<img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '20px' }} />}
                                    variant="outline"
                                    sx={{ backgroundColor: '#FFFFFF', width: '300px' }}
                                >
                                    <Text weight={600}>Bill My AWS Account</Text>
                                </Button>
                                <Space h={10}></Space>
                            </>
                        ) : (
                            <></>
                        )}
                        {azurePublicOfferUrl ? (
                            <>
                                <Button
                                    data-atid="BillCloudProvider-Az"
                                    onClick={() => {
                                        closeModal(id);
                                        openAzCspModal();
                                    }}
                                    leftIcon={<img src="/assets/azure_logo.svg" style={{ width: '20px' }} />}
                                    variant="outline"
                                    sx={{ backgroundColor: '#FFFFFF', width: '300px' }}
                                >
                                    <Text weight={600}>Bill My Azure Subscription</Text>
                                </Button>
                                <Space h={10}></Space>
                            </>
                        ) : (
                            <></>
                        )}
                        <Button
                            data-atid="PayWithCardButton"
                            onClick={() => {
                                closeModal(id);
                                openAddModal();
                            }}
                            leftIcon={<i className="ti ti-credit-card" />}
                            variant="outline"
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Text weight={600}>Pay With Card</Text>
                        </Button>
                        <Space h={10}></Space>
                        <Tooltip label="Our secure payment portal will open in a new window" openDelay={500} position="bottom" withArrow withinPortal>
                            <Button
                                data-atid="PayWithBankButton"
                                component="a"
                                target="_blank"
                                href={hostedPage?.Url ?? ''}
                                onClick={() => {
                                    closeModal(id);
                                }}
                                leftIcon={<i className="ti ti-building-bank" />}
                                rightIcon={<i className="ti ti-external-link"></i>}
                                variant="outline"
                                sx={{
                                    width: '300px',
                                }}
                            >
                                <Text weight={600}>Pay With Bank Account</Text>
                            </Button>
                        </Tooltip>
                    </Box>
                </DiContainer>
            ),
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };

    return { openPaymentsModal };
}

function AddAwsMarketplace({ awsPublicOfferUrl, close }: { awsPublicOfferUrl: string; close: () => void }) {
    return (
        <Box px="lg" mt={30}>
            <Group noWrap align="stretch">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        1
                    </ThemeIcon>
                    <Divider orientation="vertical" mt="sm" sx={{ flex: 1, height: '100%', alignSelf: 'center' }} />
                </Box>
                <Box>
                    <Text>Open the CloudSaver Platform offer in the AWS Marketplace.</Text>
                    <Space h="xs" />
                    <Button
                        data-atid="BillCloudProvider-Aws"
                        component="a"
                        target="_blank"
                        href={awsPublicOfferUrl}
                        leftIcon={<img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '20px' }} />}
                        rightIcon={<i className="ti ti-external-link"></i>}
                        variant="outline"
                        sx={{ backgroundColor: '#FFFFFF', width: '300px' }}
                    >
                        <Text weight={600}>Open Marketplace Offer</Text>
                    </Button>
                    <Space h="sm" />
                </Box>
            </Group>
            <Space h="sm" />
            <Group noWrap align="stretch">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        2
                    </ThemeIcon>
                    <Divider orientation="vertical" mt="sm" sx={{ flex: 1, height: '100%', alignSelf: 'center' }} />
                </Box>
                <Box>
                    <Text>Subscribe & Configure the SaaS Offer.</Text>
                    <Text size="sm" color="dimmed">
                        AWS will redirect you back to CloudSaver for activation.
                    </Text>
                    <Space h="sm" />
                </Box>
            </Group>
            <Space h="sm" />
            <Group noWrap align="flex-start">
                <Box>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        3
                    </ThemeIcon>
                </Box>
                <Box>
                    <Text>Your New Payment Method is Ready</Text>
                    <Text size="sm" color="dimmed">
                        After setting up the Marketplace offer, you will find it listed as a new payment method here.
                    </Text>
                </Box>
            </Group>
            <Space h="lg" />
        </Box>
    );
}

function AddAzureMarketplace({ azurePublicOfferUrl, close }: { azurePublicOfferUrl: string; close: () => void }) {
    const secretSvc = useDi(AzurePaymentMethodService);
    const [secret, setSecret] = useState<string>();
    useEffect(() => {
        secretSvc.createSecret().then((s) => s && setSecret(s));
    }, []);

    return (
        <Box px="lg" mt={30}>
            <Group noWrap align="stretch">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        1
                    </ThemeIcon>
                    <Divider orientation="vertical" mt="sm" sx={{ flex: 1, height: '100%', alignSelf: 'center' }} />
                </Box>
                <Box>
                    <Text>Open the CloudSaver Platform SaaS offer in the Azure Marketplace.</Text>
                    <Space h="xs" />

                    <Button
                        data-atid="BillCloudProvider-Azure"
                        component="a"
                        target="_blank"
                        href={azurePublicOfferUrl ?? ''}
                        leftIcon={<img src="/assets/azure_logo.svg" style={{ width: '20px' }} />}
                        rightIcon={<i className="ti ti-external-link"></i>}
                        variant="outline"
                        sx={{ backgroundColor: '#FFFFFF', width: '300px' }}
                    >
                        <Text weight={600}>Open Azure SaaS Offer</Text>
                    </Button>
                    <Space h="sm" />
                </Box>
            </Group>
            <Space h="sm" />
            <Group noWrap align="stretch">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        2
                    </ThemeIcon>
                    <Divider orientation="vertical" mt="sm" sx={{ flex: 1, height: '100%', alignSelf: 'center' }} />
                </Box>
                <Box>
                    <Text>Subscribe & Configure the SaaS Offer.</Text>
                    <Text size="sm" color="dimmed">
                        Azure will redirect you back to CloudSaver for activation. Enter the code below when prompted.
                    </Text>
                    <Space h="xs" />
                    <TextInput
                        icon={!secret ? <Loader size="sm" /> : null}
                        label="Activation Code"
                        value={secret}
                        mr={30}
                        rightSection={
                            <CopyButton value={secret ?? ''} timeout={2000}>
                                {({ copied, copy }) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                        <ActionIcon color={copied ? 'success' : 'gray'} onClick={copy}>
                                            {copied ? <Check size="1rem" /> : <Copy size="1rem" />}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        }
                    />
                    <Space h="sm" />
                </Box>
            </Group>
            <Space h="sm" />
            <Group noWrap align="flex-start">
                <Box>
                    <ThemeIcon color="gray" size="md" radius="lg" variant="outline">
                        3
                    </ThemeIcon>
                </Box>
                <Box>
                    <Text>Your New Payment Method is Ready</Text>
                    <Text size="sm" color="dimmed">
                        After activating the Marketplace offer, you will find it listed as a new payment method here.
                    </Text>
                </Box>
            </Group>
            <Space h="lg" />
        </Box>
    );
}

export function CardHolder({
    item,
    onAction,
    isPrimary,
    readonly = false,
}: {
    item: PaymentSource;
    onAction: () => void;
    isPrimary: boolean;
    readonly?: boolean;
}) {
    const [expireMsg, setExpireMsg] = useState<string>('Expires');
    const date = new Date();
    const { classes } = useStyles();
    const [svg, setSvg] = useState<string>();
    const [popoverOpened, setPopoverOpened] = useState(false);
    const notificationSvc = useDi(NotificationService);

    useEffect(() => {
        switch (item.Card?.Brand) {
            case 'Visa':
                setSvg('/assets/cards/Visa.svg');
                break;
            case 'Mastercard':
                setSvg('/assets/cards/Mastercard.svg');
                break;
            case 'AmericanExpress':
                setSvg('/assets/cards/AMEX.svg');
                break;
            case 'Discover':
                setSvg('/assets/cards/Discover.svg');
                break;
            case 'Jcb':
                setSvg('/assets/cards/JCB.svg');
                break;
            case 'DinersClub':
                setSvg('/assets/cards/DinersClub.svg');
                break;
            case 'Bancontact':
                setSvg('/assets/cards/Bancontact.svg');
                break;
            default:
                setSvg('/assets/cards/Generic_card.svg');
                break;
        }
    }, [item]);

    function deleteCard(id: string) {
        (async () => {
            setPopoverOpened(false);
            await postPaymentDeletePaymentSource({ paymentId: id });
            onAction();
            notificationSvc.notify('Payment method deleted', '', 'gray', null);
        })();
    }

    function setNewPrimary(id: string) {
        (async () => {
            await postPaymentUpdatePrimaryPayment({ paymentId: id });
            onAction();
            notificationSvc.notify('Successfully updated primary payment', '', 'success', null);
        })();
    }

    return (
        <>
            <Box className={classes.cardStyles}>
                <Group>
                    <CardIcon src={svg} alt={item.Card?.Brand ?? ''} />
                    <Box>
                        <Text color={colorPalette.subHeaderTextColor} weight={500}>
                            {item.Card?.Brand} ending in {item.Card?.Last4}
                        </Text>
                        <Text color={colorPalette.subTextColor} weight={400}>
                            {expireMsg} {item.Card?.ExpiryMonth}/{item.Card?.ExpiryYear}
                        </Text>
                    </Box>
                </Group>
                <Space h={10}></Space>
                {!isPrimary && !readonly ? (
                    <Group pl="73px">
                        <>
                            <UnstyledButton onClick={() => setNewPrimary(item.Id as string)}>
                                <Text className={classes.setPrimary} weight={600} size={14}>
                                    Set as Primary
                                </Text>
                            </UnstyledButton>
                            <Space></Space>
                        </>
                        <DeleteCard>
                            <Popover position="bottom" opened={popoverOpened}>
                                <Popover.Target>
                                    <UnstyledButton onClick={() => setPopoverOpened(true)}>
                                        <Text weight={600} size={14} className={classes.deletePayment}>
                                            Delete
                                        </Text>
                                    </UnstyledButton>
                                </Popover.Target>
                                <Popover.Dropdown className={classes.deletePopover}>
                                    <Text weight={600} size={14} color={colorPalette.titleTextColor}>
                                        Are you sure you want to delete?
                                    </Text>
                                    <Text weight={400} size={14} color={colorPalette.subHeaderTextColor}>
                                        This action cannot be undone.
                                    </Text>
                                    <Space h={10}></Space>
                                    <Group position="right">
                                        <Button variant="outline" onClick={() => setPopoverOpened(false)}>
                                            <Text weight={600} size={14}>
                                                Cancel
                                            </Text>
                                        </Button>
                                        <Button onClick={() => deleteCard(item.Id as string)}>
                                            <Text weight={600} size={14}>
                                                Delete
                                            </Text>
                                        </Button>{' '}
                                    </Group>
                                </Popover.Dropdown>
                            </Popover>
                        </DeleteCard>
                    </Group>
                ) : (
                    <Box pl="73px">
                        <Badge
                            style={{
                                backgroundColor: theme.colors?.primary?.[2] as CustomColors,
                                color: theme.colors?.primary?.[8] as CustomColors,
                            }}
                        >
                            Primary payment
                        </Badge>
                    </Box>
                )}
            </Box>
        </>
    );
}

export function AccountHolder({
    item,
    onAction,
    isPrimary,
    hostedPage,
    readonly = false,
}: {
    item: PaymentSource;
    onAction: () => void;
    isPrimary: boolean;
    hostedPage?: HostedPage;
    readonly?: boolean;
}) {
    const { classes } = useStyles();
    const [popoverOpened, setPopoverOpened] = useState(false);
    const notificationSvc = useDi(NotificationService);
    function deleteCard(id: string) {
        (async () => {
            setPopoverOpened(false);
            await postPaymentDeletePaymentSource({ paymentId: id });
            onAction();
            notificationSvc.notify('Payment method deleted', '', 'gray', null);
        })();
    }

    function setNewPrimary(id: string) {
        (async () => {
            await postPaymentUpdatePrimaryPayment({ paymentId: id });
            onAction();
            notificationSvc.notify('Successfully updated primary payment', '', 'success', null);
        })();
    }

    return (
        <>
            <Box className={classes.cardStyles}>
                <Group>
                    <ActionIcon sx={{ fontSize: '35px' }}>
                        <i className="ti ti-building-bank" />
                    </ActionIcon>

                    <Box pl="28px">
                        <Group>
                            <Text color={colorPalette.subHeaderTextColor} weight={500}>
                                Bank account ending in {item.BankAccount?.Last4}
                            </Text>
                            {item.Status == 'PendingVerification' ? (
                                <Badge
                                    sx={{
                                        height: '30px',
                                        fontSize: '12px',
                                        backgroundColor: colorPalette.warningBadgeColor,
                                        color: colorPalette.pendingVerificationColor,
                                        padding: '4px, 4px, 4px, 4px',
                                        paddingRight: '4px',
                                    }}
                                    rightSection={
                                        <Button
                                            component="a"
                                            target="_blank"
                                            href={hostedPage?.Url ?? ''}
                                            sx={{
                                                height: '22px',
                                                backgroundColor: colorPalette.white,
                                                color: colorPalette.pendingVerificationColor,
                                                fontSize: '12px',
                                                marginLeft: '4px',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                paddingLeft: '8px',
                                                paddingRight: '6px',
                                            }}
                                            radius="lg"
                                            variant="white"
                                            className={classes.arrow}
                                        >
                                            Verify<Space w={4}></Space>
                                            <i className="ti ti-arrow-narrow-right" />
                                        </Button>
                                    }
                                >
                                    Pending verification
                                </Badge>
                            ) : (
                                ''
                            )}
                        </Group>
                        <Text color={colorPalette.subTextColor} weight={400}>
                            {item.BankAccount?.BankName}
                        </Text>
                    </Box>
                </Group>
                <Space h={10}></Space>
                {!isPrimary && !readonly ? (
                    <Group pl="73px">
                        <>
                            <UnstyledButton className={classes.setPrimary} onClick={() => setNewPrimary(item.Id as string)}>
                                <Text weight={600} size={14} className={classes.setPrimary}>
                                    Set as Primary
                                </Text>
                            </UnstyledButton>
                            <Space></Space>
                        </>
                        <DeleteCard>
                            <Popover position="bottom" opened={popoverOpened}>
                                <Popover.Target>
                                    <UnstyledButton className={classes.deletePayment} onClick={() => setPopoverOpened(true)}>
                                        <Text weight={600} size={14}>
                                            Delete
                                        </Text>
                                    </UnstyledButton>
                                </Popover.Target>
                                <Popover.Dropdown className={classes.deletePopover}>
                                    <Text weight={600} size={14} color={colorPalette.titleTextColor}>
                                        Are you sure you want to delete?
                                    </Text>
                                    <Text weight={400} size={14} color={colorPalette.subHeaderTextColor}>
                                        This action cannot be undone
                                    </Text>
                                    <Space h={10}></Space>
                                    <Group position="right">
                                        <Button variant="outline" onClick={() => setPopoverOpened(false)}>
                                            <Text weight={600} size={14}>
                                                Cancel
                                            </Text>
                                        </Button>{' '}
                                        <Button onClick={() => deleteCard(item.Id as string)}>
                                            <Text weight={600} size={14}>
                                                Delete
                                            </Text>
                                        </Button>{' '}
                                    </Group>
                                </Popover.Dropdown>
                            </Popover>
                        </DeleteCard>
                    </Group>
                ) : (
                    <Box pl="73px">
                        <Badge color={theme.colors?.primary?.[8] as CustomColors}>Primary payment</Badge>
                    </Box>
                )}
            </Box>
        </>
    );
}

export function CspHolder({
    item,
    onAction,
    isPrimary,
    readonly = false,
}: {
    item: PaymentSource;
    onAction: () => void;
    isPrimary: boolean;
    readonly?: boolean;
}) {
    const { classes } = useStyles();
    const [popoverOpened, setPopoverOpened] = useState(false);
    const notificationSvc = useDi(NotificationService);
    function deleteCard(id: string) {
        (async () => {
            setPopoverOpened(false);
            await postPaymentDeletePaymentSource({ paymentId: id });
            onAction();
            notificationSvc.notify('Payment method deleted', '', 'gray', null);
        })();
    }

    function setNewPrimary(id: string) {
        (async () => {
            await postPaymentUpdatePrimaryPayment({ paymentId: id });
            onAction();
            notificationSvc.notify('Successfully updated primary payment', '', 'success', null);
        })();
    }
    const svg = item.CloudPartner?.CloudPlatform === 'Aws' ? '/assets/Amazon_Web_Services_Logo.svg' : '/assets/azure_logo.svg';

    return (
        <>
            <Box className={classes.cardStyles}>
                <Group align="flex-start">
                    <CardIcon src={svg} alt={item.CloudPartner?.CloudPlatform ?? ''} />
                    <Box>
                        <Box>
                            <Group>
                                <Text color={colorPalette.subHeaderTextColor} weight={500}>
                                    Bill Cloud Provider Directly
                                </Text>
                                {item.Status == 'Pending' ? (
                                    <Badge
                                        sx={{
                                            height: '30px',
                                            fontSize: '12px',
                                            backgroundColor: colorPalette.warningBadgeColor,
                                            color: colorPalette.pendingVerificationColor,
                                            padding: '4px, 4px, 4px, 4px',
                                            paddingRight: '4px',
                                        }}
                                    >
                                        Pending
                                    </Badge>
                                ) : (
                                    ''
                                )}
                            </Group>
                            <Text color={colorPalette.subTextColor} weight={400}>
                                {`${item.CloudPartner?.CloudPlatform} ${item.CloudPartner?.Name ?? ''} ${
                                    item.CloudPartner?.CloudPlatform === 'Azure' ? 'Tenant' : 'Account'
                                } ${item.CloudPartner?.AccountId}`}
                            </Text>
                        </Box>
                        <Space h={10}></Space>
                        {item.CloudPartner?.CloudPlatform === 'Azure' ? (
                            <>
                                <Text color="dimmed" italic>
                                    Managed in Azure Portal
                                </Text>
                            </>
                        ) : item.CloudPartner?.CloudPlatform === 'Aws' ? (
                            <>
                                <Text color="dimmed" italic>
                                    Managed in Aws Portal
                                </Text>
                            </>
                        ) : !isPrimary && !readonly ? (
                            <Group>
                                <>
                                    <UnstyledButton onClick={() => setNewPrimary(item.Id as string)}>
                                        <Text color={colorPalette.linkColor} weight={600} size={14}>
                                            Set as primary
                                        </Text>
                                    </UnstyledButton>
                                    <Space></Space>
                                </>
                                <DeleteCard>
                                    <Popover position="bottom" opened={popoverOpened}>
                                        <Popover.Target>
                                            <UnstyledButton onClick={() => setPopoverOpened(true)}>
                                                <Text weight={600} size={14} color={colorPalette.subTextColor}>
                                                    Delete
                                                </Text>
                                            </UnstyledButton>
                                        </Popover.Target>
                                        <Popover.Dropdown className={classes.deletePopover}>
                                            <Text weight={600} size={14} color={colorPalette.titleTextColor}>
                                                Are you sure you want to delete?
                                            </Text>
                                            <Text weight={400} size={14} color={colorPalette.subHeaderTextColor}>
                                                This action cannot be undone
                                            </Text>
                                            <Space h={10}></Space>
                                            <Group>
                                                <UnstyledButton onClick={() => setPopoverOpened(false)}>
                                                    <Text color={colorPalette.subHeaderTextColor} weight={600} size={14}>
                                                        Cancel
                                                    </Text>
                                                </UnstyledButton>
                                                <UnstyledButton onClick={() => deleteCard(item.Id as string)}>
                                                    <Text color={colorPalette.deleteColor} weight={600} size={14}>
                                                        Delete
                                                    </Text>
                                                </UnstyledButton>
                                            </Group>
                                        </Popover.Dropdown>
                                    </Popover>
                                </DeleteCard>
                            </Group>
                        ) : (
                            <Box pl="73px">
                                <Badge color="primary">Primary payment</Badge>
                            </Box>
                        )}
                    </Box>
                </Group>
            </Box>
        </>
    );
}

const useStyles = createStyles((theme) => ({
    cardStyles: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
        marginTop: `${theme.spacing.md}px`,
    },

    arrow: {
        i: {
            color: colorPalette.pendingVerificationArrowColor,
        },
    },

    deletePopover: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
    },

    cardNumber: {
        width: '400px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardCvv: {
        width: '96px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardExpiry: {
        width: '100px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardBox: {
        border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
        borderRadius: `${theme.radius.sm}px`,
        padding: '10px 14px ',
        fontSize: '16px',
    },

    primaryBox: {
        label: {
            fontSize: '16px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    nonCardItems: {
        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },

        input: {
            border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
            borderRadius: `${theme.radius.sm}px`,
            padding: '10px 14px ',
        },
    },

    iFrameModalStyles: {
        alignItems: 'center',
    },
    setPrimary: {
        color: theme.colors?.primary?.[6] as CustomColors,
        '&:hover': {
            color: theme.colors?.primary?.[7] as CustomColors,
            textDecoration: 'underline',
        },
    },
    deletePayment: {
        color: theme.colors?.gray?.[7] as CustomColors,
        '&:hover': {
            color: 'black',
            textDecoration: 'underline',
        },
    },
}));

const DeleteCard = styled.div`
    border: 'solid 1px #0002';
    border-radius: ${(p) => p.theme.radius?.md}px;
    background-color: ${colorPalette.white};
`;

export const BillingHeader = styled.div`
    padding: ${(p) => p.theme.spacing.lg}px ${(p) => p.theme.spacing.xl}px;
`;

export const NumberCircle = styled.div`
    background-color: ${colorPalette.numberCircleColor};
    border-radius: 50%;
    text-align: center;
    width: 22px;
    height: 22px;
    display: block;
    color: ${colorPalette.subHeaderTextColor};
`;

export const PaymentList = styled.div``;

export const CardIcon = styled.img`
    height: 40px;
`;

export function CardModal({ onAction, onClose }: { onAction: () => void; onClose: () => void }) {
    const notificationSvc = useDi(NotificationService);
    const [cardRef, setCardRef] = useState<Tokenizeable | null>();
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [add1, setAdd1] = useState('');
    const [add2, setAdd2] = useState('');
    const [city, setCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [zip, setZip] = useState(0);
    const [country, setCountry] = useState('');
    const [isPrimary, setIsPrimary] = useState(false);
    const { classes } = useStyles();
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [readyToAdd, setReadyToAdd] = useState(false);
    const [card, setCard] = useState<Card>();
    const [isCardValid, setIsCardValid] = useState(false);
    const [isCardCvvValid, setIsCardCvvValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const tokenize = useCallback(() => {
        setOverlayVisible(true);
        cardRef
            ?.tokenize({
                firstName: fName,
                lastName: lName,
                billingAddr1: add1,
                billingAddr2: add2,
                billingCity: city,
                billingState: billingState,
                billingZip: zip.toString(),
                billingCountry: country,
            })
            .then((a) => {
                AddPayment(a.token, isPrimary);
            });
    }, [cardRef, AddPayment]);

    async function AddPayment(token: string, isPrimary: boolean): Promise<void> {
        const added = await postPaymentAddPaymentSource(card as Card, { token: token, isPrimary: isPrimary });
        const icon = <i className="ti ti-circle-check"></i>;
        if (added) {
            onAction();
            onClose();
            notificationSvc.notify('Successfully added payment method', '', 'success', icon);
        } else {
            setOverlayVisible(false);
            setShowErrorMsg(true);
        }
    }

    function handleCheckBox(event: any) {
        if (event.target.checked) {
            setIsPrimary(true);
        } else {
            setIsPrimary(false);
        }
    }

    function checkOnChange(event: any) {
        if (event.field == 'number') {
            if (event.complete && !event.error) {
                setIsCardValid(true);
            } else {
                setIsCardValid(false);
            }
        }

        if (event.field == 'cvv') {
            if (event.complete && !event.error) {
                setIsCardCvvValid(true);
            } else {
                setIsCardCvvValid(false);
            }
        }

        if (event.field == 'expiry') {
            if (event.complete && !event.error) {
                setIsCardExpiryValid(true);
            } else {
                setIsCardExpiryValid(false);
            }
        }
    }

    useEffect(() => {
        if (isCardValid && isCardCvvValid && isCardExpiryValid && fName && lName && add1 && city && billingState && country && zip) {
            setReadyToAdd(true);
        } else {
            setReadyToAdd(false);
        }
    }, [isCardValid, isCardCvvValid, isCardExpiryValid, fName, lName, add1, city, billingState, country, zip]);

    useEffect(() => {
        setCard({
            FirstName: fName,
            LastName: lName,
            BillingAddr1: add1,
            BillingAddr2: add2,
            BillingCity: city,
            BillingState: billingState,
            BillingCountry: country,
            BillingZip: zip.toString(),
        });
    }, [fName, lName, add1, add2, city, billingState, zip, country]);

    const errorMsg = (
        <>
            <Space h={20}></Space>
            <Alert
                icon={<i className="ti ti-alert-circle"></i>}
                title={
                    <Text color={colorPalette.errorTitleText} weight={600} size={14}>
                        Error adding payment method!
                    </Text>
                }
                color="error"
                sx={{ backgroundColor: colorPalette.errorBgColor }}
                withCloseButton
                onClose={() => setShowErrorMsg(false)}
            >
                <Text color={colorPalette.errorTitleText} weight={400} size={14}>
                    Please double check the card info.
                </Text>
            </Alert>
            <Space h={20}></Space>
        </>
    );

    return (
        <AddCardContainer>
            <div style={{ height: '525px', overflow: 'auto' }}>
                <LoadingOverlay visible={overlayVisible} overlayBlur={2} />
                <Group>
                    <TextInput
                        label="First name"
                        required
                        onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setFName(e.currentTarget.value)}
                        className={classes.nonCardItems}
                        sx={{ width: 258 }}
                    />

                    <TextInput
                        label="Last name"
                        required
                        onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setLName(e.currentTarget.value)}
                        className={classes.nonCardItems}
                        sx={{ width: 258 }}
                    />
                </Group>
                <Space h={10}></Space>
                <CardComponent ref={(v: any) => setCardRef(v)}>
                    <Group>
                        <div className={classes.cardNumber}>
                            <label>Card number</label>

                            <CardNumber className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                        </div>
                        <Space h={10}></Space>
                        <div className={classes.cardCvv}>
                            <label>CVC</label>
                            <CardCVV placeholder="000" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                        </div>
                    </Group>
                    <Space h={10}></Space>
                    <div className={classes.cardExpiry}>
                        <label>Expiry</label>
                        <CardExpiry placeholder="MM / YY" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                    </div>
                </CardComponent>
                <Space h={20}></Space>
                <Checkbox
                    label="Save as primary payment method"
                    color={colorPalette.subHeaderTextColor}
                    onChange={(e) => {
                        handleCheckBox(e);
                    }}
                    className={classes.primaryBox}
                />
                <Space h={10}></Space>
                <div style={{ marginRight: '16px' }}>
                    <Divider
                        my="xs"
                        label={
                            <Text size={14} weight={500} color={colorPalette.subTextColor}>
                                Billing Information
                            </Text>
                        }
                        labelPosition="center"
                    ></Divider>
                    <Space h={10}></Space>
                    <TextInput
                        label="Street address"
                        required
                        onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setAdd1(e.currentTarget.value)}
                        className={classes.nonCardItems}
                    />
                    <Space h={10}></Space>

                    <TextInput
                        label="Apt, suite, etc (optional)"
                        onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setAdd2(e.currentTarget.value)}
                        className={classes.nonCardItems}
                    />
                    <Space h={10}></Space>
                    <Group>
                        <TextInput
                            label="City"
                            required
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setCity(e.currentTarget.value)}
                            className={classes.nonCardItems}
                            sx={{ width: 258 }}
                        />

                        <CountryPicker onChange={(val) => setCountry(val ?? '')} value={country} />
                    </Group>
                    <Space h={10}></Space>
                    <Group>
                        <TextInput
                            label="State"
                            required
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setBillingState(e.currentTarget.value)}
                            className={classes.nonCardItems}
                            sx={{ width: 140 }}
                        />
                        <NumberInput
                            hideControls
                            minLength={5}
                            maxLength={5}
                            required
                            label="ZIP/Postal code"
                            onChange={(e) => setZip(e ?? 0)}
                            className={classes.nonCardItems}
                            sx={{ width: 140 }}
                        />
                    </Group>
                    <Space h={16}></Space>
                </div>
            </div>
            {showErrorMsg ? errorMsg : ''}
            <Group>
                <Button onClick={tokenize} sx={{ width: '258px' }} disabled={!readyToAdd}>
                    <Text weight={600} size={16}>
                        Add Card
                    </Text>
                </Button>{' '}
                <Button variant="outline" sx={{ backgroundColor: colorPalette.white, width: '258px' }} onClick={onClose}>
                    <Text weight={600} size={16}>
                        Cancel
                    </Text>
                </Button>
            </Group>
        </AddCardContainer>
    );
}

export function CspModal({ onAction, onClose }: { onAction: () => void; onClose: () => void }) {
    return <div style={{ height: '400px', overflow: 'auto' }}>Hello</div>;
}

const AddCardContainer = styled.div``;
