import { postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { CompanyStat } from '@apis/Customers/model/companyStat';
import styled from '@emotion/styled';
import { Box, Button, Card, Grid, Group, Space, Text } from '@mantine/core';
import { PieChart } from '@root/Components/Charts/PieChart';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useEffect, useState } from 'react';
import { AssessmentsPage } from './AssessmentsPage';
import { CardHeader, CardStyle, getAssessmentColor } from './Design';
import { MspAssessmentResultRollup, MspCalcScores, Score, getMspSummary } from './Model';

export function MspSummary() {
    const fmtSvc = useDi(FormatService);

    const [mspAssessments, setMspAssessments] = useState<MspAssessmentResultRollup[]>();
    const [mspStats, setMspStats] = useState<CompanyStat[]>();
    const [numCompanies, setNumCompanies] = useState<number>(0);

    const [avgOptimizationScore, setAvgOptimizationScore] = useState<number>(0);
    const [topOptimization, setTopOptimization] = useState<MspAssessmentResultRollup[]>();
    const [bottomOptimization, setBottomOptimization] = useState<MspAssessmentResultRollup[]>();
    const [optimizationSavings, setOptimizationSavings] = useState<number>(0);
    const [optimizationOpportunities, setOptimizationOpportunities] = useState<number>(0);

    const [avgComplianceScore, setAvgComplianceScore] = useState<number>(0);
    const [topCompliance, setTopCompliance] = useState<MspAssessmentResultRollup[]>();
    const [bottomCompliance, setBottomCompliance] = useState<MspAssessmentResultRollup[]>();
    const [complianceSavings, setComplianceSavings] = useState<number>(0);
    const [complianceOpportunities, setComplianceOpportunities] = useState<number>(0);

    const [progressBarScore, setProgressBarScore] = useState<number>(50);

    useEffect(() => {
        (async () => {
            const companyList = await queryBuilder<CompanyStat>()
                .take(1000)
                .select((b) => ({
                    CompanyName: b.model.CompanyName,
                    Id: b.model.Id,
                }))
                .execute(postCompanyStatsQueryCompanyStats);
            setMspStats(companyList.Results ?? []);

            const results = await getMspSummary(companyList.Results?.map((f) => f.Id) ?? []);

            setMspAssessments(results.Results ?? []);
        })();
    }, []);

    useEffect(() => {
        setNumCompanies(new Set(mspAssessments?.map((m) => m.companyId)).size);

        const optimizationResults = mspAssessments?.filter((f) => f.category == 'Optimization');
        const optScores = MspCalcScores(optimizationResults ?? []) as Score;
        setAvgOptimizationScore(optScores.totalScore);
        setOptimizationSavings(optScores.savings);
        setOptimizationOpportunities(optScores.totalCount);
        setTopOptimization(topThree(optimizationResults ?? []));
        setBottomOptimization(bottomThree(optimizationResults ?? []));

        const complianceResults = mspAssessments?.filter((f) => f.category == 'Compliance');
        const compScores = MspCalcScores(complianceResults ?? []) as Score;
        setAvgComplianceScore(compScores.totalScore);
        setComplianceSavings(compScores.savings);
        setComplianceOpportunities(compScores.totalCount);
        setTopCompliance(topThree(complianceResults ?? []));
        setBottomCompliance(bottomThree(complianceResults ?? []));

        setProgressBarScore(
            optScores.totalCount + compScores.totalCount > 0 ? (optScores.totalCount / (optScores.totalCount + compScores.totalCount)) * 100 : 50
        );
    }, [mspAssessments]);

    function topThree(results: MspAssessmentResultRollup[]) {
        return results.sort((a, b) => sort(a, b)).slice(0, 3);
    }

    function bottomThree(results: MspAssessmentResultRollup[]) {
        return results?.sort((a, b) => sort(a, b)).slice(results.length - 3, results.length);
    }

    function sort(a: MspAssessmentResultRollup, b: MspAssessmentResultRollup) {
        if (b.score > a.score) {
            return 1;
        } else if (b.score < a.score) {
            return -1;
        } else if (b.savings < a.savings) {
            return 1;
        } else {
            return -1;
        }
    }

    const nav = useNav();
    const switchTab = (tab: string) => nav.replaceParams({ tab });

    return (
        <AssessmentsPage
            title="MSP Summary"
            titleRightPlaceholder={
                <Button data-atid="MspReviewAssessments:MSP Summary" onClick={() => switchTab('portfolio')}>
                    Review Assessments
                </Button>
            }
        >
            {mspAssessments && mspStats ? (
                <Grid>
                    <Grid.Col span={6}>
                        <Grid>
                            <Grid.Col span={4}>
                                <Card component={CardStyle}>
                                    <Text component={CardHeader}>Companies in Portfolio</Text>
                                    <Text component={DarkBlue} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '30px' }}>
                                        {numCompanies ?? 0}
                                    </Text>
                                </Card>
                            </Grid.Col>
                            <Grid.Col span={4}>
                                <Card component={CardStyle}>
                                    <Text component={CardHeader}>Avg. Optimization</Text>
                                    <Space h={16} />
                                    <ScoreCell size="big" backgroundColor={getAssessmentColor(avgOptimizationScore / 100) as unknown as CustomColors}>
                                        {Math.round(avgOptimizationScore)}
                                    </ScoreCell>
                                </Card>
                            </Grid.Col>
                            <Grid.Col span={4}>
                                <Card component={CardStyle}>
                                    <Text component={CardHeader}>Avg. Compliance</Text>
                                    <Space h={16} />
                                    <ScoreCell size="big" backgroundColor={getAssessmentColor(avgComplianceScore / 100) as unknown as CustomColors}>
                                        {Math.round(avgComplianceScore)}
                                    </ScoreCell>
                                </Card>
                            </Grid.Col>
                        </Grid>
                        <Space h={16} />
                        <Card component={CardStyle}>
                            <Text component={CardHeader}>Portfolio Potential Savings</Text>
                            <Grid>
                                <Grid.Col span={5}>
                                    <Box sx={{ height: 250, width: 250 }}>
                                        <PieChart
                                            data={[
                                                { label: 'Optimization Savings', value: optimizationSavings },
                                                { label: 'Compliance Savings', value: complianceSavings },
                                            ]}
                                            groups={['label']}
                                            values={['value']}
                                            settings={{
                                                chartColors: ['#00609f', '#67c0e5'],
                                                enableArcLinkLabels: false,
                                                enableArcLabels: false,
                                                showTotal: false,
                                            }}
                                        />
                                    </Box>
                                </Grid.Col>
                                <Grid.Col span={7}>
                                    <Box component={LightBlueBox}>
                                        <Text>TOTAL</Text>
                                        <Text component={LightBlueBoxNumber}>
                                            {fmtSvc.formatMoneyNoDecimals(optimizationSavings + complianceSavings)}
                                        </Text>
                                    </Box>
                                    <Box color="dark" component={SavingsBox}>
                                        <Group position="apart">
                                            <Text component={White}>Optimization Savings</Text>
                                            <Text component={WhiteNumber}>{fmtSvc.formatMoneyNoDecimals(optimizationSavings)}</Text>
                                        </Group>
                                    </Box>
                                    <Space h={8} />
                                    <Box color="light" component={SavingsBox}>
                                        <Group position="apart">
                                            <Text component={White}>Compliance Savings</Text>
                                            <Text component={WhiteNumber}>{fmtSvc.formatMoneyNoDecimals(complianceSavings)}</Text>
                                        </Group>
                                    </Box>
                                </Grid.Col>
                            </Grid>
                        </Card>
                        <Space h={16} />
                        <Card component={CardStyle}>
                            <Text component={CardHeader}>Portfolio Potential Opportunities</Text>
                            <Grid>
                                <Grid.Col span={4}>
                                    <Box component={LightBlueBox}>
                                        <Text>TOTAL</Text>
                                        <Text component={LightBlueBoxNumber}>
                                            {fmtSvc.formatInt(optimizationOpportunities + complianceOpportunities)}
                                        </Text>
                                    </Box>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Text>
                                        <ProgressBar score={progressBarScore}></ProgressBar>
                                    </Text>
                                    <Group position="apart">
                                        <Group>
                                            <Text style={{ fontSize: '12px' }}>Optimization Opp.</Text>
                                            <Text component={DarkBlue} style={{ fontWeight: 'bold' }}>
                                                {fmtSvc.formatInt(optimizationOpportunities)}
                                            </Text>
                                        </Group>
                                        <Group>
                                            <Text style={{ fontSize: '12px' }}>Compliance Opp.</Text>
                                            <Text component={MediumBlue} style={{ fontWeight: 'bold' }}>
                                                {fmtSvc.formatInt(complianceOpportunities)}
                                            </Text>
                                        </Group>
                                    </Group>
                                </Grid.Col>
                            </Grid>
                        </Card>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Card component={CardStyle}>
                            <Text component={CardHeader}>Optimization - Top and Bottom Companies</Text>
                            <Space h={8} />
                            {TableHeader('Optimization', 'Potential')}
                            {topOptimization?.map((m, i) => TableRow(m, GetCompanyName(mspStats, m.companyId), i))}
                            <Space h={8} />
                            {bottomOptimization?.map((m, i) => TableRow(m, GetCompanyName(mspStats, m.companyId), numCompanies - (3 - i)))}
                        </Card>
                        <Space h={16} />
                        <Card component={CardStyle}>
                            <Text component={CardHeader}>Compliance - Top and Bottom Companies</Text>
                            <Space h={8} />
                            {TableHeader('Compliance', 'Risk')}
                            {topCompliance?.map((m, i) => TableRow(m, GetCompanyName(mspStats, m.companyId), i))}
                            <Space h={8} />
                            {bottomCompliance?.map((m, i) => TableRow(m, GetCompanyName(mspStats, m.companyId), numCompanies - (3 - i)))}
                        </Card>
                    </Grid.Col>
                </Grid>
            ) : (
                <></>
            )}
        </AssessmentsPage>
    );
}

function GetCompanyName(companyStats: CompanyStat[], companyId: number) {
    return companyStats.find((f) => f.Id == companyId)?.CompanyName ?? 'Unknown';
}

function TableHeader(scoreType: string, savingsType: string) {
    return (
        <Grid gutter={0}>
            <Grid.Col span={1}>
                <Card component={TableHeaderCell}>Rank</Card>
            </Grid.Col>
            <Grid.Col span={6}>
                <Card component={TableHeaderCell} style={{ textAlign: 'left' }}>
                    Company
                </Card>
            </Grid.Col>
            <Grid.Col span={3}>
                <Card component={TableHeaderCell}>{scoreType} Score</Card>
            </Grid.Col>
            <Grid.Col span={2}>
                <Card component={TableHeaderCell} style={{ textAlign: 'right' }}>
                    {savingsType} Savings
                </Card>
            </Grid.Col>
        </Grid>
    );
}

function TableRow(row: MspAssessmentResultRollup, companyName: string, index: number) {
    const fmtSvc = useDi(FormatService);
    return (
        <>
            <Grid gutter={0}>
                <Grid.Col span={1}>
                    <Card component={TableCellSolidBackground} style={{ padding: 0, textAlign: 'center' }}>
                        <Card component={TableCellCompanyNumber}>{index + 1}</Card>
                    </Card>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Card style={{ padding: 8 }} component={TableCell}>
                        {companyName}
                    </Card>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Card style={{ padding: 8 }} component={TableCell}>
                        <ScoreCell size="small" backgroundColor={getAssessmentColor(Math.round(row.score)) as unknown as CustomColors}>
                            {Math.round(row.score)}
                        </ScoreCell>
                    </Card>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Card style={{ padding: 8, textAlign: 'right' }} component={TableCellSolidBackground}>
                        {fmtSvc.formatMoneyNoDecimals(row.savings)}
                    </Card>
                </Grid.Col>
            </Grid>
            <Space h={2} />
        </>
    );
}

export const TableHeaderCell = styled.div`
    padding: 5px;
    text-align: center;
    color: #999999;
    font-size: 12px;
`;

export const TableCell = styled.div`
    background: linear-gradient(270deg, #ffffff 2px, #f2f4f7 2px);
    border-radius: 0px;
    line-height: 25px;
`;

export const TableCellSolidBackground = styled.div`
    background-color: #f2f4f7;
    border-radius: 0px;
    line-height: 25px;
`;

export const TableCellCompanyNumber = styled.div`
    background-color: #ffffff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 8px 0 0 0;
    margin: 0;
    height: 41px;
`;

export const DarkBlue = styled.div`
    color: #00609f;
`;

export const MediumBlue = styled.div`
    color: #67c0e5;
`;
export const LightBlue = styled.div`
    color: #dff6ff;
`;
export const White = styled.div`
    color: #ffffff;
`;
export const WhiteNumber = styled.div`
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
`;

export const LightBlueBox = styled.div`
    background-color: #dff6ff;
    border-radius: 10px;
    padding: 15px;
    margin: 25px;
    width: calc(100% - 25px);
`;

export const LightBlueBoxNumber = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #00609f;
`;

export const SavingsBox = styled.div<{ color: 'light' | 'dark' }>`
    width: calc(100% - 100px);
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: ${(p) => (p.color === 'light' ? '#67c0e5' : '#00609f')};
`;

const ProgressBar = styled.div<{ score: number }>`
    & {
        background: #67c0e5;
        border-radius: 20px;
        height: 40px;
        width: calc(100% - 15px);
        margin: 25px 10px 10px 10px;
    }
    &:after {
        content: '';
        display: block;
        background: #0000cc;
        width: ${({ score = 100 }) => score}%;
        height: 100%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
`;

const ScoreCell = styled.div<{ size: 'big' | 'small'; backgroundColor: string }>`
    text-align: center;
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    height: ${(p) => (p.size === 'big' ? '30px' : '25px')};
    margin: 0px auto;
    width: 60px;
    font-size: ${(p) => (p.size === 'big' ? '20px' : '16px')};
    line-height: ${(p) => (p.size === 'big' ? '20px' : '16px')};
    position: relative;
    background-color: ${(p) => p.backgroundColor};
`;
