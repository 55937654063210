import { Query, QuerySortExpr } from '@apis/Invoices/model';
import { JobOfCostForecast, QueryExpr, QueryField, QueryResult } from '@apis/Resources';
import { IQueryExpr } from '@apis/Resources/model';
import { Button, Space, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { ActivityPanelModel } from '@root/Components/Actions/ActivityPanel/Models';
import { IDashboardConfig } from '@root/Components/DashboardLayout/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataGridState, GridColumnState, IDataSource, ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { IValueProviderFactory } from '@root/Components/Filter/Filters';
import { Picker } from '@root/Components/Picker/Picker';
import { TooltipWhite } from '@root/Design/Primitives';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { CommonForecastDetails, CostForecastPersistence, getGroupOptions } from '@root/Services/Invoices/CostForecastService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { exprBuilder, queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { addDays, addMonths, format } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Check, FileSpreadsheet } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { ForecastRangeInfo } from './Models';

type IntervalOptions = number | 'month';

export function CostForecastsGrid({
    persistenceKey,
    invoiceApi,
    forecastDetails,
    job,
    range,
    onSelectionChanged,
    onFilterChanged,
    selectionStrategy,
    renderRowSelector,
    rightPlaceholder,
    onShowHistoricalChanged,
    exportHandler,
}: {
    persistenceKey: string;
    invoiceApi: InvoiceApiService;
    job?: JobOfCostForecast;
    forecastDetails?: CommonForecastDetails;
    range: ForecastRangeInfo;
    onFilterChanged?: (filters: QueryExpr[]) => void;
    onSelectionChanged?: (selectionState: { getItems: () => Promise<CostForecastRow[]> }) => void;
    selectionStrategy: ISelectionStrategy<CostForecastRow>;
    renderRowSelector?: (
        item: CostForecastRow | null,
        selectionState: { selected?: boolean; some?: boolean; all?: boolean; toggle: (selected: boolean) => void },
        isHeader: boolean
    ) => ReactNode;
    rightPlaceholder?: ReactNode;
    onShowHistoricalChanged?: (value: boolean) => void;
    exportHandler: (filters: IQueryExpr[], showHistorical: boolean) => Promise<void>;
}) {
    const theme = useMantineTheme();
    const container = useDiContainer();

    const modelKey = JSON.stringify([job?.Id]);
    const model = useMemo(() => {
        return container
            .resolve(CostForecastsGridModel)
            .init(job ?? forecastDetails, invoiceApi, range?.intervalOptions)
            .setOnFilterChanged(onFilterChanged);
    }, [modelKey]);
    useEffect(() => {
        model.setOnFilterChanged(onFilterChanged);
    }, [onFilterChanged]);

    const selectionProps = onSelectionChanged ? { selectionMode: 'multiple' as 'multiple', onSelectedChanged: onSelectionChanged } : {};
    const groupByFieldPicker = useMemo(
        () => (select: (selection: { id: string }) => void) => <GroupByFieldPicker model={model} select={select} />,
        [model]
    );

    const interval = useEventValue(model.intervalUpdated)!;
    const showHistorical = useEventValue(model.showHistoricalUpdated)!;
    const intervalOptions = (showHistorical ? range?.intervalOptions : range?.forecastIntervalOptions) ?? [];

    const toggleHistorical = useCallback(
        (value: boolean) => {
            const intervalOptions = (value ? range?.intervalOptions : range?.forecastIntervalOptions) ?? [];
            const interval = intervalOptions[0] ?? 'month';
            model.updateShowHistorical(value, interval);
        },
        [range, model]
    );

    useEffect(() => {
        onShowHistoricalChanged?.(showHistorical);
    }, [showHistorical]);

    const toggleInterval = useCallback(
        (value: number | 'month') => {
            model.updateInterval(value);
        },
        [range, showHistorical]
    );

    const onStateSaving = useCallback((state: IDashboardConfig) => model.handleStateSaving(state), [model]);
    const onStateLoaded = useCallback((state?: IDashboardConfig) => model.handleStateLoaded(state), [model]);
    const gridKey = JSON.stringify([job?.Id]);

    return (
        <DataGrid
            key={gridKey}
            childAccessor={model.childAccessor}
            columns={model.columns}
            dataSource={model.datasource}
            statePersistence={persistenceKey ? { key: persistenceKey } : undefined}
            onStateLoaded={onStateLoaded}
            onStateSaving={onStateSaving}
            allowSavedViews={!job}
            onModelLoaded={model.attach}
            onRowClick={model.onGridRowClick}
            {...selectionProps}
            selectionStrategy={selectionStrategy}
            renderRowSelector={renderRowSelector}
            state={model.defaultState}
            allowGroupBy
            groupByAsRows
            groupByLabels={{ count: 'Cost' }}
            groupByFieldPicker={groupByFieldPicker}
            groupByDisableSort
            groupByRequired={1}
            filterValueProvider={model.filterValueProvider}
            groupConfig={model.groupConfig}
            showHeaderGroups={showHistorical}
            allowNonColumnFilters
            hideGlobalSearch
            indentLeafNodes
            hideMenu
            renderFooter
            footerPosition="top"
            rightToolPlaceHolder={
                <>
                    <TooltipWhite label="Adjust Column Grouping">
                        <Button.Group my={5}>
                            {intervalOptions.map((opt) => (
                                <Button
                                    key={opt}
                                    sx={{ height: 30, fontWeight: 'normal' }}
                                    variant="outline"
                                    radius="lg"
                                    leftIcon={interval === opt ? <Check size={16} /> : undefined}
                                    onClick={() => toggleInterval(opt)}
                                    data-atid={`Interval-${opt}`}
                                >
                                    {opt === 1 ? 'Day' : opt === 'month' ? 'Month' : opt === 7 ? 'Week' : `${opt} Days`}
                                </Button>
                            ))}
                        </Button.Group>
                    </TooltipWhite>
                    <Space w={10} />

                    <Button.Group my={5}>
                        <Button
                            sx={{ height: 30, fontWeight: 'normal' }}
                            variant="outline"
                            radius="lg"
                            onClick={() => toggleHistorical(true)}
                            data-atid="ShowHistorical"
                            leftIcon={showHistorical ? <Check size={16} /> : undefined}
                        >
                            Historical
                        </Button>
                        <Button
                            sx={{ height: 30, fontWeight: 'normal' }}
                            variant="outline"
                            radius="lg"
                            onClick={() => toggleHistorical(false)}
                            leftIcon={!showHistorical ? <Check size={16} /> : undefined}
                            data-atid="HideHistorical"
                        >
                            Forecast Only
                        </Button>
                    </Button.Group>
                    <Space w="md" />
                    <ExportButton exportHandler={exportHandler} model={model} />
                    <Space w="md" />
                    {rightPlaceholder}
                    <Space w="md" />
                </>
            }
        />
    );
}

function ExportButton({
    model,
    exportHandler,
}: {
    model: CostForecastsGridModel;
    exportHandler: (filters: IQueryExpr[], showHistorical: boolean) => Promise<void>;
}) {
    useEvent(model.totalRecords);
    const isExporting = useEventValue(model.exporting);
    const exportData = useCallback(async () => {
        await model.export(exportHandler);
    }, [exportHandler, model]);
    const exportValidationIssues = model.exportValidationIssues();
    return (
        <TooltipWhite label={`Export disabled, ${exportValidationIssues}`} disabled={!exportValidationIssues.length}>
            <Button
                sx={{ height: 30, fontWeight: 'normal' }}
                radius="lg"
                onClick={exportData}
                disabled={isExporting || !!exportValidationIssues.length}
                data-atid="ExportForecast"
                leftIcon={<FileSpreadsheet size={16} />}
            >
                Export
            </Button>
        </TooltipWhite>
    );
}

type DateProp = `date${number}-${number}-${number}`;
type CostForecastRowHistogram = Record<`date${DateProp}`, number>;
export type CostForecastRow = {
    parent?: CostForecastRow;
    value: string;
    type: string;
    depth: number;
    children?: CostForecastRow[];
    nullValue: boolean;
    total: number;
} & CostForecastRowHistogram;

@injectable()
export class CostForecastsGridModel {
    private onFilterChanged?: (filters: QueryExpr[]) => void;
    private forecastRequest?: CommonForecastDetails;
    private forecastJobId?: string;
    private filtersKey = '';
    private queryApi!: (query: Query) => Promise<QueryResult<any>>;
    private range!: Exclude<ReturnType<CostForecastPersistence['getForecastRange']>, undefined>;
    private get interval() {
        return this.intervalUpdated.value;
    }
    private get showHistorical() {
        return this.showHistoricalUpdated.value;
    }

    private gridModel?: DataGridModel;
    private sortDatasource?: (state: DataGridState) => void;
    private invalidateDatasource?: () => void;
    private totalRowCache = {
        rootData: [] as CostForecastRow[],
        totalRow: undefined as undefined | { [key in keyof CostForecastRow]: number },
    };

    public totalRecords = new EventEmitter<number | undefined>(undefined);
    public datasource!: IDataSource<CostForecastRow>;
    public columns!: ColumnConfig<CostForecastRow>[];
    public groups!: { label: string; value: string }[];
    public defaultState?: DataGridState;
    public rootDataUpdated = new EventEmitter<CostForecastRow[]>([]);
    public intervalUpdated = new EventEmitter<IntervalOptions>(1);
    public showHistoricalUpdated = new EventEmitter<boolean>(true);
    public exporting = new EventEmitter(false);
    public totalRow?: { [key in keyof CostForecastRow]: number };
    public filterValueProvider?: IValueProviderFactory;
    public includeTotal: boolean = false;
    public groupConfig = { Historical: { color: '#B0E8FF' }, Forecast: { color: '#FDB022' } };

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(CostForecastPersistence) private readonly forecastPersistence: CostForecastPersistence,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(ActivityPanelModel) private readonly activityPanel: ActivityPanelModel
    ) {}

    public init(job: JobOfCostForecast | CommonForecastDetails | undefined, invoiceApi: InvoiceApiService, intervalOptions?: (number | 'month')[]) {
        this.forecastRequest = !job ? undefined : 'Parameters' in job ? this.forecastPersistence.getCommonDetailsFromJob(job) : job;
        this.forecastJobId = job && 'ResultDetail' in job ? job?.ResultDetail?.ForecastRequestJobId : '';
        this.intervalUpdated.emit(intervalOptions?.[0] ?? 'month');
        this.queryApi = (query: Query) => invoiceApi.queryForecastData(query, this.forecastJobId);
        this.initialize();
        return this;
    }

    public exportValidationIssues() {
        const results: string[] = [];
        const records = this.totalRecords.value;
        if (records === undefined) {
            results.push(`Loading record count...`);
        } else if (records <= 0) {
            results.push(`No records to export`);
        }
        return results;
    }

    public updateInterval(interval: IntervalOptions) {
        this.updateColOptions(undefined, interval, true);
    }

    public updateShowHistorical(showHistorical: boolean, interval: IntervalOptions) {
        this.updateColOptions(showHistorical, interval, true);
    }

    public updateColOptions(showHistorical: undefined | boolean, interval: IntervalOptions, save: boolean) {
        let changed = false;
        if (showHistorical !== undefined && showHistorical !== this.showHistorical) {
            this.showHistoricalUpdated.emit(showHistorical);
            this.updateRecordCount();
            changed = true;
        }
        if (interval !== this.interval) {
            this.intervalUpdated.emit(interval);
            changed = true;
        }
        if (changed) {
            this.reinit(save);
        }
    }

    public async export(exportHandler: (filters: IQueryExpr[], showHistorical: boolean) => Promise<void>) {
        const openActivity = this.activityPanel.toggleRequested.emit;
        try {
            this.exporting.emit(true);
            exportHandler(this.gridModel?.gridState.filters ?? [], this.showHistorical);

            this.notificationSvc.notify(
                'Export Cost Forecast',
                `Export Requested. Click to check the activity log for download.`,
                'primary',
                <ThemeIcon variant="light" size="xl" radius="xl">
                    <FileSpreadsheet />
                </ThemeIcon>,
                openActivity
            );
        } catch {
            this.notificationSvc.notify(
                'Export Failed',
                `Error: Export failed. Click to check the activity log for details.`,
                'error',
                null,
                openActivity
            );
        } finally {
            this.exporting.emit(false);
        }
    }

    private reinit(save: boolean) {
        if (this.gridModel) {
            this.gridModel.invalidateColumns();
            this.columns = this.createColumns();
            const { columns, sort } = this.createDefaultState();
            this.gridModel.gridState.columns = columns;
            this.gridModel.gridState.sort = sort;
            if (save) {
                this.gridModel.saveState();
            }
        }
        this.invalidateDatasource?.();
        this.datasource = this.createDatasource();
    }

    public async initialize() {
        this.range = this.forecastPersistence.getForecastRange(this.forecastRequest)!;
        this.groups = this.getForecastGroups();
        this.columns = this.createColumns();
        this.datasource = this.createDatasource();
        this.filterValueProvider = this.createFilterValueProvider();
        this.defaultState = this.createDefaultState();
    }

    public setOnFilterChanged(handler: ((filters: QueryExpr[]) => void) | undefined) {
        if (handler !== this.onFilterChanged) {
            this.onFilterChanged = handler;
        }
        return this;
    }

    private getForecastGroups() {
        const selectedGroupFields = this.forecastRequest?.groups ?? [];
        const groupLabels = getGroupOptions(selectedGroupFields.filter((g) => g.includes('resourceTags')) ?? []);
        const groups = groupLabels.filter((l) => selectedGroupFields.includes(l.value));

        return groups;
    }

    private createFilterValueProvider() {
        return {
            getValueProvider: (field: QueryField) => {
                return async (filter: string, max: number = 100) => {
                    const qb = queryBuilder<Record<string, string>>();
                    if (filter) {
                        qb.where((b) => b.model[field.Field].contains(filter));
                    }
                    const queryResult = await qb
                        .take(max)
                        .select((b) => ({
                            value: { Operation: 'values', Operands: [{ Field: field.Field }, { Value: filter }] } as unknown as string,
                            count: b.count(),
                        }))
                        .execute(this.queryApi);

                    const comparer = new Intl.Collator(undefined, { sensitivity: 'base' }).compare;
                    return queryResult.Results?.map((r) => r.value).sort(comparer);
                };
            },
        };
    }

    public getTotalRow() {
        const rootData = this.rootDataUpdated.value;
        if (rootData !== this.totalRowCache.rootData) {
            const keys = [
                ...this.getDatesOfInterval().map((d) => `date${this.formatSvc.toUtcJsonShortDate(d)}` as keyof CostForecastRow),
                'total',
            ] as (keyof CostForecastRow)[];

            const result = {
                depth: 0,
                total: 0,
                type: 'total',
                value: 'Total',
            } as unknown as { [key in keyof CostForecastRow]: number };

            for (const key of keys) {
                result[key] = 0;
            }

            rootData?.forEach((row) => {
                for (const key of keys) {
                    result[key] = (result[key] ?? 0) + ((row[key] as number) ?? 0);
                }
            });

            this.totalRowCache.rootData = rootData;
            this.totalRowCache.totalRow = result;
        }

        return this.totalRowCache.totalRow!;
    }

    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        gridModel.gridStateChanged.listen((change) => {
            if (change?.changes.has('filters')) {
                this.raiseFilterChanged((change.state.filters ?? []).slice() as QueryExpr[]);
            }
            if (change?.changes.has('sort') && change.changes.size === 1) {
                this.sortDatasource?.(change.state);
            } else {
                this.invalidateDatasource?.();
            }
        });
    };

    public onGridRowClick = (row: CostForecastRow) => {
        this.gridModel?.setSelected(row, !this.gridModel?.isSelected(row));
    };

    public childAccessor = {
        hasChildren: (row: CostForecastRow) => row.depth < (this.gridModel?.gridState.groupBy?.length ?? 0) - 1,
    };

    public handleStateSaving(state: IDashboardConfig) {
        this.applyCurrentSettings(state);
        if (this.gridModel) {
            const state = this.gridModel.gridState;
            state.columns = this.columns
                .filter((c) => !c.defaultHidden)
                .map((c) => ({ id: c.id, width: c.defaultWidth, fixed: c.defaultFixed } as GridColumnState));
        }
    }

    public handleStateLoaded(state?: IDashboardConfig) {
        const settings = this.getSettings(state);
        this.raiseFilterChanged((this.gridModel?.gridState.filters ?? []).slice() as QueryExpr[]);
        this.invalidateDatasource?.();
        this.updateColOptions(settings.showHistorical, settings.interval, false);
    }

    private raiseFilterChanged(filters: QueryExpr[]) {
        this.onFilterChanged?.(filters);
        this.updateRecordCount(filters);
    }

    private async updateRecordCount(filters?: QueryExpr[]) {
        filters ??= (this.gridModel?.gridState.filters ?? []) as QueryExpr[];
        const fromDate = this.showHistorical ? this.range.historicalRange.from : this.range.forecastRange.from;
        const fromCrit = { Operation: 'gte', Operands: [{ Field: 'Date' }, { Value: this.formatSvc.toJsonShortDate(fromDate) }] };
        const finalFilters = [...filters, fromCrit];
        const reqKey = JSON.stringify(finalFilters);
        if (reqKey !== this.filtersKey) {
            this.filtersKey = reqKey;
            this.totalRecords.emit(undefined);

            const result = await queryBuilder()
                .where((b) => b.and(...finalFilters.map((f) => b.fromExpr<boolean>(f))))
                .take(0)
                .execute((q) => this.queryApi(q));
            if (reqKey === this.filtersKey) {
                this.totalRecords.emit(result.Count ?? 0);
            }
        }
    }

    private applyCurrentSettings(state?: IDashboardConfig) {
        const config = state?.layout[0].data;
        config.interval = this.interval;
        config.showHistorical = this.showHistorical;
    }

    private getSettings(state?: IDashboardConfig) {
        const config = state?.layout[0].data;
        return {
            showHistorical: config?.showHistorical ?? this.showHistorical,
            interval: config?.interval ?? this.interval,
        };
    }

    private createDefaultState() {
        return {
            filters: [],
            columns: this.columns
                .filter((c) => !c.defaultHidden)
                .map((c) => ({ id: c.id, width: c.defaultWidth, fixed: c.defaultFixed } as GridColumnState)),
            groupBy: this.groups.slice().map((g) => ({ id: g.value, sortDir: 'Asc', sortMode: 'value' })),
            sort: [{ Expr: { Field: 'value' }, Direction: 'Asc' }],
        } as DataGridState;
    }

    private createColumns() {
        const result: ColumnConfig<CostForecastRow>[] = [
            {
                accessor: 'value',
                defaultWidth: 350,
                id: 'value',
                header: 'Detail',
                type: 'string',
                defaultFixed: true,
                formatter: (item, value) => (value === ValuesGroupOtherText ? 'Other' : value),
                footerRenderer: () => {
                    return <>Total</>;
                },
            },
            ...this.groups.map(
                (g) =>
                    ({
                        accessor: g.value,
                        defaultWidth: 120,
                        id: g.value,
                        header: g.label,
                        type: 'string',
                        defaultHidden: true,
                        filter: true,
                    } as ColumnConfig<CostForecastRow>)
            ),
        ];
        if (this.range?.months.length) {
            const dates = this.getDatesOfInterval();
            for (let i = 0; i < dates.length; i++) {
                const date = dates[i];
                const dateKey = this.formatSvc.toUtcJsonShortDate(date) as `${DateProp}`;
                result.push({
                    accessor: `date${dateKey}`,
                    defaultWidth: 120,
                    id: `date${dateKey}`,
                    noResize: true,
                    header: this.interval === 'month' ? this.formatSvc.formatShortMonthYear(date) : format(date, 'LLL do'),
                    type: 'number',
                    align: 'right',
                    helpText:
                        this.interval === 'month'
                            ? undefined
                            : this.interval === 1
                            ? format(date, 'EEEE, LLL do, yyyy')
                            : `${format(date, 'LLL do, yyyy')} — ${format(addDays(date, this.interval - 1), 'LLL do, yyyy')}`,
                    groupName: this.showHistorical ? this.getGroupName(date) : undefined,
                    formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v[`date${dateKey}`]),
                    footerRenderer: () => {
                        return <TotalRowCell model={this} totalKey={`date${dateKey}`} />;
                    },
                });
            }
        }
        return result.map((c) => ({ ...c, noRemove: true, noReorder: true }));
    }

    private getDatesOfInterval() {
        return this.range.getDayIncrements(this.interval, this.showHistorical);
    }

    private getGroupName(date: Date) {
        return this.range.forecastRange.from && this.getNextDate(date) > this.range.forecastRange.from ? 'Forecast' : 'Historical';
    }

    private getNextDate(date: Date) {
        return this.interval === 'month' ? addMonths(date, 1) : addDays(date, this.interval);
    }

    private createDatasource() {
        const root = { value: 'root', type: 'root' } as CostForecastRow;
        this.sortDatasource = (state: DataGridState) => {
            const sort = state.sort?.length ? state.sort : [{ Expr: { Field: 'value' }, Direction: 'Asc' } as QuerySortExpr];
            this.visitRows(root, (row) => {
                if (row.children?.length) {
                    row.children.splice(0, Infinity, ...new ArrayDataSource(row.children).applyState({ filters: [], sort }));
                    this.gridModel?.treeModel?.invalidateItem(row);
                }
            });
            this.gridModel?.treeModel?.clearChildrenLoaded?.();
            this.gridModel?.refresh(true);
        };
        this.invalidateDatasource = () => {
            root.children = undefined;
        };
        return {
            getPage: async (start, end, state, parent) => {
                const parents = this.getParents(parent);
                parent = parent ?? root;
                if (!parent?.children) {
                    parent.children = await this.getRows(state, parents, parent === root ? undefined : parent);
                }
                if (parent === root) {
                    this.rootDataUpdated.emit(parent.children);
                }
                return { items: parent.children.slice(start, end), total: parent.children.length };
            },
        } as IDataSource<CostForecastRow>;
    }

    private visitRows(row: CostForecastRow, visitor: (row: CostForecastRow) => void) {
        visitor(row);
        if (row.children) {
            for (const child of row.children) {
                this.visitRows(child, visitor);
            }
        }
    }

    private async getRows(state: DataGridState, parents: CostForecastRow[], parent: CostForecastRow | undefined) {
        const filters = state.filters?.slice() ?? [];
        if (parents.length) {
            filters.push(
                ...parents.map((p) =>
                    p.nullValue
                        ? { Operation: 'isNull', Operands: [{ Field: p.type }] }
                        : { Operation: 'eq', Operands: [{ Field: p.type }, { Value: p.value }] }
                )
            );
        }
        const groupBy = state.groupBy?.[parents.length]?.id;
        const queryInterval = (typeof this.interval === 'number' ? this.interval + 'd' : 'month') as `${number}d` | 'month';
        const fromDate = this.showHistorical ? this.range.historicalRange.from : this.range.forecastRange.from;
        const query: Query = {
            Select: [
                { Alias: 'value', Expr: { Operation: 'values', Operands: [{ Field: groupBy }, { Value: '' }, { Value: ValuesGroupOtherText }] } },
                {
                    Alias: 'date',
                    Expr: exprBuilder<{ Date: Date }>().createExpr((b) =>
                        b.truncDate(queryInterval, b.model.Date, 0, fromDate, this.range?.forecastRange.to)
                    ),
                },
                { Alias: 'total', Expr: exprBuilder<{ ['P50']: number }>().createExpr((b) => b.sum(b.model.P50)) },
            ],
            Take: 50000,
            Where: {
                Operation: 'and',
                Operands: [
                    { Operation: 'gte', Operands: [{ Field: 'Date' }, { Value: this.formatSvc.toJsonShortDate(fromDate) }] },
                    ...(filters ?? []),
                ],
            },
        };

        const results = (await this.queryApi(query)) as QueryResult<{ value: string; date: string; total: number }>;
        const rows = [] as CostForecastRow[];
        const rowLookup = new Map<string, CostForecastRow>();
        for (const item of results.Results ?? []) {
            const date = this.formatSvc.toUtcJsonShortDate(new Date(item.date)) as DateProp;
            let row = rowLookup.get(item.value);
            if (!row) {
                rowLookup.set(
                    item.value,
                    (row = {
                        parent,
                        value: item.value,
                        type: groupBy,
                        depth: parents.length,
                        nullValue: item.value === ValuesGroupOtherText,
                        total: 0,
                    } as CostForecastRow)
                );
                rows.push(row);
            }
            row[`date${date}`] = item.total;
            row.total += item.total;
        }

        const datasource = new ArrayDataSource(rows, [
            { field: 'value', type: 'string', getValue: (item: CostForecastRow) => (item.nullValue ? '\uffff' : item.value) },
        ]);
        return datasource.applyState({ filters: [], sort: state.sort });
    }

    private getParents(parent: CostForecastRow | undefined) {
        const parents: CostForecastRow[] = [];
        while (parent) {
            parents.push(parent);
            parent = parent.parent;
        }
        return parents;
    }
}

function GroupByFieldPicker({ model, select }: { model: CostForecastsGridModel; select: (selection: { id: string }) => void }) {
    const handleChange = useCallback(
        (field: { label: string; value: string }[]) => {
            if (field.length) {
                select({ id: field[0].value });
            }
        },
        [select]
    );

    return <Picker minimizeHeight height={300} mode="single" items={model.groups} nameAccessor="label" selections={[]} onChange={handleChange} />;
}

function TotalRowCell({
    model,
    totalKey,
    renderer,
}: {
    model: CostForecastsGridModel;
    totalKey?: keyof CostForecastRow;
    renderer?: (value: { [key in keyof CostForecastRow]: number }) => ReactNode;
}) {
    const fmtSvc = useDi(FormatService);
    useEvent(model.rootDataUpdated);
    const data = model.getTotalRow();

    return <>{renderer ? renderer(data) : totalKey ? fmtSvc.formatMoneyNoDecimals(data[totalKey] ?? 0) : <Text align="center">&mdash;</Text>}</>;
}
