import { postMapContractsGetMapContractRollup } from '@apis/TagManager';
import { MapContractRollup } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Box, Card, Center, Checkbox, ColorSwatch, Divider, Grid, Group, Radio, SegmentedControl, Space, Text } from '@mantine/core';
import { LineChart } from '@root/Components/Charts/LineChart';
import { DateRangeFilter } from '@root/Components/Filter/DateRangeFilter';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MapMonitoringHomeModel } from './MapMonitoringHome';
import { TaggingDisplayPercent, TaggingDisplayTypes, TaggingSectionModel, TagMetric } from './Models';
import { ChartWrapper } from '@root/Components/Charts/Design';
import { addDays, differenceInDays, getMonth, getWeek, getYear, max, min, parseISO, startOfWeek } from 'date-fns';
import { FillerSwitch } from '@root/Design/Filler';

export function TaggingSection({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const taggingModel = useDi(TaggingSectionModel);

    useEffect(() => {
        if (!homeModel.latestContractSnapshot) taggingModel.setMetrics(null);
        taggingModel.setMetrics(homeModel.latestContractSnapshot!);
    }, [homeModel.latestContractSnapshot]);

    return (
        <Box ml={'md'}>
            <Grid style={{ width: '100%', margin: '0px' }}>
                <Grid.Col span={8} p={'none'} m={'none'}>
                    <ResourcesCard homeModel={homeModel}></ResourcesCard>
                </Grid.Col>
                <Grid.Col span={4}>
                    <PercentConfigCard homeModel={homeModel}></PercentConfigCard>
                    <OpportunitiesCard homeModel={homeModel}></OpportunitiesCard>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

const ResourcesCard = observer(function ResourcesCard({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const taggingModel = useDi(TaggingSectionModel);
    const [min, setMin] = useState<Date>();
    const [max, setMax] = useState<Date>();

    useEffect(() => {
        if (!homeModel.contract) {
            setMin(undefined);
            setMax(undefined);
            return;
        }
        setMin(homeModel.getContractStartDate());
        setMax(homeModel.getContractEndDate());

        let endDate = homeModel.getContractEndDate() ?? new Date();
        endDate = new Date() > endDate ? endDate : new Date();
        taggingModel.setDateRange({ to: endDate, from: homeModel.getContractStartDate() });
    }, [homeModel.contract]);
    return (
        <Card radius="md" my="xs" withBorder>
            <CardHeader>Resources</CardHeader>
            <Grid>
                <Grid.Col span={6}>
                    <Group>
                        <Box p={'xs'}>
                            <SegmentedControl
                                value={taggingModel.displayType}
                                onChange={(e) => taggingModel.setDisplayType(e)}
                                data={Object.values(TaggingDisplayTypes)}
                            />
                        </Box>
                    </Group>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Group position="right">
                        <Box p={'xs'}>
                            <DateRangeFilter
                                constraint={{
                                    min: min,
                                    max: max,
                                }}
                                value={taggingModel.dateRange}
                                onChange={(e) => taggingModel.setDateRange(e)}
                            />
                        </Box>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TaggingGraph homeModel={homeModel}></TaggingGraph>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TaggingGrid homeModel={homeModel}></TaggingGrid>
                </Grid.Col>
            </Grid>
        </Card>
    );
});

const TaggingGraph = observer(function TaggingGraph({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const taggingModel = useDi(TaggingSectionModel);
    const [graphData, setGraphData] = useState<Record<string, string | number>[]>([]);
    const [rawData, setRawData] = useState<MapContractRollup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [graphColors, setGraphColors] = useState<string[]>([]);
    const [interval, setInterval] = useState<string>();
    const fmtSvc = useDi(FormatService);

    useEffect(() => {
        if (!taggingModel.dateRange.from || !taggingModel.dateRange.to) {
        } else {
            const fromDate = taggingModel.dateRange.from;
            const toDate = taggingModel.dateRange.to;
            const [from, to] = [fromDate, toDate].sort((a, b) => a.getTime() - b.getTime());
            setLoading(true);
            postMapContractsGetMapContractRollup({
                startDate: from.toISOString(),
                endDate: to.toDateString(),
                mapContractId: homeModel.contract!.Id!,
            })
                .then(setRawData)
                .finally(() => setLoading(false));
        }
    }, [taggingModel.dateRange]);

    const generateLineData = (lineName: string, resourceName: string, spendName: string) => {
        if (!isMetricSelected(lineName) || !interval) return [];
        let resourceKey = resourceName as keyof MapContractRollup;
        let spendKey = spendName as keyof MapContractRollup;

        const groupedData = rawData.reduce((acc, data) => {
            let date = data.Date!;

            switch (interval) {
                case 'week':
                    date = fmtSvc.toShortDate(startOfWeek(parseISO(data.Date as any)));
                    break;
                case 'month':
                    date = `${getYear(parseISO(data.Date as any))}-${getMonth(parseISO(data.Date as any)) + 1}`;
                    break;
                default:
                    break;
            }

            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(data);
            return acc;
        }, {} as Record<string, MapContractRollup[]>);

        const lastDayData = Object.keys(groupedData).reduce((acc, key) => {
            const values = groupedData[key];
            const lastDay = values.reduce((latest, current) => {
                return new Date(latest.Date!) > new Date(current.Date!) ? latest : current;
            });
            acc[key] = [lastDay];
            return acc;
        }, {} as Record<string, MapContractRollup[]>);

        const result = Object.keys(lastDayData).map((key) => {
            const values = lastDayData[key];
            const totalAnnualized = values.reduce(
                (sum, data) => sum + ((data.TotalEligibleAnnualizedSpend ?? 0) + (data.TotalIneligibleAnnualizedSpend ?? 0)),
                0
            );
            const totalResources = values.reduce((sum, data) => sum + ((data.EligibleTotalCount ?? 0) + (data.IneligibleTotalCount ?? 0)), 0);
            const annualized = values.reduce((sum, data) => sum + ((data[spendKey] as number) ?? 0), 0);
            const resources = values.reduce((sum, data) => sum + ((data[resourceKey] as number) ?? 0), 0);

            let value: number =
                taggingModel.displayType == TaggingDisplayTypes.AnnualizedSpend
                    ? annualized / values.length
                    : taggingModel.displayType == TaggingDisplayTypes.Resources
                    ? resources / values.length
                    : taggingModel.displayType == TaggingDisplayTypes.Percent
                    ? taggingModel.displayPercent == TaggingDisplayPercent.AnnualizedSpend
                        ? totalAnnualized > 0
                            ? annualized / totalAnnualized
                            : 0
                        : totalResources > 0
                        ? resources / totalResources
                        : 0
                    : 0;

            return { date: key, name: lineName, value };
        });

        return result;
    };

    const isMetricSelected = (name: string) => {
        return taggingModel.metrics.some((m) => m.selected && m.name === name);
    };

    const generateColorData = () => {
        setGraphColors(taggingModel.metrics.filter((m) => m.selected).map((m) => m.color));
    };

    const generateGraphData = () => {
        const newGraphData: Record<string, string | number>[] = [];

        newGraphData.push(...generateLineData('Eligible', 'EligibleTotalCount', 'TotalEligibleAnnualizedSpend'));
        newGraphData.push(...generateLineData('Tagged Correctly', 'EligibleTaggedCorrectCount', 'EligibleTaggedCorrectAnnualizedSpend'));
        newGraphData.push(...generateLineData('Tagged Incorrectly', 'EligibleTaggedIncorrectCount', 'EligibleTaggedIncorrectAnnualizedSpend'));
        newGraphData.push(...generateLineData('Not Tagged', 'EligibleNotTaggedCount', 'EligibleNotTaggedAnnualizedSpend'));

        newGraphData.push(...generateLineData('Ineligible', 'IneligibleTotalCount', 'TotalIneligibleAnnualizedSpend'));
        newGraphData.push(
            ...generateLineData('Created Prior to Contract', 'IneligibleOutsideContractPeriodCount', 'IneligibleOutsideContractPeriodAnnualizedSpend')
        );
        newGraphData.push(...generateLineData('Not Covered Service', 'IneligibleNotCoveredCount', 'IneligibleNotCoveredAnnualizedSpend'));
        newGraphData.push(
            ...generateLineData('Not Eligible Workload', 'IneligibleNotEligibleWorkloadCount', 'IneligibleNotEligibleWorkloadAnnualizedSpend')
        );
        newGraphData.push(...generateLineData('Over-Tagged Spend', 'IneligibleOvertaggedCount', 'IneligibleOvertaggedAnnualizedSpend'));

        newGraphData.push(...generateLineData('Total', 'TotalResourcesCount', 'TotalAnnualizedSpend'));

        setGraphData(newGraphData);
    };

    useEffect(() => {
        if (!rawData || taggingModel.metrics.length == 0) {
            setGraphData([]);
            setGraphColors([]);
            return;
        }
        generateGraphData();
        generateColorData();
    }, [rawData, interval, taggingModel.displayType, taggingModel.displayPercent, JSON.stringify(taggingModel.metrics)]);

    useEffect(() => {
        const start = homeModel.getContractStartDate();
        const end = homeModel.getContractEndDate();
        if (!start || !end) return;
        const [dataFrom, dataTo] = rawData.reduce(
            ([from, to], item) => {
                const date = parseISO(item.Date!);
                return [date < from ? date : from, date > to ? date : to];
            },
            [end, start]
        );
        const days = differenceInDays(dataTo, dataFrom);
        const newInterval = days > 120 ? 'month' : days > 30 ? 'week' : 'day';
        setInterval(newInterval);
    }, [rawData]);

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <FillerSwitch loading={!interval}>
                {() => (
                    <>
                        <LineChart
                            data={graphData}
                            groups={['date', 'name']}
                            values={['value']}
                            settings={{
                                interval: interval,
                                direction: 'up',
                                chartColors: graphColors,
                                hidePoints: true,
                                margin: { bottom: 60, left: 90, right: 20, top: 10 },
                                format:
                                    taggingModel.displayType === TaggingDisplayTypes.Percent
                                        ? 'percent'
                                        : taggingModel.displayType === TaggingDisplayTypes.AnnualizedSpend
                                        ? 'money'
                                        : 'int',
                                enableArea: false,
                                noWrapper: true,
                            }}
                        ></LineChart>
                    </>
                )}
            </FillerSwitch>
        </Box>
    );
});

const MetricDetailsCell = observer(function MetricDetailsCell({ metric }: { metric: TagMetric }) {
    const color = metric.name === 'Eligible' ? 'primary.6' : metric.name === 'Ineligible' ? 'error.6' : 'gray.8';
    const subText = metric.name === 'Eligible' || metric.name === 'Ineligible' ? '(All)' : null;
    return (
        <Group>
            {' '}
            <Checkbox checked={metric.selected} onChange={(event) => (metric.selected = event.currentTarget.checked)} />
            <ColorSwatch color={metric.color} />
            <Text span color={color}>
                {metric.name}{' '}
                {subText && (
                    <Text span color={'gray.5'} size={'sm'}>
                        {subText}
                    </Text>
                )}
            </Text>
        </Group>
    );
});

const TaggingGrid = observer(function TaggingGrid({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const taggingModel = useDi(TaggingSectionModel);
    const formatSvc = useDi(FormatService);

    return (
        <>
            <Grid mt={'md'}>
                <Grid.Col span={5}></Grid.Col>
                <Grid.Col span={3}>
                    <Text weight={'bolder'} align={'right'}>
                        Annualized Spend
                    </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Text weight={'bolder'} align={'right'}>
                        Resources
                    </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Text weight={'bolder'} align={'right'}>
                        Percent
                    </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider />
                </Grid.Col>
                {taggingModel.metrics.map((metric) => (
                    <>
                        <Grid.Col span={5}>
                            <MetricDetailsCell metric={metric} />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text align={'right'}>{formatSvc.formatMoneyNoDecimals(metric.annualizedSpend)}</Text>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Text align={'right'} color={'primary.6'}>
                                {metric.resources}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Text align={'right'}>
                                {' '}
                                {formatSvc.formatPercentRoundDown(
                                    taggingModel.displayPercent === TaggingDisplayPercent.AnnualizedSpend
                                        ? metric.annualizedSpendPercent
                                        : metric.resourcesPercent
                                )}
                            </Text>
                        </Grid.Col>
                        {(metric.name === 'Not Tagged' || metric.name === 'Over-Tagged Spend') && (
                            <Grid.Col span={12}>
                                <Divider />
                            </Grid.Col>
                        )}
                    </>
                ))}
            </Grid>
        </>
    );
});

const PercentConfigCard = observer(function PercentConfigCard({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const taggingModel = useDi(TaggingSectionModel);
    return (
        <>
            <Card radius="md" my="xs" withBorder>
                <Radio.Group
                    label="Display Percent as:"
                    orientation="vertical"
                    spacing={6}
                    value={taggingModel.displayPercent}
                    onChange={(val) => taggingModel.setDisplayPercent(val)}
                >
                    <Radio
                        value={TaggingDisplayPercent.TotalResources}
                        label={TaggingDisplayPercent.TotalResources}
                        data-atid="MonitoringTotalResourcesRadioBtn"
                    />
                    <Radio
                        value={TaggingDisplayPercent.AnnualizedSpend}
                        label={TaggingDisplayPercent.AnnualizedSpend}
                        data-atid="MonitoringAnnualizedSpendRadioBtn"
                    />
                </Radio.Group>
            </Card>
        </>
    );
});

function OpportunitiesCard({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const formatSvc = useDi(FormatService);
    const taggingModel = useDi(TaggingSectionModel);
    const [opportunities, setOpportunities] = useState<TagMetric[]>([]);

    useEffect(() => {
        setOpportunities(taggingModel.metrics.filter((m) => m.actionable).sort((a, b) => b.annualizedSpend - a.annualizedSpend));
    }, [taggingModel.metrics]);

    function AnnualizedSpendCard({ metric }: { metric: TagMetric }) {
        return ActionCard('Annualized Spend', formatSvc.formatMoneyNoDecimals(metric.annualizedSpend));
    }

    function ResourcesCard({ metric }: { metric: TagMetric }) {
        return ActionCard('Resources', metric.resources.toString());
    }

    const ActionCard = (label: string, value: string) => {
        return (
            <Card radius={'md'} sx={{ backgroundColor: theme.colors?.gray?.[2] }}>
                <Text weight={'bolder'} size={'sm'} color={theme.colors?.gray?.[6] as CustomColors}>
                    {label}
                </Text>
                <Text weight={'bolder'} color={theme.colors?.primary?.[6] as CustomColors}>
                    {value}
                </Text>
            </Card>
        );
    };

    const eligibilityLabel = (opportunity: TagMetric) => {
        if (opportunity.name === 'Not Tagged' || opportunity.name === 'Tagged Incorrectly') return 'Eligible';
        return 'Ineligible';
    };

    return (
        <>
            <CardHeader>Opportunities To Improve</CardHeader>
            {opportunities.map((opportunity) => {
                return (
                    <Card key={opportunity.name} radius="md" my="xs" withBorder>
                        <CardHeader>
                            {eligibilityLabel(opportunity)} / {opportunity.name}
                        </CardHeader>
                        <Space h={'md'}></Space>
                        <Grid>
                            <Grid.Col span={2}>
                                <Center sx={{ height: '100%' }}>
                                    <i className="ti ti-alert-triangle-filled" style={{ fontSize: '3em', color: theme.colors?.warning?.[6] }} />
                                </Center>
                            </Grid.Col>
                            <Grid.Col span={5}>
                                <AnnualizedSpendCard metric={opportunity} />
                            </Grid.Col>
                            <Grid.Col span={5}>
                                <ResourcesCard metric={opportunity} />
                            </Grid.Col>
                        </Grid>
                    </Card>
                );
            })}
        </>
    );
}

export const CardHeader = styled.h4`
    margin: 0px 5px;
    font-weight: 1000;
`;
