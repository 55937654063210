import { getPaymentGetPrimaryPayment, getSubscriptionGetCompanyInfo } from '@apis/Customers';
import { BuyCreditsResult, CompanyInfo, PaymentSource } from '@apis/Customers/model';
import { Modal, createStyles, Card, Center } from '@mantine/core';
import { useFullscreenModal, WizardModalContent } from '@root/Components/Wizard/WizardModal';
import { colorPalette } from '@root/Design/Themes';
import { DiContext, useDiContainer } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useState } from 'react';
import { AddAddress } from './AddAddress';
import { AddPayment } from './AddPayment';
import { AddModal, PaymentModal, ThankyouModal } from './Components/AddCreditsComponents';

export function AddCredits() {
    const { open } = useFullscreenModal();
    const container = useDiContainer();
    useEffect(() => {
        open((close) => (
            <DiContext.Provider value={container}>
                <AddCreditsModal close={close} />
            </DiContext.Provider>
        ));
    }, []);
    return <></>;
}

export function AddCreditsModal({ close }: { close: () => void }) {
    const nav = useNav();
    const [modal, setModal] = useState('addModal');
    const [noofCredits, setNoofCredits] = useState(0);
    const [creditsId, setCreditsId] = useState('');
    const [price, setPrice] = useState(0);
    const [creditResults, setCreditResults] = useState<BuyCreditsResult>();
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();
    const [primaryPayment, setPrimaryPayment] = useState<PaymentSource>();

    const isCompanyValid = (companyInfo?: CompanyInfo) => {
        return (
            !companyInfo ||
            !companyInfo.BillingAddress?.AddressLine1 ||
            !companyInfo.BillingAddress?.City ||
            !companyInfo.BillingAddress?.StateCode ||
            !companyInfo.BillingAddress?.ZipCode ||
            !companyInfo.BillingAddress?.CountryCode
        );
    };

    async function setPaymentValues(credits: number, creditsId: string, price: number) {
        setNoofCredits(credits);
        setCreditsId(creditsId);
        setPrice(price);
        let company = companyInfo;
        let payment = primaryPayment;

        if (isCompanyValid(company)) {
            company = await getSubscriptionGetCompanyInfo();
            setCompanyInfo(company);
            if (isCompanyValid(company)) {
                setModal('addAddress');
                return;
            }
        }
        if (!payment) {
            payment = await getPaymentGetPrimaryPayment();
            setPrimaryPayment(payment);
            if (!payment) {
                setModal('addPayment');
                return;
            }
        }

        if (company && payment) {
            setModal('paymentModal');
        }
    }

    function setThankyouValues(result: BuyCreditsResult) {
        setCreditResults(result);
        setModal('thankyouModal');
    }
    function goBack(creditsId: string, numberOfCredits: number, price: number) {
        setCreditsId(creditsId);
        setPrice(price);
        setNoofCredits(numberOfCredits);
        setModal('addModal');
    }
    const onBack = () => {
        if (modal === 'paymentModal' || modal === 'addAddress' || modal === 'addPayment') {
            setModal('addModal');
        } else if (modal === 'thankyouModal') {
            onClose();
        } else {
            onClose();
        }
    };
    const onClose = () => {
        nav.ascend();
        close();
    };
    const title =
        modal === 'addModal'
            ? 'Add Credits'
            : modal === 'addAddress'
            ? 'Enter your company address'
            : modal === 'addPayment'
            ? 'Enter Payment Information'
            : modal === 'paymentModal'
            ? 'Confirm Details'
            : modal === 'thankyouModal'
            ? 'Thank you for your purchase'
            : '';

    return (
        <WizardModalContent title={title} onBack={onBack} onClose={onClose}>
            {modal == 'addModal' ? (
                <AddModal setSelected={setPaymentValues} creditsId={creditsId} numberOfCredits={noofCredits} cPrice={price}></AddModal>
            ) : modal == 'paymentModal' ? (
                <PaymentModal
                    credits={noofCredits}
                    creditsId={creditsId}
                    price={price}
                    close={onClose}
                    setSelected={setThankyouValues}
                    goBack={goBack}
                ></PaymentModal>
            ) : modal == 'thankyouModal' && creditResults ? (
                <ThankyouModal close={onClose} buyResult={creditResults}></ThankyouModal>
            ) : modal == 'addAddress' && companyInfo ? (
                <Center>
                    <Card withBorder p="xl" radius="md" sx={{ width: 500 }}>
                        <AddAddress onCancel={onClose} companyInfo={companyInfo} onNext={() => setPaymentValues(noofCredits, creditsId, price)} />
                    </Card>
                </Center>
            ) : modal === 'addPayment' && companyInfo ? (
                <Center>
                    <Card withBorder p="xl" radius="md" sx={{ width: 500 }}>
                        <AddPayment
                            onCancel={close}
                            onNext={() => setPaymentValues(noofCredits, creditsId, price)}
                            companyInfo={companyInfo}
                        ></AddPayment>
                    </Card>
                </Center>
            ) : null}
        </WizardModalContent>
    );
}
endpoint('add-credits', AddCredits, 'add-credits');
