import styled from '@emotion/styled';
import { useMantineTheme } from '@mantine/core';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import { colorPalette } from '@root/Design/Themes';
import { FormatService } from '@root/Services/FormatService';
import { useMemo } from 'react';
import { container } from 'tsyringe';
import { chartColors, ChartMargin, useTickSpacing } from './Common';
import { StandardChartProps } from './Models';
import { ChartWrapper } from './Design';

export function LineChart<T extends Record<string, string | number | Date>>(props: LineChartProps<T>) {
    const data = useMemo(() => tranformLineData(props), [props.data, props.groups, props.values[0], props.settings]);
    const wideText = props.settings?.labelAngle === 0;
    const textDist = !wideText ? 16 : 70;
    const tickSpacing = useTickSpacing(3, textDist);
    const margin = props.settings?.margin || { bottom: 60, left: 50, right: 10, top: 10 };
    const labelAngle = props.settings?.labelAngle;
    const yFormatter = getYFormatter(props);
    const theme = useMantineTheme();
    const hideGrid = !!props.settings?.hideGrid;
    const hideYAxis = !!props.settings?.hideYAxis;
    const showLines = props.settings?.mode === 'trend-curve' ? false : true;
    const curve = props.settings?.mode === 'trend-curve';

    const responsiveLine = (
        <ResponsiveLine
            data={data}
            margin={margin}
            animate
            colors={
                props.settings?.chartColors
                    ? props.settings.chartColors
                    : props.settings?.direction === 'up'
                    ? theme.colors.success[6]
                    : props.settings?.direction === 'down'
                    ? theme.colors.error[5]
                    : chartColors
            }
            enableSlices={showLines ? 'x' : false}
            enableGridX={props.settings?.mode === 'trend-line' ? true : false}
            enableGridY={showLines && !hideGrid}
            enablePoints={props.settings?.hidePoints ? false : showLines}
            enableArea={props.settings?.enableArea ?? true}
            curve={curve ? 'basis' : undefined}
            yFormat={yFormatter}
            yScale={{
                type: 'linear',
                stacked: props.settings?.stacked ?? false,
                min: 0,
                max: props.settings?.yMax ?? 'auto',
            }}
            areaBlendMode="normal"
            axisLeft={showLines && !hideYAxis ? { format: (e: any) => yFormatter(e), tickPadding: 2, tickValues: props.settings?.gridYValues } : null}
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: theme.white,
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <strong>Date:</strong> {slice.points[0].data.x as string}
                        {slice.points.map((point) => (
                            <div
                                key={point.id}
                                style={{
                                    fontSize: '0.8em',
                                }}
                            >
                                <strong>
                                    <Dot color={point.serieColor} />
                                    {point.serieId}:
                                </strong>
                                {point.data.yFormatted}
                            </div>
                        ))}
                    </div>
                );
            }}
            axisBottom={
                !showLines || props.settings?.hideXAxis
                    ? null
                    : {
                          tickRotation: typeof labelAngle === 'number' ? labelAngle : -50,
                          renderTick: ({ x, y, textX, textY, rotate, ...props }) => {
                              const { text, line } = tickSpacing.next(x);
                              const lineY2 = !wideText ? props.lineY : Math.max(0, text ? props.lineY + 2 : props.lineY - 2);

                              return (
                                  <g transform={`translate(${x},${y})`}>
                                      {line || (text && wideText) ? <line x1={0} x2={props.lineX} y1={0} y2={lineY2} stroke="#000" /> : null}
                                      {text ? (
                                          <text
                                              dominantBaseline={props.textBaseline}
                                              textAnchor={props.textAnchor}
                                              transform={`translate(${textX},${textY}) rotate(${rotate})`}
                                              style={{ fontSize: theme.fontSizes.xs + 'px' }}
                                          >
                                              {props.value}
                                          </text>
                                      ) : null}
                                  </g>
                              );
                          },
                      }
            }
        />
    );
    if (!props.settings?.noWrapper) {
        return <ChartWrapper className="chartWrapper">{responsiveLine}</ChartWrapper>;
    } else {
        return responsiveLine;
    }
}

const Dot = styled.span<{ color: string }>`
    background-color: ${(p) => p.color};
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: -4px;
    margin-right: 4px;
`;
export interface LineChartSettings {
    noWrapper?: boolean;
    labelAngle?: number;
    margin?: ChartMargin;
    topN?: number;
    stacked?: boolean;
    interval?: string;
    format?: 'percent' | 'int' | 'float' | 'money' | 'money-whole';
    direction?: 'up' | 'down';
    mode?: 'trend-curve' | 'trend-line';
    chartColors?: string[];
    enableArea?: boolean;
    hideXAxis?: boolean;
    hideGrid?: boolean;
    hideYAxis?: boolean;
    hidePoints?: boolean;
    yMax?: number;
    gridYValues?: number[];
}

interface LineChartProps<T extends Record<string, any>> extends StandardChartProps<string | number | Date, T> {
    settings?: LineChartSettings;
}

export function KpiLineChart<T extends Record<string, string | number | Date>>(props: KpiLineChartProps<T>) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const data = useMemo(() => transformKpiLineData(props), [props.data, props.groups, props.values[0]]);
    const yMax = props.settings?.yMax || 0;
    const tickValues = props.settings?.tickValues || [];
    return (
        <ResponsiveLine
            data={data}
            margin={{ bottom: 60, left: 30, right: 20, top: 10 }}
            animate={true}
            enableSlices="x"
            enableGridX={false}
            enableArea={true}
            pointColor="#006DA6"
            enablePoints={false}
            colors={['#006DA6']}
            defs={[
                linearGradientDef('gradientA', [
                    { offset: 0, color: '#006DA6' },
                    { offset: 100, color: '#006DA6', opacity: 0 },
                ]),
            ]}
            fill={[{ match: '*', id: 'gradientA' }]}
            yScale={{
                type: 'linear',
                max: yMax == 0 ? 'auto' : yMax,
            }}
            axisBottom={{
                tickValues: tickValues,
                format: function (value) {
                    var dd = new Date(value);
                    return monthNames[dd.getMonth()] + ' ' + dd.getDate();
                },
            }}
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: colorPalette.white,
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        {slice.points.map((point) => (
                            <div
                                key={point.id}
                                style={{
                                    fontSize: '0.8em',
                                }}
                            >
                                <strong>Date:</strong> {point.data.x as string}
                                <br />
                                <strong>Value:</strong> {point.data.yFormatted}
                            </div>
                        ))}
                    </div>
                );
            }}
        />
    );
}

type ValueTypes = string | number | Date;

interface KpiLineChartSettings {
    tickValues: (string | undefined)[];
    yMax: number;
    margin?: ChartMargin;
}
interface KpiLineChartProps<T extends Record<string, any>> extends StandardChartProps<string | number | Date, T> {
    settings?: KpiLineChartSettings;
}

const maxSeriesPoints = 1000;

function tranformLineData<T extends Record<string, ValueTypes>>(props: LineChartProps<T>) {
    const formatter = getXFormatter(props);
    const yTranslater = getYTranslator(props);
    const data: Serie[] = [];
    if (props.groups.length > 1) {
        return transformMultiLineData(props);
    } else {
        const resultItem: Serie = { id: 'Value', data: [] };
        const key = props.groups[0];
        let totalValue = 0;
        if (props.settings?.format === 'percent') {
            props.data.forEach((item) => {
                totalValue += item[props.values[0]] as number;
            });
        }
        for (const item of props.data) {
            const xValue = item[key];
            const yValue = props.settings?.format === 'percent' ? (item[props.values[0]] as number) / totalValue : item[props.values[0]];
            resultItem.data.push({ x: formatter(xValue), y: yTranslater(yValue) });
        }
        resultItem.data.splice(maxSeriesPoints, Infinity);
        data.push(resultItem);
    }
    return data;
}
function transformMultiLineData<T extends Record<string, ValueTypes>>(props: LineChartProps<T>) {
    const formatter = getXFormatter(props);
    const yTranslater = getYTranslator(props);
    const data: Serie[] = [];
    const [xKey, groupKey] = props.groups;
    const [yKey] = props.values;
    let xs: ValueTypes[] = [];
    const valueLookup = new Map<string, ValueTypes>();
    const groups = new Set<ValueTypes>();
    props.data.forEach((item) => {
        if (xs[xs.length - 1] !== item[xKey]) {
            xs.push(item[xKey]);
        }
        const valueKey = `${item[xKey]}-${item[groupKey]}`;
        valueLookup.set(valueKey, item[yKey] ?? 0);
        groups.add(item[groupKey]);
    }, [] as ValueTypes[]);
    xs = [...new Set(xs)];
    for (const group of groups) {
        const item = { id: group, data: [], total: 0 } as Serie & { total: number };
        for (const x of xs) {
            const value = yTranslater(valueLookup.get(`${x}-${group}`) ?? 0);
            if (typeof value === 'number' && !isNaN(value)) {
                item.total += value;
            }
            item.data.push({ x: formatter(x), y: value });
        }
        item.data.splice(maxSeriesPoints, Infinity);
        data.push(item);
    }

    if (props.settings?.topN) {
        data.sort((a, b) => b.total - a.total);
        data.splice(props.settings.topN);
    }

    return data;
}

function transformKpiLineData<T extends Record<string, ValueTypes>>(props: LineChartProps<T>) {
    const dataLookup = new Map<ValueTypes, { ser: Serie; groups: Set<ValueTypes> }>();
    const xs = new Map<ValueTypes, number>();
    const data: Serie[] = [];
    const index = props.groups[0];
    const extraKeys = props.groups.length > 1 ? props.groups.slice(1) : [];
    if (index) {
        for (const item of props.data) {
            const indexKey = `${item[index]}`;
            let resultItem = dataLookup.get(indexKey);
            if (!resultItem) {
                dataLookup.set(indexKey, (resultItem = { ser: { id: indexKey, data: [] }, groups: new Set<ValueTypes>() }));
                data.push(resultItem.ser);
            }
            const value = item[props.values[0]];
            for (const key of extraKeys) {
                const group = item[key];
                if (!xs.has(group)) {
                    xs.set(group, xs.size);
                }
                resultItem.groups.add(group);
                resultItem.ser.data.push({ x: group, y: value });
            }
        }
    }

    for (const [, item] of dataLookup) {
        for (const [g] of xs) {
            if (!item.groups.has(g)) {
                item.ser.data.push({ x: g, y: null });
            }
        }
        item.ser.data.sort((a, b) => {
            const aVal = xs.get(a.x ?? '') ?? 0;
            const bVal = xs.get(b.x ?? '') ?? 0;
            return aVal - bVal;
        });
    }

    return data;
}

function getXFormatter(props: LineChartProps<any>): (value: Date | number | string) => Date | number | string {
    const fmtSvc = container.resolve(FormatService);
    switch (props.settings?.interval?.toLowerCase()) {
        case 'hour':
            return (value) => (value instanceof Date ? fmtSvc.formatDatetime(value) : fmtSvc.formatDatetime(new Date(value)));
        case 'day':
            return (value) => (value instanceof Date ? fmtSvc.formatDate(value) : fmtSvc.formatDate(new Date(value)));
        case 'short-day':
            return (value) => (value instanceof Date ? fmtSvc.formatShortDate(value) : fmtSvc.formatShortDate(new Date(value)));
        case 'week':
            return (value) => (value instanceof Date ? fmtSvc.formatWeek(value) : fmtSvc.formatWeek(new Date(value)));
        case 'month':
            return (value) => (value instanceof Date ? fmtSvc.formatMonth(value) : fmtSvc.formatMonth(new Date(value)));
        default:
            return (value) => value;
    }
}

function getYTranslator(props: LineChartProps<any>): (value: Date | number | string) => Date | number | string {
    switch (props.settings?.format?.toLowerCase()) {
        case 'percent':
            return (value) => (typeof value === 'number' ? value : 0) * 100;
        default:
            return (value) => value;
    }
}

function getYFormatter(props: LineChartProps<any>): (value: Date | number | string) => string {
    const fmtSvc = container.resolve(FormatService);
    switch (props.settings?.format?.toLowerCase()) {
        case 'percent':
            return (value) => (typeof value === 'number' ? value : 0).toFixed(0) + '%';
        case 'int':
            return (value) => (typeof value === 'number' ? fmtSvc.formatInt(value) : '0');
        case 'money':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoney(value) : '0.00');
        case 'money-whole':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoneyNoDecimals(value) : '0');
        case 'bytes':
            return (value) => (typeof value === 'number' ? fmtSvc.formatBytes(value, null) : '0');
        default:
            return (value) => value?.toString();
    }
}
