import { Text, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import { useDi } from '@root/Services/DI';
import { DashboardItemProps } from '@root/Components/DashboardLayout/Models';
import { FormatService } from '@root/Services/FormatService';
import { ListChart } from '@root/Components/Charts/ListChart';
import { CustomColors } from '@root/Design/Themes';
import { ListData } from './MapDashboardCustomizable';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { MapContractHomeModel } from '../Models';
import styled from '@emotion/styled';

export function MapListChartRenderer({ field, model, data }: { field: string; data: ListData[]; model: DashboardItemProps<{ title: string }> }) {
    useEffect(() => {
        model.model.getHeader = () => <>{model.model.settings.title}</>;
    }, []);
    return <MapListChart field={field} data={data} type={model.model.settings.type as MapListChartTypes} />;
}
type MapListChartTypes = 'key-values-applied' | 'top-5-owners' | 'top-5-resources';
export function MapListChart({ data, type, field }: { data: ListData[]; type: MapListChartTypes; field: string }) {
    const home = useDi(MapContractHomeModel);
    const contractId = home.contract?.Id?.toString() ?? '';
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const { getDescendUrl } = useNav();
    const link = useLink();
    const getLink = (value: string) => {
        return link(
            getDescendUrl('map-resource-browser', {
                filter: JSON.stringify([{ Operation: 'eq', Operands: [{ Field: field }, { Value: value }] }]),
                id: contractId,
            })
        );
    };
    switch (type) {
        case 'key-values-applied':
            return (
                <ListChart
                    data={data}
                    groups={[]}
                    values={[]}
                    settings={{
                        keyProperty: 'value',
                        valueProperty: 'key',
                        keyRenderer: (item: { key: string; value: number }) => {
                            return (
                                <Text component={ListItemLink} {...getLink(item.key)} align="center" color="primary" weight="bolder">
                                    {fmtSvc.formatInt(item.value)}
                                </Text>
                            );
                        },
                        keyStyling: { backgroundColor: theme.colors?.primary?.[2] as CustomColors, width: '100px' },
                    }}
                />
            );
        case 'top-5-owners':
            return (
                <ListChart
                    data={data}
                    groups={[]}
                    values={[]}
                    settings={{
                        keyProperty: 'value',
                        valueProperty: 'key',
                        keyRenderer: (item: { key: string; value: number }) => {
                            return (
                                <Text component={ListItemLink} {...getLink(item.key)} align="right" color="primary" weight="bolder">
                                    {fmtSvc.formatPercent(item.value)}
                                </Text>
                            );
                        },
                        keyStyling: { width: '50px' },
                    }}
                />
            );
        case 'top-5-resources':
            return (
                <ListChart
                    data={data}
                    groups={[]}
                    values={[]}
                    settings={{
                        keyProperty: 'value',
                        valueProperty: 'key',
                        keyRenderer: (item: { key: string; value: number }) => {
                            return (
                                <Text component={ListItemLink} {...getLink(item.key)} align="right" color="primary" weight="bolder">
                                    {fmtSvc.formatMoney(item.value)}
                                </Text>
                            );
                        },
                        keyStyling: { width: '100px' },
                    }}
                />
            );
    }
}

export const ListItemLink = styled.a`
    display: block;
    text-align: center;
`;
