import { postDailyRollupQuery, postMonthlyRollupQuery } from '@apis/Invoices';
import { SchemaType } from '@apis/Jobs/model';
import { QueryResult } from '@apis/Resources';
import { injectable, inject } from 'tsyringe';
import { FormatService } from '../FormatService';
import { PlatformService } from '../PlatformService';
import { ISchemaProvider } from '../QueryExpr';

export interface IInvoiceRollup {
    [key: `resourceTags/user:${string}`]: string;
    UsageMonth: string;
    VarianceKey: string;
    ['lineItem/UnblendedCost']?: number;
    ['lineItem/AmortizedCost']?: number;
    ['lineItem/UsageAccountId']?: string;
    ['bill/PayerAccountId']?: string;
    ['bill/BillingEntity']?: string;
    ['bill/InvoiceId']?: string;
    ['product/ProductName']?: string;
    ['product/servicecode']?: string;
    ['product/instanceType']?: string;
    ['product/region']?: string;
    ['lineItem/UsageAmount']?: number;
    ['lineItem/UnblendedRate']?: string;
    ['lineItem/LineItemType']?: string;
    ['lineItem/UnblendedCost']?: number;
    ['lineItem/AmortizedCost']?: number;
    ['lineItem/Operation']?: string;
    AdjustedAmortizedCost?: number;
    AdjustedCashCost?: number;
    ExternalCostAdjustment?: number;
    ['reservation/OnDemandCost']?: number;
    ['reservation/UnusedAmortizedUpfrontFeeForBillingPeriod']?: number;
    ['savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod']?: number;
    ['savingsPlan/OfferingType']?: string;
    ['product/productFamily']?: string;
    ['ExternalCostAdjustment']?: number;
    ['reservation/OnDemandCost']?: number;
}

export interface IDailyRollup extends IInvoiceRollup {
    ['product/servicecode']?: string;
    BilledDate: Date;
    UsageStartDate: Date;
    UsageEndDate: Date;
    resourceType?: string;
    ['lineItem/ResourceId']?: string;
    ['reservation/ReservationARN']?: string;
    ['savingsPlan/SavingsPlanARN']?: string;
}

export interface IMonthlyRollup extends IInvoiceRollup {
    ['lineItem/LineItemDescription']?: string;
}

export interface IForecast {
    Model: string;
    JobId: string;
    UserId: number;
    CompanyId: number;
    Date: Date;
    P10: number;
    P50: number;
    P90: number;
    PerformanceMetricSmape: number;
}

@injectable()
export class InvoiceSchemaService implements ISchemaProvider {
    private _cachedTypes?: Promise<SchemaType[]>;
    private _lastLoadedTypes?: SchemaType[];
    private _cachedTypesMonthly?: Promise<SchemaType[]>;
    private fieldInfo = new Map<string, { format?: string; description?: string }>([
        [
            'lineItem/AmortizedCost',
            {
                format: 'number-with-two-decimals',
                description:
                    'Amortized Costs represents your usage costs on an accrual basis rather than a cash basis. This can be a more useful than the Unblended Cost for analyzing costs where savings plans and reservations are in effect. ',
            },
        ],
        [
            'lineItem/UnblendedCost',
            {
                format: 'number-with-two-decimals',
                description:
                    'Unblended Cost represents your usage costs on the day they are charged to you. In finance terms, they represent your costs on a cash basis of accounting. ',
            },
        ],
        [
            'lineItem/BlendedCost',
            {
                format: 'number-with-two-decimals',
                description:
                    "Blended Cost is calculated by multiplying each account's service usage by a blended rate. A blended rate is the average rate of on-demand usage, as well as Savings Plans and reservation-related usage. ",
            },
        ],
        [
            'AdjustedCashCost',
            {
                format: 'number-with-two-decimals',
                description: 'Adjusted Cash Cost represents the cash-basis billed costs adjusted by showback allocation rules. ',
            },
        ],
        [
            'AdjustedAmortizedCost',
            {
                format: 'number-with-two-decimals',
                description: 'Adjusted Amortized Cost represents the accrual-basis billed costs adjusted by showback allocation rules. ',
            },
        ],
        [
            'ExternalCostAdjustment',
            {
                format: 'number-with-two-decimals',
                description: 'External Cost Adjustment represents costs introduced through showback allocation rules. ',
            },
        ],
        [
            'savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod',
            {
                format: 'number-with-two-decimals',
                description:
                    'Amortized Upfront Commitment For Billing Period represents the daily amortized costs of AWS Savings Plan upfront commitment. ',
            },
        ],
        [
            'reservation/OnDemandCost',
            {
                format: 'number-with-two-decimals',
                description: 'Resevation On Demand Cost represents the cost of AWS reserved instance usage at the standard, on-demand rate. ',
            },
        ],
        ['lineItem/UsageAmount', { format: 'number-with-two-decimals' }],
        ['UsageStartDate', { format: 'short-date' }],
        ['UsageEndDate', { format: 'short-date' }],
        ['UsageMonth', { format: 'short-month' }],
    ]);

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(PlatformService) private readonly platformSvc: PlatformService
    ) {}

    public getSchema(): Promise<SchemaType[]> {
        if (!this._cachedTypes) {
            this._cachedTypes = this.getTypes(() => postDailyRollupQuery({ IncludeSchema: true, Take: 0 }, {}));
        }
        return this._cachedTypes;
    }

    public getPrecachedSchema() {
        return this._lastLoadedTypes;
    }

    public getMonthlySchema() {
        if (!this._cachedTypesMonthly) {
            this._cachedTypesMonthly = this.getTypes(() => postMonthlyRollupQuery({ IncludeSchema: true, Take: 0 }, {}));
        }
        return this._cachedTypesMonthly;
    }

    public async getTypes(source: () => Promise<QueryResult<unknown>>): Promise<SchemaType[]> {
        const results = await source();

        if (results?.Types) {
            await this.platformSvc.init();
            this.removeInternalFields(results.Types);
            this.rootTypes(results.Types);
            this.adjustTags(results.Types);
            this.adjustAllFields(results.Types);
            this.adjustTypeOrder(results.Types);
        }

        this._lastLoadedTypes = results?.Types ?? [];

        return results?.Types ?? [];
    }

    private removeInternalFields(types: SchemaType[]) {
        const hiddenFields = new Set([
            'resourceType',
            'ResourceTags',
            'VarianceKey',
            'UniqueId',
            'CompanyId',
            'RecordCount',
            'AdjustmentLog',
            'AdjFrom',
            'AdjBy',
            'AdjId',
            'TagChangeLog',
            'TagKey',
            'TagValue',
            'RuleId',
            'AmortDlt',
        ]);
        for (const type of types) {
            type.Fields = type.Fields?.filter((f) => f.Field && !hiddenFields.has(f.Field));
        }
    }

    private rootTypes(types: SchemaType[]) {
        const newTypes: SchemaType[] = [];
        const rootTypes = new Map<string, SchemaType>();

        for (const type of types!) {
            for (const field of type.Fields!) {
                if (!field.Field) continue;

                let [typeName, fieldName] = field.Field.split('/');
                if (!fieldName) {
                    fieldName = typeName;
                    typeName = 'Common';
                }
                let rootType = rootTypes.get(typeName);
                if (!rootType) {
                    rootTypes.set(
                        typeName,
                        (rootType = {
                            TypeId: typeName,
                            Name: typeName,
                            IsRoot: true,
                            Fields: [],
                        })
                    );
                    newTypes.push(rootType);
                }

                let fieldExists = rootType.Fields?.find((field) => field.Name === fieldName);
                if (!fieldExists) {
                    rootType.Fields?.push({
                        Field: field.Field,
                        HasMany: false,
                        TypeName: field.TypeName,
                        IsPrimitive: true,
                        Name: fieldName ?? typeName,
                    });
                }
            }
        }
        newTypes.sort((a, b) => a.Name!.localeCompare(b.Name!));
        types.length = 0;
        types.push(...newTypes);
    }

    private adjustTags(types: SchemaType[]) {
        for (const type of types) {
            if (type.Name === 'resourceTags') {
                type.Name = 'Tags (Cost Allocation)';
                if (type.Fields) {
                    for (const field of type.Fields!) {
                        const name = field.Name?.replace(/^user:|^aws:/, '') ?? '';
                        field.Name = name;
                    }
                }
            }
        }
    }

    private adjustTypeOrder(types: SchemaType[]) {
        const tags = this.removeType(types, 'Tags (Cost Allocation)');
        if (tags) {
            types.unshift(tags);
        }
        const common = this.removeType(types, 'Common');
        if (common) {
            types.unshift(common);
        }
    }

    private removeType(types: SchemaType[], typeName: string) {
        const index = types.findIndex((t) => t.Name === typeName);
        if (index >= 0) {
            const [result] = types.splice(index, 1);
            return result;
        }
    }

    private adjustAllFields(types: SchemaType[]) {
        for (const type of types!) {
            if (type.Name === 'Tags (Cost Allocation)') {
                continue;
            }
            if (type?.Name) {
                type.Name = this.formatSvc.userFriendlyCamelCase(type.Name!);
            }
            for (const field of type.Fields!) {
                if (field?.Name) {
                    const fieldInfo = this.fieldInfo.get(field.Field ?? '');
                    field.Name = this.formatSvc.userFriendlyCamelCase(field.Name!);
                    if (fieldInfo?.format) {
                        (field as { Format: string }).Format = fieldInfo.format;
                    }
                    if (fieldInfo?.description) {
                        field.Description = fieldInfo.description;
                    }
                }
            }
        }
    }
}
