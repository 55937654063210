import { Company } from '@apis/Customers/model';
import { inject, injectable } from 'tsyringe';
import { AuthorizationService } from '../AuthorizationService';
import { AppFeatureService } from './AppFeatureService';
import { CompanyFeatureService, ICompanyFeatureLookup } from './CompanyFeatureService';
import { CompanyRoleService, ICompanyRoleLookup } from './CompanyRoleService';

class UserFeatureAccessLookup implements IUserFeatureAccessLookup {
    public constructor(
        private readonly roleLookup: ICompanyRoleLookup,
        private readonly featureLookup: ICompanyFeatureLookup,
        private readonly appFeatureSvc: AppFeatureService,
        private readonly authSvc: AuthorizationService,
        private readonly company: Company
    ) {}

    public checkAccess(featureId: number, verb?: string) {
        verb ??= 'view';
        const [app, feat] = this.appFeatureSvc.getFeature(featureId) ?? [];
        const userHasFeature = this.roleLookup.userHasFeature(featureId);
        const companyHasFeature = this.featureLookup.checkFeature(app?.SystemName ?? 'System');
        const ufp = this.appFeatureSvc.getUfp(featureId, verb);
        const userHasAccess = ufp && this.authSvc.checkRules(this.company.Id ?? 0, ufp.RequiredPermissions ?? {});

        if (!app || !feat) {
            return false;
        }

        if (userHasFeature && companyHasFeature) {
            return true;
        }

        return !!((userHasFeature || userHasAccess) && companyHasFeature);
    }

    public checkAccessByName(featureName: string, verb?: string) {
        const feature = this.appFeatureSvc.getFeatureByTypeAndName(this.company.Type ?? 'Customer', featureName);
        if (feature) {
            return this.checkAccess(feature.Id ?? 0, verb);
        }
        return false;
    }
}

export interface IUserFeatureAccessLookup {
    checkAccess(featureId: number, verb?: string): boolean;
    checkAccessByName(featureName: string, verb?: string): boolean;
}

/**
 * THE way to check if a user has access to a feature in a given company
 * This will check the user's roles, their access rules, the company's features, and the (platform support only)user's super-user state
 */
@injectable()
export class UserFeatureAccessService {
    public constructor(
        @inject(CompanyRoleService) private readonly roleSvc: CompanyRoleService,
        @inject(CompanyFeatureService) private readonly companyFeatureSvc: CompanyFeatureService,
        @inject(AppFeatureService) private readonly appFeatureSvc: AppFeatureService,
        @inject(AuthorizationService) private readonly authSvc: AuthorizationService
    ) {}

    /**
     * Check a user's access to a feature in a company
     * @param companyId
     * @param featureId
     * @returns
     */
    public async getAccessLookup(company: Company, parentCompany?: Company): Promise<IUserFeatureAccessLookup> {
        const [roleLookup, featureLookup] = await Promise.all([
            this.roleSvc.getRoles(company.Id ?? 0, parentCompany?.Id),
            this.companyFeatureSvc.getFeatures(company),
        ]);
        return new UserFeatureAccessLookup(roleLookup, featureLookup, this.appFeatureSvc, this.authSvc, company);
    }
}
