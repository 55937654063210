import { usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, Ebs, Tag } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceStorage(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        var dotPos = props.resourceId.indexOf('.');
        var instanceId = props.resourceId.substring(dotPos + 1);
        runQuery({
            data: queryBuilder<BaseAwsResource | Ebs>()
                .where((r) => r.and(r.model.ResourceType!.eq('EBS'), r.model['Attachments.InstanceId']!.eq(instanceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        var rows = [];
        if (data == null || data.Results == null || data.Results[0] == null) {
            rows.push({
                name: 'No storage data',
            });
        } else {
            var tableRow = '';
            for (var i = 0; i < data.Results.length; i++) {
                var volName = '[No Name]';
                const volume = data.Results[i] as { Tags: Tag[]; VolumeId: string; VolumeType: { Value: string }; Size: string; Iops: string };
                if (volume.Tags.length > 0) {
                    volName = volume.Tags[0].Value ?? '';
                }
                rows.push({
                    name: volName,
                    volumeID: volume.VolumeId,
                    type: volume.VolumeType.Value,
                    size: volume.Size + 'GB',
                    iops: volume.Iops,
                });
            }
        }
        retValue = (
            <div className="resourcesTableContainer">
                <ResourceInfoTable>
                    <Table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Volume ID</td>
                                <td>Type</td>
                                <td>Size</td>
                                <td className="left">IOPS</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.name}</td>
                                        <td>{val.volumeID}</td>
                                        <td>{val.type}</td>
                                        <td>{val.size}</td>
                                        <td>{val.iops}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </ResourceInfoTable>
            </div>
        );
    }

    return retValue;
}

export function ResourceStorage() {
    return <>Resource Storage</>;
}
endpoint('resource', ResourceStorage, 'Resource Storage');
