import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource, Ebs, Ec2, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceInstances(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();

    var retValue;

    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var rows = [];
    var row;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            rows.push({
                name: 'No instances found',
            });
        } else {
            const resource = data.Results[0] as { Instances: Ec2[] };
            for (var i = 0; i < resource.Instances.length; i++) {
                rows.push({
                    availabilityZone: resource.Instances[i].AvailabilityZone as string,
                    healthStatus: resource.Instances[i].HealthStatus as string,
                    instanceId: resource.Instances[i].InstanceId as string,
                    instanceType: resource.Instances[i].InstanceType as string,
                    lifecycleState: resource.Instances[i].LifecycleState as string,
                    protectedFromScaleIn: resource.Instances[i].ProtectedFromScaleIn ? 'Yes' : 'No',
                });
            }
        }
        retValue = (
            <div className="resourcesTableContainer">
                <ResourceInfoTable>
                    <Table>
                        <thead>
                            <tr>
                                <td>Availability Zone</td>
                                <td>Health Status</td>
                                <td>Instance Id</td>
                                <td>Instance Type</td>
                                <td>Lifecycle State</td>
                                <td className="left">Protected From Scale In</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.availabilityZone}</td>
                                        <td>{val.healthStatus}</td>
                                        <td>{val.instanceId}</td>
                                        <td>{val.instanceType}</td>
                                        <td>{val.lifecycleState}</td>
                                        <td>{val.protectedFromScaleIn}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </ResourceInfoTable>
            </div>
        );
    }

    return retValue;
}

export function ResourceInstances() {
    return <>Resource Instances</>;
}
endpoint('resource', ResourceInstances, 'Resource Instances');
