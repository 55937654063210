import { MantineColor, useMantineTheme } from '@mantine/core';
import { CaretDown, CaretUp, Equal } from 'tabler-icons-react';

export function IncreaseIndicator({ value, size, preferDecrease }: { value: number; size: 'sm' | 'md' | 'lg'; preferDecrease?: boolean }) {
    const theme = useMantineTheme();
    const sizePx = size === 'sm' ? 14 : size === 'md' ? 16 : 20;
    const warn = preferDecrease ? value > 0 : value < 0;
    const colorName: MantineColor = value === 0 ? 'gray' : warn ? 'warning' : 'success';
    const color = theme.colors[colorName][theme.colorScheme === 'dark' ? 4 : 6];
    const Icon = value === 0 ? Equal : value < 0 ? CaretDown : CaretUp;
    return <Icon size={sizePx} fill={color} color={color} />;
}
