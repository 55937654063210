import { getMspGetMspCreditPackagesByCompany, getSubscriptionGetCreditRates } from '@apis/Customers';
import { CreditRateRange } from '@apis/Customers/model';
import { LoadingOverlay, Space, Table } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import React, { useEffect, useState } from 'react';

export const FeeSchedule = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rateList, setRateList] = useState<CreditRateRange[]>([] as CreditRateRange[]);
    const formatSvc = useDi(FormatService);
    const company = useCompany();

    useEffect(() => {
        try {
            setIsLoading(true);
            getCreditRates();
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getCreditRates = async () => {
        let mspCreditPackages = await getMspGetMspCreditPackagesByCompany();

        if (mspCreditPackages && mspCreditPackages[0]) {
            let rates = await getSubscriptionGetCreditRates({ creditPackageId: mspCreditPackages[0].CreditPackageId });

            if (rates && rates.Ranges) {
                setRateList(rates.Ranges);
            }
        }
    };

    return (
        <>
            {isLoading && <LoadingOverlay visible />}
            <Table horizontalSpacing="xl">
                <tbody>
                    {rateList?.map((r, i) => (
                        <tr key={i}>
                            <td>
                                {r.From} {r.To ? <>&mdash; {r.To}</> : <>&amp; above</>}
                            </td>
                            <td style={{ textAlign: 'right' }}>${formatSvc.formatDecimal(r.Rate ?? 0, 2)}</td>
                        </tr>
                    ))}
                </tbody>
                <thead>
                    <tr>
                        <th>Tier</th>
                        <th style={{ textAlign: 'right' }}>Price per unit</th>
                    </tr>
                </thead>
            </Table>
        </>
    );
};
