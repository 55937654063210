import { Group, Text, useMantineTheme } from '@mantine/core';
import { BarLayer, BarLegendProps, ComputedBarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { getValueFormatter } from '@nivo/core';
import { useCallback, useMemo } from 'react';
import { chartColors, ChartMargin } from './Common';
import { StandardChartProps } from './Models';
import { line, curveMonotoneX } from 'd3-shape';
import { AnyScale } from '@nivo/scales';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import styled from '@emotion/styled';
import './styles.css';
import { ChartWrapper } from './Design';

export function BarChart<T extends Record<string, string | number>>(props: BarChartProps<T>) {
    const bindData = useMemo(() => transformBarData(props), [props.data, props.groups, props.values]);
    const orientation = props.settings?.orientation;
    const isHorz = orientation === 'Horizontal';
    const format = props.settings?.format;
    const formatter = getValueFormatter(
        format === 'money'
            ? '>-$,.2f'
            : format === 'money-whole'
            ? '>-$,.0f'
            : format === 'percent'
            ? '>-.0%'
            : format === 'integer'
            ? '>-.0f'
            : '>-,'
    );
    const stacked = props.groups.length > 1 || props.settings?.stacked === true;
    const displayEvent = useMemo(() => new EventEmitter<ComputedDatum<DataType> | undefined>(undefined), []);

    const layers: BarLayer<DataType>[] = ['grid', 'axes', ZeroLine];
    if (props.settings?.showTrend) {
        layers.push(TrendLayer);
    }
    layers.push('bars', 'markers', 'legends', 'annotations');
    const labelModel = props.settings?.enableLabel === undefined ? true : props.settings?.enableLabel;
    if (labelModel === true) {
        layers.push(({ bars }: { bars: ComputedBarDatum<DataType>[] }) => {
            return (
                <g>
                    {bars.map(({ width, height, y, x, data, index }) => {
                        if (stacked && ((isHorz && width < 20) || (!isHorz && height < 15))) return null;
                        const translate =
                            stacked && isHorz
                                ? `translate(${x + width / 2}, ${y + height / 2})`
                                : stacked && !isHorz
                                ? `translate(${x + width / 2}, ${y + height / 2})`
                                : isHorz
                                ? `translate(${width + 4}, ${y + height / 2})`
                                : `translate(${width / 2 + x}, ${y - 8})`;
                        const textAnchor = isHorz && !stacked ? 'start' : 'middle';
                        return (
                            <text
                                key={index}
                                fontWeight="bolder"
                                transform={translate}
                                textAnchor={textAnchor}
                                fontSize=".8em"
                                dominantBaseline="central"
                            >
                                {formatter(data.value)}
                            </text>
                        );
                    })}
                </g>
            );
        });
    }
    if (props.settings?.enableLabel === 'on-hover') {
        layers.push(createDynamicLabelLayer(displayEvent, formatter));
    }
    const onMouseEnter = useCallback(
        (datum: ComputedDatum<DataType>, evt: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            displayEvent.emit(datum);
            if (props.settings?.onBarClick) {
                evt.currentTarget.style.cursor = 'pointer';
                evt.currentTarget.style.filter = 'brightness(1.2)';
            }
        },
        [props.settings?.onBarClick]
    );
    const onMouseLeave = useCallback(
        (_: ComputedDatum<DataType>, evt: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            displayEvent.emit(undefined);
            if (props.settings?.onBarClick) {
                evt.currentTarget.style.filter = '';
            }
        },
        [props.settings?.onBarClick]
    );
    const onClick = useCallback(
        (datum: ComputedDatum<DataType>) => {
            props.settings?.onBarClick?.(datum.data.__orig);
        },
        [props.settings?.onBarClick]
    );

    const responsiveBar = (
        <ResponsiveBar
            data={bindData.data}
            indexBy="id"
            keys={bindData.keys}
            colors={props.settings?.chartColors ?? chartColors}
            layout={props.settings?.orientation === 'Horizontal' ? 'horizontal' : 'vertical'}
            margin={{ left: 60, bottom: 60, ...props.settings?.margin }}
            axisBottom={{
                format: (value) => (props.settings?.orientation === 'Horizontal' ? formatter(value) : value),
                tickRotation: props.settings?.labelAngle || -50,
                tickSize: props.settings?.gridLines ? 4 : 0,
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            enableLabel={false}
            enableGridY={props.settings?.gridLines ?? false}
            axisLeft={{
                format: (value) =>
                    props.settings?.orientation === 'Horizontal'
                        ? value
                        : format === 'integer'
                        ? value === Math.floor(value) && value
                        : formatter(value),
                tickSize: !props.settings?.gridLines ? 0 : 4,
            }}
            tooltip={labelModel === 'on-hover' ? () => <></> : undefined}
            padding={props.settings?.barPadding}
            borderRadius={4}
            valueFormat={formatter}
            legends={props.settings?.legend}
            layers={layers}
        />
    );

    if (props.settings?.noWrapper) {
        return responsiveBar;
    } else {
        return <ChartWrapper className="chartWrapper">{responsiveBar}</ChartWrapper>;
    }
}

export interface BarChartSettings {
    orientation?: 'Vertical' | 'Horizontal';
    margin?: ChartMargin;
    hideYAxis?: boolean;
    hideXAxis?: boolean;
    labelAngle?: number;
    gridLines?: boolean;
    format?: 'money' | 'money-whole' | 'percent' | 'integer';
    topN?: number;
    sort?: 'ascending' | 'descending' | 'none';
    sortBy?: 'value' | 'group';
    /**
     * Number between zero and 1 representing the distance between each bar, zero means bars will touch, 1 means bars will have no width
     */
    barPadding?: number;
    showTrend?: boolean;
    fractions?: boolean;
    enableLabel?: boolean | 'on-hover';
    chartColors?: string[] | undefined;
    legend?: BarLegendProps[] | undefined;
    stacked?: boolean;
    onBarClick?: (data: DataType) => void;
    noWrapper?: boolean;
}
interface BarChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: BarChartSettings;
}
type DataType = { __total: number; __orig: any; id: string | number } & Omit<Record<string, string | number>, '__total' | '__orig'>;
function transformBarData<T extends Record<string, string | number>>(props: BarChartProps<T>) {
    const dataLookup = new Map<string | number, DataType>();
    const data: DataType[] = [];
    const index = props.groups[0];
    const extraKeys = props.groups.length > 1 ? props.groups.slice(1) : undefined;
    const keys = new Set<string>();
    if (index) {
        let totalValue = 0;
        if (props.settings?.format === 'percent') {
            props.data.forEach((item) => {
                totalValue += item[props.values[0]] as number;
            });
        }

        for (const item of props.data) {
            const indexKey = item[index];
            let resultItem = dataLookup.get(indexKey);

            if (!resultItem) {
                dataLookup.set(indexKey, (resultItem = { id: indexKey, __total: 0, __orig: item as any }));
                data.push(resultItem);
            }

            const value = item[props.values[0]];

            if (extraKeys) {
                for (const key of extraKeys) {
                    const group = `${item[key]}`;
                    keys.add(group);
                    resultItem[group] = value;
                    resultItem.__total += value as number;
                }
            } else {
                resultItem.value = props.settings?.format === 'percent' ? (value as number) / totalValue : value;
                resultItem.__total += value as number;
            }
        }
    }

    if (props.settings?.sort !== 'none') {
        const modifier = props.settings?.sort === 'ascending' ? -1 : 1;
        if (props.settings?.sortBy === 'group') {
            data.sort((a, b) => {
                return typeof b.id === 'string'
                    ? (b.id as string).localeCompare(a.id as string, undefined, { sensitivity: 'base' }) * modifier
                    : (b.id as number) - (a.id as number) * modifier;
            });
        } else {
            data.sort((a, b) => {
                return (b.__total - a.__total) * modifier;
            });
        }
    }

    if (props.settings?.topN) {
        data.splice(props.settings.topN);
    }

    if (props.settings?.orientation === 'Horizontal') {
        data.reverse();
    }

    return { data, keys: keys.size > 0 ? [...keys] : undefined };
}

function ZeroLine({
    bars,
    yScale,
    xScale,
    innerWidth,
}: {
    bars: ComputedBarDatum<DataType>[];
    yScale: AnyScale;
    xScale: AnyScale;
    innerWidth: number;
}) {
    const theme = useMantineTheme();
    const { hasNeg, hasPos } = bars.reduce(
        (res, b) => {
            const hasNeg = res.hasNeg || (b.data.value ?? 0) < 0;
            const hasPos = res.hasPos || (b.data.value ?? 0) > 0;
            return { hasNeg, hasPos };
        },
        { hasNeg: false, hasPos: false }
    );
    const showZero = hasNeg && hasPos;
    const points = showZero
        ? [
              { x: 0, y: yScale(0) },
              { x: innerWidth, y: yScale(0) },
          ]
        : [];
    const lineGenerator = line<{ x: number; y: number }>()
        .x((b) => b.x)
        .y((b) => b.y);

    return !showZero ? null : <path d={lineGenerator(points) ?? ''} fill="none" stroke={theme.colors.gray[4]} strokeWidth={1}></path>;
}

function createDynamicLabelLayer(displayEvent: EventEmitter<ComputedDatum<DataType> | undefined>, formatter: (value: unknown) => string) {
    return function DynamicLabelLayer({ bars }: { bars: ComputedBarDatum<DataType>[] }) {
        const theme = useMantineTheme();
        const datum = useEventValue(displayEvent);
        const bar = bars.find((b) => b.data === datum);
        if (!bar) return null;
        const { x, y, width, height, data } = bar;
        const translate = `translate(${x + width / 2}, ${y - 10})`;

        return (
            <g>
                <text transform={translate} fontWeight="bolder" textAnchor="middle" dominantBaseline="central" fill={theme.colors.gray[9]}>
                    {formatter(data.value)}
                </text>
            </g>
        );
    };
}

function TrendLayer({ bars }: { bars: ComputedBarDatum<DataType>[] }) {
    try {
        const theme = useMantineTheme();
        const uniqueBars = [
            ...bars.reduce((res, b) => {
                let item = res.get(b.x);
                if (!item) {
                    res.set(b.x, (item = b));
                }
                item.y = Math.min(item.y, b.y);
                item.height = Math.max(item.height, b.height);
                item.data.value = item === b ? item.data.value ?? 0 : (item.data.value ?? 0) + (b.data.value ?? 0);
                return res;
            }, new Map<number, ComputedBarDatum<DataType>>()),
        ].map(([, b]) => b as ComputedBarDatum<DataType>);
        const bounds = uniqueBars.reduce(
            (res, b) => {
                return {
                    minX: Math.min(res.minX, b.x + b.width / 2),
                    maxX: Math.max(res.maxX, b.x + b.width / 2),
                    minY: Math.min(res.minY, b.y),
                    maxY: Math.max(res.maxY, b.y + b.height),
                };
            },
            { minX: Infinity, maxX: -Infinity, minY: 0, maxY: 0 }
        );
        const width = bounds.maxX - bounds.minX;
        const dist = width / (uniqueBars.length - 1);
        const yValues = uniqueBars.map((b) => ((b.data.value ?? 0) < 0 ? b.y + b.height : b.y));
        const movingAverage: number[] = [];
        for (let i = 1; i < yValues.length - 1; i++) {
            movingAverage.push(yValues.slice(i - 1, i + 2).reduce((r, n) => r + n, 0) / 3);
        }
        const adjustedMovingAvg = [yValues[0], ...movingAverage, yValues[yValues.length - 1]];
        const scaledPoints = adjustedMovingAvg.map((y, i) => {
            const x = i * dist;
            return { x: bounds.minX + x, y: y + bounds.minY };
        });
        const lineGenerator = line<{ x: number; y: number }>()
            .curve(curveMonotoneX)
            .x((b) => b.x)
            .y((b) => b.y);
        return (
            <path
                d={lineGenerator(scaledPoints) ?? ''}
                fill="none"
                stroke={theme.fn.rgba(theme.colors.warning[4], 0.5)}
                strokeWidth={3}
                strokeDasharray="0 4 0"
            ></path>
        );
    } catch {
        return null;
    }
}
