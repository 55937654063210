import {
    getSubscriptionGetCreditRates,
    getSubscriptionGetSubscriptionOptions,
    getUserGetMyCompanies,
    getUserGetMyMspCompanies,
    postSubscriptionUpdateCompanyInfo,
    postSubscriptionUpdatePrimaryContactToCurrentUser,
    putCompanyRegisterCompany,
    getCspMktGetAwsSubscription,
    postCspMktActivateAwsSubscription,
    getCspMktGetAwsMarketplaceOffers,
} from '@apis/Customers';
import { Address, AwsMarketplaceOffer, AwsMarketplaceSubscription, Company, CreditRates, Plans } from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    Accordion,
    Anchor,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    Grid,
    Group,
    List,
    Loader,
    LoadingOverlay,
    MantineColor,
    Modal,
    Space,
    Text,
    TextInput,
    ThemeIcon,
    ThemeIconVariant,
    Title,
    UnstyledButton,
    useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSet } from '@react-hookz/web';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { CustomColors } from '@root/Design/Themes';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { Logger } from '@root/Services/Logger';

import { Router } from '@root/Services/Router/Router';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Check, ChevronRight, Dots, X } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { SubscriptionPriceDetails, useSubscriptionDetailsVisible } from './Components/SubscriptionPriceDetails';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { AwsOnboardingService } from '@root/Services/Customers/AwsOnboarding';
import { MspService } from '@root/Services/MspService';
import { PrivateOfferFulfillment } from './CspMarketplace/AwsPrivateOffers';
import { CollectSalesInfo } from './CspMarketplace/CollectSalesInfo';
import { openConfirmModal } from '@mantine/modals';
import { useSupportChat } from '@root/Components/Shell/SupportChat';

export function AwsMarketplaceLandingPage() {
    const theme = useMantineTheme();
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav();
    const router = useDi(Router);
    const logger = useDi(Logger);
    const awsOnboardingSvc = useDi(AwsOnboardingService);
    const subscriptionId = awsOnboardingSvc.awsToken;
    const [subscription, setSubscription] = useState<AwsMarketplaceSubscription>();
    const [offer, setOffer] = useState<AwsMarketplaceOffer>();
    const [companies, setCompanies] = useState<Company[]>();
    const [mspCompany, setMspCompany] = useState<Company | null>(null);
    const fail = () => router.navigate('/_register');

    useEffect(() => {
        const params = nav.getData('subscriptionId', 'contactSales');

        if (params.subscriptionId != undefined && params.subscriptionId != subscriptionId) {
            awsOnboardingSvc.setCookie(params.subscriptionId);
        } else if (!subscriptionId && !params.subscriptionId) {
            fail();
        } else if (subscriptionId != undefined) {
            (async () => {
                try {
                    const companies = await getUserGetMyCompanies();
                    const mspCompanies = await getUserGetMyMspCompanies();
                    const offers = await getCspMktGetAwsMarketplaceOffers();
                    companies.push(...mspCompanies);
                    setMspCompany(companies.find((c) => c.Type === 'Msp') ?? null);
                    setCompanies(companies.filter((c) => c.Type !== 'Msp').sort((a, b) => (a.CompanyName! > b.CompanyName! ? 1 : -1)));
                    const subscription = await getCspMktGetAwsSubscription({ subscriptionId: subscriptionId });
                    if (!subscription) {
                        fail();
                    } else {
                        const offer = offers.find((o) => o.ProductCode === subscription.ProductCode);
                        setOffer(offer);
                        setSubscription(subscription);
                    }
                } catch (err) {
                    logger.error('Error while getting current user companies', err);
                    fail();
                }
            })();
        }
    }, [subscriptionId]);

    return !companies || !subscription ? (
        <LoadingOverlay visible={true} />
    ) : subscription.IsFreeTrial ? (
        <CollectSalesInfo subscription={subscription} />
    ) : !offer || !offer.IsPrivateOnly ? (
        <SubscriptionConfirmation companies={companies} mspCompany={mspCompany} subscription={subscription} />
    ) : subscription.PrivateOfferUrl ? (
        <PrivateOfferFulfillment companies={companies} subscription={subscription} offer={offer} />
    ) : (
        <CollectSalesInfo subscription={subscription} />
    );
}

function SubscriptionConfirmation({
    companies,
    mspCompany,
    subscription,
}: {
    companies: Company[];
    mspCompany: Company | null;
    subscription: AwsMarketplaceSubscription;
}) {
    const theme = useMantineTheme();
    const hasCompanies = companies.length > 0;
    const [openSection, setOpenSection] = useState<string>(hasCompanies ? 'select' : 'register');
    const setSection = useCallback(
        (section: string | null) => {
            if (section !== null) {
                setOpenSection(section);
            }
        },
        [openSection]
    );
    useEffect(() => {
        const originalBg = document.body.style.background;
        document.body.style.background = theme.colors.gray[1];
        return () => {
            document.body.style.background = originalBg;
        };
    }, []);
    const mspSvc = useDi(MspService);
    return (
        <Box p="xl">
            <Grid m={0}>
                <Grid.Col md={6} xl={4} offsetMd={3} offsetXl={4}>
                    <Card color={theme.white} shadow="xl" radius="lg" p={0}>
                        <Box p="xl">
                            <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
                        </Box>
                        <Divider size="xs" />
                        <Accordion value={openSection} onChange={setSection}>
                            <Accordion.Item value="select" hidden={!hasCompanies} p={0}>
                                <Accordion.Control>
                                    <Title px="lg" order={4} sx={{ fontWeight: 'normal' }}>
                                        Select Registration
                                    </Title>
                                </Accordion.Control>
                                <Accordion.Panel p={0}>
                                    <SelectRegistration subscription={subscription} companies={companies} mspCompany={mspCompany} />
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="register" hidden={mspCompany != null}>
                                <Accordion.Control chevron={hasCompanies ? undefined : <></>}>
                                    <Title px="lg" order={4} sx={{ fontWeight: 'normal' }}>
                                        {hasCompanies ? 'New Registration' : 'Subscription Details'}
                                    </Title>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Group position="center" p="xl">
                                        <SubscriptionRegister subscription={subscription} />
                                    </Group>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

function SelectRegistration({
    companies,
    subscription,
    mspCompany,
}: {
    companies: Company[];
    subscription: AwsMarketplaceSubscription;
    mspCompany: Company | null;
}) {
    const formatSvc = useDi(FormatService);
    const [mspDbName, setMspDbName] = useState<string | undefined>();
    useEffect(() => {
        if (mspCompany != null) {
            setMspDbName(mspCompany.DatabaseName!.toLocaleLowerCase());
        }
    }, []);
    return (
        <Box p="md" sx={{ maxHeight: 400, overflow: 'auto' }}>
            {companies.map((c) => (
                <Fragment key={c.Id}>
                    <UnstyledButton
                        component="a"
                        href={
                            mspDbName === '' || mspDbName == undefined
                                ? `/${c.DatabaseName}/settings/payment-methods/aws-confirm-payment-method@id:${subscription.Id}`
                                : `/${mspDbName}/manage-company@id:${c.Id}/payment-methods/aws-confirm-payment-method@id:${subscription.Id}`
                        }
                    >
                        <SelectRegistrationContainer>
                            <Card px="xl" radius="lg" sx={{ background: 'none' }}>
                                <Group position="apart">
                                    <Box sx={{ flex: 1 }}>
                                        <Title order={5} sx={{ fontWeight: 'normal' }}>
                                            {c.CompanyName}
                                        </Title>
                                        <Text color="dimmed" size="sm">
                                            Date: {formatSvc.formatDate(formatSvc.toLocalDate(c.DateCreated))}
                                        </Text>
                                    </Box>
                                    <Text color="primary" size="sm" className="manage">
                                        Manage Billing
                                    </Text>
                                    <ChevronRight strokeWidth={1} />
                                </Group>
                            </Card>
                        </SelectRegistrationContainer>
                    </UnstyledButton>
                </Fragment>
            ))}
        </Box>
    );
}

const SelectRegistrationContainer = styled.div`
    > div {
        transition: all 0.25s linear;
        .manage {
            opacity: 0;
            transition: all 0.25s linear;
        }
        :hover {
            background: ${(p) => p.theme.colors.primary[1]};
            .manage {
                opacity: 1;
            }
        }
    }
`;

function showError(confirm: () => void) {
    openConfirmModal({
        children: <Text>There seems to be a problem with the Aws SaaS offer. Please contact support</Text>,
        labels: {
            confirm: 'Contact Support',
            cancel: null,
        },
        cancelProps: {
            hidden: true,
        },
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnConfirm: true,
        closeOnEscape: false,
        onCancel: undefined,
        onConfirm: confirm,
    });
}

function SubscriptionRegister({ subscription }: { subscription: AwsMarketplaceSubscription }) {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState<{ plan: Plans; creditRate: CreditRates }>();
    const di = useDiContainer();
    const activatorSvc = useMemo(() => di.resolve(AwsSubscriptionActivator), []);
    const subscriptionState = useMemo(() => new EventEmitter<ActivationState>(null), []);
    const state = useEventValue(subscriptionState);
    const logger = useDi(Logger);
    const router = useDi(Router);
    const { loadChat } = useSupportChat();

    useEffect(() => {
        (async () => {
            try {
                const creditPackageId = subscription.CreditPackageId ?? 0;
                const subscriptionTypeId = subscription.SubscriptionType?.ExternalId ?? '';

                const [subscriptionOptions, creditRate] = await Promise.all([
                    getSubscriptionGetSubscriptionOptions({ applicationType: subscription.SubscriptionType?.Application ?? undefined }),
                    getSubscriptionGetCreditRates({ creditPackageId }),
                ]);
                const plan = [...(subscriptionOptions.MonthlyPlans ?? []), ...(subscriptionOptions.YearlyPlans ?? [])].find(
                    (o) => o.ExternalId === subscriptionTypeId
                );
                if (!plan) {
                    throw new Error('No subscription option found for ID ' + subscriptionTypeId);
                }

                setSubscriptionInfo({ creditRate, plan });
            } catch (err) {
                logger.error('Error getting subscription options and credit rates', err);
                showError(loadChat);
            }
        })();
    }, []);

    const form = useForm({
        initialValues: {
            CompanyName: '',
            CompanyWebsite: '',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            CountryCode: '',
            StateCode: '',
            ZipCode: '',
        },
        validate: {
            CompanyName: (value) =>
                value.length > 50
                    ? 'Max length for company name is 50'
                    : value.replace(/[^0-9a-z]/gi, '').length < 3
                    ? 'Company name must contain at least 3 letters or numbers'
                    : null,
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const activate = useCallback(() => {
        if (!form.validate().hasErrors) {
            activatorSvc.activate(form.values, subscription.Id!, subscriptionState);
        }
    }, [form.validate, form.values]);
    const subscriptionPricingVisible = useSubscriptionDetailsVisible(subscription?.SubscriptionType?.Application ?? '');

    return (
        <Box sx={{ width: 422 }}>
            <Title order={4}>Account Setup</Title>
            <TextInput mb="md" data-atid="CompanyName" required label="Company Name" {...form.getInputProps('CompanyName')} />
            <TextInput mb="md" data-atid="CompanyWebsite" label="Company Website" {...form.getInputProps('CompanyWebsite')} />
            <Space h="lg" />
            <Title order={4}>Business Address</Title>
            <CompanyAddress
                form={form}
                prefix=""
                requiredFields={[
                    CompanyAddressFields.AddressLine1,
                    CompanyAddressFields.City,
                    CompanyAddressFields.State,
                    CompanyAddressFields.Zip,
                    CompanyAddressFields.Country,
                ]}
                isWithinPortal={false}
            />
            {subscriptionPricingVisible ? (
                <>
                    <Space h="lg" />
                    <Title order={4}>Subscription Details</Title>
                    {subscriptionInfo && (
                        <SubscriptionInfo creditRate={subscriptionInfo.creditRate} plan={subscriptionInfo.plan} subscription={subscription} />
                    )}
                </>
            ) : null}
            <Space h="lg" />
            <TermsOfService onChange={setAcceptedTerms} />
            <Button disabled={!acceptedTerms} fullWidth color="primary" onClick={activate}>
                Activate Subscription
            </Button>
            <Modal
                padding={0}
                overlayBlur={4}
                opened={state !== null}
                onClose={() => {}}
                radius="lg"
                overlayColor="white"
                withinPortal
                withCloseButton={false}
            >
                <ActivationProgress state={subscriptionState} />
            </Modal>
        </Box>
    );
}

function SubscriptionInfo({ plan, creditRate, subscription }: { subscription: AwsMarketplaceSubscription; plan: Plans; creditRate: CreditRates }) {
    return (
        <Card withBorder radius="lg" mt="xs" p={0}>
            <SubscriptionPriceDetails creditRate={creditRate} plan={plan} subscriptionType={subscription.SubscriptionType!} />
        </Card>
    );
}

function TermsOfService({ onChange, type }: { onChange: (value: boolean) => void; type?: 'trial' }) {
    return (
        <Group spacing={6} p="md">
            <Checkbox onChange={(evt) => onChange(evt.target.checked)} />I agree with the
            <Anchor
                href={'https://www.cloudsaver.com/legal/' + (type === 'trial' ? 'free-trial' : 'master-subscription') + '-agreement/'}
                target="_blank"
            >
                terms of service
            </Anchor>
        </Group>
    );
}

type ActivationState = null | 'creating' | 'failed' | 'provisioning' | 'subscribing' | 'activating' | 'redirecting' | 'finished';

@injectable()
class AwsSubscriptionActivator {
    public constructor(
        @inject(CompanyTenantPrereqService) private readonly tenantSvc: CompanyTenantPrereqService,
        @inject(JobService) private readonly jobSvc: JobService,
        @inject(Logger) private readonly logger: Logger,
        @inject(AwsOnboardingService) private readonly awsOnboardingSvc: AwsOnboardingService
    ) {}

    public async activate(
        companyRequest: Address & { CompanyName: string; CompanyWebsite?: string },
        subscriptionId: number,
        state: EventEmitter<ActivationState>
    ) {
        try {
            state.emit('creating');
            let company = await this.createCompany(companyRequest.CompanyName, companyRequest.CompanyWebsite);
            if (company === null) {
                state.emit('failed');
            } else {
                state.emit('provisioning');
                await this.provisionCompany(company);
                state.emit('subscribing');
                await this.updateCompanyDetails(companyRequest);
                state.emit('activating');
                await this.createSubscription(subscriptionId);
                state.emit('redirecting');
                await this.redirect(company.Id!, subscriptionId);
                state.emit('finished');
            }
        } catch (err) {
            this.logger.error('Failed while registering', state.value, err);
            state.emit('failed');
        }
    }
    private async createCompany(companyName: string, companyWebsite?: string) {
        return await putCompanyRegisterCompany({ companyName, companyWebsite });
    }

    private async provisionCompany(company: Company) {
        this.tenantSvc.setCompanyId(company.Id);
        const { poller } = await this.jobSvc.waitForJobHierarchyByJobId(company.ProvisionJobId!);
        const result = await poller;
        return !result?.Failed ? company : null;
    }

    private async updateCompanyDetails(address: Address) {
        await postSubscriptionUpdatePrimaryContactToCurrentUser();
        await postSubscriptionUpdateCompanyInfo({ BillingAddress: address });
    }

    private async createSubscription(subscriptionId: number) {
        await postCspMktActivateAwsSubscription({ subscriptionId });
    }

    private async redirect(companyId: number, subscriptionId: number) {
        const companies = await getUserGetMyCompanies();
        const mspCompanies = await getUserGetMyMspCompanies();
        companies.push(...mspCompanies);
        const company = companies.find((f) => f.Id === companyId);
        if (!company) {
            throw new Error(`Company ${companyId} not found`);
        }
        const msp = companies.find((m) => m.Type === 'Msp');
        if (msp) {
            window.location.href = `/${msp.DatabaseName}/manage-company@id:${company.Id}/payment-methods/aws-confirm-payment-method@id:${subscriptionId}`;
        } else {
            window.location.href = `/${company.DatabaseName}`;
        }
    }
}

function ActivationProgress({ state }: { state: EventEmitter<ActivationState> }) {
    const states = useSet<ActivationState>([]);
    const statuses = useMemo(
        () =>
            [
                { state: 'provisioning', description: 'Account initialized', working: 'Initializing account' },
                { state: 'subscribing', description: 'Provisioned workspace', working: 'Provisioning workspace' },
                { state: 'activating', description: 'Created subscription', working: 'Creating subscription' },
                { state: 'redirecting', description: 'Activated AWS offer', working: 'Activating AWS offer' },
                { state: 'finished', description: 'Redirecting to new account', working: 'Redirecting to new account' },
            ] as { state: ActivationState; description: string; working: string }[],
        []
    );
    useEvent(state, (state) => states.add(state));
    const [loadingChat, setLoadingChat] = useState(false);
    const pollingSvc = useDi(PollingService);
    const loadChat = async () => {
        setLoadingChat(true);
        try {
            window.embedded_svc.liveAgentAPI.startChat();
            await pollingSvc.pollUntil(
                async () => {},
                () => document.getElementsByClassName('embeddedServiceSidebar').length > 0
            );
        } finally {
            setLoadingChat(false);
        }
    };
    const mspSvc = useDi(MspService);

    return (
        <Box>
            <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
            <Divider />
            <List py="xl" px={48} center spacing="sm">
                {statuses.map((s) => {
                    const iconColor: CustomColors = states.has(s.state) ? 'success' : states.has('failed') ? 'error' : 'gray';
                    const icon = states.has(s.state) ? <Check size={16} /> : states.has('failed') ? <X size={16} /> : <Dots size={16} />;
                    const variant: ThemeIconVariant = !states.has(s.state) ? 'outline' : 'filled';
                    const animation = states.has(s.state) || states.has('failed') ? '' : 'ti-fade';
                    const description = states.has(s.state) ? s.description : s.working;

                    return (
                        <List.Item
                            key={s.state}
                            icon={
                                <ThemeIcon size={24} variant={variant} className={animation} radius="lg" color={iconColor}>
                                    {icon}
                                </ThemeIcon>
                            }
                        >
                            {description}
                        </List.Item>
                    );
                })}
            </List>
            {!states.has('failed') ? null : (
                <>
                    <Divider />
                    <Box p="xl">
                        <Text>We have encountered some difficulties. Let's open a support ticket to get this solved.</Text>
                        <Space h="lg" />
                        <Button leftIcon={loadingChat ? <Loader color={'#fff' as MantineColor} size="sm" /> : undefined} onClick={loadChat}>
                            Contact Support
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
