import { getCreditsGetCreditSummary, getSubscriptionGetSubscriptionsByCompany, postCreditsUpdateSettingsPage } from '@apis/Customers';
import { CreditSummary, CreditSummaryPaymentType } from '@apis/Customers/model';
import { Box, Button, Col, createStyles, Divider, Grid, Group, LoadingOverlay, NumberInput, Radio, Space, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { PageContent } from '@root/Design/Layout';
import { colorPalette, CustomColors, mspColorPalette } from '@root/Design/Themes';
import { INavigatingEvent, useOnNavigating } from '@root/Services/Router/Router';
import { useEffect, useState } from 'react';
import { BillingBody } from './Credits';

export function CreditUsageSettings() {
    const { classes } = useStyles();
    const [creditSummary, setCreditSummary] = useState<CreditSummary>();
    const [paymentType, setPaymentType] = useState<CreditSummaryPaymentType>();
    const [lowBalanceNotification, setLowBalanceNotification] = useState(true);
    const [lowCredit, setLowCredit] = useState(0);
    const [tempLowCredit, setTempLowCredit] = useState(0);
    const [hasMaxCredit, setHasMaxCredit] = useState(false);
    const [maxCredit, setMaxCredit] = useState(0);
    const [tempMaxCredit, setTempMaxCredit] = useState(0);
    const [defaultPaymentType, setDefaultPaymentType] = useState<CreditSummaryPaymentType>();
    const [defaultLowCredit, setDefaultLowCredit] = useState(0);
    const [defaultMaxCredit, setDefaultMaxCredit] = useState(0);
    const [defaultHasMaxCredit, setDefaultHasMaxCredit] = useState(false);
    const [defaultLowBalanceNotification, setDefaultLowBalanceNotification] = useState(true);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [notInTrial, setNotinTrial] = useState(false);

    useEffect(() => {
        (async () => {
            const subscription = await getSubscriptionGetSubscriptionsByCompany();
            if (subscription.length > 0) {
                const nonTrials = subscription.filter((s) => s.Tier != 'Trial');
                if (nonTrials.length > 0) {
                    setNotinTrial(true);
                }
            }
            setCreditSummary(await getCreditsGetCreditSummary());
        })();
    }, []);

    useEffect(() => {
        setPaymentType(creditSummary?.PaymentType);
        setDefaultPaymentType(creditSummary?.PaymentType);
        if (creditSummary?.CreditLimit == 0) {
            setHasMaxCredit(false);
            setDefaultHasMaxCredit(false);
        } else {
            setHasMaxCredit(true);
            setDefaultHasMaxCredit(true);
            setMaxCredit(creditSummary?.CreditLimit ?? 0);
            setTempMaxCredit(creditSummary?.CreditLimit ?? 0);
            setDefaultMaxCredit(creditSummary?.CreditLimit ?? 0);
        }

        if (creditSummary?.CreditThreshold == 0) {
            setLowBalanceNotification(false);
            setDefaultLowBalanceNotification(false);
        } else {
            setLowBalanceNotification(true);
            setDefaultLowBalanceNotification(true);
            setLowCredit(creditSummary?.CreditThreshold ?? 0);
            setTempLowCredit(creditSummary?.CreditThreshold ?? 0);
            setDefaultLowCredit(creditSummary?.CreditThreshold ?? 0);
        }
        setIsLoading(false);
    }, [creditSummary]);

    useEffect(() => {
        if (!lowBalanceNotification || paymentType == CreditSummaryPaymentType.Postpaid) {
            setLowBalanceNotification(false);
            setTempLowCredit(lowCredit);
            setLowCredit(0);
        } else {
            setLowCredit(tempLowCredit);
        }

        if (!hasMaxCredit) {
            setTempMaxCredit(maxCredit);
            setMaxCredit(0);
        } else {
            setMaxCredit(tempMaxCredit);
        }
    }, [lowBalanceNotification, hasMaxCredit, paymentType]);

    useEffect(() => {
        if (isDirty()) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
    }, [paymentType, lowCredit, maxCredit]);

    const postPaidRadioText = (
        <Box>
            <Text className={classes.titleText}>Post-paid</Text>
            <Text className={classes.normalText}>We will charge you at the end of each billing cycle for the credits you use</Text>
        </Box>
    );

    const prePaidRadioText = (
        <Box>
            <Text className={classes.titleText}>Pre-paid</Text>
            <Text className={classes.normalText}>Purchase credit packages and spend as you go</Text>
        </Box>
    );

    const inAppNoteText = (
        <Group>
            <Text className={classes.titleText}>
                Send a <strong>notification</strong> when my credits go below
            </Text>
            <NumberInput
                data-atid="LowBalanceInput"
                value={lowCredit}
                disabled={!lowBalanceNotification}
                min={0}
                step={100}
                onChange={(val) => setLowCredit(val ?? 0)}
            />
        </Group>
    );

    const maxCreditUseText = (
        <Group>
            <Text className={classes.titleText}>Limit to</Text>
            <NumberInput
                data-atid="MaxCreditInput"
                value={maxCredit}
                disabled={!hasMaxCredit}
                min={0}
                step={100}
                onChange={(val) => setMaxCredit(val ?? 0)}
            />
            <Text className={classes.titleText}>credits</Text>
        </Group>
    );

    useOnNavigating((e) => {
        if (isDirty()) {
            e.wait();
            confirmAbandonChanges(e);
        }
    });

    const isDirty = () => {
        if (defaultPaymentType !== paymentType) {
            return true;
        }

        if (defaultLowBalanceNotification != lowBalanceNotification || (lowBalanceNotification && defaultLowCredit !== lowCredit)) {
            return true;
        }

        if (defaultMaxCredit !== maxCredit) {
            return true;
        }

        return false;
    };

    const confirmAbandonChanges = (e: INavigatingEvent) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'black', fontWeight: 600 }}>
                    <b>Confirm Navigation</b>
                </span>
            ),
            children: <Text>You have unsaved changes. Are you sure you want to leave this page?</Text>,
            centered: true,
            labels: { confirm: 'Leave This Page', cancel: 'Stay On Page' },
            onConfirm: () => e.continue(),
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const timer = setTimeout(async () => {
            const success = await postCreditsUpdateSettingsPage({ paymentType: paymentType, lowCredit: lowCredit, maxCredit: maxCredit });
            if (success) {
                setCreditSummary(await getCreditsGetCreditSummary());
                setButtonEnabled(false);
            }
            setIsLoading(false);
        }, 500);
        return () => clearTimeout(timer);
    };

    const resetFormValues = () => {
        setTempLowCredit(defaultLowCredit);
        setTempMaxCredit(defaultMaxCredit);
        setPaymentType(defaultPaymentType);
        setLowCredit(defaultLowCredit);
        setMaxCredit(defaultMaxCredit);
        setLowBalanceNotification(defaultLowBalanceNotification);
        setHasMaxCredit(defaultHasMaxCredit);
    };

    return (
        <PageContent>
            <BillingBody>
                {isLoading && <LoadingOverlay visible={true} />}
                <Group style={{ paddingTop: '11px', justifyContent: 'flex-end' }}>
                    <Button
                        data-atid="RevertButton"
                        disabled={!buttonEnabled}
                        size="sm"
                        variant="outline"
                        onClick={() => resetFormValues()}
                        styles={() => ({
                            root: {
                                marginRight: 10,
                            },
                        })}
                    >
                        Revert Changes
                    </Button>{' '}
                    <Button
                        data-atid="SaveButton"
                        disabled={!buttonEnabled}
                        type="submit"
                        size="sm"
                        onClick={() => handleSubmit()}
                        styles={() => ({
                            root: {
                                marginRight: 10,
                            },
                        })}
                    >
                        Save
                    </Button>
                </Group>
                <Space h={20} />
                <Grid>
                    <Col span={3}>
                        <Text className={classes.titleText}>Credits billing method</Text>
                        <Text className={classes.normalText}>Select how you would like to pay for credits</Text>
                    </Col>
                    <Col span={6} offset={1}>
                        <Radio.Group value={paymentType} className={classes.radio}>
                            <Radio
                                data-atid="PostPaidRB"
                                label={postPaidRadioText}
                                value={CreditSummaryPaymentType.Postpaid}
                                onClick={() => setPaymentType(CreditSummaryPaymentType.Postpaid)}
                                disabled={!notInTrial}
                            ></Radio>
                            <Radio
                                data-atid="PrePaidRB"
                                disabled={(creditSummary?.TotalCredits as number) < 0 || !notInTrial}
                                label={prePaidRadioText}
                                value={CreditSummaryPaymentType.Prepaid}
                                onClick={() => setPaymentType(CreditSummaryPaymentType.Prepaid)}
                            ></Radio>
                        </Radio.Group>
                    </Col>
                </Grid>
                <Space h={10} />
                <Divider></Divider>
                <Space h={20} />
                <Grid>
                    <Col span={3}>
                        <Text className={classes.titleText}>Low credit balance notifications</Text>
                        <Text className={classes.normalText}>Notifications to remind you when your credit balance is low</Text>
                    </Col>
                    <Col span={6} offset={1}>
                        <Radio.Group value={lowBalanceNotification.toString()}>
                            <Radio
                                data-atid="NoNotificationRB"
                                disabled={paymentType == CreditSummaryPaymentType.Postpaid}
                                value="false"
                                label="Do not notify me"
                                onClick={() => setLowBalanceNotification(false)}
                            ></Radio>
                            <Radio
                                data-atid="NotificationRB"
                                disabled={paymentType == CreditSummaryPaymentType.Postpaid}
                                value="true"
                                label={inAppNoteText}
                                onClick={() => setLowBalanceNotification(true)}
                            ></Radio>
                        </Radio.Group>
                    </Col>
                </Grid>
                <Space h={10} />
                <Divider></Divider>
                <Space h={20} />
                <Grid>
                    <Col span={3}>
                        <Text className={classes.titleText}>Max credit use</Text>
                        <Text className={classes.normalText}>Set a maximum credit use per billing cycle</Text>
                    </Col>
                    <Col span={6} offset={1}>
                        <Radio.Group orientation="vertical" value={hasMaxCredit.toString()}>
                            <Radio value="false" label="No limit" onClick={() => setHasMaxCredit(false)}></Radio>
                            <Radio value="true" label={maxCreditUseText} onClick={() => setHasMaxCredit(true)}></Radio>
                        </Radio.Group>
                    </Col>
                </Grid>
            </BillingBody>
        </PageContent>
    );
}

const useStyles = createStyles((theme) => ({
    titleText: {
        color: colorPalette.titleTextColor,
        fontSize: '14px',
    },

    normalText: {
        color: colorPalette.subTextColor,
        fontWeight: 400,
        fontSize: '14px',
    },
    hoverText: {
        '&:hover': {
            backgroundColor: colorPalette.white,
        },
    },
    radio: {
        lineHeight: '14px',
    },
}));
