import styled from '@emotion/styled';
import { Anchor, Box, Group, Space, Text, ThemeIcon, Title } from '@mantine/core';
import { openModal, useModals } from '@mantine/modals';
import { useDiComponent } from '@root/Services/DI';
import { useIdGen } from '@root/Services/IdGen';
import { ReactNode } from 'react';
import { ChevronLeft, FileX, X } from 'tabler-icons-react';
import './styles.scss';

interface WizardModalProps {
    children: ReactNode;
    title: string;
    onBack?: () => void;
    onClose?: () => void;
}

export function WizardModalContent({ children, title, onBack, onClose }: WizardModalProps) {
    return (
        <Container>
            <Controls>
                <ControlsInner>
                    <Box>
                        {onBack ? (
                            <Anchor data-atid="BackButton" sx={{ display: 'flex', alignItems: 'center' }} onClick={onBack} color="gray">
                                <ThemeIcon color="gray" variant="light" mr="xs">
                                    <ChevronLeft />
                                </ThemeIcon>
                                Back
                            </Anchor>
                        ) : null}
                    </Box>
                    <Box>
                        {onClose ? (
                            <Anchor data-atid="CloseButton" onClick={onClose} color="gray">
                                <ThemeIcon color="gray" variant="light">
                                    <X />
                                </ThemeIcon>
                            </Anchor>
                        ) : null}
                    </Box>
                </ControlsInner>
            </Controls>
            <Content>
                <ContentInner>
                    <Space h="xl" />
                    <Title data-atid="ModalHeader" align="center">
                        {title}
                    </Title>
                    <Space h="xl" />
                    {children}
                </ContentInner>
            </Content>
        </Container>
    );
}

export function useFullscreenModal() {
    const { getId } = useIdGen();
    const DiContainer = useDiComponent();
    const modals = useModals();
    return {
        open: (renderer: (close: () => void) => ReactNode) => {
            const modalId = `fullscreen-modal-${getId({})}`;
            const close = () => modals.closeModal(modalId);
            openModal({
                modalId,
                fullScreen: true,
                className: 'fullscreen-modal',
                children: <DiContainer>{renderer(close)}</DiContainer>,
                withCloseButton: false,
            });
            return {
                close,
            };
        },
    };
}

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
`;
const Controls = styled.div`
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    background-color: ${(p) => p.theme.white};
`;
const ControlsInner = styled.div`
    max-width: ${(p) => p.theme.breakpoints.lg}px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
`;
const Content = styled.div`
    flex: 1;
    background-color: ${(p) => p.theme.colors.gray[1]};
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;
const ContentInner = styled.div`
    max-width: ${(p) => p.theme.breakpoints.lg}px;
    width: 100vw;
`;

const ContentInnerSingleChild = styled.div`
    max-width: ${(p) => p.theme.breakpoints.lg}px;
`;

export function WizardModalContentSingleChild({ children, title, onBack, onClose }: WizardModalProps) {
    return (
        <Container>
            <Controls>
                <ControlsInner>
                    <Box>
                        {onBack ? (
                            <Anchor sx={{ display: 'flex', alignItems: 'center' }} onClick={onBack} color="gray">
                                <ThemeIcon color="gray" variant="light" mr="xs">
                                    <ChevronLeft />
                                </ThemeIcon>
                                Back
                            </Anchor>
                        ) : null}
                    </Box>
                    <Box>
                        {onClose ? (
                            <Anchor onClick={onClose} color="gray">
                                <ThemeIcon color="gray" variant="light">
                                    <X />
                                </ThemeIcon>
                            </Anchor>
                        ) : null}
                    </Box>
                </ControlsInner>
            </Controls>
            <Content>
                <ContentInnerSingleChild>
                    <Space h="xl" />
                    <Title align="center">{title}</Title>
                    <br></br>
                    <Space h="xl" />
                    {children}
                </ContentInnerSingleChild>
            </Content>
        </Container>
    );
}
