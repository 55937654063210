import { Button, Popover, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, LayoutDashboard } from 'tabler-icons-react';
import { IDashboardConfig } from '../DashboardLayout/Models';
import { DashboardConfigLoadPanel } from '../DashboardPersistence/DashboardConfigLoadPanel';
import { DashboardConfigPanel } from '../DashboardPersistence/DashboardConfigPanel';
import { DashboardPersistenceService, IDashboardConfigBase } from '../DashboardPersistence/DashboardPersistenceService';
import { Picker } from '../Picker/Picker';
import { DataGridModel } from './DataGridModel';
import { DashboardUserSettings } from '@apis/Customers/model';
import { getDashboardGetOrCreateDashboardUserSettings, getGetDashboardGetOrCreateDashboardUserSettingsQueryKey } from '@apis/Customers';

export function GridSavedViews({ grid }: { grid: DataGridModel }) {
    const [panelOpened, { close: closePanel, toggle: togglePanel }] = useDisclosure(false);
    const [menuOpened, { close: closeMenu, toggle: toggleMenu }] = useDisclosure(false);
    const dashboardPersistenceSvc = useDi(DashboardPersistenceService);
    const [userSettings, setUserSettings] = useState<DashboardUserSettings>();
    const [options, setOptions] = useState<{ id: number; name: string }[]>([]);
    const loadOptions = useCallback(async () => {
        const settings = await getDashboardGetOrCreateDashboardUserSettings();
        setUserSettings(settings);
        if (!grid.currentOptions?.statePersistence?.key) {
            return;
        }
        const options = await dashboardPersistenceSvc.getLayouts<IDashboardConfigBase>(grid.currentOptions?.statePersistence?.key ?? '');
        options.sort((a, b) => a.layout.name.localeCompare(b.layout.name, undefined, { sensitivity: 'base' }) ?? 0);
        setOptions(options.map((o) => ({ id: o.id, name: o.layout.name })));
    }, [grid]);
    const handleLoad = useCallback(
        async ({ id }: { id: number }) => {
            if (id !== grid.getConfigId()) {
                const layout = await dashboardPersistenceSvc.load(grid.currentOptions?.statePersistence?.key ?? '', id);
                grid.loadStateConfig(id, layout, true);
            }
        },
        [grid]
    );
    const handleQuickLoad = useCallback(
        async (ids: { id: number }[]) => {
            if (ids.length) {
                await handleLoad(ids[0]);
            }
            closeMenu();
        },
        [handleLoad, closeMenu]
    );

    const handleSave = useCallback(
        async (name: string) => {
            await grid.saveStateCopy(name);
            loadOptions();
        },
        [grid]
    );

    const handleConfigChange = useCallback(
        ({ id, layout: { name } }: { id: number; layout: { name: string } }, action: 'rename' | 'delete') => {
            if (action === 'delete') {
                grid.handleStateDelete(id);
                loadOptions();
            } else if (action === 'rename') {
                grid.handleStateRename(id, name);
                loadOptions();
            }
        },
        [grid]
    );
    const selected = useMemo(() => options?.filter((o) => o.id === grid.getConfigId()), [options, grid.getConfigId()]);

    useEvent(grid.stateSaved, loadOptions);

    useEffect(() => {
        loadOptions();
    }, [grid?.currentOptions?.statePersistence?.key]);
    return (
        <>
            <Button.Group my={5}>
                <Button
                    size="xs"
                    variant="outline"
                    radius="lg"
                    leftIcon={<LayoutDashboard size={16} />}
                    onClick={togglePanel}
                    data-atid="SavedViewsButton"
                >
                    Saved Views
                </Button>
                <Button size="xs" variant="outline" radius="lg" onClick={() => grid.saveState(true)} data-atid="SavedViewsSaveButton">
                    Save
                </Button>
                <Menu position="bottom-end" opened={menuOpened} onClose={closeMenu} shadow="md">
                    <Menu.Target>
                        <Button onClick={toggleMenu} size="xs" variant="outline" radius="lg" rightIcon={<ChevronDown size={16} />}>
                            {selected?.[0]?.name ?? 'Default'}
                        </Button>
                    </Menu.Target>
                    <Menu.Dropdown p={0}>
                        <Picker
                            noFilter
                            mode="single"
                            items={options}
                            selections={selected}
                            onChange={handleQuickLoad}
                            nameAccessor="name"
                            minimizeHeight
                            height={300}
                        />
                    </Menu.Dropdown>
                </Menu>
            </Button.Group>
            <DashboardConfigPanel
                onConfigChange={handleConfigChange}
                configKey={grid.currentOptions?.statePersistence?.key ?? ''}
                loadPrompt=""
                loadTitle="My Saved Views"
                savePrompt="Save a copy of the current view"
                saveTitle="Save View"
                title="Saved Views"
                userSettings={userSettings!}
                onClose={closePanel}
                opened={panelOpened}
                onLoad={handleLoad}
                onSave={handleSave}
            />
        </>
    );
}

export function useGridSave<T>(saveState?: () => T, loadState?: (state: T) => void, grid?: DataGridModel) {
    const { onStateLoaded, onStateSaving, save } = useMemo(() => {
        const result = {
            onStateSavingHandler: (config: IDashboardConfig) => {
                if (saveState) {
                    config.layout.push({ x: 0, y: 0, h: 0, w: 0, data: saveState() });
                }
                result.defaultOnStateSaving?.(config);
            },
            onStateLoadedHandler: (config?: IDashboardConfig) => {
                if (config?.layout?.length) {
                    const [, layout] = config?.layout;
                    if (layout?.data) {
                        if (loadState) {
                            loadState(layout.data);
                        }
                    }
                }
                result.defaultOnStateLoaded?.(config);
            },
            defaultOnStateLoaded: undefined as undefined | ((config?: IDashboardConfig) => void),
            defaultOnStateSaving: undefined as undefined | ((config: IDashboardConfig) => void),
            onStateSaving: (handler?: (config?: IDashboardConfig) => void) => {
                result.defaultOnStateSaving = handler;
                return result.onStateSavingHandler;
            },
            onStateLoaded: (handler?: (config?: IDashboardConfig) => void) => {
                result.defaultOnStateLoaded = handler;
                return result.onStateLoadedHandler;
            },
            save: () => {
                if (grid) {
                    grid.saveState(true);
                }
            },
        };
        return result;
    }, [grid, saveState, loadState]);

    return { onStateLoaded, onStateSaving, save };
}
