import { getDashboardGetByKey, postDashboardSaveDashboardUserSettings } from '@apis/Customers';
import { DashboardConfig, DashboardUserSettings } from '@apis/Customers/model';
import {
    Box,
    Button,
    Card,
    CloseButton,
    Collapse,
    Divider,
    Drawer,
    Group,
    LoadingOverlay,
    Space,
    Switch,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { PanelBody, PanelContent } from '@root/Design/Layout';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useCallback, useMemo, useState } from 'react';
import { Check } from 'tabler-icons-react';
import { IDashboardConfigBase } from './DashboardPersistenceService';
import { DashboardConfigLoadPanel } from './DashboardConfigLoadPanel';

interface DashboardConfigPanelProps {
    configKey: string;
    opened: boolean;
    onClose: () => void;
    title: string;
    saveTitle: string;
    savePrompt: string;
    loadTitle: string;
    loadPrompt: string;
    tabText?: { my?: string; shared?: string; default?: 'My Saved Views' | 'Shared Saved Views' };
    userSettings: DashboardUserSettings;
    onSave: (name: string) => Promise<void>;
    onLoad: (config: { id: number; layout: IDashboardConfigBase }) => void;
    onConfigChange: (config: { id: number; layout: IDashboardConfigBase }, action: 'rename' | 'delete') => void;
    canSave?: boolean;
}
export function DashboardConfigPanel(props: DashboardConfigPanelProps) {
    const updated = useMemo(() => EventEmitter.empty(), []);
    const onSave = useCallback(
        async (name: string) => {
            await props.onSave(name);
            updated.emit();
        },
        [props.onSave]
    );
    return (
        <>
            <Drawer
                size={500}
                zIndex={101}
                opened={props.opened}
                onClose={props.onClose}
                position="right"
                withCloseButton={false}
                withinPortal
                overlayOpacity={0.25}
            >
                {props.opened && (
                    <PanelContent>
                        <Group position="apart" px="xl" py="sm">
                            <Title order={4}>{props.title}</Title>
                            <CloseButton size="lg" onClick={props.onClose} data-atid="DashConfigDrawerCloseButton" />
                        </Group>
                        <Divider color="gray.2" />
                        {props.canSave === false ? null : (
                            <>
                                <Box p="xl">
                                    <SavePanel title={props.saveTitle} prompt={props.savePrompt} onSave={onSave} />
                                </Box>
                                <Box p="xl">
                                    <ToggleAutoSavePanel userSettings={props.userSettings} />
                                </Box>
                            </>
                        )}

                        <PanelBody noPadding>
                            <DashboardConfigLoadPanel
                                onConfigChange={props.onConfigChange}
                                onSelect={props.onLoad}
                                title={props.loadTitle}
                                prompt={props.loadPrompt}
                                configKey={props.configKey}
                                tabText={props.tabText}
                                updated={updated}
                            />
                        </PanelBody>
                    </PanelContent>
                )}
            </Drawer>
        </>
    );
}

function SavePanel({ title, prompt, onSave }: { title: string; prompt: string; onSave: (name: string) => Promise<void> }) {
    const theme = useMantineTheme();
    const [name, setName] = useInputState<string>('');
    const [saved, setSaved] = useState<boolean>();
    const save = useCallback(() => {
        setSaved(false);
        onSave(name).then(() => setSaved(true));
    }, [name]);
    return (
        <Card sx={{ background: saved ? theme.colors.success[2] : theme.colors.gray[2] }} radius="md">
            <LoadingOverlay visible={saved === false} />
            <Collapse in={!saved}>
                <Text>{title}</Text>
                <Text size="sm" color="dimmed">
                    {prompt}
                </Text>
                <Space h="xs" />
                <TextInput autoComplete="off" value={name} onChange={setName} data-atid="SavedViewsTextInput" />
                <Space h="xs" />
                <Group position="right">
                    <Button disabled={!name} variant="outline" onClick={save} data-atid="SaveViewButton">
                        Save
                    </Button>
                </Group>
            </Collapse>
            <Collapse in={!!saved}>
                <Group>
                    <Check color={theme.colors.success[6]} />
                    <Text color="success">Saved</Text>
                </Group>
            </Collapse>
        </Card>
    );
}

function ToggleAutoSavePanel({ userSettings }: { userSettings: DashboardUserSettings }) {
    const theme = useMantineTheme();
    const toggle = async () => {
        userSettings.DashboardAutoSave = !userSettings.DashboardAutoSave;
        userSettings = await postDashboardSaveDashboardUserSettings(userSettings);
    };
    return (
        <Card sx={{ background: theme.colors.gray[2] }} radius="md">
            <Text>Toggle Auto save</Text>

            <Space h="xs" />
            <Group position="left">
                <Switch defaultChecked={userSettings.DashboardAutoSave} onChange={toggle} />
            </Group>
            <Text size="sm" color="dimmed">
                Toggle automatic saving based on grid changes
            </Text>
        </Card>
    );
}
