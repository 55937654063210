import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { Layout } from '@root/Components/DashboardLayout/Models';

export const defaultMapDashboardLayout = [
    {
        x: 0,
        y: 0,
        h: 4,
        w: 6,
        data: {
            type: 'current-tag',
            title: 'Tag Coverage',
        },
    },
    {
        x: 6,
        y: 0,
        w: 6,
        h: 4,
        data: {
            type: 'actions-required',
            title: 'Action Required',
        },
    },
    {
        x: 0,
        y: 12,
        w: 6,
        h: 4,
        data: {
            type: 'accounts-connected',
            title: 'Accounts Connected',
        },
    },
    {
        x: 6,
        y: 12,
        w: 6,
        h: 4,
        data: {
            type: 'configuration-settings',
            title: 'Configuration Settings',
        },
    },
    {
        x: 0,
        y: 4,
        h: 6,
        w: 12,
        data: {
            type: 'eligible-resources-by-region',
            title: 'MAP Eligible Resources by AWS Region',
        },
    },
    {
        x: 0,
        y: 15,
        h: 4,
        w: 4,
        data: {
            type: 'key-values-applied',
            title: 'Key Values Applied',
        },
    },
    {
        x: 4,
        y: 15,
        h: 4,
        w: 4,
        data: {
            type: 'top-5-owners',
            title: 'Top 5 Owners',
        },
    },
    {
        x: 8,
        y: 15,
        h: 4,
        w: 4,
        data: {
            type: 'top-5-resources',
            title: 'Top 5 Resource Types, By Spend',
        },
    },
] as Layout<ChartConfig | { title: string; type: string }>[];
