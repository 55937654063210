import styled from '@emotion/styled';
import { colorPalette } from './Themes';

export const DivLeft = styled.div`
    float: left;
`;

export const DivRight = styled.div`
    float: right;
`;

export const DivClear = styled.div`
    clear: both;
`;

export const DivCenter = styled.div`
    text-align: center;
    margin: 0 auto;
`;

export const DivCenterMarginTop = styled.div`
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
`;

export const UsageSelector = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;

    div {
        float: left;
        width: 23%;
        border: 1px solid #000;
        padding: 5px;
    }

    div.selected {
        background: #000;
        color: ${colorPalette.fffColor};
    }
`;

export const ResourceInfoTable = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
    }
    table thead td {
        font-weight: bold;
        padding: 3px;
    }
    table tbody tr td {
        border-bottom: 1px solid ${(p) => p.theme.colors.gray[3]};
        padding: 3px;
    }
    table tbody tr:last-child td {
        border-bottom-width: 0;
        padding: 3px;
    }
`;

export const ResourceInfoContainerTable = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;
    }
    table tr td {
        padding: 5px;
    }
    table tr:first-of-type td {
        text-align: center;
        cursor: pointer;
        border: 1px solid #006;
    }
    table tr:first-of-type td.selected {
        background: #006;
        color: ${colorPalette.fffColor};
    }
    table tr:first-of-type td.unselected {
        color: #006;
        background: ${colorPalette.fffColor};
    }
`;

export const ResourceInfoData = styled.div`
    .label {
        font-weight: bold;
        float: left;
        width: 49%;
        text-align: right;
        padding-right: 5px;
    }
    .value {
        float: left;
        width: 49%;
        padding-left: 5px;
    }
    .clear {
        clear: both;
    }
`;

export const ResourceChart = styled.div`
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    padding: 15px 15px 20px 15px;
    margin: 0 25px 0 0;
    border-radius: 10px;
    box-shadow: 2px 2px #efefef;
`;

export const MetricName = styled.div`
    width: 60%;
    float: left;
`;

export const MinAvgMaxContainer = styled.div`
    width: 40%;
    float: right;
    font-size: 0.7em;
    border-radius: 10px;
    background: #efefef;
    display: table;
    table-layout: fixed;
`;

export const MinAvgMax = styled.div`
    display: table-cell;
    div:first-of-type {
        text-align: center;
    }
    div:last-child {
        font-size: 1em;
        text-align: center;
        font-weight: bold;
    }
`;
