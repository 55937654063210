import { Table } from '@mantine/core';

export function NotFound(props: { description: string }) {
    return (
        <Table className="resourcesTable">
            <tbody>
                <tr>
                    <td></td>
                    <td>
                        <i>{props.description}</i>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}
