import styled from '@emotion/styled';
import { CloseButton, Divider, Drawer, DrawerProps, Group, GroupProps, MantineNumberSize, Title, TitleProps, useMantineTheme } from '@mantine/core';
import { EventEmitter, useEvent, useToggle } from '@root/Services/EventEmitter';
import { ForwardedRef, forwardRef, ReactNode, RefAttributes, useMemo, useState } from 'react';

type SidePanelTitleProps = Omit<GroupProps, 'title'> & { onClose?: () => void; title: ReactNode };
/**
 * Basic layout component for the SidePanel title and X button
 * Prefer to use the `SidePanel` component, use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export const SidePanelTitle = forwardRef<HTMLDivElement, SidePanelTitleProps>(({ onClose, title, sx, ...props }, ref) => {
    const theme = useMantineTheme();
    return (
        <>
            <Group ref={ref} position="apart" px="lg" py="md" sx={{ borderBottom: `solid 1px ${theme.colors.gray[3]}`, ...sx }} {...props}>
                <SidePanelTitleEl>{title}</SidePanelTitleEl>
                {onClose ? <SidePanelXButtonEl onClick={onClose} /> : null}
            </Group>
        </>
    );
});

/**
 * Basic style element for side panel outer container
 * Prefer to use the `SidePanel` component, use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export const SidePanelPanelEl = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

/**
 * Basic style element for side panel title text
 * Prefer to use the `SidePanel` component, `<SidePanelPanel title={...}>`, use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export const SidePanelTitleEl = forwardRef<HTMLHeadingElement, TitleProps>(
    ({ children, ...rest }: TitleProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <Title ref={ref} order={3} sx={{ fontSize: 18 }} {...rest}>
                {children}
            </Title>
        );
    }
);

/**
 * Basic side panel X button
 * Prefer to use the `SidePanel` component, `<SidePanelPanel onClose={...}>`, use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export function SidePanelXButtonEl({ onClick }: { onClick: () => void }) {
    return <CloseButton onClick={onClick} />;
}

/**
 * Basic style element for a side panel's main content area which is scrollable and consumes all available side panel height left over by the title bar and toolbar
 * Prefer to use the `SidePanel` component, `<SidePanelPanel ...>{content here}</SidePanelPanel>`, use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export const SidePanelContentEl = styled.div<{ padding?: MantineNumberSize }>`
    overflow: auto;
    min-height: 0;
    flex: 1;
    padding: ${(p) => (typeof p.padding === 'string' ? p.theme.spacing[p.padding] : p.padding ?? p.theme.spacing.lg) + 'px'};
`;

/**
 * Basic style element for a side panel's toolbar
 * Prefer to use the `SidePanel` component, `<SidePanel toolbar={...buttons}>` use this only for very custom side panels, that must break the design rules and create inconsistency
 */
export const SidePanelToolbarEl = forwardRef<HTMLDivElement, GroupProps>(({ sx, children, ...rest }: GroupProps, ref) => {
    const theme = useMantineTheme();
    return (
        <>
            <Group
                ref={ref}
                p="lg"
                position="right"
                spacing="md"
                sx={{ background: theme.colors.gray[2], borderTop: `solid 1px ${theme.colors.gray[3]}`, ...sx }}
                {...rest}
            >
                {children}
            </Group>
        </>
    );
});

type SidePanelContainerProps = { children: ReactNode; title: ReactNode; onClose: () => void; toolbar?: ReactNode };
/**
 * Base side panel composite component.
 * Prefer to use the `SidePanel` component when creating a SidePanel (aka Drawer)
 */
export const SidePanelContainer = function SidePanelPanel(props: SidePanelContainerProps) {
    return (
        <SidePanelPanelEl>
            <SidePanelTitle title={props.title} onClose={props.onClose}></SidePanelTitle>
            <SidePanelContentEl>{props.children}</SidePanelContentEl>
            {toolbar ? <SidePanelToolbarEl>{props.toolbar}</SidePanelToolbarEl> : null}
        </SidePanelPanelEl>
    );
};

export type SidePanelOpener = { opened: boolean; close: () => void; open: () => void; toggle: () => void; evt: EventEmitter<boolean> };
export function useSidePanelOpener(defaultOpened?: boolean) {
    const opener = useMemo(() => {
        const evt = new EventEmitter(!!defaultOpened);
        return {
            open: () => evt.emit(true),
            close: () => evt.emit(false),
            toggle: () => evt.emit(!evt.value),
            get opened() {
                return evt.value;
            },
            evt,
        };
    }, []);

    return opener;
}
type SidePanelProps = Omit<DrawerProps, 'title' | 'onClose' | 'opened' | 'children'> &
    Omit<SidePanelContainerProps, 'onClose' | 'children'> & {
        /**
         * Pass the opener to the side panel, e.g.,:
         *
         * ```
         * const myPanelOpener = useSidePanelOpener();
         * ...
         * myPanelOpener.open()
         * ...
         * <SidePanel opener={myPanelOpener}>...constent</SidePanel>
         * ```
         */
        opener: SidePanelOpener;
        /**
         * Pass a function to have the content rendered only when the sidepanel is open
         */
        children: ReactNode | (() => ReactNode);
    };
/**
 * Base side panel, uses the mantine drawer and sidepanel style elements configured to ensure consistency
 *
 * usage:
 * ```
 * const myPanelOpener = useSidePanelOpener();
 *
 * return <SidePanel title="My Title" opener={myPanelOpener} toolbar={<><Button>Apply</Button></>}>
 *     ...side panel content here
 * </SidePanel>
 * ```
 */
export function SidePanel({ title, children, toolbar, opener, ...drawerProps }: SidePanelProps) {
    useEvent(opener.evt);
    return (
        <Drawer onClose={opener.close} withCloseButton={false} position="right" {...{ ...drawerProps, opened: opener.opened }}>
            <SidePanelContainer title={title} onClose={opener.close} toolbar={toolbar}>
                {typeof children !== 'function' ? children : opener.opened ? children() : null}
            </SidePanelContainer>
        </Drawer>
    );
}
