import { usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';

export function GetResourceDetails(props: {
    resourceType: string;
    resourceId: string;
    onModelLoaded: (model: BaseResource & { Name: string }) => void;
}) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    var resourceType = props.resourceType;
    const formatSvc = useDi(FormatService);

    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);
    useEffect(() => {
        if (data?.Results?.length) {
            props.onModelLoaded(data.Results[0] as BaseAwsResource & { Name: string });
        }
    }, [data]);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <>No resource found!</>;
        } else {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            var resourceValues = [];
            const resource = data.Results[0] as Record<string, any>;

            var resourceName =
                (resource?.Name ?? '') != '' ? ((resource?.CloudPlatform ?? 'Aws') !== 'Aws' ? '' : resource?.CsTags?.Name ?? '') : resource?.Name;

            try {
                if (resourceType == 'API Gateway') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType.startsWith('Autoscale')) {
                    var dotPos = props.resourceId.indexOf('.');
                    var instanceId = props.resourceId.substring(dotPos + 1);
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Id', value: instanceId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'API Gateway HTTP API') {
                    resourceValues.push({ label: 'ApiEndpoint', value: resource.ApiEndpoint });
                    resourceValues.push({ label: 'Api Gateway Managed', value: resource.ApiGatewayManaged ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Disable Execute Api Endpoint', value: resource.Description ? 'true' : 'false' });
                    resourceValues.push({ label: 'Disable Schema Validation', value: resource.DisableSchemaValidation ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Protocol Type', value: resource.ProtocolType.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Route Selection Expression', value: resource.RouteSelectionExpression });
                } else if (resourceType == 'API Gateway Rest API') {
                    resourceValues.push({ label: 'Api Key Source', value: resource.ApiKeySource.Value });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Disable Execute Api Endpoint', value: resource.DisableExecuteApiEndpoint ? 'true' : 'false' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'API Gateway WebSocket API') {
                    resourceValues.push({ label: 'ApiEndpoint', value: resource.ApiEndpoint });
                    resourceValues.push({ label: 'Api Gateway Managed', value: resource.ApiGatewayManaged ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Disable Execute Api Endpoint', value: resource.Description ? 'true' : 'false' });
                    resourceValues.push({ label: 'Disable Schema Validation', value: resource.DisableSchemaValidation ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Protocol Type', value: resource.ProtocolType.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Route Selection Expression', value: resource.RouteSelectionExpression });
                } else if (resourceType == 'API Gateway Stage') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Deployment Id', value: resource.DeploymentId });
                    resourceValues.push({ label: 'Api Gateway Managed', value: resource.ApiGatewayManaged ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last Updated Date', value: formatSvc.toLocal(resource.LastUpdatedDate) });
                } else if (resourceType == 'AppStream Fleet') {
                    resourceValues.push({ label: 'Arn', value: resource.Arn });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Disconnect Timeout In Seconds', value: resource.DisconnectTimeoutInSeconds });
                    resourceValues.push({ label: 'Enable Default Internet Access', value: resource.EnableDefaultInternetAccess ? 'true' : 'false' });
                    resourceValues.push({ label: 'Fleet Type', value: resource.FleetType.Value });
                    resourceValues.push({ label: 'Idle Disconnect Timeout In Seconds', value: resource.IdleDisconnectTimeoutInSeconds });
                    resourceValues.push({ label: 'Image Arn', value: resource.ImageArn });
                    resourceValues.push({ label: 'Image Name', value: resource.ImageName });
                    resourceValues.push({ label: 'Instance Type', value: resource.InstanceType });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'MaxUser Duration In Seconds', value: resource.MaxUserDurationInSeconds });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Stream View', value: resource.StreamView.Value });
                } else if (resourceType == 'AppStream Stack') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Display Name', value: resource.DisplayName });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType.startsWith('AppStream')) {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'AppSync') {
                    resourceValues.push({ label: 'API Type', value: resource.ApiType.Value });
                    resourceValues.push({ label: 'ARN', value: resource.Arn });
                    resourceValues.push({ label: 'Authentication Type', value: resource.AuthenticationType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Owner/Contact', value: resource.OwnerContact });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Visibility', value: resource.Visibility.Value });
                    resourceValues.push({ label: 'Xray Enabled', value: resource.XrayEnabled ? 'true' : 'false' });
                } else if (resourceType == 'Athena') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Effective Engine Version', value: resource.Configuration.EngineVersion.EffectiveEngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Selected Engine Version', value: resource.Configuration.EngineVersion.SelectedEngineVersion });
                } else if (resourceType == 'Athena Capacity Reservation') {
                    resourceValues.push({ label: 'Allocated Dpus', value: resource.AllocatedDpus });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Successful Allocation Time', value: resource.LastSuccessfulAllocationTime });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                } else if (resourceType.startsWith('Backup')) {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType.startsWith('Batch Compute Environment')) {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Status Reason', value: resource.StatusReason });
                    resourceValues.push({ label: 'UnManaged vCPUs', value: resource.UnmanagedvCpus });
                } else if (resourceType == 'Certificate Manager') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Domain Name', value: resource.DomainName });
                    resourceValues.push({ label: 'KeyAlgorithm', value: resource.KeyAlgorithm.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                } else if (resourceType == 'Cloud Directory') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Cloud HSM Cluster') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'HSM Type', value: resource.HsmType });
                    resourceValues.push({ label: 'VPC Id', value: resource.VpcId });
                } else if (resourceType == 'Cloud HSM Backup') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'HSM Type', value: resource.HsmType });
                    resourceValues.push({ label: 'VPC Id', value: resource.VpcId });
                } else if (resourceType == 'CloudFront') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Domain Name', value: resource.DomainName });
                    resourceValues.push({ label: 'Http Version', value: resource.DistributionConfig.HttpVersion.Value });
                    resourceValues.push({ label: 'Last Modified', value: formatSvc.toLocal(resource.LastModifiedTime) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'CloudFront Streaming Distribution') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Domain Name', value: resource.DomainName });
                    resourceValues.push({ label: 'Last Modified', value: formatSvc.toLocal(resource.LastModifiedTime) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                } else if (resourceType == 'CloudTrail Trail') {
                    resourceValues.push({ label: 'Has Custom Event Selectors', value: resource.HasCustomEventSelectors ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Has Insight Selectors', value: resource.Region ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Include Global Service Events', value: resource.IncludeGlobalServiceEvents ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Is Organization Trail', value: resource.IsOrganizationTrail ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Is MultiRegion Trail', value: resource.IsMultiRegionTrail ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Log File Validation Enabled', value: resource.LogFileValidationEnabled ? 'Yes' : 'No' });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'S3 Bucket Name', value: resource.S3BucketName });
                } else if (resourceType == 'CloudWatch Log Group') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Metric Filter Count', value: resource.MetricFilterCount });
                    resourceValues.push({ label: 'Name', value: resourceName });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Stored Bytes', value: resource.StoredBytes });
                } else if (resourceType == 'CloudWatch Metric Stream') {
                    resourceValues.push({ label: 'Arn', value: resource.Arn });
                    resourceValues.push({ label: 'Check sum Algorithm', value: resource.ResponseMetadata.ChecksumAlgorithm });
                    resourceValues.push({ label: 'Check sum Validation Status', value: resource.ResponseMetadata.ChecksumValidationStatus });
                    resourceValues.push({ label: 'Content Length', value: resource.ContentLength });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Firehose Arn', value: resource.FirehoseArn });
                    resourceValues.push({ label: 'Http Status Code', value: resource.HttpStatusCode });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Updated', value: resource.LastUpdateDate });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Output Format', value: resource.OutputFormat.Value });
                    resourceValues.push({ label: 'State', value: resource.State });
                } else if (resourceType == 'CloudWatch Metric Alarm') {
                    resourceValues.push({ label: 'Name', value: resource.AlarmName });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'CloudWatch Composite Alarm ') {
                    resourceValues.push({ label: 'Name', value: resource.AlarmName });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Code Star') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified', value: resource.LastModified });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Project Id', value: resource.ProjectId });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'CodeBuild Project') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified', value: resource.LastModified });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Queued Timeout In Minutes', value: resource.QueuedTimeoutInMinutes });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Timeout In Minutes', value: resource.TimeoutInMinutes });
                } else if (resourceType == 'CodeBuild Report Group') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Export Config', value: resource.ExportConfig.ExportConfigType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified', value: resource.LastModified });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                } else if (resourceType == 'CodePipeline') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Updated Date', value: formatSvc.toLocal(resource.Updated) });
                    resourceValues.push({ label: 'Version', value: resource.Version });
                } else if (resourceType == 'Cognito Identity') {
                    resourceValues.push({ label: 'Client Id', value: resource.CognitoIdentityProviders[0].ClientId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Provider Name', value: resource.CognitoIdentityProviders[0].ProviderName });
                } else if (resourceType == 'Cognito User Pool') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Estimated Number of Users', value: resource.EstimatedNumberOfUsers });
                    resourceValues.push({ label: 'MFA Configuration', value: resource.MfaConfiguration.Value });
                } else if (resourceType == 'Comprehend') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Data Lake S3 Uri', value: resource.DataLakeS3Uri });
                    resourceValues.push({ label: 'Model Type', value: resource.ModelType.Value });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                } else if (resourceType == 'Database Migration Service Endpoint') {
                    resourceValues.push({ label: 'Engine Name', value: resource.EngineName });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Database Migration Service Event Subscription') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Sns Topic Arn', value: resource.SnsTopicArn });
                    resourceValues.push({ label: 'Source Type', value: resource.SourceType });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                } else if (resourceType == 'Database Migration Service Replication Instance') {
                    resourceValues.push({ label: 'Allocated Storage', value: resource.AllocatedStorage });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Engine Name', value: resource.EngineName });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Replication Instance Class', value: resource.ReplicationInstanceClass });
                    resourceValues.push({ label: 'Vpc Id', value: resource.ReplicationSubnetGroup.VpcId });
                } else if (resourceType == 'Database Migration Service Replication Task') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Migration Type', value: resource.MigrationType.Value });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Stop Reason', value: resource.StopReason });
                } else if (resourceType == 'DataSync Location') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Location Uri', value: resource.LocationUri });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'DataSync Task') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType.startsWith('DataSync')) {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Data Pipeline') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'Direct Connect') {
                    resourceValues.push({ label: 'Bandwidth', value: resource.Bandwidth });
                    resourceValues.push({ label: 'Connection State', value: resource.ConnectionState.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Provider Name', value: resource.ProviderName });
                } else if (resourceType == 'Directory Service') {
                    resourceValues.push({ label: 'AccessUrl', value: resource.AccessUrl });
                    resourceValues.push({ label: 'Alias', value: resource.Alias });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                } else if (resourceType == 'DocumentDB Cluster') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                } else if (resourceType == 'DocumentDB Instance') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Instance Class', value: resource.DBInstanceClass });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'StorageType', value: resource.StorageType });
                } else if (resourceType == 'Dynamo DB') {
                    if (resource.BillingModeSummary != null) {
                        resourceValues.push({ label: 'Billing Mode', value: resource.BillingModeSummary.BillingMode.Value });
                    }
                    resourceValues.push({ label: 'Create Date', value: resource.CreationDateTime });
                    resourceValues.push({ label: 'Item Count', value: resource.ItemCount });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({
                        label: 'Provisioned Throughput Last Decrease',
                        value:
                            resource.ProvisionedThroughput.LastDecreaseDateTime === '0001-01-01T00:00:00'
                                ? 'N/A'
                                : resource.ProvisionedThroughput.LastDecreaseDateTime,
                    });
                    resourceValues.push({
                        label: 'Provisioned Throughput Last Increase',
                        value:
                            resource.ProvisionedThroughput.LastIncreaseDateTime === '0001-01-01T00:00:00'
                                ? 'N/A'
                                : resource.ProvisionedThroughput.LastIncreaseDateTime,
                    });
                    resourceValues.push({
                        label: 'Provisioned Throughput Number Of Decreases Today',
                        value: resource.ProvisionedThroughput.NumberOfDecreasesToday,
                    });
                    resourceValues.push({
                        label: 'Provisioned Throughput Read Capacity Units',
                        value: resource.ProvisionedThroughput.ReadCapacityUnits,
                    });
                    resourceValues.push({
                        label: 'Provisioned Throughput Write Capacity Units',
                        value: resource.ProvisionedThroughput.WriteCapacityUnits,
                    });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.TableStatus.Value });
                    resourceValues.push({ label: 'Table Size', value: resource.TableSizeBytes });
                } else if (resourceType == 'Dynamo DB DAX') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'EBS') {
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Create Date', value: resource.CreateTime });
                    resourceValues.push({
                        label: 'Delete on Termination',
                        value: resource.Attachments[0].DeleteOnTermination ? 'Yes' : 'No',
                    });
                    resourceValues.push({ label: 'Device', value: resource.Attachments[0].Device });
                    resourceValues.push({ label: 'Encrypted', value: resource.Encrypted ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Instance Id', value: resource.Attachments[0].InstanceId });
                    resourceValues.push({ label: 'IOPS', value: resource.Iops });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Size', value: resource.Size + ' GB' });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Volume Id', value: resource.Id });
                    resourceValues.push({ label: 'Volume Type', value: resource.VolumeType.Value });
                } else if (resourceType == 'EC2 Flow Log') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Deliver Logs Permission Arn', value: resource.DeliverLogsPermissionArn });
                    resourceValues.push({ label: 'Deliver Logs Status', value: resource.DeliverLogsStatus });
                    resourceValues.push({ label: 'Flow Log Id', value: resource.FlowLogId });
                    resourceValues.push({ label: 'Flow Log Status', value: resource.FlowLogStatus });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Log Destination Type', value: resource.LogDestinationType.Value });
                    resourceValues.push({ label: 'Max Aggregation Interval', value: resource.MaxAggregationInterval });
                    resourceValues.push({ label: 'Name', value: resourceName });
                    resourceValues.push({ label: 'Traffic Type', value: resource.TrafficType.Value });
                } else if (resourceType == 'EC2 Image') {
                    resourceValues.push({ label: 'Architecture', value: resource.Architecture.Value });
                    resourceValues.push({ label: 'Create Date', value: resource.CreationDate });
                    resourceValues.push({ label: 'Device Name', value: resource.BlockDeviceMappings[0].DeviceName });
                    resourceValues.push({ label: 'Hypervisor', value: resource.Hypervisor.Value });
                    resourceValues.push({ label: 'Image Type', value: resource.ImageType.Value });
                    resourceValues.push({ label: 'Iops', value: resource.BlockDeviceMappings[0].Ebs.Iops });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Platform', value: resource.PlatformDetails });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Usage Operation', value: resource.UsageOperation });
                    resourceValues.push({ label: 'Volume Size', value: resource.BlockDeviceMappings[0].Ebs.VolumeSize + ' GB' });
                    resourceValues.push({ label: 'Volume Type', value: resource.BlockDeviceMappings[0].Ebs.VolumeType.Value });
                } else if (resourceType == 'EC2 On Demand') {
                    resourceValues.push({ label: 'Architecture', value: resource.Architecture.Value });
                    resourceValues.push({ label: 'Availability Zone', value: resource.Placement.AvailabilityZone });
                    if (resource.CreditSpecifications != null) {
                        resourceValues.push({ label: 'CPU Credits', value: resource.CreditSpecifications.CpuCredits });
                    }
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'ENA Support', value: resource.EnaSupport == 'true' ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Hosting Type', value: resource.Placement.Tenancy.Value });
                    resourceValues.push({ label: 'Hypervisor', value: resource.Hypervisor.Value });
                    resourceValues.push({ label: 'Image Id', value: resource.ImageId });
                    resourceValues.push({ label: 'Instance Id', value: resource.InstanceId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Size', value: resource.InstanceType.Value });
                    resourceValues.push({ label: 'State', value: resource.State.Name.Value });
                    resourceValues.push({ label: 'Subnet Id', value: resource.SubnetId });
                    resourceValues.push({ label: 'Termination Protection', value: resource.DisableApiTermination == 'true' ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Virtualization Type', value: resource.VirtualizationType.Value });
                    resourceValues.push({ label: 'VPC ID', value: resource.VpcId });
                } else if (resourceType == 'EC2 Reserved') {
                    var duration = resource.Duration / 60 / 60 / 24 / 365;
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Duration', value: duration + (duration == 1 ? 'Year' : ' Years') });
                    resourceValues.push({ label: 'End', value: resource.End });
                    resourceValues.push({ label: 'FixedPrice', value: formatter.format(resource.FixedPrice) });
                    resourceValues.push({ label: 'InstanceCount', value: resource.InstanceCount });
                    resourceValues.push({ label: 'Instance Tenancy', value: resource.InstanceTenancy.Value });
                    resourceValues.push({ label: 'Instance Type', value: resource.InstanceType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Offering Class', value: resource.OfferingClass.Value });
                    resourceValues.push({ label: 'Offering Type', value: resource.OfferingType.Value });
                    resourceValues.push({ label: 'Product Description', value: resource.ProductDescription.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Scope', value: resource.Scope.Value });
                    resourceValues.push({ label: 'Start', value: resource.Start });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Usage Price', value: formatter.format(resource.UsagePrice) });
                } else if (resourceType == 'EC2 Snapshot') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Encrypted', value: resource.Encrypted.toString() });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Progress', value: resource.Progress });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Volume Size', value: resource.VolumeSize });
                } else if (resourceType == 'EC2 Spot') {
                    resourceValues.push({ label: 'Create Date', value: resource.CreateTime });
                    resourceValues.push({ label: 'Instance Interruption Behavior', value: resource.InstanceInterruptionBehavior.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Price', value: resource.SpotPrice });
                    resourceValues.push({ label: 'Product Description', value: resource.ProductDescription.Value });
                    resourceValues.push({ label: 'Status', value: resource.Status.Code });
                    resourceValues.push({
                        label: 'Valid Until',
                        value: resource.ValidUntil === '0001-01-01T00:00:00' ? 'No End Date Set' : resource.ValidUntil,
                    });
                } else if (resourceType == 'ECR') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Repository Uri', value: resource.RepositoryUri });
                    resourceValues.push({ label: 'Registry Id', value: resource.RegistryId });
                } else if (resourceType == 'ECS') {
                    resourceValues.push({ label: 'Capacity Providers', value: resource.CapacityProviders.join(', ') });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'ECS Service') {
                    resourceValues.push({ label: 'Desired Count', value: resource.DesiredCount });
                    resourceValues.push({ label: 'Health Check Grace Period Seconds', value: resource.HealthCheckGracePeriodSeconds });
                    resourceValues.push({ label: 'Enable ECS Managed Tags', value: resource.EnableECSManagedTags ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Enable Execute Command', value: resource.EnableExecuteCommand ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Launch Type', value: resource.LaunchType.Value });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Platform Family', value: resource.PlatformFamily });
                    resourceValues.push({ label: 'Platform Version', value: resource.PlatformVersion });
                    resourceValues.push({ label: 'Propagate Tags', value: resource.PropagateTags.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Running Count', value: resource.RunningCount });
                    resourceValues.push({ label: 'Scheduling Strategy', value: resource.SchedulingStrategy.Value });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                } else if (resourceType == 'ECS Task') {
                    resourceValues.push({ label: 'Created Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Cluster Arn', value: resource.ClusterArn });
                    resourceValues.push({ label: 'Enable Execute Command', value: resource.EnableExecuteCommand ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Launch Type', value: resource.LaunchType.Value });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Platform Family', value: resource.PlatformFamily });
                    resourceValues.push({ label: 'Platform Version', value: resource.PlatformVersion });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.LastStatus });
                } else if (resourceType == 'EKS') {
                    resourceValues.push({ label: 'ARN', value: resource.Arn });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Elastic Beanstalk Application') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Updated', value: resource.DateUpdated });
                } else if (resourceType == 'Elastic Beanstalk Environment') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Solution Name', value: resource.SolutionName });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Updated', value: resource.DateUpdated });
                    resourceValues.push({ label: 'Version', value: resource.VersionLabel });
                } else if (resourceType == 'Elastic Disaster Recovery Instance') {
                    resourceValues.push({ label: 'Ec2 Instance State', value: resource.Ec2InstanceState.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Origin Availability Zone', value: resource.OriginAvailabilityZone });
                    resourceValues.push({ label: 'Point In Time Snapshot DateTime', value: resource.PointInTimeSnapshotDateTime });
                    resourceValues.push({ label: 'Recovery Instance ID', value: resource.RecoveryInstanceID });
                    resourceValues.push({ label: 'Source Server ID', value: resource.SourceServerID });
                } else if (resourceType == 'Elastic Disaster Recovery Snapshot') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Source Server ID', value: resource.SourceServerID });
                    resourceValues.push({ label: 'Timestamp', value: resource.Timestamp });
                } else if (resourceType == 'Elastic Disaster Recovery Source Network') {
                    resourceValues.push({ label: 'Cfn Stack Name', value: resource.CfnStackName });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Replication Status', value: resource.ReplicationStatus });
                    resourceValues.push({ label: 'Source Account Id', value: resource.SourceAccountId });
                    resourceValues.push({ label: 'Source Network Id', value: resource.SourceNetworkId });
                    resourceValues.push({ label: 'Source Region', value: resource.SourceRegion });
                    resourceValues.push({ label: 'Source Vpc Id', value: resource.SourceVpcId });
                } else if (resourceType == 'Elastic Disaster Recovery Source Server') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last Updated DateTime', value: resource.SourceProperties.LastUpdatedDateTime });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'OS', value: resource.SourceProperties.Os.FullString });
                    resourceValues.push({ label: 'Ram Bytes', value: resource.SourceProperties.RamBytes });
                    resourceValues.push({ label: 'Recommended Instance Type', value: resource.SourceProperties.RecommendedInstanceType });
                    resourceValues.push({ label: 'Recovery Instance Id', value: resource.RecoveryInstanceId });
                } else if (resourceType == 'Elastic File System') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Encrypted', value: resource.Encrypted ? 'true' : 'false' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Life Cycle State', value: resource.LifeCycleState.Value });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Number Of Mount Targets', value: resource.NumberOfMountTargets });
                    resourceValues.push({ label: 'Performance Mode', value: resource.PerformanceMode.Value });
                    resourceValues.push({ label: 'Provisioned Throughput In Mibps', value: resource.ProvisionedThroughputInMibps });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Throughput Mode', value: resource.ThroughputMode.Value });
                } else if (resourceType == 'Elastic File System Access Point') {
                    resourceValues.push({ label: 'File System Id', value: resource.FileSystemId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Life Cycle State', value: resource.LifeCycleState.Value });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Root Directory', value: resource.RootDirectory.Path });
                } else if (resourceType == 'Elastic IP Address') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Network Interface Id', value: resource.NetworkInterfaceId });
                    resourceValues.push({ label: 'Private Ip Address', value: resource.PrivateIpAddress });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'ElastiCache Cluster') {
                    resourceValues.push({ label: 'Cache Cluster Status', value: resource.CacheClusterStatus });
                    resourceValues.push({ label: 'Cache Node Type', value: resource.CacheNodeType });
                    resourceValues.push({ label: 'Cache Parameter Group Name', value: resource.CacheParameterGroup.CacheParameterGroupName });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Ip Discovery', value: resource.IpDiscovery.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resourceName });
                    resourceValues.push({ label: 'Number Cache Nodes', value: resource.NumCacheNodes });
                    resourceValues.push({ label: 'Parameter Apply Status', value: resource.CacheParameterGroup.ParameterApplyStatus });
                    resourceValues.push({ label: 'Preferred Maintenance Window', value: resource.PreferredMaintenanceWindow });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'ElastiCache Replication Group') {
                    resourceValues.push({ label: 'At Rest Encryption Enabled', value: resource.AtRestEncryptionEnabled ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Auth Token Enabled', value: resource.AuthTokenEnabled });
                    resourceValues.push({ label: 'Auto Minor Version Upgrade', value: resource.AutoMinorVersionUpgrade ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Automatic Failover', value: resource.AutomaticFailover.Value });
                    resourceValues.push({ label: 'Cache Node Type', value: resource.CacheNodeType });
                    resourceValues.push({ label: 'Cluster Enabled', value: resource.ClusterEnabled ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Coverage Score', value: resource.CoverageScore });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Multi AZ', value: resource.MultiAZ.Value });
                    resourceValues.push({ label: 'Name', value: resourceName });
                    resourceValues.push({ label: 'Network Type', value: resource.NetworkType.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Replication Group Id', value: resource.ReplicationGroupId });
                    resourceValues.push({ label: 'Snapshot Retention Limit', value: resource.SnapshotRetentionLimit });
                    resourceValues.push({ label: 'Snapshot Window', value: resource.SnapshotWindow });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Transit Encryption Enabled', value: resource.TransitEncryptionEnabled ? 'Yes' : 'No' });
                } else if (resourceType == 'ElastiCache Snapshot') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resourceName });
                } else if (resourceType == 'ElasticSearch') {
                    resourceValues.push({ label: 'Domain Name', value: resource.DomainName });
                    resourceValues.push({ label: 'Engine Type', value: resource.EngineType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    if (resource.InstanceState != null) {
                        resourceValues.push({ label: 'Instance Count', value: resource.InstanceState.ElasticsearchClusterConfig.InstanceCount });
                        resourceValues.push({
                            label: 'Warm Enabled',
                            value: resource.InstanceState.ElasticsearchClusterConfig.WarmEnabled ? 'Yes' : 'No',
                        });
                        resourceValues.push({ label: 'Warm Count', value: resource.InstanceState.ElasticsearchClusterConfig.WarmCount });
                        resourceValues.push({
                            label: 'Dedicated Master Enabled',
                            value: resource.InstanceState.ElasticsearchClusterConfig.DedicatedMasterEnabled ? 'Yes' : 'No',
                        });
                        resourceValues.push({
                            label: 'Availability Zone Count',
                            value: resource.InstanceState.ElasticsearchClusterConfig.ZoneAwarenessConfig.AvailabilityZoneCount,
                        });
                        resourceValues.push({
                            label: 'Cold Storage Enabled',
                            value: resource.InstanceState.ElasticsearchClusterConfig.ColdStorageOptions.Enabled ? 'Yes' : 'No',
                        });
                        resourceValues.push({
                            label: 'Dedicated Master Count',
                            value: resource.InstanceState.ElasticsearchClusterConfig.DedicatedMasterCount,
                        });
                        resourceValues.push({
                            label: 'Instance Type',
                            value: resource.InstanceState.ElasticsearchClusterConfig.InstanceType.Value,
                        });

                        resourceValues.push({ label: 'Elasticsearch Version', value: resource.InstanceState.ElasticsearchVersion });
                        resourceValues.push({ label: 'Auto Tune Options', value: resource.InstanceState.AutoTuneOptions.State.Value });
                        resourceValues.push({
                            label: 'Automated Snapshot Start Hour',
                            value: resource.InstanceState.SnapshotOptions.AutomatedSnapshotStartHour,
                        });
                        resourceValues.push({
                            label: 'Enforce HTTPS',
                            value: resource.InstanceState.DomainEndpointOptions.EnforceHTTPS ? 'Yes' : 'No',
                        });
                        resourceValues.push({ label: 'Domain Id', value: resource.InstanceState.DomainId });
                        resourceValues.push({ label: 'EBS Enabled', value: resource.InstanceState.EBSOptions.EBSEnabled ? 'Yes' : 'No' });
                        resourceValues.push({ label: 'Volume Type', value: resource.InstanceState.EBSOptions.VolumeType.Value });
                        resourceValues.push({ label: 'Iops', value: resource.InstanceState.EBSOptions.Iops });
                        resourceValues.push({ label: 'Volume Size', value: resource.InstanceState.EBSOptions.VolumeSize + ' GB' });
                    }
                } else if (resourceType.startsWith('ELB')) {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    if ((resourceType as unknown) == 'ELBV1') {
                        resourceValues.push({ label: 'Scheme', value: resource.Scheme });
                        resourceValues.push({ label: 'Canonical Hosted Zone Name Id', value: resource.CanonicalHostedZoneNameID });
                        resourceValues.push({ label: 'VPC Id', value: resource.VPCId });
                    } else {
                        resourceValues.push({ label: 'Scheme', value: resource.Scheme.Value });
                        resourceValues.push({ label: 'Canonical Hosted Zone Id', value: resource.CanonicalHostedZoneId });
                        resourceValues.push({ label: 'VPC Id', value: resource.VpcId });
                    }
                } else if (resourceType == 'EMR') {
                    resourceValues.push({ label: 'Auto Terminate', value: resource.AutoTerminate ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Ebs Root Volume Size', value: resource.EbsRootVolumeSize });
                    resourceValues.push({ label: 'EMR Id', value: resource.EmrId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Normalized Instance Hours', value: resource.NormalizedInstanceHours });
                    resourceValues.push({ label: 'Ready Date', value: resource.Status.Timeline.ReadyDateTime });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status.State.Value });
                } else if (resourceType == 'Elemental MediaConvert Queue') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaConvert Job Template') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaConvert Job') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaConvert Preset') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaLive Channel') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaLive Input') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaLive Input Security Group') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaLive Multiplex') {
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaPackage Channel') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Elemental MediaPackage Channel V2') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Channel Group Name', value: resource.ChannelGroupName });
                } else if (resourceType == 'Elemental MediaPackage Channel Group') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Egress Domain', value: resource.EgressDomain });
                } else if (resourceType == 'Elemental MediaPackage Channel Origin Endpoint') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Channel Id', value: resource.ChannelId });
                    resourceValues.push({ label: 'ManifestName', value: resource.StreamSelection.ManifestName });
                } else if (resourceType == 'Elemental MediaPackage Channel Origin Endpoint V2') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Channel Name', value: resource.ChannelName });
                    resourceValues.push({ label: 'Channel Group Name', value: resource.ChannelGroupName });
                } else if (resourceType == 'Elemental MediaPackage Packaging Configuration') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Packaging Group Id', value: resource.PackagingGroupId });
                    resourceValues.push({ label: 'Segment Duration Seconds', value: resource.CmafPackage.SegmentDurationSeconds });
                } else if (resourceType == 'Elemental MediaPackage Packaging Group') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Domain Name', value: resource.DomainName });
                } else if (resourceType == 'FSx File System') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DNS Name', value: resource.DNSName });
                    resourceValues.push({ label: 'File System Type', value: resource.FileSystemType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Lifecycle', value: resource.Lifecycle.Value });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'OwnerId', value: resource.OwnerId });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Storage Capacity', value: resource.StorageCapacity });
                    resourceValues.push({ label: 'Storage Type Id', value: resource.StorageType.Value });
                    resourceValues.push({ label: 'VpcId', value: resource.VpcId });
                } else if (resourceType == 'FSx Cache') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'File System Id', value: resource.FileSystemId });
                } else if (resourceType == 'FSx Volume') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Volume Type', value: resource.VolumeType.Value });
                    resourceValues.push({ label: 'File System Id', value: resource.FileSystemId });
                } else if (resourceType == 'Glue Job') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Command', value: resource.Command.PythonVersion });
                    resourceValues.push({ label: 'Command Name', value: resource.Command.Name });
                    resourceValues.push({ label: 'Execution Class', value: resource.ExecutionClass.Value });
                    resourceValues.push({ label: 'Glue Version', value: resource.GlueVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified', value: resource.LastModifiedOn });
                    resourceValues.push({ label: 'Max Capacity', value: resource.MaxCapacity });
                    resourceValues.push({ label: 'Max Concurrent Runs', value: resource.ExecutionProperty.MaxConcurrentRuns });
                    resourceValues.push({ label: 'Max Retries', value: resource.MaxRetries });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Script Location', value: resource.Command.ScriptLocation });
                    resourceValues.push({ label: 'Timeout', value: resource.Timeout });
                } else if (resourceType == 'Glue Crawler') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Database Name', value: resource.DatabaseName });
                } else if (resourceType == 'Health Imaging') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'DatastoreName', value: resource.DatastoreName });
                    resourceValues.push({ label: 'Status', value: resource.DatastoreStatus.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Kendra Index') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({
                        label: 'Document Count',
                        value: resource.IndexStatistics.TextDocumentStatistics.IndexedTextDocumentsCount,
                    });
                    resourceValues.push({ label: 'Faq Count', value: resource.IndexStatistics.FaqStatistics.IndexedQuestionAnswersCount });
                    resourceValues.push({ label: 'Query Capacity Units', value: resource.CapacityUnits.QueryCapacityUnits });
                    resourceValues.push({ label: 'Storage Capacity Units', value: resource.CapacityUnits.StorageCapacityUnits });
                    resourceValues.push({ label: 'User Context Policy', value: resource.UserContextPolicy.Value });
                } else if (resourceType == 'Kendra Data Source') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                } else if (resourceType == 'Kinesis') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Retention Period', value: resource.RetentionPeriodHours + ' hours' });
                    resourceValues.push({ label: 'Stream Status', value: resource.StreamStatus.Value });
                    resourceValues.push({ label: 'Stream Mode', value: resource.StreamModeDetails.StreamMode.Value });
                } else if (resourceType == 'Kinesis Data Analytics') {
                    // resourceValues.push({ label: 'Application Code', value: resource.ApplicationCode });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    // resourceValues.push({ label: 'Description', value: resource.ApplicationDescription });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Status', value: resource.ApplicationStatus?.Value ?? '' });
                } else if (resourceType == 'Kinesis Data Firehose') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Kinesis Video Stream') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Device Name', value: resource.DeviceName });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Media Type', value: resource.MediaType });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Version', value: resource.Version });
                } else if (resourceType == 'KMS') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Key Spec', value: resource.KeySpec.Value });
                    resourceValues.push({ label: 'Key Usage', value: resource.KeyUsage.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Keyspaces') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Keyspaces Table') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                } else if (resourceType == 'Lake Formation') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Lambda') {
                    resourceValues.push({ label: 'Code Size', value: resource.CodeSize });
                    resourceValues.push({ label: 'Ephemeral Storage', value: resource.EphemeralStorage.Size + ' GB' });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified Date', value: resource.LastModified });
                    resourceValues.push({ label: 'Memory Size', value: resource.MemorySize });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Package Type', value: resource.PackageType.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Runtime Value', value: resource.Runtime.Value });
                    if (resource.VpcConfig) {
                        resourceValues.push({ label: 'SubnetIds', value: resource.VpcConfig.SubnetIds.join(', ') });
                    }
                    resourceValues.push({ label: 'Timeout', value: resource.Timeout });
                } else if (resourceType == 'Mainframe Modernization') {
                    resourceValues.push({ label: 'Application Version', value: resource.ApplicationVersion });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Deployment Status', value: resource.DeploymentStatus });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Start Time', value: formatSvc.toLocal(resource.LastStartTime) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Role Arn', value: resource.RoleArn });
                } else if (resourceType == 'Mainframe Modernization Environment') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                } else if (resourceType == 'MSK') {
                    resourceValues.push({ label: 'Cluster Type', value: resource.ClusterType.Value });
                    resourceValues.push({ label: 'Current Version', value: resource.CurrentVersion });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'MSK VPC Connection') {
                    resourceValues.push({ label: 'Target Cluster Arn', value: resource.TargetClusterArn });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'VpcId', value: resource.VpcId });
                    resourceValues.push({ label: 'Authentication', value: resource.Authentication });
                } else if (resourceType == 'MQ Broker') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Deployment Mode', value: resource.DeploymentMode.Value });
                    resourceValues.push({ label: 'Engine Type', value: resource.EngineType.Value });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Host Instance Type', value: resource.HostInstanceType });
                    resourceValues.push({ label: 'State', value: resource.BrokerState.Value });
                } else if (resourceType == 'MQ Configuration') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Engine Type', value: resource.EngineType.Value });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                } else if (resourceType == 'Nat Gateway') {
                    resourceValues.push({ label: 'Allocation Id', value: resource.NatGatewayAddresses[0]?.AllocationId });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Network Interface Id', value: resource.NatGatewayAddresses[0]?.NetworkInterfaceId });
                    resourceValues.push({ label: 'Private Ip', value: resource.NatGatewayAddresses[0]?.PrivateIp });
                    resourceValues.push({ label: 'Public Ip', value: resource.NatGatewayAddresses[0]?.PublicIp });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Subnet Id', value: resource.SubnetId });
                    resourceValues.push({ label: 'Vpc Id', value: resource.VpcId });
                } else if (resourceType == 'Neptune Cluster') {
                    resourceValues.push({ label: 'Allocated Storage', value: resource.AllocatedStorage });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Endpoint', value: resource.Endpoint });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                } else if (resourceType == 'Neptune Instance') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Instance Class', value: resource.DBInstanceClass });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'StorageType', value: resource.StorageType });
                } else if (resourceType.startsWith('Network Firewall')) {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'Network Interface') {
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Interface Type', value: resource.InterfaceType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Private Dns Name', value: resource.PrivateDnsName });
                    resourceValues.push({ label: 'Private Ip Address', value: resource.PrivateIpAddress });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Vpc Id', value: resource.VpcId });
                } else if (resourceType == 'OpenSearch') {
                    resourceValues.push({ label: 'ARN', value: resource.ARN });
                    resourceValues.push({ label: 'Domain ID', value: resource.DomainId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    if (resource.ClusterConfig) {
                        resourceValues.push({ label: 'Dedicated Master Count', value: resource.ClusterConfig.DedicatedMasterCount });
                        resourceValues.push({
                            label: 'Dedicated Master Enabled',
                            value: resource.ClusterConfig.DedicatedMasterEnabled ? 'Yes' : 'No',
                        });
                        resourceValues.push({ label: 'Instance Count', value: resource.ClusterConfig.InstanceCount });
                        resourceValues.push({ label: 'Instance Type', value: resource.ClusterConfig.InstanceType.Value });
                        resourceValues.push({ label: 'Warm Count', value: resource.ClusterConfig.WarmCount });
                        resourceValues.push({ label: 'Warm Enabled', value: resource.ClusterConfig.WarmEnabled ? 'Yes' : 'No' });
                        resourceValues.push({
                            label: 'Availability Zone Count',
                            value: resource.ClusterConfig.ZoneAwarenessConfig.AvailabilityZoneCount,
                        });
                        resourceValues.push({ label: 'Zone Awareness Enabled', value: resource.ClusterConfig.ZoneAwarenessEnabled });
                    }
                    if (resource.EBSOptions) {
                        resourceValues.push({ label: 'EBS Enabled', value: resource.EBSOptions.EBSEnabled ? 'Yes' : 'No' });
                        resourceValues.push({ label: 'Iops', value: resource.EBSOptions.Iops });
                        resourceValues.push({ label: 'Volume Size', value: resource.EBSOptions.VolumeSize });
                        resourceValues.push({ label: 'Volume Type', value: resource.EBSOptions.VolumeType.Value });
                    }
                } else if (resourceType == 'Quicksight Analysis') {
                    resourceValues.push({ label: 'Analysis Id', value: resource.AnalysisId });
                    resourceValues.push({ label: 'Arn', value: resource.Arn });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight Dashboard') {
                    resourceValues.push({ label: 'Dashboard Id', value: resource.DashboardId });
                    resourceValues.push({ label: 'Version Number', value: resource.Version.VersionNumber });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight Data Source') {
                    resourceValues.push({ label: 'Data Source Id', value: resource.DataSourceId });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight Dataset') {
                    resourceValues.push({ label: 'Dataset Id', value: resource.DataSetId });
                    resourceValues.push({ label: 'Import Mode', value: resource.ImportMode.Value });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight Template') {
                    resourceValues.push({ label: 'Template Id', value: resource.TemplateId });
                    resourceValues.push({ label: 'Version Number', value: resource.Version.VersionNumber });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight Theme') {
                    resourceValues.push({ label: 'Theme Id', value: resource.ThemeId });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                    resourceValues.push({ label: 'Version Number', value: resource.Version.VersionNumber });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight User') {
                    resourceValues.push({ label: 'Email', value: resource.Email });
                    resourceValues.push({ label: 'Identity Type', value: resource.IdentityType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Quicksight VPC Connection') {
                    resourceValues.push({ label: 'VPCConnection Id', value: resource.VPCConnectionId });
                    resourceValues.push({ label: 'Availability Status', value: resource.AvailabilityStatus.Value });
                    resourceValues.push({ label: 'VPC Id', value: resource.VPCId });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'RDS') {
                    resourceValues.push({ label: 'Allocated Storage', value: resource.AllocatedStorage + 'GB' });
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Deletion Protection', value: resource.DeletionProtection ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'IOPS', value: resource.Iops });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'License Model', value: resource.LicenseModel });
                    resourceValues.push({ label: 'Multi-AZ', value: resource.MultiAZ ? 'Yes' : 'No' });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Running', value: resource.DBInstanceStatus });
                    resourceValues.push({ label: 'Size', value: resource.DBInstanceClass });
                    resourceValues.push({ label: 'Storage Encrypted', value: resource.StorageEncrypted ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Storage Type', value: resource.StorageType });
                } else if (resourceType == 'RDS Cluster') {
                    resourceValues.push({ label: 'Activity Stream Status', value: resource.ActivityStreamStatus.Value });
                    resourceValues.push({ label: 'Availability Zones', value: resource.AvailabilityZones.join(', ') });
                    resourceValues.push({ label: 'Is Global Cluster', value: resource.IsGlobalCluster ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Backup Retention Period', value: resource.BackupRetentionPeriod });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Cross Account Clone', value: resource.CrossAccountClone ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Database Name', value: resource.DatabaseName });
                    resourceValues.push({ label: 'DB Subnet Group', value: resource.DBSubnetGroup });
                    resourceValues.push({ label: 'Deletion Protection', value: resource.DeletionProtection ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Earliest Restorable Time', value: resource.EarliestRestorableTime });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Mode', value: resource.EngineMode });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({
                        label: 'Global Write Forwarding Requested',
                        value: resource.GlobalWriteForwardingRequested ? 'Yes' : 'No',
                    });
                    resourceValues.push({ label: 'Hosted Zone Id', value: resource.HostedZoneId });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Latest Restorable Time', value: resource.LatestRestorableTime });
                    resourceValues.push({ label: 'Multi AZ', value: resource.MultiAZ ? 'Yes' : 'No' });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Performance Insights Enabled', value: resource.PerformanceInsightsEnabled ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Port', value: resource.Port });
                    resourceValues.push({ label: 'Preferred Backup Window', value: resource.PreferredBackupWindow });
                    resourceValues.push({ label: 'Preferred Maintenance Window', value: resource.PreferredMaintenanceWindow });
                    resourceValues.push({ label: 'Publicly Accessible', value: resource.PubliclyAccessible ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Storage Encrypted', value: resource.StorageEncrypted ? 'Yes' : 'No' });
                } else if (resourceType == 'RDS Cluster Snapshot') {
                    resourceValues.push({ label: 'Availability Zones', value: resource.AvailabilityZones.join(', ') });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Cluster Snapshot Identifier', value: resource.DBClusterSnapshotIdentifier });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Mode', value: resource.EngineMode });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({
                        label: 'IAM Database Authentication Enabled',
                        value: resource.IAMDatabaseAuthenticationEnabled ? 'Yes' : 'No',
                    });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'License Model', value: resource.LicenseModel });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Percent Progress', value: resource.PercentProgress });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Snapshot Create Time', value: resource.SnapshotCreateTime });
                    resourceValues.push({ label: 'Storage Encrypted', value: resource.StorageEncrypted ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Vpc Id', value: resource.VpcId });
                } else if (resourceType == 'RDS Reserved') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Instance Class', value: resource.DBInstanceClass });
                    resourceValues.push({ label: 'DB Instance Count', value: resource.DBInstanceCount });
                    var duration = resource.Duration / 60 / 60 / 24 / 365;
                    resourceValues.push({ label: 'Duration', value: duration + (duration == 1 ? 'Year' : ' Years') });
                    resourceValues.push({ label: 'FixedPrice', value: formatter.format(resource.FixedPrice) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'MultiAZ', value: resource.MultiValueAnswer ? 'Yes' : 'No' });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Offering Type', value: resource.OfferingType });
                    resourceValues.push({ label: 'Product Description', value: resource.ProductDescription });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Reserved DB Instance Arn', value: resource.ReservedDBInstanceArn });
                    resourceValues.push({ label: 'Reserved DB Instance Id', value: resource.ReservedDBInstanceId });
                    resourceValues.push({ label: 'Start Time', value: resource.StartTime });
                    resourceValues.push({ label: 'State', value: resource.State });
                    resourceValues.push({ label: 'Usage Price', value: formatter.format(resource.UsagePrice) });
                } else if (resourceType == 'RDS Snapshot') {
                    resourceValues.push({ label: 'Allocated Storage', value: resource.AllocatedStorage });
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Instance Identifier', value: resource.DBInstanceIdentifier });
                    resourceValues.push({ label: 'DB Snapshot Identifier', value: resource.DBSnapshotIdentifier });
                    resourceValues.push({ label: 'Encrypted', value: resource.Encrypted == 'true' ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Engine', value: resource.Engine });
                    resourceValues.push({ label: 'Engine Version', value: resource.EngineVersion });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'License Model', value: resource.LicenseModel });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Original Snapshot Create Time', value: resource.OriginalSnapshotCreateTime });
                    resourceValues.push({ label: 'Percent Progress', value: resource.PercentProgress });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Snapshot Create Time', value: resource.SnapshotCreateTime });
                    resourceValues.push({ label: 'Snapshot Target', value: resource.SnapshotTarget });
                    resourceValues.push({ label: 'Snapshot Type', value: resource.SnapshotType });
                    resourceValues.push({ label: 'Status', value: resource.Status });
                    resourceValues.push({ label: 'Storage Type', value: resource.StorageType });
                    resourceValues.push({ label: 'Vpc Id', value: resource.VpcId });
                } else if (resourceType == 'Redshift') {
                    resourceValues.push({ label: 'Allow Version Upgrade', value: resource.AllowVersionUpgrade ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Automated Snapshot Retention Period', value: resource.AutomatedSnapshotRetentionPeriod });
                    resourceValues.push({ label: 'Availability Zone', value: resource.AvailabilityZone });
                    resourceValues.push({ label: 'Availability Zone Relocation Status', value: resource.AvailabilityZoneRelocationStatus });
                    resourceValues.push({ label: 'Cluster Availability Status', value: resource.ClusterAvailabilityStatus });
                    resourceValues.push({ label: 'Cluster Identifier', value: resource.ClusterIdentifier });
                    resourceValues.push({ label: 'Cluster Revision Number', value: resource.ClusterRevisionNumber });
                    resourceValues.push({ label: 'Cluster Status', value: resource.ClusterStatus });
                    resourceValues.push({ label: 'Cluster Version', value: resource.ClusterVersion });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'DB Name', value: resource.DBName });
                    resourceValues.push({ label: 'Encrypted', value: resource.Encrypted ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Enhanced VPC Routing', value: resource.EnhancedVpcRouting ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'Expected Next Snapshot Schedule Time', value: resource.ExpectedNextSnapshotScheduleTime });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Maintenance Track Name', value: resource.MaintenanceTrackName });
                    resourceValues.push({ label: 'Manual Snapshot Retention Period', value: resource.ManualSnapshotRetentionPeriod });
                    resourceValues.push({ label: 'Master Username', value: resource.MasterUsername });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Next Maintenance Window Start Time', value: resource.NextMaintenanceWindowStartTime });
                    resourceValues.push({ label: 'Node Type', value: resource.NodeType });
                    resourceValues.push({ label: 'Number of Nodes', value: resource.NumberOfNodes });
                    resourceValues.push({ label: 'Preferred Maintenance Window', value: resource.PreferredMaintenanceWindow });
                    resourceValues.push({ label: 'Publicly Accessible', value: resource.PubliclyAccessible ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'VPC Id', value: resource.VpcId });
                } else if (resourceType == 'Redshift Serverless') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'Route 53') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'MultiValueAnswer', value: resource.ResourceRecordSets.MultiValueAnswer ? 'Yes' : 'No' });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'TTL', value: resource.ResourceRecordSets.TTL });
                    resourceValues.push({ label: 'Type', value: resource.ResourceRecordSets[0].Type.Value });
                } else if (resourceType == 'Route 53 Health Check') {
                    resourceValues.push({ label: 'Health Check Version', value: resource.HealthCheckVersion });
                    resourceValues.push({ label: 'Zone Id', value: resource.ZoneId });
                    resourceValues.push({ label: 'Caller Reference', value: resource.CallerReference });
                    resourceValues.push({ label: 'Fully Qualified Domain Name', value: resource.HealthCheckConfig.FullyQualifiedDomainName });
                    resourceValues.push({ label: 'Request Interval', value: resource.HealthCheckConfig.RequestInterval });
                    resourceValues.push({ label: 'Failure Threshold', value: resource.HealthCheckConfig.FailureThreshold });
                    resourceValues.push({ label: 'Health Threshold', value: resource.HealthCheckConfig.HealthThreshold });
                } else if (resourceType == 'S3') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'S3 Glacier') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Number of archives', value: resource.NumberOfArchives });
                } else if (resourceType == 'Organization Account') {
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Joined Method', value: resource.JoinedMethod.Value });
                    resourceValues.push({ label: 'Joined Timestamp', value: formatSvc.toLocal(resource.JoinedTimestamp) });
                } else if (resourceType == 'Organizational Unit') {
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'Organization Policy') {
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Policy Type', value: resource.Type.Value });
                    resourceValues.push({ label: 'Aws Managed', value: resource.AwsManaged ? 'Yes' : 'No' });
                } else if (resourceType == 'Organization Root') {
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                } else if (resourceType == 'Sagemaker') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Modified Date', value: resource.LastModifiedTime });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                } else if (resourceType == 'Sagemaker App') {
                    resourceValues.push({ label: 'App Type', value: resource.AppType.Value });
                    resourceValues.push({ label: 'Domain Id', value: resource.DomainId });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'User Profile Name', value: resource.UserProfileName });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Sagemaker Notebook Instance') {
                    resourceValues.push({ label: 'Check sum Algorithm', value: resource.ResponseMetadata.ChecksumAlgorithm });
                    resourceValues.push({ label: 'Check sum Validation Status', value: resource.ResponseMetadata.ChecksumValidationStatus });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Direct Internet Access', value: resource.DirectInternetAccess.Value });
                    resourceValues.push({ label: 'Http Status Code', value: resource.HttpStatusCode });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Modified', value: resource.LastModifiedTime });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Notebook Instance Status', value: resource.NotebookInstanceStatus.Value });
                    resourceValues.push({ label: 'Platform Identifier', value: resource.PlatformIdentifier });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Root Access', value: resource.RootAccess.Value });
                    resourceValues.push({
                        label: 'Svc Configuration',
                        value: resource.InstanceMetadataServiceConfiguration.MinimumInstanceMetadataServiceVersion,
                    });
                    resourceValues.push({ label: 'Volume Size In GB', value: resource.VolumeSizeInGB });
                } else if (resourceType == 'Sagemaker Project') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Savings Plan') {
                    resourceValues.push({ label: 'ARN', value: resource.SavingsPlanArn });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'End Date', value: resource.End });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Products', value: resource.ProductTypes.join(', ') });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'Start Date', value: resource.Start });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Term Duration In Seconds', value: resource.TermDurationInSeconds });
                    resourceValues.push({ label: 'Type', value: resource.SavingsPlanType.Value });
                    resourceValues.push({ label: 'Upfront Payment Amount', value: formatter.format(resource.UpfrontPaymentAmount) });
                } else if (resourceType == 'Secret Manager') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Last Access Date', value: resource.LastAccessedDate });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Primary Region', value: resource.PrimaryRegion });
                    resourceValues.push({ label: 'Rotation Enabled', value: resource.ResourceRecordSets.RotationEnabled ? 'Yes' : 'No' });
                } else if (resourceType == 'SNS') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'SQS') {
                    resourceValues.push({ label: 'Created', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                } else if (resourceType == 'Step Functions Activity') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Content Length', value: resource.ContentLength });
                } else if (resourceType == 'Step Functions State Machine') {
                    resourceValues.push({ label: 'Content Length', value: resource.ContentLength });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreateDate) });
                    resourceValues.push({ label: 'Status', value: resource.Status.Value });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                } else if (resourceType == 'Storage Gateway') {
                    resourceValues.push({ label: 'EC2 Instance Id', value: resource.Ec2InstanceId });
                    resourceValues.push({ label: 'Gateway ARN', value: resource.GatewayARN });
                    resourceValues.push({ label: 'Gateway Id', value: resource.GatewayId });
                    resourceValues.push({ label: 'Gateway Operational State', value: resource.GatewayOperationalState });
                    resourceValues.push({ label: 'Gateway Type', value: resource.GatewayType });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Storage Gateway Volume') {
                    resourceValues.push({ label: 'Volume Id', value: resource.VolumeId });
                    resourceValues.push({ label: 'Volume Type', value: resource.VolumeType });
                    resourceValues.push({ label: 'Gateway ARN', value: resource.GatewayARN });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Storage Gateway File Share') {
                    resourceValues.push({ label: 'File Share Id', value: resource.FileShareId });
                    resourceValues.push({ label: 'Gateway ARN', value: resource.GatewayARN });
                    resourceValues.push({ label: 'File Share Type', value: resource.FileShareType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Systems Manager') {
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreatedDate) });
                    resourceValues.push({ label: 'Document Format', value: resource.DocumentFormat.Value });
                    resourceValues.push({ label: 'Document Type', value: resource.DocumentType.Value });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({ label: 'TargetType', value: resource.TargetType });
                } else if (resourceType == 'Systems Manager Parameter') {
                    resourceValues.push({ label: 'Data Type', value: resource.DataType });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resourceName });
                    resourceValues.push({ label: 'Version', value: resource.Version });
                    resourceValues.push({ label: 'Tier', value: resource.Tier.Value });
                    resourceValues.push({ label: 'Type', value: resource.Type.Value });
                } else if (resourceType == 'Systems Manager Patch Baseline') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Operating System', value: resource.OperatingSystem.Value });
                    resourceValues.push({ label: 'Region', value: resource.Region });
                } else if (resourceType == 'Transfer Family Workflow') {
                    resourceValues.push({ label: 'Description', value: resource.Description });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Transfer Family Connector') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                } else if (resourceType == 'Transit Gateways') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreatedDate) });
                } else if (resourceType == 'Transit Gateways Attachment') {
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    resourceValues.push({ label: 'Name', value: resource.Name });
                    resourceValues.push({ label: 'Create Date', value: formatSvc.toLocal(resource.CreatedDate) });
                } else if (resourceType == 'Workspace') {
                    resourceValues.push({ label: 'Bundle Id', value: resource.BundleId });
                    resourceValues.push({ label: 'Compute Type Name', value: resource.WorkspaceProperties.ComputeTypeName.Value });
                    resourceValues.push({ label: 'Directory Id', value: resource.DirectoryId });
                    resourceValues.push({ label: 'IP Address', value: resource.IpAddress });
                    resourceValues.push({ label: 'Last 30 Days Cost', value: formatSvc.formatMoneyAllDecimals(resource.Last30DaysCost ?? 0) });
                    if (resourceName != '') {
                        resourceValues.push({ label: 'Name', value: resourceName });
                    }
                    resourceValues.push({ label: 'Region', value: resource.Region });
                    resourceValues.push({
                        label: 'Root Volume Encryption Enabled',
                        value: resource.RootVolumeEncryptionEnabled ? 'Yes' : 'No',
                    });
                    resourceValues.push({ label: 'Root Volume Size', value: resource.RootVolumeSizeGib + ' GB' });
                    resourceValues.push({ label: 'Running Mode', value: resource.WorkspaceProperties.RunningMode.Value });
                    resourceValues.push({
                        label: 'Running Mode Auto Stop Timeout In Minutes',
                        value: resource.WorkspaceProperties.RunningModeAutoStopTimeoutInMinutes,
                    });
                    resourceValues.push({ label: 'State', value: resource.State.Value });
                    resourceValues.push({ label: 'Subnet Id', value: resource.SubnetId });
                    resourceValues.push({ label: 'User Name', value: resource.UserName });
                    resourceValues.push({ label: 'User Volume Encryption Enabled', value: resource.UserVolumeEncryptionEnabled ? 'Yes' : 'No' });
                    resourceValues.push({ label: 'User Volume Size', value: resource.Region });
                    resourceValues.push({ label: 'Workspace Id', value: resource.WorkspaceId });
                }
            } catch (e) {
                //(`error reading resources for type '${resourceType}', id: ${props.resourceType}`);
            }
            resourceValues.push({ label: 'Account', value: resource?.Account });
            resourceValues.push({ label: 'Account Name', value: resource?.AccountName });
            let valuesArray = [];
            for (var i = 0; i < resourceValues.length; i++) {
                valuesArray.push(
                    <tr key={'dr' + i}>
                        <td key={'l' + i} className="label">
                            {resourceValues[i]?.label !== undefined ? resourceValues[i].label : ''}
                        </td>
                        <td key={'v' + i} className="value">
                            {resourceValues[i]?.value !== undefined ? resourceValues[i].value : ''}
                        </td>
                    </tr>
                );
            }
            retValue = (
                <div className="resourcesTableContainer">
                    <Table className="resourcesTable">
                        <tbody>{valuesArray}</tbody>
                    </Table>
                </div>
            );
        }
    }
    return retValue;
}
