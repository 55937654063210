import {
    getInvoiceDownloadInvoice,
    getPaymentGetCreditBuyingList,
    getPaymentGetPrimaryPayment,
    postInvoiceCreateEstimate,
    postPaymentBuyCredits,
    useGetCreditsGetCreditSummary,
    useGetPaymentGetPaymentSources,
} from '@apis/Customers';
import { BuyCreditsResult, InvoiceDetail, ItemPrice, PaymentSource } from '@apis/Customers/model';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    Radio,
    Space,
    Text,
    createStyles,
    Alert,
    Center,
    Loader,
} from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { JobService } from '@root/Services/Jobs/JobService';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { useEffect, useState } from 'react';
import { CardIcon } from '../PaymentMethods';
import { ModalBody, ModalHeader, SuccessIcon } from './Credits';

export function AddModal({
    creditsId,
    numberOfCredits,
    cPrice,
    setSelected,
}: {
    creditsId: string;
    numberOfCredits: number;
    cPrice: number;
    setSelected: (noofcredits: number, creditBuyOptions: string, price: number) => void;
}) {
    const { classes } = useStyles();
    const { data: creditSummary, refetch } = useGetCreditsGetCreditSummary();
    const [creditBuyingOptions, setCreditBuyingOptions] = useState<ItemPrice[]>([]);
    const [creditBuySelected, setCreditBuySelected] = useState(creditsId);
    const [noofCredits, setNoofCredits] = useState(numberOfCredits);
    const [price, setPrice] = useState(cPrice);
    const [disableButton, setDisableButton] = useState(true);
    const { getMoveUrl } = useNav();
    useEffect(() => {
        if (creditBuySelected !== '') {
            setDisableButton(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            setCreditBuyingOptions(await getPaymentGetCreditBuyingList());
        })();
    }, []);

    const radioButtons = creditBuyingOptions
        .filter((s) => (s.Credits as number) > 0)
        .sort((a, b) => (a.Credits as number) - (b.Credits as number))
        .map((item, i) => {
            return <GetRadios key={i} {...item}></GetRadios>;
        });

    function GetRadios(item: ItemPrice) {
        const labelText = (
            <Text size={14} color={colorPalette.subTextColor} weight={600}>
                {item?.Name}{' '}
                {(item.Discount as number) > 0 ? (
                    <Badge color="success">
                        <Text size={12}>Save {item.Discount}%</Text>
                    </Badge>
                ) : (
                    ''
                )}
            </Text>
        );
        return (
            <Box className={classes.radioBox}>
                <Group position="apart">
                    <Radio
                        label={labelText}
                        value={item.Id as string}
                        onClick={() => (
                            setCreditBuySelected(item.Id as string),
                            setNoofCredits(item.Credits as number),
                            setPrice(item.Price as number),
                            setDisableButton(false)
                        )}
                    ></Radio>
                    <Box>
                        <Text size={14} weight={600} color={colorPalette.titleTextColor}>
                            {USDformatter.format((item.Price as number)!)}
                        </Text>
                    </Box>
                </Group>
                <Text size={14} weight={400} color={colorPalette.subTextColor} align="right">
                    ({USDformatter.format((item.Price as number) / (item.Credits as number)!)}/credit)
                </Text>
            </Box>
        );
    }

    var formatter = new Intl.NumberFormat('en-US', {});
    var USDformatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
            <Grid>
                <Grid.Col span={6} offset={3}>
                    <Box>
                        <Box className={classes.modalBox}>
                            <Box>
                                <Text size={14} weight={400} color={colorPalette.subTextColor}>
                                    Current credit balance
                                </Text>
                                <Text data-atid="CurrentCreditsText" size={30} weight={600} color={colorPalette.darkTitleColor}>
                                    {formatter.format(creditSummary?.TotalCredits!)}
                                </Text>
                            </Box>
                            <Space h={15} />
                            <Divider></Divider>
                            <Space h={20} />
                            <Box>
                                <Text size={14} weight={600} color={colorPalette.titleTextColor}>
                                    Add credit package:
                                </Text>
                                {creditBuyingOptions ? (
                                    <Radio.Group orientation="vertical" defaultValue={creditsId}>
                                        {radioButtons}
                                    </Radio.Group>
                                ) : (
                                    ''
                                )}
                            </Box>
                            <Space h={15} />
                            <Button
                                onClick={() => {
                                    setSelected(noofCredits, creditBuySelected, price);
                                }}
                                disabled={disableButton}
                                sx={{ padding: '10px, 18px, 10px, 18px', height: '44px', borderRadius: theme.radius?.md }}
                                fullWidth
                            >
                                <Text size={16} weight={600}>
                                    Continue to Payment
                                </Text>
                            </Button>
                        </Box>
                    </Box>
                </Grid.Col>
            </Grid>
        </>
    );
}

export function PaymentModal(props: {
    credits: number;
    creditsId: string;
    price: number;
    setSelected: (result: BuyCreditsResult) => void;
    goBack: (creditsId: string, numberOfCredits: number, price: number) => void;
    close: () => void;
}) {
    const { data: savedPayments, refetch } = useGetPaymentGetPaymentSources();
    const [primaryPayment, setPrimaryPayment] = useState<PaymentSource>();
    const [paymentSelected, setPaymentSelected] = useState('');
    const [creditsBought, setCreditsBought] = useState(0);
    const [tax, setTax] = useState(0);
    const { classes } = useStyles();
    const company = useCompany();
    const jobService = useDi(JobService);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [estimate, setEstimate] = useState<InvoiceDetail>();
    useEffect(() => {
        (async () => {
            const primary = await getPaymentGetPrimaryPayment();
            setPrimaryPayment(primary);
            if (primary != null) {
                setPaymentSelected(primary.Id as string);
            }
            setEstimate(await postInvoiceCreateEstimate({ CreditPackage: props.creditsId, CompanyId: company?.Id }));
        })();
    }, []);

    const nonPrimarySources = savedPayments?.filter((p) => p.Id != primaryPayment?.Id);
    const nonPrimaryCards = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'Card');
    const nonPrimaryBankAccounts = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'DirectDebit');

    const primaryRadio = primaryPayment ? (
        <>
            {primaryPayment.PaymentSourceType == 'Card' ? (
                <CardHolder item={primaryPayment as PaymentSource} key={0} isPrimary={true} setSelected={setPaymentSelected}></CardHolder>
            ) : (
                <AccountHolder item={primaryPayment as PaymentSource} key={0} isPrimary={true} setSelected={setPaymentSelected}></AccountHolder>
            )}
        </>
    ) : (
        ''
    );

    const nonPrimaryCardRadio = nonPrimaryCards?.map((item, i) => (
        <>
            <CardHolder item={item} key={'card' + i + 1} isPrimary={false} setSelected={setPaymentSelected}></CardHolder>
        </>
    ));
    const nonPrimaryAccountRadio = nonPrimaryBankAccounts?.map((item, i) => (
        <>
            <AccountHolder item={item} key={'account' + i + 1} isPrimary={false} setSelected={setPaymentSelected}></AccountHolder>
        </>
    ));

    const purchaseCredits = async (creditsId: string, paymentId: string) => {
        setOverlayVisible(true);
        try {
            const result = await postPaymentBuyCredits({ creditPackageId: creditsId, paymentSourceId: paymentSelected });

            if (result.InvoiceId) {
                const { poller } = await jobService.waitForJobHierarchyByJobId(result.JobId!);
                await poller;
                props.setSelected(result);
            }
        } catch {
            setShowErrorMsg(true);
        } finally {
            setOverlayVisible(false);
        }
    };

    const errorMsg = (
        <>
            <Space h={20}></Space>
            <Alert
                icon={<i className="ti ti-alert-circle"></i>}
                title={
                    <Text color={colorPalette.errorTitleText} weight={600} size={14}>
                        Payment Process Failed
                    </Text>
                }
                color="error"
                sx={{ backgroundColor: colorPalette.errorBgColor }}
                withCloseButton
                onClose={() => setShowErrorMsg(false)}
            >
                <Text color={colorPalette.errorTitleText} weight={400} size={14}>
                    An error has occurred and your payment cannot be proccessed at this time. Please try again later, or contact support.
                </Text>
                <Anchor href="">
                    <Text color={colorPalette.errorSubText} weight={600} size={14}>
                        Contact Support
                    </Text>
                </Anchor>
            </Alert>
        </>
    );

    var formatter = new Intl.NumberFormat('en-US', {});
    var USDformatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
            <Space h={22}></Space> <LoadingOverlay visible={overlayVisible} overlayBlur={2} />
            <Grid>
                <Grid.Col span={12} offset={0}>
                    <ModalBody>
                        <Space h={30}></Space>
                        <Grid>
                            <Grid.Col span={6}>
                                <Box className={classes.paymentBox}>
                                    <Box className={classes.paymentHeaderBox}>
                                        <Group position="apart">
                                            <Text>{formatter.format(props.credits!)} credits</Text>
                                            <Button variant="white" onClick={() => props.goBack(props.creditsId, props.credits, props.price)}>
                                                Change
                                            </Button>
                                        </Group>
                                    </Box>
                                    <Space h={20}></Space>
                                    <Text>Payment method</Text>
                                    <Radio.Group value={paymentSelected}>
                                        {primaryRadio} {nonPrimaryCardRadio} {nonPrimaryAccountRadio}
                                    </Radio.Group>
                                </Box>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Box className={classes.paymentBox}>
                                    <Text weight={700} size={16} color={colorPalette.subTextColor}>
                                        Order summary
                                    </Text>
                                    <Space h={20}></Space>
                                    <Group position="apart">
                                        <Text weight={600} size={14} color={colorPalette.titleTextColor}>
                                            Subtotal
                                        </Text>
                                        <Text weight={400} size={14} color={colorPalette.lightTextColor}>
                                            {USDformatter.format(estimate?.SubTotal ?? 0)}
                                        </Text>
                                    </Group>
                                    <Group position="apart">
                                        <Text weight={600} size={14} color={colorPalette.titleTextColor}>
                                            Tax
                                        </Text>
                                        <Text weight={400} size={14} color={colorPalette.lightTextColor}>
                                            {USDformatter.format(estimate?.Tax ?? 0)}
                                        </Text>
                                    </Group>
                                    <Space h={10}></Space>
                                    <Divider></Divider>
                                    <Space h={10}></Space>
                                    <Group position="apart">
                                        <Text weight={700} size={16} color={colorPalette.lightTextColor}>
                                            Total
                                        </Text>
                                        <Text weight={700} size={16} color={colorPalette.lightTextColor}>
                                            {USDformatter.format(estimate?.Total ?? 0)}
                                        </Text>
                                    </Group>
                                    {showErrorMsg ? errorMsg : ''}
                                    <Space h={20}></Space>
                                    <Button onClick={() => purchaseCredits(props.creditsId, paymentSelected)} fullWidth>
                                        <Text size={16} weight={600}>
                                            Purchase
                                        </Text>
                                    </Button>
                                    <Space h={20}></Space>
                                    <Center>
                                        <Button variant="outline" component="a" onClick={props.close} fullWidth>
                                            <Text size={14} weight={600}>
                                                Cancel
                                            </Text>
                                        </Button>
                                    </Center>
                                </Box>
                            </Grid.Col>
                        </Grid>
                    </ModalBody>
                </Grid.Col>
            </Grid>
        </>
    );
}

export function ThankyouModal(props: { buyResult: BuyCreditsResult; close: () => void }) {
    const { classes } = useStyles();
    const [downloadUrl, setDownloadUrl] = useState('');
    const { isLoading, data: creditSummary, refetch } = useGetCreditsGetCreditSummary();

    const invoiceIdNumber = props.buyResult.InvoiceId ?? '';

    useEffect(() => {
        (async () => {
            setDownloadUrl(await getInvoiceDownloadInvoice({ invoiceId: invoiceIdNumber }));
        })();
    }, []);

    var formatter = new Intl.NumberFormat('en-US', {});

    return (
        <Grid>
            <Grid.Col span={5} offset={3.5}>
                <ModalBody>
                    <Space h={20}></Space>
                    <Box className={classes.successBox}>
                        <Group className={classes.successIconBox} position="center">
                            <SuccessIcon>
                                <i className="ti ti-circle-check"></i>
                            </SuccessIcon>
                        </Group>
                        <Space h={20}></Space>
                        <Text size={16} weight={500} color={colorPalette.titleTextColor}>
                            Your credit balance has been updated.
                        </Text>
                        <Space h={20}></Space>
                        <Text size={14} weight={400} color={colorPalette.subTextColor}>
                            New credit balance
                        </Text>
                        <Text size={30} weight={600} color={colorPalette.darkTitleColor}>
                            {isLoading ? <Loader /> : formatter.format(creditSummary?.TotalCredits!)}
                        </Text>
                        <Space h={20}></Space>
                        <Button component="a" onClick={props.close} fullWidth>
                            <Text size={16} weight={600} color={colorPalette.white}>
                                Done
                            </Text>
                        </Button>
                        <Space h={20}></Space>
                        <Button component="a" href={downloadUrl} variant="white" fullWidth>
                            <Text size={16} weight={600} color={colorPalette.subTextColor}>
                                <i className="ti ti-download" /> Download invoice
                            </Text>
                        </Button>
                    </Box>
                </ModalBody>
            </Grid.Col>
        </Grid>
    );
}

function CardHolder({ item, isPrimary, setSelected }: { item: PaymentSource; isPrimary: boolean; setSelected: (id: string) => void }) {
    const [svg, setSvg] = useState<string>();
    const { classes } = useStyles();

    useEffect(() => {
        switch (item.Card?.Brand) {
            case 'Visa':
                setSvg('/assets/cards/Visa.svg');
                break;
            case 'Mastercard':
                setSvg('/assets/cards/Mastercard.svg');
                break;
            case 'AmericanExpress':
                setSvg('/assets/cards/AMEX.svg');
                break;
            case 'Discover':
                setSvg('/assets/cards/Discover.svg');
                break;
            case 'Jcb':
                setSvg('/assets/cards/JCB.svg');
                break;
            case 'DinersClub':
                setSvg('/assets/cards/DinersClub.svg');
                break;
            case 'Bancontact':
                setSvg('/assets/cards/Bancontact.svg');
                break;
            default:
                setSvg('/assets/cards/Generic_card.svg');
                break;
        }
    }, [item]);

    return (
        <>
            <Box className={classes.cardStyles}>
                <Group position="apart">
                    <Group>
                        <CardIcon src={svg} alt={item.Card?.Brand ?? ''} />
                        <Box>
                            <Text color={colorPalette.subHeaderTextColor} weight={500}>
                                {item.Card?.Brand} ending in {item.Card?.Last4}{' '}
                                {isPrimary ? (
                                    <Badge
                                        style={{
                                            backgroundColor: theme.colors?.primary?.[2] as CustomColors,
                                            color: theme.colors?.primary?.[8] as CustomColors,
                                        }}
                                    >
                                        Primary payment
                                    </Badge>
                                ) : (
                                    ''
                                )}
                            </Text>
                            <Text color={colorPalette.subTextColor} weight={400}>
                                {item.Card?.ExpiryMonth}/{item.Card?.ExpiryYear}
                            </Text>
                        </Box>
                    </Group>
                    <Radio value={item.Id as string} onClick={() => setSelected(item.Id as string)}></Radio>
                </Group>
            </Box>
        </>
    );
}

function AccountHolder({ item, isPrimary, setSelected }: { item: PaymentSource; isPrimary: boolean; setSelected: (id: string) => void }) {
    const { classes } = useStyles();
    return (
        <>
            <Box className={classes.cardStyles}>
                <Group position="apart">
                    <Group>
                        <ActionIcon sx={{ fontSize: '35px' }}>
                            <i className="ti ti-building-bank" />
                        </ActionIcon>

                        <Box pl="28px">
                            <Group>
                                <Text color={colorPalette.subHeaderTextColor} weight={500}>
                                    Bank account ending in {item.BankAccount?.Last4}{' '}
                                    {isPrimary ? (
                                        <Badge
                                            style={{
                                                backgroundColor: theme.colors?.primary?.[2] as CustomColors,
                                                color: theme.colors?.primary?.[8] as CustomColors,
                                            }}
                                        >
                                            Primary payment
                                        </Badge>
                                    ) : (
                                        ''
                                    )}
                                </Text>
                            </Group>
                            <Text color={colorPalette.subTextColor} weight={400}>
                                {item.BankAccount?.BankName}
                            </Text>
                        </Box>
                    </Group>
                    <Radio value={item.Id as string} onClick={() => setSelected(item.Id as string)}></Radio>
                </Group>
                <Space h={10}></Space>
            </Box>
        </>
    );
}

const useStyles = createStyles((theme) => ({
    modalBox: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        paddingTop: '24px',
        paddingBottom: '24px',
        paddingLeft: '40px',
        paddingRight: '40px',
        marginTop: `${theme.spacing.md}px`,
        justifyContent: 'center',
    },

    paymentBox: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        width: '550px',
        padding: '24px',
    },

    successBox: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: '24px',
        textAlign: 'center',
    },

    successIconBox: {
        i: {
            size: `${theme.fontSizes.md}px`,
            color: colorPalette.greenIconColor,
            backgroundcolor: colorPalette.greenIconBgColor,
            borderradius: '50%',
            textAlign: 'center',
            width: '32px',
            height: '32px',
            display: 'block',
        },
    },

    paymentHeaderBox: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: '16px',
    },

    radioBox: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.md}px`,
    },

    cardStyles: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
        marginTop: `${theme.spacing.md}px`,
        width: '780px',
    },

    arrow: {
        i: {
            color: colorPalette.pendingVerificationArrowColor,
        },
    },

    successIcon: {},
}));
