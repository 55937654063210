import {
    getPaymentGetPrimaryPayment,
    getSubscriptionGetCompanyInfo,
    getSubscriptionGetSubscriptionById,
    getSubscriptionGetSubscriptionOptions,
    getSubscriptionGetSubscriptionsByCompany,
    postInvoiceCreateEstimate,
    postPaymentAddPaymentSource,
    postSubscriptionCancelSubscriptionAtContractEnd,
    postSubscriptionReverseSubscriptionCancellation,
    postSubscriptionUpdateCompanyInfo,
    postSubscriptionUpdateSubscription,
    useGetPaymentGetChargeBeeCustomer,
    useGetPaymentGetPrimaryPayment,
} from '@apis/Customers';
import {
    Address,
    CompanyInfo,
    EstimateRequest,
    InvoiceDetail,
    Plans,
    SubscriptionDetail,
    SubscriptionLite,
    Card as PaymentCard,
} from '@apis/Customers/model';
import {
    Anchor,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    Space,
    Text,
    TextInput,
    ThemeIcon,
    useMantineTheme,
    createStyles,
    Alert,
} from '@mantine/core';
// import { PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
// import { Link } from '@root/Services/Router/Links';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsPage, SettingsPageHeader } from '../SettingsPage';
// import { SubscriptionCard } from './SubscriptionCard';
import { getProductIcon } from '@root/Components/Applications/AppIcons';
// import { openModal, useModals } from '@mantine/modals';
import { useFullscreenModal, WizardModalContent, WizardModalContentSingleChild } from '@root/Components/Wizard/WizardModal';
import { AccountHolder, CardHolder, Tokenizeable } from '../Billing/PaymentMethods';
import { SubscriptionChangeSuccess } from './SubscriptionChangeSuccess';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { closeModal, openConfirmModal, useModals } from '@mantine/modals';
import { useDi, useDiComponent } from '@root/Services/DI';
import { JobService } from '@root/Services/Jobs/JobService';
import { BasicApi } from '@root/Services/BasicApi';
import { RegistrationService } from '@root/Site/Registration';
import { FormatService } from '@root/Services/FormatService';
import { useForm } from '@mantine/form';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
// @ts-ignore
import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
});

export function SubscriptionManage() {
    const { getData, getAscendUrl, goto } = useNav();
    const { subscriptionId, appType, promocode } = getData('subscriptionId', 'appType', 'promocode');
    const theme = useMantineTheme();

    const [monthlyPlans, setMonthlyPlans] = useState([] as Plans[]);
    const [yearlyPlans, setYearlyPlans] = useState([] as Plans[]);
    const [showYearly, setShowYearly] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState({} as SubscriptionLite);
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({} as CompanyInfo);

    const [subscription, setSubscription] = useState<SubscriptionDetail>();
    const [expiredSub, setExpiredSub] = useState(false);
    const [showPurchaseSuccess, setPurchaseSuccess] = useState(false);
    const jobSvc = useDi(JobService);
    const api = useDi(BasicApi);
    const nav = useNav();
    const regSvc = useMemo(() => new RegistrationService(jobSvc, api, nav), []);
    const [loading, setLoading] = useState(true);
    const formatSvc = useDi(FormatService);
    const [isSixMonthSub, setSixMonthSub] = useState(false);
    const [appTypeDeux, setAppTypeDeux] = useState('');
    const [defaultTier, setDefaultTier] = useState('Standard');
    useEffect(() => {
        (async () => {
            if (promocode) {
                const plan = await regSvc.getSubscriptionByPromoCode(promocode);
                if (plan) {
                    const subscriptions = await getSubscriptionGetSubscriptionsByCompany();
                    const presentSubs = subscriptions.filter((s) => s.AppType == plan.Applictaion)[0];
                    await openConfirmation(plan, presentSubs.SubscriptionId!, 1, companyInfo);
                }
            }
        })();
    }, []);

    async function getCompanySubscription() {
        await getSubscriptionGetSubscriptionsByCompany().then((subscriptions) => {
            var sub = subscriptions.find((s) => s.SubscriptionId === subscriptionId);
            setSubscription(sub);
            if (sub !== undefined && sub!.DaysRemaining! < 0) {
                setExpiredSub(true);
            }

            if (sub === undefined) {
                goto(getAscendUrl());
            }
            if (appType === undefined || sub?.Term === 'Trial') {
                //this is needed until we have other Tiers and a way to purchase them
                setAppTypeDeux('Tag Manager');
                setDefaultTier('Standard');
            }
            setLoading(false);
        });
    }

    async function getSubscriptionOptions() {
        await getSubscriptionGetSubscriptionOptions({ applicationType: appTypeDeux ?? appType }).then((plans) => {
            setMonthlyPlans((plans.MonthlyPlans ?? []).filter((p) => p.IsActive));
            setYearlyPlans((plans.YearlyPlans ?? []).filter((p) => p.IsActive));
        });
    }

    async function getSubscriptionById() {
        var subscription = await getSubscriptionGetSubscriptionById({ subscriptionId });
        setCurrentSubscription(subscription);
        await setSixMonthSub(subscription?.AppExternalId === 'Tag-Manager-Standard-Special-Offer-USD-Monthly');
        setLoading(false);
    }

    useEffect(() => {
        const getCompanyInfo = async () => {
            await GetCompanySubscriptionInfo();
        };

        getCompanyInfo();
    }, []);

    async function cancelSubscription(subscriptionId: any) {
        await postSubscriptionCancelSubscriptionAtContractEnd({ subscriptionId: subscriptionId });
        await GetCompanySubscriptionInfo();
    }

    const enableAutoRenewal = async (subscriptionId: string) => {
        await postSubscriptionReverseSubscriptionCancellation({ subscriptionId: subscriptionId });
        await GetCompanySubscriptionInfo();
    };

    const GetCompanySubscriptionInfo = async () => {
        await getCompanySubscription();
        await getSubscriptionOptions();
        await getSubscriptionById();
        setLoading(false);
    };

    function switchPlansType() {
        setShowYearly((showYearly) => !showYearly);
    }

    function formatDate(date: string) {
        if (date === null || date === undefined) {
            return 'N/A';
        }
        return new Intl.DateTimeFormat('en', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(Date.parse(date));
    }

    function getRenewText() {
        //account for what to say if this is 6month trial sub
        if (subscription?.Renewing === 1) {
            if (subscription.Type === 'Msp') {
                return 'Your plan will renew on ' + formatDate(currentSubscription.TermDate!);
            }
            return 'Your plan will renew for one year on ' + formatDate(currentSubscription.TermDate!);
        }

        if (currentSubscription.Term === 'Trial') {
            return 'You currently have a trial plan which expires on ' + formatDate(currentSubscription.TrialEnd!);
        }
    }

    const isTrial = subscription?.Term === 'Trial';
    const AppIcon = getProductIcon(subscription?.AppType ?? '');
    const { open } = useFullscreenModal();
    const company = useCompany();
    function UserAgrees({ plan }: { plan: Plans }) {
        const [userAgrees, setUserAgrees] = useState<boolean>(false);

        return (
            <Box>
                <Group position="left" spacing={6}>
                    <Checkbox
                        onChange={(event: any) => {
                            setUserAgrees(event.target.checked);
                        }}
                    />
                    I agree with the
                    <Anchor href="https://www.cloudsaver.com/legal/master-subscription-agreement/" target="_blank">
                        terms of service
                    </Anchor>
                </Group>
                <Space h={15} />
                <Group position="right">
                    <Button
                        disabled={!userAgrees}
                        onClick={() => {
                            closeModal('UserAgrees');
                            openConfirmation(plan, subscriptionId!, 1, companyInfo);
                        }}
                    >
                        Proceed
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() => {
                            closeModal('UserAgrees');
                        }}
                    >
                        Cancel
                    </Button>
                </Group>
            </Box>
        );
    }

    const modals = useModals();
    const DiContainer = useDiComponent();
    const openUpgradeTermsOfServiceModal = (plan: Plans) => {
        const id = modals.openModal({
            zIndex: 500,
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                    CloudSaver Terms of Service
                </Text>
            ),
            children: (
                <DiContainer>
                    <UserAgrees plan={plan} />,
                </DiContainer>
            ),
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };

    const onSelect = (plan: Plans) => {
        openUpgradeTermsOfServiceModal(plan);
    };

    async function loadCompanyInfo(): Promise<CompanyInfo> {
        return getSubscriptionGetCompanyInfo().then((info) => {
            setCompanyInfo(info);
            return info;
        });
    }

    const openConfirmation = useCallback(async (plan: Plans, subId: string, step: number, companyInfo: CompanyInfo) => {
        const primaryPayment = await getPaymentGetPrimaryPayment();
        companyInfo = await loadCompanyInfo();
        if (step === 1 || !companyInfo) {
            open((close) => (
                <AddAddress
                    onCancel={close}
                    onNext={() => {
                        openConfirmation(plan, subId, 2, companyInfo);
                    }}
                    companyInfo={companyInfo}
                ></AddAddress>
            ));
        } else if (step === 2) {
            if (!primaryPayment) {
                open((close) => (
                    <AddPayment
                        onCancel={() => {
                            openConfirmation(plan, subId, 1, companyInfo);
                        }}
                        onNext={() => {
                            openConfirmation(plan, subId, 3, companyInfo);
                        }}
                        companyInfo={companyInfo}
                    ></AddPayment>
                ));
            } else {
                open((close) => <ConfirmSubscription onCancel={close} plan={plan} subscriptionId={subId} promocode={promocode!} />);
            }
        } else {
            open((close) => <ConfirmSubscription onCancel={close} plan={plan} subscriptionId={subId} promocode={promocode!} />);
        }
    }, []);

    async function getInvoiceEstimate(planId: string) {
        var estimateResult = await postInvoiceCreateEstimate({ PlanId: planId, CompanyId: company?.Id });
        return estimateResult;
    }

    async function hasCharegeBeeBillingInfo() {
        const chargeBeeCustomer = await getChargebeeCustomer();
        return chargeBeeCustomer;
    }

    async function getChargebeeCustomer() {
        const customerResult = useGetPaymentGetChargeBeeCustomer();
        return customerResult;
    }

    async function switchToYearlyBilling(currentSubscription: SubscriptionDetail) {
        const yearlyPlan = yearlyPlans.find(
            (x) => x.Applictaion === currentSubscription.AppType && x.Tier === currentSubscription.Tier && x.Term === 'Year'
        );
        await openConfirmation(yearlyPlan!, currentSubscription.SubscriptionId!, 1, companyInfo);
    }

    const confirmCancelAutoRenewal = (id: string) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'red' }}>
                    <b>Cancel Auto-Renewal?</b>
                </span>
            ),
            children: <Text>{`Are you sure you want your subscription to expire on ${formatDate(currentSubscription.TermDate!)}`}</Text>,
            centered: true,
            labels: { confirm: 'Cancel Auto-Renewal', cancel: 'No Change' },
            onConfirm: () => cancelSubscription(id),
            confirmProps: { color: 'error' },
        });
    };

    function getSubscriptionAmount(sub: SubscriptionDetail) {
        if (sub.Amount === null || sub.Amount === undefined) {
            return 0;
        }
        if (sub.FirstYearDiscountApplied) {
            return sub.Amount! / 2;
        }
        return sub.Amount;
    }

    function getSubscriptionTermText(sub: SubscriptionDetail) {
        if (sub.FriendlyName === 'Tag Manager - 6 Month Promo') {
            return '6 month free promo';
        } else if (sub.Term === 'Trial') {
            return '1 month free trial';
        } else if (sub.Term === 'Year') {
            return 'One-year contract, billed annually';
        }
        if (sub.Term === 'Month' && sub.Type === 'Msp') {
            return 'Billed monthly';
        }
        return 'One-year contract, billed monthly';
    }

    return loading ? (
        <></>
    ) : (
        <SettingsPage>
            <Box px="xl" pt="xl">
                <Anchor data-atid="BackButton" size="sm" href={getAscendUrl()} color={theme.colors?.primary?.[6] as CustomColors}>
                    <i color={theme.colors?.primary?.[6] as CustomColors} className="ti ti-arrow-left"></i> Back To Subscriptions
                </Anchor>
            </Box>
            <SettingsPageHeader text="Manage Subscription" />

            <Box p="xl" sx={{ maxWidth: theme.breakpoints.md }}>
                <Space h="xl" />
                <Group>
                    <ThemeIcon radius="xl" size="lg" color={theme.colors?.primary?.[0] as CustomColors}>
                        {AppIcon ? <AppIcon color={theme.colors?.primary?.[8] as CustomColors} /> : null}
                    </ThemeIcon>
                    <Group sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                        <Text style={{ textAlign: 'left' }}>{subscription?.AppType}</Text>
                        <Text sx={{ marginLeft: -40 }} size="xs">
                            {subscription?.Tier === 'Exclusive' ? '' : defaultTier}
                        </Text>
                    </Group>
                </Group>
                {/* <Switch onLabel="Yearly" offLabel="Monthly" size={'lg'} pr={10} m={3} onClick={() => switchPlansType()} /> */}
                {/* isTrial: {isTrial} subscription: {subscription}{' '} */}
                {!expiredSub && !isTrial ? null : (
                    <>
                        <Space h="xl" />
                        <Grid>
                            {[...yearlyPlans, ...monthlyPlans].map((plan) => {
                                return (
                                    <Grid.Col span={4}>
                                        <TrialUpgradeOption plan={plan} onSelect={onSelect} />
                                    </Grid.Col>
                                );
                            })}
                        </Grid>
                        <Space h="md" />
                    </>
                )}
                <Space h="md" />
                {isTrial || expiredSub ? null : (
                    <Group>
                        <Card style={{ minWidth: '120%' }} p="xl" withBorder color="white" radius="lg">
                            {/* <Text weight="bold" color="dimmed">
                                {subscription?.Tier} {' - '}
                                {subscription?.IsSixMonthPromo ? '6 Months Free' : <>{subscription?.Term}ly</>}
                            </Text> */}
                            <Grid>
                                <Grid.Col span={4}>
                                    <Text>Subscription Term: </Text>
                                    <Space h="sm" />
                                    <Text>{getSubscriptionTermText(subscription!)}</Text>
                                </Grid.Col>{' '}
                                <Grid.Col span={3}>
                                    <Text>Next Billing Date: </Text>
                                    <Space h="sm" />
                                    <Text weight={'bolder'} size={16}>
                                        {(subscription?.Renewing === 0 && subscription?.BillingCycles === 0) || subscription?.Term === 'Trial' ? (
                                            <>N/A</>
                                        ) : (
                                            <>{formatDate(subscription?.NextBillDate!)}</>
                                        )}
                                    </Text>
                                </Grid.Col>{' '}
                                <Grid.Col span={3}>
                                    <Text>Billing Frequency</Text>
                                    {subscription?.FirstYearDiscountApplied ? (
                                        <Text
                                            color={theme.colors?.gray?.[5] as CustomColors}
                                            mr={4}
                                            size={24}
                                            weight="lighter"
                                            sx={{ display: 'inline' }}
                                            strikethrough
                                        >
                                            {formatSvc.formatMoneyNoDecimals(subscription.Amount ?? 0)}
                                        </Text>
                                    ) : isSixMonthSub ? (
                                        <>
                                            <Text
                                                color={theme.colors?.gray?.[5] as CustomColors}
                                                mr={4}
                                                size={24}
                                                weight="lighter"
                                                sx={{ display: 'inline' }}
                                                strikethrough
                                            >
                                                {formatSvc.formatMoneyNoDecimals(2616)}
                                            </Text>{' '}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <Text size={24} weight="bold" sx={{ display: 'inline' }}>
                                        {formatSvc.formatMoneyNoDecimals(getSubscriptionAmount(subscription!) ?? 0)}
                                    </Text>{' '}
                                    {!subscription?.IsSixMonthPromo ? (
                                        <>
                                            {' '}
                                            /{' '}
                                            <Text size={16} sx={{ display: 'inline' }}>
                                                {subscription?.Term!.toLowerCase()}
                                            </Text>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Grid.Col>
                                {subscription?.Term === 'Month' && subscription?.Tier !== 'Trial' && subscription?.Type === 'Standard' ? (
                                    <>
                                        <Button
                                            onClick={() => switchToYearlyBilling(subscription)}
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 20,
                                                maxWidth: '16%',
                                                padding: 8,
                                            }}
                                            variant="outline"
                                        >
                                            Switch to yearly billing
                                        </Button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Card>
                        <Box hidden={subscription?.Renewing === 0 || subscription?.IsRenewAllowed === 0} style={{ marginLeft: 10 }}>
                            <Text weight="bold">Auto-renewal</Text>
                            <Text>{getRenewText()}</Text>
                        </Box>
                        <Space h="lg" />
                        <Button
                            data-atid="CancelAutoRenewalButton"
                            hidden={subscription?.Renewing === 0 || subscription?.IsRenewAllowed === 0 || subscription?.CancelDate !== null}
                            style={{ justifyContent: 'right' }}
                            variant="outline"
                            onClick={() => confirmCancelAutoRenewal(subscription?.SubscriptionId!)}
                        >
                            Cancel Auto-Renewal
                        </Button>
                        <Button
                            data-atid="EnableAutoRenewalButton"
                            hidden={(subscription?.Renewing === 1 || subscription?.IsRenewAllowed === 0) && subscription?.CancelDate === null}
                            style={{ justifyContent: 'right' }}
                            variant="outline"
                            onClick={() => enableAutoRenewal(subscription?.SubscriptionId!)}
                            color={theme.colors?.primary?.[6] as CustomColors}
                        >
                            Enable Auto-Renewal
                        </Button>
                    </Group>
                )}
                <Space h="md" />
                {}
            </Box>
        </SettingsPage>
    );
}
endpoint('subscriptionManage', SubscriptionManage, 'Manage Subscription');

function TrialUpgradeOption({ plan, onSelect }: { plan: Plans; onSelect: (plan: Plans) => void }) {
    const select = useCallback(() => onSelect(plan), [onSelect, plan]);
    const formatSvc = useDi(FormatService);
    const discountPrice = plan.Price ? plan.Price / 2 : 0;
    const theme = useMantineTheme();
    return (
        <Card p="xl" withBorder color="white" radius="lg">
            <Text>{plan.Tier}</Text>
            <Space h="md" />
            <Text weight="bold" color="dimmed">
                {plan.Term === 'Year' ? 'Pay Upfront' : 'Pay Monthly'},{' '}
                <Text size="sm" color="dimmed">
                    Commit Annually
                </Text>
            </Text>{' '}
            <Space h="md" />
            <Text size={30} weight="bold" sx={{ display: 'inline' }}>
                {plan.Term === 'Month' ? formatSvc.formatMoneyNoDecimals(plan.Price ?? 0) : formatSvc.formatMoneyNoDecimals(plan.Price ?? 0 / 12)}
            </Text>
            <Text ml={4} size="xs" sx={{ display: 'inline' }}>
                / month
            </Text>
            <Text size="sm" color="dimmed">
                Billed at{' '}
                {plan.Term === 'Month' ? formatSvc.formatMoneyNoDecimals(plan.Price ?? 0) : formatSvc.formatMoneyNoDecimals(plan.Price ?? 0)}
                {' / '}
                {plan.Term?.toLocaleLowerCase()}
            </Text>
            <Space h="md" />
            <Space h="lg" />
            <Button fullWidth onClick={select}>
                Purchase
            </Button>
        </Card>
    );
}

function ConfirmSubscription({
    onCancel,
    plan,
    subscriptionId,
    promocode,
}: {
    onCancel: () => void;
    plan: Plans;
    subscriptionId: string;
    promocode: string;
}) {
    const [invoiceId, setInvoiceId] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const titleText = invoiceId ? 'Subscription Upgraded' : 'Confirm Details';
    const jobSvc = useDi(JobService);
    const api = useDi(BasicApi);
    const nav = useNav();
    const regSvc = useMemo(() => new RegistrationService(jobSvc, api, nav), []);
    const [upgradePlan, setUpgradePlan] = useState<Plans>(plan);
    const company = useCompany();
    const [estimate, setEstimate] = useState<InvoiceDetail>();
    const [showDiscount, setShowDiscount] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [promoId, setPromoId] = useState<string>();
    const companyFeatureSvc = useDi(CompanyFeatureService);

    useEffect(() => {}, []);

    useEffect(() => {
        (async () => {
            let promoid = promocode;
            setPromoId(promoid);
            const estimateRequest: EstimateRequest = {
                PlanId: plan.ExternalId,
                CompanyId: company?.Id,
                PromoId: promoid,
                ExistingSubscriptionId: subscriptionId,
            };
            const estimateData = await regSvc.createEstimate(estimateRequest);
            setEstimate(estimateData);
            setLoading(false);
            if ((estimateData?.Discount as number) > 0) {
                setShowDiscount(true);
            }
        })();
    }, []);

    async function updateSubscription(planId: string) {
        try {
            setLoading(true);

            var invoice = await postSubscriptionUpdateSubscription({
                currentSubscriptionId: subscriptionId,
                newPlanId: planId!,
                promoCode: promoId,
            });
            companyFeatureSvc.reload(company?.Id ?? 0);
            setInvoiceId(invoice.Id!);
        } catch (error) {
            setError(error + 'error');
        } finally {
            setLoading(false);
        }
    }

    const { data: primaryPayment, refetch: refetchPrimary } = useGetPaymentGetPrimaryPayment();
    useEffect(() => {
        refetchPrimary;
    }, []);

    return (
        <WizardModalContent title={titleText} onBack={onCancel} onClose={onCancel}>
            <LoadingOverlay visible={loading} />
            {invoiceId ? (
                <>
                    <SubscriptionChangeSuccess invoiceId={invoiceId} plan={plan} close={onCancel} />
                </>
            ) : (
                <Grid gutter="xl">
                    <Grid.Col span={6}>
                        <Card shadow="lg" p="xl" radius="lg">
                            <Card withBorder radius="lg">
                                <Text size="lg" weight="bold">
                                    {upgradePlan.FriendlyName}
                                </Text>
                            </Card>
                            <Space h="md" />
                            <Text weight="bold">Primary payment method</Text>
                            {!primaryPayment ? (
                                <Card withBorder radius="lg">
                                    <Text color="error">No payment methods</Text>
                                </Card>
                            ) : primaryPayment.PaymentSourceType == 'Card' ? (
                                <CardHolder isPrimary item={primaryPayment} readonly onAction={() => {}} />
                            ) : (
                                <AccountHolder isPrimary item={primaryPayment} readonly onAction={() => {}} />
                            )}
                        </Card>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        {loading ? (
                            <></>
                        ) : (
                            <Card shadow="lg" p="xl" radius="lg">
                                <Text size="lg" weight="bold">
                                    Order summary
                                </Text>
                                <Space h="md" />
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th align="left">Subtotal</th>
                                            <td align="right">{moneyFormatter.format(estimate?.SubTotal ?? 0)}</td>
                                        </tr>
                                        {showDiscount ? (
                                            <tr>
                                                <th align="left">Discount</th>
                                                <td align="right">({moneyFormatter.format(estimate?.Discount ?? 0)})</td>
                                            </tr>
                                        ) : (
                                            ''
                                        )}
                                        <tr>
                                            <th align="left">Tax</th>
                                            <td align="right">{moneyFormatter.format(estimate?.Tax!)}</td>
                                        </tr>
                                        {/* hide if credit = 0 */}
                                        <tr>
                                            {/* <th align="left">Account Credit</th>
                                    <td align="right">{moneyFormatter.format(0)}</td> */}
                                        </tr>
                                    </tbody>
                                </table>
                                <Space h="md" />
                                <Divider />
                                <Space h="md" />
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th align="left">TOTAL</th>
                                            <td align="right">{moneyFormatter.format(estimate?.Total ?? 0)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Space h="md" />
                                <Button fullWidth disabled={!primaryPayment} onClick={() => updateSubscription(plan.ExternalId!)}>
                                    Purchase subscription
                                </Button>
                                <Space h="md" />
                                <Button variant="white" fullWidth onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Card>
                        )}
                    </Grid.Col>
                </Grid>
            )}
        </WizardModalContent>
    );
}

function AddAddress({ onNext, onCancel, companyInfo }: { onNext: () => void; onCancel: () => void; companyInfo: CompanyInfo }) {
    const [loading, setLoading] = useState(false);
    const titleText = companyInfo.BillingAddress === null ? 'Enter your company address' : 'Edit your company address';
    const form = useForm({
        initialValues: companyInfo.BillingAddress ?? ({ AddressLine2: '', City: '', CountryCode: '', StateCode: '', ZipCode: '' } as Address),
        validate: {
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        companyInfo.BillingAddress = form.values;
        await SubmitChanges(companyInfo);
    };

    const SubmitChanges = async (companyInfo: CompanyInfo) => {
        setLoading(true);
        await postSubscriptionUpdateCompanyInfo(companyInfo).catch((error) => {
            //console.log(`Error: ${error}`);
        });
        setLoading(false);
        onNext();
    };

    return (
        <WizardModalContentSingleChild title={titleText} onBack={onCancel} onClose={onCancel}>
            {loading && <LoadingOverlay visible={true} />}

            <div style={{ width: '600px', justifyContent: 'center', display: 'flex' }}>
                <form onSubmit={handleSubmit}>
                    <CompanyAddress
                        form={form}
                        prefix={''}
                        requiredFields={[
                            CompanyAddressFields.AddressLine1,
                            CompanyAddressFields.City,
                            CompanyAddressFields.State,
                            CompanyAddressFields.Zip,
                            CompanyAddressFields.Country,
                        ]}
                        isWithinPortal={false}
                    />
                    <Button type="submit">{companyInfo.BillingAddress === null ? 'Add Address' : 'Save Address'}</Button>
                </form>
            </div>
        </WizardModalContentSingleChild>
    );
}

function AddPayment({ onNext, onCancel, companyInfo }: { onNext: () => void; onCancel: () => void; companyInfo: CompanyInfo }) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(false);
    const [cardRef, setCardRef] = useState<Tokenizeable | null>();
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const { classes } = useStyles();
    const [invoice, setInvoice] = useState<InvoiceDetail>();
    const [readyToAdd, setReadyToAdd] = useState(false);
    const [isCardValid, setIsCardValid] = useState(false);
    const [isCardCvvValid, setIsCardCvvValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [paymentCard, setPaymentCard] = useState<PaymentCard>();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const billingAddress = useForm({
        initialValues:
            companyInfo.BillingAddress ?? ({ AddressLine1: '', AddressLine2: '', City: '', CountryCode: '', StateCode: '', ZipCode: '' } as Address),
        validate: {
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const tokenize = () => {
        const validity = billingAddress.validate();
        if (validity.hasErrors) {
            return;
        }
        setLoading(true);
        const { AddressLine1, AddressLine2, City, CountryCode, StateCode, ZipCode } = billingAddress.values;

        cardRef
            ?.tokenize({
                firstName: fName,
                lastName: lName,
                billingAddr1: AddressLine1,
                billingAddr2: AddressLine2,
                billingCity: City,
                billingStateCode: StateCode,
                billingZip: ZipCode,
                billingCountry: CountryCode,
            })
            .then((a) => {
                AddPayment(a.token, billingAddress.values);
            })
            .catch((...args) => {
                setLoading(false);
            });
    };

    const errorMsg = (
        <>
            <Alert
                icon={<i className="ti ti-alert-circle"></i>}
                title={
                    <Text color={colorPalette.errorTitleText} weight={600} size={14}>
                        Error adding payment method!
                    </Text>
                }
                color="error"
                sx={{ backgroundColor: colorPalette.errorBgColor }}
                withCloseButton
                onClose={() => setShowErrorMsg(false)}
            >
                <Text color={colorPalette.errorTitleText} weight={400} size={14}>
                    Please double check the card info.
                </Text>
            </Alert>
            <Space h={20}></Space>
        </>
    );

    async function AddPayment(token: string, address: Address) {
        const added = await postPaymentAddPaymentSource(
            {
                FirstName: fName,
                LastName: lName,
                BillingAddr1: address.AddressLine1,
                BillingAddr2: address.AddressLine2,
                BillingCity: address.City,
                BillingCountry: address.CountryCode,
                BillingStateCode: address.StateCode,
                BillingZipCode: address.ZipCode,
            } as PaymentCard,
            { token: token, isPrimary: true }
        );
        setLoading(false);
        if (added) {
            onNext();
        } else {
            setShowErrorMsg(true);
        }
    }

    function checkOnChange(event: any) {
        if (event.field == 'number') {
            if (event.complete && !event.error) {
                setIsCardValid(true);
            } else {
                setIsCardValid(false);
            }
        }

        if (event.field == 'cvv') {
            if (event.complete && !event.error) {
                setIsCardCvvValid(true);
            } else {
                setIsCardCvvValid(false);
            }
        }

        if (event.field == 'expiry') {
            if (event.complete && !event.error) {
                setIsCardExpiryValid(true);
            } else {
                setIsCardExpiryValid(false);
            }
        }
    }

    useEffect(() => {
        if (isCardValid && isCardCvvValid && isCardExpiryValid) {
            setReadyToAdd(true);
        } else {
            setReadyToAdd(false);
        }
    }, [isCardValid, isCardCvvValid, isCardExpiryValid]);

    const titleText = 'Enter Payment Information';

    return (
        <WizardModalContentSingleChild title={titleText} onBack={onCancel} onClose={onCancel}>
            {loading && <LoadingOverlay visible={true} />}
            <div style={{ width: '600px' }}>
                <div style={{ height: '420px' }}>
                    <Group>
                        <TextInput
                            required
                            label="First name"
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setFName(e.currentTarget.value)}
                            className={classes.nonCardItems}
                            sx={{ width: 258 }}
                        />

                        <TextInput
                            required
                            label="Last name"
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setLName(e.currentTarget.value)}
                            className={classes.nonCardItems}
                            sx={{ width: 258 }}
                        />
                    </Group>
                    <Space h={10}></Space>
                    <CardComponent ref={(v: any) => setCardRef(v)}>
                        <Group>
                            <div className={classes.cardNumber}>
                                <label>Card number</label>

                                <CardNumber className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                            </div>
                            <Space h={10}></Space>
                            <div className={classes.cardCvv}>
                                <label>CVC</label>
                                <CardCVV placeholder="000" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                            </div>
                        </Group>
                        <Space h={10}></Space>
                        <div className={classes.cardExpiry}>
                            <label>Expiry</label>
                            <CardExpiry placeholder="MM / YY" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                        </div>
                    </CardComponent>
                    <Space h={20}></Space>
                    <div style={{ marginRight: '15px' }}>
                        <Divider
                            my="xs"
                            label={
                                <Text size={14} weight={500} color={colorPalette.subTextColor}>
                                    Billing Information
                                </Text>
                            }
                            labelPosition="center"
                        ></Divider>
                        <Space h={10} />
                        <CompanyAddress
                            form={billingAddress}
                            prefix=""
                            requiredFields={[
                                CompanyAddressFields.AddressLine1,
                                CompanyAddressFields.City,
                                CompanyAddressFields.State,
                                CompanyAddressFields.Zip,
                                CompanyAddressFields.Country,
                            ]}
                            isWithinPortal={false}
                        />
                        <Space h={32}></Space>
                        {showErrorMsg ? errorMsg : ''}
                        <Button onClick={tokenize} sx={{ width: '258px' }} disabled={!readyToAdd}>
                            <Text weight={600} color={colorPalette.white} size={16}>
                                Add Card
                            </Text>
                        </Button>
                    </div>
                </div>
            </div>
        </WizardModalContentSingleChild>
    );
}

const useStyles = createStyles((theme) => ({
    cardStyles: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
        marginTop: `${theme.spacing.md}px`,
        maxWidth: '780px',
    },

    arrow: {
        i: {
            color: colorPalette.pendingVerificationArrowColor,
        },
    },

    linkStyles: {
        '&:hover': {
            color: colorPalette.linkHoverColor,
        },
    },

    cardNumber: {
        width: '400px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardCvv: {
        width: '96px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardExpiry: {
        width: '100px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardBox: {
        border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
        borderRadius: `${theme.radius.sm}px`,
        padding: '10px 14px ',
        fontSize: '16px',
    },

    primaryBox: {
        label: {
            fontSize: '16px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    nonCardItems: {
        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },

        input: {
            border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
            borderRadius: `${theme.radius.sm}px`,
            padding: '10px 14px ',
        },
    },

    iFrameModalStyles: {
        alignItems: 'center',
    },
}));
