import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, Ebs, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceRoute53Records(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();

    var retValue;

    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var rows = [];
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            rows.push({
                name: 'No nodes found',
            });
        } else {
            const resource = data.Results[0] as { Route53RecordSet: { ResourceRecords: { Value: string }[] } };
            const records = resource.Route53RecordSet?.ResourceRecords;
            if (records) {
                for (var i = 0; i < records.length; i++) {
                    rows.push({
                        value: records[i].Value,
                    });
                }
            }
        }
        retValue = (
            <div className="resourcesTableContainer">
                <ResourceInfoTable>
                    <Table>
                        <thead>
                            <tr>
                                <td className="left">Value</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </ResourceInfoTable>
            </div>
        );
    }

    return retValue;
}

export function ResourceRoute53Records() {
    return <>Resource Route 53 Records</>;
}
endpoint('resource', ResourceRoute53Records, 'Resource Route 53 Records');
