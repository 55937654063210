import { CompanyInfo, CreditSummary } from '@apis/Customers/model';
import { Card, Group, Stack, Title, Text, Space, Radio, NumberInput, LoadingOverlay, Modal, Anchor, Switch } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { FormatService } from '@root/Services/FormatService';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import {
    getCreditsGetCreditsSpentForPeriod,
    getCreditsGetCreditSummary,
    postCreditsUpdateCreditOverride,
    postCreditsUpdateSettingsPage,
} from '@apis/Customers';
import { AdminPageTitle, AdminPanel } from './Design';
import { useDisclosure } from '@mantine/hooks';
import { DatePeriodPicker } from '@root/Site/Msp/Components/DatePeriodPicker';
import { CreditUsageOverview } from '@root/Site/Settings/Billing/Components/CreditUsageOverview';
import { FeeSchedule } from '@root/Components/CompanyInfo/Administration/FeeSchedule';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { AuthorizationService, useAuthZ, useAuthZValues } from '@root/Services/AuthorizationService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCompany } from '@root/Components/Router/CompanyContent';

export interface AdminCompanyInfoFormProps {
    companyInfo: CompanyInfo;
    model: CompanyAdministrationPanelModel;
    toggleSidePanel: (showSidePanel: boolean) => void;
    loadCompany: () => void;
}

export const AdminCreditUsage = (props: AdminCompanyInfoFormProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [intialLoad, setInitialLoad] = useState<boolean>(true);
    const [creditSummary, setCreditSummary] = useState<CreditSummary>();
    const [selectedDate, setSelectedDate] = useState<string>('This Month');
    const [selectedLimit, setSelectedLimit] = useState<string>('No Limit');
    const [limitAmount, setLimitAmount] = useState<number>(0);
    const [dateRange, setDateRange] = useState<{ startDate: Date; endDate: Date }>({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
    });
    const [creditsSpent, setCreditsSpent] = useState<number>(0);
    const [creditUsageFees, setCreditUsageFees] = useState<number>(0);
    const contextSvc = useDi(CompanyContextService);
    const company = useCompany();
    const parentCompany = contextSvc.parentCompany;
    const [isPlatformSupport, setIsPlatformSupport] = useState<boolean>(parentCompany?.Type === 'PlatformSupport');
    const authZSvc = useDi(AuthorizationService);
    const canManageSubscription = authZSvc.allow('CustomerSubscription', 'Manage', parentCompany?.Id ?? 0);
    const [creditOverride, setCreditOverride] = useState<boolean>(false);
    const notficationSvc = useDi(NotificationService);

    const formatSvc = useDi(FormatService);
    var formatter = new Intl.NumberFormat('en-US', {});

    const [opened, { open, close }] = useDisclosure(false);
    useEvent(props.model.tabChanged, (tab) => {
        if (intialLoad && tab === 'credit_usage') {
            loadData();
            setInitialLoad(false);
        }
    });

    useEffect(() => {
        loadData();
    }, [dateRange]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            setCreditSummary(await getCreditsGetCreditSummary());
            let usedCredits = await getCreditsGetCreditsSpentForPeriod({
                startDate: dateRange.startDate!.toDateString(),
                endDate: dateRange.endDate!.toDateString(),
                isMsp: false,
            });
            if (usedCredits && usedCredits[0]) {
                setCreditsSpent(usedCredits[0].CreditsUsed!);
                setCreditUsageFees(usedCredits[0].CreditUsageFees!);
            }
            setValues();
        } finally {
            setIsLoading(false);
        }
    };

    const setValues = useCallback(() => {
        const creditLimit = creditSummary?.CreditLimit ?? 0;
        setSelectedLimit(creditLimit === 0 ? 'No Limit' : 'Limit to');

        if (creditLimit > 0) {
            setLimitAmount(creditLimit);
        }
        setCreditOverride(!creditSummary?.CanUseCredits);
    }, [creditSummary]);

    const handleSaveChanges = useCallback(async () => {
        try {
            setIsLoading(true);
            await postCreditsUpdateSettingsPage({
                paymentType: creditSummary?.PaymentType,
                lowCredit: creditSummary?.CreditThreshold,
                maxCredit: limitAmount,
            });
        } finally {
            setIsLoading(false);
        }
        props.model.isModifiedChanged.emit(false);
    }, [creditSummary, limitAmount]);

    const handleDiscardChanges = useCallback(() => {
        setValues();
        props.model.isModifiedChanged.emit(false);
    }, [props.model.isModifiedChanged, setValues]);

    useEvent(props.model.discardChangesRequested, handleDiscardChanges);
    useEvent(props.model.saveChangesRequested, handleSaveChanges);

    const handleLimitChanged = (value: string) => {
        setSelectedLimit(value);
        setLimitAmount(0);
        props.model.isModifiedChanged.emit(true);
    };

    const handleLimitAmountChanged = (newAmount: number) => {
        if (newAmount !== limitAmount) {
            setLimitAmount(newAmount);
            props.model.isModifiedChanged.emit(true);
        }
    };

    const handleCreditOverrideChanged = async (value: boolean) => {
        contextSvc.withParentCompany(async () => {
            try {
                const updatedCreditSummary = await postCreditsUpdateCreditOverride({ companyId: company?.Id, creditOverride: value });
                setCreditOverride(value);
                setCreditSummary(updatedCreditSummary);
            } catch (err) {
                notficationSvc.notify('Failed to update credit override', err + '', 'error', <></>);
            }
        });
    };

    return (
        <>
            <Modal opened={opened} onClose={close} size="auto" title={<Title order={3}>Credit Fee Schedule</Title>}>
                <FeeSchedule />
            </Modal>
            <AdminPanel>
                {isLoading && <LoadingOverlay visible={true} />}
                {isPlatformSupport && canManageSubscription && (
                    <>
                        <Group position="apart">
                            <AdminPageTitle>Company Utilizes Credits</AdminPageTitle>
                            <Switch
                                label=""
                                key="creditsSwitch"
                                id="creditsSwitch"
                                checked={!creditOverride}
                                onChange={(e) => handleCreditOverrideChanged(!e.currentTarget.checked)}
                            />
                        </Group>
                        <Space h="sm" />
                    </>
                )}
                {!creditOverride && (
                    <>
                        <Group position="apart">
                            <AdminPageTitle>Credit Usage</AdminPageTitle>

                            <Anchor component="button" onClick={open}>
                                View Credit Fee Schedule
                            </Anchor>
                        </Group>
                        <Space h="sm" />
                        <Group position="center" style={{ display: 'flex', alignItems: 'stretch' }}>
                            <Card withBorder radius="lg" p="lg" style={{ flex: 1 }}>
                                <Stack spacing={2}>
                                    <Title order={5}>Credits Used</Title>
                                    <Title order={2}>{formatter.format(creditsSpent)}</Title>
                                    <Text color="dimmed">{selectedDate}</Text>
                                </Stack>
                            </Card>
                            <Card withBorder radius="lg" p="lg" style={{ flex: 1 }}>
                                <Stack spacing={2}>
                                    <Title order={5}>Credits Usage Fees</Title>
                                    <Title order={2}>{formatSvc.formatMoney(creditUsageFees)}</Title>
                                    <Text color="dimmed">{selectedDate}</Text>
                                </Stack>
                            </Card>
                        </Group>
                        <Space h="sm" />
                        <Stack style={{ margin: '16px 0px' }} spacing={2}>
                            <Group>
                                <Title order={5}>Credit Limit</Title>
                                <Text color="dimmed">(For each billing cycle)</Text>
                            </Group>
                            <Radio.Group orientation="vertical" spacing={2} value={selectedLimit} onChange={(val) => handleLimitChanged(val)}>
                                <Radio value="No Limit" label="No Limit" />
                                <Radio
                                    value="Limit to"
                                    label={
                                        <Group>
                                            <Text>Limit to</Text>
                                            <NumberInput
                                                min={0}
                                                value={limitAmount}
                                                onChange={(val) => handleLimitAmountChanged(val ?? 0)}
                                                disabled={selectedLimit === 'No Limit'}
                                            />
                                            <Text>credits</Text>
                                        </Group>
                                    }
                                />
                            </Radio.Group>
                        </Stack>
                        <Space h="sm" />
                        <Title order={5}>Credit Transactions</Title>
                        <CreditUsageOverview
                            hideTotalCreditsBalance={!isPlatformSupport}
                            transactionHeaderSize={5}
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            rightTopControl={
                                <>
                                    <DatePeriodPicker range={dateRange} selectedPeriodChanged={setSelectedDate} onRangeChanged={setDateRange} />
                                </>
                            }
                        />
                    </>
                )}
            </AdminPanel>
        </>
    );
};
