import { Box, Button, Popover, Text } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import { useDi } from '@root/Services/DI';
import { FilterItem, FilterToken } from './Design';
import { FormatService } from '@root/Services/FormatService';
import { RangeCalendar } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { injectable } from 'tsyringe';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { Picker } from '../Picker/Picker';

export function DateRangeFilter({
    value,
    onChange,
    constraint,
    options,
    disabled,
    model,
    mode,
    listItems,
    handleItemChanged,
    corners,
    height,
    emptyLabel,
}: DateRangeFilterProps) {
    const [opened, { open, toggle, close }] = useDisclosure(false);
    const formatSvc = useDi(FormatService);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    useEvent(model?.toggleDialog, () => {
        toggle();
    });
    useEvent(model?.showCalendar, (value) => {
        setShowCalendar(value);

        // Close the dialog if we are hiding the calendar
        if (value === false) {
            close();
        }
    });
    const handleChange = useCallback(
        ([from, to]: [Date, Date]) => {
            onChange({ from, to });
        },
        [onChange]
    );
    const optionLookup = useMemo(
        () => options?.reduce((result, item) => result.set(item.id, item), new Map<string, LabeledRangeOption>()),
        [options]
    );
    const calendarValue = useMemo(() => [value.from || null, value.to || null] as [Date | null, Date | null], [value.from, value.to]);
    const selectedLabel = value.id ? optionLookup?.get(value.id) : null;
    const selectOption = useCallback(
        (option: { id: string }) => {
            onChange(option);
            close();
        },
        [close]
    );
    const handlePickerChange = (items: string[]) => {
        if (handleItemChanged) {
            handleItemChanged(items);
        }
    };
    return (
        <Popover opened={opened} withinPortal shadow="md" onClose={close}>
            <Popover.Target>
                <FilterItem
                    style={{ height }}
                    state="valid"
                    corners={corners}
                    onClick={() => {
                        if (!disabled) {
                            toggle();
                            setShowCalendar(false);
                        }
                    }}
                    data-atid="GlobalDateRangeFilter"
                >
                    <FilterToken maxWidth={30}>
                        <i className="ti ti-calendar" />
                    </FilterToken>
                    {selectedLabel ? (
                        <FilterToken data-atid={'FilterToken:' + selectedLabel.label}>{selectedLabel.label}</FilterToken>
                    ) : emptyLabel && !value.from && !value.to ? (
                        <FilterToken>{emptyLabel}</FilterToken>
                    ) : (
                        <>
                            <FilterToken maxWidth={200} data-atid="GlobalDateRangeFilterFrom">
                                {value.from ? formatSvc.toShortDate(value.from) : 'All prior'}
                            </FilterToken>
                            <FilterToken>&mdash;</FilterToken>
                            <FilterToken maxWidth={200} data-atid="GlobalDateRangeFilterTo">
                                {value.to ? formatSvc.toShortDate(value.to) : 'All later'}
                            </FilterToken>
                        </>
                    )}
                    {!disabled && (
                        <FilterToken>
                            <i className="ti ti-chevron-down" />
                        </FilterToken>
                    )}
                </FilterItem>
            </Popover.Target>
            <Popover.Dropdown>
                <Box>
                    {mode !== 'combo' || showCalendar ? (
                        <>
                            {options?.map((o) => (
                                <Button radius="xl" size="xs" fullWidth variant="outline" onClick={() => selectOption(o)} my="sm">
                                    {o.label}
                                </Button>
                            ))}
                            <RangeCalendar
                                onChange={handleChange}
                                firstDayOfWeek="sunday"
                                value={calendarValue}
                                minDate={constraint.min}
                                maxDate={constraint.max}
                                id="date-range-filter"
                            />
                        </>
                    ) : (
                        <Picker
                            mode="single"
                            width="250px"
                            height={300}
                            minimizeHeight
                            // default type is an object with children. This is just a noop override
                            childAccessor={() => undefined}
                            items={listItems ?? []}
                            selections={[]}
                            nameAccessor={(n) => n}
                            renderItem={(item) => <Text size="sm">{item}</Text>}
                            noFilter={true}
                            onChange={handlePickerChange}
                        />
                    )}
                </Box>
            </Popover.Dropdown>
        </Popover>
    );
}
interface DateRangeFilterProps {
    value: { from?: Date; to?: Date; id?: string };
    onChange: (value: { from?: Date; to?: Date; id?: string }) => void;
    options?: LabeledRangeOption[];
    constraint: { min?: Date; max?: Date };
    disabled?: boolean;
    model?: DateRangeFilterModel;
    mode?: 'combo' | 'calendar';
    listItems?: string[];
    handleItemChanged?: (item: string[]) => void;
    corners?: number;
    height?: number;
    emptyLabel?: string;
}
interface LabeledRangeOption {
    id: string;
    label: string;
}

@injectable()
export class DateRangeFilterModel {
    public toggleDialog = EventEmitter.empty();
    public showCalendar = new EventEmitter<boolean>(false);
}
