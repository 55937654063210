import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { NotFound } from './NotFound';

export function GetResourceTags(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        if (props.resourceType == 'ELB') {
            (async () => {
                var query = queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq('ELBV1'), r.model.Id!.eq(props.resourceId)))
                    .build();
                var v1Data = await postResourcesQuery(query, { companyId: company?.Id });
                var resourceType = v1Data.Results?.[0] != null ? 'ELBV1' : 'ELBV2';

                runQuery({
                    data: queryBuilder<BaseAwsResource>()
                        .where((r) => r.and(r.model.ResourceType!.eq(resourceType), r.model.Id!.eq(props.resourceId)))
                        .build(),
                    params: { companyId: company?.Id },
                });
            })();
        } else {
            runQuery({
                data: queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                    .build(),
                params: { companyId: company?.Id },
            });
        }
    }, []);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <NotFound description="No tags"></NotFound>;
        } else {
            const resource = data.Results[0] as BaseResource;
            if (resource.CsTags == null) {
                retValue = <NotFound description="No tags"></NotFound>;
            } else {
                var tags = resource.CsTags;
                var keys = Object.keys(tags);
                keys.sort();

                var resourceValues = [];
                try {
                    for (var j = 0; j < keys.length; j++) {
                        resourceValues.push({ label: keys[j], value: tags[keys[j]] });
                    }
                } catch (error) {}

                if (resourceValues.length > 0) {
                    let valuesArray = [];
                    for (var i = 0; i < resourceValues.length; i++) {
                        valuesArray.push(
                            <tr key={'tr' + i}>
                                <td key={'l' + i} className="label">
                                    {resourceValues[i].label}
                                </td>
                                <td key={'v' + i} className="value">
                                    {resourceValues[i].value}
                                </td>
                            </tr>
                        );
                    }
                    retValue = (
                        <Table className="resourcesTable">
                            <tbody>{valuesArray}</tbody>
                        </Table>
                    );
                } else {
                    retValue = <NotFound description="No tags"></NotFound>;
                }
            }
        }
    }

    return <div className="resourcesTableContainer">{retValue}</div>;
}

export function ResourceTags() {
    return <>Resource Tags</>;
}
endpoint('resource', ResourceTags, 'Resource Tags');
