import { useCallback, useEffect, useMemo, useState } from 'react';
import { ResourceGridModel, ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { ChildAccessor, ColumnConfig, ColumnGroupConfig, GridColumnState } from '@root/Components/DataGrid/Models';
import { BaseResource, IQueryExpr } from '@apis/Resources/model';
import { generateColor } from '@root/Design/Primitives';
import {
    Button,
    CloseButton,
    Divider,
    Drawer,
    Group,
    LoadingOverlay,
    Title,
    Text,
    Select,
    Switch,
    Box,
    TextInput,
    NumberInput,
    Tooltip,
    Checkbox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { QueryExpr } from '@apis/Resources';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { SchemaService } from '@root/Services/QueryExpr';
import { PanelBody, PanelContent, PanelHeader, PanelToolbar } from '@root/Design/Layout';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { useDi, useDiContainer } from '@root/Services/DI';
import { ConfigAdministrationModel } from './ConfigAdministrationModel';
import { postCWMetricsConfigSaveCWMetricsConfig, postCWMetricsConfigSaveCWMetricsConfigFilters } from '@apis/Customers';
import {
    PostCWMetricsConfigSaveCWMetricsConfigAppType,
    CWMetricsLabelConfig,
    PostCWMetricsConfigSaveCWMetricsConfigFiltersAppType,
} from '@apis/Customers/model';
import { IdleResourcesService } from '../Services/IdleResourcesService';
import { Observer } from 'mobx-react';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { ResourceSchemaCache } from '@root/Services/Resources/ResourceService';

export function IdleResourceGrid({
    resourceType,
    includeMemory,
    title,
    extraColumns,
    onColumnsLoaded: onColumnsLoadedProp,
    refreshNeeded,
    setIdleResourcesGrid,
    hideHierarchyToggle,
    customDefaultColumns,
}: {
    title: string;
    includeMemory: boolean;
    resourceType: string | string[];
    extraColumns: GridColumnState[];
    onColumnsLoaded?: (grid: ResourceGridModel) => void;
    refreshNeeded: EventEmitter<void>;
    setIdleResourcesGrid?: (grid: DataGridModel) => void;
    hideHierarchyToggle?: boolean;
    customDefaultColumns?: ColumnConfig<BaseResource>[];
}) {
    const [rulesOpen, { open: openRules, close: closeRules }] = useDisclosure(false);
    const [schemaSvc, setSchemaSvc] = useState<SchemaService>();
    const [refresh, setRefresh] = useState(false);
    const [updateQueryToDefault, setUpdateQueryToDefault] = useState(false);
    const [updateConfigDataToDefault, setUpdateConfigToDefault] = useState(false);
    const { idleResourceRefinements } = useAuthZValues({ idleResourceRefinements: { IdleResources: 'Manage' } });
    const { idleResourceChangeState } = useAuthZValues({ idleResourceChangeState: { IdleResources: 'ChangeState' } });
    const noStartStop = !idleResourceChangeState || resourceType === 'ELB' || resourceType === 'EMR' ? true : false;
    const container = useDiContainer();
    const model = container.resolve(ConfigAdministrationModel);
    const [configGeneration, setConfigGeneration] = useState(0);

    const idleResourceService = useDi(IdleResourcesService);
    const [configData, setConfigData] = useState<CWMetricsLabelConfig[]>(
        idleResourceService.getDefaultConfigData(resourceType as string)?.cWMetricLabelConfig as CWMetricsLabelConfig[]
    );
    const [query, setQuery] = useState<IQueryExpr>(idleResourceService.getDefaultQuery(resourceType as string));

    useEffect(() => {
        LoadConfigData();
    }, []);

    function CancelConfigData() {
        LoadConfigData();
        closeRules();
    }

    function LoadConfigData() {
        (async () => {
            setQuery((await idleResourceService.getResourceTypeQuery(resourceType as string, includeMemory)) as unknown as IQueryExpr);
            var originalConfigData = await idleResourceService.getResourceTypeConfigData(resourceType as string);
            setConfigData(originalConfigData?.map((m) => ({ ...m })) as CWMetricsLabelConfig[]);
            setRefresh(true);
        })();
    }

    const updateQuery = useCallback(
        (query: IQueryExpr) => {
            setQuery(query);
            model.isModifiedChanged.emit(true);
            model.isFilterDataModified.emit(true);
        },
        [query]
    );

    const updateConfigData = useCallback(
        (configData: CWMetricsLabelConfig[]) => {
            setConfigData(configData);
            model.isModifiedChanged.emit(true);
            model.isConfigDataModified.emit(true);
        },
        [configData, configGeneration]
    );

    const incrementConfigGeneration = useCallback(() => setConfigGeneration((v) => v + 1), []);

    const [grid, setGrid] = useState<DataGridModel>();
    const [childAccessor, setChildAccessor] = useState<ChildAccessor<BaseResource>>();
    const [showChildren, setShowChildren] = useState(false);
    const defaultColumns = useMemo(
        () =>
            showChildren
                ? ([
                      { id: 'Base.Name', width: 250, fixed: true },
                      { id: 'Base.ResourceType', width: 250, fixed: true },
                      { id: 'Base.Account', width: 150, fixed: true },
                      { id: 'Base.AccountName', width: 200 },
                      { id: 'Base.Region', width: 100 },
                      { id: 'Base.AnnualizedCost', width: 150 },
                  ] as GridColumnState[])
                : ([
                      { id: 'Base.Name', width: 250, fixed: true },
                      { id: 'Base.Account', width: 150, fixed: true },
                      { id: 'Base.AccountName', width: 200 },
                      { id: 'Base.Region', width: 100 },
                      { id: 'Base.AnnualizedCost', width: 150 },
                  ] as GridColumnState[]),
        [showChildren]
    );

    useMemo(() => {
        if (showChildren) {
            setChildAccessor({
                hasChildren: (item) => {
                    return 'groupId' in item || (item.ChildResourceIds && item.ChildResourceIds.length > 0) ? true : false;
                },
            } as ChildAccessor<BaseResource>);
        } else {
            setChildAccessor(undefined);
        }
    }, [showChildren]);

    useEvent(
        refreshNeeded,
        useCallback(() => {
            grid?.refresh();
        }, [grid])
    );

    useEffect(() => {
        if (refresh) {
            grid?.refresh();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (updateQueryToDefault) {
            setQuery(idleResourceService.getDefaultQuery(resourceType as string));
            setUpdateQueryToDefault(false);
            model.isFilterDataModified.emit(true);
            model.isModifiedChanged.emit(true);
        }
    }, [updateQueryToDefault]);

    useEffect(() => {
        if (updateConfigDataToDefault) {
            setConfigData(idleResourceService.getDefaultConfigData(resourceType as string)?.cWMetricLabelConfig as CWMetricsLabelConfig[]);
            setUpdateQueryToDefault(false);
            model.isConfigDataModified.emit(true);
            model.isModifiedChanged.emit(true);
        }
    }, [updateConfigDataToDefault]);

    const groupConfig: { [groupName: string]: ColumnGroupConfig } = { 'Idle Metrics': { color: '#B0E8FF' }, Tags: { color: generateColor('Tags') } };

    const onColumnsLoaded = useCallback(
        (grid: ResourceGridModel) => {
            const foundColumns = new Set<string | undefined>();
            const defaultExtraColumns: GridColumnState[] = [];

            grid.availableColumns.filter((c) => {
                extraColumns.forEach((a) => {
                    if (a.id.includes(c.sortField ?? '') && !foundColumns.has(c.sortField)) {
                        foundColumns.add(c.sortField);
                        defaultExtraColumns.push({ id: c.id!, width: a.width });
                        return true;
                    }
                });
                return false;
            });

            if (defaultExtraColumns.length) {
                defaultColumns.push(...defaultExtraColumns);
            }

            onColumnsLoadedProp?.(grid);
        },
        [showChildren]
    );

    useEvent(model.isRefreshNeeded, (value) => setRefresh(value));
    useEvent(model.changeFiltersToDefault, (value) => setUpdateQueryToDefault(value));
    useEvent(model.changeConfigDataToDefault, (value) => setUpdateConfigToDefault(value));

    const rowSelector = useCallback(
        (item: BaseResource | null, { selected, toggle }: { selected?: boolean | undefined; toggle: (selected: boolean) => void }) => {
            return item ? (
                item?.ResourceType == 'EC2 On Demand' || item?.ResourceType == 'RDS' || item?.ResourceType == 'Redshift' ? (
                    <Checkbox checked={selected} onChange={(e: any) => toggle(e.target.checked!)} style={{ marginLeft: 'auto' }} />
                ) : (
                    <></>
                )
            ) : null;
        },
        []
    );

    return (
        <>
            <ResourcesGrid
                childAccessor={childAccessor}
                resourceType={resourceType}
                defaultColumns={defaultColumns}
                allowSavedViews={true}
                persistenceKey={`Idle ${resourceType}`}
                customDefaultColumns={customDefaultColumns}
                defaultCriteria={query}
                onModelLoaded={(g) => {
                    setGrid(g.dataGrid);
                    setIdleResourcesGrid ? setIdleResourcesGrid(g.dataGrid!) : null;
                    setSchemaSvc(g.dataGrid?.schemaSvc);
                }}
                renderRowSelector={!noStartStop ? rowSelector : undefined}
                onColumnsLoaded={onColumnsLoaded}
                showRefresh
                rightTopPlaceHolder={
                    idleResourceRefinements ? (
                        <Button
                            onClick={openRules}
                            data-atid="DefaultFilterAppliedLink"
                            variant="outline"
                            leftIcon={<i className="ti ti-adjustments-horizontal"></i>}
                            my={5}
                            ml={10}
                            sx={{ height: 30 }}
                        >
                            Refinements
                        </Button>
                    ) : (
                        <></>
                    )
                }
                leftResultsPlaceHolder={
                    hideHierarchyToggle ? null : (
                        <Switch
                            label="Show child resources"
                            checked={showChildren}
                            onChange={(v) => {
                                setShowChildren(v.target.checked);
                                grid?.refresh();
                            }}
                        />
                    )
                }
                noSelect={noStartStop}
                key={configGeneration + 'IdleResourceGrid' + showChildren}
            />
            <IdleRuleDrawer
                query={query!}
                configData={configData}
                schemaSvc={schemaSvc}
                title={title}
                isOpen={rulesOpen}
                onClose={CancelConfigData}
                resourceType={resourceType}
                includeMemory={includeMemory}
                model={model}
                updateQuery={updateQuery}
                updateConfigData={updateConfigData}
                configSaved={incrementConfigGeneration}
            />
        </>
    );
}

function IdleRuleDrawer({
    query,
    configData,
    isOpen,
    onClose,
    schemaSvc,
    title,
    resourceType,
    includeMemory,
    model,
    updateQuery,
    updateConfigData,
    configSaved,
}: {
    query: IQueryExpr;
    configData: CWMetricsLabelConfig[];
    isOpen: boolean;
    onClose: () => void;
    schemaSvc?: SchemaService;
    title: string;
    resourceType: string | string[];
    includeMemory: boolean;
    model: ConfigAdministrationModel;
    updateQuery: (query: IQueryExpr) => void;
    updateConfigData: (configData: CWMetricsLabelConfig[]) => void;
    configSaved: () => void;
}) {
    const [hasChanges, setHasChanges] = useState(false);
    const [hasConfigDataChanges, setHasConfigDataChanges] = useState(false);
    const [hasFilterChanges, setHasFilterChanges] = useState(false);
    useEvent(model.isModifiedChanged, (value) => setHasChanges(value));
    useEvent(model.isConfigDataModified, (value) => setHasConfigDataChanges(value));
    useEvent(model.isFilterDataModified, (value) => setHasFilterChanges(value));
    const idleResourceService = useDi(IdleResourcesService);
    const schemaCache = useDi(ResourceSchemaCache);

    function onCancel() {
        setHasChanges(false);
        onClose();
    }

    const onSave = async () => {
        let saved = false;
        if (hasFilterChanges) {
            saved = await postCWMetricsConfigSaveCWMetricsConfigFilters({
                resourceType: resourceType as string,
                appType: PostCWMetricsConfigSaveCWMetricsConfigFiltersAppType.IdleResources,
                configData: JSON.stringify(query),
            });
        }

        if (hasConfigDataChanges) {
            saved = await postCWMetricsConfigSaveCWMetricsConfig({
                resourceType: resourceType as string,
                appType: PostCWMetricsConfigSaveCWMetricsConfigAppType.IdleResources,
                configData: JSON.stringify(configData.map(({ threshold, ...item }) => item)),
            });
            model.isResyncNeeded.emit(true);
            if (saved) {
                schemaCache.invalidate();
                configSaved();
            }
        }

        if (saved) {
            idleResourceService.invalidateCache();
            model.isModifiedChanged.emit(false);
            model.isRefreshNeeded.emit(true);
        }
        onClose();
    };
    return (
        <Drawer position="right" withCloseButton={false} size={600} opened={isOpen} onClose={onClose}>
            <PanelContent>
                <PanelHeader>
                    <Title order={4}>{title} - Filters</Title>

                    <CloseButton onClick={onClose} />
                </PanelHeader>
                <Divider />

                <PanelBody>
                    <IdleRuleCustomConfig
                        query={query}
                        configData={configData}
                        schemaSvc={schemaSvc}
                        model={model}
                        idleResourceService={idleResourceService}
                        resourceType={resourceType as string}
                        includeMemory={includeMemory}
                        updateQuery={updateQuery}
                        updateConfigData={updateConfigData}
                    />
                </PanelBody>

                <Divider />
                <PanelToolbar padding="sm">
                    <Button variant="outline" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button disabled={!hasChanges} onClick={onSave}>
                        Save
                    </Button>
                </PanelToolbar>
            </PanelContent>
            <Divider />
        </Drawer>
    );
}

function IdleRuleCustomConfig({
    query,
    configData,
    schemaSvc,
    model,
    idleResourceService,
    resourceType,
    includeMemory,
    updateQuery,
    updateConfigData,
}: {
    query: IQueryExpr;
    configData: CWMetricsLabelConfig[];
    schemaSvc?: SchemaService;
    model: ConfigAdministrationModel;
    idleResourceService: IdleResourcesService;
    resourceType: string;
    includeMemory: boolean;
    updateQuery: (query: IQueryExpr) => void;
    updateConfigData: (configData: CWMetricsLabelConfig[]) => void;
}) {
    const [disableResetQuery, setDisableResetQuery] = useState(false);
    const [disableResetConfigData, setDisableResetConfigData] = useState(false);
    const { idleResource } = useAuthZValues({ idleResource: { IdleResources: 'Manage' } });

    const [isNotAdmin, setIsNotAdmin] = useState(!idleResource ? true : false);

    useEffect(() => {
        checkResetState();
    }, []);

    const checkResetState = () => {
        const defaultQuery = idleResourceService.getDefaultQuery(resourceType);
        const defaultConfigData = idleResourceService.getDefaultConfigData(resourceType)?.cWMetricLabelConfig;
        setDisableResetQuery(JSON.stringify(defaultQuery) === JSON.stringify(query));
        setDisableResetConfigData(
            JSON.stringify(defaultConfigData!.map(({ threshold, aggregationType, namespace, metricName, ...item }) => item)) ===
                JSON.stringify(configData.map(({ threshold, aggregationType, namespace, metricName, ...item }) => item))
        );
    };

    const filters = useMemo(
        () =>
            'Operands' in query
                ? (query.Operands.filter((o: IQueryExpr) => 'Operation' in o && !['and', 'or'].includes(o.Operation)) as QueryExpr[])
                : [],
        [query]
    );

    const thresholdFields = useMemo(() => {
        return filters.map((filter) => {
            const fields = 'Operands' in filter ? filter.Operands : '';
            return {
                field: fields ? fields[0].Field : '',
                value: fields ? fields[1].Value : '',
            };
        });
    }, [query]);

    const updateFilterThreshold = useCallback(
        (e: number, label: string) => {
            const newQuery = { ...query };
            newQuery.Operands = newQuery.Operands.map((operand: { Operands: any }) => {
                if ('Operands' in operand) {
                    const fields = operand.Operands;
                    if (
                        fields &&
                        'Operands' in fields[0] &&
                        fields[0].Operands[0].Field.toLocaleLowerCase() === 'resourcetype' &&
                        fields[0].Operands[1].Value.toLocaleLowerCase().includes('elb')
                    ) {
                        return {
                            ...operand,
                            Operands: [fields[0], { ...fields[1], Value: e }],
                        };
                    } else if (fields && (fields[0].Field as string).toLocaleLowerCase().includes(label.toLocaleLowerCase())) {
                        return {
                            ...operand,
                            Operands: [fields[0], { ...fields[1], Value: e }],
                        };
                    }
                }
                return operand;
            });
            updateQuery(newQuery);
        },
        [query, updateQuery]
    );

    const updateConfigDataFields = useCallback(
        (e: any, label: string, field: string) => {
            const newConfigData = [...configData];
            newConfigData.map((config) => {
                if (config.label === label) {
                    configData.map((config) => {
                        if (config.label === label) {
                            config[field as keyof CWMetricsLabelConfig] = e;
                            if (field === 'pollingInterval' && e === 60 && config.lookBackDate! > 15) {
                                config.lookBackDate = 15;
                            }
                            if (field === 'pollingInterval' && e === 300 && config.lookBackDate! > 63) {
                                config.lookBackDate = 63;
                            }
                        }
                        return config;
                    });
                    updateConfigData(configData);
                }
                return config;
            });
            updateConfigData(newConfigData);
        },
        [configData, updateConfigData]
    );

    const updateToDefault = useCallback(() => {
        model.changeFiltersToDefault.emit(true);
        if (!isNotAdmin) {
            model.changeConfigDataToDefault.emit(true);
        }
    }, []);

    const disableDefaultButton = useMemo(() => {
        return disableResetQuery && (!isNotAdmin ? disableResetConfigData : true);
    }, [disableResetQuery, disableResetConfigData]);

    useEffect(() => {
        checkResetState();
    }, [query, configData]);

    const pollingComponent = (config: CWMetricsLabelConfig) => (
        <Group style={{ marginBottom: '-8px', flexWrap: 'nowrap' }}>
            <Text
                style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    fontSize: '15px',
                    color: isNotAdmin ? '#D3D3D3' : '#475467',
                    flex: '0 0 150px',
                    textAlign: 'right',
                }}
            >
                Period
            </Text>
            <Select
                data-atid="IdleResourcesConfigDataSelect"
                value={idleResourceService.pollingIntervals.find((i) => i.value === config.pollingInterval)?.value.toString()}
                onChange={(e) => {
                    updateConfigDataFields(Number(e) as number, config.label as string, 'pollingInterval');
                }}
                placeholder={idleResourceService.pollingIntervals.find((i) => i.value === config.pollingInterval)?.label}
                data={idleResourceService.pollingIntervals.map((i) => {
                    return { value: String(i.value), label: i.label };
                })}
                disabled={isNotAdmin}
                style={{ width: '302px' }}
            />
            {isNotAdmin && <i className="ti ti-lock" style={{ fontSize: '20px' }}></i>}
        </Group>
    );

    const lookBackDateComponent = (config: CWMetricsLabelConfig) => (
        <Group style={{ marginBottom: '-8px', flexWrap: 'nowrap' }}>
            <Text
                style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    fontSize: '15px',
                    color: isNotAdmin ? '#D3D3D3' : '#475467',
                    flex: '0 0 150px',
                    textAlign: 'right',
                }}
            >
                Lookback Date
            </Text>
            <TextInput
                type="number"
                step={5}
                disabled={isNotAdmin}
                max={config.pollingInterval! < 61 ? 15 : config.pollingInterval! < 301 ? 63 : 455}
                min={1}
                style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    fontSize: '15px',
                    color: '#475467',
                    width: '245px',
                }}
                rightSection={<span style={{ paddingRight: '10px' }}>days</span>}
                value={config.lookBackDate!}
                onChange={(e) => {
                    const value = Number(e.target.value);
                    const max = config.pollingInterval! < 61 ? 15 : config.pollingInterval! < 301 ? 63 : 455;
                    if (value <= max) {
                        updateConfigDataFields(value as number, config.label as string, 'lookBackDate');
                    }
                }}
            />
            {isNotAdmin && <i className="ti ti-lock" style={{ fontSize: '20px' }}></i>}
        </Group>
    );

    const statComponent = (config: CWMetricsLabelConfig) => (
        <Group style={{ marginBottom: '-8px', flexWrap: 'nowrap' }}>
            <Text
                style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    fontSize: '15px',
                    color: isNotAdmin ? '#D3D3D3' : '#475467',
                    flex: '0 0 150px',
                    textAlign: 'right',
                }}
            >
                Stat
            </Text>
            <Select
                data-atid="IdleResourcesConfigDataSelect"
                value={idleResourceService.stats.find((i) => i.value === config.stat)?.label}
                onChange={(e) => {
                    updateConfigDataFields(e as string, config.label as string, 'stat');
                }}
                placeholder={idleResourceService.stats.find((i) => i.value === config.stat)?.label}
                data={idleResourceService.stats.map((i) => {
                    return { value: String(i.value), label: i.label };
                })}
                disabled={isNotAdmin}
                style={{ width: '322px' }}
            />
            {isNotAdmin && <i className="ti ti-lock" style={{ fontSize: '20px' }}></i>}
        </Group>
    );

    const labelComponent = (config: CWMetricsLabelConfig, i: number) => {
        if ((config.label as string) === 'SumOfRequests' && i === 0) {
            return 'SumOfRequests ELBV1';
        } else if ((config.label as string) === 'SumOfRequests' && i === 1) {
            return 'SumOfRequests ELBV2';
        } else {
            return config.label;
        }
    };

    const fieldInfo = useMemo(() => (schemaSvc ? new SchemaFieldNameProvider(schemaSvc) : undefined), [schemaSvc]);
    return (
        <>
            {!fieldInfo ? (
                <LoadingOverlay visible />
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outline" onClick={updateToDefault} disabled={disableDefaultButton}>
                            Default Filters
                        </Button>
                    </div>
                    <Group>
                        {configData
                            ? configData.map((config, i) =>
                                  !includeMemory && config.label === 'MemoryUtilization' ? (
                                      <></>
                                  ) : (
                                      ((config.threshold = thresholdFields.filter((f) =>
                                          (f.field as string).toLowerCase().includes((config.label! as string).toLowerCase())
                                      )[0]?.value),
                                      (
                                          <Observer
                                              key={i}
                                              render={() => {
                                                  return (
                                                      <Box component="fieldset" style={{ borderRadius: '10px', borderColor: '#DOD5DD' }}>
                                                          <legend style={{ padding: '10px' }}>
                                                              {idleResourceService.formatLabel(labelComponent(config, i)!)}
                                                          </legend>
                                                          <Group style={{ paddingBottom: resourceType === 'EMR' ? '10px' : '0' }}>
                                                              {isNotAdmin ? (
                                                                  <>
                                                                      <Tooltip label="Please contact company admin to update" withArrow>
                                                                          {pollingComponent(config)}
                                                                      </Tooltip>
                                                                      <Tooltip label="Please contact company admin to update" withArrow>
                                                                          {lookBackDateComponent(config)}
                                                                      </Tooltip>
                                                                      <Tooltip label="Please contact company admin to update" withArrow>
                                                                          {statComponent(config)}
                                                                      </Tooltip>
                                                                  </>
                                                              ) : (
                                                                  <>
                                                                      {pollingComponent(config)}
                                                                      {lookBackDateComponent(config)}
                                                                      {statComponent(config)}
                                                                  </>
                                                              )}
                                                              {resourceType !== 'EMR' && (
                                                                  <Group style={{ flexWrap: 'nowrap' }}>
                                                                      <Text
                                                                          style={{
                                                                              fontFamily: 'Open Sans',
                                                                              fontWeight: '500',
                                                                              fontSize: '15px',
                                                                              color: '#475467',
                                                                              flex: '0 0 150px',
                                                                              textAlign: 'right',
                                                                          }}
                                                                      >
                                                                          Threshold
                                                                      </Text>
                                                                      <NumberInput
                                                                          value={config.threshold as unknown as number}
                                                                          onChange={(e) => {
                                                                              updateFilterThreshold(e as number, config.label as string);
                                                                          }}
                                                                          style={{ width: '278px' }}
                                                                      />
                                                                  </Group>
                                                              )}
                                                          </Group>
                                                      </Box>
                                                  );
                                              }}
                                          />
                                      ))
                                  )
                              )
                            : null}
                    </Group>
                </>
            )}
        </>
    );
}

export function IdleRunningStatusCell({ item, resourceType }: { item: BaseResource; resourceType: string }) {
    let runningStatus;
    switch (resourceType) {
        case 'EC2 On Demand':
            runningStatus = (item.RunningState as { Value: string })?.Value === 'running';
            break;
        case 'RDS':
            runningStatus = (item.ActivityStreamStatus as { Value: string })?.Value === 'running';
            break;
        case 'Redshift':
            runningStatus = item.ClusterStatus === 'resuming' || item.ClusterStatus === 'available';
            break;
    }
    return (
        <>
            {item.ResourceType == resourceType ? (
                runningStatus ? (
                    <Text style={{ marginTop: '5px' }}>Running</Text>
                ) : (
                    <Text style={{ marginTop: '5px', fontWeight: 'bolder' }}>Stopped</Text>
                )
            ) : (
                <></>
            )}
        </>
    );
}

export function IdleUsedByOtherResourceCell({ used }: { used: boolean }) {
    return <>{!used ? <Text style={{ marginTop: '5px' }}>No</Text> : <Text style={{ marginTop: '5px', fontWeight: 'bolder' }}>Yes</Text>}</>;
}
