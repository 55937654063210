import { ActionIcon, Anchor, Box, Card, Divider, Drawer, UnstyledButton } from '@mantine/core';
import { MapMonitoringHomeModel } from './MapMonitoringHome';
import { useEffect, useMemo, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, ColumnGroupConfig, DataColumnConfig, DataGridState, GridGroupByState, IDataSource } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { useTheme } from '@emotion/react';
import { useDi, useDiContainer } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { set } from 'date-fns';
import { BaseResource } from '@root/Components/Resources/ResourcesGrid';
import { SchemaService } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { Clearfix } from '@root/Design/Primitives';
import { X } from 'tabler-icons-react';

export interface WorkloadGridDataItem {
    workloadName: string;
    eligibleSpend: number;
    eligibleCount: number;
    ineligibleSpend: number;
    ineligibleCount: number;
    eligibleTaggedCorrectlyCount: number;
    outsidePeriodSpend: number;
    outsidePeriodCount: number;
    invalidResourceSpend: number;
    invalidResourceCount: number;
    overtaggedSpend: number;
    overtaggedCount: number;
    uniqueResourceTypesCount: number;
    taggedCorrectlyResourceCount: number;
    totalSpend: number;
    totalResourceCount: number;
    totalMAPTaggedCount: number;
}

export interface WorkloadDetailsGridDataItem {
    resourceType: string;
    resourceId: string;
    annualizedSpend: number;
    eligibility: string;
}

export function WorkloadSection({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const [showSidePanel, setshowSidePanel] = useState(false);
    const [gridData, setGridData] = useState<WorkloadGridDataItem[]>([]);
    const [barData, setBarData] = useState<{ workloadName: string; eligibleSpend: number; ineligibleSpend: number }[]>([]);
    const [workloadSelected, setWorkloadSelected] = useState<string | undefined>();
    const [grid, setGrid] = useState<DataGridModel>();
    const theme = useTheme();
    const fmtSvc = useDi(FormatService);
    const [detailsGridDataSource, setDetailsGridDataSource] = useState<IDataSource<BaseResource>>();
    const [detailsGrid, setDetailsGrid] = useState<DataGridModel>();
    const link = useLink();
    const { getMoveUrl, descend } = useNav();

    useMemo(async () => {
        const data = await homeModel.getWorkloadResourceData();
        const barStats = data.map((d) => {
            return {
                workloadName: d.workloadName,
                eligibleSpend: d.eligibleSpend,
                ineligibleSpend: d.outsidePeriodSpend + d.invalidResourceSpend + d.overtaggedSpend,
            };
        });

        const gridStats = data.map((d) => {
            return {
                workloadName: d.workloadName,
                eligibleSpend: d.eligibleSpend,
                eligibleCount: d.eligibleCount,
                eligibleTaggedCorrectlyCount: d.eligibleTaggedCorrectlyCount,
                outsidePeriodSpend: d.outsidePeriodSpend,
                outsidePeriodCount: d.outsidePeriodCount,
                invalidResourceSpend: d.invalidResourceSpend,
                invalidResourceCount: d.invalidResourceCount,
                overtaggedSpend: d.overtaggedSpend,
                overtaggedCount: d.overtaggedCount,
                uniqueResourceTypesCount: d.uniqueResourceTypesCount,
                taggedCorrectlyResourceCount: d.taggedCorrectlyResourceCount,
                totalSpend: d.eligibleSpend + d.outsidePeriodSpend + d.invalidResourceSpend + d.overtaggedSpend,
                totalResourceCount: d.eligibleCount + d.outsidePeriodCount + d.invalidResourceCount + d.overtaggedCount,
                totalMAPTaggedCount: d.eligibleTaggedCorrectlyCount + d.overtaggedCount,
                ineligibleSpend: d.outsidePeriodSpend + d.invalidResourceSpend + d.overtaggedSpend,
                ineligibleCount: d.outsidePeriodCount + d.invalidResourceCount + d.overtaggedCount,
            };
        });

        setGridData(gridStats);
        setBarData(barStats);
    }, [homeModel.contract]);

    const groupConfig: { [groupName: string]: ColumnGroupConfig } = {};
    groupConfig['Annualized Spend'] = { color: theme.colors.primary[3] };
    groupConfig['Resources'] = { color: theme.colors.warning[3] };
    groupConfig['MAP Tags'] = { color: theme.colors.success[3] };
    const columns = useMemo(() => {
        return [
            {
                header: 'Workload Name',
                type: 'string',
                accessor: (item) => item.workloadName,
                defaultWidth: 200,
                id: 'workloadName',
                sortField: 'workloadName',
                filter: { filterType: 'string', filterField: 'workloadName', name: 'Workload Name' },
            },
            {
                header: 'Total',
                accessor: (item) => item.totalSpend,
                type: 'number',
                defaultWidth: 100,
                id: 'totalSpend',
                sortField: 'totalSpend',
                groupName: 'Annualized Spend',
                formatter(item, value) {
                    return fmtSvc.formatMoneyNoDecimals(value);
                },
                exportOptions: {
                    header: 'Total Spend (Annualized)',
                },
                filter: { filterType: 'number', filterField: 'totalSpend', name: 'Total Spend' },
            },
            {
                header: 'Eligible',
                accessor: (item) => item.eligibleSpend,
                type: 'number',
                defaultWidth: 100,
                id: 'eligibleSpend',
                sortField: 'eligibleSpend',
                groupName: 'Annualized Spend',
                formatter(item, value) {
                    return fmtSvc.formatMoneyNoDecimals(value);
                },
                exportOptions: {
                    header: 'Eligible Spend (Annualized)',
                },
                filter: { filterType: 'number', filterField: 'eligibleSpend', name: 'Eligible Spend' },
            },
            {
                header: 'Ineligible',
                accessor: (item) => item.ineligibleSpend,
                type: 'number',
                defaultWidth: 100,
                id: 'ineligibleSpend',
                sortField: 'ineligibleSpend',
                groupName: 'Annualized Spend',
                formatter(item, value) {
                    return fmtSvc.formatMoneyNoDecimals(value);
                },
                exportOptions: {
                    header: 'Ineligible Spend (Annualized)',
                },
            },
            {
                header: 'Types',
                accessor: (item) => item.uniqueResourceTypesCount,
                type: 'number',
                defaultWidth: 100,
                id: 'uniqueResourceTypesCount',
                sortField: 'uniqueResourceTypesCount',
                groupName: 'Resources',
                exportOptions: {
                    header: 'Resource Types',
                },
                filter: { filterType: 'number', filterField: 'uniqueResourceTypesCount', name: 'Types' },
            },
            {
                header: 'Total',
                accessor: (item) => item.totalResourceCount,
                type: 'number',
                defaultWidth: 100,
                id: 'totalResourceCount',
                sortField: 'totalResourceCount',
                groupName: 'Resources',
                exportOptions: {
                    header: 'Resources Total',
                },
            },
            {
                header: 'Eligible',
                accessor: (item) => item.eligibleCount,
                type: 'number',
                defaultWidth: 100,
                id: 'eligibleCount',
                sortField: 'eligibleCount',
                groupName: 'Resources',
                exportOptions: {
                    header: 'Eligible Resources',
                },
                filter: { filterType: 'number', filterField: 'uniqueResourceTypesCount', name: 'Types' },
            },
            {
                header: 'Ineligible',
                accessor: (item) => item.ineligibleCount,
                type: 'number',
                defaultWidth: 100,
                id: 'ineligibleCount',
                sortField: 'ineligibleCount',
                groupName: 'Resources',
                exportOptions: {
                    header: 'Ineligible Resources',
                },
            },
            {
                header: 'Total',
                accessor: (item) => item.totalMAPTaggedCount,
                type: 'number',
                defaultWidth: 100,
                id: 'totalMAPTaggedCount',
                sortField: 'totalMAPTaggedCount',
                groupName: 'MAP Tags',
                exportOptions: {
                    header: 'MAP Tags Total',
                },
            },
            {
                header: 'Correctly Tagged',
                accessor: (item) => item.taggedCorrectlyResourceCount,
                type: 'number',
                defaultWidth: 100,
                id: 'taggedCorrectlyResourceCount',
                sortField: 'taggedCorrectlyResourceCount',
                groupName: 'MAP Tags',
                filter: { filterType: 'number', filterField: 'uniqueResourceTypesCount', name: 'Types' },
                exportOptions: {
                    header: 'MAP Tags Correct',
                },
            },
            {
                header: 'Overtagged',
                accessor: (item) => item.overtaggedCount,
                type: 'number',
                defaultWidth: 100,
                id: 'overtaggedCount',
                sortField: 'overtaggedCount',
                groupName: 'MAP Tags',
                filter: { filterType: 'number', filterField: 'overtaggedCount', name: 'Types' },
                exportOptions: {
                    header: 'MAP Tags Overtagged',
                },
            },
        ] as DataColumnConfig<WorkloadGridDataItem>[];
    }, [gridData]);

    const detailsGridColumns = useMemo(() => {
        return [
            {
                header: 'Resource Type',
                accessor: 'ResourceType',
                defaultWidth: 200,
                id: 'ResourceType',
                sortField: 'ResourceType',
            },
            {
                header: 'Resource ID',
                accessor: 'Id',
                defaultWidth: 400,
                id: 'Id',
                sortField: 'Id',
                cellRenderer: (item) => {
                    const filter = { Operation: 'eq', Operands: [{ Field: 'Id' }, { Value: item.Id }], description: 'Id' };
                    const state: DataGridState = {
                        columns: [],
                        filters: [filter],
                        sort: [],
                    };
                    const stateData = JSON.stringify(state);
                    return (
                        <Anchor {...link(getMoveUrl('map-resource-browser', { id: homeModel.contract?.Id?.toString() ?? '', state: stateData }))}>
                            {item.Id}
                        </Anchor>
                    );
                },
            },
            {
                header: 'Annualized Spend',
                accessor: 'ExtendedAnnualizedCost',
                defaultWidth: 150,
                id: 'ExtendedAnnualizedCost',
                sortField: 'ExtendedAnnualizedCost',
                formatter(item, value) {
                    return fmtSvc.formatMoneyNoDecimals(value);
                },
            },
            {
                header: 'Eligibility',
                defaultWidth: 100,
                id: 'eligibility',
                sortField: 'eligibility',

                cellRenderer: (item) => {
                    var isCorrectResourceType = homeModel.mapQuerySvc.validResourceTypes.includes(item.ResourceType!);
                    var isInContractRange = checkItemDates(item);
                    if (isCorrectResourceType && isInContractRange) return 'Eligible';
                    else return 'Ineligible';
                },
            },
        ] as ColumnConfig<BaseResource>[];
    }, [detailsGridDataSource]);

    useEffect(() => {
        if (workloadSelected === undefined) {
            closeSidePanel();
            setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
        }
    }, [workloadSelected]);

    function closeSidePanel() {
        setshowSidePanel(false);
        setWorkloadSelected(undefined);
    }

    function checkItemDates(item: BaseResource) {
        if (item.ParentCreateDate !== null && item.ParentCreateDate !== undefined) {
            return (
                new Date(item.ParentCreateDate) >= homeModel.mapQuerySvc.contractFrom &&
                new Date(item.ParentCreateDate) <= homeModel.mapQuerySvc.contractTo
            );
        } else {
            if (item.CreateDate === null || item.CreateDate === undefined) return false;

            return new Date(item.CreateDate!) >= homeModel.mapQuerySvc.contractFrom && new Date(item.CreateDate!) <= homeModel.mapQuerySvc.contractTo;
        }
    }

    const onRowClick = async (e: WorkloadGridDataItem) => {
        setshowSidePanel(true);
        setWorkloadSelected(e.workloadName);
        const dataSource = await homeModel.getWorkloadDetailsDataSource(e.workloadName, detailsGrid!);
        setDetailsGridDataSource(dataSource);
    };

    return (
        <Box ml={'md'}>
            {' '}
            <Card withBorder radius="lg" p="lg" sx={{ height: '400px', width: '98.5%' }}>
                Workload Annualized Spend
                {barData ? (
                    <ResponsiveBar
                        data={barData}
                        indexBy="workloadName"
                        keys={['eligibleSpend', 'ineligibleSpend']}
                        layout={'vertical'}
                        margin={{ left: 60, bottom: 50, top: 20 }}
                        colors={['#7db9d2', '#ef9c3b']}
                        valueFormat={'>-$,.2~f'}
                        axisLeft={{
                            format: '>-$,.2~f',
                            tickSize: 4,
                        }}
                        enableLabel={false}
                        enableGridY={false}
                        padding={0.7}
                        innerPadding={2}
                        borderRadius={4}
                        tooltipLabel={(l) => {
                            if (l.id === 'eligibleSpend') return 'Eligible Spend';
                            else if (l.id === 'ineligibleSpend') return 'Ineligible Spend';
                            else return '';
                        }}
                        legendLabel={(l) => {
                            if (l.id === 'eligibleSpend') return 'Eligible Spend';
                            else if (l.id === 'ineligibleSpend') return 'Ineligible Spend';
                            else return '';
                        }}
                        legends={[
                            {
                                anchor: 'top-right',
                                dataFrom: 'keys',
                                itemWidth: 130,
                                direction: 'column',
                                translateY: -20,
                                translateX: 20,
                                itemHeight: 18,
                            },
                        ]}
                    />
                ) : null}
            </Card>
            <Card withBorder radius="lg" p="lg" sx={{ marginTop: '20px', width: '98.5%' }}>
                Workload Annualized Spend
                {gridData ? (
                    <>
                        <Box sx={{ height: '600px' }}>
                            <DataGrid
                                dataSource={gridData}
                                columns={columns}
                                groupConfig={groupConfig}
                                displayMode="grid"
                                showHeaderGroups={true}
                                hideHeader={false}
                                showCount
                                showToolbar={false}
                                showRefresh
                                allowNonColumnFilters={true}
                                onRowClick={onRowClick}
                                onModelLoaded={setGrid}
                            />
                        </Box>
                    </>
                ) : null}
            </Card>
            {showSidePanel && workloadSelected && (
                <Drawer
                    opened={showSidePanel}
                    onClose={() => {
                        setWorkloadSelected(undefined);
                        setshowSidePanel(false);
                    }}
                    size={900}
                    padding={0}
                    position="right"
                    overlayOpacity={0.1}
                    withinPortal={false}
                    withCloseButton={false}
                    withOverlay={true}
                    shadow="md"
                    closeOnClickOutside={true}
                    closeOnEscape={true}
                >
                    <Box p="sm">
                        <h3 style={{ marginTop: '0px' }}>Workload Detail</h3>
                        <ActionIcon sx={{ right: '10px', top: '10px', position: 'absolute' }} onClick={closeSidePanel}>
                            <X />
                        </ActionIcon>
                        <Clearfix />
                        <Divider />
                    </Box>
                    <Box p="sm">
                        <h5>Name</h5>
                        <h4 style={{ marginTop: '-20px' }}>{workloadSelected}</h4>
                    </Box>
                    <Box p="sm" sx={{ width: '880px', margin: '0px auto', height: '800px' }}>
                        {showSidePanel && detailsGridDataSource && (
                            <DataGrid
                                dataSource={detailsGridDataSource!}
                                columns={detailsGridColumns}
                                displayMode="grid"
                                hideHeader={true}
                                showToolbar={false}
                                showRefresh={true}
                                hideMenu={true}
                                hideFilter={true}
                                allowGroupBy={true}
                                hideGlobalSearch={true}
                                allowNonColumnFilters={true}
                                onModelLoaded={setDetailsGrid}
                            />
                        )}
                    </Box>
                </Drawer>
            )}
        </Box>
    );
}
