import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { Group, Space, Switch, Tooltip, Text, LoadingOverlay, Button } from '@mantine/core';
import {
    getCompanyGetCompanyAppInfo,
    getCompanyGetCompanyFeatures,
    putCompanySaveCompanyApps,
    putCompanyToggleCompanyAppLocked,
} from '@apis/Customers';
import { AppFeatures, CompanyApps, CompanyInfo, PutCompanySaveCompanyAppsType } from '@apis/Customers/model';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { colorPalette } from '@root/Design/Themes';
import { Observer } from 'mobx-react';
import { AdminPageTitle, AdminPanel } from './Design';
import { useModals } from '@mantine/modals';
import { useDi, useDiComponent } from '@root/Services/DI';
import { AddSubscriptionModal } from '@root/Site/Support/Components/AddSubscriptionModal';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { SiteMenuCompanyService } from '@root/Components/Shell/SiteMenuCompanyService';
import { useEventValue } from '@root/Services/EventEmitter';
import { AppFeatureService } from '@root/Services/Customers/AppFeatureService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { TenantCacheInvalidator } from '@root/Services/Customers/TenantCacheInvalidator';

export interface AdminAppAccessProps {
    model: CompanyAdministrationPanelModel;
    companyInfo: CompanyInfo;
}

export const AdminAppAccess = (props: AdminAppAccessProps) => {
    const appFeatureSvc = useDi(AppFeatureService);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);

    const [apps, setApps] = useState<CompanyApps>();
    const [allSelfAppFeatures, setAllSelfAppFeatures] = useState<AppFeatures[]>([]);
    const [allChildAppFeatures, setAllChildAppFeatures] = useState<AppFeatures[]>([]);

    const contextSvc = useDi(CompanyContextService);
    const tenantCache = useDi(TenantCacheInvalidator);
    const parentCompany = contextSvc.parentCompany;
    const [isPlatformSupport, setIsPlatformSupport] = useState<boolean>(parentCompany?.Type === 'PlatformSupport');
    const siteMenuContextSvc = useDi(SiteMenuCompanyService);
    const rootCompany = useEventValue(siteMenuContextSvc.rootCompany);
    const company = useCompany();

    const loadCompanyApps = async () => {
        if (props.companyInfo && rootCompany != null) {
            (async () => {
                var companyApps = await getCompanyGetCompanyAppInfo();
                setApps(companyApps);
            })();
        }
    };

    const reload = async () => {
        setIsLoading(true);
        Promise.all([loadCompanyApps()]).then(() => {
            const allSelfFeatures = appFeatureSvc.getFeatures().filter((f) => f.CompanyType == (company?.Type == null ? 'Customer' : company?.Type));
            setAllSelfAppFeatures(allSelfFeatures ?? []);

            if (company?.Type == 'Msp') {
                const allChildFeatures = appFeatureSvc.getFeatures().filter((f) => f.CompanyType == 'Customer');
                setAllChildAppFeatures(allChildFeatures ?? []);
            }

            setIsLoading(false);
        });
    };

    useEffect(() => {
        reload();
    }, [props.companyInfo.IsActive]);

    const modals = useModals();
    const DiContainer = useDiComponent();
    const openAddSubscriptionModal = () => {
        const id = modals.openModal({
            title: (
                <Text sx={{ padding: '0px 0px 0px 16px', margin: '0px' }} size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Add Subscription
                </Text>
            ),
            children: (
                <DiContainer>
                    <AddSubscriptionModal
                        onSubmit={() => {
                            props.model.isSubscriptionUpdated.emit(true);
                            modals.closeModal(id);
                        }}
                        onClose={() => {
                            modals.closeModal(id);
                        }}
                        companyId={props.companyInfo.CompanyId!}
                    />
                </DiContainer>
            ),
            size: '600px',
        });
    };

    const invalidateCacheAndReload = () => {
        tenantCache.invalidate(parentCompany?.Id ?? 0, company?.Id ?? 0);
        reload();
    };

    const syncChargebeeSubscriptions = async () => {
        (async () => {
            setIsSyncing(true);
            await getCompanyGetCompanyFeatures();
            setIsSyncing(false);
            reload();
        })();
    };

    return (
        <AdminPanel>
            <LoadingOverlay visible={isLoading} />
            <Group position="apart">
                <AdminPageTitle style={{ display: 'inline-block' }}>App Access</AdminPageTitle>
                {isPlatformSupport && (
                    <Group>
                        <Button
                            leftIcon={<i className="ti ti-plus" />}
                            variant="outline"
                            data-atid="AddSubscriptionButton"
                            onClick={() => {
                                openAddSubscriptionModal();
                            }}
                        >
                            Add Subscription
                        </Button>
                        <Button
                            leftIcon={<i className="ti ti-refresh" />}
                            variant="outline"
                            data-atid="AddSubscriptionButton"
                            onClick={() => {
                                syncChargebeeSubscriptions();
                            }}
                            disabled={isSyncing}
                        >
                            Sync Chargebee Subscriptions
                        </Button>
                    </Group>
                )}
            </Group>
            <Space h="sm" />
            {isLoading || !allSelfAppFeatures || !apps ? (
                <></>
            ) : (
                <AllAppsContainer>
                    {allSelfAppFeatures
                        .filter((f) => f.Name != 'System' && !f.Name!.endsWith('Companies'))
                        .map((s) => (
                            <Observer
                                render={() => (
                                    <CompanyApplication
                                        type="Self"
                                        companyApplications={[...new Set(apps.SelfApps?.map((item) => item.Id!))]}
                                        appName={s.Name!}
                                        appId={s.Id!}
                                        features={s.Features?.sort((a, b) => (a.Name! > b.Name! ? 1 : -1)).map((m) => m.Name!) ?? []}
                                        isActive={(apps.SelfApps?.map((m) => m.Name).indexOf(s.Name!) ?? -1) > -1}
                                        onChange={invalidateCacheAndReload}
                                        appFromParent={
                                            parentCompany?.Type == 'PlatformSupport' ||
                                            company?.Type == 'Msp' ||
                                            apps!.ParentApps!.map((m) => m.Name).indexOf(s.Name!) > -1
                                        }
                                        isLoading={isLoading}
                                        canBeLocked={parentCompany.Type == 'PlatformSupport'}
                                        isLocked={apps.SelfApps?.find((m) => m.Id == s.Id)?.IsLocked ?? false}
                                    />
                                )}
                            ></Observer>
                        ))}
                </AllAppsContainer>
            )}

            {company?.Type == 'Msp' ? (
                <>
                    <AdminPageTitle style={{ display: 'inline-block' }}>Customer App Access</AdminPageTitle>
                    <Space h="sm" />
                    {!allChildAppFeatures || !apps ? (
                        <></>
                    ) : (
                        <AllAppsContainer>
                            {allChildAppFeatures!
                                .filter((f) => f.Name != 'System')
                                .map((s) => (
                                    <Observer
                                        render={() => (
                                            <CompanyApplication
                                                type="Child"
                                                companyApplications={[...new Set(apps.ChildApps?.map((item) => item.Id!))]}
                                                appName={s.Name!}
                                                appId={s.Id!}
                                                features={s.Features?.sort((a, b) => (a.Name! > b.Name! ? 1 : -1)).map((m) => m.Name!) ?? []}
                                                isActive={(apps.ChildApps?.map((m) => m.Name).indexOf(s.Name!) ?? -1) > -1}
                                                onChange={invalidateCacheAndReload}
                                                appFromParent={
                                                    parentCompany?.Type == 'PlatformSupport' ||
                                                    company?.Type == 'Msp' ||
                                                    apps!.ParentApps!.map((m) => m.Name).indexOf(s.Name!) > -1
                                                }
                                                isLoading={isLoading}
                                                canBeLocked={parentCompany.Type == 'PlatformSupport'}
                                                isLocked={apps.ChildApps?.find((m) => m.Id == s.Id)?.IsLocked ?? false}
                                            />
                                        )}
                                    ></Observer>
                                ))}
                        </AllAppsContainer>
                    )}
                </>
            ) : (
                <></>
            )}
        </AdminPanel>
    );
};

export function CompanyApplication({
    type,
    companyApplications,
    appId,
    appName,
    features,
    isActive,
    onChange,
    isLoading,
    appFromParent,
    canBeLocked,
    isLocked,
}: {
    type: PutCompanySaveCompanyAppsType;
    companyApplications: number[];
    appId: number;
    appName: string;
    features: string[];
    isActive: boolean;
    onChange: () => void;
    appFromParent: boolean;
    isLoading: boolean;
    canBeLocked: boolean;
    isLocked: boolean;
}) {
    const changeApplication = async (appId: number, isActive: boolean) => {
        if (isActive) {
            //Add a new application
            companyApplications?.push(appId);
        } else {
            //Remove an existing application
            companyApplications = companyApplications?.filter((f) => f != appId);
        }

        await putCompanySaveCompanyApps(companyApplications, { type: type });
        onChange();
    };

    const toggleLock = async (appId: number, isLocked: boolean) => {
        await putCompanyToggleCompanyAppLocked({ type: type, appId: appId, isLocked: isLocked });
        onChange();
    };

    return (
        <>
            <AppContainer>
                {appFromParent ? (
                    <Space w={40} />
                ) : isActive ? (
                    <KeyContainer>
                        <Tooltip withArrow label="Access granted from a direct subscription" withinPortal>
                            <i className="ti ti-key"></i>
                        </Tooltip>
                    </KeyContainer>
                ) : (
                    <Space w={40} />
                )}
                <SwitchContainer>
                    {!appFromParent || isLoading ? (
                        <Tooltip label="Cannot grant access to this application">
                            <div style={{ width: '40px', textAlign: 'center' }}>---</div>
                        </Tooltip>
                    ) : (
                        <Switch
                            key={appName}
                            id={appName}
                            checked={isActive}
                            onChange={(event) => changeApplication(appId, event.currentTarget.checked)}
                            disabled={!appFromParent || isLoading}
                        />
                    )}
                </SwitchContainer>
                {canBeLocked && isActive ? (
                    isLocked ? (
                        <Tooltip label="This app is locked to prevent disabling">
                            <Locked className="ti ti-lock" onClick={() => toggleLock(appId, false)} />
                        </Tooltip>
                    ) : (
                        <Tooltip label="Lock this app to prevent disabling">
                            <Unlocked className="ti ti-lock-open" onClick={() => toggleLock(appId, true)} />
                        </Tooltip>
                    )
                ) : (
                    <div style={{ width: '32px' }} />
                )}
                <AppFeaturesContainer>
                    <AppNameContainer>{appName}</AppNameContainer>
                    <FeaturesContainer paddingTop={features.length > 1 ? 8 : 0}>
                        {features.length > 1 ? features.map((m) => <div> - {m}</div>) : <></>}
                    </FeaturesContainer>
                </AppFeaturesContainer>
            </AppContainer>
            {appName == 'System' ? <Space h={12}></Space> : <></>}
        </>
    );
}

export const Locked = styled.i`
    margin-left: 16px;
    margin-top: 4px;
    cursor: pointer;
    font-size: 16px;
    color: ${(p) => p.theme.colors.success[6]};
    font-weight: bold;
`;

export const Unlocked = styled.i`
    margin-left: 16px;
    margin-top: 4px;
    cursor: pointer;
    font-size: 16px;
    color: ${(p) => p.theme.colors.error[6]};
    font-weight: bold;
`;

export const AllAppsContainer = styled.div`
    margin: 16px;
`;

export const AppContainer = styled.div`
    display: flex;
`;

export const SwitchContainer = styled.div`
    height: inherit;
    margin-top: 2px;
`;

export const AppFeaturesContainer = styled.div`
    height: inherit;
    margin-left: 16px;
`;

export const VerticalBar = styled.div<{ barHeight: string }>`
    height: ${(p) => p.barHeight};
    width: 20px;
    border-right: 5px solid ${(p) => p.theme.colors.gray[4]};
`;

export const AppNameContainer = styled.div`
    font-size: 15px;
`;

export const FeaturesContainer = styled.div<{ paddingTop: number }>`
    padding-top: ${(p) => p.paddingTop + 'px'};
    padding-bottom: 16px;
    color: ${(p) => p.theme.colors.gray[5]};
`;

export const KeyContainer = styled.div`
    width: 16px;
    height: 16px;
    font-size: 20px;
    margin-right: 24px;
    margin-top: 10px;
    transform: rotate(-135deg);
    color: ${(p) => p.theme.colors.success[6]};
`;

export const AppAccessInfo = styled.div`
    text-align: right;
    color: darkblue;
`;
