import { ResponsivePie } from '@nivo/pie';
import { ReactNode, useCallback, useMemo } from 'react';
import { chartColors, ChartMargin } from './Common';
import { StandardChartProps } from './Models';
import { LegendProps } from '@nivo/legends';
import { ValueFormat } from '@nivo/core';
import styled from '@emotion/styled';
import { Score } from '@root/Design/Primitives';
import { useElementSize } from '@mantine/hooks';
import './styles.css';
import { ChartWrapper } from './Design';

export function PieChart<T extends Record<string, string | number>>(props: PieChartProps<T>) {
    const getId = useCallback((item: T) => props.groups.map((g) => `${item[g]}`).join('-'), [props.groups]);
    const bindData = useMemo(() => transformPieData(props), [props.data, props.groups, props.values[0]]);
    const { ref: sizeRef, width, height } = useElementSize();
    const margin = { left: 25, bottom: 25, top: 25, right: 25, ...props.settings?.margin };
    const innerDiameter = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) * 0.6;

    const responsivePie = (
        <>
            <ResponsivePie
                data={bindData}
                id={getId}
                tooltip={props.settings?.hideToolTip ? () => <></> : undefined}
                value={props.values[0] as string}
                colors={props.settings?.chartColors ?? chartColors}
                valueFormat={
                    props.settings?.valueFormat == undefined
                        ? '>-,'
                        : props.settings?.valueFormat === 'money'
                        ? ' >-$,.2f'
                        : props.settings?.valueFormat === 'money-whole'
                        ? ' >-$,.0f'
                        : props.settings?.valueFormat === 'percent'
                        ? ' >-.0%'
                        : props.settings?.valueFormat
                }
                innerRadius={props.settings?.innerRadius ?? 0.6}
                padAngle={0.5}
                cornerRadius={props.settings?.cornerRadius ?? 5}
                enableArcLinkLabels={props.settings?.enableArcLinkLabels ?? true}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={props.settings?.enableArcLabels ?? true}
                arcLinkLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.2]],
                }}
                margin={margin}
                legends={props.settings?.legend}
            />
            <PositionWrapper chartMargin={margin} width={innerDiameter}>
                {props.settings?.showTotal && (
                    <Score
                        titlePos={props.settings.totalLabelPosition ?? 'top'}
                        title={props.settings?.totalLabel ?? ''}
                        value={props.settings?.total ?? ''}
                    />
                )}
            </PositionWrapper>
            {props.settings?.renderCenter && (
                <CenterWrapper chartMargin={margin} width={innerDiameter}>
                    {props.settings?.renderCenter?.()}
                </CenterWrapper>
            )}
        </>
    );

    if (!props.settings?.noWrapper) {
        return <ChartWrapper className="chartWrapper">{responsivePie}</ChartWrapper>;
    } else {
        return responsivePie;
    }
}
export interface PieChartSettings {
    noWrapper?: boolean;
    margin?: ChartMargin;
    threshold?: number;
    topN?: number;
    valueFormat?: ValueFormat<number, void> | undefined | 'money' | 'money-whole';
    legend?: LegendProps[] | undefined;
    enableArcLinkLabels?: boolean | undefined;
    showTotal?: boolean | undefined;
    renderCenter?: () => ReactNode;
    total?: string | undefined;
    totalLabel?: string | undefined;
    enableArcLabels?: boolean | undefined;
    chartColors?: string[] | undefined;
    totalLabelPosition?: 'top' | 'bottom' | undefined;
    hideToolTip?: boolean | undefined;
    innerRadius?: number | undefined;
    cornerRadius?: number | undefined;
}
interface PieChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: PieChartSettings;
}

function transformPieData<T extends Record<string, string | number>>(props: PieChartProps<T>): T[] {
    let result: T[] = [];
    const pieGroup = props.groups[0] ?? '';
    const valueKey = props.values[0] ?? '';
    let thresholdTotal = 0;
    let totalValue = 0;
    const threshold = props.settings?.threshold ? props.settings?.threshold : 0;
    if (props.settings?.valueFormat === 'percent') {
        props.data.forEach((item) => {
            totalValue += item[valueKey] as number;
        });
    }
    for (const item of props.data) {
        const id = (item[pieGroup] || '') as string;
        const value = (item[valueKey] || 0) as number;
        if (value > threshold) {
            const sliceValue = props.settings?.valueFormat === 'percent' ? value / totalValue : value;
            const slice: T = { [pieGroup]: id, [valueKey]: sliceValue } as unknown as T;
            result.push(slice);
        } else {
            thresholdTotal += value;
        }
    }

    if (thresholdTotal != 0) {
        const threshholdValue = props.settings?.valueFormat === 'percent' ? thresholdTotal / totalValue : thresholdTotal;
        const thresholdSlice = { [pieGroup]: 'Other', [valueKey]: threshholdValue } as unknown as T;
        result.push(thresholdSlice);
    }

    result.sort((a, b) => (b[valueKey] as number) - (a[valueKey] as number));

    if (props.settings?.topN) {
        result.splice(props.settings.topN);
    }
    return result;
}

const PositionWrapper = styled.div<{ chartMargin: ChartMargin; width: number }>`
    position: absolute;
    width: ${(p) => p.width - 16}px;
    margin-left: ${(p) => p.chartMargin.left}px;
    margin-right: ${(p) => p.chartMargin.right}px;
    margin-top: ${(p) => p.chartMargin.top}px;
    margin-bottom: ${(p) => p.chartMargin.bottom}px;
`;

const CenterWrapper = styled.div<{ chartMargin: ChartMargin; width: number }>`
    position: absolute;
    height: ${(p) => p.width - 16}px;
    width: ${(p) => p.width - 16}px;
    margin-left: ${(p) => p.chartMargin.left}px;
    margin-right: ${(p) => p.chartMargin.right}px;
    margin-top: ${(p) => p.chartMargin.top}px;
    margin-bottom: ${(p) => p.chartMargin.bottom}px;
`;
