import { getCompanyGetCompanyBySystemName, getCompanyGetCurrentCompany, getUserGetMyCompanies } from '@apis/Customers';
import { Company } from '@apis/Customers/model';
import { BasicApi } from '@root/Services/BasicApi';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { TenantCacheInvalidator } from '@root/Services/Customers/TenantCacheInvalidator';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { ConsumedRouteService } from '@root/Services/Router/BasicRouteLoader';
import { Router } from '@root/Services/Router/Router';
import { System } from '@root/Services/System';
import { UnknownUser } from '@root/Site/Error/UnknownUser';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { inject, singleton } from 'tsyringe';
import { SiteMenuCompanyService } from '../Shell/SiteMenuCompanyService';

@singleton()
export class CompanyTenantPrereqService {
    private _companyId?: number;
    public get companyId() {
        return this._companyId;
    }

    public constructor(
        @inject(BasicApi) private readonly api: BasicApi,
        @inject(TenantCacheInvalidator) private readonly cacheInvalidator: TenantCacheInvalidator
    ) {
        this.api.registerPrerequestHandler((_, request) => {
            if (this._companyId) {
                (request.headers as Record<string, any>)['x-tenant-id'] = this._companyId;
            }
        });
    }

    public handleCacheInvalidate(callback: (companyId: number) => void) {
        return this.cacheInvalidator.listen(callback);
    }

    public setCompanyId(nextCompanyId?: number) {
        const current = this._companyId;
        this._companyId = nextCompanyId;
        return () => {
            this._companyId = current;
        };
    }

    public withCompanyId<T>(nextCompanyId: number, callback: () => T) {
        const unset = this.setCompanyId(nextCompanyId);
        let result: T;
        try {
            result = callback();
        } finally {
            unset();
        }
        return result;
    }

    public async getCompanyById(companyId: number) {
        let unset = () => {};
        let companyTask: Promise<Company | undefined> | undefined = undefined;
        try {
            unset = this.setCompanyId(companyId);
            companyTask = getCompanyGetCurrentCompany();
        } finally {
            unset();
        }
        return await companyTask;
    }
}

export function CompanyContent({ children }: { children: () => ReactNode }) {
    const container = useDiContainer();
    const [ready, setReady] = useState(false);
    const [hasCompanies, setHasCompanies] = useState(true);
    const consumedRoute = useDi(ConsumedRouteService);
    const router = useDi(Router);
    const route = useEventValue(router.route);
    const system = useDi(System);
    const companyTenantSvc = useMemo(() => container.resolve(CompanyTenantPrereqService), []);
    const siteReady = useEventValue(system.siteReady);
    const siteMenuCompanySvc = useDi(SiteMenuCompanyService);

    const companyName = useMemo(() => {
        const routeStart = router.getCurrentRoute()?.newRoute[0];

        return routeStart?.name ?? '';
    }, [route]);
    useEffect(() => {
        return () => {
            consumedRoute.depth = 0;
        };
    }, []);
    useEffect(() => {
        if (companyName) {
            consumedRoute.depth = 1;
        } else {
            consumedRoute.depth = 0;
        }
    }, [companyName]);
    useEffect(() => {
        setReady(false);
        let companyId: number | undefined;
        let disposer: (() => void) | null = null;
        if (siteReady) {
            (async () => {
                const companies = await getUserGetMyCompanies();
                const lcCompany = companyName?.toLocaleLowerCase();
                const company = !lcCompany
                    ? undefined
                    : companies.find((c) => c.DatabaseName?.toLocaleLowerCase() === lcCompany) ??
                      (await getCompanyGetCompanyBySystemName({ systemName: companyName }));
                companyId = company ? company.Id : undefined;
                if (companyId) {
                    container.register(ICompanyContextToken, { useValue: company });
                    siteMenuCompanySvc.rootCompany.emit(company);
                    disposer = companyTenantSvc.setCompanyId(companyId);
                    setReady(true);
                } else {
                    const defaultCompany = companies.find((c) => !!c.DatabaseName);
                    if (defaultCompany) {
                        if (lcCompany.includes('chargebee-subscription')) {
                            router.navigate(
                                '/' +
                                    defaultCompany.DatabaseName!.toLocaleLowerCase() +
                                    `/settings/subscription/subscriptionmanage@subscriptionId:${getSubscriptionId(router)}`
                            );
                        } else {
                            router.navigate('/' + defaultCompany.DatabaseName!.toLocaleLowerCase() + '/landing');
                        }
                    } else {
                        setHasCompanies(false);
                    }
                    container.register(ICompanyContextToken, { useValue: undefined });
                }
            })();
        }
        return () => disposer?.();
    }, [companyName?.toLocaleLowerCase(), setReady, siteReady]);

    return <>{ready ? children() : !hasCompanies ? <UnknownUser /> : <></>}</>;
}

export function useCompany() {
    return useDi(ICompanyContextToken) as Company | undefined;
}

function getSubscriptionId(router: Router) {
    const routeStart = router.getCurrentRoute()?.newRoute[0];
    var routeData = routeStart!.data;
    let routeId = routeData.id;
    let lastUnderscoreIndex = routeId.lastIndexOf('_');
    if (lastUnderscoreIndex === -1) {
        return routeId;
    }
    return routeId.slice(lastUnderscoreIndex + 1);
}
