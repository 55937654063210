import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { Group, Tooltip, Text, Card, Box, Divider, Grid, useMantineTheme, Accordion, LoadingOverlay } from '@mantine/core';
import {
    getMspGetMspsForCompany,
    getSubscriptionGetAppAccessByCompany,
    getSubscriptionGetAppAccessByMspCompany,
    getSubscriptionGetSubscriptionsByCompany,
    getSubscriptionGetSubscriptionsByCompanyId,
    getSubscriptionGetSubscriptionTypes,
} from '@apis/Customers';
import { Company, CompanyInfo, CompanySubscriptionType, SubscriptionDetail, SubscriptionType } from '@apis/Customers/model';
import { useState, useEffect } from 'react';
import { AdminPageTitle, AdminPanel } from './Design';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';

export interface AdminAppAccessProps {
    model: CompanyAdministrationPanelModel;
    companyInfo: CompanyInfo;
    isSubscriptionUpdated: boolean;
}

interface MspInfo {
    company: Company;
    subscription?: SubscriptionDetail[];
}

export const SubscriptionDiagnosis = (props: AdminAppAccessProps) => {
    const [subscriptionTypes, setSubscriptionTypes] = useState<SubscriptionType[]>([]);
    const [companySubscriptions, setCompanySubscriptions] = useState<SubscriptionDetail[]>([]);
    const [appAccess, setAppAccess] = useState<string[]>([]);
    const [appAccessViaMsp, setAppAccessViaMsp] = useState<CompanySubscriptionType>();
    const [mspInfo, setMspInfo] = useState<MspInfo[]>([]);
    const formatSvc = useDi(FormatService);
    const theme = useMantineTheme();

    const loadSubscriptionDetails = () => {
        getSubscriptionGetSubscriptionTypes().then(setSubscriptionTypes);
        getSubscriptionGetSubscriptionsByCompany().then(setCompanySubscriptions);
        getSubscriptionGetAppAccessByCompany().then(setAppAccess);
    };

    useEffect(() => {
        loadSubscriptionDetails();
    }, []);

    useEffect(() => {
        if (props.companyInfo?.CompanyId) {
            getMspGetMspsForCompany({ companyId: props.companyInfo?.CompanyId }).then((msps) => {
                setMspInfo(msps.map((m) => ({ company: m })));
            });
            getSubscriptionGetAppAccessByMspCompany({ companyId: props.companyInfo?.CompanyId }).then(setAppAccessViaMsp);
        }
    }, [props.companyInfo]);

    useEffect(() => {
        setTimeout(() => {
            loadSubscriptionDetails();
        }, 3000);
        loadSubscriptionDetails();
    }, [props.isSubscriptionUpdated]);

    useEffect(() => {
        mspInfo.forEach((msp) => {
            getSubscriptionGetSubscriptionsByCompanyId({ companyId: msp.company.Id }).then((subs) => {
                msp.subscription = subs;
            });
        });
    }, [mspInfo]);

    const IsValidColumn = (isValid: boolean, value?: string, tooltip?: string) => {
        const color = isValid ? theme.colors!.success[6] : theme.colors!.error[6];
        const text = value ? value : isValid ? 'Valid' : 'Invalid';

        return tooltip ? (
            <>
                <Tooltip label={tooltip} position="bottom" withArrow transition="pop" zIndex={10000}>
                    <div style={{ color: color }}>
                        {text}{' '}
                        <Text size={14} style={{ display: 'inline-block', color: 'black' }}>
                            <i className={'ti ti-help'}></i>
                        </Text>{' '}
                    </div>
                </Tooltip>
            </>
        ) : (
            <div style={{ color: color }}>{text}</div>
        );
    };

    const hasInvalidSubscriptions = (subscriptions: SubscriptionDetail[]) => {
        return subscriptions?.filter((s) => !subscriptionTypes.some((st) => st.ExternalId === s.ExternalPlanId)).length === 0;
    };

    const GridRow = (columnName: string, columnValue: any) => {
        return (
            <>
                <Grid.Col span={3}>
                    <Text size={'sm'}>{columnName}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <Text size={'sm'}>{columnValue}</Text>
                </Grid.Col>
            </>
        );
    };

    const getAppAccessList = (apps: string[]) => {
        return [...new Set(apps)].sort((a, b) => (a! > b! ? 1 : -1)).join(', ');
    };

    return (
        <AdminPanel>
            <Group>
                <AdminPageTitle style={{ display: 'inline-block' }}>Customer Subscription Details</AdminPageTitle>
            </Group>
            <Box p="md">
                {!companySubscriptions?.length ? (
                    <Card shadow="sm" radius="md" withBorder mb={'xs'}>
                        <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>No Subscriptions Found</h3>
                    </Card>
                ) : (
                    <>
                        <Accordion>
                            {companySubscriptions?.map((s) => {
                                const isValid = subscriptionTypes.some((st) => st.ExternalId === s.ExternalPlanId && (s.DaysRemaining ?? -1) >= 0);

                                return (
                                    <Accordion.Item key={s.SubscriptionId} value={s.SubscriptionId!}>
                                        <Accordion.Control>{IsValidColumn(isValid, s.ExternalPlanId!)}</Accordion.Control>
                                        <Accordion.Panel>
                                            <Grid>
                                                {GridRow('Application Type', s.AppType)}
                                                {GridRow('Type', s.Type)}
                                                {GridRow('End Date', s.EndDate ? formatSvc.toShortDate(formatSvc.toLocalDate(s.EndDate)) : 'N/A')}
                                                {GridRow('Auto Renew', s.Renewing ? 'Yes' : 'No')}
                                                {GridRow('Status', IsValidColumn(isValid, s.Status ?? 'Unknown'))}
                                                {s.Type === 'Msp' ? (
                                                    <>{GridRow('Apps Granted', getAppAccessList(appAccess))}</>
                                                ) : (
                                                    <>{GridRow('Apps', s.SubscriptionTypeApps?.map((a) => a).join(', '))}</>
                                                )}
                                                {GridRow('Subscription State', IsValidColumn(isValid))}
                                            </Grid>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </>
                )}
            </Box>
            <Group>
                <AdminPageTitle style={{ display: 'inline-block' }}>Msp Subscription Details</AdminPageTitle>
            </Group>
            <Box p="md">
                {!mspInfo?.length ? (
                    <Card shadow="sm" radius="md" withBorder mb={'xs'}>
                        <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>No Msps Found</h3>
                    </Card>
                ) : (
                    <Accordion>
                        {mspInfo?.map((s) => (
                            <Accordion.Item key={s.company.Id} value={s.company.UniqueId!}>
                                <Accordion.Control>
                                    {IsValidColumn(hasInvalidSubscriptions(s.subscription!), s.company.CompanyName!)}
                                </Accordion.Control>
                                <Accordion.Panel>
                                    {s.subscription?.map((s) => (
                                        <Box p="md">
                                            <Grid>{GridRow('Subscription', s.ExternalPlanId!)}</Grid>
                                            <Divider />
                                            <Box p="md">
                                                <Grid>{GridRow('Apps Available', s.SubscriptionTypeApps?.join(', '))}</Grid>
                                                <Grid>
                                                    {GridRow(
                                                        'Subscription Status',
                                                        IsValidColumn(subscriptionTypes.some((st) => st.ExternalId === s.ExternalPlanId))
                                                    )}
                                                </Grid>
                                                <Grid>{GridRow('Application', s.AppType)}</Grid>
                                                <Grid>{GridRow('Type', s.Type)}</Grid>
                                            </Box>
                                        </Box>
                                    ))}
                                </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                )}
            </Box>
            <Group>
                <AdminPageTitle style={{ display: 'inline-block' }}>Overall Details</AdminPageTitle>
            </Group>
            <Box p="md">
                <Grid>{GridRow('Overall App Access', getAppAccessList(appAccess))}</Grid>
                <Grid>{GridRow('App Access through Msp', getAppAccessList(appAccessViaMsp?.Apps?.map((a) => a.App!) ?? []))}</Grid>
            </Box>
        </AdminPanel>
    );
};
