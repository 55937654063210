import { Recommendation, RecommendationOption } from '@apis/Recommendations/model';
import { Anchor, Box, Button, CloseButton, Divider, Group, Loader, Space, Stack, Text, useMantineTheme } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetailsTabs';
import { FillerSwitch } from '@root/Design/Filler';
import { PagePanel } from '@root/Design/Layout';
import { useDi, useDiComponent } from '@root/Services/DI';
import { useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AlertTriangle, ChevronRight, Trash } from 'tabler-icons-react';
import { IComparsonConfig } from './Models';
import { RecommendationOptionComparison } from './RecommendationOptionComparison';
import { RecommendationOptionGrid } from './RecommendationOptionGrid';

interface IRecommendationOptionContentProps {
    recommendation: Recommendation;
    comparison: IComparsonConfig;
    onSelectionChange: (option?: RecommendationOption | 'no change') => Promise<void>;
    selected?: RecommendationOption;
}

export function useRecommendationModal() {
    const modals = useModals();
    const DiComp = useDiComponent();
    const notificationSvc = useDi(NotificationService);
    const open = useCallback(
        (recommendation: Recommendation, comparison: IComparsonConfig, onSelect: (option?: RecommendationOption | 'no change') => Promise<void>) => {
            const title = recommendation.Type === 'Size' ? 'Resize Options' : 'Options';
            let modalId: string | undefined;
            const handleSelect = async (option?: RecommendationOption | 'no change') => {
                try {
                    await onSelect(option);
                    modals.closeModal(modalId!);
                } catch (e) {
                    notificationSvc.notify('Error', 'Failed to update selection, please try again or contact support', 'error', <AlertTriangle />);
                }
            };
            modalId = modals.openModal({
                title,
                children: (
                    <DiComp>
                        <RecommendationOptionModalContent onSelectionChange={handleSelect} recommendation={recommendation} comparison={comparison} />
                    </DiComp>
                ),
                size: 'xl',
            });
        },
        [modals]
    );
    return open;
}

function RecommendationOptionModalContent(props: IRecommendationOptionContentProps) {
    const theme = useMantineTheme();
    const { selectedOption, handleSelectionChange, toolbarState } = useRecommendationOptionContentState(props);

    return (
        <>
            <Box mx={-theme.spacing.lg} sx={{ height: 'calc(100vh - 250px)' }}>
                <Divider />
                <Box sx={{ height: '100%', overflow: 'auto' }}>
                    <RecommendationOptions
                        selected={selectedOption}
                        recommendation={props.recommendation}
                        comparison={props.comparison}
                        onSelectionChange={handleSelectionChange}
                    />
                </Box>
                <Divider />
            </Box>
            <Space h="lg" />
            <RecommendationOptionToolbar {...toolbarState} />
        </>
    );
}

interface IRecommendationOptionToolbarProps {
    recommendationExists: boolean;
    selecting?: 'remove' | 'apply';
    canRemove: boolean;
    remove: () => void;
    canSelect: boolean;
    apply: () => void;
    cancel?: () => void;
    doNotChange: () => void;
}
function RecommendationOptionToolbar({
    recommendationExists,
    selecting,
    canRemove,
    remove,
    canSelect,
    apply,
    cancel,
    doNotChange,
}: IRecommendationOptionToolbarProps) {
    return (
        <Group position="right">
            {cancel ? (
                <Button variant="outline" onClick={cancel}>
                    Cancel
                </Button>
            ) : null}
            <Button variant="outline" disabled={!!selecting} onClick={doNotChange}>
                Do Not Change
            </Button>
            {recommendationExists ? (
                <Button
                    leftIcon={selecting === 'remove' ? <Loader color="gray.0" size="sm" /> : undefined}
                    color="error"
                    disabled={!canRemove}
                    onClick={remove}
                >
                    Remove Selection
                </Button>
            ) : null}
            <Button
                rightIcon={selecting === 'apply' ? <Loader color="gray.0" size="sm" /> : undefined}
                color="primary"
                disabled={!canSelect}
                onClick={apply}
            >
                Apply Selection
            </Button>
        </Group>
    );
}

function useRecommendationOptionContentState({ recommendation, onSelectionChange }: IRecommendationOptionContentProps) {
    const optionsMatch = (a?: RecommendationOption, b?: RecommendationOption) =>
        a?.ChangeDetails?.SizeChange?.RecommendedSize === b?.ChangeDetails?.SizeChange?.RecommendedSize;
    const isOptionSelected = (o: RecommendationOption) => optionsMatch(o, recommendation.OptionSelected);
    const initialSelection = useMemo(
        () =>
            recommendation.OptionSelected?.ChangeDetails
                ? recommendation.Options?.find(isOptionSelected)
                : recommendation.LowestRiskHighestSavings
                ? recommendation.Options?.find((o) => optionsMatch(o, recommendation.LowestRiskHighestSavings))
                : undefined,
        [recommendation]
    );
    const [selectedOption, setSelectedOption] = useState(initialSelection);
    const [selecting, setSelecting] = useState<'remove' | 'apply'>();
    const handleSelectionChange = useCallback((option?: RecommendationOption) => {
        setSelectedOption(option);
    }, []);

    const canRemove = !selecting && !!recommendation.OptionSelected?.ChangeDetails;
    const canSelect = !selecting && !!selectedOption && (!recommendation.OptionSelected || !isOptionSelected(selectedOption));
    const select = useCallback(
        async (selecting: 'remove' | 'apply', selection?: RecommendationOption | 'no change') => {
            setSelecting(selecting);
            onSelectionChange(selection).finally(() => setSelecting(undefined));
        },
        [onSelectionChange]
    );
    const doNotChange = useCallback(() => select('apply', 'no change'), [select]);
    const apply = useCallback(() => select('apply', selectedOption), [selectedOption, select]);
    const remove = useCallback(() => select('remove', undefined), [select]);
    const recommendationExists = recommendation.OptionSelected != null;

    return {
        handleSelectionChange,
        selectedOption,
        toolbarState: { recommendationExists, canRemove, canSelect, apply, remove, selecting, doNotChange },
    };
}

function ResourceTitle({ resource }: { resource: Recommendation['ResourceMetadata'] }) {
    const fmtSvc = useDi(FormatService);
    return resource?.CloudPlatform === 'Aws' ? (
        <>
            {resource.CsTags?.['Name'] ?? resource.Name} - {resource.ResourceType} - {resource['Region']} -{' '}
            {fmtSvc.awsAccountId(resource.Account ?? '')}
        </>
    ) : (
        <></>
    );
}

export function RecommendationOptionsPanel({ onClose, ...props }: { onClose: () => void } & IRecommendationOptionContentProps) {
    const { selectedOption, handleSelectionChange, toolbarState } = useRecommendationOptionContentState(props);
    const resource = props.recommendation.ResourceMetadata;
    const [resourceDetailsPanelOpened, { close: closeDetailsPanel, open: openDetailsPanel }] = useToggle(false);
    return (
        <>
            <PagePanel padded={false} size={825} style={{ height: '100%' }}>
                <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Group position="apart" p="lg">
                        <Box>
                            {!resource ? null : (
                                <Anchor size="sm" onClick={openDetailsPanel}>
                                    <ResourceTitle resource={resource} />
                                </Anchor>
                            )}
                            <Text data-atid="SidePanelHeader" size={20}>
                                {props.recommendation.Type === 'Size' ? 'Resize Options' : 'Options'}
                            </Text>
                        </Box>
                        <CloseButton data-atid="SidePanelCloseButton" mr="xl" onClick={onClose} />
                    </Group>
                    <Divider />
                    <Box sx={{ height: '100%', justifySelf: 'stretch', overflow: 'auto' }}>
                        <RecommendationOptions
                            selected={selectedOption}
                            recommendation={props.recommendation}
                            comparison={props.comparison}
                            onSelectionChange={handleSelectionChange}
                        />
                    </Box>
                    <Divider />
                    <Box p="lg">
                        <RecommendationOptionToolbar {...toolbarState} cancel={onClose} />
                    </Box>
                </Box>
            </PagePanel>
            <ResourceDetailsOpener
                onClose={closeDetailsPanel}
                platform={resource?.CloudPlatform!}
                resourceId={resourceDetailsPanelOpened ? resource?.Id! : null}
                resourceType={resource?.ResourceType!}
            />
        </>
    );
}

export function RecommendationOptions({
    recommendation,
    comparison,
    onSelectionChange,
    selected,
}: {
    recommendation: Recommendation;
    comparison: IComparsonConfig;
    onSelectionChange: (option?: RecommendationOption) => void;
    selected?: RecommendationOption;
}) {
    return (
        <>
            <Stack spacing={0} sx={{ height: '100%' }}>
                <FillerSwitch noData={!recommendation.Options?.length} noDataMessage="No options available">
                    {() => (
                        <Box sx={{ minHeight: 400 }} px="lg" mt="xs">
                            <RecommendationOptionGrid
                                persistenceKey={`RecommendationOptions-${recommendation.ResourceMetadata?.ResourceType}`}
                                options={recommendation.Options!}
                                comparison={comparison}
                                type={recommendation.Type!}
                                onClick={onSelectionChange}
                                selected={selected ?? null}
                            />
                        </Box>
                    )}
                </FillerSwitch>
                <Space h="md" />
                <Divider />
                <FillerSwitch
                    noData={!selected || !recommendation.Options?.length}
                    noDataMessage={!recommendation.Options?.length ? '' : 'Select Option for Comparison'}
                >
                    {() => <RecommendationOptionComparison option={selected!} recommendation={recommendation} comparison={comparison} />}
                </FillerSwitch>
            </Stack>
        </>
    );
}
