import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Card,
    CardProps,
    createStyles,
    Divider,
    Group,
    Loader,
    MantineColor,
    MantineNumberSize,
    Popover,
    Space,
    Text,
    ThemeIcon,
    Tooltip,
    TooltipProps,
    UnstyledButton,
    useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import {
    Children,
    CSSProperties,
    ForwardedRef,
    forwardRef,
    MouseEventHandler,
    ReactEventHandler,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { CustomColors } from './Themes';

export function ToolTip(props: { infoText: string | ReactNode; infoSize?: 'small' | 'big' }) {
    const theme = useTheme();
    return (
        <Tooltip withinPortal label={props.infoText} position="top" withArrow>
            <ActionIcon>
                <i className="ti ti-info-circle-filled" style={{ fontSize: theme.fontSizes.md }}></i>
            </ActionIcon>
        </Tooltip>
    );
}

export function TooltipWhite(props: TooltipProps) {
    const theme = useMantineTheme();
    return (
        <Tooltip
            position="bottom"
            arrowSize={10}
            withArrow
            {...props}
            sx={{
                background: '#fff',
                padding: '10px 16px',
                border: `solid 1px ${theme.colors.gray[3]}`,
                color: theme.colors.gray[8],
                '.mantine-Tooltip-arrow': {
                    borderTop: `solid 1px ${theme.colors.gray[3]}`,
                    borderLeft: `solid 1px ${theme.colors.gray[3]}`,
                },
                boxShadow: theme.shadows.md,
                ...props.sx,
            }}
        />
    );
}

export function InfoIconTooltip({ label }: { label: ReactNode }) {
    return (
        <TooltipWhite withinPortal label={label} width={300} multiline>
            <ActionIcon color="primary" variant="transparent">
                <InfoCircle size={16} />
            </ActionIcon>
        </TooltipWhite>
    );
}

export function generateColor(uniqueId: string) {
    let hash = 0;
    for (var i = 0; i < uniqueId.length; i++) {
        hash = uniqueId.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 50%, 60%)`;
}

interface AnchorButtonProps {
    color?: MantineColor;
    icon: ReactNode;
    iconPosition?: 'right' | 'left';
    text: string;
    href?: string;
    external?: boolean;
    size?: MantineNumberSize;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    atid?: string;
}
export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(function AnchorButton(
    props: AnchorButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>
) {
    return (
        <Anchor
            ref={ref}
            onClick={props.onClick}
            target={props.external ? '_blank' : undefined}
            href={props.href}
            color={props.color}
            size={props.size}
            sx={{ display: 'inline-flex', alignItems: 'center' }}
            data-atid={props.atid}
        >
            {props.iconPosition === 'right' ? props.text : null}
            <ThemeIcon variant="light" color={props.color} radius="xl" sx={{ background: 'none' }}>
                {props.icon}
            </ThemeIcon>
            {props.iconPosition !== 'right' ? props.text : null}
        </Anchor>
    );
});

export function FileDownload(props: { fileLocation: string; displayText: string }) {
    return (
        <Anchor href={props.fileLocation} download target="_blank" size="sm" sx={{ display: 'block' }}>
            {props.displayText}
            <Space w="sm" sx={{ display: 'inline-block' }} />
            <i className="ti ti-download" />
        </Anchor>
    );
}

export function ListBadge({ text, colored }: { text: string; colored?: boolean }) {
    return (
        <Badge radius="sm" color={colored ? 'success' : 'gray'} variant="light" m={2} sx={{ textTransform: 'none' }}>
            {text}
        </Badge>
    );
}

export function groupBy(arr: any[], property: string) {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, {});
}

export const ListBadgeCell = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    margin: 5px 0;
    align-items: center;
    overflow: hidden;
`;

export const ToolTipWrapper = styled.div`
    margin-left: 10px;
    i {
        color: #788896;
    }
`;

export const FileDownloadWrapper = styled(Anchor)`
    display: block;
    i {
        margin-left: ${(p) => p.theme.spacing.xs}px;
    }
`;

export const SubHeading = styled.h3`
    display: flex;
`;

export const Clearfix = styled.div`
    content: '';
    display: table;
    clear: both;
`;

export const PrimitiveTypeIcon = styled.i`
    background: ${(p) => p.theme.colors?.primary?.[6] as CustomColors}22;
    border-radius: ${(p) => p.theme.radius.sm}px;
    color: ${(p) => p.theme.colors?.primary?.[6] as CustomColors};
    font-size: ${(p) => p.theme.fontSizes.md}px;
    vertical-align: middle;
`;

export function NullText({ text }: { text: string }) {
    return (
        <Text sx={{ display: 'inline' }} color="dimmed" italic>
            {text}
        </Text>
    );
}

export function SectionedPopover({ children }: { children: ReactNode }) {
    const { classes } = usePopoverStyles();
    return <Popover.Dropdown className={classes.dropdown}>{children}</Popover.Dropdown>;
}
export const SectionedPopoverToolbar = styled.div`
    background: ${(p) => p.theme.colors.gray[2]};
    margin: -12px -16px 0;
    padding: 12px 16px;
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[4]};
    border-radius: ${(p) => p.theme.radius.sm}px ${(p) => p.theme.radius.sm}px 0 0;
`;
const usePopoverStyles = createStyles((theme) => ({
    dropdown: {
        ['.mantine-Popover-arrow']: {
            background: theme.colors.gray[2],
            border: `solid 1px ${theme.colors.gray[4]}`,
        },
        border: `solid 1px ${theme.colors.gray[4]}`,
    },
}));

export const useReadonlyInputStyles = createStyles((theme) => ({
    readonly: {
        ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 },
    },
}));

export function MeasuredText({ text, validationKey, onWidth }: { text: ReactNode; validationKey?: string; onWidth: (width: number) => void }) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            onWidth(ref.current.clientWidth);
        }
    }, [ref?.current, typeof text === 'string' ? text : null, validationKey]);
    return (
        <div ref={ref} style={{ position: 'fixed', top: '100vh', visibility: 'hidden', display: 'grid', gridTemplateColumns: 'max-content' }}>
            {text}
        </div>
    );
}

export function Score({
    title,
    value,
    sizeDependency,
    helpText,
    loading,
    color,
    titlePos = 'top',
    titleAlign = 'center',
    maxSize = '2rem',
}: {
    title: string;
    value: string | ((styles: CSSProperties) => ReactNode);
    sizeDependency?: string;
    helpText?: ReactNode;
    loading?: boolean;
    color?: MantineColor;
    titleAlign?: 'left' | 'center' | 'right';
    titlePos?: 'top' | 'bottom';
    maxSize?: number | string;
}) {
    const theme = useMantineTheme();
    const { ref: containerRef, width: containerWidth } = useElementSize();
    const [textWidth, setTextWidth] = useState(0);
    const scale = textWidth > containerWidth ? containerWidth / textWidth : 1;

    const textStyle = {
        fontSize: maxSize,
        lineHeight: `calc(${theme.lineHeight} * ${scale})`,
        transform: `scale(${scale})`,
        transformOrigin: 'center',
    };
    return (
        <TooltipWhite disabled={!helpText} label={helpText} withinPortal>
            <Box ref={containerRef} sx={{ textAlign: 'center' }}>
                {titlePos === 'top' ? (
                    <Text
                        align={titleAlign}
                        color={color ?? 'dimmed'}
                        size="sm"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {title}
                    </Text>
                ) : null}
                {typeof value !== 'string' ? (
                    value(textStyle)
                ) : (
                    <>
                        <Text color={color} sx={textStyle}>
                            {loading ? <Loader /> : value}
                        </Text>
                    </>
                )}
                <Text sx={{ fontSize: maxSize }}>
                    <MeasuredText onWidth={setTextWidth} validationKey={sizeDependency} text={typeof value === 'string' ? value : value({})} />
                </Text>
                {titlePos === 'bottom' ? (
                    <Text
                        align={titleAlign}
                        color={color ?? 'dimmed'}
                        size="sm"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {title}
                    </Text>
                ) : null}
            </Box>
        </TooltipWhite>
    );
}

export function useResizeNeeded() {
    return useCallback(() => setTimeout(() => window.dispatchEvent(new Event('resize')), 1), []);
}

type AccentCardProps = { accent?: MantineColor; background?: MantineColor } & CardProps;
export function AccentCard({ accent, background, children, p, ...props }: AccentCardProps) {
    return (
        <Card p={0} {...props} sx={{ background, borderTopWidth: accent ? 0 : '1px' }}>
            {accent ? <Divider size={3} color="primary" /> : null}
            <Box p={p}>{children}</Box>
        </Card>
    );
}
