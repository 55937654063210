import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { SettingsPage } from '../SettingsPage';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { TextInput, Text, Button, Grid, Select, ActionIcon, SelectItem, LoadingOverlay, Group, Title, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal, openModal } from '@mantine/modals';
import { User as UserIcon, CircleMinus } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import { getSubscriptionGetCompanyInfo, getUserGetCompanyUsers, postSubscriptionUpdateCompanyInfo } from '@apis/Customers';
import { UserListItem, CompanyInfo, Address, Contact } from '@apis/Customers/model';
import { INavigatingEvent, useOnNavigating } from '@root/Services/Router/Router';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { colorPalette, CustomColors, mspColorPalette } from '@root/Design/Themes';
import { ClassNames } from '@emotion/react';

export function CompanyInfoAdmin() {
    const userPermissions = useAuthZValues({
        canManage: { Permissions: 'Manage' },
    });
    const company = useCompany();
    const [users, setUsers] = useState<UserListItem[]>([] as UserListItem[]);
    const [info, setInfo] = useState<CompanyInfo>({} as CompanyInfo);
    const [isLoading, setIsLoading] = useState(true);
    const [countryList, setCountyList] = useState([] as { value: string; group: string; text: string }[]);

    const form = useForm({
        initialValues: {
            CompanyId: 0,
            CompanyName: '',
            CompanyWebsite: '',
            PrimaryContactEmail: '',
            SecondaryContactEmails: [] as string[],
            BillingAddress: { AddressLine1: '', AddressLine2: '', City: '', StateCode: '', CountryCode: '', ZipCode: '' } as Address,
        },

        validate: {
            CompanyName: (value: string) => (value.length > 0 ? null : 'Invalid company name'),
        },
    });

    useOnNavigating((e) => {
        if (form.isDirty()) {
            e.wait();
            confirmAbandonChanges(e);
        }
    });

    const loadCompanyUsers = () => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUsers(users.filter((u) => u.Status === 'Active'));
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        loadCompanyInfo();
    }, []);

    const loadCompanyInfo = () => {
        getSubscriptionGetCompanyInfo()
            .then((info) => {
                setInfo(info);
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        loadCompanyUsers();
        loadCompanyInfo();
    }, []);

    useEffect(() => {
        // set fields to values from api call and reset touched/dirty (initial values)
        resetFormValues();
        setIsLoading(false);
    }, [info]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let companyName = form.isDirty('CompanyName') ? form.values.CompanyName : null;
        let CompanyWebsite = form.isDirty('CompanyWebsite') ? form.values.CompanyWebsite : null;
        let primaryContact = form.isDirty('PrimaryContactEmail') ? getNewPrimaryContact(form.values.PrimaryContactEmail) : null;
        let secondaryContacts = form.isDirty('SecondaryContactEmails') ? getNewSecondaryContacts(form.values.SecondaryContactEmails) : null;
        let billingAddress = form.isDirty('BillingAddress') && form.values.BillingAddress ? form.values.BillingAddress : null;

        let companyInfo = {
            CompanyId: info.CompanyId,
            CompanyName: companyName,
            CompanyWebsite: CompanyWebsite,
            PrimaryContact: primaryContact,
            SecondaryContacts: secondaryContacts,
            BillingAddress: billingAddress,
        } as CompanyInfo;

        await SubmitChanges(companyInfo);
    };

    const getNewPrimaryContact = (emailAddress: string) => {
        let user = users.find((u) => u.EMail === emailAddress);
        if (user) {
            return { FirstName: user.FirstName, LastName: user.LastName, Email: user.EMail, IsPrimary: true } as Contact;
        }

        return null;
    };

    const getNewSecondaryContacts = (addresses: string[]) => {
        let contacts = [] as Contact[];
        addresses.forEach((address) => {
            let contact = users.find((u) => u.EMail == address);
            if (contact) {
                contacts.push({
                    FirstName: contact.FirstName,
                    LastName: contact.LastName,
                    Email: contact.EMail,
                    IsPrimary: false,
                } as Contact);
            }
        });
        return contacts;
    };

    const SubmitChanges = async (changes: CompanyInfo) => {
        setIsLoading(true);
        await postSubscriptionUpdateCompanyInfo(changes).catch((error) => {
            //console.log(`Error: ${error}`);
        });
        loadCompanyInfo();
        setIsLoading(false);
    };

    const resetFormValues = () => {
        let secondaryContactEmails = info?.SecondaryContacts?.map((d) => d.Email) as string[];

        form.setValues({
            CompanyId: info.CompanyId ?? 0,
            CompanyName: info.CompanyName ?? '',
            CompanyWebsite: info.CompanyWebsite ?? '',
            PrimaryContactEmail: info.PrimaryContact?.Email ?? '',
            SecondaryContactEmails: secondaryContactEmails ?? [],
            BillingAddress: info.BillingAddress
                ? ({
                      AddressLine1: info.BillingAddress.AddressLine1 ?? '',
                      AddressLine2: info.BillingAddress.AddressLine2 ?? '',
                      City: info.BillingAddress.City ?? '',
                      StateCode: info.BillingAddress.StateCode ?? '',
                      ZipCode: info.BillingAddress.ZipCode ?? '',
                      CountryCode: info.BillingAddress.CountryCode ?? '',
                  } as Address)
                : ({ AddressLine1: '', AddressLine2: '', City: '', StateCode: '', ZipCode: '', CountryCode: '' } as Address),
        });

        form.resetTouched();
        form.resetDirty();
    };

    const handleAddSecondaryContact = () => {
        let availableUsers = users.length - form.getInputProps('SecondaryContactEmails').value.length - 1;
        if (availableUsers < 1) {
            messageBox('No Available Users', 'All available users have been assigned to contacts.');
            return;
        }
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.push(`placeholder${curVal.length + 1}`);
        form.setFieldValue('SecondaryContactEmails', curVal);
    };

    const handleRemoveSecondaryContact = (index: number) => {
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.splice(index, 1);
        form.setFieldValue('SecondaryContactEmails', curVal);
        // For some reason, Mantine form state doesn't pick up a delete item change, so you have to force it.
        form.setDirty({ SecondaryContactEmails: true });
    };

    const handleSecondaryContactChange = (selectedValue: any, index: number) => {
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.splice(index, 1, selectedValue);
        form.setFieldValue('SecondaryContactEmails', curVal);
    };

    const unselectedUsers = (selectIndex: number) => {
        // Remove primary contact from the list so it can't be selected in secondary contacts.
        // Also, remove selected secondary contacts from the list, unless this is the list
        // that has it selected, so it can display in the select list.
        let list = users.filter(
            (u) =>
                u.EMail !== form.getInputProps('PrimaryContactEmail').value &&
                (!form.getInputProps('SecondaryContactEmails').value.includes(u.EMail) ||
                    (form.getInputProps('SecondaryContactEmails').value.includes(u.EMail) &&
                        form.getInputProps('SecondaryContactEmails').value.indexOf(u.EMail) === selectIndex))
        );

        return list;
    };

    const confirmAbandonChanges = (e: INavigatingEvent) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'black', fontWeight: 600 }}>
                    <b>Confirm Navigation</b>
                </span>
            ),

            children: <Text>You have unsaved changes. Are you sure you want to leave this page?</Text>,
            centered: true,
            labels: { confirm: 'Leave This Page', cancel: 'Stay On Page' },
            onConfirm: () => e.continue(),
        });
    };

    const messageBox = (title: string, message: string) => {
        openModal({
            title: <Text>{title}</Text>,
            children: <Text>{message}</Text>,
            centered: true,
        });
    };

    const handleAddressUpdate = (address: Address) => {
        const nextAddress = address as Record<string, string>;
        const currentAddress = (info.BillingAddress || {}) as Record<string, string>;
        const changes: Record<string, boolean> = {};
        for (const key of Object.keys(address)) {
            changes[`BillingAddress.${key}`] = (nextAddress[key] || '') !== (currentAddress[key] || '');
        }
        form.setFieldValue('BillingAddress', nextAddress);
    };

    return (
        <SettingsPage>
            {isLoading && <LoadingOverlay visible={true} />}
            <div>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            paddingBottom: '1rem',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid lightgray',
                        }}
                    >
                        <Group position="apart">
                            <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                                Company Info
                            </Title>
                            {userPermissions.canManage ? (
                                <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>
                                    <Button
                                        data-atid="RevertButton"
                                        disabled={!form.isDirty()}
                                        size="sm"
                                        variant="outline"
                                        onClick={() => resetFormValues()}
                                        styles={() => ({
                                            root: {
                                                marginRight: 10,
                                            },
                                        })}
                                    >
                                        Revert Changes
                                    </Button>{' '}
                                    <Button data-atid="SaveButton" disabled={!form.isDirty()} type="submit" size="sm">
                                        Save
                                    </Button>{' '}
                                </Group>
                            ) : (
                                <></>
                            )}
                        </Group>
                    </div>

                    {/* Need marginRight to elimiate horizontal scroll bar */}
                    <Grid style={{ padding: '24px', marginRight: '1px' }}>
                        <Grid.Col span={4}>
                            <Text>Company Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyNameInput" {...form.getInputProps('CompanyName')} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Website</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyWebsiteInput" {...form.getInputProps('CompanyWebsite')} />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Billing Contacts</Text>
                            <Text color="dimmed" size="sm">
                                Invoices will be sent to these email addresses.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <>
                                <Select
                                    data-atid="PrimaryContactSelect"
                                    {...form.getInputProps('PrimaryContactEmail')}
                                    placeholder="Select primary contact"
                                    label="Primary Contact"
                                    icon={<UserIcon size={20} />}
                                    data={users.map<SelectItem>((user) => {
                                        return { value: `${user.EMail}`, label: `${user.FirstName} ${user.LastName}` };
                                    })}
                                ></Select>

                                {/* map start------------------ */}
                                {form.getInputProps('SecondaryContactEmails').value.map((contact: string, index: number) => (
                                    <div
                                        key={`SecondaryContact ${index}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Select
                                            data-atid="SecondaryContactSelect"
                                            value={form.getInputProps('SecondaryContactEmails').value[index]}
                                            onChange={(value) => handleSecondaryContactChange(value, index)}
                                            placeholder="Select secondary contact"
                                            label="Secondary Contact"
                                            icon={<UserIcon size={20} />}
                                            style={{ width: '100%', marginTop: '.3rem' }}
                                            data={unselectedUsers(index).map<SelectItem>((user) => {
                                                return { value: `${user.EMail}`, label: `${user.FirstName} ${user.LastName}` };
                                            })}
                                        ></Select>
                                        <ActionIcon
                                            onClick={() => handleRemoveSecondaryContact(index)}
                                            variant="transparent"
                                            style={{ marginTop: '1.8rem' }}
                                        >
                                            <CircleMinus color="red" />
                                        </ActionIcon>
                                    </div>
                                ))}
                                {/* map End---------------- */}
                                <Button
                                    data-atid="AddSecondaryContactButton"
                                    onClick={handleAddSecondaryContact}
                                    style={{ padding: '0px' }}
                                    variant="subtle"
                                    color="gray"
                                >
                                    + Add Secondary Billing Contact
                                </Button>
                            </>
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Address</Text>
                            <Text color="dimmed" size="sm">
                                This address will be added to invoices.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <CompanyAddress
                                prefix="BillingAddress."
                                form={form}
                                requiredFields={[
                                    CompanyAddressFields.AddressLine1,
                                    CompanyAddressFields.City,
                                    CompanyAddressFields.State,
                                    CompanyAddressFields.Zip,
                                    CompanyAddressFields.Country,
                                ]}
                                isWithinPortal={false}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </div>
        </SettingsPage>
    );
}
endpoint('company-info', CompanyInfoAdmin, 'Company Info');
