import { Tooltip } from '@mantine/core';
import { EditableTagCell } from '@root/Components/Resources/InlineTagging';
import { useDiContainer } from '@root/Services/DI';
import { ReactNode, useMemo, useState } from 'react';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { inject, injectable } from 'tsyringe';
import { TagResourcesJob } from '@apis/Resources/model';
import { ActivityPoller } from '@root/Components/Actions/ActivityPanel/ActivityPoller';
import { JobStatus } from '@root/Components/Actions/ActivityPanel/ActivityTypes';

interface PendingChangesIndicatorProps {
    children: ReactNode;
}
export const PendingChangesIndicator = ({ children }: PendingChangesIndicatorProps) => {
    const di = useDiContainer();
    const tagJobListener = useMemo(() => di.resolve(PendingTagJobListener), []);
    const [hasPendingChanges, setHasPendingChanges] = useState(false);

    const handleTagJobListenerEvent = () => {
        setHasPendingChanges(tagJobListener.tagHasPendingChanges(children as string));
    };
    useEvent(tagJobListener.jobStatusChanged, handleTagJobListenerEvent);

    return (
        <EditableTagCell>
            {children}
            {hasPendingChanges ? (
                <Tooltip label="Change pending" withinPortal position="right">
                    <div className={`status running`}></div>
                </Tooltip>
            ) : null}
        </EditableTagCell>
    );
};

@injectable()
export class PendingTagJobListener {
    public readonly jobStatusChanged = EventEmitter.empty();
    private readonly disposer: () => void;
    private readonly pendingTags = new Set<string>();

    public constructor(@inject(ActivityPoller) private readonly poller: ActivityPoller) {
        this.disposer = this.poller.listen(this.updateStatus).dispose;
    }

    public dispose() {
        this.disposer();
    }

    private updateStatus = (jobStatuses: JobStatus[]) => {
        this.pendingTags.clear();

        for (const status of jobStatuses) {
            if (status.job.Type?.endsWith('TagResourcesJob')) {
                const jobParameters = status.job.Parameters as TagResourcesJob;

                if (!status.status.lastDate && (status.status.Created || status.status.Started)) {
                    const tags = this.getTagsFromJob(jobParameters);

                    if (tags) {
                        for (const tag of tags) {
                            if (!this.pendingTags.has(tag)) {
                                this.pendingTags.add(tag);
                            }
                        }
                    }
                }
            }
        }

        this.jobStatusChanged.emit();
    };

    public tagHasPendingChanges(tag: string) {
        return this.pendingTags.has(tag);
    }

    private getTagsFromJob(job: TagResourcesJob) {
        if (job.AddTags) {
            return job.AddTags.map((t) => t.Key ?? '');
        } else if (job.DeleteTags) {
            return job.DeleteTags.map((t) => t.Key ?? '');
        } else if (job.Renames) {
            return job.Renames.reduce((result, item) => {
                result.push(item.OldTagKey ?? '', item.NewTagKey ?? '');
                return result;
            }, [] as string[]);
        } else if (job.ReplaceValues) {
            return [job.ReplaceValues.Key ?? ''];
        }
    }
}
