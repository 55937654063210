import styled from '@emotion/styled';
import { Button, createStyles, Divider, Group, Tabs, UnstyledButton } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { Component } from 'react';

export function CreditsSubHeader() {
    const { classes } = useStyles();
    const link = useLink();
    const { getRootUrl } = useNav();
    return (
        <>
            <Group className={classes.creditsSubHeader}>
                <Button component="a" variant="subtle" {...link(getRootUrl('settings/credit-usage'))}>
                    Overview
                </Button>
                <Button component="a" variant="subtle" {...link(getRootUrl('settings/credit-usage-settings'))}>
                    Settings
                </Button>
            </Group>
            <Divider></Divider>
        </>
    );
}

export const BillingBody = styled.div`
    padding: ${(p) => p.theme.spacing.lg}px ${(p) => p.theme.spacing.xl}px;
`;

export const CreditIcon = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${colorPalette.greenIconBgColor};
    border-radius: 50%;

    i {
        color: ${colorPalette.greenIconColor};
        margin-top: 7px;
        margin-left: -1px;
    }
`;

export const BiggerSuccessCircle = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${colorPalette.lightGreenIconBgColor};
    border-radius: 50%;
    justify-content: 'center';
`;

export const SuccessIcon = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${colorPalette.greenIconBgColor};
    border-radius: 50%;

    i {
        color: ${colorPalette.greenIconColor};
        margin-top: 7px;
        margin-left: -1px;
    }
`;

export const ModalBody = styled.div`
    height: '100%';
`;

export const ModalHeader = styled.div`
    justify-content: 'center';
`;

const useStyles = createStyles((theme) => ({
    creditsSubHeader: {
        a: {
            fontWeight: 600,
            fontSize: '14px',
            Color: colorPalette.lightTextColor,
        },
    },
}));
