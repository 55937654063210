import {
    getCreditsGetCreditSummary,
    getMspGetMspsForCompany,
    getSubscriptionGetSubscriptionRate,
    getSubscriptionGetSubscriptionsByCompany,
    getSubscriptionGetSubscriptionTypes,
    postCreditsAddCreditsBySupportUser,
    postSubscriptionAddSubscriptionBySupportUser,
} from '@apis/Customers';
import { CompanyType, SubscriptionType } from '@apis/Customers/model';
import { Box, Button, Card, Divider, Group, LoadingOverlay, NumberInput, Radio, Select, Space, Text, useMantineTheme } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useDi } from '@root/Services/DI';
import { useEffect, useMemo, useState } from 'react';

export function AddCreditsModal({ onClose, onSubmit, companyId }: { onClose: () => void; onSubmit: () => void; companyId: number }) {
    const [presentCredits, setPresentCredits] = useState<number>(0);
    const [credits, setCredits] = useState<number>(0);
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const companyContext = useDi(CompanyContextService);

    useEffect(() => {
        (async () => {
            const creditsSummary = await getCreditsGetCreditSummary();
            setPresentCredits(creditsSummary?.TotalCredits ?? 0);
        })();
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        companyContext.withParentCompany(async () => {
            await postCreditsAddCreditsBySupportUser({
                companyId: companyContext.company?.Id!,
                credits: credits,
            });
        });
        onSubmit();
        setLoading(false);
    };

    return (
        <Box>
            {loading && <LoadingOverlay visible={true} />}
            <Divider />
            <Space h="xs" />
            <Card sx={{ backgroundColor: theme.colors?.gray?.[2] }} radius="md">
                <Group position="left">
                    <Text>Total Credits:</Text>
                    <Text weight={600}> {presentCredits}</Text>
                </Group>
                <Space h="xs" />
                <Group>
                    <Text>Credits: </Text>
                    <Space h="xs" />
                    <NumberInput
                        data-atid="DiscountValue"
                        value={credits}
                        onChange={(val) => setCredits(val ?? 0)}
                        max={1000000}
                        min={-presentCredits}
                    />
                </Group>
                <Space h="xs" />
                <Group p="lg" position="right" sx={{ background: theme.colors?.gray?.[2], borderRadius: `0 0 4px 4px` }}>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="submit">
                        Add Credits
                    </Button>
                </Group>
            </Card>
        </Box>
    );
}
