import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ResourceIopsUtilizationChart } from '@root/Components/Resources/ResourceIopsUtilizationChart';
import { useEffect, useState } from 'react';
import { SegmentedControl, Table } from '@mantine/core';

export function GetResourceIopsUtilization(props: { resourceType: string; resourceId: string }) {
    const [currChartType, setChartType] = useState('VolumeReadOps');
    const [currDaysBack, setDaysBack] = useState(30);
    const [dataFrequency, setDataFrequency] = useState<string>('60');
    const [currFrequency, setFrequency] = useState<number>(60);

    function chartTypeChange(e: any) {
        setChartType(e.target.value);
    }

    function daysBackChange(e: any) {
        setDaysBack(e);
    }

    function frequencyChange(e: any) {
        setDataFrequency(e);
    }

    useEffect(() => {
        setFrequency(Number(dataFrequency));
    }, [dataFrequency]);

    var iopsChart = (
        <>
            <ResourceIopsUtilizationChart
                resourceType={props.resourceType}
                resourceId={props.resourceId}
                chartType={currChartType}
                daysBack={currDaysBack}
                frequency={currFrequency}
            />
        </>
    );

    return (
        <div className="resourcesTableContainer">
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <SegmentedControl
                                key="DaysBack"
                                onChange={daysBackChange}
                                defaultValue="30"
                                data={[
                                    { label: '30 Days', value: '30' },
                                    { label: '60 Days', value: '60' },
                                    { label: '180 Days', value: '180' },
                                    { label: '12 Months', value: '365' },
                                ]}
                            />
                        </td>
                        <td>
                            <select onChange={chartTypeChange} id="iopsUsageChart">
                                <option value="Volume_Iops">IOPS Utilization (Read + Write)</option>
                                <option value="Volume_Read_Ops">Read IOPS</option>
                                <option value="Volume_Write_Ops">Write IOPS</option>
                                <option value="Volume_Read_Bytes">Read MB</option>
                                <option value="Volume_Write_Bytes">Write MB</option>
                                <option value="Volume_Total_Read_Time">Total Read Time (Seconds)</option>
                                <option value="Volume_Total_Write_Time">Total Write Time (Seconds)</option>
                                <option value="Volume_Idle_Time">Idle Time (Seconds)</option>
                            </select>
                        </td>
                        <td style={{ float: 'right' }}>
                            <SegmentedControl
                                key="Frequency"
                                onChange={frequencyChange}
                                defaultValue="60"
                                data={[
                                    { label: '5 Minutes', value: '5' },
                                    { label: '15 Minutes', value: '15' },
                                    { label: '1 Hour', value: '60' },
                                    { label: '1 Day', value: '1440' },
                                ]}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
            {iopsChart}
        </div>
    );
}

export function ResourceIopsUtilization() {
    return <>Resource IOPS Utilization</>;
}
endpoint('resource', ResourceIopsUtilization, 'Resource IOPS Utilization');
