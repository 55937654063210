import {
    getCspMktGetStagedAzureSubscription,
    getSubscriptionGetCreditRates,
    getSubscriptionGetSubscriptionOptions,
    getUserGetMyCompanies,
    postCspMktActivateAzureSubscription,
    postCspMktActivateAzureSubscriptionBySecret,
    postCspMktGetPendingPaymentMethod,
    postSubscriptionUpdateCompanyInfo,
    postSubscriptionUpdatePrimaryContactToCurrentUser,
    putCompanyRegisterCompany,
} from '@apis/Customers';
import {
    Address,
    AzureMarketplaceSubscription,
    Company,
    CreditRates,
    PendingPaymentMethodInfo,
    Plans,
    SubscriptionOptions,
    SubscriptionType,
} from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    Accordion,
    Anchor,
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Divider,
    Grid,
    Group,
    List,
    Loader,
    LoadingOverlay,
    MantineColor,
    Modal,
    Space,
    Table,
    Text,
    TextInput,
    ThemeIcon,
    ThemeIconVariant,
    Title,
    UnstyledButton,
    useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTimeout } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { useList, useSet } from '@react-hookz/web';
import { getProductIcon } from '@root/Components/Applications/AppIcons';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { Logger } from '@root/Services/Logger';
import { NavigationService } from '@root/Services/NavigationService';
import { Router } from '@root/Services/Router/Router';
import { ca } from 'date-fns/locale';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Check,
    ChevronRight,
    CircleCheck,
    CircleDashed,
    CircleDot,
    CircleDotted,
    CircleX,
    Cloud,
    DoorExit,
    Dots,
    DotsCircleHorizontal,
    RulerMeasure,
    X,
} from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { SubscriptionPriceDetails } from './Components/SubscriptionPriceDetails';
import { AzurePaymentMethodService } from './Services/AzurePaymentMethodService';
import { MspService } from '@root/Services/MspService';

export function AzureMarketplaceLandingPage() {
    const router = useDi(Router);
    const logger = useDi(Logger);
    const route = useEventValue(router.route);
    const [start] = route?.newRoute ?? [];
    const { id: rawId } = start.data;
    const id = parseInt(rawId);
    const [companies, setCompanies] = useState<Company[]>();
    const [subscription, setSubscription] = useState<AzureMarketplaceSubscription>();

    useEffect(() => {
        const fail = () => router.navigate('/_register');
        if (!id) {
            fail();
        } else {
            (async () => {
                try {
                    const companies = await getUserGetMyCompanies();
                    setCompanies(companies);
                    const subscription = await getCspMktGetStagedAzureSubscription({ subscriptionId: id });
                    if (!subscription) {
                        fail();
                    } else {
                        setSubscription(subscription);
                    }
                } catch (err) {
                    logger.error('Error while getting current user companies', err);
                    fail();
                }
            })();
        }
    }, [id]);

    return companies && subscription ? (
        <SubscriptionConfirmation companies={companies} subscription={subscription} />
    ) : (
        <LoadingOverlay visible={true} />
    );
}

function SubscriptionConfirmation({ companies, subscription }: { companies: Company[]; subscription: AzureMarketplaceSubscription }) {
    const theme = useMantineTheme();
    const secretSvc = useDi(AzurePaymentMethodService);
    const restoredSecret = secretSvc.restoreSecret();
    const hasCompanies = companies.length > 0;
    const [openSection, setOpenSection] = useState<string>(restoredSecret ? 'activation-code' : hasCompanies ? 'select' : 'register');
    const setSection = useCallback(
        (section: string | null) => {
            if (section !== null) {
                setOpenSection(section);
            }
        },
        [openSection]
    );
    const mspSvc = useDi(MspService);
    useEffect(() => {
        const originalBg = document.body.style.background;
        document.body.style.background = theme.colors.gray[1];
        return () => {
            document.body.style.background = originalBg;
        };
    }, []);
    return (
        <Box p="xl">
            <Grid m={0}>
                <Grid.Col md={6} xl={4} offsetMd={3} offsetXl={4}>
                    <Card color={theme.white} shadow="xl" radius="lg" p={0}>
                        <Box p="xl">
                            <img src={mspSvc.mspSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.mspSupportAndLogos.CompanyName} />
                        </Box>
                        <Divider size="xs" />
                        <Accordion value={openSection} onChange={setSection}>
                            <Accordion.Item value="select" hidden={!hasCompanies} p={0}>
                                <Accordion.Control>
                                    <Title px="lg" order={4} sx={{ fontWeight: 'normal' }}>
                                        Select Registration
                                    </Title>
                                </Accordion.Control>
                                <Accordion.Panel p={0}>
                                    <SelectRegistration subscription={subscription} companies={companies} />
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="activation-code">
                                <Accordion.Control chevron={hasCompanies ? undefined : <></>}>
                                    <Title px="lg" order={4} sx={{ fontWeight: 'normal' }}>
                                        Activate Registration
                                    </Title>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Group position="center" p="xl">
                                        <ActivateRegistration subscription={subscription} />
                                    </Group>
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="register">
                                <Accordion.Control chevron={hasCompanies ? undefined : <></>}>
                                    <Title px="lg" order={4} sx={{ fontWeight: 'normal' }}>
                                        {hasCompanies ? 'New Registration' : 'Subscription Details'}
                                    </Title>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Group position="center" p="xl">
                                        <SubscriptionRegister subscription={subscription} />
                                    </Group>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

function ActivateRegistration({ subscription }: { subscription: AzureMarketplaceSubscription }) {
    const theme = useMantineTheme();
    const secretSvc = useDi(AzurePaymentMethodService);
    const restoredSecret = secretSvc.restoreSecret();
    const [secret, setSecret] = useState(secretSvc.restoreSecret());
    const [valid, setValid] = useState<boolean>();
    const [pendingPmtInfo, setPendingPmtInfo] = useState<PendingPaymentMethodInfo>();
    const [subscriptionInfo, setSubscriptionInfo] = useState<{ plan: Plans; creditRate: CreditRates }>();
    const logger = useDi(Logger);
    const model = useMemo(
        () => ({
            timeout: 0 as any,
            async load(secret: string) {
                try {
                    const pendingPmt = await secretSvc.getSecret(secret);
                    setSubscriptionInfo(undefined);
                    setPendingPmtInfo(undefined);
                    if (!pendingPmt || !!pendingPmt.UsedAt) {
                        setValid(false);
                    } else {
                        const info = await postCspMktGetPendingPaymentMethod({ secret, subscriptionId: subscription.Id });
                        setPendingPmtInfo(info);
                        const [subscriptionOptions, creditRate] = await Promise.all([
                            getSubscriptionGetSubscriptionOptions({
                                applicationType: subscription?.Offer?.SubscriptionType?.Application ?? undefined,
                            }),
                            getSubscriptionGetCreditRates({ creditPackageId: info.CreditPackageId }),
                        ]);
                        const plan = [...(subscriptionOptions.MonthlyPlans ?? []), ...(subscriptionOptions.YearlyPlans ?? [])].find(
                            (o) => o.SubscriptionTypeId === info.SubscriptionTypeId
                        );
                        if (!plan) {
                            throw new Error('No subscription option found for ID ' + info.SubscriptionTypeId);
                        }
                        creditRate.Ranges = creditRate.Ranges?.filter((r) => r.Type === 'Azure');

                        setSubscriptionInfo({ creditRate, plan });
                        setValid(true);
                    }
                } catch (err) {
                    logger.error('Error getting subscription options and credit rates', err);
                    setValid(false);
                }
            },
            check(secret: string) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(async () => {
                    await this.load(secret);
                }, 500);
            },
        }),
        []
    );
    const modals = useModals();
    const activate = () => {
        modals.openModal({
            title: null,
            closeOnClickOutside: false,
            withCloseButton: false,
            padding: 0,
            overlayBlur: 4,
            radius: 'lg',
            overlayColor: 'white',
            withinPortal: true,
            children: <ActivationConfirmation company={pendingPmtInfo?.CompanyName!} secret={secret} subscriptionId={subscription.Id!} />,
        });
        secretSvc.clear();
    };

    useEffect(() => {
        if (secret) {
            model.check(secret);
        }
    }, [secret]);

    return (
        <Box sx={{ width: 422 }}>
            <Title order={4}>Payment Method Activation</Title>
            <Space h="md" />
            <ActivationCodeContainer>
                <TextInput
                    color={valid !== false ? undefined : theme.colors.error[5]}
                    label="Activation Code"
                    size="md"
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                />
            </ActivationCodeContainer>
            <Text size="sm" color={valid !== false ? 'dimmed' : 'error'}>
                {valid === false
                    ? 'Activation code is invalid. Please use a new activation code.'
                    : restoredSecret
                    ? 'Activation code found. If this looks incorrect, you may try a different code.'
                    : 'If you have an activation code, enter it here.'}
            </Text>
            <Space h="xl" />
            {pendingPmtInfo && (
                <>
                    <Title order={4}>Subscription Details</Title>
                    <Space h="xs" />
                    <Text size="sm" color="dimmed">
                        Activating will enable Azure billing according the details below.
                    </Text>
                    <Space h="xs" />
                    <Card withBorder radius="lg" p={0}>
                        <Box py="md" px={30}>
                            <Text color="dimmed" size="sm">
                                CloudSaver Account (Benficiary):
                            </Text>
                            <Text>{pendingPmtInfo.CompanyName}</Text>
                            <Space h="sm" />
                            <Text color="dimmed" size="sm">
                                Azure Tenant (Payer):
                            </Text>
                            <Text>{subscription.PurchaserTenantId}</Text>
                        </Box>
                        <Divider />
                        {subscriptionInfo ? (
                            <SubscriptionPriceDetails
                                creditRate={subscriptionInfo.creditRate}
                                plan={subscriptionInfo.plan}
                                subscriptionType={subscription.Offer?.SubscriptionType!}
                            />
                        ) : null}
                    </Card>
                    <Space h="xl" />
                </>
            )}
            <Button disabled={!pendingPmtInfo} fullWidth color="primary" onClick={activate}>
                Activate Subscription
            </Button>
        </Box>
    );
}

const ActivationCodeContainer = styled.div`
    input {
        text-align: center;
    }
`;

function ActivationConfirmation({ secret, subscriptionId, company }: { secret: string; subscriptionId: number; company: string }) {
    const [state, setState] = useState<'loading' | 'done' | 'failed'>('loading');
    const logger = useDi(Logger);
    const [loadingChat, setLoadingChat] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                await postCspMktActivateAzureSubscriptionBySecret({ secret, subscriptionId });
                setState('done');
            } catch (err) {
                logger.error('Error getting subscription options and credit rates', err);
                setState('failed');
            }
        })();
    }, []);
    const pollingSvc = useDi(PollingService);
    const loadChat = async () => {
        setLoadingChat(true);
        try {
            window.embedded_svc.liveAgentAPI.startChat();
            await pollingSvc.pollUntil(
                async () => {},
                () => document.getElementsByClassName('embeddedServiceSidebar').length > 0
            );
        } finally {
            setLoadingChat(false);
        }
    };
    const authNSvc = useDi(AuthenticationService);
    const mspSvc = useDi(MspService);

    return (
        <Box>
            <Box p="xl">
                <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
            </Box>
            <Divider />
            <Group p="xl" noWrap>
                <ThemeIcon
                    size={24}
                    variant={state === 'loading' ? 'outline' : 'filled'}
                    className={state === 'loading' ? 'ti-fade' : ''}
                    radius="lg"
                    color={state === 'loading' ? 'gray' : state === 'done' ? 'success' : 'error'}
                >
                    {state === 'loading' ? <Dots size={16} /> : state === 'done' ? <Check size={16} /> : <X size={16} />}
                </ThemeIcon>
                {state === 'loading' ? 'Activating' : state === 'done' ? 'Activated' : 'Failed Activating'} Subscription for {company}
            </Group>
            {state === 'failed' ? (
                <>
                    <Divider />
                    <Box p="xl">
                        <Text>We have encountered some difficulties. Let's open a support ticket to get this solved.</Text>
                        <Space h="lg" />
                        <Text align="center">
                            <Button leftIcon={loadingChat ? <Loader color={'#fff' as MantineColor} size="sm" /> : undefined} onClick={loadChat}>
                                Contact Support
                            </Button>
                        </Text>
                    </Box>
                </>
            ) : state === 'done' ? (
                <>
                    <Divider />
                    <Box p="xl">
                        <Text>Finished. </Text>
                        <Space h="md" />
                        <Text size="sm">The SaaS offer has been activated and {company} can now use this payment method. </Text>
                        <Space h="md" />
                        <Text size="sm" color="dimmed">
                            You are currently logged in with AzureAD credentials. If this is not your normal login method, then we recommend logging
                            out.
                        </Text>
                        <Space h="md" />
                        <Text align="center">
                            <AnchorButton onClick={() => authNSvc.signout()} text="Log out" icon={<DoorExit />} />
                        </Text>
                    </Box>
                </>
            ) : null}
        </Box>
    );
}

function SelectRegistration({ companies, subscription }: { companies: Company[]; subscription: AzureMarketplaceSubscription }) {
    const formatSvc = useDi(FormatService);
    return (
        <Box p="md" sx={{ maxHeight: 400, overflow: 'auto' }}>
            {companies.map((c) => (
                <Fragment key={c.Id}>
                    <UnstyledButton
                        component="a"
                        href={`/${c.DatabaseName}/settings/payment-methods/azure-confirm-payment-method@id:${subscription.Id}`}
                    >
                        <SelectRegistrationContainer>
                            <Card px="xl" radius="lg" sx={{ background: 'none' }}>
                                <Group position="apart">
                                    <Box sx={{ flex: 1 }}>
                                        <Title order={5} sx={{ fontWeight: 'normal' }}>
                                            {c.CompanyName}
                                        </Title>
                                        <Text color="dimmed" size="sm">
                                            Date: {formatSvc.formatDate(formatSvc.toLocalDate(c.DateCreated))}
                                        </Text>
                                    </Box>
                                    <Text color="primary" size="sm" className="manage">
                                        Manage Billing
                                    </Text>
                                    <ChevronRight strokeWidth={1} />
                                </Group>
                            </Card>
                        </SelectRegistrationContainer>
                    </UnstyledButton>
                </Fragment>
            ))}
        </Box>
    );
}

const SelectRegistrationContainer = styled.div`
    > div {
        transition: all 0.25s linear;
        .manage {
            opacity: 0;
            transition: all 0.25s linear;
        }
        :hover {
            background: ${(p) => p.theme.colors.primary[1]};
            .manage {
                opacity: 1;
            }
        }
    }
`;

function SubscriptionRegister({ subscription }: { subscription: AzureMarketplaceSubscription }) {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState<{ plan: Plans; creditRate: CreditRates }>();
    const di = useDiContainer();
    const activatorSvc = useMemo(() => di.resolve(AzureSubscriptionActivator), []);
    const subscriptionState = useMemo(() => new EventEmitter<ActivationState>(null), []);
    const state = useEventValue(subscriptionState);
    const logger = useDi(Logger);
    const router = useDi(Router);

    useEffect(() => {
        (async () => {
            try {
                const creditPackageId = subscription.Offer?.CreditPackageId ?? 0;
                const subscriptionTypeId = subscription.Offer?.SubscriptionType?.ExternalId ?? '';
                const [subscriptionOptions, creditRate] = await Promise.all([
                    getSubscriptionGetSubscriptionOptions({ applicationType: subscription?.Offer?.SubscriptionType?.Application ?? undefined }),
                    getSubscriptionGetCreditRates({ creditPackageId }),
                ]);
                const plan = [...(subscriptionOptions.MonthlyPlans ?? []), ...(subscriptionOptions.YearlyPlans ?? [])].find(
                    (o) => o.ExternalId === subscriptionTypeId
                );
                if (!plan) {
                    throw new Error('No subscription option found for ID ' + subscriptionTypeId);
                }
                creditRate.Ranges = creditRate.Ranges?.filter((r) => r.Type === 'Azure');

                setSubscriptionInfo({ creditRate, plan });
            } catch (err) {
                logger.error('Error getting subscription options and credit rates', err);
                router.navigate('/_register');
            }
        })();
    }, []);

    const form = useForm({
        initialValues: {
            CompanyName: '',
            CompanyWebsite: '',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            CountryCode: '',
            StateCode: '',
            ZipCode: '',
        },
        validate: {
            CompanyName: (value) =>
                value.length > 50
                    ? 'Max length for company name is 50'
                    : value.replace(/[^0-9a-z]/gi, '').length < 3
                    ? 'Company name must contain at least 3 letters or numbers'
                    : null,
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const activate = useCallback(() => {
        if (!form.validate().hasErrors) {
            activatorSvc.activate(form.values, subscription.Id!, subscriptionState);
        }
    }, [form.validate, form.values]);

    return (
        <Box sx={{ width: 422 }}>
            <Title order={4}>Account Setup</Title>
            <TextInput mb="md" data-atid="CompanyName" required label="Company Name" {...form.getInputProps('CompanyName')} />
            <TextInput mb="md" data-atid="CompanyWebsite" label="Company Website" {...form.getInputProps('CompanyWebsite')} />
            <Space h="lg" />
            <Title order={4}>Business Address</Title>
            <CompanyAddress
                form={form}
                prefix=""
                requiredFields={[
                    CompanyAddressFields.AddressLine1,
                    CompanyAddressFields.City,
                    CompanyAddressFields.State,
                    CompanyAddressFields.Zip,
                    CompanyAddressFields.Country,
                ]}
                isWithinPortal={false}
            />
            <Space h="lg" />
            <Title order={4}>Subscription Details</Title>
            {subscriptionInfo && (
                <SubscriptionInfo creditRate={subscriptionInfo.creditRate} plan={subscriptionInfo.plan} subscription={subscription} />
            )}
            <Space h="lg" />
            <TermsOfService onChange={setAcceptedTerms} />
            <Button disabled={!acceptedTerms} fullWidth color="primary" onClick={activate}>
                Activate Subscription
            </Button>
            <Modal
                padding={0}
                overlayBlur={4}
                opened={state !== null}
                onClose={() => {}}
                radius="lg"
                overlayColor="white"
                withinPortal
                withCloseButton={false}
            >
                <ActivationProgress state={subscriptionState} />
            </Modal>
        </Box>
    );
}

function SubscriptionInfo({ plan, creditRate, subscription }: { subscription: AzureMarketplaceSubscription; plan: Plans; creditRate: CreditRates }) {
    return (
        <Card withBorder radius="lg" mt="xs" p={0}>
            <SubscriptionPriceDetails creditRate={creditRate} plan={plan} subscriptionType={subscription.Offer?.SubscriptionType!} />
        </Card>
    );
}

function TermsOfService({ onChange, type }: { onChange: (value: boolean) => void; type?: 'trial' }) {
    return (
        <Group spacing={6} p="md">
            <Checkbox onChange={(evt) => onChange(evt.target.checked)} />I agree with the
            <Anchor
                href={'https://www.cloudsaver.com/legal/' + (type === 'trial' ? 'free-trial' : 'master-subscription') + '-agreement/'}
                target="_blank"
            >
                terms of service
            </Anchor>
        </Group>
    );
}

type ActivationState = null | 'creating' | 'failed' | 'provisioning' | 'subscribing' | 'activating' | 'redirecting' | 'finished';

@injectable()
class AzureSubscriptionActivator {
    public constructor(
        @inject(CompanyTenantPrereqService) private readonly tenantSvc: CompanyTenantPrereqService,
        @inject(JobService) private readonly jobSvc: JobService,
        @inject(Logger) private readonly logger: Logger
    ) {}

    public async activate(
        companyRequest: Address & { CompanyName: string; CompanyWebsite?: string },
        subscriptionId: number,
        state: EventEmitter<ActivationState>
    ) {
        try {
            state.emit('creating');
            let company = await this.createCompany(companyRequest.CompanyName, companyRequest.CompanyWebsite);
            if (company === null) {
                state.emit('failed');
            } else {
                state.emit('provisioning');
                await this.provisionCompany(company);
                state.emit('subscribing');
                await this.updateCompanyDetails(companyRequest);
                state.emit('activating');
                await this.createSubscription(subscriptionId);
                state.emit('redirecting');
                await this.redirect(company.Id!);
                state.emit('finished');
            }
        } catch (err) {
            this.logger.error('Failed while registering', state.value, err);
            state.emit('failed');
        }
    }
    private async createCompany(companyName: string, companyWebsite?: string) {
        return await putCompanyRegisterCompany({ companyName, companyWebsite });
    }

    private async provisionCompany(company: Company) {
        this.tenantSvc.setCompanyId(company.Id);
        const { poller } = await this.jobSvc.waitForJobHierarchyByJobId(company.ProvisionJobId!);
        const result = await poller;
        return !result?.Failed ? company : null;
    }

    private async updateCompanyDetails(address: Address) {
        await postSubscriptionUpdatePrimaryContactToCurrentUser();
        await postSubscriptionUpdateCompanyInfo({ BillingAddress: address });
    }

    private async createSubscription(subscriptionId: number) {
        await postCspMktActivateAzureSubscription({ subscriptionId });
    }

    private async redirect(companyId: number) {
        const companies = await getUserGetMyCompanies();
        const company = companies.find((f) => f.Id === companyId);
        if (!company) {
            throw new Error(`Company ${companyId} not found`);
        }
        window.location.href = `${company.DatabaseName}/home/dashboard`;
    }
}

function ActivationProgress({ state }: { state: EventEmitter<ActivationState> }) {
    const states = useSet<ActivationState>([]);
    const statuses = useMemo(
        () =>
            [
                { state: 'provisioning', description: 'Account initialized', working: 'Initializing account' },
                { state: 'subscribing', description: 'Provisioned workspace', working: 'Provisioning workspace' },
                { state: 'activating', description: 'Created subscription', working: 'Creating subscription' },
                { state: 'redirecting', description: 'Activated Azure offer', working: 'Activating Azure offer' },
                { state: 'finished', description: 'Redirecting to new account', working: 'Redirecting to new account' },
            ] as { state: ActivationState; description: string; working: string }[],
        []
    );
    useEvent(state, (state) => states.add(state));
    const [loadingChat, setLoadingChat] = useState(false);
    const pollingSvc = useDi(PollingService);
    const loadChat = async () => {
        setLoadingChat(true);
        try {
            window.embedded_svc.liveAgentAPI.startChat();
            await pollingSvc.pollUntil(
                async () => {},
                () => document.getElementsByClassName('embeddedServiceSidebar').length > 0
            );
        } finally {
            setLoadingChat(false);
        }
    };
    const mspSvc = useDi(MspService);

    return (
        <Box>
            <Box p="xl">
                <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
            </Box>
            <Divider />
            <List py="xl" px={48} center spacing="sm">
                {statuses.map((s) => {
                    const iconColor: CustomColors = states.has(s.state) ? 'success' : states.has('failed') ? 'error' : 'gray';
                    const icon = states.has(s.state) ? <Check size={16} /> : states.has('failed') ? <X size={16} /> : <Dots size={16} />;
                    const variant: ThemeIconVariant = !states.has(s.state) ? 'outline' : 'filled';
                    const animation = states.has(s.state) || states.has('failed') ? '' : 'ti-fade';
                    const description = states.has(s.state) ? s.description : s.working;

                    return (
                        <List.Item
                            key={s.state}
                            icon={
                                <ThemeIcon size={24} variant={variant} className={animation} radius="lg" color={iconColor}>
                                    {icon}
                                </ThemeIcon>
                            }
                        >
                            {description}
                        </List.Item>
                    );
                })}
            </List>
            {!states.has('failed') ? null : (
                <>
                    <Divider />
                    <Box p="xl">
                        <Text>We have encountered some difficulties. Let's open a support ticket to get this solved.</Text>
                        <Space h="lg" />
                        <Button leftIcon={loadingChat ? <Loader color={'#fff' as MantineColor} size="sm" /> : undefined} onClick={loadChat}>
                            Contact Support
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
