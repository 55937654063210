import { QueryResult, postAssessmentsQueryAssessments } from '@apis/Resources';
import { AssessmentResult } from '@apis/Resources/model';
import { Platform } from '@root/Services/PlatformService';
import { queryBuilder } from '@root/Services/QueryExpr';

export type MspAssessmentResultRollup = {
    companyId: number;
    category: string;
    score: number;
    savings: number;
    opportunityCount: number;
    totalCount: number;
};

export type CompanyAssessmentResultRollup = {
    companyId: number;
    platform: string;
    code: AssessmentResult['AssessmentCode'];
    category: string;
    type: string;
    score: number;
    savings: number;
    opportunityCount: number;
    totalCount: number;
};

export type MspPortfolioAssessmentRollup = {
    companyId: number;
    companyName: string;
    platform: string;
    assessmentDate: string;
    score: number;
    savings: number;
    opportunityCount: number;
    totalCount: number;
    //code: string;
    //category: string;
    //type: string;
};

export async function getCompanySummary(companyId: number, companyPlatforms: Set<Platform>) {
    const results = await queryBuilder<
        AssessmentResult & {
            ['AssessmentScore.AssessmentScore']: number;
            ['AssessmentScore.AssessmentSavings']: number;
            ['AssessmentScore.TotalCount']: number;
            ['AssessmentScore.AssessmentOpportunityCount']: number;
        }
    >()
        .where((b) => b.and(b.model.IsLatest!.eq(true), b.model.CompanyId!.eq(companyId), b.model.Platform!.eq([...companyPlatforms])))
        .select((b) => ({
            companyId: b.model.CompanyId,
            platform: b.model.Platform!,
            category: b.model.AssessmentCategory!,
            code: b.model.AssessmentCode!,
            type: b.model.AssessmentType!,
            score: b.sum(b.model['AssessmentScore.AssessmentScore']),
            savings: b.sum(b.model['AssessmentScore.AssessmentSavings']),
            opportunityCount: b.sum(b.model['AssessmentScore.AssessmentOpportunityCount']),
            totalCount: b.sum(b.model['AssessmentScore.TotalCount']),
        }))
        .execute(postAssessmentsQueryAssessments);

    return results;
}

export async function getMspSummary(companyIDs: number[]) {
    const results = await queryBuilder<
        AssessmentResult & {
            ['AssessmentScore.AssessmentScore']: number;
            ['AssessmentScore.AssessmentSavings']: number;
            ['AssessmentScore.TotalCount']: number;
            ['AssessmentScore.AssessmentOpportunityCount']: number;
        }
    >()
        .where((b) => b.model.IsLatest!.eq(true))
        .select((b) => ({
            companyId: b.model.CompanyId,
            category: b.model.AssessmentCategory!,
            score: b.sum(b.model['AssessmentScore.AssessmentScore']),
            savings: b.sum(b.model['AssessmentScore.AssessmentSavings']),
            opportunityCount: b.sum(b.model['AssessmentScore.AssessmentOpportunityCount']),
            totalCount: b.sum(b.model['AssessmentScore.TotalCount']),
        }))
        .execute(postAssessmentsQueryAssessments);

    return results;
}

export async function getMspPortfolio(type: string) {
    const results = await queryBuilder<
        AssessmentResult & {
            ['AssessmentScore.AssessmentScore']: number;
            ['AssessmentScore.AssessmentSavings']: number;
            ['AssessmentScore.TotalCount']: number;
            ['AssessmentScore.AssessmentOpportunityCount']: number;
        }
    >()
        .where((b) => b.and(b.model.IsLatest!.eq(true), type != 'All' ? b.model.Platform!.eq(type) : b.model.Platform!.ne('')))
        .select((b) => ({
            companyId: b.model.CompanyId,
            //platform: b.model.Platform!,
            //companyName: b.model.CompanyId,
            assessmentDate: b.max(b.model.AssessmentDate),
            //code: b.model.AssessmentCode ?? '',
            //category: b.model.AssessmentCategory!,
            //type: b.model.AssessmentType!,
            score: b.avg(b.model['AssessmentScore.AssessmentScore']),
            savings: b.sum(b.model['AssessmentScore.AssessmentSavings']),
            opportunityCount: b.sum(b.model['AssessmentScore.AssessmentOpportunityCount']),
            totalCount: b.sum(b.model['AssessmentScore.TotalCount']),
        }))
        .execute(postAssessmentsQueryAssessments);

    return results as QueryResult<MspPortfolioAssessmentRollup>;
}

export interface Score {
    totalOpportunity: number;
    score: number;
    savings: number;
    totalCount: number;
    totalScore: number;
    noData: boolean;
}

export function CalcScores(results: CompanyAssessmentResultRollup[]) {
    const totalOpportunity = calcSum(results.map((m) => m.opportunityCount));
    const score = calcSum(results.map((m) => m.score));
    const savings = calcSum(results.map((m) => m.savings));
    const totalCount = calcSum(results.map((m) => m.totalCount));
    const totalScore = results.length > 0 ? score / results.length : 0;
    const noData = totalCount === 0;

    return {
        totalOpportunity: totalOpportunity,
        score: score,
        savings: savings,
        totalCount: totalCount,
        totalScore: totalScore,
        noData: noData,
    };
}

export function MspCalcScores(results: MspAssessmentResultRollup[]) {
    const totalOpportunity = calcSum(results.map((m) => m.opportunityCount));
    const score = calcSum(results.map((m) => m.score));
    const savings = calcSum(results.map((m) => m.savings));
    const totalCount = calcSum(results.map((m) => m.totalCount));
    const totalScore = results.length > 0 ? score / results.length : 0;
    const noData = totalCount === 0;

    return {
        totalOpportunity: totalOpportunity,
        score: score,
        savings: savings,
        totalCount: totalCount,
        totalScore: totalScore,
        noData: noData,
    };
}

function calcSum(values: number[]) {
    return values.reduce((sum, current) => sum + current, 0) ?? 0;
}
