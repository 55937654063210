import { Group, Space, Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { useMemo } from 'react';
import { container } from 'tsyringe';
import { ChartMargin, useTickSpacing } from './Common';
import { StandardChartProps } from './Models';

export function ConfidenceLineChart<T extends Record<string, string | number | Date>>(props: ConfidenceChartProps<T>) {
    const data = useMemo(() => {
        const transformedData = ['P10', 'P50', 'P90'].map((key) => ({
            id: key,
            data: props.data.map((d) => ({ x: d.Date, y: d[key] })),
        }));
        return transformedData;
    }, [props.data]);
    const fmtSvc = useDi(FormatService);

    const confidenceStartIndex = props.data.findIndex((d) => d.Date > props.confidenceStartDate);
    const lastSamePointIndex = confidenceStartIndex > 0 ? confidenceStartIndex - 1 : 0;
    const lastSamePointValue = props.data[lastSamePointIndex].Date;
    const tickSpacing = useTickSpacing(3, 3);

    const { combinedData, monthTotals, years, maxDate, minValue } = useMemo(() => {
        const dataTraining = data
            .filter((d) => d.id === 'P50')
            .map((d) => ({
                id: 'Cost',
                data: d.data.slice(0, confidenceStartIndex),
            }));
        const dataForecast = data.map((d) => ({
            ...d,
            data: d.data.slice(confidenceStartIndex - 1),
        }));

        let minValue = 0;
        const combinedData = [...dataTraining, ...dataForecast];
        const allData = combinedData.flatMap((d) => d.data);
        const dates = allData.map((item) => fmtSvc.parseDateNoTime(item.x as string));
        const monthTotalsLookup = combinedData.reduce((result, item) => {
            for (const pt of item.data as { x: string; y: number }[]) {
                if (item.id === 'Cost' || (pt.x > props.confidenceStartDate && item.id === 'P50')) {
                    const monthKey = pt.x.toString().substring(0, 7);
                    let month = result.get(monthKey);
                    if (!month) {
                        result.set(monthKey, (month = { start: pt.x, end: pt.x, total: 0 }));
                    }
                    month.end = month.end < pt.x ? pt.x : month.end;
                    month.start = month.start > pt.x ? pt.x : month.start;
                    month.total += pt.y as number;
                }
                minValue = Math.min(minValue, pt.y as number);
            }
            return result;
        }, new Map<string, { start: string; end: string; total: number }>());
        const monthTotals = [...monthTotalsLookup.values()].sort((a, b) => (a.start < b.start ? -1 : 1));
        const yearsLookups = dates.reduce((result, item) => {
            const yearKey = item.getFullYear();
            let year = result.get(yearKey);
            if (!year) {
                result.set(yearKey, (year = { start: item, end: item }));
            }
            year.end = item > year.end ? item : year.end;
            year.start = item < year.start ? item : year.start;
            return result;
        }, new Map<number, { start: Date; end: Date }>());
        const years = [...yearsLookups.values()].sort((a, b) => (a.start < b.start ? -1 : 1));
        const maxDate = dates.reduce((max, d) => (d > max ? d : max), dates[0]);
        return { combinedData, allData, dates, monthTotals, years, maxDate: fmtSvc.toJsonShortDate(maxDate), minValue };
    }, [data, confidenceStartIndex]);

    const MonthTotalsComponent = useMemo(
        () => (props: { xScale: any; yScale: any }) => <MonthTotals {...props} totals={monthTotals} minValue={minValue} />,
        [monthTotals, minValue]
    );

    const margin = props.settings?.margin || { bottom: 60, left: 50, right: 10, top: 10 };
    const yFormatter = getYFormatter(props);
    const theme = useMantineTheme();
    const showLines = props.settings?.mode === 'trend-curve' ? false : true;
    const curve = props.settings?.mode === 'trend-curve';

    return (
        <>
            <ResponsiveLine
                data={combinedData}
                margin={margin}
                useMesh={true}
                animate
                colors={(d) => (d.id === 'Cost' ? '#31C2FF' : d.id === 'P50' ? '#FDB022' : '00')}
                enableSlices={showLines ? 'x' : false}
                enablePoints={props.settings?.hidePoints ? false : showLines}
                curve={curve ? 'basis' : undefined}
                yFormat={yFormatter}
                yScale={{
                    type: 'linear',
                    stacked: props.settings?.stacked ?? false,
                    min: minValue,
                    max: props.settings?.yMax ?? 'auto',
                }}
                areaBlendMode="normal"
                axisLeft={
                    !showLines
                        ? null
                        : {
                              renderTick: ({ x, y, textX, textY, rotate, ...props }) => {
                                  return (
                                      <g transform={`translate(${x},${y})`}>
                                          <text
                                              dominantBaseline={props.textBaseline}
                                              textAnchor={props.textAnchor}
                                              transform={`translate(${textX},${textY}) rotate(${rotate})`}
                                              style={{ fontSize: theme.fontSizes.xs + 'px' }}
                                          >
                                              {yFormatter(props.value)}
                                          </text>
                                      </g>
                                  );
                              },
                          }
                }
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: theme.white,
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                                minWidth: 200,
                            }}
                        >
                            <div style={{ fontSize: '0.8em' }}>
                                <strong>Date:</strong>{' '}
                                {props.settings?.interval === 'month'
                                    ? fmtSvc.formatShortMonthYear(fmtSvc.parseDateNoTime(slice.points[0].data.x as string))
                                    : fmtSvc.toShortDate(fmtSvc.parseDateNoTime(slice.points[0].data.x as string), true)}
                            </div>
                            {slice.points.map((point) => (
                                <Group
                                    key={point.id}
                                    position="apart"
                                    px="xs"
                                    py={3}
                                    sx={{ fontWeight: point.serieId === 'P50' ? 'bold' : 'normal' }}
                                >
                                    <Text size={point.serieId === 'P90' || point.serieId === 'P10' ? 'sm' : undefined}>
                                        {point.serieId === 'P50' ? 'Median' : point.serieId}:{' '}
                                    </Text>
                                    <Text size={point.serieId === 'P90' || point.serieId === 'P10' ? 'sm' : undefined}>{point.data.yFormatted}</Text>
                                </Group>
                            ))}
                        </div>
                    );
                }}
                axisBottom={{
                    renderTick: ({ x, y, textX, textY, rotate, ...props }) => {
                        const date = fmtSvc.parseDateNoTime(props.value);
                        const isFirstOfWeek = date?.getDay() === 0;
                        const { line: showMinor } = tickSpacing.next(x);

                        return isFirstOfWeek ? (
                            <line x1={x} x2={x} y1={y} y2={y + 7} stroke="#000" strokeWidth={1}></line>
                        ) : showMinor ? (
                            <line x1={x} x2={x} y1={y} y2={y + 5} stroke="#0003" strokeWidth={1}></line>
                        ) : (
                            <></>
                        );
                    },
                }}
                layers={[
                    ({ xScale, yScale }) => (
                        <>
                            <rect
                                shapeRendering="crispEdges"
                                x={-1}
                                y={-30}
                                width={2 + (xScale(maxDate as unknown as number) as number)}
                                height={(yScale(minValue) as number) + 150}
                                fill="#fff"
                                stroke="#0003"
                                strokeWidth={0.5}
                            />
                        </>
                    ),
                    'axes',
                    'areas',
                    // Custom layer to draw confidence area
                    ({ series, lineGenerator, xScale, yScale }) => {
                        const costSeries = series.find((serie) => serie.id === 'Cost');
                        const p90Series = series.find((serie) => serie.id === 'P90');
                        const p50Series = series.find((serie) => serie.id === 'P50');
                        const p10Series = series.find((serie) => serie.id === 'P10');

                        if (!costSeries || !p90Series || !p10Series || !p50Series) {
                            return null;
                        }

                        const costAreaPoints = [
                            { x: xScale(costSeries.data[0].data.x as number), y: yScale(0) },
                            ...costSeries.data.map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) })),
                            { x: xScale(costSeries.data[costSeries.data.length - 1].data.x as number), y: yScale(0) },
                        ];

                        const confidenceAreaLightTop = [
                            ...p50Series.data.map((point, index) => ({
                                x: xScale(point.data.x as number),
                                y: yScale(((p90Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                            })),
                            ...p90Series.data.map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) })).reverse(),
                        ];

                        const confidenceAreaLightBottom = [
                            ...p50Series.data.map((point, index) => ({
                                x: xScale(point.data.x as number),
                                y: yScale(((p10Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                            })),
                            ...p10Series.data.map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) })).reverse(),
                        ];

                        const confidenceAreaDark = [
                            ...p50Series.data.map((point, index) => ({
                                x: xScale(point.data.x as number),
                                y: yScale(((p90Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                            })),
                            ...p50Series.data
                                .map((point, index) => ({
                                    x: xScale(point.data.x as number),
                                    y: yScale(((p10Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                                }))
                                .reverse(),
                        ];

                        const costAreaPath = lineGenerator(costAreaPoints as any) + 'Z';
                        const confidenceAreaPathLightTop = lineGenerator(confidenceAreaLightTop as any) + 'Z';
                        const confidenceAreaPathLightBottom = lineGenerator(confidenceAreaLightBottom as any) + 'Z';
                        const confidenceAreaPathDark = lineGenerator(confidenceAreaDark as any) + 'Z';

                        return (
                            <>
                                <path d={costAreaPath} fill="#EFFAFF" />
                                <path d={confidenceAreaPathLightTop} fill="#FEF0C7" />
                                <path d={confidenceAreaPathLightBottom} fill="#FEF0C7" />
                                <path d={confidenceAreaPathDark} fill="#FEDF89" />
                            </>
                        );
                    },
                    // Custom layer to draw the vertical line, dividing the training data and forecast data
                    ({ xScale, yScale }) =>
                        lastSamePointIndex === 0 ? null : (
                            <line
                                x1={xScale(lastSamePointValue as number) as number}
                                x2={xScale(lastSamePointValue as number) as number}
                                y1={0}
                                y2={yScale(minValue) as number}
                                stroke="#FDB022"
                                strokeWidth={2}
                            />
                        ),
                    'lines',
                    'slices',
                    'mesh',
                    'legends',
                    MonthTotalsComponent,
                    ({ xScale, yScale }) => (
                        <>
                            <line
                                shapeRendering="crispEdges"
                                x1={0}
                                x2={2 + (xScale(maxDate as unknown as number) as number)}
                                y1={yScale(0) as number}
                                y2={yScale(0) as number}
                                stroke="#0006"
                                strokeWidth={0.5}
                            />
                        </>
                    ),
                    // Custom layer to add horizontal lines for each year
                    ({ xScale, yScale }) => {
                        const linePosition = (yScale(minValue) as number) + 55;
                        return (
                            <>
                                {years.map((year, index) => {
                                    const start = year.start;
                                    const end = years.length > index + 1 ? years[index + 1].start : year.end;
                                    const startX = xScale(fmtSvc.toJsonShortDate(start) as unknown as number) as number;
                                    const endX = xScale(fmtSvc.toJsonShortDate(end) as unknown as number) as number;
                                    const middleX = (endX - startX) / 2;

                                    const yearTextWidth = 20;
                                    const colors = ['#00A79D', '#5C4B8C', '#2C607B'];
                                    return endX - startX < 50 ? null : (
                                        <React.Fragment key={index}>
                                            <line
                                                x1={startX + 3}
                                                x2={startX + middleX - yearTextWidth}
                                                y1={linePosition}
                                                y2={linePosition}
                                                stroke={colors[index % colors.length]}
                                                strokeWidth={2}
                                            />
                                            <text x={startX + middleX} y={linePosition} textAnchor="middle" dominantBaseline="middle" fontSize="12px">
                                                {year.start.getFullYear().toString()}
                                            </text>
                                            <line
                                                x1={startX + middleX + yearTextWidth}
                                                x2={endX - 3}
                                                y1={linePosition}
                                                y2={linePosition}
                                                stroke={colors[index % colors.length]}
                                                strokeWidth={2}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        );
                    },
                    'crosshair',
                ]}
            />
        </>
    );
}

function MonthTotals({
    totals,
    xScale,
    yScale,
    minValue,
}: {
    xScale: (value: string) => number;
    yScale: (value: number) => number;
    totals: { start: string; end: string; total: number }[];
    minValue: number;
}) {
    const fmtSvc = useDi(FormatService);
    const months = useMemo(() => {
        return totals.map((item) => {
            const startX = xScale(item.start) as number;
            const endX = xScale(item.end) as number;
            const narrow = endX - startX < 100;
            const middleX = (startX + endX) / 2;
            const y = yScale(minValue) + 19;
            const from = fmtSvc.parseDateNoTime(item.start);
            const to = fmtSvc.parseDateNoTime(item.end);
            const label = narrow ? format(from, 'MMM d') + ' - ' + format(to, 'd') : format(from, 'MMM do') + ' — ' + format(to, 'do');
            return { startX, endX, middleX, y, label, total: fmtSvc.formatMoneyNoDecimals(item.total), skip: endX - startX < 50, narrow };
        });
    }, [totals, xScale, yScale]);
    return (
        <>
            {months
                .filter((m) => !m.skip)
                .map((m) => (
                    <Fragment key={m.startX}>
                        {m.startX === 0 ? null : (
                            <line
                                x1={m.startX}
                                x2={m.startX}
                                y1={m.y - 8}
                                y2={m.y + 25}
                                shapeRendering="crispEdges"
                                stroke="#0003"
                                strokeWidth={1}
                            ></line>
                        )}
                        <text x={m.middleX} y={m.y} textAnchor="middle" dominantBaseline="middle" fontSize="12px">
                            {m.label}
                        </text>
                        <text x={m.middleX} y={m.y + 16} textAnchor="middle" dominantBaseline="middle" fontSize={m.narrow ? '14px' : '16px'}>
                            {m.total}
                        </text>
                    </Fragment>
                ))}
        </>
    );
}

interface ConfidenceChartProps<T extends Record<string, any>> extends StandardChartProps<string | number | Date, T> {
    confidenceStartDate: string;
    settings?: ConfidenceChartSettings;
}

export interface ConfidenceChartSettings {
    labelAngle?: number;
    margin?: ChartMargin;
    topN?: number;
    stacked?: boolean;
    interval?: string;
    format?: 'percent' | 'int' | 'float' | 'money' | 'money-whole';
    direction?: 'up' | 'down';
    mode?: 'trend-curve' | 'trend-line';
    chartColors?: string[];
    enableArea?: boolean;
    hideXAxis?: boolean;
    hidePoints?: boolean;
    yMax?: number;
    monthTotals?: boolean;
}

function getYFormatter(props: ConfidenceChartProps<any>): (value: Date | number | string) => string {
    const fmtSvc = container.resolve(FormatService);
    switch (props.settings?.format?.toLowerCase()) {
        case 'percent':
            return (value) => (typeof value === 'number' ? value : 0).toFixed(0) + '%';
        case 'int':
            return (value) => (typeof value === 'number' ? fmtSvc.formatInt(value) : '0');
        case 'money':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoney(value) : '0.00');
        case 'money-whole':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoneyNoDecimals(value) : '0');
        case 'bytes':
            return (value) => (typeof value === 'number' ? fmtSvc.formatBytes(value, null) : '0');
        default:
            return (value) => value?.toString();
    }
}
