import { ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { EventEmitter } from '@root/Services/EventEmitter';
import { chartColors } from '../Charts/Common';

export class BaseChartKeySelectionStrategy<T> implements ISelectionStrategy<T> {
    private readonly selectionLookup = new Map<T, string>();
    private selections: T[] = [];
    private readonly colors: string[];
    public constructor(private readonly maxSelections: number, private allSelected = true) {
        this.colors = chartColors.slice(0, maxSelections);
    }
    public selectionChanged = EventEmitter.empty();
    public isSelected(item: T) {
        return this.allSelected || this.selectionLookup.has(item);
    }
    public getSelectAllValidity() {
        return undefined;
    }
    public isAllSelected() {
        return this.allSelected;
    }
    public getSelectionColor(item: T) {
        return this.selectionLookup.get(item);
    }
    public setSelected(item: T, selected: boolean) {
        if (this.allSelected) {
            this.allSelected = false;
            this.selectionLookup.set(item, this.colors.shift()!);
            this.selections.push(item);
        } else {
            if (selected) {
                while (this.selections.length >= this.maxSelections) {
                    const removed = this.selections.shift();
                    if (removed) {
                        this.colors.push(this.selectionLookup.get(removed)!);
                        this.selectionLookup.delete(removed);
                    }
                }
                this.selections.push(item);
                this.selectionLookup.set(item, this.colors.shift()!);
            } else {
                this.colors.unshift(this.selectionLookup.get(item)!);
                this.selectionLookup.delete(item);
                if (this.selectionLookup.size === 0) {
                    this.allSelected = true;
                }
            }
        }
        this.resolveSelections();
    }
    public async setSelectAll(selected: boolean) {
        this.allSelected = true;
        this.colors.push(...chartColors.slice(0, this.maxSelections));
        this.selectionLookup.clear();
        this.resolveSelections();
    }
    public async getSelected() {
        return this.selections;
    }
    public count() {
        return this.allSelected ? Infinity : this.selectionLookup.size;
    }

    private resolveSelections() {
        this.selections = this.selections.filter((s) => this.selectionLookup.has(s));
        this.selectionChanged.emit();
    }
}
