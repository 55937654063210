import { CompanyStat, CompanyType } from '@apis/Customers/model';
import type { Role, UserListItem } from '@apis/Customers/model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    deleteCompanyBulkDeleteCompanies,
    getRbacGetRolesByCompany,
    getUserGetCompanyUsers,
    postRbacBulkAssignMspUsers,
    postRbacBulkRemoveMspUsers,
    postSubscriptionHandleBulkActivateCompanies,
} from '@apis/Customers';
import { useDi, useDiComponent } from '@root/Services/DI';
import { Button, Divider, Group, MultiSelect, Popover, Select, SelectItem, Space, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { colorPalette } from '@root/Design/Themes';
import { MspService } from '@root/Services/MspService';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useEvent } from '@root/Services/EventEmitter';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import styled from '@emotion/styled';
import { confirmAction } from '../../Components/CompanyInfo/Administration/ConfirmActionDialog';
import { CompanyStatsGrid } from '@root/Components/CompanyStats/CompanyStatsGrid';
import { AddCompanyInfo } from '@root/Components/CompanyStats/AddCompanyInfo';
export interface MyCompaniesGridProps {
    selectCompany: (selectedCompanyId: number) => void;
}

export const MyCompaniesGrid = (props: MyCompaniesGridProps) => {
    const refreshNeeded = useMemo(() => EventEmitter.empty(), []);
    const refChanged = useMemo(() => EventEmitter.empty(), []);
    const { canAdd } = useAuthZValues({ canAdd: { Customer: 'Manage' } });
    const mspSvc = useDi(MspService);
    const [enableBulkActions, setEnableBulkAcctions] = useState(false);
    const [bulkActionsOpened, setBulkActionsOpened] = useState(false);
    const [usersList, setUsersList] = useState<UserListItem[]>([]);
    const [rolesList, setRolesList] = useState<Role[]>([]);
    let selectedCompanies = useRef<number[]>([]);
    let selectedUsers = useRef<number[]>([]);
    let selectedRole = useRef<number>();

    useEvent(refChanged);

    const loadUsers = () => {
        getUserGetCompanyUsers().then((users) => {
            setUsersList(() => users);
        });
    };

    const loadRoles = () => {
        getRbacGetRolesByCompany().then((roles) => {
            setRolesList(() =>
                roles
                    .filter((r) => (r.CompanyType == null || r.CompanyType == CompanyType.Customer) && r.CompanyId == null)
                    .sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''))
            );
        });
    };

    useEffect(() => {
        loadUsers();
        loadRoles();
    }, []);

    useEffect(() => {
        if (selectedCompanies.current.length > 0) {
            setEnableBulkAcctions(true);
        } else if (selectedCompanies.current.length == 0) {
            setEnableBulkAcctions(false);
        }
    }, [selectedCompanies]);

    useEvent(mspSvc.companyListChanged, () => {
        refreshNeeded.emit();
    });

    const onCheckedCompanyChange = useCallback(async (items: CompanyStat[] | undefined) => {
        let hasCheckedItems = items && items?.length > 0;
        setEnableBulkAcctions(hasCheckedItems!);
        if (hasCheckedItems) {
            let companiesIds = items?.map((i) => +i.Id!) as number[];
            selectedCompanies.current = companiesIds;
            refChanged.emit();
        }
    }, []);

    const modals = useModals();
    const DiContainer = useDiComponent();

    const openAddCompanyModal = () => {
        const id = modals.openModal({
            title: (
                <Text sx={{ padding: '0px 0px 0px 16px', margin: '0px' }} size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Add Company
                </Text>
            ),
            children: (
                <DiContainer>
                    <AddCompanyInfo
                        onClose={() => {
                            modals.closeModal(id);
                        }}
                        onSubmit={(companyId) => {
                            refreshNeeded.emit();
                            modals.closeModal(id);
                            props.selectCompany(companyId);
                        }}
                    />
                </DiContainer>
            ),
            closeOnClickOutside: false,
        });
    };

    async function handleOnSelectUsers(e: string[]) {
        let values = e.map((val) => +val);
        selectedUsers.current = values;
    }

    async function handleOnSelectRole(value: string) {
        selectedRole.current = +value;
    }

    const openAssignUsersModal = () => {
        setBulkActionsOpened(false);
        const id = modals.openModal({
            size: 'lg',
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Assign MSP Users
                </Text>
            ),
            children: (
                <>
                    <Text size={15} weight={400} color={colorPalette.subTextColor} align="left">
                        Select users to assign to the selected companies.
                    </Text>
                    <MultiSelectWraper>
                        <MultiSelect
                            label="Select Users"
                            searchable
                            data={usersList.map((i) => {
                                return {
                                    label: !i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName,
                                    value: i.Id?.toString(),
                                } as SelectItem;
                            })}
                            onChange={handleOnSelectUsers}
                        />
                    </MultiSelectWraper>

                    <MultiSelectWraper>
                        <Select
                            label="Select Role"
                            searchable
                            data={rolesList.map((i) => {
                                return { label: i.Name, value: i.Id?.toString() } as SelectItem;
                            })}
                            onChange={handleOnSelectRole}
                        />
                    </MultiSelectWraper>
                    <Space h="xl" />
                    <Group position="right">
                        <Button
                            variant="outline"
                            sx={{ backgroundColor: colorPalette.white, width: '190px' }}
                            onClick={() => {
                                modals.closeModal(id);
                                selectedUsers.current = [];
                            }}
                        >
                            <Text weight={600} color={colorPalette.subHeaderTextColor} size={16}>
                                Cancel
                            </Text>
                        </Button>
                        <Button sx={{ width: '190px' }} type="submit">
                            <Text
                                weight={600}
                                color={colorPalette.white}
                                size={16}
                                onClick={() => {
                                    assignUsersAndRoles(id);
                                }}
                            >
                                Assign Users
                            </Text>
                        </Button>
                    </Group>
                </>
            ),
        });
    };

    const assignUsersAndRoles = (modalId: string) => {
        postRbacBulkAssignMspUsers({
            CompaniesIds: selectedCompanies.current,
            UsersIds: selectedUsers.current,
            RoleId: selectedRole.current,
        }).then(() => {
            modals.closeModal(modalId);
        });
    };

    const openRemoveUsersModal = () => {
        setBulkActionsOpened(false);
        const id = modals.openModal({
            size: 'lg',
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Remove MSP Users
                </Text>
            ),
            children: (
                <>
                    <Text size={15} weight={400} color={colorPalette.subTextColor} align="left">
                        Which MSP users do you want to remove from the selected companies?
                    </Text>
                    <MultiSelectWraper>
                        <MultiSelect
                            label="Select Users to Remove"
                            searchable
                            data={usersList.map((i) => {
                                return {
                                    label: !i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName,
                                    value: i.Id?.toString(),
                                } as SelectItem;
                            })}
                            onChange={handleOnSelectUsers}
                        />
                    </MultiSelectWraper>
                    <Space h="xl" />
                    <Group position="right">
                        <Button
                            variant="outline"
                            sx={{ backgroundColor: colorPalette.white, width: '190px' }}
                            onClick={() => {
                                modals.closeModal(id);
                                selectedUsers.current = [];
                            }}
                        >
                            <Text weight={600} color={colorPalette.subHeaderTextColor} size={16}>
                                Cancel
                            </Text>
                        </Button>
                        <Button sx={{ width: '190px' }} type="submit">
                            <Text
                                weight={600}
                                color={colorPalette.white}
                                size={16}
                                onClick={() => {
                                    removeUsersAndRoles(id);
                                }}
                            >
                                Remove Users
                            </Text>
                        </Button>
                    </Group>
                </>
            ),
        });
    };

    const removeUsersAndRoles = (modalId: string) => {
        postRbacBulkRemoveMspUsers({
            CompaniesIds: selectedCompanies.current,
            UsersIds: selectedUsers.current,
        }).then(() => {
            modals.closeModal(modalId);
        });
    };

    const confirmBulkActivateCompanies = () => {
        setBulkActionsOpened(false);
        confirmAction({
            headerText: 'Activate Companies?',
            promptText: `Are you sure you want to activate the selected companies?`,
            confirmButtonText: 'Yes, activate',
            cancelButtonText: "No, don't activate",
            confirmAction: () => handleBulkCompanyActivation(true),
        });
    };

    const confirmBulkDeactivateCompanies = () => {
        setBulkActionsOpened(false);
        confirmAction({
            headerText: 'Deactivate Companies?',
            promptText: `Are you sure you want to deactivate the selected companies?`,
            confirmButtonText: 'Yes, deactivate',
            cancelButtonText: "No, don't deactivate",
            confirmAction: () => handleBulkCompanyActivation(false),
        });
    };

    const handleBulkCompanyActivation = async (isActive: boolean) => {
        await postSubscriptionHandleBulkActivateCompanies(selectedCompanies.current, { isActive: isActive });
        refreshNeeded.emit();
    };

    const confirmBulkDeleteCompanies = () => {
        setBulkActionsOpened(false);
        confirmAction({
            headerText: 'Delete Companies?',
            promptText: `Are you sure you want to permanently delete the selected companies?`,
            confirmButtonText: 'Yes, delete',
            cancelButtonText: "No, don't delete",
            confirmAction: () => bulkDeleteCompanies(),
        });
    };

    const bulkDeleteCompanies = async () => {
        await deleteCompanyBulkDeleteCompanies(selectedCompanies.current);
        refreshNeeded.emit();
    };

    const buttons = (
        <>
            <Space w="sm" />
            <Popover width={200} position="bottom" withArrow shadow="md" opened={bulkActionsOpened} onChange={setBulkActionsOpened}>
                <Popover.Target>
                    <Button
                        onClick={() => setBulkActionsOpened((o) => !o)}
                        disabled={!enableBulkActions}
                        style={{ marginRight: '15px' }}
                        rightIcon={<i className="ti ti-chevron-down" />}
                    >
                        Actions
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <BulkActionMenuOption
                        onClick={() => {
                            openAssignUsersModal();
                        }}
                    >
                        Assign MSP Users
                    </BulkActionMenuOption>
                    <BulkActionMenuOption
                        onClick={() => {
                            openRemoveUsersModal();
                        }}
                    >
                        Remove MSP Users
                    </BulkActionMenuOption>
                    <Divider color="gray.3" />
                    <BulkActionMenuOption
                        onClick={() => {
                            confirmBulkActivateCompanies();
                        }}
                    >
                        Activate Company
                    </BulkActionMenuOption>
                    <BulkActionMenuOption
                        onClick={() => {
                            confirmBulkDeactivateCompanies();
                        }}
                    >
                        Deactivate Company
                    </BulkActionMenuOption>
                    <Divider color="gray.3" />
                    <BulkActionMenuOption
                        onClick={() => {
                            confirmBulkDeleteCompanies();
                        }}
                    >
                        Delete Company
                    </BulkActionMenuOption>
                </Popover.Dropdown>
            </Popover>

            {canAdd && (
                <Button
                    leftIcon={<i className="ti ti-plus" />}
                    variant="outline"
                    data-atid="UpdateKeysButton"
                    onClick={() => {
                        openAddCompanyModal();
                    }}
                >
                    Add Company
                </Button>
            )}
        </>
    );

    return (
        <CompanyStatsGrid
            selectCompany={props.selectCompany}
            onCheckedCompanyChange={onCheckedCompanyChange}
            rightTopPlaceHolder={buttons}
            refreshNeeded={refreshNeeded}
        />
    );
};

const BulkActionMenuOption = styled.div`
    cursor: pointer;
    height: 30px;
    margin-top: 15px;
`;

const MultiSelectWraper = styled.div`
    margin-top: 15px;
`;
