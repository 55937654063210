import { endpoint } from '@root/Services/Router/EndpointRegistry';
import '@root/Components/Resources/resources.scss';
import { GetResourceDetailsTabs } from '@root/Components/Resources/ResourceDetailsTabs';

export function AwsResourcesLanding(props: { resourceType: string; resourceId: string }) {
    var resourceType = props.resourceType;
    var resourceId = props.resourceId;

    return (
        <div className="resourcesContainer">
            <GetResourceDetailsTabs resourceType={resourceType} resourceId={resourceId} />
        </div>
    );
}

endpoint('aws', AwsResourcesLanding, 'Aws Resources');
