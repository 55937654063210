import { usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { NotFound } from './NotFound';

export function GetResourceIpAddresses(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <NotFound description="No resource found"></NotFound>;
        } else {
            var resourceValues = [];
            const resource = data.Results[0] as { NetworkInterfaces: { PrivateIpAddresses: { PrivateIpAddress: string }[] }[] };

            switch (props.resourceType) {
                case 'EC2 On Demand':
                    for (var j = 0; j < resource.NetworkInterfaces[0].PrivateIpAddresses.length; j++) {
                        resourceValues.push({
                            label: 'Private IP Address',
                            value: resource.NetworkInterfaces[0].PrivateIpAddresses[j].PrivateIpAddress,
                        });
                    }
                    break;

                default:
                    return <>invalid type</>;
                    break;
            }

            if (resourceValues.length == 0) {
                retValue = <NotFound description="No ip addresses"></NotFound>;
            } else {
                let valuesArray = [];
                for (var i = 0; i < resourceValues.length; i++) {
                    valuesArray.push(
                        <tr key={'ipr' + i}>
                            <td className="label">{resourceValues[i].label}</td>
                            <td className="value">{resourceValues[i].value}</td>
                        </tr>
                    );
                }
                retValue = (
                    <div className="resourcesTableContainer">
                        <Table className="resourcesTable">
                            <tbody>{valuesArray}</tbody>
                        </Table>
                    </div>
                );
            }
        }
    }

    return retValue;
}

export function ResourceIpAddresses() {
    return <>Resource IP Addresses</>;
}
endpoint('resource', ResourceIpAddresses, 'Resource Ip Addresses');
