import { UserListItem } from '@apis/Customers/model';
import { ExportRequest } from '@apis/Export/model';
import { AnonymousJob } from '@apis/Resources';
import { Box, Card, Group, Loader, LoadingOverlay, Progress, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useNav } from '@root/Services/NavigationService';
import { useEffect, useMemo, useState } from 'react';
import { inject, injectable } from 'tsyringe';
import { ActivityDetailsService } from './ActivityDetailsService';
import { ActivityItemAdapter } from './ActivityTypes';
import { ActivityDetailsContainer, DetailRow, RowDivider } from './Design';
import { ActivityDetailsProps } from './Models';

export function ExportActivityDetails({ item: jobStatus }: ActivityDetailsProps) {
    const nav = useNav();
    const authSvc = useDi(AuthenticationService);
    const activityAdapater = useDi(ActivityItemAdapter);
    const currentUser = authSvc.user;
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(ExportDetailsModel).init(jobStatus.job), []);
    const item = model.job ?? jobStatus.job;
    const formatSvc = useDi(FormatService);
    const displayInfo = activityAdapater.getActivityModel({ job: item, status: jobStatus.status });
    const inProgress = item.Status !== 'Canceled' && item.Status !== 'Failed' && item.Status !== 'Succeeded';
    const loading = useEventValue(model.loading);

    const dateNow = new Date();
    var jobFinishedDate = formatSvc.toLocalDate(item.FinishedAt);
    jobFinishedDate.setDate(jobFinishedDate.getDate() + 30);

    const downloadExpired = dateNow > jobFinishedDate;
    const downloadReady = displayInfo.action && !downloadExpired;
    const canDownload = displayInfo.action && currentUser?.Id === item.RequestedById;

    const showProgress = inProgress;
    const failed = item.Status === 'Failed';
    const exportData = item.Parameters as ExportRequest;
    const dataType =
        exportData.Target === 'ResourcesQuery' ? 'Resource' : exportData.Target === 'RecommendationQuery' ? 'Recommendations' : 'Unknown';
    const result = item.ResultDetail as { Bytes: number; ContentType?: string };
    const extension = exportData.Type === 'PDF' ? 'pdf' : result.ContentType === 'text/csv' ? 'csv' : 'xlsx';
    const recordCount = item.Progress?.Total ? formatSvc.formatInt(item.Progress?.Total - 1) : '';
    const fileName = `${dataType} - ${recordCount} records.${extension}`;
    const Icon = displayInfo.icon;
    const progress = item.Progress && item.Progress.Total && item.Progress.Progress ? Math.min(1, item.Progress.Progress / item.Progress.Total) : 0;
    const [downloading, setDownloading] = useState<boolean>(false);
    const tooBig = !!item.FailureMessage?.includes('Excel cannot load more than');
    const errorMessage = tooBig ? 'Export would be more than one million records which is not supported by Excel' : 'Please contact support';

    const handleDownloadClick = async () => {
        setDownloading(true);
        try {
            if (canDownload) {
                await displayInfo.action?.execute(nav);
            }
        } finally {
            setDownloading(false);
        }
    };
    useEffect(() => {
        return () => model.dispose();
    }, []);
    useEvent(model.refreshed);

    return loading ? (
        <LoadingOverlay visible />
    ) : (
        <ActivityDetailsContainer>
            <DetailRow title="Description">{displayInfo.description}</DetailRow>
            <RowDivider />
            <DetailRow title="Started">{formatSvc.toLocal(item.StartedAt)}</DetailRow>
            {inProgress ? null : <DetailRow title="Finished">{formatSvc.toLocal(item.FinishedAt)}</DetailRow>}
            <RowDivider />
            <DetailRow title="Requested By">{model.getRequestedBy()}</DetailRow>
            <RowDivider />
            {downloadExpired && (
                <Card radius="lg" withBorder shadow="md" m="xl">
                    <Group>
                        <ThemeIcon variant="light" color="gray" size="xl" radius="xl">
                            <Icon />
                        </ThemeIcon>
                        <Box sx={{ flex: 1 }}>
                            <Text color="dimmed" weight="bold">
                                Export Expired
                            </Text>
                            <Text color="dimmed">Please run the export again</Text>
                        </Box>
                    </Group>
                </Card>
            )}
            {downloadReady && (
                <Tooltip hidden={canDownload} position="bottom" label={`Download is only available to ${model.getRequestedBy()}`}>
                    <Card
                        component="a"
                        sx={{ cursor: canDownload ? 'pointer' : 'not-allowed' }}
                        onClick={handleDownloadClick}
                        radius="lg"
                        withBorder
                        shadow="md"
                        m="xl"
                    >
                        <Group>
                            <ThemeIcon color={canDownload ? 'primary' : 'gray'} variant="light" size="xl" radius="xl">
                                {downloading ? <Loader /> : <Icon />}
                            </ThemeIcon>
                            <Box sx={{ flex: 1 }}>
                                <Text color={canDownload ? 'primary' : 'gray'} weight="bold">
                                    {fileName}
                                </Text>
                                <Text size="sm">Download - {formatSvc.formatBytes(result.Bytes)}</Text>
                            </Box>
                        </Group>
                    </Card>
                </Tooltip>
            )}
            {showProgress && (
                <Card radius="lg" withBorder shadow="md" m="xl">
                    <Group>
                        <ThemeIcon variant="light" color="gray" size="xl" radius="xl">
                            <Icon />
                        </ThemeIcon>
                        <Box sx={{ flex: 1 }}>
                            <Text color="dimmed" weight="bold">
                                Export in Progress
                            </Text>
                            <Progress mt="sm" value={progress * 100} />
                        </Box>
                    </Group>
                </Card>
            )}
            {failed && (
                <DetailRow title="Status">
                    <Text color="error">Failed</Text>
                    <Text color="dimmed">{errorMessage}</Text>
                </DetailRow>
            )}
        </ActivityDetailsContainer>
    );
}

@injectable()
class ExportDetailsModel {
    public loading = new EventEmitter(true);
    public refreshed = EventEmitter.empty();
    public job?: AnonymousJob;
    public requestedBy?: UserListItem;
    private disposed = false;
    private readonly pollFrequency = 3000;

    public constructor(
        @inject(ActivityDetailsService) public readonly activitySvc: ActivityDetailsService,
        @inject(JobService) public readonly jobService: JobService,
        @inject(PollingService) private readonly poller: PollingService
    ) {}

    public init(job: AnonymousJob) {
        this.job = job;
        this.initialize();
        return this;
    }

    private async initialize() {
        this.job = (await this.jobService.getJobById(this.job!.Id!)) as AnonymousJob;
        this.poller.pollUntil(
            async () => {
                this.job = (await this.jobService.getJobById(this.job!.Id!)) as AnonymousJob;
                this.refreshed.emit();
                return this.job;
            },
            (r) => this.disposed || r.Status === 'Canceled' || r.Status === 'Failed' || r.Status === 'Succeeded',
            this.pollFrequency
        );
        await this.reload();
    }

    public async reload() {
        this.loading.emit(true);
        try {
            const users = await this.activitySvc.getUsers();
            this.requestedBy = users.find((u) => u.Id === this.job?.RequestedById);
        } finally {
            this.loading.emit(false);
        }
    }

    public dispose() {
        this.disposed = true;
    }

    public getRequestedBy() {
        return !this.job?.RequestedById || !this.requestedBy
            ? 'System'
            : this.requestedBy.FirstName
            ? `${this.requestedBy.FirstName} ${this.requestedBy.LastName}`
            : this.requestedBy.EMail;
    }
}
