import { closeModal, useModals } from '@mantine/modals';
import { Box, Button, Group, Space, Text, Title, Tooltip } from '@mantine/core';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import {
    getPaymentGetAwsPublicOfferUrl,
    getPaymentGetAzurePublicOfferUrl,
    getPaymentGetHostedPage,
    useGetPaymentGetPaymentSources,
    useGetPaymentGetPrimaryPayment,
} from '@apis/Customers';
import { HostedPage, PaymentSource } from '@apis/Customers/model';
import { useEffect, useState } from 'react';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import {
    AccountHolder,
    CardHolder,
    CardModal,
    CspHolder,
    CspModal,
    NumberCircle,
    PaymentList,
    useAddPaymentMethod,
} from '../../../Site/Settings/Billing/PaymentMethods';
import { Observer } from 'mobx-react';
import { AdminPageTitle, AdminPanel } from './Design';
import { useDiComponent } from '@root/Services/DI';

export interface AdminPaymentMethodsProps {
    model: CompanyAdministrationPanelModel;
}

export const AdminPaymentMethods = (props: AdminPaymentMethodsProps) => {
    const { data: savedPayments, refetch } = useGetPaymentGetPaymentSources();
    const { data: primaryPayment, refetch: refetchPrimary } = useGetPaymentGetPrimaryPayment();
    const [hostedPage, setHostedPage] = useState<HostedPage>();

    useEffect(() => {
        (async () => {
            setHostedPage(await getPaymentGetHostedPage());
        })();
    }, []);

    const reload = () => {
        refetch();
        refetchPrimary();
    };

    const { openPaymentsModal } = useAddPaymentMethod(reload, hostedPage);

    const nonPrimarySources = savedPayments?.filter((p) => p.Id != primaryPayment?.Id && p.CloudPartner?.Status != 'Deleted');
    const nonPrimaryCards = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'Card');
    const nonPrimaryBankAccounts = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'DirectDebit');
    const nonPrimaryCSPs = nonPrimarySources?.filter((p) => p.PaymentSourceType == 'CSP');

    return (
        <AdminPanel>
            <Group position="apart">
                <AdminPageTitle>Payment Methods</AdminPageTitle>
                <Group>
                    <Button
                        data-atid="AddPaymentButton"
                        onClick={() => {
                            openPaymentsModal();
                        }}
                        leftIcon={<i className="ti ti-plus" />}
                    >
                        <Text weight={600}>Add Payment Method</Text>
                    </Button>
                </Group>
            </Group>
            <Space h="sm" />
            {primaryPayment && (
                <Box sx={{ maxWidth: 720 }}>
                    <Text size="md" weight={600} color={colorPalette.titleTextColor}>
                        Primary payment method
                    </Text>
                    <PaymentList>
                        <Observer
                            render={() =>
                                primaryPayment.PaymentSourceType == 'Card' ? (
                                    <CardHolder item={primaryPayment as PaymentSource} onAction={reload} key={0} isPrimary={true}></CardHolder>
                                ) : primaryPayment.PaymentSourceType == 'DirectDebit' ? (
                                    <AccountHolder
                                        item={primaryPayment as PaymentSource}
                                        onAction={reload}
                                        key={0}
                                        isPrimary={true}
                                        hostedPage={hostedPage as HostedPage}
                                    ></AccountHolder>
                                ) : (
                                    <>
                                        <CspHolder item={primaryPayment as PaymentSource} onAction={reload} key={0} isPrimary={true}></CspHolder>
                                    </>
                                )
                            }
                        />
                    </PaymentList>
                    <Space h={20}></Space>
                </Box>
            )}
            {!!nonPrimarySources && nonPrimarySources.length > 0 && (
                <>
                    <Group>
                        <Text size="md" weight={600} color={colorPalette.titleTextColor}>
                            Saved payment methods
                        </Text>
                        <NumberCircle>
                            <Text weight={500} color={colorPalette.subHeaderTextColor}>
                                {nonPrimarySources.length}
                            </Text>
                        </NumberCircle>
                    </Group>
                    <PaymentList>
                        {nonPrimaryCards?.map((item, i) => (
                            <Observer render={() => <CardHolder item={item} onAction={reload} key={'card' + i + 1} isPrimary={false}></CardHolder>} />
                        ))}

                        {nonPrimaryBankAccounts?.map((item, i) => (
                            <Observer
                                render={() => (
                                    <AccountHolder
                                        item={item}
                                        onAction={reload}
                                        key={'account' + i + 1}
                                        isPrimary={false}
                                        hostedPage={hostedPage as HostedPage}
                                    ></AccountHolder>
                                )}
                            />
                        ))}

                        {nonPrimaryCSPs?.map((item, i) => (
                            <Observer render={() => <CspHolder item={item} onAction={reload} key={'csp' + i + 1} isPrimary={false}></CspHolder>} />
                        ))}
                    </PaymentList>
                </>
            )}
            {!primaryPayment && (!nonPrimarySources || nonPrimarySources.length == 0) && (
                <>
                    <Box px="xl" pt="md">
                        <Text italic color="dimmed">
                            No payment methods
                        </Text>
                    </Box>
                </>
            )}
        </AdminPanel>
    );
};
