import { Account } from '@apis/Customers/model';
import { MapContract, MapContractStatus } from '@apis/TagManager/model';
import { Box, Button, Card, Divider, Group, Modal, Space, Stack, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AwsReconnector } from '@root/Components/Connections/AwsReconnector';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircleCheck, AlertTriangle, Plus } from 'tabler-icons-react';
import { AddConnection } from '../../Setup/ConnectionWizard';
import { ReportContainer, ReportFooter, ReportHeader, ReportPanel } from '../Design';
import { MapContractHomeModel } from '../Models';

interface ManagementAccount {
    management: Account;
    members: Account[];
}

export function MapConnectionHealth({ contract }: { contract: MapContract }) {
    const model = useDi(MapContractHomeModel);

    const accounts = model.accountSets
        .map((set) => {
            return {
                management: set.find((acc) => acc.IsMasterAccount),
                members: set,
            } as ManagementAccount;
        })
        .filter((set) => set.management && set.management.AwsAccountId && contract.AccountIds?.includes(set.management.AwsAccountId ?? ''));

    const handleConnectionAdded = useCallback(
        (accountId: string) => {
            if (accountId) {
                model.addManagementAccount(accountId);
            }
        },
        [model]
    );

    return (
        <ReportPanel>
            <ReportHeader>
                <Title order={4}>Connection Health</Title>
            </ReportHeader>
            <ReportContainer>
                {accounts.map((set) => {
                    return (
                        <>
                            <Space h="md" />
                            <ManagementAccountHealth account={set} status={model.contractStatus.get(set.management.Id!)} />
                        </>
                    );
                })}
            </ReportContainer>
            <Divider />
            <ReportFooter>
                <Group position="right">
                    <AddManagementAccount onConnectionAdded={handleConnectionAdded} />
                </Group>
            </ReportFooter>
        </ReportPanel>
    );
}

function AddManagementAccount({ onConnectionAdded }: { onConnectionAdded: (accountId: string) => void }) {
    const theme = useMantineTheme();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onComplete = (accountId: string) => {
        onConnectionAdded(accountId);
        close();
    };
    return (
        <>
            <Button size="sm" variant="outline" leftIcon={<Plus size={16} />} onClick={open}>
                Add Management Account
            </Button>
            <Modal
                sx={{ '[role=dialog]': { background: theme.colors.gray[1] } }}
                closeOnClickOutside={false}
                opened={opened}
                onClose={close}
                size={900}
                withCloseButton={false}
                radius="lg"
            >
                {opened ? <AddConnection onClose={close} onComplete={onComplete} /> : null}
            </Modal>
        </>
    );
}

function ManagementAccountHealth({ account, status }: { account: ManagementAccount; status: MapContractStatus | undefined }) {
    const tagActivatedIssues = status?.TagActivated ?? false ? 0 : 1;
    const tagEnabledIssues = status?.TagExists ?? false ? 0 : 1;
    const connectionIssues = account.members.filter((a) => !a.IsConnected)?.length ?? 1;
    const issueCount = tagActivatedIssues + tagEnabledIssues + connectionIssues;
    const contractIsValid = issueCount === 0;
    const accordianColor = (contractIsValid ? theme.colors?.success?.[6] : theme.colors?.warning?.[6]) as CustomColors;

    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
    }, [account]);

    return (
        <>
            <Card color={theme.colors?.success?.[6]} shadow="xl" radius="md" p={0}>
                <Group position="apart" style={{ backgroundColor: accordianColor, padding: '10px 20px' }}>
                    <Box>
                        <Text size="xs" color={theme.colors?.gray?.[2] as CustomColors}>
                            Management Account
                        </Text>
                        <Group>
                            <Text color={theme.colors?.gray?.[2] as CustomColors}>{account.management.AwsAccountId}</Text>
                            <Text color={theme.colors?.gray?.[0] as CustomColors}>{account.management.Name}</Text>
                        </Group>
                    </Box>
                    <Box>
                        <Group>
                            <ThemeIcon size={'xl'} color={accordianColor}>
                                {contractIsValid ? <CircleCheck size={48} /> : <AlertTriangle size={48} />}
                            </ThemeIcon>

                            {issueCount > 0 && (
                                <Box>
                                    <Text size="sm" color={theme.colors?.gray?.[0] as CustomColors}>
                                        Issues ({issueCount})
                                    </Text>
                                </Box>
                            )}
                        </Group>
                    </Box>
                </Group>
                <Stack style={{ margin: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Configuration Settings</Text>
                    <ConfigurationGrid status={status!} />
                </Stack>
                <Stack style={{ margin: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Accounts</Text>
                    <AccountGrid members={account.members} />
                </Stack>
            </Card>
        </>
    );
}

function ConfigurationGrid({ status }: { status: MapContractStatus }) {
    const [grid, setGrid] = useState<DataGridModel>();
    const [data, setData] = useState<configurationStatus[]>([]);
    type configurationStatus = { configuration: string; status: boolean };

    useEffect(() => {
        setData(loadConfig());
    }, []);

    const loadConfig = () => {
        let data: configurationStatus[] = [];
        data.push({ configuration: 'Cost allocation tags enabled', status: status?.TagActivated ?? false });
        data.push({ configuration: '"map-migrated" designated as a cost allocation tag', status: status?.TagExists ?? false });
        return data;
    };

    const getCurStatusLink = () => {
        return (
            <Group>
                <ThemeIcon color={theme.colors?.gray?.[0] as CustomColors}>
                    <CircleCheck color={theme.colors?.success?.[6] as CustomColors} />
                </ThemeIcon>
                <Text color={theme.colors?.success?.[6] as CustomColors}>Completed</Text>
            </Group>
        );
    };

    const columns = useMemo(() => {
        return [
            {
                header: 'Configuration',
                accessor: 'configuration',
                defaultWidth: 400,
                id: 'configuration',
                sortField: 'configuration',
            },
            {
                header: 'Status',
                id: 'status',
                accessor: (item) => {
                    return item.status ? 'Completed' : 'Not Completed';
                },
                defaultWidth: 250,
                sortField: 'status',
                cellRenderer: (item) => {
                    return item.configuration === 'Cost and usage report created' ? (
                        getCurStatusLink()
                    ) : item.status ? (
                        <Group>
                            <ThemeIcon color={theme.colors?.gray?.[0] as CustomColors}>
                                <CircleCheck color={theme.colors?.success?.[6] as CustomColors} />
                            </ThemeIcon>
                            <Text color={theme.colors?.success?.[6] as CustomColors}>Completed</Text>
                        </Group>
                    ) : (
                        <Group>
                            <ThemeIcon color={theme.colors?.gray?.[0] as CustomColors}>
                                <AlertTriangle color={theme.colors?.warning?.[6] as CustomColors} />
                            </ThemeIcon>
                            <Text color={theme.colors?.warning?.[6] as CustomColors}>Not Completed</Text>
                        </Group>
                    );
                },
            },
        ] as ColumnConfig<configurationStatus>[];
    }, [grid, data]);

    return (
        <DataGrid
            rightTopPlaceHolder={<></>}
            selectionMode="none"
            disableHighlight
            dataSource={data}
            columns={columns}
            hideFilter
            hideHeader
            displayMode="grid"
            onModelLoaded={setGrid}
        />
    );
}

function AccountGrid({ members }: { members: Account[] }) {
    const theme = useMantineTheme();
    const [fixAccount, setFixAccount] = useState<string>();
    const [grid, setGrid] = useState<DataGridModel>();
    const model = useDi(MapContractHomeModel);
    const [accountConnected, setAccountConnected] = useState(false);

    const handleConnectionFix = useCallback(() => {
        setFixAccount(undefined);
        model.reloadContract();
    }, [fixAccount]);

    const columns = useMemo(() => {
        return [
            {
                header: 'Account Number',
                accessor: 'AwsAccountId',
                defaultWidth: 190,
                id: 'AwsAccountId',
                sortField: 'AwsAccountId',
            },
            {
                header: 'Account Name',
                accessor: 'Name',
                defaultWidth: 300,
                id: 'Name',
                sortField: 'Name',
            },
            {
                header: 'Type',
                accessor: (item) => {
                    return item.IsMasterAccount ? 'Management Account' : 'Member Account';
                },
                defaultWidth: 300,
                id: 'IsMasterAccount',
                sortField: 'IsMasterAccount',

                cellRenderer: (item) => {
                    return item.IsMasterAccount ? <Text>Management Account</Text> : <Text>Member Account</Text>;
                },
            },
            {
                header: 'Connection Status',
                accessor: (item) => {
                    return item.IsConnected ? 'Connected' : 'Fix Connection';
                },
                defaultWidth: 250,
                id: 'IsConnected',
                sortField: 'IsConnected',
                cellRenderer: (item) => {
                    return (
                        <GridFullCell data-atid={'MAPHealthStatus:' + (item.IsConnected ? 'Connected' : 'Fix')}>
                            {item.IsConnected ? (
                                <Group spacing={4}>
                                    <ThemeIcon color={theme.colors?.gray?.[0] as CustomColors}>
                                        <CircleCheck color={theme.colors?.success?.[6] as CustomColors} />
                                    </ThemeIcon>
                                    <Text color={theme.colors?.success?.[6] as CustomColors}>Connected</Text>
                                </Group>
                            ) : (
                                <Group sx={{ padding: '0px' }}>
                                    <AnchorButton
                                        icon={<AlertTriangle color={theme.colors?.warning?.[6] as CustomColors} />}
                                        text="Fix Connection"
                                        color="warning.6"
                                        iconPosition="left"
                                        onClick={() => setFixAccount(item.AwsAccountId ?? undefined)}
                                    />
                                </Group>
                            )}
                        </GridFullCell>
                    );
                },
            },
        ] as ColumnConfig<Account>[];
    }, [grid, members]);

    return (
        <>
            <DataGrid
                rightTopPlaceHolder={<></>}
                selectionMode="none"
                disableHighlight
                dataSource={members}
                columns={columns}
                hideFilter
                hideHeader
                displayMode="grid"
                onModelLoaded={setGrid}
            />
            <Modal
                opened={!!fixAccount}
                onClose={() => setFixAccount(undefined)}
                size={600}
                withCloseButton={false}
                radius="lg"
                sx={{ '[role=dialog]': { background: theme.colors.gray[1] } }}
            >
                {fixAccount ? <AwsReconnector accountId={fixAccount} onConnectionStateChange={setAccountConnected} /> : null}
                <Space h="md" />
                <Group position="right">
                    <Button variant="outline" onClick={() => setFixAccount(undefined)}>
                        Cancel
                    </Button>
                    <Button disabled={!accountConnected} onClick={handleConnectionFix}>
                        Finish
                    </Button>
                </Group>
            </Modal>
        </>
    );
}
