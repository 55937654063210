import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, Ebs, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Table } from '@mantine/core';

export function GetResourceLaunchConfigurations(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();

    var retValue;

    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);

    var rows = [];
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data?.Results?.[0]) {
            const resource = data.Results[0] as {
                LaunchConfiguration: {
                    CreatedTime: string;
                    EbsOptimized: boolean;
                    ImageId: string;
                    InstanceMonitoring: { Enabled: boolean };
                    InstanceType: string;
                }[];
            };
            for (var i = 0; i < resource.LaunchConfiguration.length; i++) {
                rows.push({
                    createdTime: resource.LaunchConfiguration[i].CreatedTime,
                    ebsOptimized: resource.LaunchConfiguration[i].EbsOptimized ? 'Yes' : 'No',
                    imageId: resource.LaunchConfiguration[i].ImageId,
                    instanceMonitoring: resource.LaunchConfiguration[i].InstanceMonitoring.Enabled ? 'Yes' : 'No',
                    instanceType: resource.LaunchConfiguration[i].InstanceType,
                });
            }
        }
        retValue = (
            <div className="resourcesTableContainer">
                <ResourceInfoTable>
                    <Table>
                        <thead>
                            <tr>
                                <td>Created Date</td>
                                <td>Ebs Optimized</td>
                                <td>Image Id</td>
                                <td>Instance Monitoring</td>
                                <td className="left">Instance Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.createdTime.substring(0, 10)}</td>
                                        <td>{val.ebsOptimized}</td>
                                        <td>{val.imageId}</td>
                                        <td>{val.instanceMonitoring}</td>
                                        <td>{val.instanceType}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </ResourceInfoTable>
            </div>
        );
    }

    return retValue;
}

export function ResourceLaunchConfigurations() {
    return <>Resource LaunchConfigurations</>;
}
endpoint('resource', ResourceLaunchConfigurations, 'Resource LaunchConfigurations');
