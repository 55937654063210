import { Group, Space, Text } from '@mantine/core';
import { isValidValue } from '@root/Components/Resources/TagValidation';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { Fragment, useEffect, useState } from 'react';
import { ResourceTagService } from '../Services/ResourceTagService';
import { BulkTagProps } from './BulkTagProps';
import { TagEditor } from './TagEditor';
import { TagField } from './TagField';

export const ChangeTagValue = (props: BulkTagProps) => {
    const [tagKeyList, setTagKeyList] = useState<string[]>([]);
    const [currentValuesPicklist, setCurrentValuesPicklist] = useState<string[]>([]);
    const [newValuesPicklist, setNewValuesPicklist] = useState<string[]>([]);
    const resourceSvc = useDi(ResourceService);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [keyToUpdate, setKeyToUpdate] = useState<string>('');
    const [valuesToReplace, setValuesToReplace] = useState<string[]>(['']);
    const [newValue, setNewValue] = useState<string>('');
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canRemove, setCanRemove] = useState<boolean>(false);
    const [selectedKeyError, setSelectedKeyError] = useState<string>('');
    const [selectedValueErrors, setSelectedValueErrors] = useState<Map<number, string>>(new Map());
    const tagSvc = useDi(ResourceTagService);

    useEffect(() => {
        if (props.tagstoUpdate && props.updateToValue && props.tagKeyGettingValue) {
            setKeyToUpdate(props.tagKeyGettingValue);
            setCurrentValuesPicklist(props.tagstoUpdate);
        }
    }, []);

    useEffect(() => {
        (async () => {
            setTagKeyList(await getKeysOfSelected());
            setNewValuesPicklist((await resourceSvc.getAllTagValues()).sort());
            setCurrentValuesPicklist(await getValuesOfSelected());
        })();
    }, [props.resourceIds.count()]);

    useEffect(() => {
        (async () => {
            setCurrentValuesPicklist(await getValuesOfSelected());
        })();
        if (props.tagstoUpdate && props.updateToValue && props.tagKeyGettingValue) {
            setValuesToReplace(props.tagstoUpdate);
            setNewValue(props.updateToValue);
        } else {
            setValuesToReplace(['']);
            setNewValue('');
        }
    }, [keyToUpdate]);

    useEffect(() => {
        if (valuesToReplace.length > 1) {
            setCanRemove(true);
        } else {
            setCanRemove(false);
        }
        setCanAdd(currentValuesPicklist.filter((i) => !valuesToReplace.includes(i)).length > 0 && valuesToReplaceIsValid());
        formIsValid();
    }, [newValue, JSON.stringify(valuesToReplace), currentValuesPicklist.length]);

    const getKeysOfSelected = async () => {
        if (props.tagKeyGettingValue) {
            return [props.tagKeyGettingValue];
        } else {
            return await tagSvc.getValidKeys(props.resourceIds);
        }
    };

    const getValuesOfSelected = async () => {
        if (props.tagstoUpdate) {
            return props.tagstoUpdate;
        } else {
            return await tagSvc.getValidValues(props.resourceIds, keyToUpdate);
        }
    };

    const valuesToReplaceIsValid = () => {
        return !valuesToReplace.includes('');
    };

    const formIsValid = async () => {
        let isValid =
            valuesToReplaceIsValid() && keyToUpdate !== '' && valuesToReplaceIsValid() && newValue !== '' && isValidValue(newValue ?? '') == 0;
        setDisableSubmit(!isValid);

        return isValid;
    };

    const addRowHandler = () => {
        setValuesToReplace((prevState) => {
            let newState = [...prevState];
            newState.push('');
            return newState;
        });
    };

    const removeRowHandler = (index: number) => {
        setValuesToReplace((prevState) => {
            let newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const setKeyValue = (_: number, value: string) => {
        setKeyToUpdate(value);
    };

    const addValueToReplace = (index: number, value: string) => {
        setValuesToReplace((prevState) => {
            let newState = [...prevState];
            newState.splice(index, 1, value);
            return newState;
        });
    };

    const setReplacementValue = (_: number, value: string) => {
        setNewValue(value);
    };

    const tagJob = tagSvc.createTagJob(props.resourceIds, { ReplaceValues: { Key: keyToUpdate, Replacement: newValue, Values: valuesToReplace } });

    return (
        <TagEditor
            onButtonClick={props.onButtonClick}
            companyId={props.companyId}
            resourceIds={props.resourceIds}
            pageTitle={props.pageTitle}
            instructions={props.instructions}
            canSubmit={!disableSubmit}
            setDisableSubmit={setDisableSubmit}
            onTagJobStarting={props.onTagJobStarting}
            onTagJobStarted={props.onTagJobStarted}
            operationType="renameValue"
            tagJob={tagJob}
            overwriteConflicts={false}
            setOverwriteConflicts={() => null}
        >
            <>
                <TagField
                    atid={'TagKeyInput'}
                    key={`changeTagValue0`}
                    fieldValue={keyToUpdate}
                    valuePickList={tagKeyList}
                    label="Select Tag"
                    setSelectedValue={setKeyValue}
                    canAdd={false}
                    canRemove={false}
                    receiveInitalFocus={true}
                />
                <Text color="error" hidden={selectedKeyError == undefined || selectedKeyError.length === 0}>
                    *{selectedKeyError}
                </Text>
                <Space h="sm" />
                {valuesToReplace.map((_, index) => (
                    <Fragment key={`fragment${index}`}>
                        <Group noWrap grow key={`groupTag${index}`} spacing={0}>
                            <TagField
                                atid={'TagOldValueInput:' + index}
                                key={`changeTagValue${index + 2}`}
                                fieldValue={valuesToReplace[index]}
                                index={index}
                                count={valuesToReplace.length}
                                valuePickList={currentValuesPicklist.filter((i) => !valuesToReplace.includes(i))}
                                label="Value to replace"
                                setSelectedValue={addValueToReplace}
                                handleAddRow={addRowHandler}
                                handleRemoveRow={removeRowHandler}
                                canAdd={canAdd}
                                canRemove={canRemove}
                                disabled={selectedKeyError.length > 0}
                            />
                        </Group>
                        <Text key={`tagError${index}`} color="error" hidden={!selectedValueErrors.has(index)}>
                            *{selectedValueErrors.get(index)}
                        </Text>
                        <Space key={`spaceTag${index}`} h="sm" />
                    </Fragment>
                ))}
                <AnchorButton text="Add" onClick={addRowHandler} icon={<i className="ti ti-circle-plus"></i>} atid="RenameTagValuesAddAnchor" />
                <Space h="sm" />
                <TagField
                    key={`changeTagValue1`}
                    atid={'TagNewValueInput'}
                    fieldValue={newValue}
                    valuePickList={newValuesPicklist}
                    label="Replacement value"
                    setSelectedValue={setReplacementValue}
                    canAdd={false}
                    canRemove={false}
                    disabled={selectedKeyError.length > 0}
                />
            </>
        </TagEditor>
    );
};
