import { getPaymentGetPrimaryPayment, getSubscriptionGetCompanyInfo, postSubscriptionUpdateCompanyInfo } from '@apis/Customers';
import { Address, CompanyInfo } from '@apis/Customers/model';
import { Box, Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { useState } from 'react';

export function AddAddress({ onNext, companyInfo }: { onNext: () => void; onCancel: () => void; companyInfo: CompanyInfo }) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: companyInfo.BillingAddress ?? ({ AddressLine2: '', City: '', CountryCode: '', StateCode: '', ZipCode: '' } as Address),
        validate: {
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        companyInfo.BillingAddress = form.values;
        await SubmitChanges(companyInfo);
    };

    const SubmitChanges = async (companyInfo: CompanyInfo) => {
        try {
            setLoading(true);
            await postSubscriptionUpdateCompanyInfo(companyInfo).catch((error) => {
                //console.log(`Error: ${error}`);
            });
            onNext();
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {loading ? <LoadingOverlay visible /> : null}
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <form onSubmit={handleSubmit}>
                    <CompanyAddress
                        form={form}
                        prefix={''}
                        requiredFields={[
                            CompanyAddressFields.AddressLine1,
                            CompanyAddressFields.City,
                            CompanyAddressFields.State,
                            CompanyAddressFields.Zip,
                            CompanyAddressFields.Country,
                        ]}
                        isWithinPortal={false}
                    />
                    <Button fullWidth type="submit">
                        {companyInfo.BillingAddress === null ? 'Add Address' : 'Save Address'}
                    </Button>
                </form>
            </div>
        </Box>
    );
}
