import { Recommendation, RecommendationOption } from '@apis/Recommendations/model';
import { postObservabilityQuery } from '@apis/Resources';
import { Box, Group, Text, useMantineTheme } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { addDays, addMonths } from 'date-fns';
import { useMemo, useState, useEffect, Fragment } from 'react';
import { LineChartSettings, LineChart } from '../../Charts/LineChart';
import { IObservabilityMetricConfig } from './Models';
import { ChartWrapper } from '@root/Components/Charts/Design';

export function OptionPerformance({
    option,
    recommendation,
    metrics,
}: {
    option: RecommendationOption;
    recommendation: Recommendation;
    metrics: IObservabilityMetricConfig[];
}) {
    const { data: perfData, loading: perfDataLoading, isEmpty } = usePerformanceData({ recommendation, metrics, duration: 'month' });

    return (
        <Group align="stretch">
            <FillerSwitch loading={perfDataLoading} noData={isEmpty}>
                {() => (
                    <>
                        {metrics.map((m, i) => {
                            const labelSuffix = m.format === 'bytes' ? 'Bytes' : m.format === 'count' ? '' : '%';
                            return (
                                <Fragment key={i}>
                                    <Box>
                                        <Text>
                                            {m.title} Usage {labelSuffix}
                                        </Text>
                                        <Box sx={{ height: 250, width: 350 }}>
                                            <PerformanceChart config={m} data={perfData} option={option} recommendation={recommendation} />
                                        </Box>
                                    </Box>
                                </Fragment>
                            );
                        })}
                    </>
                )}
            </FillerSwitch>
        </Group>
    );
}

export function PerformanceChart({
    config,
    data,
    option,
    recommendation,
}: {
    config: IObservabilityMetricConfig;
    data: PerformanceMetrics;
    option: RecommendationOption;
    recommendation: Recommendation;
}) {
    const theme = useMantineTheme();
    const specFactor = useMemo(() => {
        const currentSpec = recommendation.HistoricalUtilizationMetrics?.[config.spec] ?? 1;
        const comparedSpec = option.ExpectedUtilizationMetrics?.[config.spec] ?? 1;
        return currentSpec / comparedSpec;
    }, [config, option, recommendation]);
    const { records, empty } = useMemo(() => {
        const result: Record<string, string | number | Date>[] = [];
        let anyResults = false;
        if (data[config.metric]) {
            for (const item of data[config.metric]) {
                anyResults = anyResults || typeof item.value === 'number';
                result.push({
                    Time: item.time,
                    Group: 'Before',
                    Value: item.value ?? 0,
                });
                result.push({
                    Time: item.time,
                    Group: 'After',
                    Value:
                        config.prerenderTransform?.(item.value ?? 0, recommendation, option.ExpectedUtilizationMetrics?.[config.spec] ?? 0) ??
                        (item.value ? item.value * specFactor : 0),
                });
            }
        }
        return { records: result, empty: !anyResults };
    }, [data, config, specFactor]);
    const chartProps = useMemo(
        () => ({
            groups: ['Time', 'Group'],
            values: ['Value'],
            settings: {
                chartColors: [theme.colors.primary[6], theme.colors.success[6]],
                format: config.format === 'count' ? 'int' : config.format ?? 'percent',
                interval: 'hour',
                yMax: config.yMax === -1 ? null : config.yMax ?? 100,
                hideXAxis: true,
                hidePoints: true,
                margin: { top: 15, right: 0, bottom: 15, left: config.format === 'bytes' ? 65 : 50 },
            } as LineChartSettings,
        }),
        []
    );

    return (
        <FillerSwitch noData={empty} noDataMessage={`${config.title} Not Available`}>
            {() => <LineChart data={records} {...chartProps} />}
        </FillerSwitch>
    );
}

type PerformanceMetrics = { [metric: string]: { time: Date; value: number | null }[] };

export function usePerformanceData({
    recommendation,
    metrics,
    duration,
}: {
    recommendation: Recommendation;
    metrics: IObservabilityMetricConfig[];
    duration: 'month' | 'day' | 'week';
}) {
    const fmtSvc = useDi(FormatService);
    const [data, setData] = useState<PerformanceMetrics>({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        const endTime = new Date();
        const startTime = duration === 'day' ? addDays(new Date(), -1) : duration === 'week' ? addDays(new Date(), -7) : addMonths(new Date(), -1);
        const period = 60 * 60;
        (async () => {
            try {
                setData({});
                const results = await postObservabilityQuery(
                    {
                        EndTime: fmtSvc.toJsonShortDate(endTime),
                        StartTime: fmtSvc.toJsonShortDate(startTime),
                        Period: period,
                        Requests:
                            metrics.map((m) => ({
                                ResourceId: recommendation.ResourceIdentifier?.ResourceId ?? '',
                                ResourceType: recommendation.ResourceIdentifier?.ResourceType ?? '',
                                Metric: m.metric,
                                Operation: 'avg',
                            })) ?? [],
                    },
                    { companyId: recommendation.CompanyId }
                );

                const data: PerformanceMetrics = {};
                for (const result of results.Results ?? []) {
                    const time = new Date(result.Time!);
                    for (let i = 0; i < metrics.length; i++) {
                        const metric = metrics[i];
                        const value = result.Values?.[i] ?? null;
                        if (!data[metric.metric]) {
                            data[metric.metric] = [];
                        }
                        data[metric.metric].push({ time, value: typeof value === 'number' ? metric.transform(value, recommendation) : null });
                    }
                }
                setData(data);
            } finally {
                setLoading(false);
            }
        })();
    }, [recommendation.ResourceIdentifier?.ResourceId, recommendation.ResourceIdentifier?.ResourceType, duration]);

    return { data, loading, isEmpty: !Object.keys(data).length };
}
