import { postPaymentAddPaymentSource } from '@apis/Customers';
import { Alert, Box, Button, createStyles, Divider, Group, LoadingOverlay, Space, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { WizardModalContentSingleChild } from '@root/Components/Wizard/WizardModal';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { SetStateAction, useEffect, useState } from 'react';
import { Tokenizeable } from './PaymentMethods';
// @ts-ignore
import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper';
import {
    Address,
    Company,
    CompanyInfo,
    EstimateRequest,
    InvoiceDetail,
    Plans,
    SubscriptionDetail,
    SubscriptionLite,
    Card as PaymentCard,
} from '@apis/Customers/model';

export function AddPayment({ onNext, onCancel, companyInfo }: { onNext: () => void; onCancel: () => void; companyInfo: CompanyInfo }) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(false);
    const [cardRef, setCardRef] = useState<Tokenizeable | null>();
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const { classes } = useStyles();
    const [invoice, setInvoice] = useState<InvoiceDetail>();
    const [readyToAdd, setReadyToAdd] = useState(false);
    const [isCardValid, setIsCardValid] = useState(false);
    const [isCardCvvValid, setIsCardCvvValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [paymentCard, setPaymentCard] = useState<PaymentCard>();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const billingAddress = useForm({
        initialValues:
            companyInfo.BillingAddress ?? ({ AddressLine1: '', AddressLine2: '', City: '', CountryCode: '', StateCode: '', ZipCode: '' } as Address),
        validate: {
            AddressLine1: (v: string) => (v ? null : 'Address is required'),
            CountryCode: (v: string) => (v ? null : 'Country is required'),
            StateCode: (v: string) => (v ? null : 'State is required'),
            ZipCode: (v: string) => (v ? null : 'Zip Code is required'),
        },
    });

    const tokenize = () => {
        const validity = billingAddress.validate();
        if (validity.hasErrors) {
            return;
        }
        setLoading(true);
        const { AddressLine1, AddressLine2, City, CountryCode, StateCode, ZipCode } = billingAddress.values;

        cardRef
            ?.tokenize({
                firstName: fName,
                lastName: lName,
                billingAddr1: AddressLine1,
                billingAddr2: AddressLine2,
                billingCity: City,
                billingStateCode: StateCode,
                billingZip: ZipCode,
                billingCountry: CountryCode,
            })
            .then((a) => {
                AddPayment(a.token, billingAddress.values);
            })
            .catch((...args) => {
                setLoading(false);
            });
    };

    const errorMsg = (
        <>
            <Alert
                icon={<i className="ti ti-alert-circle"></i>}
                title={
                    <>
                        <Text color={colorPalette.errorTitleText} weight={600} size={14}>
                            Error adding payment method!
                        </Text>
                    </>
                }
                color="error"
                sx={{ backgroundColor: colorPalette.errorBgColor }}
                withCloseButton
                onClose={() => setShowErrorMsg(false)}
            >
                <Text color={colorPalette.errorTitleText} weight={400} size={14}>
                    Please double check the card info.
                </Text>
            </Alert>
            <Space h={20}></Space>
        </>
    );

    async function AddPayment(token: string, address: Address) {
        const added = await postPaymentAddPaymentSource(
            {
                FirstName: fName,
                LastName: lName,
                BillingAddr1: address.AddressLine1,
                BillingAddr2: address.AddressLine2,
                BillingCity: address.City,
                BillingCountry: address.CountryCode,
                BillingStateCode: address.StateCode,
                BillingZipCode: address.ZipCode,
            } as PaymentCard,
            { token: token, isPrimary: true }
        );
        setLoading(false);
        if (added) {
            onNext();
        } else {
            setShowErrorMsg(true);
        }
    }

    function checkOnChange(event: any) {
        if (event.field == 'number') {
            if (event.complete && !event.error) {
                setIsCardValid(true);
            } else {
                setIsCardValid(false);
            }
        }

        if (event.field == 'cvv') {
            if (event.complete && !event.error) {
                setIsCardCvvValid(true);
            } else {
                setIsCardCvvValid(false);
            }
        }

        if (event.field == 'expiry') {
            if (event.complete && !event.error) {
                setIsCardExpiryValid(true);
            } else {
                setIsCardExpiryValid(false);
            }
        }
    }

    useEffect(() => {
        if (isCardValid && isCardCvvValid && isCardExpiryValid) {
            setReadyToAdd(true);
        } else {
            setReadyToAdd(false);
        }
    }, [isCardValid, isCardCvvValid, isCardExpiryValid]);

    const titleText = 'Enter Payment Information';

    return (
        <Box sx={{ position: 'relative' }}>
            {loading && <LoadingOverlay visible={true} />}
            <div>
                <div>
                    <Group>
                        <TextInput
                            required
                            sx={{ flex: 1 }}
                            label="First name"
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setFName(e.currentTarget.value)}
                            className={classes.nonCardItems}
                        />

                        <TextInput
                            required
                            sx={{ flex: 1 }}
                            label="Last name"
                            onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setLName(e.currentTarget.value)}
                            className={classes.nonCardItems}
                        />
                    </Group>
                    <Space h={10}></Space>
                    <CardComponent ref={(v: any) => setCardRef(v)}>
                        <Group noWrap>
                            <div className={classes.cardNumber}>
                                <label>Card number</label>

                                <CardNumber className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                            </div>
                            <Space h={10}></Space>
                            <div className={classes.cardCvv}>
                                <label>CVC</label>
                                <CardCVV placeholder="000" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                            </div>
                        </Group>
                        <Space h={10}></Space>
                        <div className={classes.cardExpiry}>
                            <label>Expiry</label>
                            <CardExpiry placeholder="MM / YY" className={classes.cardBox} onChange={(e: any) => checkOnChange(e)} />
                        </div>
                    </CardComponent>
                    <Space h={20}></Space>
                    <div style={{ marginRight: '15px' }}>
                        <Divider
                            my="xs"
                            label={
                                <Text size={14} weight={500} color={colorPalette.subTextColor}>
                                    Billing Information
                                </Text>
                            }
                            labelPosition="center"
                        ></Divider>
                        <Space h={10} />
                        <CompanyAddress
                            form={billingAddress}
                            prefix=""
                            requiredFields={[
                                CompanyAddressFields.AddressLine1,
                                CompanyAddressFields.City,
                                CompanyAddressFields.State,
                                CompanyAddressFields.Zip,
                                CompanyAddressFields.Country,
                            ]}
                            isWithinPortal={false}
                        />
                        <Space h={32}></Space>
                        {showErrorMsg ? errorMsg : ''}
                        <Button fullWidth onClick={tokenize} disabled={!readyToAdd}>
                            <Text weight={600} size={16}>
                                Add Card
                            </Text>
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    cardStyles: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
        marginTop: `${theme.spacing.md}px`,
        maxWidth: '780px',
    },

    arrow: {
        i: {
            color: colorPalette.pendingVerificationArrowColor,
        },
    },

    linkStyles: {
        '&:hover': {
            color: colorPalette.linkHoverColor,
        },
    },

    cardNumber: {
        flex: 1,
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardCvv: {
        width: '96px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardExpiry: {
        width: '100px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardBox: {
        border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
        borderRadius: `${theme.radius.sm}px`,
        padding: '10px 14px ',
        fontSize: '16px',
    },

    primaryBox: {
        label: {
            fontSize: '16px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    nonCardItems: {
        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },

        input: {
            border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
            borderRadius: `${theme.radius.sm}px`,
            padding: '10px 14px ',
        },
    },

    iFrameModalStyles: {
        alignItems: 'center',
    },
}));
