import { TextInput, Text, Button, Grid, Select, ActionIcon, SelectItem, LoadingOverlay, Group, Title, Space, Divider, Anchor } from '@mantine/core';
import { useForm } from '@mantine/form';
import { openModal } from '@mantine/modals';
import { User as UserIcon, CircleMinus, Divide } from 'tabler-icons-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    getSubscriptionGetCompanyInfo,
    getUserGetCompanyUsers,
    postSubscriptionUpdateCompanyInfo,
    postSubscriptionUpdateMspCompanyInfo,
} from '@apis/Customers';
import { UserListItem, CompanyInfo, Address, Contact } from '@apis/Customers/model';
import { INavigatingEvent, useOnNavigating } from '@root/Services/Router/Router';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { CompanyAdministrationPanelModel } from '@root/Components/CompanyInfo/Administration/CompanyAdministrationPanelModel';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';

export interface CompanyInfoFormProps {
    ShowSaveHeader: boolean;
    OnNavigating?: (e: INavigatingEvent) => void;
    model?: CompanyAdministrationPanelModel;
    noHeader?: boolean;
    // undefined if no change, boolean value if changed to that value.
    mspActivateChange?: boolean;
    agreementAccepted?: boolean;
    statJobComplete?: () => void;
}

export const CompanyInfoForm = (props: CompanyInfoFormProps) => {
    const userPermissions = useAuthZValues({
        canManage: { Permissions: 'Manage' },
    });
    const [users, setUsers] = useState<UserListItem[]>([] as UserListItem[]);
    const [isLoading, setIsLoading] = useState(true);
    const [compInfo, setCompInfo] = useState<CompanyInfo>({});
    const handlers = useMemo(() => ({ submit: () => {}, reset: () => {} }), []);
    useEvent(
        props.model?.discardChangesRequested,
        useCallback(() => handlers.reset(), [])
    );
    useEvent(
        props.model?.saveChangesRequested,
        useCallback(() => handlers.submit(), [])
    );
    const company = useCompany();

    const form = useForm({
        initialValues: {
            CompanyId: 0,
            CompanyName: '',
            CompanyWebsite: '',
            PrimaryContactEmail: '',
            SecondaryContactEmails: [] as string[],
            BillingAddress: { AddressLine1: '', AddressLine2: '', City: '', StateCode: '', CountryCode: '', ZipCode: '' } as Address,
        },

        validate: {
            CompanyName: (value: string) => (value.length > 0 ? null : 'Invalid company name'),
        },
    });

    useOnNavigating((e) => {
        if (form.isDirty()) {
            e.wait();
            if (props.OnNavigating) {
                props.OnNavigating(e);
            } else {
                e.continue();
            }
        }
    });

    const loadCompanyUsers = () => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUsers(users.filter((u) => u.Status !== 'Deactivated'));
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    const loadCompanyInfo = () => {
        getSubscriptionGetCompanyInfo()
            .then((info) => {
                setCompInfo(info);
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        loadCompanyInfo();
        loadCompanyUsers();
    }, []);

    // Prompt the user to save if there's been a change to the company info AND
    // either the address is completely filled out, or the address is completely blank.
    useEffect(() => {
        if (props.model) {
            props.model.isModifiedChanged.emit(form.isDirty() && (BillingAddressComplete() || BillingAddressEmpty()));
        }
    }, [
        form.isDirty(),
        form.isDirty('BillingAddress.AddressLine1'),
        form.isDirty('BillingAddress.City'),
        form.isDirty('BillingAddress.CountryCode'),
        form.isDirty('BillingAddress.StateCode'),
        form.isDirty('BillingAddress.ZipCode'),
    ]);

    // If the billing address is completely filled out, enable the "Activate Company" option.
    useEffect(() => {
        if (props.model) {
            props.model.isAddressGood.emit(BillingAddressComplete());
        }
    }, [
        form.isDirty('BillingAddress.AddressLine1'),
        form.isDirty('BillingAddress.City'),
        form.isDirty('BillingAddress.CountryCode'),
        form.isDirty('BillingAddress.StateCode'),
        form.isDirty('BillingAddress.ZipCode'),
    ]);

    function BillingAddressComplete() {
        return (
            form.values.BillingAddress.AddressLine1 != '' &&
            form.values.BillingAddress.City != '' &&
            form.values.BillingAddress.CountryCode != '' &&
            form.values.BillingAddress.StateCode != '' &&
            form.values.BillingAddress.ZipCode != ''
        );
    }

    function BillingAddressEmpty() {
        return (
            form.values.BillingAddress.AddressLine1 == '' &&
            form.values.BillingAddress.City == '' &&
            form.values.BillingAddress.CountryCode == '' &&
            form.values.BillingAddress.StateCode == '' &&
            form.values.BillingAddress.ZipCode == ''
        );
    }

    useEffect(() => {
        // set fields to values from api call and reset touched/dirty (initial values)
        resetFormValues();
        setIsLoading(false);
    }, [compInfo]);

    const handleSubmit = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        let companyName = form.isDirty('CompanyName') ? form.values.CompanyName : null;
        let CompanyWebsite = form.isDirty('CompanyWebsite') ? form.values.CompanyWebsite : null;
        let primaryContact = form.isDirty('PrimaryContactEmail') ? getNewPrimaryContact(form.values.PrimaryContactEmail) : null;
        let secondaryContacts = form.isDirty('SecondaryContactEmails') ? getNewSecondaryContacts(form.values.SecondaryContactEmails) : null;
        let billingAddress = form.isDirty('BillingAddress') && form.values.BillingAddress ? form.values.BillingAddress : null;
        let agreementAccepted = props.agreementAccepted;

        let companyInfo = {
            CompanyId: compInfo.CompanyId,
            CompanyName: companyName,
            CompanyWebsite: CompanyWebsite,
            PrimaryContact: primaryContact,
            SecondaryContacts: secondaryContacts,
            BillingAddress: billingAddress,
            AgreementAccepted: agreementAccepted,
        } as CompanyInfo;

        if (hasChanges(companyInfo)) {
            await SubmitChanges(companyInfo);
        }
    };
    handlers.submit = () => handleSubmit(null);

    const hasChanges = (companyInfo: CompanyInfo) => {
        return (
            companyInfo.CompanyName ||
            companyInfo.CompanyWebsite ||
            companyInfo.PrimaryContact ||
            companyInfo.SecondaryContacts ||
            companyInfo.BillingAddress ||
            props.mspActivateChange != undefined ||
            companyInfo.AgreementAccepted != undefined
        );
    };

    const getNewPrimaryContact = (emailAddress: string) => {
        let user = users.find((u) => u.EMail === emailAddress);
        if (user) {
            return { FirstName: user.FirstName, LastName: user.LastName, Email: user.EMail, IsPrimary: true } as Contact;
        }

        return null;
    };

    const getNewSecondaryContacts = (addresses: string[]) => {
        let contacts = [] as Contact[];
        addresses.forEach((address) => {
            let contact = users.find((u) => u.EMail == address);
            if (contact) {
                contacts.push({
                    FirstName: contact.FirstName,
                    LastName: contact.LastName,
                    Email: contact.EMail,
                    IsPrimary: false,
                } as Contact);
            }
        });
        return contacts;
    };

    const SubmitChanges = async (changes: CompanyInfo) => {
        try {
            setIsLoading(true);
            if (props.mspActivateChange !== undefined) {
                await postSubscriptionUpdateMspCompanyInfo(changes, { activate: props.mspActivateChange });
            } else {
                await postSubscriptionUpdateCompanyInfo(changes);
            }
            if (props.statJobComplete) {
                props.statJobComplete();
            }
            loadCompanyInfo();
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormValues = () => {
        let secondaryContactEmails = compInfo?.SecondaryContacts?.map((d) => d.Email) as string[];

        form.setValues({
            CompanyId: compInfo.CompanyId ?? 0,
            CompanyName: compInfo.CompanyName ?? '',
            CompanyWebsite: compInfo.CompanyWebsite ?? '',
            PrimaryContactEmail: compInfo.PrimaryContact?.Email ?? '',
            SecondaryContactEmails: secondaryContactEmails ?? [],
            BillingAddress: compInfo.BillingAddress
                ? ({
                      AddressLine1: compInfo.BillingAddress.AddressLine1 ?? '',
                      AddressLine2: compInfo.BillingAddress.AddressLine2 ?? '',
                      City: compInfo.BillingAddress.City ?? '',
                      StateCode: compInfo.BillingAddress.StateCode ?? '',
                      ZipCode: compInfo.BillingAddress.ZipCode ?? '',
                      CountryCode: compInfo.BillingAddress.CountryCode ?? '',
                  } as Address)
                : ({ AddressLine1: '', AddressLine2: '', City: '', StateCode: '', ZipCode: '', CountryCode: '' } as Address),
        });

        form.resetTouched();
        form.resetDirty();
    };

    handlers.reset = () => resetFormValues();

    const handleAddSecondaryContact = () => {
        let availableUsers = users.length - form.getInputProps('SecondaryContactEmails').value.length - 1;
        if (availableUsers < 1) {
            messageBox('No Available Users', 'All available users have been assigned to contacts.');
            return;
        }
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.push(`placeholder${curVal.length + 1}`);
        form.setFieldValue('SecondaryContactEmails', curVal);
    };

    const handleRemoveSecondaryContact = (index: number) => {
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.splice(index, 1);
        form.setFieldValue('SecondaryContactEmails', curVal);
        // For some reason, Mantine form state doesn't pick up a delete item change, so you have to force it.
        form.setDirty({ SecondaryContactEmails: true });
    };

    const handleSecondaryContactChange = (selectedValue: any, index: number) => {
        let curVal: string[] = form.getInputProps('SecondaryContactEmails').value as string[];
        curVal.splice(index, 1, selectedValue);
        form.setFieldValue('SecondaryContactEmails', curVal);
    };

    const unselectedUsers = (selectIndex: number) => {
        // Remove primary contact from the list so it can't be selected in secondary contacts.
        // Also, remove selected secondary contacts from the list, unless this is the list
        // that has it selected, so it can display in the select list.
        let list = users.filter(
            (u) =>
                u.EMail !== form.getInputProps('PrimaryContactEmail').value &&
                (!form.getInputProps('SecondaryContactEmails').value.includes(u.EMail) ||
                    (form.getInputProps('SecondaryContactEmails').value.includes(u.EMail) &&
                        form.getInputProps('SecondaryContactEmails').value.indexOf(u.EMail) === selectIndex))
        );

        return list;
    };

    const messageBox = (title: string, message: string) => {
        openModal({
            title: <Text>{title}</Text>,
            children: <Text>{message}</Text>,
            centered: true,
        });
    };

    return (
        <>
            {isLoading && <LoadingOverlay visible={true} />}
            <div>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            paddingBottom: '1rem',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {props.noHeader ? (
                            <></>
                        ) : (
                            <>
                                <Group position="apart" mb="md">
                                    <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                                        Company Info
                                    </Title>
                                    {userPermissions.canManage && props.ShowSaveHeader ? (
                                        <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>
                                            <Button
                                                data-atid="RevertButton"
                                                disabled={!form.isDirty()}
                                                size="sm"
                                                variant="outline"
                                                onClick={() => resetFormValues()}
                                                styles={() => ({
                                                    root: {
                                                        marginRight: 10,
                                                    },
                                                })}
                                            >
                                                Revert Changes
                                            </Button>
                                            <Button data-atid="SaveButton" disabled={!form.isDirty()} type="submit" size="sm" color="primary">
                                                Save
                                            </Button>
                                        </Group>
                                    ) : (
                                        <></>
                                    )}
                                </Group>
                                <Divider />
                            </>
                        )}
                    </div>

                    {/* Need marginRight to elimiate horizontal scroll bar */}
                    <Grid style={{ padding: props.noHeader ? '0 24px' : '24px', marginRight: '1px' }}>
                        <Grid.Col span={4}>
                            <Text>Company Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyNameInput" {...form.getInputProps('CompanyName')} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Website</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyWebsiteInput" {...form.getInputProps('CompanyWebsite')} />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Billing Contacts</Text>
                            <Text color="dimmed" size="sm">
                                Invoices will be sent to these email addresses.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <>
                                <Select
                                    data-atid="PrimaryContactSelect"
                                    {...form.getInputProps('PrimaryContactEmail')}
                                    placeholder="Select primary contact"
                                    label="Primary Contact"
                                    icon={<UserIcon size={20} />}
                                    data={users.map<SelectItem>((user) => {
                                        return {
                                            value: `${user.EMail}`,
                                            label: user.FirstName ? `${user.FirstName} ${user.LastName}` : `${user.EMail}`,
                                        };
                                    })}
                                ></Select>

                                {/* map start------------------ */}
                                {form.getInputProps('SecondaryContactEmails').value.map((contact: string, index: number) => (
                                    <div
                                        key={`SecondaryContact ${index}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Select
                                            data-atid="SecondaryContactSelect"
                                            value={form.getInputProps('SecondaryContactEmails').value[index]}
                                            onChange={(value) => handleSecondaryContactChange(value, index)}
                                            placeholder="Select secondary contact"
                                            label="Secondary Contact"
                                            icon={<UserIcon size={20} />}
                                            style={{ width: '100%', marginTop: '.3rem' }}
                                            data={unselectedUsers(index).map<SelectItem>((user) => {
                                                return {
                                                    value: `${user.EMail}`,
                                                    label: user.FirstName ? `${user.FirstName} ${user.LastName}` : `${user.EMail}`,
                                                };
                                            })}
                                        ></Select>
                                        <ActionIcon
                                            onClick={() => handleRemoveSecondaryContact(index)}
                                            variant="transparent"
                                            style={{ marginTop: '1.8rem' }}
                                        >
                                            <CircleMinus color="red" />
                                        </ActionIcon>
                                    </div>
                                ))}
                                {/* map End---------------- */}
                                <Space h="xs" />
                                <Anchor data-atid="AddSecondaryContactButton" onClick={handleAddSecondaryContact}>
                                    + Add Secondary Billing Contact
                                </Anchor>
                            </>
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Address</Text>
                            <Text color="dimmed" size="sm">
                                This address will be added to invoices.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <CompanyAddress
                                prefix="BillingAddress."
                                form={form}
                                requiredFields={[
                                    CompanyAddressFields.AddressLine1,
                                    CompanyAddressFields.City,
                                    CompanyAddressFields.State,
                                    CompanyAddressFields.Zip,
                                    CompanyAddressFields.Country,
                                ]}
                                isWithinPortal={false}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </div>
        </>
    );
};
