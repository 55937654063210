import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { NotFound } from './NotFound';

export function GetResourceBundles(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        runQuery({
            data: queryBuilder<BaseAwsResource>()
                .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                .build(),
            params: { companyId: company?.Id },
        });
    }, []);
    const resource = data?.Results?.[0] as BaseResource & { Bundle: Record<string, any> };

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <NotFound description="No bundles"></NotFound>;
        } else {
            if (resource.Bundle == null) {
                retValue = <NotFound description="No bundles"></NotFound>;
            } else {
                var resourceValues = [];
                resourceValues.push({ label: 'Bundle Id', value: resource.Bundle.BundleId });
                resourceValues.push({ label: 'Owner', value: resource.Bundle.Owner });
                resourceValues.push({ label: 'CreationTime', value: resource.Bundle.CreationTime });
                resourceValues.push({ label: 'LastUpdatedTime', value: resource.Bundle.LastUpdatedTime });
                resourceValues.push({ label: 'UserStorage', value: resource.Bundle.UserStorage.Capacity + ' GB' });
                resourceValues.push({ label: 'ComputeType', value: resource.Bundle.ComputeType.Name.Value });
                resourceValues.push({ label: 'Name', value: resource.Bundle.Name });
                resourceValues.push({ label: 'RootStorage', value: resource.Bundle.RootStorage.Capacity + ' GB' });

                let valuesArray = [];
                for (var i = 0; i < resourceValues.length; i++) {
                    valuesArray.push(
                        <tr key={'br' + i}>
                            <td key={'l' + i} className="label">
                                {resourceValues[i].label}
                            </td>
                            <td key={'v' + i} className="value">
                                {resourceValues[i].value}
                            </td>
                        </tr>
                    );
                }
                retValue = (
                    <div className="resourcesTableContainer">
                        <Table className="resourcesTable">
                            <tbody>{valuesArray}</tbody>
                        </Table>
                    </div>
                );
            }
        }
    }

    return <div className="resourcesTableContainer">{retValue}</div>;
}

export function ResourceBundles() {
    return <>Resource Bundles</>;
}
endpoint('resource', ResourceBundles, 'Resource Bundles');
