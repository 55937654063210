import { MouseEventHandler, useCallback, useEffect } from 'react';

const globalFloatedItem = (() => {
    let clone: HTMLElement | null = null;
    let currentTarget: HTMLElement | null = null;
    const destroyClone = () => {
        if (clone) {
            clone.remove();
            clone = null;
        }
    };

    document.addEventListener('scroll', destroyClone, true);

    const copyStyles = (target: HTMLElement, floatedStyles?: CSSStyleDeclaration) => {
        if (clone && currentTarget === target) return;
        destroyClone();
        if (target.scrollWidth <= target.offsetWidth) {
            return;
        }
        clone = target.cloneNode(true) as HTMLElement;
        const dispose = () => {
            destroyClone();
            if (throttledNextTarget === target) {
                clearTimeout(throttleTo!);
            }
            currentTarget = null;
            target.removeEventListener('mouseleave', dispose);
        };
        target.addEventListener('mouseleave', dispose);
        const rect = target.getBoundingClientRect();
        Object.assign(clone.style, {
            position: 'fixed',
            top: `${rect.top}px`,
            left: `${rect.left}px`,
            maxWidth: `calc(100% - ${rect.left}px)`,
            ...floatedStyles,
            zIndex: '10000',
            pointerEvents: 'none',
        });
        document.body.append(clone);
    };

    let throttledNextTarget: HTMLElement | null = null;
    let throttleTo: number | null = null;

    const floatOnMouseEnter = (target: HTMLElement, styles?: CSSStyleDeclaration) => {
        clearTimeout(throttleTo!);
        throttledNextTarget = target;
        throttleTo = setTimeout(() => {
            copyStyles(target, styles);
        }, 100) as unknown as number;
    };
    return {
        clear: destroyClone,
        floatOnMouseEnter,
    };
})();

export function useFloatedFullText(styles?: CSSStyleDeclaration) {
    const floatOnMouseEnter: MouseEventHandler<HTMLElement> = useCallback(
        (e) => globalFloatedItem.floatOnMouseEnter(e.currentTarget, styles),
        [styles]
    );
    useEffect(() => {
        return () => globalFloatedItem.clear();
    }, []);
    return { floatOnMouseEnter };
}
