import { postResourcesQuery, QueryExpr, QueryOperation, QueryResult } from '@apis/Resources';
import { BaseAwsResource, IQueryExpr, Query } from '@apis/Resources/model';
import { getMapContractsGetMapContracts } from '@apis/TagManager';
import { MapContract } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Group,
    Loader,
    MantineColor,
    SegmentedControl,
    Space,
    Text,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { GridArrayDataSource } from '@root/Components/DataGrid/DataSources';
import { ColumnConfig, DataGridStateChange } from '@root/Components/DataGrid/Models';
import { Clearfix, MeasuredText, TooltipWhite, useResizeNeeded } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useId } from '@root/Services/IdGen';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder, SchemaService, SchemaValueProvider, traverseExpr, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { useLink } from '@root/Services/Router/Router';
import { ViewByPicker } from '@root/Site/TagManager/TagIntelligence/ViewByGrid';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AlertTriangle, Refresh } from 'tabler-icons-react';
import { MapContractsEvents } from '../../Services/MapContractsEvents';
import { ReportContainer, ReportHeader, ReportPanel } from '../Design';
import { MapContractHomeModel } from '../Models';

interface CoverageRecord {
    type: string;
    correct: number;
    correctother: number;
    incorrect: number;
    undecided: number;
    notCovered: number;
    taggedNotEligible: number;
    overRide: number;
    outsideContractTerm: number;
    total: number;
}

const CoverageRecordSchema = {
    IsRoot: true,
    TypeId: 'Base',
    Name: 'Coverage',
    Description: '',
    Fields: [
        {
            Field: 'correct',
            Name: 'Tagged Correctly',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'correctother',
            Name: 'Tagged Correctly (other contract)',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'incorrect',
            Name: 'Tagged Incorrectly',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'undecided',
            Name: 'Not Tagged',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'notCovered',
            Name: 'Not Covered Service',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'taggedNotEligible',
            Name: 'Not Eligible Workload',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'overRide',
            Name: 'Ineligible MAP Tags',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'outsideContractTerm',
            Name: 'Create Outside Contract Period',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
        {
            Field: 'total',
            Name: 'Total',
            Description: null,
            IsPrimitive: true,
            TypeName: 'number',
            HasMany: false,
        },
    ],
};

function CountCell({
    value,
    criteria,
    description,
    pivot,
    type,
    format,
    contractId,
    gridFilterRef,
}: {
    pivot?: string;
    type?: string;
    value?: number;
    format?: 'money' | 'int';
    criteria?: QueryExpr;
    description?: string;
    gridFilterRef?: QueryExpr[];
    contractId: number | undefined;
}) {
    const theme = useMantineTheme();
    const { getDescendUrl } = useNav();
    const link = useLink();
    const fmtSvc = useDi(FormatService);
    const filter: (QueryExpr & { description?: string })[] = !pivot ? [] : [{ Operation: 'eq', Operands: [{ Field: pivot }, { Value: type }] }];
    if (criteria) {
        filter.push({ ...criteria, description });
    }

    if (gridFilterRef) {
        let queryEx = {
            Operation: 'and',
            Operands: gridFilterRef,
        } as QueryOperation;

        filter.push(...gridFilterRef);
    }
    const filterUrl = getDescendUrl('map-resource-browser', {
        returnTo: 'Manage Tags',
        id: contractId?.toString() ?? '',
        filter: JSON.stringify(filter),
    });
    return (format !== 'money' || value === null || value === undefined) && !value ? (
        <span style={{ color: theme.colors.gray[4] }}>&mdash;</span>
    ) : (
        <Anchor {...link(filterUrl)} data-atid={description + ':' + type}>
            {format !== 'money' ? (
                fmtSvc.formatInt(value)
            ) : value >= 1 ? (
                fmtSvc.formatMoneyNoDecimals(value)
            ) : (
                <span style={{ color: theme.colors.gray[4] }}>&mdash;</span>
            )}
        </Anchor>
    );
}

type AggregateBy = 'cost' | 'count';

function CoverageGrid({
    contract,
    otherContracts,
    refreshEvent,
    aggregateBy,
    onTotalsChanged,
    onAggregateByChange,
}: {
    contract: MapContract;
    otherContracts: MapContract[];
    refreshEvent: EventEmitter<void>;
    aggregateBy: AggregateBy;
    onTotalsChanged: (totals?: Partial<CoverageRecord>) => void;
    onAggregateByChange: (aggregateBy: AggregateBy) => void;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const footer = useMemo(() => ({ totals: {} as Partial<CoverageRecord>, onChange: EventEmitter.empty() }), []);
    const { mapResourceQuerySvc, resourceSchema } = useDi(MapContractHomeModel);
    const [grid, setGrid] = useState<DataGridModel>();
    const [viewBy, setViewBy] = useState(resourceSchema!.getField('ResourceType'));
    const coverageSchema = useMemo(
        () => resourceSchema && new SchemaService([CoverageRecordSchema, ...(resourceSchema?.types ?? [])]),
        [resourceSchema]
    );

    const schemaValueProvider = useMemo(() => coverageSchema && new SchemaValueProvider(coverageSchema, postResourcesQuery), [coverageSchema]);
    const pivotColumn = viewBy?.path ?? '';
    const needResize = useResizeNeeded();
    const contractEvts = useDi(MapContractsEvents);
    const gridFilterRef = useMemo(() => ({ filter: [] as QueryExpr[] }), []);

    const updateFooter = async (data: CoverageRecord[]) => {
        footer.totals =
            data.reduce(
                (result, item) => {
                    result.correct += item.correct;
                    result.incorrect += item.incorrect;
                    result.undecided += item.undecided;
                    result.notCovered += item.notCovered;
                    result.taggedNotEligible += item.taggedNotEligible;
                    result.overRide += item.overRide;
                    result.outsideContractTerm += item.outsideContractTerm;
                    result.total += item.total;
                    return result;
                },
                {
                    correct: 0,
                    incorrect: 0,
                    notCovered: 0,
                    outsideContractTerm: 0,
                    taggedNotEligible: 0,
                    overRide: 0,
                    total: 0,
                    type: '',
                    undecided: 0,
                }
            ) ?? {};

        onTotalsChanged(footer.totals);
        footer.onChange.emit();
    };

    const attachGrid = useCallback(
        (grid: DataGridModel) => {
            setGrid(grid);
            grid.gridStateChanged.listen((change?: DataGridStateChange) => {
                if (change?.changes.has('filters')) {
                    (async () => {
                        const data = await grid?.getData();
                        updateFooter(data);
                    })();
                }
            });
        },
        [setGrid]
    );

    useEffect(() => {
        return contractEvts.onTagChanged.listen(refreshEvent.emit).dispose;
    }, []);

    useEvent(contractEvts.onTaggedIncorrectly, () => {
        if (grid) {
            grid.updateColumnBackgroundColor('incorrect', theme.colors.error[2] as CustomColors);
        }
    });

    useEvent(contractEvts.onNotTagged, () => {
        if (grid) {
            grid.updateColumnBackgroundColor('undecided', theme.colors.warning[2] as CustomColors);
        }
    });

    useEvent(contractEvts.onIneligibleMAPTags, () => {
        if (grid) {
            grid.updateColumnBackgroundColor('overRide', theme.colors.gray[2] as CustomColors);
        }
    });

    useEvent(contractEvts.onActionRequired, () => {
        if (grid) {
            grid.updateColumnBackgroundColor('incorrect', theme.colors.error[2] as CustomColors);
            grid.updateColumnBackgroundColor('undecided', theme.colors.warning[2] as CustomColors);
            grid.updateColumnBackgroundColor('overRide', theme.colors.gray[2] as CustomColors);
        }
    });

    useEvent(contractEvts.onNoSelection, () => {
        if (grid) {
            grid.updateColumnBackgroundColor('incorrect', undefined);
            grid.updateColumnBackgroundColor('undecided', undefined);
            grid.updateColumnBackgroundColor('overRide', undefined);
        }
    });

    const columns = useMemo(() => {
        const format = aggregateBy === 'cost' ? 'money' : 'int';
        return [
            {
                header: viewBy?.name ?? 'Resource Type',
                accessor: (item) => (item.type === ValuesGroupOtherText ? 'Other' : item.type),
                defaultWidth: 250,
                id: 'type',
                sortField: 'type',
                type: viewBy?.typeName ?? 'string',
                filter: {
                    filterField: viewBy?.path,
                    filterType: viewBy?.typeName ?? 'string',
                    name: viewBy?.name ?? 'Resource Type',
                },
                cellRenderer: (item) => {
                    return <>{item.type === ValuesGroupOtherText ? 'Other' : item.type}</>;
                },
                footerRenderer: () => <span style={{ fontWeight: 'bold' }}>Total</span>,
            },
            {
                accessor: 'correct',
                id: 'correct',
                defaultWidth: 125,
                header: 'Tagged Correctly',
                align: format === 'money' ? 'right' : 'center',
                type: 'number',
                filter: {
                    filterField: 'correct',
                    filterType: 'number',
                    name: 'Tagged Correctly',
                    fieldPickerRenderer: () => 'Tagged Correctly',
                },
                groupName: 'Eligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        format={format}
                        value={item.correct}
                        criteria={mapResourceQuerySvc?.getCorrectlyTaggedQuery().resolve()}
                        description="Correctly Tagged"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.correct}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getCorrectlyTaggedQuery().resolve()}
                        description="Correctly Tagged"
                    />
                ),
                headerRenderer: () => (
                    <TooltipWhite
                        label={
                            <>
                                Resources that were created within the contract date, are covered by the MAP program,
                                <br /> and have a correct map-migration tag.
                            </>
                        }
                        withinPortal
                    >
                        <div style={{ lineHeight: '25px' }}>
                            <>
                                Tagged <br /> Correctly
                            </>
                        </div>
                    </TooltipWhite>
                ),
            },
            {
                accessor: 'correctother',
                id: 'correctother',
                defaultWidth: 145,
                header: 'Tagged Correctly (Other Contract)',
                align: format === 'money' ? 'right' : 'center',
                type: 'number',
                filter: {
                    filterField: 'correct',
                    filterType: 'number',
                    name: 'Tagged Correctly',
                    fieldPickerRenderer: () => 'Tagged Correctly',
                },
                groupName: 'Eligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        format={format}
                        value={item.correctother}
                        criteria={mapResourceQuerySvc?.getCorrectlyTaggedOtherContractQuery().resolve()}
                        description="Correctly Tagged (Other Contract)"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.correctother}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getCorrectlyTaggedOtherContractQuery().resolve()}
                        description="Correctly Tagged (Other Contract)"
                    />
                ),
                headerRenderer: () => (
                    <TooltipWhite label={<>Resources that were created within the contract date, are covered by other MAP programs.</>} withinPortal>
                        <div style={{ lineHeight: '25px' }}>
                            <>
                                Tagged Correctly <br /> (Other Contract)
                            </>
                        </div>
                    </TooltipWhite>
                ),
            },
            {
                accessor: 'incorrect',
                id: 'incorrect',
                defaultWidth: 125,
                header: 'Tagged Incorrectly',
                headerRenderer: () => (
                    <TooltipWhite
                        label={
                            <>
                                Resources that were created within the contract date, are covered by the MAP program, <br />
                                but have a map-migration tag that is incorrect.
                            </>
                        }
                        withinPortal
                    >
                        <div style={{ lineHeight: '25px' }}>
                            {footer.totals?.incorrect! >= 1 ? (
                                <>
                                    <AlertTriangle style={{ marginBottom: -4 }} size={16} color={theme.colors.warning[6]} /> Tagged
                                    <br />
                                    Incorrectly
                                </>
                            ) : (
                                <>
                                    Tagged
                                    <br />
                                    Incorrectly
                                </>
                            )}
                        </div>
                    </TooltipWhite>
                ),
                align: format === 'money' ? 'right' : 'center',
                type: 'number',
                groupName: 'Eligible Resources',
                filter: {
                    filterField: 'incorrect',
                    filterType: 'number',
                    name: 'Tagged Incorrectly',
                    fieldPickerRenderer: () => 'Tagged Incorrectly',
                },
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.incorrect}
                        format={format}
                        criteria={{ Operation: 'and', Operands: [mapResourceQuerySvc!.getIncorrectlyTaggedQuery().resolve()] }}
                        description="Incorrectly Tagged"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.incorrect}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getIncorrectlyTaggedQuery().resolve()}
                        description="Incorrectly Tagged"
                    />
                ),
            },
            {
                accessor: 'undecided',
                id: 'undecided',
                defaultWidth: 90,
                header: 'Not Tagged',
                headerRenderer: () => (
                    <TooltipWhite
                        withinPortal
                        label={
                            <>
                                Services with no map-migrated value, but are covered by the
                                <br /> MAP program and were created during the contract period
                            </>
                        }
                    >
                        <div style={{ lineHeight: '25px' }}>
                            {footer.totals?.undecided! >= 1 ? (
                                <>
                                    {' '}
                                    <AlertTriangle style={{ marginBottom: -4 }} size={16} color={theme.colors.warning[6]} /> Not <br /> Tagged
                                </>
                            ) : (
                                <>
                                    Not <br /> Tagged
                                </>
                            )}
                        </div>
                    </TooltipWhite>
                ),
                align: format === 'money' ? 'right' : 'center',
                type: 'number',
                groupName: 'Eligible Resources',
                filter: {
                    filterField: 'undecided',
                    filterType: 'number',
                    name: 'Not Tagged',
                    fieldPickerRenderer: () => 'Not Tagged',
                },
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.undecided}
                        format={format}
                        criteria={mapResourceQuerySvc?.getUndecidedQuery().resolve()}
                        description="Not Tagged"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.undecided}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getUndecidedQuery().resolve()}
                        description="Not Tagged"
                    />
                ),
            },
            {
                accessor: 'outsideContractTerm',
                id: 'outsideContractTerm',
                defaultWidth: 145,
                header: 'Created Outside Contract Period',
                align: format === 'money' ? 'right' : 'center',
                helpText: 'Resources that were created outside of the contract period',
                type: 'number',
                filter: {
                    filterField: 'outsideContractTerm',
                    filterType: 'number',
                    name: 'Launched Outside Contract',
                    fieldPickerRenderer: () => 'Launched Outside Contract',
                },
                groupName: 'Ineligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.outsideContractTerm}
                        format={format}
                        criteria={{ Operation: 'and', Operands: [mapResourceQuerySvc!.getOutsidePeriodQuery().resolve()] }}
                        description="Outside Contract Term"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.outsideContractTerm}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getOutsidePeriodQuery().resolve()}
                        description="Outside Contract Term"
                    />
                ),
                headerRenderer: () => (
                    <TooltipWhite withinPortal label="Resources that were created outside of the contract period">
                        <div style={{ lineHeight: '25px' }}>
                            Created Outside <br /> Contract Period
                        </div>
                    </TooltipWhite>
                ),
            },
            {
                accessor: 'notCovered',
                id: 'notCovered',
                defaultWidth: 110,
                header: 'Not Covered Service',
                align: format === 'money' ? 'right' : 'center',
                helpText: 'Resources that are not covered by the MAP program',
                type: 'number',
                filter: {
                    filterField: 'notCovered',
                    filterType: 'number',
                    name: 'Not Covered Service',
                    fieldPickerRenderer: () => 'Not Covered Service',
                },
                groupName: 'Ineligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.notCovered}
                        format={format}
                        criteria={{ Operation: 'and', Operands: [mapResourceQuerySvc!.getNotCoveredQuery().resolve()] }}
                        description="Not Covered"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.notCovered}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getNotCoveredQuery().resolve()}
                        description="Not Covered"
                    />
                ),
                headerRenderer: () => (
                    <TooltipWhite withinPortal label="Resources that are not covered by the MAP program">
                        <div style={{ lineHeight: '25px' }}>
                            Not Covered <br />
                            Service
                        </div>
                    </TooltipWhite>
                ),
            },
            {
                accessor: 'taggedNotEligible',
                id: 'taggedNotEligible',
                defaultWidth: 100,
                header: 'Tagged as Ineligible',
                align: format === 'money' ? 'right' : 'center',
                helpText: 'Resources that are tagged as not-eligible-workload.',
                type: 'number',
                filter: {
                    filterField: 'taggedNotEligible',
                    filterType: 'number',
                    name: 'Not Eligible Workload',
                    fieldPickerRenderer: () => 'Not Eligible Workload',
                },
                groupName: 'Ineligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.taggedNotEligible}
                        format={format}
                        criteria={{ Operation: 'and', Operands: [mapResourceQuerySvc!.getIneligbleTaggedQuery().resolve()] }}
                        description="Not Eligible Workload"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.taggedNotEligible}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getIneligbleTaggedQuery().resolve()}
                        description="Tagged as Ineligible"
                    />
                ),
                headerRenderer: () => (
                    <TooltipWhite withinPortal label="Resources that are tagged as not-eligible-workload.">
                        <div style={{ lineHeight: '25px' }}>
                            Not Eligible
                            <br />
                            Workload
                        </div>
                    </TooltipWhite>
                ),
            },
            {
                accessor: 'overRide',
                id: 'overRide',
                defaultWidth: 140,
                header: 'Ineligible MAP Tags',
                align: format === 'money' ? 'right' : 'center',
                helpText: 'Resource has correct map-migration tag but is not eligible for MAP program based on creation date or service type.',
                type: 'number',
                filter: {
                    filterField: 'overRide',
                    filterType: 'number',
                    name: 'Override',
                    fieldPickerRenderer: () => 'Override',
                },
                headerRenderer: () => (
                    <TooltipWhite
                        withinPortal
                        label="Resource has correct map-migration tag but is not eligible for MAP program based on creation date or service type."
                    >
                        <div style={{ lineHeight: '25px' }}>
                            {footer.totals?.overRide! >= 1 ? (
                                <>
                                    {' '}
                                    <AlertTriangle style={{ marginBottom: -4 }} size={16} color={theme.colors.warning[6]} /> Ineligible <br /> MAP
                                    Tags
                                </>
                            ) : (
                                <>
                                    Ineligible <br /> MAP Tags
                                </>
                            )}
                        </div>
                    </TooltipWhite>
                ),
                groupName: 'Ineligible Resources',
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.overRide}
                        format={format}
                        criteria={{ Operation: 'and', Operands: [mapResourceQuerySvc!.getOverrideQuery().resolve()] }}
                        description="Override"
                    />
                ),
                footerRenderer: () => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        value={footer.totals?.overRide}
                        format={format}
                        contractId={contract.Id}
                        criteria={mapResourceQuerySvc?.getOverrideQuery().resolve()}
                        description="Override"
                    />
                ),
            },
            {
                accessor: 'total',
                id: 'total',
                defaultWidth: 85,
                header: 'Total',
                align: format === 'money' ? 'right' : 'center',
                type: 'number',
                filter: {
                    filterField: 'total',
                    filterType: 'number',
                    name: 'Total',
                },
                cellRenderer: (item) => (
                    <CountCell
                        gridFilterRef={gridFilterRef.filter}
                        contractId={contract.Id}
                        pivot={pivotColumn}
                        type={item.type}
                        value={item.total}
                        format={format}
                    />
                ),
                footerRenderer: () => {
                    useEvent(footer.onChange);
                    return <CountCell gridFilterRef={gridFilterRef.filter} format={format} value={footer.totals?.total} contractId={contract.Id} />;
                },
            },
        ] as ColumnConfig<CoverageRecord>[];
    }, [viewBy, aggregateBy]);

    const cols = useMemo(() => {
        if (otherContracts && otherContracts.length > 0) {
            return columns;
        } else {
            return columns.filter((c) => c.id !== 'correctother');
        }
    }, [viewBy, aggregateBy]);

    useEffect(() => {
        if (grid) {
            grid.refresh(true);
        }
    }, [viewBy, grid]);

    const getData = useCallback(
        async (gridQuery: Query) => {
            let data = [] as CoverageRecord[];
            if (mapResourceQuerySvc) {
                onTotalsChanged();
                const criteria = gridQuery.Where && 'Operands' in gridQuery.Where ? (gridQuery.Where.Operands as IQueryExpr[]) : [];
                const resultFields = new Set([
                    'correct',
                    'correctother',
                    'incorrect',
                    'notCovered',
                    'outsideContractTerm',
                    'taggedNotEligible',
                    'overRide',
                    'total',
                    'type',
                    'undecided',
                ] as string[]);

                const { backendFilters, frontendFilters } = criteria.reduce(
                    (result, expr) => {
                        if (traverseExpr(expr as QueryExpr, (e) => 'Field' in e && resultFields.has(e.Field))) {
                            result.frontendFilters.push(expr);
                        } else {
                            result.backendFilters.push(expr);
                        }
                        return result;
                    },
                    { backendFilters: [] as IQueryExpr[], frontendFilters: [] as IQueryExpr[] }
                );

                const query = await queryBuilder<Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date; Last30DaysCost: number }>()
                    .select((b) => ({
                        type: {
                            Operation: 'values',
                            Operands: [{ Field: pivotColumn }, { Value: '' }, { Value: ValuesGroupOtherText }],
                        } as unknown as string,
                        total: aggregateBy === 'cost' ? b.sum(b.model.Last30DaysCost) : b.count(),
                        correct:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getCorrectlyTaggedQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getCorrectlyTaggedQuery()),
                        correctother:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getCorrectlyTaggedOtherContractQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getCorrectlyTaggedOtherContractQuery()),
                        incorrect:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getIncorrectlyTaggedQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getIncorrectlyTaggedQuery()),
                        undecided:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getUndecidedQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getUndecidedQuery()),
                        notCovered:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getNotCoveredQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getNotCoveredQuery()),
                        taggedNotEligible:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getIneligbleTaggedQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getIneligbleTaggedQuery()),
                        overRide:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getOverrideQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getOverrideQuery()),
                        outsideContractTerm:
                            aggregateBy === 'cost'
                                ? b.aggIf(mapResourceQuerySvc.getOutsidePeriodQuery(), b.sum(b.model.Last30DaysCost))
                                : b.countIf(mapResourceQuerySvc.getOutsidePeriodQuery()),
                    }))
                    .build();
                query.Where = {
                    Operation: 'and',
                    Operands: [
                        ...backendFilters,
                        { Operation: 'eq', Operands: [{ Field: 'ManagementAccount' }, { Value: contract.AccountIds ?? [] }] },
                    ],
                };
                query.Take = null;
                query.Skip = null;
                const results = (await postResourcesQuery(query)) as QueryResult<CoverageRecord>;

                gridFilterRef.filter = backendFilters;
                const round = (value: number) => Math.round(value * 100) / 100;
                results?.Results?.forEach((r) => {
                    r.total = round(r.total);
                    r.correct = round(r.correct);
                    r.correctother = round(r.correctother);
                    r.incorrect = round(r.incorrect);
                    r.undecided = round(r.undecided);
                    r.notCovered = round(r.notCovered);
                    r.taggedNotEligible = round(r.taggedNotEligible);
                    r.overRide = round(r.overRide);
                    r.outsideContractTerm = round(r.outsideContractTerm);
                });
                const datasource = new GridArrayDataSource(results?.Results ?? []);
                data = datasource.applyState({ filters: frontendFilters, sort: gridQuery.Sort ?? [] });
                updateFooter(data);
            }
            return { Count: data.length, Results: data };
        },
        [pivotColumn, aggregateBy]
    );
    const gridKey = useId(getData);

    useEvent(
        refreshEvent,
        useCallback(() => {
            grid?.refresh(false);
        }, [grid])
    );

    useEvent(
        grid?.itemCount,
        useCallback(() => {
            if (grid) {
                needResize();
            }
        }, [grid])
    );
    const rowCount = useEventValue(grid?.itemCount) ?? 0;

    return (
        <Box sx={{ height: '100%', minHeight: '300px', maxHeight: `calc(${rowCount * 30}px + 164px)`, flex: 1, overflow: 'hidden' }}>
            {coverageSchema && schemaValueProvider && resourceSchema ? (
                <DataGrid
                    key={gridKey}
                    onModelLoaded={attachGrid}
                    hideGlobalSearch
                    hideHeader
                    showHeaderGroups
                    leftTopPlaceHolder={<ViewByPicker field={viewBy} schema={resourceSchema} onChange={setViewBy} />}
                    headerHeight={50}
                    dataSource={getData}
                    columns={cols}
                    schemaSvc={coverageSchema}
                    allowNonColumnFilters
                    filterValueProvider={schemaValueProvider}
                    renderColumnGroup={(g) => <Text style={{ color: g === 'Ineligible Resources' ? theme.colors.warning[6] : undefined }}>{g}</Text>}
                    groupConfig={{
                        'Eligible Resources': { color: theme.colors.success[2] },
                        'Ineligible Resources': { color: theme.colors.warning[2] },
                    }}
                    exportName="MAP Coverage Stats"
                    allowLoadAllPages
                    hideColumnSelector
                    hideMenu={false}
                    rightTopPlaceHolder={
                        <Group noWrap>
                            <SegmentedControl
                                data={[
                                    {
                                        label: '30-Day Spend',
                                        value: 'cost',
                                    },
                                    { label: 'Resources', value: 'count' },
                                ]}
                                value={aggregateBy}
                                onChange={(v) => onAggregateByChange(v as 'cost' | 'count')}
                            />
                            <Tooltip label="Refresh">
                                <ActionIcon color="primary" onClick={refreshEvent.emitCurrent}>
                                    <Refresh size={20} />
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    }
                    renderFooter
                />
            ) : null}
        </Box>
    );
}

function CoverageProgress({ aggregateBy, data }: { aggregateBy: AggregateBy; data?: Partial<CoverageRecord> }) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();

    const incorrect = data?.incorrect ?? 0;
    const notTagged = data?.undecided ?? 0;
    const ineligible = data?.overRide ?? 0;
    const total = (data?.incorrect ?? 0) + (data?.undecided ?? 0) + (data?.overRide ?? 0);

    const formatter = useMemo(
        () => (value: number) => aggregateBy === 'cost' ? (value === 0 ? '-' : fmtSvc.formatMoneyNoDecimals(value)) : fmtSvc.formatInt(value),
        [aggregateBy]
    );
    const loading = !data;

    const contractEvts = useDi(MapContractsEvents);

    const sendNoSelectionEvent = () => {
        contractEvts.onNoSelection.emit();
    };

    return (
        <>
            <Box mb="sm">
                <ScoreContainer>
                    <MapScoreMain
                        onMouseEnter={() => {
                            contractEvts.onActionRequired.emit();
                        }}
                        onMouseLeave={sendNoSelectionEvent}
                        color="gray"
                        loading={loading}
                        title={
                            <>
                                Action <br /> Required
                            </>
                        }
                        atid="ActionRequired"
                        value={formatter(total)}
                    />
                    <Space w="sm" />
                    <MapScore
                        onMouseEnter={() => {
                            contractEvts.onTaggedIncorrectly.emit();
                        }}
                        onMouseLeave={sendNoSelectionEvent}
                        indent
                        color="error"
                        loading={loading}
                        title={
                            <>
                                Tagged <br /> Incorrectly
                            </>
                        }
                        atid="TaggedIncorrectly"
                        value={formatter(incorrect)}
                        intValue={incorrect}
                    />
                    <Space w="sm" />
                    <MapScore
                        onMouseEnter={() => {
                            contractEvts.onNotTagged.emit();
                        }}
                        onMouseLeave={sendNoSelectionEvent}
                        color="warning"
                        loading={loading}
                        title={
                            <>
                                Not
                                <br /> Tagged
                            </>
                        }
                        value={formatter(notTagged)}
                        intValue={notTagged}
                        atid="NotTagged"
                    />
                    <Space w="sm" />
                    <MapScore
                        onMouseEnter={() => {
                            contractEvts.onIneligibleMAPTags.emit();
                        }}
                        onMouseLeave={sendNoSelectionEvent}
                        color="gray"
                        loading={loading}
                        title={
                            <>
                                Ineligible
                                <br /> MAP Tags
                            </>
                        }
                        value={formatter(ineligible)}
                        intValue={ineligible}
                        atid="IneligibleMAPTags"
                    />
                </ScoreContainer>
            </Box>
        </>
    );
}
const ScoreContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export function CoverageOverview({ contract, otherContracts }: { contract: MapContract; otherContracts: MapContract[] }) {
    const theme = useMantineTheme();
    const refreshRequested = useMemo(() => EventEmitter.empty(), []);
    const [aggregateBy, setAggregateBy] = useState<AggregateBy>('count');
    const [totals, setTotals] = useState<Partial<CoverageRecord>>();

    return (
        <ReportPanel>
            <ReportHeader>
                <Title order={4}>Manage Tags</Title>
            </ReportHeader>
            <ReportContainer fullHeight>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <CoverageProgress data={totals} aggregateBy={aggregateBy} />
                    <CoverageGrid
                        onAggregateByChange={setAggregateBy}
                        onTotalsChanged={setTotals}
                        aggregateBy={aggregateBy}
                        refreshEvent={refreshRequested}
                        contract={contract}
                        otherContracts={otherContracts}
                    />
                </Box>
            </ReportContainer>
        </ReportPanel>
    );
}

export function MapScoreMain({
    title,
    value,
    helpText,
    loading,
    color,
    titlePos = 'top',
    atid,
    onMouseEnter,
    onMouseLeave,
}: {
    title: ReactNode;
    value: string;
    helpText?: ReactNode;
    loading?: boolean;
    color?: MantineColor;
    titlePos?: 'top' | 'bottom';
    atid?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}) {
    const theme = useMantineTheme();
    const { ref: containerRef, width: containerWidth } = useElementSize();
    const scale = 1;

    return (
        <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Badge
                variant="filled"
                sx={{
                    height: '55px',
                    width: '300px',
                    backgroundColor: theme.colors.gray[8],
                    position: 'relative',
                    zIndex: 1,
                    marginRight: -32,
                    textTransform: 'none',
                    fontWeight: 'normal',
                }}
            >
                <Group align="center">
                    <Text
                        size="sm"
                        align="right"
                        color="gray.4"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: 1 }}
                    >
                        {title}
                    </Text>

                    <Text
                        sx={{
                            fontSize: '2rem',
                            lineHeight: `calc(${theme.lineHeight} * ${scale})`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'left',
                        }}
                        data-atid={atid + ':' + value}
                    >
                        {loading ? <Loader /> : value}
                    </Text>
                </Group>
            </Badge>
            <Clearfix />
        </Box>
    );
}

export function MapScore({
    title,
    value,
    intValue,
    helpText,
    loading,
    color,
    titlePos = 'top',
    indent,
    atid,
    onMouseEnter,
    onMouseLeave,
}: {
    title: ReactNode;
    value: string;
    intValue: number;
    helpText?: ReactNode;
    loading?: boolean;
    color?: MantineColor;
    titlePos?: 'top' | 'bottom';
    indent?: boolean;
    atid?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}) {
    const theme = useMantineTheme();
    const { ref: containerRef, width: containerWidth } = useElementSize();
    const [textWidth, setTextWidth] = useState(0);
    const scale = textWidth > containerWidth ? containerWidth / textWidth : 1;

    return (
        <>
            <Box
                component={MapScoreComponent}
                indent={indent}
                ref={containerRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                sx={{
                    height: '55px',
                    textAlign: 'center',
                    borderTop: '5px solid',
                    borderTopColor: color == 'error' ? theme.colors.error[6] : color == 'warning' ? theme.colors.warning[6] : theme.colors.gray[6],
                    backgroundColor: color == 'error' ? theme.colors.error[2] : color == 'warning' ? theme.colors.warning[2] : theme.colors.gray[2],
                }}
            >
                <Group align="center">
                    {titlePos === 'top' ? (
                        <Text align="right" color={'gray'} size="sm" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: 1 }}>
                            {title}
                        </Text>
                    ) : null}
                    <Text
                        color={color}
                        sx={{
                            fontSize: intValue < 1000000 ? '2rem' : containerWidth > 265 ? '1.5rem' : '1rem',
                            lineHeight: `calc(${theme.lineHeight} * ${scale})`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'left',
                        }}
                        data-atid={atid + ':' + value}
                    >
                        {loading ? <Loader /> : value}
                    </Text>
                    <Text sx={{ fontSize: '2rem' }}>
                        <MeasuredText onWidth={setTextWidth} text={value} />
                    </Text>
                    {titlePos === 'bottom' ? (
                        <Text color={'gray'} size="sm" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {title}
                        </Text>
                    ) : null}
                </Group>
            </Box>
            <Clearfix />
        </>
    );
}

const MapScoreComponent = styled.div<{ indent?: boolean }>`
    text-align: center;
    width: 25%;
    display: flex;
    justify-content: center;
    position: relative;
    :after {
        content: '';
        display: ${(p) => (p.indent ? 'block' : 'none')};
        background: #fff;
        position: absolute;
        top: -8px;
        bottom: -4px;
        width: 27.5px;
        left: 0px;
        border-radius: 0 28px 28px 0;
    }
`;
