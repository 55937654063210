import { Job } from '@apis/Resources';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Card,
    Divider,
    Drawer,
    Group,
    Loader,
    Popover,
    Progress,
    Text,
    ThemeIcon,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { JobService } from '@root/Services/Jobs/JobService';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { Fragment, useState, useCallback, useEffect, useMemo } from 'react';
import { ListCheck } from 'tabler-icons-react';
import { ActivityDetailsOpener } from '../ActionDetails';
import { ActivityItemAdapter, JobStatus } from './ActivityTypes';
import { ActivityPanelModel } from './Models';
import { ActivityPoller } from './ActivityPoller';
import { X } from 'tabler-icons-react';

export function ActivityPanelOpener() {
    const company = useCompany();
    const { user } = useDi(AuthenticationService);
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(ActivityPanelModel), []);
    const poller = useMemo(() => di.resolve(ActivityPoller), []);
    const [opened, { close, toggle }] = useDisclosure(false);
    const [selected, setSelected] = useState<JobStatus>();
    const jobsUpdated = useEventValue(model.jobsUpdated);
    useEffect(() => {
        poller.load(user, company);
    }, []);
    useEffect(() => {
        poller.load(user, company);
    }, [user, company]);
    const hidePopover = useCallback(() => {
        if (opened) {
            model.pause();
            close();
        }
    }, [close, model, opened]);
    const togglePopover = useCallback(() => {
        if (!opened) {
            model.poll();
            model.seeJobs();
        }
        toggle();
    }, [opened, toggle]);
    useEvent(model.toggleRequested, togglePopover);
    const selectJob = useCallback(
        (jobStatus: JobStatus) => {
            hidePopover();
            setSelected(jobStatus);
        },
        [setSelected, hidePopover]
    );

    return (
        <>
            <Popover opened={opened} onClose={hidePopover} withinPortal radius="lg">
                <Popover.Target>
                    <ActionIcon data-atid="ActivityLogButton" onClick={togglePopover}>
                        <ActivityPanelIcon model={model} />
                    </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown p={0} sx={{ borderWidth: 0 }}>
                    <ActivityPanel model={model} visible={opened} onClose={close} onSelect={selectJob} />
                </Popover.Dropdown>
            </Popover>
            <ActivityDetailsOpener item={selected?.job} onClose={() => setSelected(undefined)} />
        </>
    );
}

function ActivityPanelIcon({ model }: { model: ActivityPanelModel }) {
    const theme = useMantineTheme();
    useEvent(model.jobsUpdated);
    return (
        <IconContainer mode={model.newJobs ? 'dotted' : undefined}>
            <ListCheck style={{ color: model.newJobs ? theme.colors.primary?.[6] : theme.black }} className={model.newJobs ? 'dotted' : undefined} />
        </IconContainer>
    );
}

interface IActivityPanelProps {
    model: ActivityPanelModel;
    visible: boolean;
    onClose: () => void;
    onSelect: (jobStatus: JobStatus) => void;
}
function ActivityPanel({ model, visible, onClose, onSelect }: IActivityPanelProps) {
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav(routeLoader.getTopRouteMeta());
    const { getDescendUrl, goto } = nav;
    const allActivityUrl = getDescendUrl('actionslog');
    const company = useCompany();
    const viewAll = useCallback(
        (e: { preventDefault: () => void }) => {
            if (routeLoader.getTopRouteMeta().endpointInfo?.name?.toLowerCase() !== 'actionslog') {
                goto(allActivityUrl);
                //goto('/' + company?.DatabaseName + '/settings/actionslog');
            }
            onClose();
            e.preventDefault();
        },
        [onClose]
    );
    const closeCard = useCallback(
        (e: { preventDefault: () => void }) => {
            onClose();
        },
        [onClose]
    );
    const jobService = useDi(JobService);
    const jobs = useEventValue(model.jobsUpdated);
    const jobGroups = jobs?.reduce(
        (result, item) => {
            const { inProgress } = jobService.getStatusInfo(item.status);
            if (inProgress) {
                result.inProgress.push(item);
            } else {
                result.finished.push(item);
            }
            return result;
        },
        { inProgress: [], finished: [] } as { inProgress: JobStatus[]; finished: JobStatus[] }
    );
    return (
        <Card component={Container} radius="lg" p={0} withBorder shadow="lg" mr="xl">
            <Toolbar>
                <Group position="apart">
                    <Text py="sm" px="md">
                        Recent Activity
                    </Text>
                    <Button size="xs" variant="subtle" mr={10} color="gray" onClick={closeCard}>
                        <X />
                    </Button>
                </Group>
            </Toolbar>
            <Divider />
            <Content data-atid={'ActivityLogContainer'}>
                {!visible ? null : (
                    <>
                        {jobs?.length ? null : <Text align="center">No recent activity</Text>}
                        <ActivitySection
                            nav={nav}
                            onSelect={onSelect}
                            items={jobGroups?.inProgress}
                            model={model}
                            title={jobGroups?.finished.length ? 'In Progress' : ''}
                            data-atid="ActivityLogInProgressSection"
                        />
                        <ActivitySection
                            nav={nav}
                            onSelect={onSelect}
                            items={jobGroups?.finished}
                            model={model}
                            title={jobGroups?.inProgress.length ? 'Finished' : ''}
                            data-atid="ActivityLogFinishedSection"
                        />
                    </>
                )}
            </Content>
            <Divider />
            <Toolbar>
                <Text align="center" py="xs">
                    <Anchor data-atid={'ActivityLogLinkAnchor'} size="sm" href={allActivityUrl} onClick={viewAll}>
                        View All Activity
                    </Anchor>
                </Text>
            </Toolbar>
        </Card>
    );
}

function ActivitySection({
    title,
    items,
    onSelect,
    model,
    nav,
}: {
    title: string;
    items?: JobStatus[];
    onSelect: (jobStatus: JobStatus) => void;
    model: ActivityPanelModel;
    nav: NavigationService;
}) {
    return (
        <>
            {title && items?.length ? (
                <>
                    <Text pl="md" pt={4}>
                        {title}
                    </Text>
                </>
            ) : null}
            {items?.map((j, i) => (
                <Fragment key={j.job.Id!}>
                    {i > 0 ? null : null}
                    <ActivityItem nav={nav} onClick={() => onSelect(j)} adapter={model.activityItemAdapter} item={j} />
                </Fragment>
            ))}
        </>
    );
}

function ActivityItem({
    item,
    adapter,
    onClick,
    nav,
}: {
    item: JobStatus;
    adapter: ActivityItemAdapter;
    onClick: () => void;
    nav: NavigationService;
}) {
    const { icon: Icon, ...model } = adapter.getActivityModel(item);
    const { inProgress, progress: multiJobProgress, hasErrors } = adapter.jobService.getStatusInfo(item.status);
    const singleJobProgress =
        item.job.Progress && item.job.Progress.Total && item.job.Progress.Progress
            ? Math.min(1, item.job.Progress.Progress / item.job.Progress.Total)
            : null;
    const progress = singleJobProgress === null ? multiJobProgress : singleJobProgress;
    const ago = adapter.formatSvc.timeAgo(new Date(item.job.QueuedAt!));
    const finish = inProgress ? 'In progress' : hasErrors ? 'Finished with errors' : 'Finished';
    const statusText = model.action?.description ?? finish;
    const [actionStarted, setActionStarted] = useState(false);
    const handleClick = async () => {
        if (model.action?.execute) {
            setActionStarted(true);
            try {
                await model.action.execute(nav);
            } finally {
                setActionStarted(false);
            }
        } else {
            onClick();
        }
    };

    return (
        <Box component={ItemLink} py="xs" px="md" onClick={handleClick}>
            <Group noWrap>
                <ThemeIcon color={hasErrors ? 'warning' : 'success'} variant="light" size="xl" radius="xl">
                    {actionStarted ? <Loader /> : <Icon />}
                </ThemeIcon>
                <Box>
                    <Group noWrap>
                        <Text size="sm">{model.title}</Text>
                        <Text size="xs" color="dimmed">
                            {ago}
                        </Text>
                    </Group>
                    <Text size="xs">{model.description}</Text>
                    {inProgress && model.showProgress ? <Progress value={Math.min(100, progress * 100)} /> : null}
                    {!inProgress || !model.showProgress ? (
                        <Text size="xs" color={hasErrors ? 'warning' : 'success'}>
                            {statusText}
                        </Text>
                    ) : null}
                </Box>
            </Group>
        </Box>
    );
}

const ItemLink = styled.a`
    display: block;
    border-radius: ${(p) => p.theme.radius.sm}px;
    cursor: pointer;
    :hover {
        background: ${(p) => p.theme.colors.primary[0]};
    }
`;

const Container = styled.div`
    height: 60vh;
    min-height: 600px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const IconContainer = styled.div<{ mode?: 'dotted' }>`
    position: relative;
    ::after {
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 5px;
        background: ${(p) => (p.mode === 'dotted' ? p.theme.colors.success[6] : undefined)};
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
`;

const Toolbar = styled.div`
    flex: 0;
`;
const Content = styled.div`
    flex: 1;
    min-height: 0;
    height: 100%;
    overflow: auto;
    padding: ${(p) => p.theme.spacing.xs / 2}px;
`;
