import {
    getMspGetMspsForCompany,
    getSubscriptionGetSubscriptionRate,
    getSubscriptionGetSubscriptionsByCompany,
    getSubscriptionGetSubscriptionTypes,
    postSubscriptionAddSubscriptionBySupportUser,
} from '@apis/Customers';
import { CompanyType, SubscriptionType } from '@apis/Customers/model';
import { Box, Button, Card, Divider, Group, LoadingOverlay, NumberInput, Radio, Select, Space, Text, useMantineTheme } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useDi } from '@root/Services/DI';
import { sub } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

export function AddSubscriptionModal({ onClose, onSubmit, companyId }: { onClose: () => void; onSubmit: () => void; companyId: number }) {
    const [subscriptionTypesData, setSubscriptionTypesData] = useState<{ value: string; label: string }[]>([]);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<string>('');
    const [subscriptionFamily, setSubscriptionFamily] = useState<string>('Standard');
    const [hasPercentDiscount, setHasPercentDiscount] = useState(true);
    const [discountValue, setDiscountValue] = useState(0);
    const [subcriptionTerm, setSubscriptionTerm] = useState(12);
    const [selectedSubscriptionPrice, setSelectedSubscriptionPrice] = useState(0);
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const companyContext = useDi(CompanyContextService);

    var company = useCompany();

    useEffect(() => {
        (async () => {
            setSelectedSubscriptionPrice(await getSubscriptionGetSubscriptionRate({ subscriptionName: selectedSubscriptionType }));
        })();
    }, [selectedSubscriptionType]);
    useEffect(() => {
        (async () => {
            /**
             * Retrieves subscription types and filters them based on certain conditions.
             * - Filters out subscription types with terms other than 'Month' or 'Trial'.
             * - Filters out subscription types that are already present in the company.
             * - Filters subscription types based on the company's type (Msp or Standard).
             */
            var subscriptionTypes = await getSubscriptionGetSubscriptionTypes();
            subscriptionTypes = subscriptionTypes?.filter((x) => x.Term === 'Month' || x.Term === 'Trial');
            const presentSubscriptions = await getSubscriptionGetSubscriptionsByCompany();
            if (presentSubscriptions?.length > 0) {
                subscriptionTypes = subscriptionTypes?.filter((x) => !presentSubscriptions.some((y) => x.ExternalId === y.ExternalPlanId));
            }

            var Msp = await getMspGetMspsForCompany({ companyId: companyId });
            if (company?.Type === 'Msp' || (Msp?.length > 0 && Msp[0]?.Type === CompanyType.Msp)) {
                subscriptionTypes = subscriptionTypes?.filter((x) => x.Type === 'Msp');
                setSubscriptionFamily('Msp');
            } else {
                subscriptionTypes = subscriptionTypes?.filter((x) => x.Type === 'Standard');
            }
            setSubscriptionTypesData(subscriptionTypes?.map((x) => ({ value: x.Name ?? '', label: x.FriendlyName ?? '' })) ?? []);
        })();
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        companyContext.withParentCompany(async () => {
            await postSubscriptionAddSubscriptionBySupportUser({
                companyId: companyId,
                subscriptionType: selectedSubscriptionType,
                term: subcriptionTerm,
                discountType: hasPercentDiscount ? 'Percent' : 'Amount',
                discount: discountValue,
            });
        });

        setLoading(false);
        onSubmit();
    };

    return (
        <Box>
            {loading && <LoadingOverlay visible={true} />}
            <Divider />
            <Space h="xs" />
            <Text size="sm" weight={700}>
                Subscription Information
            </Text>
            <Space h="xs" />
            <Card sx={{ backgroundColor: theme.colors?.gray?.[2] }} radius="md">
                <Select
                    withinPortal={false}
                    data-atid="SubscriptionType"
                    label="Subscription Type"
                    placeholder="Select Subscription Type"
                    searchable
                    required
                    onChange={(val) => setSelectedSubscriptionType(val ?? '')}
                    data={subscriptionTypesData}
                ></Select>
                <Space h="xs" />
                {subscriptionFamily === 'Standard' && (
                    <>
                        <Group position="left">
                            <Text>Subscription Price:</Text>
                            <Text weight={600}> {selectedSubscriptionPrice > 0 ? '$' + selectedSubscriptionPrice : ''}</Text>
                        </Group>
                        <Space h="xs" />
                    </>
                )}
                <Group>
                    <Text>Discount Type: </Text>
                    <Space w="xs" />
                    <Radio
                        data-atid="DiscountTypePercent"
                        label="Percent"
                        checked={hasPercentDiscount}
                        onChange={() => setHasPercentDiscount(true)}
                        value={''}
                    />
                    <Radio
                        data-atid="DiscountTypeAmount"
                        label="Amount"
                        checked={!hasPercentDiscount}
                        onChange={() => setHasPercentDiscount(false)}
                        value={''}
                    />
                </Group>
                <Space h="xs" />
                <Group>
                    <Text>Discount Value: </Text>
                    <Space w="xs" />
                    <NumberInput
                        data-atid="DiscountValue"
                        value={discountValue}
                        onChange={(val) => setDiscountValue(val ?? 0)}
                        max={hasPercentDiscount ? 100 : undefined}
                    />
                </Group>
                <Space h="xs" />
                <Group>
                    <Text>Subscription Term: </Text>
                    <NumberInput data-atid="SubscriptionTerm" value={subcriptionTerm} onChange={(val) => setSubscriptionTerm(val ?? 0)} />
                    <Text>Months</Text>
                </Group>
                <Space h="xs" />
                <Group p="lg" position="right" sx={{ background: theme.colors?.gray?.[2], borderRadius: `0 0 4px 4px` }}>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="submit">
                        Add Subscription
                    </Button>
                </Group>
            </Card>
        </Box>
    );
}
