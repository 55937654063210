import { PrimitiveTypeIcon } from '@root/Design/Primitives';
import { FieldInfo, primitiveIcons, PrimitiveType, SchemaService, TypeInfo } from '@root/Services/QueryExpr';
import { ReactNode, useCallback, useMemo } from 'react';
import { Picker } from './Picker';

export function FieldPicker({ schema, types, mode, selections, onChange, width = 400, ...props }: FieldPickerProps) {
    const items = useMemo(() => {
        const rootTypes: FieldPickerItem[] = schema.rootTypeInfo.length > 1 ? schema.rootTypeInfo : schema.rootTypeInfo[0]?.children ?? [];
        return rootTypes;
    }, [schema]);
    const typeLookup = useMemo(() => (types ? new Set<string>(types) : undefined), [types]);
    const isSelectable = useMemo(() => {
        return props.isSelectable ? props.isSelectable : (o: FieldPickerItem) => o instanceof FieldInfo && o.isPrimitive;
    }, [props.isSelectable]);
    const minimizeHeight = props.minimizeHeight !== false;
    const height = props.height ?? (minimizeHeight ? 350 : undefined);
    const filter = useCallback(
        (item: FieldPickerItem) => {
            return (
                (!typeLookup || ('isPrimitive' in item && typeLookup.has(item.field.TypeName ?? ''))) &&
                (!props.schemaFilter || props.schemaFilter(item))
            );
        },
        [typeLookup, props.schemaFilter]
    );
    const getChildren = useCallback(
        (item: FieldPickerItem) => {
            return props.getChildren ? props.getChildren(item) : item.children;
        },
        [props.getChildren]
    );

    return (
        <Picker
            items={items}
            selections={selections as FieldPickerItem[]}
            onChange={onChange as (selections: FieldPickerItem[]) => void}
            childAccessor={getChildren}
            isSelectable={isSelectable}
            isDisabled={props.isDisabled}
            nameAccessor={(o) => o.name}
            filterPlaceholder="Find field"
            mode={mode}
            width={width}
            minimizeHeight={minimizeHeight}
            height={height}
            renderItem={(o) =>
                'isPrimitive' in o && o.isPrimitive ? (
                    <>
                        <PrimitiveTypeIcon className={primitiveIcons[o.field.TypeName as PrimitiveType]}></PrimitiveTypeIcon>{' '}
                        {props.renderItem?.(o) ?? o.name}
                    </>
                ) : (
                    o.name
                )
            }
            filter={filter}
            data-atid="field-picker"
        />
    );
}
type FieldPickerItem = FieldInfo | TypeInfo;
interface FieldPickerProps {
    schema: SchemaService;
    types?: string[];
    mode: 'single' | 'multiple';
    selections: FieldInfo[];
    isSelectable?: (item: FieldPickerItem) => boolean;
    isDisabled?: (item: FieldPickerItem) => boolean;
    onChange: (selections: FieldInfo[]) => void;
    renderItem?: (item: FieldPickerItem) => ReactNode;
    schemaFilter?: (item: FieldInfo | TypeInfo) => boolean;
    getChildren?: (item: FieldPickerItem) => undefined | FieldPickerItem[];
    width?: number;
    minimizeHeight?: boolean;
    height?: number;
    noFilter?: boolean;
}
