import { Company, IQueryExpr } from '@apis/Customers/model';
import { getInvoiceRuleGetProcessingReport, postDailyRollupMultiQuery, postForecastMultiQuery, postMonthlyRollupMultiQuery } from '@apis/Invoices';
import { PostDailyRollupQueryParams, PostMonthlyRollupQueryParams, Query } from '@apis/Invoices/model';
import { QueryResult } from '@apis/Resources';
import { inject, injectable } from 'tsyringe';
import { AsyncBundler } from '../AsyncBundler';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { FormatService } from '../FormatService';
import { InvoiceApiCache } from './InvoiceApiCache';

@injectable()
export class InvoiceApiService {
    private asyncBundler = new AsyncBundler();

    public constructor(
        @inject(InvoiceApiCache) private readonly cache: InvoiceApiCache,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public getDateRange() {
        return this.cache.getDateRange(this.company.Id);
    }

    public async getProcessingReport(month: Date) {
        return await getInvoiceRuleGetProcessingReport({ month: this.fmtSvc.toJsonShortDate(month) });
    }

    public queryByUsageMonth(query: Query, usageDate: Date) {
        const month = this.fmtSvc.formatYearMonth(usageDate);
        const params = { from: month, to: month } as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [{ Operation: 'eq', Operands: [{ Field: 'UsageMonth' }, { Value: month }] }];
        const where = { Operation: 'and', Operands: dateCriteria };
        if (query.Where) {
            where.Operands.push(query.Where);
        }
        query.Where = where;
        const key = `queryByUsageMonth-${month}`;
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => postDailyRollupMultiQuery(queries, params));
    }

    public query<T = unknown>(query: Query, { from, to }: { from?: Date; to?: Date }, appendDateCriteria: boolean = true): Promise<QueryResult<T>> {
        const params = {} as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [];
        if (from) {
            params.from = this.fmtSvc.toJsonShortDate(from);
        }
        if (to) {
            params.to = this.fmtSvc.toJsonShortDate(to);
        }
        if (appendDateCriteria) {
            if (params.from) {
                dateCriteria.push({ Operation: 'gte', Operands: [{ Field: 'UsageStartDate' }, { Value: params.from }] });
            }
            if (params.to) {
                dateCriteria.push({ Operation: 'lte', Operands: [{ Field: 'UsageStartDate' }, { Value: params.to }] });
            }
        }
        if (dateCriteria.length) {
            const where = { Operation: 'and', Operands: dateCriteria };
            if (query.Where) {
                where.Operands.push(query.Where);
            }
            query.Where = where;
        }

        const key = `${params.from}-${params.to}`;
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => postDailyRollupMultiQuery(queries, params)) as Promise<QueryResult<T>>;
    }

    public queryMonthlyRollup(query: Query, months: Date[]) {
        const params = {
            months: months.map((m) => this.fmtSvc.toJsonShortDate(m)),
        } as PostMonthlyRollupQueryParams;
        const key = JSON.stringify(params);
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => postMonthlyRollupMultiQuery(queries, params));
    }

    public queryForecastData(query: Query, jobId?: string) {
        const where: IQueryExpr[] = !jobId ? [] : [{ Operation: 'eq', Operands: [{ Field: 'JobId' }, { Value: jobId }] }];
        if (query.Where) {
            where.push(query.Where);
        }
        query.Where = !where.length ? undefined : where.length === 1 ? where[0] : { Operation: 'and', Operands: where };
        const key = `forecast-${jobId}`;
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => postForecastMultiQuery(queries));
    }
}
