import { Box, Card, Stack, Tooltip, useMantineTheme } from '@mantine/core';
import { queryBuilder } from '@root/Services/QueryExpr';
import { ColumnConfig, DataSourceConfig, GridGroupByState } from '@root/Components/DataGrid/Models';
import { AssessmentResult } from '@apis/Resources/model';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { FormatService } from '@root/Services/FormatService';
import { IQueryExpr } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi } from '@root/Services/DI';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@root/Components/DataGrid';
import { addMonths, addWeeks, addYears } from 'date-fns';
import { QueryExpr, getAssessmentsGetAssessmentDefinitions, postAssessmentsQueryAssessments, postAssessmentsSearchAll } from '@apis/Resources';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { Clearfix } from '@root/Design/Primitives';
import { AssessmentsHistoryRangePicker, AssessmentsHistoryScoreTypePicker, AssessmentsPlatformSelector } from './Components/AssessmentCommon';
import { FillerSwitch } from '@root/Design/Filler';
import { Platform, PlatformService } from '@root/Services/PlatformService';
import { AssessmentsPage } from './AssessmentsPage';
import { BaseChartKeySelectionStrategy } from '@root/Components/DataGrid/BaseChartKeySelectionStrategy';
import { useEvent } from '@root/Services/EventEmitter'
import { itemsEqual } from '@dnd-kit/sortable/dist/utilities';
import { getFilterFromSelection } from '@root/Components/Invoices/MonthlyInvoicesGrid';
import { ChartWrapper } from '@root/Components/Charts/Design';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { GridArrayDataSource } from '@root/Components/DataGrid/DataSources';

export function HistoryPage() {
    const company = useCompany();
    const formatSvc = useDi(FormatService);
    const today = new Date();
    const platformSvc = useDi(PlatformService);

    //load data here to feed both time line and grid
    const [defaultCriteria, setDefaultCriteria] = useState<IQueryExpr>({});
    const [startDate, setStartDate] = useState<Date>(addMonths(today, -3));
    const [endDate, setEndDate] = useState<Date>(today);
    const [gridFilters, setGridFilters] = useState<IQueryExpr[]>([]);
    const [type, setType] = useState<string>('All');
    const [lineData, setLineData] = useState<Record<string | number, string | number | Date>[]>([]);
    const [gridLineData, setGridLineData] = useState<{ filter: IQueryExpr; label: string; color: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [interval, setInterval] = useState<'week' | 'month' | '3month' | 'year'>('3month');
    const [lineSelection, setLineSelection] = useState<string>('score');
    const [companyPlatforms, setCompanyPlatforms] = useState<Set<Platform>>();

    useEffect(() => {
        (async () => {
            await platformSvc.init();
            setCompanyPlatforms(platformSvc.getPlatforms());
        })();
    }, []);

    useEffect(() => {
        if (companyPlatforms && companyPlatforms.size == 1) {
            setType([...companyPlatforms][0]);
        }
        let types: string[] = [];
        if (type == 'All') {
            types = ['Aws', 'Azure'];
        } else {
            types = [type];
        }
        const applicableTypes = types.filter((platform) => companyPlatforms?.has(platform as Platform));
        const defaultCriteria: IQueryExpr = {
            Operation: 'and',
            Operands: [
                { Operation: 'eq', Operands: [{ field: 'CompanyId' }, { value: company!.Id }] },
                { Operation: 'eq', Operands: [{ field: 'Platform' }, { value: applicableTypes }] },
                { Operation: 'gte', Operands: [{ field: 'AssessmentDate' }, { value: startDate }] },
                { Operation: 'lte', Operands: [{ field: 'AssessmentDate' }, { value: endDate }] },
            ],
        };
        setDefaultCriteria(defaultCriteria);
    }, [startDate, endDate, type, companyPlatforms]);

    useEffect(() => {
        (async () => {
            const where: IQueryExpr = {
                Operation: 'and',
                Operands: [defaultCriteria, ...gridFilters],
            };

            const result = await queryBuilder<{
                AssessmentDate: Date;
                ['AssessmentScore.AssessmentScore']: number;
                ['AssessmentScore.AssessmentSavings']: number;
            }>()
                .where((b) => b.fromExpr(where as QueryExpr))
                .select((b) => ({
                    ...(gridLineData
                        ?.map((line) => ({
                            alias: line.label,
                            expr:
                                lineSelection == 'score'
                                    ? b.aggIf(b.fromExpr(line.filter as QueryExpr), b.avg(b.model['AssessmentScore.AssessmentScore']))
                                    : b.aggIf(b.fromExpr(line.filter as QueryExpr), b.avg(b.model['AssessmentScore.AssessmentSavings'])),
                        }))
                        .reduce((parent, item) => {
                            return { ...parent, [item.alias]: item.expr };
                        }, {}) ?? {}),
                    Date: b.truncDate('day', b.model.AssessmentDate!, 0, undefined, undefined) as unknown as number,
                    Total:
                        lineSelection == 'score'
                            ? b.avg(b.model['AssessmentScore.AssessmentScore'])
                            : b.avg(b.model['AssessmentScore.AssessmentSavings']),
                }))
                .execute(postAssessmentsQueryAssessments);

            const items = result.Results ?? [];
            if (gridLineData.length > 0) {
                const flattenedItems: Record<string, number | string>[] = [];
                for (const item of items) {
                    const entries = Object.entries(item);
                    const date = item.Date;
                    for (const [key, value] of entries) {
                        if (key !== 'Date' && key !== 'Total') {
                            flattenedItems.push({
                                Date: date,
                                Total: value as unknown as number,
                                Label: key,
                            });
                        }
                    }
                }

                const lineData = flattenedItems
                    .sort((a, b) => (a.Date as number) - (b.Date as number))
                    .map((o) => ({
                        Date: formatSvc.formatDate(formatSvc.parseDateNoTime(o.Date as string)),
                        Label: o.Label,
                        Total: lineSelection == 'score' ? (o.Total as number) ?? 0 : o.Total ?? (0 as number),
                    }));
                setLineData(lineData);
            } else {
                const lineData = items.map((o) => ({
                    Date: new Date(o.Date),
                    Total: lineSelection == 'score' ? (o.Total as number) ?? 0 : o.Total ?? (0 as number),
                }));

                setLineData(lineData);
            }
        })();
    }, [defaultCriteria, gridFilters, lineSelection, gridLineData]);

    //load time line controls here to pass as right place holder and have events at this level.
    function toggleInterval(interval: string) {
        switch (interval) {
            case 'week':
                setInterval('week');
                setStartDate(addWeeks(today, -1));
                setEndDate(today);
                break;
            case 'month':
                setInterval('month');
                setStartDate(addMonths(today, -1));
                setEndDate(today);
                break;
            case '3month':
                setInterval('3month');
                setStartDate(addMonths(today, -3));
                setEndDate(today);
                break;
            case 'year':
                setInterval('year');
                setStartDate(addYears(today, -1));
                setEndDate(today);
                break;
        }
    }
    const rightTopPlaceHolder: ReactNode = (
        <>
            <AssessmentsHistoryRangePicker selectedInterval={interval} setInterval={toggleInterval} />
        </>
    );
    //add logic to determine start date based on lookback range component

    const chartProps = useMemo(
        () => ({
            groups: gridLineData.length > 0 ? ['Date', 'Label'] : ['Date'],
            values: ['Total'],
            settings: {
                chartColors: gridLineData.length > 0 ? gridLineData.map((line) => line.color) : undefined,
                format: lineSelection == 'score' ? 'percent' : 'money-whole',
                margin: { top: 20, right: 20, bottom: 60, left: 60 },
                yMax: lineSelection == 'score' ? 100 : undefined,
                interval: 'day',
            } as LineChartSettings,
        }),
        [lineSelection, gridLineData]
    );

    return (
        <FillerSwitch loading={loading}>
            {() => (
                <AssessmentsPage
                    title={'History'}
                    titleRightPlaceholder={
                        <>
                            <Box sx={{ width: companyPlatforms && companyPlatforms.size > 1 ? '300px' : '120px' }}>
                                {companyPlatforms && companyPlatforms.size > 1 ? (
                                    <AssessmentsPlatformSelector style={{ float: 'right' }} selectedType={type} setType={setType} />
                                ) : null}

                                <AssessmentsHistoryScoreTypePicker selectedType={lineSelection} setType={setLineSelection} />
                                <Clearfix />
                            </Box>
                        </>
                    }
                >
                    <Stack sx={{ height: '100%', overflow: 'hidden' }}>
                        <Card sx={{ minHeight: '300px', flex: 0 }} shadow="md" withBorder radius="lg">
                            <LineChart data={lineData} {...chartProps} />
                        </Card>
                        <Box sx={{ flex: 1, minHeight: 0 }}>
                            <HistoricalAssessmentGrid
                                criteria={defaultCriteria}
                                setLoading={setLoading}
                                rightTopPlaceHolder={rightTopPlaceHolder}
                                setGridFilters={setGridFilters}
                                setLineData={setGridLineData}
                            />
                        </Box>
                    </Stack>
                </AssessmentsPage>
            )}
        </FillerSwitch>
    );
}

type HistoricalAssessmentRow = AssessmentResult & { UfTitle: string; Id: string };

interface HistoricalAssessmentGridProps {
    criteria?: IQueryExpr;
    rightTopPlaceHolder?: ReactNode;
    setGridFilters: (filters: IQueryExpr[]) => void;
    setLoading: (loading: boolean) => void;
    setLineData: (data: { filter: IQueryExpr; label: string; color: string }[]) => void;
}
function HistoricalAssessmentGrid({ ...props }: HistoricalAssessmentGridProps) {
    const formatSvc = useDi(FormatService);
    const [grid, setGrid] = useState<DataGridModel>();
    const gridModel = useMemo(() => ({ grid: undefined as DataGridModel | undefined }), []);
    const gridSelectionStrategy = useMemo(() => new HistoricalLineChartSelectionStrategy(6, true), []);
    const [filters, setFilters] = useState<IQueryExpr[]>([]);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        setGrid(dataGrid);
        gridModel.grid = dataGrid;
    };

    const onFilterAdded = (filters: IQueryExpr[]) => {
        setFilters(filters);
        props.setGridFilters(filters);
    };

    const onFilterClearing = () => {
        setFilters([]);
        props.setGridFilters([]);
    };

    const columns = useMemo(
        () =>
            [
                {
                    id: 'AssessmentCode',
                    noResize: true,
                    header: 'Assessment',
                    accessor: (item) => item.AssessmentCode ?? item.UfTitle,
                    filter: {
                        filterType: 'string',
                        name: 'Assessment',
                        filterField: 'AssessmentCode',
                        options: {
                            getValueProvider() {
                                return [
                                    { label: 'EC2 Rightsizing', value: 'EC2 Rightsizing' },
                                    { label: 'RDS Rightsizing', value: 'RDS Rightsizing' },
                                    { label: 'Idle EC2 Resources', value: 'Idle EC2 Resources' },
                                    { label: 'Idle ELB Resources', value: 'Idle ELB Resources' },
                                    { label: 'Idle EMR Resources', value: 'Idle EMR Resources' },
                                    { label: 'Idle RDS Resources', value: 'Idle RDS Resources' },
                                    { label: 'Idle Redshift Resources', value: 'Idle Redshift Resources' },
                                    { label: 'Tag Clarity', value: 'Tag Clarity' },
                                    { label: 'Tag Compliance', value: 'Tag Compliance' },
                                    { label: 'Tag Coverage', value: 'Tag Coverage' },
                                ];
                            },
                        },
                    },
                    sortField: 'AssessmentCode',
                    type: 'string',
                    align: 'left',
                    defaultWidth: 200,
                    noRemove: true,
                    defaultFixed: true,
                    allowGrouping: true,
                },
                {
                    id: 'AssessmentCategory',
                    noResize: true,
                    header: 'Category',
                    accessor: (item) => item.AssessmentCategory,
                    filter: {
                        filterType: 'string',
                        name: 'Category',
                        filterField: 'AssessmentCategory',
                        options: {
                            getValueProvider() {
                                return [
                                    { label: 'Optimization', value: 'Optimization' },
                                    { label: 'Compliance', value: 'Compliance' },
                                ];
                            },
                        },
                    },
                    sortField: 'AssessmentCategory',
                    type: 'string',
                    align: 'left',
                    defaultWidth: 150,
                    noRemove: true,
                    defaultFixed: true,
                },
                {
                    id: 'AssessmentType',
                    noResize: true,
                    header: 'Type',
                    accessor: (item) => item.AssessmentType,
                    filter: {
                        filterType: 'string',
                        name: 'Type',
                        filterField: 'AssessmentType',
                        options: {
                            getValueProvider() {
                                return [
                                    { label: 'Compute', value: 'Compute' },
                                    { label: 'Database', value: 'Database' },
                                    { label: 'Tagging', value: 'Tagging' },
                                ];
                            },
                        },
                    },
                    sortField: 'AssessmentType',
                    type: 'string',
                    align: 'left',
                    defaultWidth: 150,
                    noRemove: true,
                    defaultFixed: true,
                },
                {
                    id: 'AssessmentDate',
                    noResize: true,
                    header: 'Assessment Date',
                    filter: {
                        filterType: 'date',
                        name: 'Date',
                        filterField: 'AssessmentDate',
                    },
                    cellRenderer: (item) => formatSvc.formatDate(formatSvc.toLocalDate(new Date(item.AssessmentDate!))),
                    accessor: (item) => item.AssessmentDate,
                    sortField: 'AssessmentDate',
                    type: 'date',
                    align: 'left',
                    defaultWidth: 210,
                    noRemove: true,
                    defaultFixed: true,
                },
                {
                    id: 'AssessmentScore.AssessmentScore',
                    noResize: true,
                    header: 'Score',
                    align: 'center',
                    cellRenderer: (item) =>
                        typeof item.AssessmentScore?.AssessmentScore === 'number'
                            ? formatSvc.formatInt(Math.round((item.AssessmentScore?.AssessmentScore ?? 0) * 100))
                            : null,
                    accessor: (item) => item.AssessmentScore?.AssessmentScore ?? 0,
                    sortField: 'AssessmentScore.AssessmentScore',
                    type: 'number',
                    filter: true,
                    defaultWidth: 100,
                    noRemove: true,
                    defaultFixed: true,
                },
                {
                    id: 'AssessmentScore.AssessmentSavings',
                    noResize: true,
                    header: 'Savings or Risk',
                    align: 'right',
                    cellRenderer: (item) =>
                        typeof item.AssessmentScore?.AssessmentSavings === 'number'
                            ? formatSvc.formatMoneyNoDecimals(item.AssessmentScore?.AssessmentSavings ?? 0)
                            : null,
                    accessor: (item) => item.AssessmentScore?.AssessmentSavings ?? 0,
                    sortField: 'AssessmentScore.AssessmentSavings',
                    type: 'number',
                    filter: true,
                    defaultWidth: 190,
                    noRemove: true,
                    defaultFixed: true,
                },
                {
                    id: 'AssessmentScore.AssessmentOpportunityCount',
                    noResize: true,
                    header: 'Opportunities',
                    align: 'right',
                    cellRenderer: (item) =>
                        typeof item.AssessmentScore?.AssessmentOpportunityCount === 'number'
                            ? formatSvc.formatInt(item.AssessmentScore?.AssessmentOpportunityCount ?? 0)
                            : null,
                    accessor: (item) => item.AssessmentScore?.AssessmentOpportunityCount ?? 0,
                    sortField: 'AssessmentScore.AssessmentOpportunityCount',
                    type: 'number',
                    defaultWidth: 150,
                    filter: true,
                    noRemove: true,
                    defaultFixed: true,
                },
            ] as ColumnConfig<HistoricalAssessmentRow>[],
        []
    );

    const defaultGroupBy = useMemo(() => [{ id: 'AssessmentCode', sortDir: 'Desc', sortMode: 'count' }] as GridGroupByState[], []);

    const gridDataSource = useMemo(() => {
        return {
            async getPage(start, end, state, parent) {
                if (parent == null || parent == undefined) {
                    let results = await getAssessmentsGetAssessmentDefinitions();
                    const datasource = new GridArrayDataSource(results ?? []);
                    let data = datasource.applyState({ filters: filters, sort: [] });
                    return { items: data.sort((a, b) => (a.UfTitle ?? '')?.localeCompare(b.UfTitle ?? '')), total: data.length };
                } else {
                    const query = queryBuilder<HistoricalAssessmentRow>()
                        .take(end - start + 1)
                        .skip(start)
                        .sortAsc((b) => b.model.AssessmentDate!)
                        .build();
                    const assessmentDefClause = {
                        Operation: 'eq',
                        Operands: [{ field: 'AssessmentDefId' }, { value: 'Id' in parent ? parent.Id : null }],
                    };
                    query.Where = {
                        Operation: 'and',
                        Operands: [...(state.filters ?? []), props.criteria, assessmentDefClause],
                    };
                    const results = await postAssessmentsSearchAll(query);
                    return { items: results.Results, total: results.Count };
                }
            },
        } as DataSourceConfig<HistoricalAssessmentRow>;
    }, [props.criteria, filters]);

    const childAccessor = useMemo(() => {
        return {
            hasChildren: (row: HistoricalAssessmentRow) => {
                return 'Id' in row;
            },
        };
    }, [grid]);

    const rowSelector = useRowSelector({ gridSelectionStrategy, tooltip: 'Chart this data in Historical Chart' });
    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<HistoricalAssessmentRow[]> }) => {
        const selections = await getItems();
        const items = selections.filter((s) => 'Id' in s);
        const lineItems = items.map((s) => ({
            filter: { Operation: 'eq', Operands: [{ field: 'AssessmentDefId' }, { value: s.Id }] } as IQueryExpr,
            color: gridSelectionStrategy.getSelectionColor(s)!,
            label: s.UfTitle,
        }));
        props.setLineData(lineItems);
    }, []);

    const onRowClick = useCallback((row: HistoricalAssessmentRow) => {
        if (row.Id !== null && row.Id !== undefined) {
            gridModel.grid?.setSelected(row, !gridModel.grid?.isSelected(row));
        }
    }, []);

    return (
        <DataGrid
            dataSource={gridDataSource}
            allowGroupBy={true}
            columns={columns}
            defaultGroupBy={defaultGroupBy}
            groupByAsRows={true}
            onFilterAdded={onFilterAdded}
            onFilterClearing={onFilterClearing}
            rightTopPlaceHolder={props.rightTopPlaceHolder}
            onModelLoaded={handleModelLoaded}
            selectionMode="multiple"
            onSelectedChanged={handleSelectionChanged}
            selectionStrategy={gridSelectionStrategy}
            renderRowSelector={rowSelector}
            onRowClick={onRowClick}
            indentLeafNodes
            showRefresh
            childAccessor={childAccessor}
            allowSavedViews={true}
            statePersistence={{ key: `Assessment-History` }}
            exportName="Historical-Assessments"
        />
    );
}

class HistoricalLineChartSelectionStrategy extends BaseChartKeySelectionStrategy<HistoricalAssessmentRow> {}

function HistoricalLineKeyRowSelector({
    selectionStrategy,
    item,
    toggle,
    color,
    tooltip,
}: {
    selectionStrategy: HistoricalLineChartSelectionStrategy;
    item: HistoricalAssessmentRow | null;
    toggle: (selected: boolean) => void;
    color?: string;
    tooltip?: string;
}) {
    const theme = useMantineTheme();
    useEvent(selectionStrategy.selectionChanged);
    const isSelected = item ? selectionStrategy.isSelected(item) : false;
    const itemColor = item ? selectionStrategy.getSelectionColor(item) : undefined;
    const allSelected = selectionStrategy.isAllSelected();
    const selectAll = useCallback(() => {
        toggle(true);
    }, [selectionStrategy, toggle]);
    const iconColor = allSelected ? theme.colors.primary[6] : isSelected ? color ?? itemColor ?? theme.colors.primary[6] : theme.colors.gray[4];

    return item && 'Id' in item ? (
        <Tooltip withinPortal label={tooltip} position="right">
            {item ? (
                <i className="ti ti-chart-histogram" style={{ color: iconColor }} />
            ) : (
                <i className="ti ti-chart-histogram" style={{ color: theme.colors.primary[6] }} onClick={selectAll} />
            )}
        </Tooltip>
    ) : null;
}

function useRowSelector({
    gridSelectionStrategy,
    color,
    tooltip,
}: {
    gridSelectionStrategy: HistoricalLineChartSelectionStrategy;
    color?: string;
    tooltip?: string;
}) {
    return useCallback(
        (item: HistoricalAssessmentRow | null, { toggle }: { toggle: (selected: boolean) => void }) => (
            <HistoricalLineKeyRowSelector selectionStrategy={gridSelectionStrategy} toggle={toggle} item={item} color={color} tooltip={tooltip} />
        ),
        [gridSelectionStrategy, color, tooltip]
    );
}
