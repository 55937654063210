import { postResourcesQuery, usePostResourcesQuery } from '@apis/Resources';
import { BaseAwsResource, BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';
import { useCompany } from '../Router/CompanyContent';

export function GetResourceAvailabilityZones(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const { data, isLoading, mutate: runQuery } = usePostResourcesQuery();
    useEffect(() => {
        if (props.resourceType == 'ELB') {
            (async () => {
                var query = queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq('ELBV1'), r.model.Id!.eq(props.resourceId)))
                    .build();
                var v1Data = await postResourcesQuery(query, { companyId: company?.Id });
                var resourceType = v1Data.Results?.[0] != null ? 'ELBV1' : 'ELBV2';

                runQuery({
                    data: queryBuilder<BaseAwsResource>()
                        .where((r) => r.and(r.model.ResourceType!.eq(resourceType), r.model.Id!.eq(props.resourceId)))
                        .build(),
                    params: { companyId: company?.Id },
                });
            })();
        } else {
            runQuery({
                data: queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                    .build(),
                params: { companyId: company?.Id },
            });
        }
    }, []);

    var retValue;
    if (isLoading) {
        retValue = <>Loading...</>;
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            retValue = <>No resource found!</>;
        } else {
            const resource = data.Results[0] as BaseResource & { AvailabilityZones: Record<string, any>[] };
            if (resource.AvailabilityZones == null) {
                retValue = <>No availability zones found!</>;
            } else {
                if (resource.AvailabilityZones.length > 0) {
                    let valuesArray = [];
                    for (var i = 0; i < resource.AvailabilityZones.length; i++) {
                        valuesArray.push(
                            <tr key={'azr' + i}>
                                <td key={'l' + i} className="label">
                                    {resource.AvailabilityZones[i].SubnetId}
                                </td>
                                <td key={'v' + i} className="value">
                                    {resource.AvailabilityZones[i].ZoneName}
                                </td>
                            </tr>
                        );
                    }
                    retValue = (
                        <div className="resourcesTableContainer">
                            <Table className="resourcesTable">
                                <tbody>{valuesArray}</tbody>
                            </Table>
                        </div>
                    );
                } else {
                    retValue = (
                        <div className="resourcesTableContainer">
                            <Table className="resourcesTable">
                                <tbody>
                                    <tr>
                                        <td>No availability zones</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    );
                }
            }
        }
    }

    return retValue;
}

export function ResourceAvailabilityZones() {
    return <>Resource Availability Zones</>;
}
endpoint('resource', ResourceAvailabilityZones, 'Resource Availability Zones');
