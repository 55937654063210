import { ActionIcon, Box, Center, Space, Text } from '@mantine/core';
import { InvoiceDetail } from '@apis/Customers/model';
import { getInvoiceGetInvoices, getInvoiceDownloadInvoice } from '@apis/Customers';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useState, useEffect, useMemo } from 'react';
import { AdminPageTitle, AdminPanel } from './Design';

export const AdminInvoices = () => {
    const formatSvc = useDi(FormatService);
    const [invoices, setInvoices] = useState([] as InvoiceDetail[]);
    useEffect(() => {
        getInvoiceGetInvoices().then((invoices) =>
            setInvoices(
                invoices.map((invoice) => ({
                    Name: invoice.Name,
                    Date: invoice.Date,
                    FilterableDate: new Date(formatSvc.toShortDate(new Date(invoice.Date as string))),
                    Description: invoice.Description,
                    Amount: invoice.Amount,
                    Status: invoice.Status,
                    Id: invoice.Id,
                }))
            )
        );
    }, [] as InvoiceDetail[]);

    async function downloadInvoice(invoiceId: string) {
        var pdfUrl = await getInvoiceDownloadInvoice({ invoiceId });
        window.open(pdfUrl);
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const columns = useMemo(
        () =>
            [
                {
                    id: 'Name',
                    accessor: (item) => item.Name,
                    header: 'Name',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 165,
                    sortField: 'Name',
                    filter: {
                        filterField: 'Name',
                        filterType: 'string',
                        name: 'Name',
                    },
                },
                {
                    id: 'Date',
                    accessor: 'Date',
                    formatter: (i) => new Intl.DateTimeFormat().format(Date.parse(i.Date!)),
                    header: 'Date',
                    align: 'center',
                    type: 'string',
                    defaultWidth: 100,
                    sortField: 'Date',
                    filter: {
                        filterType: 'date',
                        name: 'Date',
                        filterField: 'FilterableDate',
                    },
                },
                {
                    id: 'Description',
                    accessor: (item) => item.Description,
                    header: 'Description',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 200,
                    sortField: 'Description',
                    filter: {
                        filterField: 'Description',
                        filterType: 'string',
                        name: 'Description',
                    },
                },
                {
                    id: 'Amount',
                    accessor: (item) => item.Amount,
                    cellRenderer: (item) => (
                        <Text
                            color={item.Status == 'Paid' ? colorPalette.subTextColor : colorPalette.negativeNumberColor}
                            sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {formatter.format(item.Amount!)}
                        </Text>
                    ),
                    header: 'Amount',
                    align: 'center',
                    type: 'number',
                    defaultWidth: 150,
                    sortField: 'Amount',
                    filter: {
                        filterField: 'Amount',
                        filterType: 'number',
                        name: 'Amount',
                    },
                },
                {
                    id: 'Status',
                    accessor: (item) => item.Status,
                    header: 'Status',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 150,
                    sortField: 'Status',
                    filter: {
                        filterField: 'Status',
                        filterType: 'string',
                        name: 'Status',
                    },
                },
                {
                    id: 'Id',
                    header: '',
                    align: 'center',
                    defaultWidth: 50,
                    cellRenderer: (i) => (
                        <Center sx={{ height: 30 }}>
                            <ActionIcon color="primary" onClick={() => downloadInvoice(i.Id!)}>
                                <i className="ti ti-download" style={{ cursor: 'pointer' }} />
                            </ActionIcon>
                        </Center>
                    ),
                    exportOptions: {
                        hidden: true,
                    },
                },
            ] as DataColumnConfig<InvoiceDetail>[],
        []
    );

    return (
        <AdminPanel fullHeight>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box>
                    <AdminPageTitle>Invoices</AdminPageTitle>
                    <Space h="sm" />
                </Box>
                <Box sx={{ flex: 1, minHeight: 0 }}>{invoices.length == 0 ? null : <DataGrid dataSource={invoices} columns={columns} />}</Box>
            </Box>
        </AdminPanel>
    );
};
