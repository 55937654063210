import '@root/Components/Actions/actions.scss';
import { ActionIcon, Box, Divider, Drawer, Group, Title } from '@mantine/core';
import { ComponentType, useCallback, useEffect, useState } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { AnonymousJob } from '@apis/Resources';
import { useDi } from '@root/Services/DI';
import { ActivityItemAdapter, ActivityItemModel, JobStatus } from './ActivityPanel/ActivityTypes';
import { X } from 'tabler-icons-react';
import { TagActivityDetail } from './ActivityPanel/TagActivityDetails';
import { ActivityDetailsProps } from './ActivityPanel/Models';
import { GenericDetails } from './ActivityPanel/GenericDetails';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { inject, singleton } from 'tsyringe';
import { JobService } from '@root/Services/Jobs/JobService';
import { ExportActivityDetails } from './ActivityPanel/ExportActivityDetails';
import { IdleStartStopActivityDetails } from './ActivityPanel/IdleStartStopActivityDetails';
import { CostForecastActivityDetails } from './ActivityPanel/CostForecastActivityDetails';

@singleton()
export class ActivityDetailsPanelService {
    public readonly openRequested = new EventEmitter<AnonymousJob | undefined>(undefined);

    public constructor(@inject(JobService) private readonly jobService: JobService) {}

    public async openByJobId(jobId: string) {
        const result = await this.jobService.getJob(jobId);
        if (result) {
            this.openRequested.emit(result as AnonymousJob);
        }
    }
    public async open(job: AnonymousJob) {
        this.openRequested.emit(job);
    }
}

export function ActivityDetailsOpener({ item, onClose }: { item: AnonymousJob | undefined; onClose: () => void }) {
    const adapter = useDi(ActivityItemAdapter);
    const openerSvc = useDi(ActivityDetailsPanelService);
    const [details, setDetails] = useState<{ jobStatus: JobStatus; model: ActivityItemModel }>();
    useEvent(openerSvc.openRequested, (job) => {
        loadDetails(job as AnonymousJob);
    });
    const loadDetails = useCallback(
        async (job: AnonymousJob) => {
            const details = await adapter.loadActivityModel(job);
            setDetails(details);
        },
        [setDetails]
    );
    useEffect(() => {
        setDetails(undefined);
        if (item) {
            loadDetails(item);
        }
    }, [item?.Id, setDetails]);
    const close = useCallback(() => {
        onClose();
        setDetails(undefined);
    }, [onClose, setDetails]);
    return (
        <Drawer onClose={close} opened={!!details} position="right" withinPortal size={500} withCloseButton={false}>
            <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                <Group position="apart" px="lg" sx={{ height: 80 }}>
                    <Title order={4}>{details ? details.model?.title : ''}</Title>
                    <ActionIcon onClick={close}>
                        <X />
                    </ActionIcon>
                </Group>
                <Divider />
                {details ? <ActionDetailsLoader item={details?.jobStatus ?? undefined} displayInfo={details?.model} /> : null}
            </Box>
        </Drawer>
    );
}

const activityDetailsComponentLookup = new Map<string, ComponentType<ActivityDetailsProps>>([
    ['Cloudsaver.Resources.Domain.Models.TagResourcesJob', TagActivityDetail],
    ['Cloudsaver.Export.Application.Models.ExportRequestJob', ExportActivityDetails],
    ['Cloudsaver.Resources.Domain.Models.IdleResources.IdleResourcesStartStopJob', IdleStartStopActivityDetails],
    ['Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested', CostForecastActivityDetails],
]);
export function ActionDetailsLoader({ item, displayInfo }: ActivityDetailsProps) {
    const Component = activityDetailsComponentLookup.get(item.job.Type) ?? GenericDetails;
    return <Component item={item} displayInfo={displayInfo} />;
}
