import { getInvoiceDownloadInvoice } from '@apis/Customers';
import { Plans } from '@apis/Customers/model';
import { Box, Button, Card, Grid, Space, Text, ThemeIcon, Title } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { useCallback } from 'react';
import { CircleCheck, Download, Link } from 'tabler-icons-react';

export function SubscriptionChangeSuccess({ plan, invoiceId, close }: { plan: Plans; close: () => void; invoiceId: string }) {
    const { getRootUrl } = useNav();
    const downloadInvoice = useCallback(async () => {
        var pdfUrl = await getInvoiceDownloadInvoice({ invoiceId });
        window.open(pdfUrl);
    }, [invoiceId]);
    function goToSettingsPage() {
        window.location.replace(getRootUrl('settings/subscription'));
    }
    return (
        <Grid>
            <Grid.Col span={5} offset={3.5}>
                <Card shadow="lg" p="xl" radius="lg">
                    <Box sx={{ textAlign: 'center' }}>
                        <ThemeIcon size="xl" radius="xl" color="success" variant="light">
                            <CircleCheck />
                        </ThemeIcon>
                        <Text size="xl" m="lg">
                            Your subscription has been updated.
                        </Text>
                        <Text>New subscription</Text>
                        <Title order={3}>{plan.FriendlyName}</Title>
                        <Space h="lg" />
                        <Button fullWidth radius="md" onClick={() => goToSettingsPage()}>
                            Done
                        </Button>
                        <Space h="md" />
                        <AnchorButton text="Download invoice" icon={<Download />} color="gray" onClick={downloadInvoice} />
                    </Box>
                </Card>
            </Grid.Col>
        </Grid>
    );
}
